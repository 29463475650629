import axios from "../axios";

class GiftSupplierService {
  createSupplier(data) {
    return axios.post("/giftsupplier", data);
  }

  getSupplierById(id) {
    return axios.get(`/giftsupplier/getById/${id}`);
  }

  getSupplierAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`giftsupplier/${pageNo}?${params}`);
  }

  getSupplierList() {
    return axios.get(`/giftsupplier`);
  }

  deleteSupplier(id) {
    return axios.delete(`/giftsupplier/${id}`);
  }

  updateSupplier(id, data) {
    return axios.put(`/giftsupplier/${id}`, data);
  }
}

export default new GiftSupplierService();
