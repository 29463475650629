import axios from "../axios";

class DashboardService {
  getAll() {
    return axios.get(`/dashboard/gift`);
  }

  getOrders(filter) {
    let uri = `/dashboard/giftordersbystatus`;
    var filterLink = "";
    filterLink += filter.startDate ? `?startDate=${filter.startDate}` : "?";
    filterLink += filter.endDate ? `&endDate=${filter.endDate}` : "";

    return axios.get(uri + filterLink);
  }

  getOrdersPerProvince(filter) {
    let uri = `/dashboard/giftordersbyprovince`;
    var filterLink = "";
    filterLink += filter.startDate ? `?startDate=${filter.startDate}` : "?";
    filterLink += filter.endDate ? `&endDate=${filter.endDate}` : "";
    filterLink += filter.provinceId ? `&provinceId=${filter.provinceId}` : "";

    return axios.get(uri + filterLink);
  }
}

export default new DashboardService();
