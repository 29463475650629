import axios from "../axios";

class VariantService {
  createVariant(data) {
    return axios.post("/giftitemvariant", data);
  }

  getVariantById(id) {
    return axios.get(`/giftitemvariant/getById/${id}`);
  }

  findVariantBySku(sku) {
    return axios.get(`/giftitemvariant/finbysku/${sku}`);
  }

  deleteVariant(id) {
    return axios.delete(`/giftitemvariant/${id}`);
  }

  updateVariant(id, data) {
    return axios.put(`/giftitemvariant/${id}`, data);
  }
}

export default new VariantService();
