import { TextField, Grid, Stack, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { openDialogHomeItems, updateHomeItems } from "src/actions/homeItems";
import { getAllVendors } from "src/actions/vendors";

const HomeItemsForm = () => {
  const _HomeItems = useSelector((state) => state.homeItems);
  const loading = useSelector((state) => state.homeItems.reload);
  const saveHomeItemsPageNO = useSelector(
    (state) => state.homeItems.saveHomeItemsPageNum
  );
  const _SearchBody = useSelector((state) => state.homeItems.searchBody);
  const vendors = useSelector((state) => state.vendors.dataList);

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      allVendorsIds: [],
      maleVendorsIds: [],
      feMaleVendorsIds: [],
    },
  });

  useEffect(() => {
    reset({ ..._HomeItems.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_HomeItems.form]);

  useEffect(() => {
    dispatch(getAllVendors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(updateHomeItems(data, saveHomeItemsPageNO, _SearchBody)).then(
      () => {
        handleDialog();
      }
    );
  };

  const handleDialog = () => {
    dispatch(openDialogHomeItems());
  };

  return (
    <CustomDialog
      title={"UPDATE HOME ITEMS"}
      isOpen={_HomeItems.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="allVendorsIds"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  id="vendors"
                  options={vendors.filter(
                    (vendor) =>
                      !watch("maleVendorsIds")?.includes(vendor.id) &&
                      !watch("feMaleVendorsIds")?.includes(vendor.id)
                  )}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Both Genders" />
                  )}
                  onChange={(event, value) => {
                    var ids = value.map((x) => x.id);
                    setValue("allVendorsIds", value ? ids : [], {
                      shouldDirty: true,
                    });
                  }}
                  value={vendors?.filter((x) =>
                    field.value ? field.value.includes(x.id) : null
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="maleVendorsIds"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  id="vendors"
                  options={vendors.filter(
                    (vendor) =>
                      !watch("allVendorsIds")?.includes(vendor.id) &&
                      !watch("feMaleVendorsIds")?.includes(vendor.id)
                  )}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Male" />
                  )}
                  onChange={(event, value) => {
                    var ids = value.map((x) => x.id);
                    setValue("maleVendorsIds", value ? ids : []);
                  }}
                  value={vendors?.filter((x) =>
                    field.value ? field.value.includes(x.id) : null
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="feMaleVendorsIds"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  id="vendors"
                  options={vendors.filter(
                    (vendor) =>
                      !watch("allVendorsIds")?.includes(vendor.id) &&
                      !watch("maleVendorsIds")?.includes(vendor.id)
                  )}
                  getOptionLabel={
                    (option) => option.name
                    //    (
                    //   <Grid container spacing={2}>
                    //     <Grid item xs={4}>
                    //       <Typography variant="body">{option.name}</Typography>
                    //     </Grid>
                    //     <Grid item xs={8}>
                    //       <Box
                    //         component="img"
                    //         height={50}
                    //         sx={{ objectFit: "contain" }}
                    //         src={option.logoUrl}
                    //       />
                    //     </Grid>
                    //   </Grid>
                    // )
                  }
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Female" />
                  )}
                  onChange={(event, value) => {
                    var ids = value.map((x) => x.id);
                    setValue("feMaleVendorsIds", value ? ids : []);
                  }}
                  value={vendors?.filter((x) =>
                    field.value ? field.value.includes(x.id) : null
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default HomeItemsForm;
