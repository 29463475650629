import {
  CREATE_EXPENSE,
  DELETE_EXPENSE,
  GET_ALL_EXPENSES,
} from "../actions/type";

const initialState = [];

function expenseReducer(expenses = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_EXPENSE:
      expenses.data.push(payload.data);
      expenses.totalCount += 1;
      return [...expenses, payload];

    case GET_ALL_EXPENSES:
      return payload;

    case DELETE_EXPENSE:
      expenses.data = expenses.data.filter((x) => x.id !== payload.id);
      expenses.rowCount -= 1;
      return expenses;

    default:
      return expenses;
  }
}

export default expenseReducer;
