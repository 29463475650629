import { Helmet } from "react-helmet";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import { FaUsersCog } from "react-icons/fa";

const DynamicAuthStaffHeader = () => {
  return (
    <>
      <Helmet>
        <title>Staff Users | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Staff Users
              </Typography>
            }
            avatar={<FaUsersCog size={20} />}
          />
        </Card>
      </Box>
    </>
  );
};

export default DynamicAuthStaffHeader;
