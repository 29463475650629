import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Slide,
  Stack,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateCategory,
  createCategory,
  openCategoryDialog,
  resetForm,
} from "src/actions/ticket/category";
import { Controller, useForm } from "react-hook-form";
import { CustomDialog } from "src/components/CustomDialog";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TicketCategoryForm = () => {
  const dispatch = useDispatch();
  const ticketCategory = useSelector((state) => state.ticketCategory);
  const pagNo = useSelector((state) => state.ticketCategory.savePageNo);

  const isAdd = ticketCategory.form.id ? false : true;
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: ticketCategory.form,
  });

  useEffect(() => {
    reset({ ...ticketCategory.form });
  }, [ticketCategory.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const handleDialog = () => {
    dispatch(openCategoryDialog());
  };

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(createCategory(data, pagNo + 1));
    } else {
      dispatch(UpdateCategory(data.id, data, pagNo + 1));
    }
  };

  return (
    <CustomDialog
      title={isAdd ? "CREATE CATEGORY" : "UPDATE CATEGORY"}
      isOpen={ticketCategory.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Controller
              name="nameAr"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="nameEn"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={field.value}
                      onChange={(e) => {
                        setValue("isActive", e.target.checked);
                      }}
                    />
                  }
                  label="Is Active !"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <Button variant="contained" type="submit" color="primary">
                Add
              </Button>
              {/* <Button onClick={() => dispatch(resetForm())} variant="contained" color="warning">
                Clear Text
              </Button> */}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default TicketCategoryForm;
