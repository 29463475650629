import { useState } from "react";
import { Typography, Button, Grid, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { openNotifyUserDialog } from "src/actions/users";
import {
  getPersonalNotifications,
  notificationUserById,
} from "src/actions/notification";
import { CustomDialog } from "src/components/CustomDialog";
import CustomText from "src/components/CustomText";

const NotifyUser = (props) => {
  const dispatch = useDispatch();

  const notifyUserDialog = useSelector((state) => state.users.notifyUserDialog);

  const [viewConfirm, setViewConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [userByIdContent, setUserByIdContent] = useState({
    titleEn: "",
    contentEn: "",
    titleAr: "",
    contentAr: "",
    userIds: [],
  });

  function onSubmitUserById(e) {
    e.preventDefault();
    setViewConfirm(true);
  }

  function sendUsersById() {
    setIsLoading(true);
    dispatch(
      notificationUserById({
        ...userByIdContent,
        userIds: [props.userId],
      })
    )
      .then((res) => {
        setUserByIdContent({
          titleEn: "",
          contentEn: "",
          titleAr: "",
          contentAr: "",
          userIds: [],
        });
        setIsLoading(false);
        setViewConfirm(false);
        dispatch(getPersonalNotifications());
        dispatch(openNotifyUserDialog());
      })
      .catch((err) => {
        setIsLoading(false);
        setViewConfirm(false);
      });
  }

  return (
    <CustomDialog
      isOpen={notifyUserDialog}
      handleClose={() => {
        dispatch(openNotifyUserDialog());
        setUserByIdContent({
          titleEn: "",
          contentEn: "",
          titleAr: "",
          contentAr: "",
          userIds: [],
        });
        setViewConfirm(false);
        setIsLoading(false);
      }}
      maxWidth="lg"
      title={
        <Stack
          spacing={0.5}
          direction="row"
          justifyContent="start"
          alignItems="center"
        >
          <Typography variant="body">Notify User:</Typography>
          <Typography variant="h5">{props.userName}</Typography>
        </Stack>
      }
    >
      <form onSubmit={onSubmitUserById}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomText
              required
              label="English Title"
              multiline
              value={userByIdContent.titleEn}
              onChange={(e) => {
                setUserByIdContent({
                  ...userByIdContent,
                  titleEn: e.target.value,
                });
              }}
              style={{ margin: "5px" }}
              fullWidth
              inputProps={{ minLength: 5 }}
              helperText={
                userByIdContent.titleEn?.length < 5
                  ? "Title should at least 5 characters"
                  : null
              }
            />
            <CustomText
              required
              label="English Content"
              multiline
              value={userByIdContent.contentEn}
              onChange={(e) => {
                setUserByIdContent({
                  ...userByIdContent,
                  contentEn: e.target.value,
                });
              }}
              style={{ margin: "5px" }}
              fullWidth
              rows="8"
              inputProps={{ minLength: 10 }}
              helperText={
                userByIdContent.contentEn?.length < 10
                  ? "Content should at least 10 characters"
                  : null
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CustomText
              required
              label="العنوان"
              multiline
              value={userByIdContent.titleAr}
              onChange={(e) => {
                setUserByIdContent({
                  ...userByIdContent,
                  titleAr: e.target.value,
                });
              }}
              style={{ margin: "5px", direction: "rtl" }}
              fullWidth
              inputProps={{ minLength: 5 }}
              helperText={
                userByIdContent.titleAr?.length < 5
                  ? "العنوان يحب ان لا يكون اقل من 5 احرف"
                  : null
              }
            />
            <CustomText
              required
              label="المحتوى"
              multiline
              value={userByIdContent.contentAr}
              onChange={(e) => {
                setUserByIdContent({
                  ...userByIdContent,
                  contentAr: e.target.value,
                });
              }}
              style={{ margin: "5px", direction: "rtl" }}
              fullWidth
              rows="8"
              inputProps={{ minLength: 10 }}
              helperText={
                userByIdContent.contentAr?.length < 10
                  ? "المحتوي يحب ان لا يكون اقل من 10 احرف"
                  : null
              }
            />
          </Grid>
          <Grid item xs={12}>
            {viewConfirm ? (
              <Stack
                direction={"row"}
                spacing={2}
                sx={{
                  marginTop: 2,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>
                  Are you sure you want to send notification ?
                </Typography>
                <LoadingButton
                  variant="contained"
                  onClick={() => {
                    sendUsersById();
                  }}
                  color="success"
                  loading={isLoading}
                >
                  Confirm
                </LoadingButton>
                <Button
                  variant="contained"
                  onClick={() => setViewConfirm(false)}
                  color="error"
                >
                  Cancel
                </Button>
              </Stack>
            ) : (
              <Grid style={{ position: "relative", left: "35%" }} item xs={3}>
                <Button
                  style={{ position: "relative" }}
                  variant="contained"
                  sx={{ margin: "5px" }}
                  type="submit"
                  fullWidth
                >
                  Send
                </Button>
                <Button
                  style={{ position: "relative" }}
                  color="secondary"
                  variant="contained"
                  sx={{ margin: "5px" }}
                  onClick={() => {
                    setUserByIdContent({
                      titleEn: "",
                      contentEn: "",
                      titleAr: "",
                      contentAr: "",
                      userIds: [],
                    });
                  }}
                  fullWidth
                >
                  Clear All
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default NotifyUser;
