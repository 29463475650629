import {
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
  GET_ALL_ADDRESSES,
  DELETE_ADDRESS,
} from "../actions/type";

const initialState = [];

function addressReducer(addresses = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_ADDRESSES:
      return payload;

    case CREATE_ADDRESS:
      addresses
        .filter((x) => x.id == payload.provinceId)[0]
        .cities.push(payload);
      return addresses;

    case UPDATE_ADDRESS:
      addresses
        .filter((x) => x.id == payload.provinceId)[0]
        .cities.filter((x) => x.id == payload.id)[0].nameUS = payload.nameUS;
      addresses
        .filter((x) => x.id == payload.provinceId)[0]
        .cities.filter((x) => x.id == payload.id)[0].nameAR = payload.nameAR;
      addresses
        .filter((x) => x.id == payload.provinceId)[0]
        .cities.filter((x) => x.id == payload.id)[0].nameKU = payload.nameKU;
      return addresses;

    case DELETE_ADDRESS:
      addresses.map((data) =>
        data.cities.filter((city) => city.id !== payload)
      );
      // addresses.filter(x=>x.id == payload.provinceId)[0].cities.filter(x=>x.id !== payload)
      return addresses;

    default:
      return addresses;
  }
}

export default addressReducer;
