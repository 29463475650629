import {
  GET_ALL_BANNERS,
  OPEN_BANNER_DIALOG,
  RESET_FORM_BANNERS,
  RELOAD_BANNER,
  SAVE_BANNERS_PAGE_NO,
  GET_BY_ID_BANNERS,
  SAVE_PROMO_PAGE_SIZE,
  SAVE_SEARCH_BODY_BANNERS,
} from "./type";

import BannerDataService from "../api/services/banner.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const openDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_BANNER_DIALOG,
    payload: data,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: RESET_FORM_BANNERS,
  });
};

export const reloadBanners = () => (dispatch) => {
  dispatch({
    type: RELOAD_BANNER,
  });
};

export const savePromoPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_BANNERS_PAGE_NO,
    payload: data,
  });
};

export const saveBannersSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_BODY_BANNERS,
    payload: data,
  });
};

export const pageSizeBanners = (data) => (dispatch) => {
  dispatch({
    type: SAVE_PROMO_PAGE_SIZE,
    payload: data,
  });
};

export const createBanner =
  (data, type, pagNo, pageSize) => async (dispatch) => {
    try {
      const res = await BannerDataService.create(data);
      dispatch(showSuccessSnackbar("Created successfully!"));
      dispatch(getAllBanners(type, pagNo, pageSize));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
      return Promise.resolve(err);
    }
  };

export const getAllBanners =
  (type, pageNumber, pageSize) => async (dispatch) => {
    try {
      dispatch(reloadBanners());
      const res = await BannerDataService.getAll(type, pageNumber, pageSize);
      dispatch({
        type: GET_ALL_BANNERS,
        payload: res.data.data,
      });
      dispatch(reloadBanners());
    } catch (err) {
      dispatch(reloadBanners());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
      return Promise.resolve(err);
    }
  };

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadBanners());
    const res = await BannerDataService.getById(id);
    dispatch(reloadBanners());
    dispatch({
      type: GET_BY_ID_BANNERS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch(reloadBanners());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
export const getByIdForUpdate = (id, attachmentUrl) => async (dispatch) => {
  try {
    dispatch(reloadBanners());
    const res = await BannerDataService.getById(id);
    dispatch({
      type: GET_BY_ID_BANNERS,
      payload: res.data.data,
    });
    dispatch(reloadBanners());
    dispatch(openDialog(attachmentUrl));
  } catch (err) {
    dispatch(reloadBanners());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateBanner =
  (data, type, pagNo, pageSize) => async (dispatch) => {
    try {
      const res = await BannerDataService.update(data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllBanners(type, pagNo, pageSize));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteBanner = (id, type, pagNo, pageSize) => async (dispatch) => {
  try {
    const res = await BannerDataService.delete(id);
    dispatch(showSuccessSnackbar("Deleted successfully!"));
    dispatch(getAllBanners(type, pagNo, pageSize));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const uploadAttachment =
  (data, createData, type, pageNo, pageSize) => async (dispatch) => {
    try {
      const res = await BannerDataService.uploadAttachment(data);
      createData.attacmentId = res.data.data.id;
      createData.isTop = true;
      dispatch(createBanner(createData, type, pageNo, pageSize));
      dispatch(showSuccessSnackbar("uploaded successfully"));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const uploadAttachmentForUpdate =
  (data, updateData, type, pageNo, pageSize) => async (dispatch) => {
    try {
      if (data) {
        const res = await BannerDataService.uploadAttachment(data);
        updateData.attachmentId = res.data.data.id;
        updateData.isTop = true;
        dispatch(updateBanner(updateData, type, pageNo, pageSize));
        dispatch(showSuccessSnackbar("uploaded successfully"));
        return Promise.resolve(res.data);
      } else {
        updateData.isTop = true;
        dispatch(updateBanner(updateData, type, pageNo, pageSize));
        dispatch(showSuccessSnackbar("uploaded successfully"));
      }
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getAttachment = (id) => async (dispatch) => {
  try {
    const res = await BannerDataService.getAttachment(id);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteAttachment = (id) => async (dispatch) => {
  try {
    const res = await BannerDataService.deleteAttachment(id);
    dispatch(showSuccessSnackbar("Delete successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
