import { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  Typography,
  Tooltip,
} from "@mui/material";
import { Icon, IconButton, Stack } from "@mui/material";
import moment from "moment";
import DateRangepicker from "src/components/DateRangePicker";
import * as XLSX from "xlsx/xlsx.mjs";
import { useDispatch, useSelector } from "react-redux";
import { showErrorSnackbar } from "src/actions/snackbarMessages";
import { BiExport } from "react-icons/bi";

const Orders = (props) => {
  const dispatch = useDispatch();

  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [viewCalendar, setViewCalendar] = useState(false);
  const theme = useTheme();

  const dateArray = props.data.dates?.concat(props.user_data.dates);
  const newDateArray = [...new Set(dateArray)]?.sort(
    (a, b) => new Date(a) - new Date(b)
  );

  const orderData = props.data.dates.map((label, index) => {
    return { x: label, y: props.data.counts[index] };
  });

  const signUpData = props.user_data.dates.map((label, index) => {
    return { x: label, y: props.user_data.counts[index] };
  });

  function downloadXLSX(orders, users, filename) {
    const wb = convertDataToXLSX(orders, users);
    XLSX.writeFile(wb, `${filename}.xlsx`);
  }

  function convertDataToXLSX(orders, users) {
    const ws = XLSX.utils.json_to_sheet(
      newDateArray.map((date, index) => {
        return {
          Date: date,
          Orders:
            orderData.filter((user) => (user.x === date ? user.y : null))[0]
              ?.y || null,
          Users:
            signUpData.filter((user) => (user.x === date ? user.y : null))[0]
              ?.y || null,
        };
      })
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    return wb;
  }

  const data = {
    datasets: [
      {
        backgroundColor: "#2979ff",
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: orderData,
        label: "Total orders",
        maxBarThickness: 10,
      },
      {
        backgroundColor: "#f73378",
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: signUpData,
        label: "Total users",
        maxBarThickness: 10,
      },
    ],
    labels: newDateArray,
  };

  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card>
      <CardHeader
        title="Orders and users per month"
        subheader={
          <Stack direction="column" spacing={1}>
            <Typography variant="caption">
              {props.data.fromDate + " - " + props.data.toDate}
            </Typography>
            {viewCalendar && (
              <Stack direction="row" spacing={1}>
                <DateRangepicker
                  startDate={props.data.fromDate}
                  endDate={props.data.toDate}
                  handleOnChange={(searchDate) => {
                    const startMoment = moment(searchDate[0].startDate);
                    const endMoment = moment(searchDate[0].endDate);
                    const differenceInDays = endMoment.diff(
                      startMoment,
                      "days"
                    );

                    if (differenceInDays > 365) {
                      dispatch(
                        showErrorSnackbar(
                          "Date range cannot be more than one year"
                        )
                      );
                      return;
                    }

                    const updatedFilter = {
                      from: startMoment.format("YYYY-MM-DD"),
                      to: endMoment.format("YYYY-MM-DD"),
                    };

                    if (
                      updatedFilter.from !== props.data.fromDate ||
                      updatedFilter.to !== props.data.toDate
                    ) {
                      props.onDateChanged(updatedFilter);
                    }
                  }}
                />
              </Stack>
            )}
          </Stack>
        }
        action={
          <Stack direction="row" spacing={0} alignItems="center">
            <IconButton onClick={() => setViewCalendar(!viewCalendar)}>
              <Icon aria-label="settings">calendar_month</Icon>
            </IconButton>
            {_UserInfo.roles.includes("Export") && (
              <Tooltip title="Export to excel">
                <IconButton
                  onClick={() =>
                    downloadXLSX(
                      orderData,
                      signUpData,
                      "Orders and users per month"
                    )
                  }
                >
                  <BiExport />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        }
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: "relative",
          }}
        >
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default Orders;
