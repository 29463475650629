import moment from "moment";
import {
  CREATE_PROMO,
  GET_ALL_PROMOS,
  UPDATE_PROMO,
  DELETE_PROMO,
  CHANGE_PROMO_STATUS,
  RESET_FORM_PROMO,
  GET_BY_ID_PROMO,
  OPEN_PROMO_DIALOG,
  RELOAD_PROMO,
  SAVE_PROMO_SAVE_NO,
  SAVE_PROMO_SEARCH_BODY,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  savePromoPageNum: 1,
  pageSize: 15,
  form: {
    id: null,
    name: "",
    discount: "",
    discountLimit: "",
    validUntil: moment().format("YYYY-MM-DD"),
    isActive: false,
    newUsersOnly: false,
    allowedPerUser: "",
    limitedUserCount: 0,
    totalCost: false,
    vendordId: null,
    region: null,
    isCashback: false,
    promoCodeType: 0,
    isAllUsers: true,
    users: [],
    userIds: false,
    isFreeDelivery: false,
    note: "",
  },
  data: [],
  searchBody: {
    pageSize: 15,
    isActive: true,
    isExpired: false,
    isAscending: false,
    isBusiness: false,
    isUsed: null,
    company: "",
    name: "",
  },
  getById: {},
};

function promoReducer(promos = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_PROMO:
      promos.data.push(payload);
      promos.rowCount = promos.rowCount + 1;
      promos.pageCount = parseInt(promos.rowCount / 10, 10) + 1;
      return promos;

    case GET_ALL_PROMOS:
      var _Promos = {
        reload: promos.reload,
        data: payload,
        getById: promos.getById,
        form: promos.form,
        openDialog: false,
        pageCount: payload.pageCount,
        rowCount: payload.rowCount,
        savePromoPageNum: promos.savePromoPageNum,
        pageSize: promos.pageSize,
        searchBody: promos.searchBody,
      };
      return _Promos;

    case GET_BY_ID_PROMO:
      promos.form.id = payload.id;
      promos.form.name = payload.name;
      promos.form.discount = payload.discount;
      promos.form.discountLimit = payload.discountLimit;
      promos.form.validUntil = payload.validUntil;
      promos.form.isActive = payload.isActive;
      promos.form.newUsersOnly = payload.newUsersOnly;
      promos.form.allowedPerUser = payload.allowedPerUser;
      promos.form.limitedUserCount = payload.limitedUserCount;
      promos.form.totalCost = payload.totalCost;
      promos.form.vendordId = payload.vendorId;
      promos.form.createdAt = payload.createdAt;
      promos.form.isCashback = payload.isCashback;
      promos.form.promoCodeType = payload.promoCodeType;
      promos.form.isAllUsers = payload.users.length <= 0 ? true : false;
      promos.form.users = payload.users;
      promos.form.isFreeDelivery = payload.isFreeDelivery;
      promos.form.note = payload.note ?? "";
      return promos;

    case RESET_FORM_PROMO:
      promos.form.id = null;
      promos.form.name = "";
      promos.form.discount = "";
      promos.form.discountLimit = "";
      promos.form.validUntil = moment().format("YYYY-MM-DD");
      promos.form.isActive = false;
      promos.form.newUsersOnly = false;
      promos.form.allowedPerUser = "";
      promos.form.limitedUserCount = 0;
      promos.form.totalCost = false;
      promos.form.vendordId = null;
      promos.form.isCashback = false;
      promos.form.isAllUsers = true;
      promos.form.isFreeDelivery = false;
      promos.form.userIds = false;
      promos.form.users = [];
      promos.form.note = "";
      return promos;

    case SAVE_PROMO_SAVE_NO:
      promos.savePromoPageNum = payload;
      return promos;

    case SAVE_PROMO_SEARCH_BODY:
      return { ...promos, searchBody: payload };

    case OPEN_PROMO_DIALOG:
      return { ...promos, openDialog: !promos.openDialog };

    case RELOAD_PROMO:
      return { ...promos, reload: !promos.reload };

    case UPDATE_PROMO:
      var indexToChange = promos.data.indexOf(
        promos.data.filter((x) => x.id === payload.id)[0]
      );
      promos.data.splice(indexToChange, 1, payload);
      return promos;

    case DELETE_PROMO:
      promos.data = promos.data.filter((promo) => promo.id !== payload.id);
      return promos;

    case CHANGE_PROMO_STATUS:
      promos.data.map((promo) => {
        if (promo.id === payload.id) promo.isActive = !promo.isActive;
        return promo;
      });
      return promos;

    default:
      return promos;
  }
}

export default promoReducer;
