import {
  RESET_VARIANT_FORM,
  SAVE_VARIANT_SEARCH_BODY,
  SAVE_VARIANT_PAGE_NO,
  FIND_VARIANT_BY_SKU,
  RELOAD_VARIANT,
  GET_BY_ID_VARIANT,
  OPEN_VARIANT_DIALOG,
} from "src/actions/gifts/giftTypes";

const initialState = {
  reload: false,
  openDialog: false,
  isInGiftOrder: false,
  pageNo: 0,
  pageSize: 10,
  form: {
    id: null,
    giftItemId: null,
    quantity: "",
    giftItemOptionIds: [],
  },
  variantData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  searchBody: {
    pageSize: 15,
    name: "",
  },
  variantDataList: [],
};

function variantReducer(variant = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FIND_VARIANT_BY_SKU:
      variant.variantDataList = payload;
      return variant;

    case GET_BY_ID_VARIANT:
      variant.form.id = payload.id;
      variant.form.giftItemId = payload.giftItemId;
      variant.form.quantity = payload.quantity;
      variant.form.giftItemOptionIds = payload.giftItemOptionIds;
      return variant;

    case RESET_VARIANT_FORM:
      variant.form.id = null;
      variant.form.giftItemId = null;
      variant.form.quantity = "";
      variant.form.giftItemOptionIds = [];
      return variant;

    case SAVE_VARIANT_PAGE_NO:
      return { ...variant, pageNo: payload };

    case OPEN_VARIANT_DIALOG:
      // variant.form.id = payload;
      variant.isInGiftOrder = payload;
      return {
        ...variant,
        openDialog: !variant.openDialog,
      };

    case SAVE_VARIANT_SEARCH_BODY:
      return { ...variant, searchBody: payload };

    case RELOAD_VARIANT:
      return { ...variant, reload: !variant.reload };

    default:
      return variant;
  }
}

export default variantReducer;
