import BrandService from "src/api/giftServices/brand.service";
import helper from "src/utils/helper";
import uploadAttachmentsService from "src/api/services/uploadAttachments.service";
import {
  GET_ALL_GIFTS_BRAND,
  GET_BY_ID_GIFTS_BRAND,
  GET_GIFTS_BRAND_LIST,
  OPEN_GIFTS_BRAND_DIALOG,
  RELOAD_GIFTS_BRAND,
  RESET_GIFTS_BRAND_FORM,
  SAVE_GIFTS_BRAND_PAGE_NO,
  SAVE_GIFTS_BRAND_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";
import { UPLOAD_ATTACHMENTS } from "src/actions/type";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";

export const openGiftsBrandDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_GIFTS_BRAND_DIALOG,
  });
};
export const reloadGiftsBrand = () => (dispatch) => {
  dispatch({
    type: RELOAD_GIFTS_BRAND,
  });
};

export const saveGiftsBrandPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_GIFTS_BRAND_PAGE_NO,
    payload: data,
  });
};

export const saveGiftsBrandSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_GIFTS_BRAND_SEARCH_BODY,
    payload: data,
  });
};

export const resetGiftsBrandForm = () => (dispatch) => {
  dispatch({
    type: RESET_GIFTS_BRAND_FORM,
  });
};

export const createGiftBrand = (data, searchBody) => async (dispatch) => {
  try {
    const res = await BrandService.createGiftsBrand(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(
      getAllGiftsBrand(searchBody.pageNo, searchBody.pageSize, searchBody.name)
    );
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout(); 
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteGiftsBrand = (id, searchBody) => async (dispatch) => {
  try {
    const res = await BrandService.deleteGiftsBrand(id);
    dispatch(showSuccessSnackbar("Gift brand deleted successfully!"));
    dispatch(
      getAllGiftsBrand(searchBody.pageNo, searchBody.pageSize, searchBody.name)
    );
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getGiftsBrandById = (id, openDialog) => async (dispatch) => {
  try {
    dispatch(reloadGiftsBrand());
    const res = await BrandService.getGiftsBrandById(id);
    dispatch({
      type: GET_BY_ID_GIFTS_BRAND,
      payload: res.data.data,
    });
    if (openDialog) dispatch(openGiftsBrandDialog());
    dispatch(reloadGiftsBrand());
    // return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(reloadGiftsBrand());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllGiftsBrand =
  (pageNO, pageSize, name) => async (dispatch) => {
    try {
      dispatch(reloadGiftsBrand());
      const res = await BrandService.getGiftsBrandAll(pageNO, pageSize, name);
      dispatch({
        type: GET_ALL_GIFTS_BRAND,
        payload: res.data.data,
      });
      dispatch(reloadGiftsBrand());
      // return Promise.resolve(res.data.data)
    } catch (err) {
      dispatch(reloadGiftsBrand());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getGiftsBrandList = () => async (dispatch) => {
  try {
    dispatch(reloadGiftsBrand());
    const res = await BrandService.getGiftsBrandList();
    dispatch({
      type: GET_GIFTS_BRAND_LIST,
      payload: res.data.data,
    });
    dispatch(reloadGiftsBrand());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadGiftsBrand());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateGiftsBrand = (id, data, searchBody) => async (dispatch) => {
  try {
    const res = await BrandService.updateGiftsBrand(id, data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    dispatch(
      getAllGiftsBrand(searchBody.pageNo, searchBody.pageSize, searchBody.name)
    );
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const uploadAttachments = (data) => async (dispatch) => {
  try {
    dispatch(reloadGiftsBrand());
    const res = await uploadAttachmentsService.uploadAttachments(data);
    dispatch(showSuccessSnackbar("Uploaded successfully"));
    dispatch({
      type: UPLOAD_ATTACHMENTS,
      data: res.data,
    });
    dispatch(reloadGiftsBrand());
    // return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadGiftsBrand());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
