import axios from "../axios";

class shipmentsService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (
          data[key] !== "" &&
          (data[key] !== null) & (data[key] !== undefined)
        ) {
          if (key === "vendorIds" && Array.isArray(data[key])) {
            data[key].forEach((value) => {
              params += `vendorIds=${value}&`;
            });
          } else {
            params += `${key}=${data[key] ?? ""}&`;
          }
        }
      });
    }
    return axios.get(`/shippments/${pageNo}?${params}`);
  }
  getById(id) {
    return axios.get(`/shippments/getById/${id}`);
  }

  getByShipmentNumber(shippingNumber) {
    return axios.get(`/shippments/getbyshippingnumber/${shippingNumber}`);
  }

  update(id, data) {
    return axios.put(`/shippments/${id}`, data);
  }

  delete(id) {
    return axios.delete(`/shippments/${id}`);
  }

  deleteOrderItemFromShipment(id, orderItemId) {
    return axios.delete(`/shippments/${id}/${orderItemId}`);
  }

  changeStatus(id, status) {
    return axios.put(`/shippments/${id}/${status}`);
  }

  printPackingList(shippingNumber) {
    return axios.get(`/shippments/getpackinglist/${shippingNumber}`);
  }
}
export default new shipmentsService();
