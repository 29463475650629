import {
  ONBOARDING_RESET_FORM,
  ONBOARDING_GET_BY_ID,
  ONBOARDING_OPEN_DIALOG,
  ONBOARDING_RELOAD,
  ONBOARDING_GET_ALL,
  ONBOARDING_SEARCH_BODY,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  pageSize: 10,
  searchBody: {
    pageSize: 15,
    name: "",
    value: "",
  },
  form: {
    id: null,
    file: "",
    type: "",
  },
  onboardingData: [],
  onboardingDataList: [],
  getById: {},
};

function onboardingReducer(onboarding = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ONBOARDING_GET_ALL:
      onboarding.onboardingData = payload.data;
      return onboarding;

    case ONBOARDING_GET_BY_ID:
      onboarding.form = payload;
      return onboarding;

    case ONBOARDING_RESET_FORM:
      onboarding.form.id = null;
      onboarding.form.file = "";
      onboarding.form.type = "";
      return onboarding;

    case ONBOARDING_SEARCH_BODY:
      return { ...onboarding, searchBody: payload };

    case ONBOARDING_OPEN_DIALOG:
      return {
        ...onboarding,
        openDialog: !onboarding.openDialog,
      };

    case ONBOARDING_RELOAD:
      return { ...onboarding, reload: !onboarding.reload };

    default:
      return onboarding;
  }
}

export default onboardingReducer;
