import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import RolePermission from "src/components/RolePermission";
import RatingQuestionsForm from "../../form/Form";
import { openDialog } from "src/actions/ratingQuestions";
import { IoIosStarHalf } from "react-icons/io";

const RatingQuestionsHeader = () => {
  const dispatch = useDispatch();

  const openRatingQuestions = useSelector(
    (state) => state.ratingQuestions.openDialog
  );

  return (
    <>
      <Helmet>
        <title>Rating Questions | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Rating Questions
              </Typography>
            }
            avatar={<IoIosStarHalf size={20} />}
            action={
              <RolePermission permission="52-01">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => dispatch(openDialog(openRatingQuestions))}
                >
                  Create
                </Button>
              </RolePermission>
            }
          />
          {openRatingQuestions ? <RatingQuestionsForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default RatingQuestionsHeader;
