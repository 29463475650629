import OrderService from "src/api/giftServices/orders.service";
import helper from "src/utils/helper";
import {
  GET_ALL_ORDERS,
  GET_BY_ID_ORDERS,
  OPEN_COD_DIALOG,
  RELOAD_ORDERS,
  SAVE_ORDERS_PAGE_NO,
  SAVE_ORDERS_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";

export const reloadOrder = () => (dispatch) => {
  dispatch({
    type: RELOAD_ORDERS,
  });
};

export const openCODdialog = () => (dispatch) => {
  dispatch({
    type: OPEN_COD_DIALOG,
  });
};

export const saveOrderPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_ORDERS_PAGE_NO,
    payload: data,
  });
};

export const saveOrderSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_ORDERS_SEARCH_BODY,
    payload: data,
  });
};

// export const deleteOrder = (id, pageNo, searchBody) => async (dispatch) => {
//   try {
//     const res = await OrderService.deleteOrder(id);
//     dispatch(showSuccessSnackbar("Gift category deleted successfully!"));
//     dispatch(getAllOrder(pageNo, searchBody));
//     return Promise.resolve(res.data);
//   } catch (err) {
//     if (err.message.includes(401)) helper.logout();
//     if (err.response.status === 401)
//       dispatch(showErrorSnackbar("Token expire"));
//     else if (err.response.status === 400)
//       dispatch(showErrorSnackbar(err.response.data.message));
//     else if (err.response.status === 500)
//       dispatch(showErrorSnackbar(err.response.data.message));
//     else if (err.response.status === 403)
//       dispatch(
//         openAlertDialog("You do not have role permission for this action")
//       );
//     else dispatch(showErrorSnackbar("Network error"));
//   }
// };

export const getOrderById = (id, openDialog) => async (dispatch) => {
  try {
    dispatch(reloadOrder());
    const res = await OrderService.getOrderById(id);
    dispatch({
      type: GET_BY_ID_ORDERS,
      payload: res.data.data,
    });
    // if (openDialog) dispatch(openOrderDialog());
    dispatch(reloadOrder());
    // return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(reloadOrder());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllOrders = (pageNO, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadOrder());
    const res = await OrderService.getOrderAll(pageNO, searchBody);
    dispatch({
      type: GET_ALL_ORDERS,
      payload: res.data.data,
    });
    dispatch(reloadOrder());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadOrder());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateOrder =
  (id, data, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await OrderService.updateOrder(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllOrders(pageNo, searchBody));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getOrderStatusHistories = (id) => async (dispatch) => {
  try {
    const res = await OrderService.getOrderStatusHistories(id);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateOrderStatus = (id, status) => async (dispatch) => {
  try {
    dispatch(reloadOrder());
    const res = await OrderService.updateOrderStatus(id, status);
    dispatch(showSuccessSnackbar("Status Changed successfully!"));
    dispatch(reloadOrder());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadOrder());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const cancelOrder =
  (id, isGetAll, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await OrderService.cancelOrder(id);
      dispatch(showSuccessSnackbar("Order canceled successfully!"));
      if (isGetAll) {
        dispatch(getAllOrders(pageNo, searchBody));
      } else {
        dispatch(getOrderById(id));
      }
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const cancelOrderItem = (id, orderId) => async (dispatch) => {
  try {
    const res = await OrderService.cancelOrderItem(id);
    dispatch(showSuccessSnackbar("Order item canceled successfully!"));
    dispatch(getOrderById(orderId));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const refundOrder = (id) => async (dispatch) => {
  try {
    const res = await OrderService.refundOrder(id);
    dispatch(showSuccessSnackbar("Order refunded successfully!"));
    dispatch(getOrderById(id));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const refundOrderItem = (id, orderId) => async (dispatch) => {
  try {
    const res = await OrderService.refundOrderItem(id);
    dispatch(showSuccessSnackbar("Order item refunded successfully!"));
    dispatch(getOrderById(orderId));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateRemainingCODAmount = (id, amount) => async (dispatch) => {
  try {
    const res = await OrderService.updateRemainingCODAmount(id, amount);
    dispatch(showSuccessSnackbar("Remaining COD amount paid successfully!"));
    dispatch(getOrderById(id));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateLockerNumber = (id, amount) => async (dispatch) => {
  try {
    const res = await OrderService.updateLockerNumber(id, amount);
    dispatch(showSuccessSnackbar("Locker updated successfully!"));
    dispatch(getOrderById(id));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateEmployeeNotes = (id, employeeNote) => async (dispatch) => {
  try {
    const res = await OrderService.updateEmployeeNotes(id, employeeNote);
    dispatch(showSuccessSnackbar("Note updated successfully!"));
    dispatch(getOrderById(id));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
