import axiosService from "src/api/axiosService";

class DepartmentEmployee {
  createDepartmentEmployee(data) {
    return axiosService.post(`/department-service/api/departmentemployee`, data);
  }

  getDepartmentEmployee(departmentId) {
    return axiosService.get(`/department-service/api/departmentemployee/${departmentId}`);
  }

  deleteDepartmentEmployee(departmentId, employeeId) {
    return axiosService.delete(`/department-service/api/departmentemployee/${departmentId}/${employeeId}`);
  } 

}

export default new DepartmentEmployee();
