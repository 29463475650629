import { Grid, Box } from "@mui/material";
import React from "react";
import CustomerPromosTable from "./home/table";
import CustomerPromosHeader from "./home/header/header";

const CustomerPromos = () => {
  return (
    <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12}>
          <CustomerPromosHeader />
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: 2 }}>
          <CustomerPromosTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerPromos;
