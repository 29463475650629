import { forwardRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import CustomizedSnackbars from "./Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { closeAlertDialog, closeSnackbar } from "src/actions/snackbarMessages";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import CustomSpinner from "./CustomSpinner";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64,
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DashboardLayout = () => {
  const dispatch = useDispatch();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const snackbarMessage = useSelector((state) => state.snackbarMessages);

  const _UserInfoLoading = useSelector((state) => state.users.userInfoReload);
  const _UserInfoData = useSelector((state) => state.users.userInfo);

  return !_UserInfoLoading && _UserInfoData.id ? (
    <DashboardLayoutRoot>
      <CustomizedSnackbars
        open={snackbarMessage.show}
        type={snackbarMessage.type}
        message={snackbarMessage.message}
        onClose={() => dispatch(closeSnackbar())}
      />
      <Dialog
        open={snackbarMessage.dialog}
        onClose={() => dispatch(closeAlertDialog())}
        TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle align="center" id="responsive-dialog-title">
          <Stack direction="row" width="100%">
            <Typography sx={{ fontSize: 25 }} width={"100%"}>
              Alert !
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => dispatch(closeAlertDialog())}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <IoMdClose />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider sx={{ marginX: 2 }} />
        <DialogContent>
          <Stack justifyContent="center" alignItems="center">
            {/* <div class="blob"></div> */}
            <DialogContentText sx={{ fontSize: 20 }}>
              You do not have permissions for this action
            </DialogContentText>
          </Stack>
        </DialogContent>
      </Dialog>
      <DashboardNavbar
        onMobileNavOpen={() => {
          setMobileNavOpen(true);
        }}
      />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  ) : (
    <Stack
      width={"100%"}
      height={"100%"}
      justifyContent="center"
      alignItems="center"
    >
      <CustomSpinner />
    </Stack>
  );
};

export default DashboardLayout;
