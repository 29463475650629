import axios from "../axios";

class OrderService {
  getAll() {
    return axios.get(`/address/all`);
  }

  addCity(data) {
    return axios.post("/address/add", data);
  }

  updateCity(data) {
    return axios.put(`/address/update`, data);
  }

  deleteCity(id) {
    return axios.delete(`/address?id=${id}`);
  }


}

export default new OrderService()