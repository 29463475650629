import {
  RESET_WISH_CARD_FORM,
  SAVE_WISH_CARD_SEARCH_BODY,
  SAVE_WISH_CARD_PAGE_NO,
  RELOAD_WISH_CARD,
  GET_BY_ID_WISH_CARD,
  OPEN_WISH_CARD_DIALOG,
  GET_ALL_WISH_CARD,
  RESET_WRAPPING_FORM,
  SAVE_WRAPPING_SEARCH_BODY,
  SAVE_WRAPPING_PAGE_NO,
  RELOAD_WRAPPING,
  GET_BY_ID_WRAPPING,
  OPEN_WRAPPING_DIALOG,
  GET_ALL_WRAPPING,
} from "src/actions/gifts/giftTypes";

const initialState = {
  wrappingReload: false,
  wishCardReload: false,
  wrappingDialog: false,
  wishCardDialog: false,
  wishCardPageNo: 0,
  wrappingPageNo: 0,
  wrappingForm: {
    id: null,
    isActive: true,
    attachmentId: null,
    descriptionEn: "",
    descriptionAr: "",
  },
  wishCardForm: {
    id: null,
    isActive: true,
    attachmentId: null,
    descriptionEn: "",
    descriptionAr: "",
  },
  wrappingData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  wishCardData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  wishCardSearchBody: {
    pageSize: 15,
    description: "",
  },
  wrappingSearchBody: {
    pageSize: 15,
    description: "",
  },
};

function wrappingReducer(wrapping = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_WRAPPING:
      wrapping.wrappingData.data = payload.data;
      wrapping.wrappingData.total = payload.pageCount;
      wrapping.wrappingData.rowCount = payload.rowCount;
      return wrapping;

    case GET_BY_ID_WRAPPING:
      wrapping.wrappingForm.id = payload.id;
      wrapping.wrappingForm.attachmentId = payload.attachment;
      wrapping.wrappingForm.isActive = payload.isActive;
      wrapping.wrappingForm.descriptionAr = payload.nameEn;
      wrapping.wrappingForm.descriptionEn = payload.nameAr;
      return wrapping;

    case RESET_WRAPPING_FORM:
      wrapping.wrappingForm.id = null;
      wrapping.wrappingForm.attachmentId = null;
      wrapping.wrappingForm.isActive = true;
      wrapping.wrappingForm.descriptionAr = "";
      wrapping.wrappingForm.descriptionEn = "";
      return wrapping;

    case SAVE_WRAPPING_PAGE_NO:
      return { ...wrapping, wrappingPageNo: payload };

    case OPEN_WRAPPING_DIALOG:
      return {
        ...wrapping,
        wrappingDialog: !wrapping.wrappingDialog,
      };

    case SAVE_WRAPPING_SEARCH_BODY:
      return { ...wrapping, wrappingSearchBody: payload };

    case RELOAD_WRAPPING:
      return { ...wrapping, wrappingReload: !wrapping.wrappingReload };

    // ------------------------ Wish Card ------------------------ //

    case GET_ALL_WISH_CARD:
      wrapping.wishCardData.data = payload.data;
      wrapping.wishCardData.total = payload.pageCount;
      wrapping.wishCardData.rowCount = payload.rowCount;
      return wrapping;

    case GET_BY_ID_WISH_CARD:
      wrapping.wishCardForm.id = payload.id;
      wrapping.wishCardForm.attachmentId = payload.attachment;
      wrapping.wishCardForm.isActive = payload.isActive;
      wrapping.wishCardForm.descriptionAr = payload.nameEn;
      wrapping.wishCardForm.descriptionEn = payload.nameAr;
      return wrapping;

    case RESET_WISH_CARD_FORM:
      wrapping.wishCardForm.id = null;
      wrapping.wishCardForm.attachmentId = null;
      wrapping.wishCardForm.isActive = true;
      wrapping.wishCardForm.descriptionAr = "";
      wrapping.wishCardForm.descriptionEn = "";
      return wrapping;

    case SAVE_WISH_CARD_PAGE_NO:
      return { ...wrapping, wishCardPageNo: payload };

    case OPEN_WISH_CARD_DIALOG:
      return {
        ...wrapping,
        wishCardDialog: !wrapping.wishCardDialog,
      };

    case SAVE_WISH_CARD_SEARCH_BODY:
      return { ...wrapping, wishCardSearchBody: payload };

    case RELOAD_WISH_CARD:
      return { ...wrapping, wishCardReload: !wrapping.wishCardReload };

    default:
      return wrapping;
  }
}

export default wrappingReducer;
