import {
  CHANGE_VIEW_TYPE,
  GET_ALL_GIFTS_ITEM,
  GET_BY_ID_GIFTS_ITEM,
  GET_BY_ID_GIFTS_ITEM_UPDATE_AFTER_ORDER,
  GET_GIFTS_ITEM_VARIANTS,
  GET_PRODUCT_INFO,
  GIFT_ITEM_FILTER_DIALOG,
  GIFT_ITEM_PRINT_SKU,
  GIFT_ITEM_SORT_DIALOG,
  OPEN_BULK_UPLOAD_DIALOG,
  OPEN_GIFTS_ITEM_DIALOG,
  OPEN_PRODUCT_INFO_DIALOG,
  OPEN_UPDATE_MULTI_FILTER_COLLECTION_DIALOG,
  RELOAD_GIFTS_ITEM,
  RELOAD_UPDATE_GIFTS_ITEM,
  RESET_BULK_UPLOAD_FORM,
  RESET_GIFTS_ITEM_AFTER_ORDER_FORM,
  RESET_GIFTS_ITEM_FORM,
  SAVE_GIFTS_ITEM_PAGE_NO,
  SAVE_GIFTS_ITEM_SEARCH_BODY,
  SAVE_SCROLL_POSITION,
  SELECTED_FILTER_COLLECTION_DATA,
} from "src/actions/gifts/giftTypes";

const initialState = {
  viewType: localStorage.getItem("giftItemsViewType") || "list",
  reload: false,
  updateReload: false,
  openDialog: false,
  openProductInfoDialog: false,
  saveScrollPosition: 0,
  addForm: {
    id: null,
    giftSupplierId: null,
    giftBrandId: null,
    nameAr: "",
    nameEn: "",
    nameKu: "",
    descriptionAr: "",
    descriptionEn: "",
    descriptionKu: "",
    costPrice: "",
    sellPrice: "",
    discountPrice: "",
    giftItemAttachment: [],
    giftItemCategoryIds: [],
    giftItemCategories: [],
    filters: [],
    giftItemOption: [],
    original: false,
    localExpress: false,
  },
  updateForm: {
    id: null,
    giftSupplierId: null,
    giftBrandId: null,
    isActive: true,
    nameAr: "",
    nameEn: "",
    nameKu: "",
    descriptionAr: "",
    descriptionEn: "",
    descriptionKu: "",
    costPrice: "",
    sellPrice: "",
    discountPrice: "",
    giftItemAttachment: [],
    giftItemAttachments: [],
    giftItemCategoryIds: [],
    giftItemCategories: [],
    filters: [],
    giftItemOption: [],
    groupedOptions: {},
    original: false,
    localExpress: false,
  },
  data: {
    data: [],
    total: 0,
    rowCount: 0,
    costPrice: 0,
    discountPrice: 0,
    sellPrice: 0,
  },
  giftItemsList: [],
  searchBody: {
    pageSize: 16,
    name: "",
    giftBrandId: null,
    giftSupplierId: null,
  },
  ItemVariants: [],
  saveGiftItemsPageNum: 1,
  editItemAfterOrder: {
    id: null,
    giftItemCategoryIds: [],
    giftItemCategories: [],
    options: [],
    isActive: true,
    costPrice: 0,
    discountPrice: 0,
    sellPrice: 0,
    original: false,
    localExpress: false,
  },
  getProductInfo: {},
  openBulkUploadDialog: false,
  bulkUploadForm: {
    supplierId: null,
    file: null,
  },
  updateMultiFilterCollection: {
    dialog: false,
    selectedFilterCollection: [],
    form: {
      giftItemIds: [],
      filters: [],
    },
  },
  filter: {
    dialog: false,
    filterIds: [],
  },
  sort: {
    dialog: false,
    sort: {
      fromCostPrice: "",
      toCostPrice: "",
      fromSellPrice: "",
      toSellPrice: "",
      fromDiscountPrice: "",
      toDiscountPrice: "",
      SortByPrice: null,
    },
  },
  printSkuForm: {
    dialog: false,
    data: [],
  },
};

function giftItemsReducer(giftItems = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_GIFTS_ITEM:
      // if (!localStorage.getItem("priceRange")) {
      //   let priceRange = {
      //     fromCostPrice: payload.costPrice.min,
      //     toCostPrice: payload.costPrice.max,
      //     fromSellPrice: payload.sellPrice.min,
      //     toSellPrice: payload.sellPrice.max,
      //     fromDiscountPrice: payload.discountPrice.min,
      //     toDiscountPrice: payload.discountPrice.max,
      //   };
      //   localStorage.setItem("priceRange", JSON.stringify(priceRange));
      // }

      giftItems.data.data = payload.data;
      giftItems.data.total = payload.pageCount;
      giftItems.data.rowCount = payload.rowCount;
      giftItems.data.costPrice = payload.costPrice;
      giftItems.data.discountPrice = payload.discountPrice;
      giftItems.data.sellPrice = payload.sellPrice;
      return giftItems;

    case GET_GIFTS_ITEM_VARIANTS:
      giftItems.ItemVariants = payload;
      return giftItems;

    case GET_BY_ID_GIFTS_ITEM:
      const flattened = payload.filters
        ?.map((x) => x.filterCollections?.map((y) => y.values.map((z) => z.id)))
        ?.flat()
        ?.flat();
      const uniqueAndNonNull = Array.from(
        new Set(flattened.filter((item) => item !== null))
      );
      giftItems.updateForm.id = payload.id;
      giftItems.updateForm.giftSupplierId = payload.giftSupplier?.id;
      giftItems.updateForm.giftBrandId = payload.giftBrand?.id;
      giftItems.updateForm.isActive = payload.isActive;
      giftItems.updateForm.nameAr = payload.nameAr;
      giftItems.updateForm.nameEn = payload.nameEn;
      giftItems.updateForm.nameKu = payload.nameKu;
      giftItems.updateForm.descriptionAr = payload.descriptionAr;
      giftItems.updateForm.descriptionEn = payload.descriptionEn;
      giftItems.updateForm.descriptionKu = payload.descriptionKu;
      giftItems.updateForm.costPrice = payload.costPrice;
      giftItems.updateForm.sellPrice = payload.sellPrice;
      giftItems.updateForm.localExpress = payload.localExpress;
      giftItems.updateForm.original = payload.original;
      giftItems.updateForm.discountPrice = payload.discountPrice ?? "";
      giftItems.updateForm.giftItemCategoryIds =
        payload.giftItemCategories?.map((x) => x?.id);
      giftItems.updateForm.giftItemCategories = payload.giftItemCategories;
      giftItems.updateForm.filters = uniqueAndNonNull;
      giftItems.updateForm.giftItemAttachments = payload.giftItemAttachments;
      giftItems.updateForm.giftItemOption = payload.giftItemOptions?.map(
        (x) => {
          return {
            ...x,
            optionId: x.options?.id,
            hasAttachment: x.attachments?.length > 0 ? true : false,
          };
        }
      );
      giftItems.updateForm.groupedOptions = payload.giftItemOptions?.reduce(
        (acc, option) => {
          const key = option.options.id;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(option);
          return acc;
        },
        {}
      );
      return giftItems;

    case OPEN_GIFTS_ITEM_DIALOG:
      return { ...giftItems, openDialog: !giftItems.openDialog };

    case SAVE_GIFTS_ITEM_PAGE_NO:
      return { ...giftItems, saveGiftItemsPageNum: payload };

    case RELOAD_GIFTS_ITEM:
      return { ...giftItems, reload: !giftItems.reload };

    case RELOAD_UPDATE_GIFTS_ITEM:
      return { ...giftItems, updateReload: !giftItems.updateReload };

    case SAVE_GIFTS_ITEM_SEARCH_BODY:
      return { ...giftItems, searchBody: payload };

    case RESET_GIFTS_ITEM_FORM:
      giftItems.addForm.id = null;
      giftItems.addForm.nameEn = "";
      giftItems.addForm.nameAr = "";
      giftItems.addForm.nameKu = "";
      giftItems.addForm.descriptionAr = "";
      giftItems.addForm.descriptionEn = "";
      giftItems.addForm.descriptionKu = "";
      giftItems.addForm.costPrice = "";
      giftItems.addForm.sellPrice = "";
      giftItems.addForm.discountPrice = "";
      giftItems.addForm.giftItemCategoryIds = [];
      giftItems.addForm.giftItemAttachment = "";
      giftItems.addForm.giftItemOption = "";
      giftItems.addForm.giftSupplierId = null;
      giftItems.addForm.giftBrandId = null;
      giftItems.addForm.filters = [];
      giftItems.addForm.giftItemCategories = [];
      giftItems.addForm.original = false;
      giftItems.addForm.localExpress = false;
      return giftItems;

    case GET_BY_ID_GIFTS_ITEM_UPDATE_AFTER_ORDER:
      const flattenedAfterOrder = payload.filters
        ?.map((x) => x.filterCollections?.map((y) => y.values.map((z) => z.id)))
        ?.flat()
        ?.flat();
      const uniqueAndNonNullAfterOrder = Array.from(
        new Set(flattenedAfterOrder.filter((item) => item !== null))
      );

      giftItems.editItemAfterOrder.id = payload.id;
      giftItems.editItemAfterOrder.isActive = payload.isActive;
      giftItems.editItemAfterOrder.costPrice = payload.costPrice;
      giftItems.editItemAfterOrder.sellPrice = payload.sellPrice;
      giftItems.editItemAfterOrder.localExpress = payload.localExpress;
      giftItems.editItemAfterOrder.original = payload.original;
      giftItems.editItemAfterOrder.discountPrice = payload.discountPrice;
      giftItems.editItemAfterOrder.giftItemCategoryIds =
        payload.giftItemCategories?.map((x) => x?.id);
      giftItems.editItemAfterOrder.giftItemCategories =
        payload.giftItemCategories;
      giftItems.editItemAfterOrder.filters = uniqueAndNonNullAfterOrder;
      giftItems.editItemAfterOrder.options = payload.giftItemOptions?.map(
        (x) => {
          return {
            isActive: x.isActive,
            optionItemId: x.id,
            nameEn: x.nameEn,
          };
        }
      );
      return giftItems;

    case RESET_GIFTS_ITEM_AFTER_ORDER_FORM:
      giftItems.editItemAfterOrder.id = null;
      giftItems.editItemAfterOrder.isActive = true;
      giftItems.editItemAfterOrder.giftItemCategoryIds = [];
      giftItems.editItemAfterOrder.options = [];
      giftItems.editItemAfterOrder.original = false;
      giftItems.editItemAfterOrder.localExpress = false;
      return giftItems;

    case OPEN_PRODUCT_INFO_DIALOG:
      return {
        ...giftItems,
        openProductInfoDialog: !giftItems.openProductInfoDialog,
      };

    case GET_PRODUCT_INFO:
      giftItems.getProductInfo = {
        ...payload,
        groupedOptions: payload.giftItemOptions?.reduce((acc, option) => {
          const key = option.options.id;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(option);
          return acc;
        }, {}),
      };
      return giftItems;

    case RESET_BULK_UPLOAD_FORM:
      giftItems.bulkUploadForm.supplierId = null;
      giftItems.bulkUploadForm.file = null;
      return giftItems;

    case OPEN_BULK_UPLOAD_DIALOG:
      return {
        ...giftItems,
        openBulkUploadDialog: !giftItems.openBulkUploadDialog,
      };

    case OPEN_UPDATE_MULTI_FILTER_COLLECTION_DIALOG:
      return {
        ...giftItems,
        updateMultiFilterCollection: {
          ...giftItems.updateMultiFilterCollection,
          dialog: !giftItems.updateMultiFilterCollection.dialog,
        },
      };

    case SELECTED_FILTER_COLLECTION_DATA:
      return {
        ...giftItems,
        updateMultiFilterCollection: {
          ...giftItems.updateMultiFilterCollection,
          selectedFilterCollection: payload,
        },
      };

    case GIFT_ITEM_FILTER_DIALOG:
      return {
        ...giftItems,
        filter: {
          ...giftItems.filter,
          dialog: !giftItems.filter.dialog,
        },
      };

    case GIFT_ITEM_SORT_DIALOG:
      return {
        ...giftItems,
        sort: {
          ...giftItems.sort,
          dialog: !giftItems.sort.dialog,
        },
      };

    case SAVE_SCROLL_POSITION:
      return { ...giftItems, saveScrollPosition: payload };

    case GIFT_ITEM_PRINT_SKU:
      return {
        ...giftItems,
        printSkuForm: {
          dialog: !giftItems.printSkuForm.dialog,
          data: payload,
        },
      };

    case CHANGE_VIEW_TYPE:
      localStorage.setItem("giftItemsViewType", payload);
      return { ...giftItems, viewType: payload };

    default:
      return giftItems;
  }
}

export default giftItemsReducer;
