import { RELOAD_ATTACHMENTS } from "./type";

import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import uploadAttachmentsService from "src/api/services/uploadAttachments.service";
import { reloadUpdateGiftItem } from "src/actions/gifts/giftItem";

export const reload = () => (dispatch) => {
  dispatch({
    type: RELOAD_ATTACHMENTS,
  });
};

export const uploadAttachments = (data) => async (dispatch) => {
  try {
    dispatch(reload());
    const res = await uploadAttachmentsService.uploadAttachments(data);
    dispatch(showSuccessSnackbar("Uploaded successfully"));
    dispatch(reload());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reload());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const uploadMultiAttachments = (data) => async (dispatch) => {
  try {
    dispatch(reload());
    dispatch(reloadUpdateGiftItem());
    const res = await uploadAttachmentsService.uploadMultiAttachments(data);
    dispatch(showSuccessSnackbar("Uploaded successfully"));
    dispatch(reloadUpdateGiftItem());
    dispatch(reload());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reload());
    dispatch(reloadUpdateGiftItem());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
