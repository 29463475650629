import { useEffect } from "react";
import {
  Card,
  CardContent,
  colors,
  useTheme,
  CardHeader,
  Divider,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getTicketSubmitted } from "src/actions/ticket/statistics";
import CustomSpinner from "src/components/CustomSpinner";
import { Doughnut } from "react-chartjs-2";

const TicketSubmitted = () => {
  const dispatch = useDispatch();

  const theme = useTheme();

  const submittedData = useSelector((state) => state.ticketStatistic.submitted);

  const handleSubmitted = () => {
    // const data = Obj
    // var total = submittedData.map((row) => submittedData.total);
    // var solved = submittedData.map((row) => submittedData.solved);
    // var _new = submittedData.map((row) => submittedData.new);
    // var inProgress = submittedData.map((row) => submittedData.inProgress);
    // var canceled = submittedData.map((row) => submittedData.canceled);
    // submittedData.map((x) => {
    //   setSubmmited({
    //     total: total,
    //     solved: solved,
    //     canceled: canceled,
    //     inProgress: inProgress,
    //     new: _new,
    //   })
    // })
  };

  useEffect(() => {
    dispatch(getTicketSubmitted());
  }, []);

  useEffect(() => {
    handleSubmitted();
  }, [submittedData]);

  const data = {
    datasets: [
      {
        data: Object.values(submittedData),
        backgroundColor: [
          colors.blue[500],
          colors.yellow[600],
          colors.green[200],
          colors.red[900],
          colors.blueGrey[200],
          colors.orange[500],
          colors.green[600],
        ],
        borderWidth: 2,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: Object.keys(submittedData),
  };

  const options = {
    animation: true,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  return !submittedData ? (
    <CustomSpinner />
  ) : (
    <Card>
      <CardHeader title="Submitted Tickets" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: "relative",
          }}
        >
          <Doughnut data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default TicketSubmitted;
