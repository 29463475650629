import { useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Autocomplete,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { Controller, useForm } from "react-hook-form";
import {
  UpdateGeneralSettings,
  addGeneralSetting,
  openGeneralSettingDialog,
  resetForm,
} from "src/actions/generalSetting";
import { GENERAL_SETTINGS_TYPES } from "src/utils/common";

const SettingsForm = () => {
  const _GeneralSetting = useSelector((state) => state.generalSetting);
  const searchFilter = useSelector((state) => state.generalSetting.searchBody);

  const isAdd = _GeneralSetting.form.id ? false : true;
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _GeneralSetting.form,
  });

  useEffect(() => {
    reset({ ..._GeneralSetting.form });
  }, [_GeneralSetting.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(addGeneralSetting(data, 1, searchFilter));
    } else {
      dispatch(UpdateGeneralSettings(data, 1, searchFilter));
    }
  };

  const handleDialog = () => {
    dispatch(openGeneralSettingDialog(_GeneralSetting.openDialog));
  };

  return (
    <CustomDialog
      title={isAdd ? "ADD GENERAL SETTINGS" : "UPDATE GENERAL SETTINGS"}
      isOpen={_GeneralSetting.openDialog}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {isAdd && (
            <Grid item xs={12} md={12}>
              <Controller
                name="key"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Key"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Controller
              name="value"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Value"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} md={12}>
            <Controller
              name="metaData"
              control={control}
              render={({ field }) => (
                <TextField size="small" label="Metadata" fullWidth {...field} />
              )}
            />
          </Grid> */}
          {isAdd && (
            <Grid item xs={12} md={12}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    id="type"
                    options={GENERAL_SETTINGS_TYPES}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField required {...params} label="Type" />
                    )}
                    onChange={(event, value) => {
                      setValue("type", value ? value.id : null);
                    }}
                    value={
                      GENERAL_SETTINGS_TYPES.filter(
                        (x) => x.id === field.value
                      )[0] ?? null
                    }
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={field.value}
                      onChange={(e) => {
                        setValue("isActive", e.target.checked);
                      }}
                    />
                  }
                  label="Is Active"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button type="submit" variant="contained" color="primary">
              {isAdd ? "Create" : "Update"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default SettingsForm;
