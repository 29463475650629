import {
  CREATE_PROMO,
  UPDATE_PROMO,
  GET_ALL_PROMOS,
  DELETE_PROMO,
  CHANGE_PROMO_STATUS,
  RESET_FORM_PROMO,
  GET_BY_ID_PROMO,
  OPEN_PROMO_DIALOG,
  RELOAD_PROMO,
  SAVE_PROMO_SAVE_NO,
  SAVE_PROMO_SEARCH_BODY,
} from "./type";

import PromoDataService from "../api/services/promo.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const createPromo = (data, pagNo, pageSize) => async (dispatch) => {
  try {
    const res = await PromoDataService.create(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(getAllPromos(pagNo, pageSize));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllPromos = (pageNo, pageSize) => async (dispatch) => {
  try {
    dispatch(reloadPromos());
    const res = await PromoDataService.getAll(pageNo, pageSize);
    dispatch({
      type: GET_ALL_PROMOS,
      payload: res.data.data,
    });
    dispatch(reloadPromos());
  } catch (err) {
    dispatch(reloadPromos());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const openDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_PROMO_DIALOG,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: RESET_FORM_PROMO,
  });
};

export const reloadPromos = () => (dispatch) => {
  dispatch({
    type: RELOAD_PROMO,
  });
};

export const savePromoPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_PROMO_SAVE_NO,
    payload: data,
  });
};

export const savePromoSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_PROMO_SEARCH_BODY,
    payload: data,
  });
};

export const updatePromo = (data, pagNo, pageSize) => async (dispatch) => {
  try {
    const res = await PromoDataService.update(data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    dispatch(getAllPromos(pagNo, pageSize));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deletePromo = (id, pagNo, pageSize) => async (dispatch) => {
  try {
    const res = await PromoDataService.delete(id);
    dispatch(showSuccessSnackbar("Promo Code deleted successfully!"));
    dispatch(getAllPromos(pagNo, pageSize));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadPromos());
    const res = await PromoDataService.getById(id);
    dispatch({
      type: GET_BY_ID_PROMO,
      payload: res.data.data,
    });
    dispatch(reloadPromos());
  } catch (err) {
    dispatch(reloadPromos());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(reloadPromos());
    const res = await PromoDataService.getById(id);
    dispatch({
      type: GET_BY_ID_PROMO,
      payload: res.data.data,
    });
    dispatch(reloadPromos());
    dispatch(openDialog());
  } catch (err) {
    dispatch(reloadPromos());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const changeStatus =
  (id, status, pagNo, pageSize) => async (dispatch) => {
    try {
      const res = await PromoDataService.changeStatus(id, status);
      dispatch(showSuccessSnackbar("Updated promo status successfully!"));
      dispatch(getAllPromos(pagNo, pageSize));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };
