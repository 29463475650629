import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Stack,
  FormControlLabel,
  Switch,
  Autocomplete,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { Controller, useForm } from "react-hook-form";
import {
  createShippingConfig,
  openShippingConfigsDialog,
  resetForm,
  saveShippingConfigsPageNum,
  updateShippingConfig,
} from "src/actions/shippingConfig";

export default function ShippingConfigsForm() {
  const saveSCPageNum = useSelector(
    (state) => state.shippingConfig.saveSCPageNum
  );
  const _ShippingConfigs = useSelector((state) => state.shippingConfig);
  const loading = useSelector((state) => state.shippingConfig.loading);

  const [page, setPage] = useState(saveSCPageNum ? saveSCPageNum : 0);

  const isAdd = _ShippingConfigs.form.id ? false : true;
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _ShippingConfigs.form,
  });

  useEffect(() => {
    reset({ ..._ShippingConfigs.form });
  }, [_ShippingConfigs.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(saveShippingConfigsPageNum(page));
  }, [page]);

  const handleDialog = () => {
    dispatch(openShippingConfigsDialog(_ShippingConfigs.openDialog));
  };

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(createShippingConfig(data, saveSCPageNum));
    } else {
      dispatch(updateShippingConfig(data, saveSCPageNum));
    }
  };

  const type = [
    { id: 0, name: "Pound Price US" },
    { id: 1, name: "Pound Price TR" },
    { id: 2, name: "Delivery Baghdad" },
    { id: 3, name: "Delivery Other" },
    { id: 4, name: "Custom Fees" },
    { id: 5, name: "Orderii Fees" },
    { id: 6, name: "Dangerous Goods Fees" },
    { id: 7, name: "Switch Ratio" },
    { id: 8, name: "Insurance Fee" },
    { id: 9, name: "Vip Pound Price US" },
    { id: 10, name: "Vip Pound Price TR" },
    { id: 11, name: "Vip Fee US" },
    { id: 12, name: "Vip Fee TR" },
    { id: 13, name: "Pound Price EU" },
    { id: 14, name: "Pound Price AG" },
    { id: 15, name: "Vip pound Price EU" },
    { id: 16, name: "Vip pound Price AG" },
    { id: 17, name: "Vip Tax EU" },
    { id: 18, name: "Vip Tax AG" },
    { id: 19, name: "Pound Price WSM" },
    { id: 20, name: "Vip Pound Price WSM" },
    { id: 21, name: "Vip Tax WSM" },
  ];

  return (
    <Box>
      <CustomDialog
        isOpen={_ShippingConfigs.openDialog}
        handleClose={handleDialog}
        title={
          isAdd
            ? "CREATE SHIPPING CONFIGURATION"
            : "UPDATE SHIPPING CONFIGURATION"
        }
        maxWidth="sm"
        loading={loading}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    disabled
                    options={type}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Type" />
                    )}
                    onChange={(event, value) => {
                      setValue("type", value ? value.name : null);
                    }}
                    value={
                      type.filter((x) => x.name == field.value)[0]
                        ? type.filter((x) => x.name == field.value)[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="value"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="value"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={6}
                    size="small"
                    label="Notes"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="isPercentage"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("isPercentage", e.target.checked);
                        }}
                      />
                    }
                    label="is Percentage !"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Button type="submit" variant="contained" color="primary">
                  {isAdd ? "Create" : "Update"}
                </Button>
                {isAdd ? (
                  <Button
                    onClick={() => {
                      reset({ ..._ShippingConfigs.form });
                      dispatch(resetForm());
                    }}
                    variant="contained"
                    color="warning"
                  >
                    Clear Text
                  </Button>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        </form>
      </CustomDialog>
    </Box>
  );
}
