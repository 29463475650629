import axios from "../axios";

class MobileAppVersionService {
  getAll(pageNo) {
    return axios.get(`/mobileappversion/all/${pageNo}`);
  }

  getById(id) {
    return axios.get(`/mobileappversion/${id}`);
  }

  create(data) {
    return axios.post("/mobileappversion", data);
  }

  update(data) {
    return axios.put(`/mobileappversion`, data);
  }

  delete(id) {
    return axios.delete(`/mobileappversion/${id}`);
  }

}
export default new MobileAppVersionService()