import { TextField, Grid, Stack, Button, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import {
  createVendorCategory,
  openVendorsCategoryDialog,
  resetVendorsCategoryForm,
  updateVendorsCategory,
} from "src/actions/vendors";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { uploadAttachments } from "src/actions/uploadAttachments";
import { showErrorSnackbar } from "src/actions/snackbarMessages";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const VendorsCategoryForm = () => {
  const dispatch = useDispatch();

  const vendorsCategory = useSelector((state) => state.vendorsCategory);
  const loading = useSelector((state) => state.vendorsCategory.reload);
  const searchBody = useSelector((state) => state.vendorsCategory.searchBody);
  const pageNo = useSelector(
    (state) => state.vendorsCategory.saveVendorsCategoryPageNum
  );
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
    setValue,
    watch,
  } = useForm({
    defaultValues: vendorsCategory.form,
  });

  const [attachmentsFiles, setAttachmentsFiles] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (attachmentsFiles.length > 0) {
      setFiles(attachmentsFiles.map((files) => files.file));
      setValue("attachmentId", attachmentsFiles.map((files) => files.file)[0], {
        shouldDirty: true,
      });
    } else {
      setValue("attachmentId", watch("attachmentId"), {
        shouldDirty: true,
      });
    }
  }, [attachmentsFiles, setValue, watch]);

  const isAdd = vendorsCategory.form.id ? false : true;

  const handleDialog = () => {
    dispatch(openVendorsCategoryDialog());
  };

  useEffect(() => {
    reset({ ...vendorsCategory.form });
  }, [reset, vendorsCategory.form]);

  useEffect(() => {
    return () => {
      dispatch(resetVendorsCategoryForm());
    };
  }, [dispatch]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("type", 1);
    if (isAdd) {
      dispatch(uploadAttachments(formData))
        .then((res) => {
          dispatch(
            createVendorCategory(
              {
                nameAr: data.nameAr,
                nameEn: data.nameEn,
                nameKu: data.nameKu,
                attachmentId: res.data.id,
              },
              {
                pageNo: pageNo + 1,
                pageSize: searchBody.pageSize,
                name: searchBody.name,
              }
            )
          ).then((x) => {
            reset({ ...vendorsCategory.form });
            dispatch(resetVendorsCategoryForm());
            handleDialog();
          });
        })
        .catch(() => dispatch(showErrorSnackbar("Network error")));
    } else {
      if (files[0]) {
        dispatch(uploadAttachments(formData))
          .then((res) => {
            dispatch(
              updateVendorsCategory(
                data.id,
                {
                  nameAr: data.nameAr,
                  nameEn: data.nameEn,
                  nameKu: data.nameKu,
                  attachmentId: res.data.id,
                },
                {
                  pageNo: pageNo + 1,
                  pageSize: searchBody.pageSize,
                  name: searchBody.name,
                }
              )
            ).then((x) => {
              reset({ ...vendorsCategory.form });
              dispatch(resetVendorsCategoryForm());
              handleDialog();
            });
          })
          .catch(() => dispatch(showErrorSnackbar("Network error")));
      } else {
        dispatch(
          updateVendorsCategory(
            data.id,
            {
              nameAr: data.nameAr,
              nameEn: data.nameEn,
              nameKu: data.nameKu,
              attachmentId: data.attachmentId,
            },
            {
              pageNo: pageNo + 1,
              pageSize: searchBody.pageSize,
              name: searchBody.name,
            }
          )
        ).then((x) => {
          reset({ ...vendorsCategory.form });
          dispatch(resetVendorsCategoryForm());
          handleDialog();
        });
      }
    }
  };

  return (
    <CustomDialog
      title={isAdd ? "CREATE VENDOR CATEGORY" : "UPDATE VENDOR CATEGORY"}
      isOpen={vendorsCategory.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="nameEn"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="nameAr"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="nameKu"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name Kurdish"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="attachmentId"
              control={control}
              render={({ field }) => (
                <FilePond
                  required={watch("attachmentId") === "" ? true : false}
                  files={attachmentsFiles}
                  onupdatefiles={setAttachmentsFiles}
                  maxFiles={10}
                  name="file"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  credits={false}
                />
              )}
            />
          </Grid>
          {!isAdd ? (
            <Grid
              item
              xs={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                component="img"
                src={vendorsCategory.form.imageUrl}
                width={300}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
              {
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => reset({ ...vendorsCategory.form })}
                >
                  Clear Text
                </Button>
              }
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};
export default VendorsCategoryForm;
