import { Grid, Box } from "@mui/material";
import React from "react";
import OrderRatingHeader from "./home/header/header";
import OrderRatingTable from "./home/table";

const OrderRating = () => {
  return (
    <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <OrderRatingHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <OrderRatingTable />
        </Grid>
      </Grid>
      <Box sx={{ paddingX: 2 }}></Box>
    </Box>
  );
};

export default OrderRating;
