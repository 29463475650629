import { Helmet } from "react-helmet";
import { Box, Stack } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import RolePermission from "src/components/RolePermission";
import { openDialog } from "src/actions/locker";
import LockerForm from "../../from/Form";
import { IoMdBasket } from "react-icons/io";
import LockerHistoryDialog from "src/pages/cross/lockers/lockerHistory";

const LockerHeader = () => {
  const dispatch = useDispatch();

  const _Locker = useSelector((state) => state.locker.openDialog);
  const lockerHistoryDialog = useSelector(
    (state) => state.locker.lockerHistoryDialog
  );

  return (
    <>
      <Helmet>
        <title>Warehouse | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Warehouse
              </Typography>
            }
            avatar={<IoMdBasket size={20} />}
            action={
              <Stack direction="row" spacing={2}>
                <RolePermission permission="71-01">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={() => dispatch(openDialog("add"))}
                  >
                    Add Range of Lockers
                  </Button>
                </RolePermission>
                <RolePermission permission="71-05">
                  <Button
                    variant="contained"
                    color="error"
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={() => dispatch(openDialog("delete"))}
                  >
                    Delete Range of Lockers
                  </Button>
                </RolePermission>
              </Stack>
            }
          />
          {_Locker ? <LockerForm /> : null}
          {lockerHistoryDialog ? <LockerHistoryDialog /> : null}
        </Card>
      </Box>
    </>
  );
};

export default LockerHeader;
