import { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  FormControlLabel,
  DialogTitle,
  Typography,
  DialogContent,
  Box,
  Switch,
  IconButton,
  Stack,
  Divider,
  Tabs,
  Tab,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  notificationUserById,
  resetNotifyByUserId,
  setNotifyByUserIdDialog,
} from "src/actions/notification";
import { LoadingButton } from "@mui/lab";
import {
  DesktopDateTimePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { week_days } from "src/utils/common";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import moment from "moment";
import {
  createScheduledAction,
  updateScheduledAction,
} from "src/actions/scheduledAction";
import { showErrorSnackbar } from "src/actions/snackbarMessages";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NotifyByUserIdForm = () => {
  const dispatch = useDispatch();

  const _NotifyByUserId = useSelector((state) => state.notification);
  const searchFilter = useSelector(
    (state) => state.scheduledActions.searchBody
  );
  const PageNo = useSelector((state) => state.notification.pageNo);
  const PageNoSchedule = useSelector((state) => state.scheduledActions.pageNo);
  const loading = useSelector((state) => state.notification.reload);
  const loadingSchedule = useSelector((state) => state.scheduledActions.reload);

  const isAdd = _NotifyByUserId.notifyByUserIdForm.id ? false : true;

  const [confirm, setConfirm] = useState(false);
  const [formData, setFormData] = useState(false);
  const [isScheduled, setIsScheduled] = useState(isAdd ? false : true);
  const [actionTimeType, setActionTimeType] = useState(
    _NotifyByUserId.notifyByUserIdForm.isOneTime ? 0 : 1
  );

  const handleChange = (event, newValue) => {
    setActionTimeType(newValue);
    setConfirm(false);
    if (newValue === 1) {
      setValue("isOneTime", false);
    } else {
      setValue("isOneTime", true);
      setValue("actionTimes", []);
    }
  };

  const {
    control,
    reset,
    handleSubmit,
    // formState: { isDirty },
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: _NotifyByUserId.notifyByUserIdForm,
  });

  // const isValidGUID = (guid) => {
  //   const guidRegex =
  //     /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  //   return guidRegex.test(guid);
  // };

  useEffect(() => {
    reset({ ..._NotifyByUserId.notifyByUserIdForm });
  }, [_NotifyByUserId.notifyByUserIdForm, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetNotifyByUserId());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTimeChange = (dayIndex, timeIndex, newValue) => {
    setValue(`actionTimes[${dayIndex}].times[${timeIndex}]`, newValue);
    setValue(`actionTimes[${dayIndex}].weekDay`, dayIndex, {
      shouldDirty: true,
    });
  };

  const handleAddTime = (dayIndex) => {
    const times = watch(`actionTimes[${dayIndex}].times`) || [];
    setValue(`actionTimes[${dayIndex}].times`, [...times, ""], {
      shouldDirty: true,
    });
  };

  const handleRemoveTime = (dayIndex, timeIndex) => {
    const times = watch(`actionTimes[${dayIndex}].times`) || [];
    times.splice(timeIndex, 1);
    setValue(`actionTimes[${dayIndex}].times`, times, {
      shouldDirty: true,
    });
  };

  const onSubmit = (data) => {
    const filteredActionTimes = data.actionTimes
      ?.filter((x) => x.times?.length > 0 && !x.times.includes(""))
      .map((x) => ({
        ...x,
        times: x.times?.map((time) => moment(time, "HH:mm").format("HH:mm")),
      }));
    if (data.isOneTime === false && data.actionTimes.every((x) => !x.checked)) {
      dispatch(showErrorSnackbar("At least one day should be selected"));
    } else if (filteredActionTimes?.length <= 0 && data.isOneTime === false) {
      dispatch(
        showErrorSnackbar("At least one time should be selected for this day")
      );
    } else {
      setConfirm(true);
      setFormData(data);
    }
  };

  const handleNotificationSubmit = (data) => {
    const filteredActionTimes = data.actionTimes
      ?.filter((x) => x.times.length > 0 && !x.times.includes(""))
      .map((x) => ({
        ...x,
        times: x.times?.map((time) => moment(time, "HH:mm").format("HH:mm")),
      }));

    if (isScheduled) {
      if (isAdd) {
        dispatch(
          createScheduledAction(
            {
              isOneTime: data.isOneTime,
              actionTimes: data.isOneTime ? null : filteredActionTimes,
              actionTime: data.isOneTime ? data.actionTime : null,
              headers: "",
              body: JSON.stringify({
                titleEn: data.titleEn,
                titleAr: data.titleAr,
                titleKU: data.titleKU,
                contentEn: data.contentEn,
                contentAr: data.contentAr,
                contentKu: data.contentKu,
                userIds: data.userIds,
              }),
              url: "notification/notifygroup",
              methodType: 1,
            },
            PageNoSchedule,
            searchFilter
          )
        )
          .then((x) => {
            if (x.message === "Success") {
              handleDialog();
            }
            setConfirm(false);
          })
          .catch((err) => {
            setConfirm(false);
          });
      } else {
        // updateScheduledAction
        dispatch(
          updateScheduledAction(
            data.id,
            {
              isOneTime: data.isOneTime,
              actionTimes: data.isOneTime ? null : filteredActionTimes,
              actionTime: data.isOneTime ? data.actionTime : null,
              headers: "",
              body: JSON.stringify({
                titleEn: data.titleEn,
                titleAr: data.titleAr,
                titleKU: data.titleKU,
                contentEn: data.contentEn,
                contentAr: data.contentAr,
                contentKu: data.contentKu,
                userIds: data.userIds,
              }),
              url: "notification/notifygroup",
              methodType: 1,
            },
            PageNoSchedule,
            searchFilter
          )
        )
          .then((x) => {
            if (x.message === "Success") {
              handleDialog();
            }
            setConfirm(false);
          })
          .catch((err) => {
            setConfirm(false);
          });
      }
    } else {
      dispatch(
        notificationUserById(
          {
            titleEn: data.titleEn,
            titleAr: data.titleAr,
            titleKU: data.titleKU,
            contentEn: data.contentEn,
            contentAr: data.contentAr,
            contentKu: data.contentKu,
            userIds: data.userIds,
          },
          PageNo,
          searchFilter
        )
      )
        .then((x) => {
          if (x.message === "Success") {
            handleDialog();
          }
          setConfirm(false);
        })
        .catch((err) => {
          setConfirm(false);
        });
    }
  };

  const handleDialog = () => {
    dispatch(setNotifyByUserIdDialog());
  };

  return (
    <Dialog
      open={_NotifyByUserId.notifyByUserIdDialog}
      keepMounted
      fullWidth={true}
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
    >
      <DialogTitle>
        <Typography variant="h4" component="div">
          {"Notify By User Ids"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid item xs={12} md={4}>
              <Controller
                name="titleEn"
                control={control}
                rules={{
                  required: { value: true, message: "Required Field" },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Title English"
                    fullWidth
                    error={!!errors.titleEn}
                    helperText={errors.titleEn ? errors.titleEn.message : ""}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="titleAr"
                control={control}
                rules={{
                  required: { value: true, message: "Required Field" },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Title Arabic"
                    fullWidth
                    error={!!errors.titleAr}
                    helperText={errors.titleAr ? errors.titleAr.message : ""}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="titleKU"
                control={control}
                rules={{
                  required: { value: true, message: "Required Field" },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Title Kurdish"
                    fullWidth
                    error={!!errors.titleKU}
                    helperText={errors.titleKU ? errors.titleKU.message : ""}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="contentEn"
                control={control}
                rules={{
                  required: { value: true, message: "Required Field" },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    multiline
                    rows={4}
                    size="small"
                    label="Content English"
                    fullWidth
                    error={!!errors.contentEn}
                    helperText={
                      errors.contentEn ? errors.contentEn.message : ""
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="contentAr"
                control={control}
                rules={{
                  required: { value: true, message: "Required Field" },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    multiline
                    rows={4}
                    size="small"
                    label="Content Arabic"
                    fullWidth
                    error={!!errors.contentAr}
                    helperText={
                      errors.contentAr ? errors.contentAr.message : ""
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="contentKu"
                control={control}
                rules={{
                  required: { value: true, message: "Required Field" },
                }}
                render={({ field }) => (
                  <TextField
                    required
                    multiline
                    rows={4}
                    size="small"
                    label="Content Kurdish"
                    fullWidth
                    error={!!errors.contentKu}
                    helperText={
                      errors.contentKu ? errors.contentKu.message : ""
                    }
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="userIds"
                control={control}
                defaultValue={[]}
                rules={{
                  required: { value: true, message: "Required Field" },
                  // validate: (value) =>
                  //   (Array.isArray(value) && value.every(isValidGUID)) ||
                  //   "Invalid GUID format. Ensure all IDs are valid GUIDs.",
                }}
                render={({ field }) => (
                  <TextField
                    required
                    multiline
                    rows={4}
                    size="small"
                    label="User IDs"
                    fullWidth
                    value={
                      Array.isArray(field.value) ? field.value.join(", ") : ""
                    }
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const formattedValue = inputValue
                        .replace(/\s+/g, " ")
                        .split(",")
                        .map((id) => id.trim())
                        .filter((id) => id !== "");
                      field.onChange(formattedValue);
                    }}
                    error={!!errors.userIds}
                    helperText={errors.userIds ? errors.userIds.message : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {isScheduled && (
              <Grid item xs={12}>
                <Tabs
                  value={actionTimeType}
                  onChange={handleChange}
                  aria-label="basic tabs"
                  variant="fullWidth"
                >
                  <Tab label="One Time" {...a11yProps(0)} />
                  <Tab label="Multi Time" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={actionTimeType} index={0}>
                  <Box>
                    <Controller
                      name={`actionTime`}
                      control={control}
                      defaultValue={null}
                      rules={{
                        required: { value: true, message: "Required Field" },
                      }}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DesktopDateTimePicker
                            required
                            inputFormat="YYYY-MM-DD / hh:mm a"
                            label="Time"
                            value={field.value}
                            onChange={(newValue) => {
                              const formattedValue = moment(newValue).format();
                              setValue(`actionTime`, formattedValue);
                            }}
                            minutesStep={15}
                            minDateTime={moment()}
                            renderInput={(params) => (
                              <TextField
                                required
                                size="small"
                                {...params}
                                fullWidth
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "YYYY-MM-DD / hh:mm a",
                                }}
                                error={!!errors.actionTime}
                                helperText={
                                  errors.actionTime
                                    ? errors.actionTime.message
                                    : ""
                                }
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value={actionTimeType} index={1}>
                  {week_days.map((day, index) => (
                    <Grid container spacing={2} my={1} key={index}>
                      <Grid item xs={12} md={3}>
                        <Controller
                          name={`actionTimes[${index}].checked`}
                          control={control}
                          render={({ field }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  {...field}
                                  checked={watch(
                                    `actionTimes[${index}].checked`
                                  )}
                                  onChange={(e) => {
                                    setValue(
                                      `actionTimes[${index}].checked`,
                                      e.target.checked,
                                      { shouldDirty: true }
                                    );
                                    if (
                                      e.target.checked &&
                                      watch(`actionTimes[${index}].times`)
                                        ?.length === 0
                                    ) {
                                      handleAddTime(index);
                                    }
                                  }}
                                />
                              }
                              label={day.name}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>
                          {watch(`actionTimes[${index}].times`)?.map(
                            (time, timeIndex) => (
                              <Grid item xs={12} sm={6} md={4} key={timeIndex}>
                                <Stack direction="row" spacing={2}>
                                  <Controller
                                    name={`actionTimes[${index}].times[${timeIndex}]`}
                                    control={control}
                                    defaultValue={null}
                                    render={({ field }) => (
                                      <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                      >
                                        <DesktopTimePicker
                                          inputFormat="HH:mm"
                                          label="Time"
                                          value={field.value}
                                          onChange={(newValue) => {
                                            handleTimeChange(
                                              index,
                                              timeIndex,
                                              newValue
                                            );
                                          }}
                                          disabled={
                                            watch(
                                              `actionTimes[${index}].checked`
                                            )
                                              ? false
                                              : true
                                          }
                                          minutesStep={15}
                                          renderInput={(params) => (
                                            <TextField
                                              required={
                                                watch(
                                                  `actionTimes[${index}].checked`
                                                )
                                                  ? true
                                                  : false
                                              }
                                              disabled={
                                                watch(
                                                  `actionTimes[${index}].checked`
                                                )
                                                  ? false
                                                  : true
                                              }
                                              size="small"
                                              {...params}
                                              fullWidth
                                            />
                                          )}
                                        />
                                      </LocalizationProvider>
                                    )}
                                  />
                                  <IconButton
                                    color="error"
                                    onClick={() =>
                                      handleRemoveTime(index, timeIndex)
                                    }
                                  >
                                    <MdDelete />
                                  </IconButton>
                                </Stack>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={1}>
                        <IconButton
                          disabled={
                            watch(`actionTimes[${index}].checked`)
                              ? false
                              : true
                          }
                          onClick={() => handleAddTime(index)}
                        >
                          <IoMdAdd />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </TabPanel>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {confirm ? (
          <>
            <LoadingButton
              variant="contained"
              onClick={() => {
                handleNotificationSubmit(formData);
              }}
              color="primary"
              sx={{ width: "15%", mx: 2 }}
              loading={loading || loadingSchedule}
            >
              Yes
            </LoadingButton>
            <Button
              variant="contained"
              onClick={() => setConfirm(false)}
              color="secondary"
              sx={{ width: "15%", mx: 2 }}
            >
              No
            </Button>
          </>
        ) : (
          <Box display="flex" justifyContent="end" width="100%">
            <FormControlLabel
              control={
                <Switch
                  checked={isScheduled}
                  onChange={(e) => {
                    setIsScheduled(e.target.checked);
                  }}
                />
              }
              label="Schedule"
            />
            <LoadingButton
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              color="primary"
              loading={loading}
              sx={{ width: "30%", mx: 2 }}
            >
              {isAdd ? "Save" : "Update"}
            </LoadingButton>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default NotifyByUserIdForm;
