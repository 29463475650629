import {
  POP_UP_POST_RESET_FORM,
  POP_UP_POST_GET_BY_ID,
  POP_UP_POST_PAGE_NUMBER,
  POP_UP_POST_OPEN_DIALOG,
  POP_UP_POST_RELOAD,
  POP_UP_POST_GET_ALL_LIST,
  POP_UP_POST_GET_ALL,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 1,
  pageSize: 10,
  form: {
    id: null,
    type: null,
    titleAr: "",
    titleEn: "",
    titleKu: "",
    descriptionAr: "",
    descriptionEn: "",
    descriptionKu: "",
    url: "",
    isActive: true,
    large: false,
    giftItemCategoryId: null,
    giftItemId: "",
    productUrl: "",
    expiredAt: null,
  },
  popUpPostData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  popUpPostDataList: [],
  getById: {},
};

function popUpPostReducer(popUpPost = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case POP_UP_POST_GET_ALL:
      popUpPost.popUpPostData.data = payload.data;
      popUpPost.popUpPostData.total = payload.pageCount;
      popUpPost.popUpPostData.rowCount = payload.rowCount;
      return popUpPost;

    case POP_UP_POST_GET_ALL_LIST:
      popUpPost.popUpPostDataList = payload;
      return popUpPost;

    case POP_UP_POST_GET_BY_ID:
      popUpPost.form = {
        ...payload,
        type: payload.type === "Global" ? 0 : 1,
      };
      return popUpPost;

    case POP_UP_POST_RESET_FORM:
      popUpPost.form.id = null;
      popUpPost.form.type = null;
      popUpPost.form.titleEn = "";
      popUpPost.form.titleAr = "";
      popUpPost.form.titleKu = "";
      popUpPost.form.url = "";
      popUpPost.form.descriptionEn = "";
      popUpPost.form.descriptionAr = "";
      popUpPost.form.descriptionKu = "";
      popUpPost.form.isActive = true;
      popUpPost.form.large = false;
      popUpPost.form.giftItemCategoryId = null;
      popUpPost.form.giftItemId = "";
      popUpPost.form.productUrl = "";
      popUpPost.form.expiredAt = "";
      return popUpPost;

    case POP_UP_POST_PAGE_NUMBER:
      return { ...popUpPost, pageNo: payload };

    case POP_UP_POST_OPEN_DIALOG:
      return {
        ...popUpPost,
        openDialog: !popUpPost.openDialog,
      };

    case POP_UP_POST_RELOAD:
      return { ...popUpPost, reload: !popUpPost.reload };

    default:
      return popUpPost;
  }
}

export default popUpPostReducer;
