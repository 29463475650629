import CategoryBrandsService from "src/api/giftServices/categoryBrands.service";
import helper from "src/utils/helper";
import {
  GET_ALL_CATEGORY_BRANDS,
  OPEN_CATEGORY_BRANDS_DIALOG,
  RELOAD_CATEGORY_BRANDS,
  RESET_CATEGORY_BRANDS_FORM,
  SAVE_CATEGORY_BRANDS_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";

export const openCategoryBrandsDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_CATEGORY_BRANDS_DIALOG,
    payload: data,
  });
};

export const saveCategoryBrandsSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_CATEGORY_BRANDS_SEARCH_BODY,
    payload: data,
  });
};

export const resetCategoryBrandsForm = () => (dispatch) => {
  dispatch({
    type: RESET_CATEGORY_BRANDS_FORM,
  });
};

export const reloadCategoryBrands = () => (dispatch) => {
  dispatch({
    type: RELOAD_CATEGORY_BRANDS,
  });
};

export const createCategoryBrands = (data, closeDialog) => async (dispatch) => {
  try {
    dispatch(reloadCategoryBrands());
    const res = await CategoryBrandsService.createCategoryBrands(data);
    dispatch(showSuccessSnackbar("Updated successfully"));
    if (closeDialog) dispatch(openCategoryBrandsDialog());
    dispatch(reloadCategoryBrands());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadCategoryBrands());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllCategoryBrands = (categoryId) => async (dispatch) => {
  try {
    dispatch(reloadCategoryBrands());
    const res = await CategoryBrandsService.getGiftCollectionAll(categoryId);
    dispatch({
      type: GET_ALL_CATEGORY_BRANDS,
      payload: res.data,
    });
    dispatch(reloadCategoryBrands());
    dispatch(openCategoryBrandsDialog(categoryId));
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadCategoryBrands());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
