import {
  GET_ALL_SCHEDULED_ACTION,
  RESET_FORM_SCHEDULED_ACTION,
  OPEN_SCHEDULED_ACTION_DIALOG,
  RELOAD_SCHEDULED_ACTION,
  SAVE_SCHEDULED_ACTION_SAVE_NO,
  SAVE_SCHEDULED_ACTION_SEARCH_BODY,
  NOTIFICATION_GET_BY_ID,
} from "./type";

import ScheduledActionDataService from "../api/services/scheduledActions.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const openDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_SCHEDULED_ACTION_DIALOG,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: RESET_FORM_SCHEDULED_ACTION,
  });
};

export const reloadScheduledActions = () => (dispatch) => {
  dispatch({
    type: RELOAD_SCHEDULED_ACTION,
  });
};

export const saveScheduledActionPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SCHEDULED_ACTION_SAVE_NO,
    payload: data,
  });
};

export const saveScheduledActionSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SCHEDULED_ACTION_SEARCH_BODY,
    payload: data,
  });
};

export const createScheduledAction =
  (data, pagNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadScheduledActions());
      const res = await ScheduledActionDataService.create(data);
      dispatch(showSuccessSnackbar("Created successfully"));
      dispatch(getAllScheduledActions(pagNo, searchBody));
      dispatch(reloadScheduledActions());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadScheduledActions());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const updateScheduledAction =
  (id, data, pagNo, searchBody) => async (dispatch) => {
    try {
      const res = await ScheduledActionDataService.update(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllScheduledActions(pagNo, searchBody));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getAllScheduledActions =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadScheduledActions());
      const res = await ScheduledActionDataService.getAll(pageNo, searchBody);
      dispatch({
        type: GET_ALL_SCHEDULED_ACTION,
        payload: res.data.data,
      });
      dispatch(reloadScheduledActions());
    } catch (err) {
      dispatch(reloadScheduledActions());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteScheduledAction =
  (id, pagNo, pageSize) => async (dispatch) => {
    try {
      const res = await ScheduledActionDataService.delete(id);
      dispatch(
        showSuccessSnackbar("ScheduledAction Code deleted successfully!")
      );
      dispatch(getAllScheduledActions(pagNo, pageSize));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadScheduledActions());
    const res = await ScheduledActionDataService.getById(id);
    dispatch({
      // type: GET_BY_ID_SCHEDULED_ACTION,
      type: NOTIFICATION_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadScheduledActions());
  } catch (err) {
    dispatch(reloadScheduledActions());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(reloadScheduledActions());
    const res = await ScheduledActionDataService.getById(id);
    dispatch({
      type: NOTIFICATION_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadScheduledActions());
    // dispatch(openDialog());
  } catch (err) {
    dispatch(reloadScheduledActions());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
