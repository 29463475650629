import {
  RATING_QUESTIONS_RESET_FORM,
  RATING_QUESTIONS_GET_BY_ID,
  RATING_QUESTIONS_PAGE_NUMBER,
  RATING_QUESTIONS_OPEN_DIALOG,
  RATING_QUESTIONS_RELOAD,
  RATING_QUESTIONS_GET_ALL,
  RATING_QUESTIONS_GET_ALL_LIST,
  RATING_QUESTIONS_SEARCH_BODY,
  ORDER_RATING_GET_ALL,
  ORDER_RATING_RELOAD,
  ORDER_RATING_SEARCH_BODY,
  ORDER_RATING_PAGE_NUMBER,
} from "./type";

import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import RatingQuestionsService from "src/api/services/ratingQuestions.service";

export const openDialog = () => (dispatch) => {
  dispatch({
    type: RATING_QUESTIONS_OPEN_DIALOG,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: RATING_QUESTIONS_RESET_FORM,
  });
};

export const reloadRatingQuestions = () => (dispatch) => {
  dispatch({
    type: RATING_QUESTIONS_RELOAD,
  });
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: RATING_QUESTIONS_SEARCH_BODY,
    payload: data,
  });
};

export const savePageNo = (data) => (dispatch) => {
  dispatch({
    type: RATING_QUESTIONS_PAGE_NUMBER,
    payload: data,
  });
};

export const createRatingQuestions =
  (data, pagNo, searchBody) => async (dispatch) => {
    try {
      const res = await RatingQuestionsService.create(data);
      dispatch(showSuccessSnackbar("Created successfully"));
      dispatch(getAllRatingQuestions(pagNo, searchBody));
      dispatch(openDialog());
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getAllRatingQuestions =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadRatingQuestions());
      const res = await RatingQuestionsService.getAll(pageNo, searchBody);
      dispatch({
        type: RATING_QUESTIONS_GET_ALL,
        payload: res.data.data,
      });
      dispatch(reloadRatingQuestions());
    } catch (err) {
      dispatch(reloadRatingQuestions());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getListOrderCancelReasons = () => async (dispatch) => {
  try {
    dispatch(reloadRatingQuestions());
    const res = await RatingQuestionsService.getList();
    dispatch({
      type: RATING_QUESTIONS_GET_ALL_LIST,
      payload: res.data.data,
    });
    dispatch(reloadRatingQuestions());
  } catch (err) {
    dispatch(reloadRatingQuestions());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateRatingQuestions =
  (data, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await RatingQuestionsService.update(data.id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllRatingQuestions(pageNo, searchBody));
      dispatch(openDialog());
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteRatingQuestions =
  (id, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await RatingQuestionsService.delete(id);
      dispatch(showSuccessSnackbar("Deleted successfully!"));
      dispatch(getAllRatingQuestions(pageNo, searchBody));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadRatingQuestions());
    const res = await RatingQuestionsService.getById(id);
    dispatch({
      type: RATING_QUESTIONS_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadRatingQuestions());
  } catch (err) {
    dispatch(reloadRatingQuestions());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(reloadRatingQuestions());
    const res = await RatingQuestionsService.getById(id);
    dispatch({
      type: RATING_QUESTIONS_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadRatingQuestions());
    dispatch(openDialog());
  } catch (err) {
    dispatch(reloadRatingQuestions());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllOrderRating =
  (pageNo, searchBody, type) => async (dispatch) => {
    try {
      dispatch(orderRatingReload());
      const res = await RatingQuestionsService.getAllOrderRating(
        pageNo,
        searchBody,
        type
      );
      dispatch({
        type: ORDER_RATING_GET_ALL,
        payload: res.data.data,
      });
      dispatch(orderRatingReload());
    } catch (err) {
      dispatch(orderRatingReload());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const orderRatingReload = () => (dispatch) => {
  dispatch({
    type: ORDER_RATING_RELOAD,
  });
};

export const saveSearchBodyOrderRating = (data) => (dispatch) => {
  dispatch({
    type: ORDER_RATING_SEARCH_BODY,
    payload: data,
  });
};

export const savePageNoOrderRating = (data) => (dispatch) => {
  dispatch({
    type: ORDER_RATING_PAGE_NUMBER,
    payload: data,
  });
};
