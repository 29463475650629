import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import { orange } from "@mui/material/colors";
import InsertChartIcon from "@mui/icons-material/InsertChartOutlined";

const OrdersThisMonth = (props) => (
  <Card sx={{ height: "100%" }} {...props}>
    <CardContent>
      <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
        <Grid item>
          <Typography color="textSecondary" gutterBottom variant="h6">
            ORDERS CURRENT MONTH
          </Typography>
          <Typography color="textPrimary" variant="h3">
            {props.data ? props.data.toLocaleString() : ""}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: orange[600],
              height: 56,
              width: 56,
            }}
          >
            <InsertChartIcon />
          </Avatar>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default OrdersThisMonth;
