import { Box, Card, CardHeader } from "@mui/material";
import { Typography } from "@mui/material";
import { MdFactCheck } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";
import { useSelector } from "react-redux";
import AdditionalFeesForm from "src/pages/shared/orderAdditionalFees/form";

const OrdersHeaders = (props) => {
  const additionalFees = useSelector(
    (state) => state.additionalFees.openDialog
  );
  return (
    <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
      <Card>
        <CardHeader
          title={
            <Typography variant="h4" component="div">
              {props.orderDetails ? "Order Details" : "Orders"}
            </Typography>
          }
          avatar={
            props.orderDetails ? (
              <TbListDetails size={20} />
            ) : (
              <MdFactCheck size={20} />
            )
          }
        />
      </Card>
      {additionalFees ? <AdditionalFeesForm /> : null}
    </Box>
  );
};

export default OrdersHeaders;
