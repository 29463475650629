import { Button, Divider } from "@mui/material";
import {
  InputAdornment,
  Stack,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deposit,
  depositByAdmin,
  depositWithCash,
  withDrawByAdmin,
  withDrawWithCash,
} from "src/actions/wallet";
import { CustomDialog } from "src/components/CustomDialog";
import CustomText from "src/components/CustomText";
import { wallet_action_types } from "src/utils/common";
import { LoadingButton } from "@mui/lab";
import SvgIcon from "@mui/material/SvgIcon";
import { Search as SearchIcon } from "react-feather";
import { getAllUsers } from "src/actions/users";
import { useReactToPrint } from "react-to-print";
import Logo from "src/components/Logo";
import moment from "moment";
import { getExchangeRate } from "src/actions/exchangeRate";
import { showErrorSnackbar } from "src/actions/snackbarMessages";

export default function TransferMoney({
  open = false,
  handleClose = function () {},
  type = "",
  onSuccess = function () {},
  onError = function () {},
  userName = "",
  userPhoneNumber = "",
  userBalance = "",
}) {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [searchBody, setSearchBody] = useState({
    phoneNumber: "",
    fullName: "",
    role: 0,
  });
  const [note, setNote] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const dispatch = useDispatch();
  const [withdrawToUser, setwithdrawToUser] = useState("");
  const [exchangeRate, setExchangeRate] = useState("");
  const [representativeInfo, setRepresentativeInfo] = useState({});

  useEffect(() => {
    if (searchBody.phoneNumber.length > 0) {
      dispatch(getAllUsers(1, 15, searchBody)).then((res) => {
        setwithdrawToUser(res.data[0] ? res.data[0].id : "");
        setRepresentativeInfo(res.data[0]);
      });
    }
  }, [isSearch]);

  useEffect(() => {
    dispatch(getExchangeRate()).then((res) => {
      setExchangeRate(res.data);
    });
  }, []);

  const handleCompleteClickForWithdraw = () => {
    if (searchBody.phoneNumber <= 0)
      dispatch(showErrorSnackbar("Please Enter Phone Number"));
    else {
      dispatch(getAllUsers(1, 15, searchBody ? searchBody : {})).then((res) => {
        setwithdrawToUser(res.data[0] ? res.data[0].id : "");
        setRepresentativeInfo(res.data[0]);
        if (res.data.length == 0) {
          dispatch(showErrorSnackbar("Could not find a user with that Number"));
          // onError(`Could not find a user with that Number`);
        } else {
          setViewConfirm(true);
        }
      });
    }
  };
  const handleCompleteClick = () => {
    setViewConfirm(true);
  };

  const userId = window.location.pathname.replace("/app/wallet/", "");
  const [amount, setAmount] = useState(0);
  const [viewConfirm, setViewConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const title =
    type == wallet_action_types.transferMoney
      ? "Transfer Money"
      : type == wallet_action_types.clearance
      ? "Clearance"
      : type == wallet_action_types.TopUp
      ? "Top-up"
      : type == wallet_action_types.refund
      ? "Refund"
      : "Withdraw";

  function completeAction() {
    var withdrawBody = {
      userId: withdrawToUser,
      amount: amount,
      fromUserId: userId,
    };

    var body = {
      userId: userId,
      amount: amount,
    };

    if (amount <= 0) {
      setViewConfirm(false);
      return dispatch(showErrorSnackbar("Amount should be more than 0"));
      // onError("Amount should be more than 0");
    }
    setIsLoading(true);
    if (type == wallet_action_types.transferMoney) {
      dispatch(
        deposit({
          ...body,
          note: note,
        })
      )
        .then((res) => {
          if (res) {
            onSuccess();
            setViewConfirm(false);
            handleClose();
          }
          setIsLoading(false);
          setNote("");
        })
        .catch((err) => {
          setIsLoading(false);
          setViewConfirm(false);
        });
    } else if (type == wallet_action_types.TopUp) {
      dispatch(
        depositByAdmin({
          ...body,
          note: note,
        })
      )
        .then((res) => {
          if (res) {
            onSuccess();
            setViewConfirm(false);
            handleClose();
          }
          setIsLoading(false);
          setNote("");
        })
        .catch((err) => {
          setIsLoading(false);
          setViewConfirm(false);
        });
    } else if (type == wallet_action_types.clearance) {
      dispatch(depositWithCash(body))
        .then((res) => {
          if (res) {
            onSuccess();
            setViewConfirm(false);
            setOpenPrint(true);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setViewConfirm(false);
        });
    } else if (type == wallet_action_types.refund) {
      dispatch(withDrawWithCash(body))
        .then((res) => {
          if (res) {
            onSuccess();
            setViewConfirm(false);
            handleClose();
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setViewConfirm(false);
        });
    } else if (type == wallet_action_types.withdraw) {
      dispatch(withDrawByAdmin(withdrawBody))
        .then((res) => {
          if (res) {
            onSuccess();
            setViewConfirm(false);
            handleClose();
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setViewConfirm(false);
        });
    }
  }

  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: `@media print {
          @page {
            size: A4;
            margin: 10
          }
        }`,
  });
  return (
    <CustomDialog
      isOpen={open}
      handleClose={() => {
        setViewConfirm(false);
        handleClose();
      }}
      title={title}
      maxWidth="md"
    >
      <Box>
        {type === wallet_action_types.withdraw ? (
          <TextField
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            fullWidth
            placeholder="Search by phone"
            variant="outlined"
            type="number"
            value={searchBody.phoneNumber}
            sx={{ marginRight: 1 }}
            onChange={(e) => {
              setViewConfirm(false);
              var phoneNumber = e.target.value;
              setSearchBody({
                ...searchBody,
                phoneNumber:
                  phoneNumber[0] == "0"
                    ? phoneNumber.substring(1)
                    : phoneNumber,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setIsSearch(!isSearch);
                handleCompleteClickForWithdraw();
              }
            }}
          />
        ) : null}
        <CustomText
          fullWidth
          label="Amount"
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={amount}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (type === wallet_action_types.withdraw) {
                handleCompleteClickForWithdraw();
              } else {
                handleCompleteClick();
              }
            }
          }}
          onChange={(e) => {
            setAmount(e.target.value);
            setViewConfirm(false);
          }}
        ></CustomText>
        {type === wallet_action_types.TopUp ||
        type === wallet_action_types.transferMoney ? (
          <TextField
            size="small"
            fullWidth
            placeholder="Note"
            variant="outlined"
            multiline
            rows={3}
            value={note}
            sx={{ marginRight: 1 }}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setIsSearch(!isSearch);
                handleCompleteClickForWithdraw();
              }
            }}
          />
        ) : null}
        {viewConfirm ? (
          <Grid container direction="column">
            <Grid item>
              {type == wallet_action_types.withdraw ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Box
                      sx={{
                        margin: "auto",
                        padding: 2,
                        border: 1,
                        borderRadius: 3,
                        color: "#1abbe8",
                        // backgroundColor: "#1abbe8",
                        width: "210px",
                        display: "flex",
                      }}
                    >
                      <Stack direction={"column"}>
                        <Typography marginLeft={0.5} color="black">
                          Name: {userName}
                        </Typography>
                        <Typography marginLeft={0.5} color="black">
                          Phone: {userPhoneNumber}
                        </Typography>
                        <Typography marginLeft={0.5} color="black">
                          Balance: {userBalance}$
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4" color="#1abbe8">
                      ------------ <b>{amount}$</b> ------------{">"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box
                      sx={{
                        margin: "auto",
                        padding: 2,
                        border: 1,
                        borderRadius: 3,
                        color: "#1abbe8",
                        // backgroundColor: "#1abbe8",
                        width: "210px",
                        display: "flex",
                      }}
                    >
                      <Stack direction={"column"}>
                        <Typography marginLeft={0.5} color="black">
                          Name: {representativeInfo.firstName}{" "}
                          {representativeInfo.lastName ?? ""}
                        </Typography>
                        <Typography marginLeft={0.5} color="black">
                          Phone: {representativeInfo.phoneNumber}
                        </Typography>
                        <Typography marginLeft={0.5} color="black">
                          Balance: {representativeInfo.balance}$
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            <Grid item>
              <Stack direction={"row"} spacing={2} sx={{ marginTop: 2 }}>
                <Typography>
                  Are you sure you want to {title} with amount: {amount}$ ?
                </Typography>
                <LoadingButton
                  variant="contained"
                  onClick={completeAction}
                  color="success"
                  loading={isLoading}
                >
                  Confirm
                </LoadingButton>
                <Button
                  variant="contained"
                  onClick={() => setViewConfirm(false)}
                  color="error"
                >
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <Button
            variant="contained"
            fullWidth
            sx={{ marginTop: 2 }}
            onClick={() => {
              if (type === wallet_action_types.withdraw) {
                handleCompleteClickForWithdraw();
              } else {
                handleCompleteClick();
                // setOpenPrint(true)
              }
            }}
          >
            Complete Action
          </Button>
        )}
      </Box>

      {type === wallet_action_types.clearance ? (
        <CustomDialog
          isOpen={openPrint}
          handleClose={() => {
            setOpenPrint(false);
            handleClose();
          }}
          title="Print Clearance Receipt"
          // loading={isReload}
        >
          <Button
            size="small"
            variant="contained"
            fullWidth
            onClick={() => handlePrint()}
          >
            Print
          </Button>
          <Divider />
          <div ref={printRef} style={{ padding: "15px" }}>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Logo style={{ width: "300px", float: "right" }} />
                  <Typography variant="h3">Clearance Receipt</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                  <Typography variant="h5" sx={{ paddingTop: 2 }}>
                    Date: {moment(new Date()).format("yyyy-MM-DD, dddd")}
                  </Typography>
                  <Typography variant="h5">Name: {userName}</Typography>
                  <Typography variant="h5">
                    Phone number: 0{userPhoneNumber}
                  </Typography>
                  {/* <Typography variant="h5">
                        Address: { wallet.address}
                      </Typography> */}
                  <Typography variant="h5">
                    Amount In USD: {amount} $
                  </Typography>
                  <Typography variant="h5">
                    Amount In IQD: {Math.ceil(amount * exchangeRate)} IQ
                  </Typography>
                  <Typography variant="h5" sx={{ paddingBottom: 2 }}>
                    Wallet current balance: {userBalance} $
                  </Typography>
                  <Divider />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    justifyContent: "space-between",
                    display: "flex",
                    padding: "20px",
                    marginTop: "70px",
                    textAlign: "center",
                  }}
                >
                  <Grid>
                    <Typography variant="h5">Accountant:</Typography>
                    <Typography variant="h5">
                      {_UserInfo?.firstName ?? ""} {_UserInfo?.secondName ?? ""}{" "}
                      {_UserInfo?.lastName ?? ""}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="h5">Representative:</Typography>
                    <Typography variant="h5">{userName}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Divider />
          </div>
        </CustomDialog>
      ) : null}
    </CustomDialog>
  );
}
