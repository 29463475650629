import { Grid, Box } from "@mui/material";
import React from "react";
import BannerHeader from "./home/header/header";
import BannersTable from "./home/table";

const Banner = () => {
  return (
    <Box sx={{ minWidth: { lg: '100%', xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <BannerHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <BannersTable />
        </Grid>
      </Grid>
      <Box sx={{ paddingX: 2 }}>
      </Box>
    </Box>
  );
};

export default Banner;
