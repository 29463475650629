import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Grid } from "@mui/material";
import TotalOrders from "../components/pagesContent/dashboard/TotalOrders";
import OrdersChart from "../components/pagesContent/dashboard/OrdersChart";
import OrdersThisMonth from "../components/pagesContent/dashboard/OrdersThisMonth";
import TotalCustomers from "../components/pagesContent/dashboard/TotalCustomers";
import TotalVendors from "../components/pagesContent/dashboard/TotalVendors";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCounts,
  getbalance,
  getNewUsersByProvince,
  getOrderCountByStatus,
  getOrders,
  getOrdersPerProvince,
  getUsersSignUp,
} from "src/actions/dashboard";
import { useEffect } from "react";
import moment from "moment";
import OrdersByStatus from "src/components/pagesContent/dashboard/OrdersByStatus";
import MessageBalance from "src/components/pagesContent/dashboard/MessageBalance";
import { topup } from "src/actions/reports";
import TopUp from "src/components/pagesContent/dashboard/topup";
import OrdersPerProvince from "src/components/pagesContent/dashboard/ordersPerProvince";
import NewUsersPerProvince from "src/components/pagesContent/dashboard/newUsersPerProvince";
import RolePermission from "src/components/RolePermission";
import GoIp from "src/components/pagesContent/dashboard/goip";

export default function Dashboard() {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const dispatch = new useDispatch();
  const [dailyOrders, setDailyOrders] = useState({
    dates: [],
    counts: [],
  });

  const [dailyUsers, setDailyUsers] = useState({
    dates: [],
    counts: [],
  });

  const [newUserPerProvince, setNewUserPerProvince] = useState({
    provinceName: [],
    counts: [],
  });

  const [ordersPerProvince, setOrdersPerProvince] = useState({
    provinceName: [],
    counts: [],
  });

  const [ordersByStatus, setOrdersByStatus] = useState({
    data: [],
    labels: [],
    revenue: 0,
    ordered: 0,
  });
  const [messagebalance, setMessagebalance] = useState({
    messageBirdBalance: "",
    twilioBalance: "",
  });

  const [statistics, setStatistics] = useState({
    customersCount: 0,
    ordersCount: 0,
    vendorsCount: 0,
    ordersMonthlyCount: 0,
  });
  const [topUp, setTopup] = useState({
    data: "",
    labels: "",
    types: "",
    counts: "",
  });

  function getStatistics() {
    dispatch(getAllCounts())
      .then((res) => setStatistics(res.data))
      .catch(() => {});
  }

  function getDailyOrders(_from, _to) {
    var from = _from ? _from : moment().format("YYYY-MM-01");
    var to = _to ? _to : moment().format("YYYY-MM-DD");
    dispatch(getOrders({ startDate: from, endDate: to }))
      .then((res) => {
        var dates = res.data.map((row) => moment(row.date).format("MMM-DD"));
        var counts = res.data.map((row) => row.count);
        setDailyOrders({
          dates: dates,
          counts: counts,
          fromDate: from,
          toDate: to,
        });
      })
      .catch(() => {});
  }

  function getDailyUsrsSignUp(_from, _to) {
    var from = _from ? _from : moment().format("YYYY-MM-01");
    var to = _to ? _to : moment().format("YYYY-MM-DD");
    dispatch(getUsersSignUp({ startDate: from, endDate: to }))
      .then((res) => {
        var dates = res.data.map((row) => moment(row.date).format("MMM-DD"));
        var counts = res.data.map((row) => row.count);
        setDailyUsers({
          dates: dates,
          counts: counts,
          fromDate: from,
          toDate: to,
        });
      })
      .catch(() => {});
  }

  function handleOrdersPerProvince(_from, _to, provinceId) {
    var from = _from ? _from : moment().format("YYYY-MM-01");
    var to = _to ? _to : moment().format("YYYY-MM-DD");
    dispatch(
      getOrdersPerProvince({
        startDate: from,
        endDate: to,
        someProvinceId: provinceId,
      })
    )
      .then((res) => {
        var provinceName = res.data.map((row) => row.provinceName);
        var counts = res.data.map((row) => row.count);
        setOrdersPerProvince({
          provinceName: provinceName,
          counts: counts,
          fromDate: from,
          toDate: to,
          data: res.data,
        });
      })
      .catch(() => {});
  }

  function handleNewUsersByProvince(_from, _to, provinceId) {
    var from = _from ? _from : moment().format("YYYY-MM-01");
    var to = _to ? _to : moment().format("YYYY-MM-DD");
    dispatch(
      getNewUsersByProvince({
        startDate: from,
        endDate: to,
        someProvinceId: provinceId,
      })
    )
      .then((res) => {
        var provinceName = res.data.map((row) => row.provinceName);
        var counts = res.data.map((row) => row.count);
        setNewUserPerProvince({
          provinceName: provinceName,
          counts: counts,
          fromDate: from,
          toDate: to,
          data: res.data,
        });
      })
      .catch(() => {});
  }

  function getOrdersByStatus(_from, _to) {
    var from = _from ? _from : moment().format("YYYY-MM-01");
    var to = _to ? _to : moment().format("YYYY-MM-DD");
    dispatch(getOrderCountByStatus(from, to))
      .then((res) => {
        var data = res.data.map((row) => row.count);
        var labels = res.data.map((row) =>
          row.statusName?.replace(/(?!(H))[A-Z]/g, " $&")
        );
        var status = res.data.map((row) => row.status);
        const revenue = res.data.reduce((accumulator, obj) => {
          return obj.status == 3 || //canceled by customer
            obj.status == 16 || //canceled
            obj.status == 0 ||
            obj.status == 1 ||
            obj.status == 8
            ? accumulator
            : accumulator + obj.amount;
        }, 0);
        var ordered = 0;
        res.data.forEach((obj) => {
          if (
            obj.status != 16 &&
            obj.status != 0 &&
            obj.status != 1 &&
            obj.status != 8 &&
            obj.status != 3
          ) {
            ordered = ordered + obj.count;
          }
        });
        setOrdersByStatus({
          data: data,
          labels: labels,
          revenue: revenue,
          ordered: ordered,
          fromDate: from,
          toDate: to,
          status: status,
        });
      })
      .catch(() => {});
  }

  function messageBalance() {
    dispatch(getbalance()).then((res) => {
      setMessagebalance(res.data);
    });
  }

  function getTopup(_from, _to) {
    var from = _from ? _from : moment().format("YYYY-MM-01");
    var to = _to ? _to : moment().format("YYYY-MM-DD");
    dispatch(topup({ from: from, to: to }))
      .then((res) => {
        var data = res.data.map((row) => row.amount);
        var labels = res.data.map((row) => `${row.type} (${row.count}) `);
        var counts = res.data.map((row) => row.count);
        setTopup({
          data: data,
          labels: labels,
          counts: counts,
          fromDate: from,
          toDate: to,
        });
      })
      .catch(() => {});
  }

  useEffect(() => {
    getStatistics();
    getDailyOrders();
    getOrdersByStatus();
    getDailyUsrsSignUp();
    messageBalance();
    handleOrdersPerProvince();
    handleNewUsersByProvince();
    if (_UserInfo.roleOEndpoint.map((data) => data.Code).includes("27-14"))
      getTopup();
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Grid container spacing={1} sx={{ p: 2, paddingTop: 0 }}>
          <RolePermission permission="07-01">
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalOrders data={statistics.ordersCount} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalCustomers data={statistics.customersCount} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalVendors data={statistics.vendorsCount} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <OrdersThisMonth data={statistics.ordersMonthlyCount} />
            </Grid>
          </RolePermission>
          <Grid item md={12} xs={12}>
            <RolePermission permission="66-04">
              <GoIp />
            </RolePermission>
          </Grid>
          <RolePermission permission="07-05">
            <Grid item md={12} xs={12}>
              <MessageBalance data={messagebalance} />
            </Grid>
          </RolePermission>
          <RolePermission permission="07-04">
            <Grid item md={6} xl={6} xs={6}>
              <OrdersByStatus
                sx={{ height: "100%" }}
                data={ordersByStatus}
                onDateChanged={(date) => {
                  getOrdersByStatus(date.from, date.to);
                }}
              />
            </Grid>
          </RolePermission>
          <RolePermission permission="27-14">
            <Grid item md={6} xl={6} xs={6}>
              <TopUp
                data={topUp}
                onDateChanged={(date) => {
                  getTopup(date.from, date.to);
                }}
              />
            </Grid>
          </RolePermission>
          <RolePermission permission="07-07">
            <Grid item md={6} xl={6} xs={6}>
              <OrdersPerProvince
                sx={{ height: "100%" }}
                data={ordersPerProvince}
                onDateChanged={(data) => {
                  handleOrdersPerProvince(data.from, data.to, data.provinceId);
                }}
              />
            </Grid>
          </RolePermission>
          <RolePermission permission="07-06">
            <Grid item md={6} xl={6} xs={6}>
              <NewUsersPerProvince
                sx={{ height: "100%" }}
                data={newUserPerProvince}
                onDateChanged={(data) => {
                  handleNewUsersByProvince(data.from, data.to, data.provinceId);
                }}
              />
            </Grid>
          </RolePermission>
          <RolePermission permission="07-02">
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <OrdersChart
                data={dailyOrders}
                user_data={dailyUsers}
                onDateChanged={(date) => {
                  getDailyOrders(date.from, date.to);
                  getDailyUsrsSignUp(date.from, date.to);
                }}
              />
            </Grid>
          </RolePermission>
        </Grid>
      </Box>
    </>
  );
}
