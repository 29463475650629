import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, Tooltip, LinearProgress, Stack, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";

import RolePermission from "src/components/RolePermission";
import {
  getAllContactInfo,
  getByIdForUpdate,
  saveSearchBody,
} from "src/actions/contactInfo";
import ContactInfoForm from "src/pages/cross/Configuration/contactInfo/form/Form";

export default function ContactInfoTable() {
  const dispatch = useDispatch();

  const _ContactInfo = useSelector(
    (state) => state.contactInfo.contactInfoData
  );
  const searchFilter = useSelector((state) => state.contactInfo.searchBody);
  const reload = useSelector((state) => state.contactInfo.reload);

  // const [searchBody, setSearchBody] = useState({
  //   isActive: null,
  //   type: 2,
  //   vendorId: null,
  //   pageSize: 15,
  //   Question: "",
  // });

  // const isActive = [
  //   { name: "Yes", key: true },
  //   { name: "No", key: false },
  // ];

  useEffect(() => {
    dispatch(getAllContactInfo(searchFilter));
  }, [searchFilter, dispatch]);

  const handleLimitChange = (newPageSize) => {
    dispatch(saveSearchBody({ ...searchFilter, pageSize: newPageSize }));
  };

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <RolePermission permission="56-03">
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={(e) => {
                dispatch(getByIdForUpdate(params.row.id));
              }}
            >
              <Tooltip title="Edit" aria-label="edit">
                <EditIcon />
              </Tooltip>
            </IconButton>
          </RolePermission>
        </Stack>
      ),
    },
  ];

  return (
    <Card>
      <ContactInfoForm />
      <PerfectScrollbar>
        <Grid container spacing={2} sx={{ padding: 3 }}>
          {/* <Grid item xs={3}>
            <TextField
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder="Name"
              variant="outlined"
              value={searchBody.name}
              onChange={(e) => {
                setSearchBody({
                  ...searchBody,
                  name: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBody({
                      ...searchFilter,
                      name: searchBody.name,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              disabled={reload}
              options={isActive}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Is Active !" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveSearchBody({
                    ...searchFilter,
                    isActive: value ? value.key : null,
                  })
                );
                setSearchBody({
                  ...searchBody,
                  isActive: value ? value.id : null,
                });
              }}
              value={
                isActive.filter((x) => x.key === searchFilter.isActive)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              options={RATING_QUESTIONS_TYPES || []}
              getOptionLabel={(option) => option.value}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Type" />}
              onChange={(event, value) => {
                dispatch(
                  saveSearchBody({
                    ...searchFilter,
                    type: value ? value.key : 2,
                  })
                );
                setSearchBody({
                  ...searchBody,
                  type: value ? value.key : null,
                });
              }}
              value={
                RATING_QUESTIONS_TYPES?.filter(
                  (x) => x.key === searchFilter.type
                )[0] ?? null
              }
            />
          </Grid> */}
          {/* <Grid item xs={3} lg={3}>
            <Stack direction="row" sx={{ justifyContent: "start" }}>
              <IconButton
                disabled={reload}
                onClick={() => {
                  dispatch(saveSearchBody({ ...searchBody }));
                }}
              >
                <Tooltip title="Search with filter">
                  <SearchIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                disabled={reload}
                onClick={() => {
                  dispatch(
                    saveSearchBody({
                      pageSize: 15,
                      question: "",
                      isActive: null,
                      type: 2,
                    })
                  );
                  setSearchBody({
                    pageSize: 15,
                    question: "",
                    isActive: null,
                    type: 2,
                  });
                }}
              >
                <Tooltip title="Clear filter">
                  <Close />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid> */}
          <Grid item xs={12}>
            <Box sx={{ minWidth: 1050 }}>
              <Box sx={{ height: { md: "calc(100vh - 220px)", xs: 1000 } }}>
                <DataGrid
                  sx={{
                    "& .css-204u17-MuiDataGrid-main": {
                      paddingLeft: "15px",
                    },
                    "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                      paddingLeft: "15px",
                    },
                  }}
                  loading={reload}
                  rows={_ContactInfo || []}
                  columns={columns}
                  rowHeight={50}
                  disableSelectionOnClick
                  pagination
                  pageSize={searchFilter.pageSize}
                  onPageSizeChange={(newPageSize) =>
                    handleLimitChange(newPageSize)
                  }
                  rowCount={_ContactInfo.length || 0}
                  paginationMode="client"
                  rowsPerPageOptions={[15, 25, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Card>
  );
}
