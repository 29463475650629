import {
  TextField,
  Grid,
  DialogActions,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  FormControlLabel,
  Switch,
  Autocomplete,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import {
  createShippingOptions,
  openShippingOptionsFormDialog,
  resetShippingOptionsForm,
  updateShippingOptions,
} from "src/actions/shippingOptions";
import { shipping_types } from "src/utils/common";

const ShippingOptionsForm = () => {
  const dispatch = useDispatch();

  const _ShippingOption = useSelector((state) => state.shippingOption);
  const reload = useSelector((state) => state.shippingOption.reload);
  const selectedVendor = useSelector(
    (state) => state.shippingOption.selectedVendor
  );

  const isAdd = _ShippingOption.form.id ? false : true;

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: _ShippingOption.form,
  });

  useEffect(() => {
    reset({ ..._ShippingOption.form });
  }, [_ShippingOption.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetShippingOptionsForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        createShippingOptions(
          {
            name: data.name,
            price: data.price,
            vendorId: selectedVendor?.id,
            isActive: data.isActive,
            isDefault: data.isDefault,
          },
          selectedVendor,
          true
        )
      );
    } else {
      dispatch(
        updateShippingOptions(
          data.id,
          {
            name: data.name,
            price: data.price,
            vendorId: selectedVendor?.id,
            isActive: data.isActive,
            isDefault: data.isDefault,
          },
          selectedVendor
        )
      );
    }
  };

  const handleDialog = () => {
    dispatch(openShippingOptionsFormDialog(null));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog
        open={_ShippingOption.formDialog}
        keepMounted
        onClose={() => {
          handleDialog();
        }}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography color={isAdd ? "primary" : "orange.main"}>
            {isAdd ? "Add Shipping Option" : "Update Shipping Option"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} py={2}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={shipping_types}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Shipping Type" />
                    )}
                    onChange={(event, value) => {
                      setValue("name", value ? value.id : null, {
                        shouldDirty: true,
                      });
                    }}
                    value={
                      shipping_types.filter((x) => x.id === field.value)[0] ??
                      null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    type="number"
                    size="small"
                    label="Price"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="isActive"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("isActive", e.target.checked, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    }
                    label="Is Active"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="isDefault"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("isDefault", e.target.checked, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    }
                    label="Is Default"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            color="primary"
            loading={reload}
            disabled={!isDirty}
            sx={{ width: "25%", my: 2 }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default ShippingOptionsForm;
