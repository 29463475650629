import axios from "../axios";

class UserDeactivationService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`/userdeactivationreason/all/${pageNo}?${params}`);
  }

  getById(id) {
    return axios.get(`/userdeactivationreason/getById/${id}`);
  }

  create(data) {
    return axios.post("/userdeactivationreason/create", data);
  }

  update(data) {
    return axios.put(`/userdeactivationreason/update`, data);
  }

  delete(id) {
    return axios.delete(`/userdeactivationreason/delete/${id}`);
  }

  getAllList() {
    return axios.get(`/userdeactivationreason/list`);
  }
}
export default new UserDeactivationService();
