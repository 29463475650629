import {
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
  GET_ALL_ADDRESSES,
  DELETE_ADDRESS,
} from "./type";

import AddressDataService from "../api/services/address.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const getAllAddresses = () => async (dispatch) => {
  try {
    const res = await AddressDataService.getAll();
    dispatch({
      type: GET_ALL_ADDRESSES,
      payload: res.data.data,
    });
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const createCity = (data) => async (dispatch) => {
  try {
    const res = await AddressDataService.addCity(data);
    dispatch({
      type: CREATE_ADDRESS,
      payload: res.data.data,
    });
    dispatch(showSuccessSnackbar("City added successfully"));
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateCity = (data) => async (dispatch) => {
  try {
    const res = await AddressDataService.updateCity(data);
    dispatch({
      type: UPDATE_ADDRESS,
      payload: data,
    });
    dispatch(showSuccessSnackbar("City edited successfully"));
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteCity = (id) => async (dispatch) => {
  try {
    const res = await AddressDataService.deleteCity(id);
    dispatch({
      type: DELETE_ADDRESS,
      payload: id,
    });
    dispatch(getAllAddresses());
    dispatch(showSuccessSnackbar("City Deleted successfully"));
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
