import {
  OPEN_GENERAL_SETTING_DEALOG,
  CREATE_GENERAL_SETTINGR,
  GET_ALL_GENERAL_SETTINGR,
  SAVE_SEARCH_BODY_GENERAL_SETTINGR,
  GET_BY_ID_GENERAL_SETTING,
  RELOAD_GENERAL_SETTINGR,
  SAVE_SEARCH_PAGE_NO_GS,
  RESET_FORM_GS,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageCount: 0,
  rowCount: 0,
  pageNo: 0,
  form: {
    id: null,
    key: "",
    value: "",
    metaData: "",
    type: null,
    isActive: true,
  },
  data: [],
  searchBody: {
    pageSize: 10,
  },
  getById: {},
};

function generalSettingReducer(generalSetting = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_GENERAL_SETTINGR:
      var _GeneralSetting = {
        reload: generalSetting.reload,
        data: payload.data,
        pageCount: payload.pageCount,
        rowCount: payload.rowCount,
        searchBody: generalSetting.searchBody,
        pageNo: generalSetting.pageNo,
        getById: generalSetting.getById,
        form: generalSetting.form,
        openDialog: false,
      };
      return _GeneralSetting;

    case RESET_FORM_GS:
      generalSetting.form.id = null;
      generalSetting.form.value = "";
      generalSetting.form.key = "";
      generalSetting.form.metaData = "";
      generalSetting.form.isActive = true;
      generalSetting.form.type = null;
      return generalSetting;

    case GET_BY_ID_GENERAL_SETTING:
      generalSetting.form.id = payload.id;
      generalSetting.form.value = payload.value;
      generalSetting.form.type = payload.type;
      generalSetting.form.isActive = payload.isActive;
      generalSetting.form.metaData = payload.metaData;
      generalSetting.form.key = payload.key;
      return generalSetting;

    case RELOAD_GENERAL_SETTINGR:
      return { ...generalSetting, reload: !payload };

    case CREATE_GENERAL_SETTINGR:
      generalSetting.data = payload.data;
      return generalSetting;

    case SAVE_SEARCH_BODY_GENERAL_SETTINGR:
      return { ...generalSetting, searchBody: payload };

    case SAVE_SEARCH_PAGE_NO_GS:
      generalSetting.pageNo = payload;
      return generalSetting;

    case OPEN_GENERAL_SETTING_DEALOG:
      return { ...generalSetting, openDialog: !payload };

    default:
      return generalSetting;
  }
}

export default generalSettingReducer;
