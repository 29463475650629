import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  reloadTicketDetails,
  updateDepartment,
} from "src/actions/ticket/ticket";
import { getDepartment } from "src/actions/department/department";

export default function DepartmentMenu({ department = "", ticketId = null }) {
  const _UserInfo = useSelector((state) => state.users.userInfo);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [_department, setDepartment] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  const ticketDetailsReload = useSelector((state) => state.tickets.reload);
  const departmentData = useSelector((state) => state.department.data);

  useEffect(() => {
    dispatch(getDepartment()).then((res) => {});
  }, []);

  const updatedepartment = async (e) => {
    dispatch(reloadTicketDetails(ticketDetailsReload));
    dispatch(
      updateDepartment(
        {
          ticketId: ticketId,
          departmentId: e.target.id,
        },
        ticketId
      )
    );
  };

  const getPriorityIcon = (department) => {
    return (
      <Chip
        size="small"
        label={department}
        color={"primary"}
        clickable
        onClick={handleClick}
      />
    );
  };

  return (
    <React.Fragment>
      {getPriorityIcon(_department ? _department : department)}
      <Menu
        anchorEl={anchorEl}
        open={
          _UserInfo.roleOEndpoint.map((data) => data.Code).includes("T3-02")
            ? open
            : false
        }
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {departmentData.map((department) => (
          <MenuItem
            key={department.id}
            id={department.id}
            onClick={updatedepartment}
          >
            {department.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
