import { Helmet } from "react-helmet";
import { Box, Card, CardHeader, Grid } from "@mui/material";
import WalletContent from "../components/pagesContent/wallet/WalletContent";
import { Typography } from "@mui/material";
import { BsWallet2 } from "react-icons/bs";

const Wallet = () => (
  <>
    <Helmet>
      <title>Wallet | Orderii</title>
    </Helmet>
    <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <Card>
            <CardHeader
              title={
                <Typography variant="h4" component="div">
                  Wallet
                </Typography>
              }
              avatar={<BsWallet2 size={20} />}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <WalletContent />
        </Grid>
      </Grid>
    </Box>
  </>
);

export default Wallet;
