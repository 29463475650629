import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  Typography,
  Chip,
  Icon,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { CustomDialog } from "src/components/CustomDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import moment from "moment";
import {
  createPopUpPost,
  deletePopUpPost,
  getAllPopUpPost,
  getByIdForUpdate,
  resetForm,
  savePopUpPostPageNo,
} from "src/actions/popUpPost";
import host from "src/api/host";
import { HiDocumentDuplicate } from "react-icons/hi2";
import { showErrorSnackbar } from "src/actions/snackbarMessages";
import useCanShow from "src/components/CanShow";

export default function PopUpPostTable() {
  const dispatch = useDispatch();

  const editRole = useCanShow("24-02");
  const duplicateRole = useCanShow("24-01");
  const deleteRole = useCanShow("24-03");

  const _PopUpPosts = useSelector((state) => state.popUpPost.popUpPostData);
  const loading = useSelector((state) => state.popUpPost.reload);
  const pageNo = useSelector((state) => state.popUpPost.pageNo);

  const [page, setPage] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openOrderReasonDialog, setOpenOrderReasonDialog] = useState(false);

  useEffect(() => {
    dispatch(savePopUpPostPageNo(page + 1));
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(getAllPopUpPost(page + 1));
  }, [dispatch, page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  function deleteSelectedPromo(id) {
    setOpenDeleteDialog(false);
    dispatch(deletePopUpPost(id, pageNo)).then((x) => {
      dispatch(resetForm());
    });
  }

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "url",
      headerName: "Image",
      flex: 2,
      renderCell: (params) => (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img
          src={params.row.url}
          style={{ maxHeight: "200px", maxWidth: "250px" }}
        />
      ),
    },
    {
      field: "titleEn",
      headerName: "Title English",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={<Typography variant="h5">{params.row.titleEn}</Typography>}
          aria-label="edit"
        >
          <Typography noWrap>{params.row.titleEn}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "titleAr",
      headerName: "Title Arabic",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={<Typography variant="h5">{params.row.titleAr}</Typography>}
          aria-label="edit"
        >
          <Typography noWrap>{params.row.titleAr}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "descriptionEn",
      headerName: "Description English",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={
            <Typography variant="h5">{params.row.descriptionEn}</Typography>
          }
          aria-label="edit"
        >
          <Typography noWrap>{params.row.descriptionEn}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "descriptionAr",
      headerName: "Description Arabic",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={
            <Typography variant="h5">{params.row.descriptionAr}</Typography>
          }
          aria-label="edit"
        >
          <Typography noWrap>{params.row.descriptionAr}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "large",
      headerName: "Post Size",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.large ? "Large" : "Small"}
          color={params.row.large ? "primary" : "info"}
          size="small"
        />
      ),
    },
    {
      field: "isActive",
      headerName: "Is Active",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.isActive ? "Active" : "Inactive"}
          color={params.row.isActive ? "success" : "error"}
          size="small"
        />
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("YYYY-MM-DD")}
        </Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Icon color="primary">edit</Icon>}
          label="Edit"
          hidden={!editRole}
          showInMenu
          onClick={(e) => {
            dispatch(getByIdForUpdate(params.row.id, host + params.row.url));
          }}
        />,
        <GridActionsCellItem
          icon={<HiDocumentDuplicate size={25} />}
          label="Duplicate"
          hidden={!duplicateRole}
          showInMenu
          onClick={(e) => {
            const formData = new FormData();
            formData.append("titleAr", params.row.titleAr ?? "");
            formData.append("titleEn", params.row.titleEn ?? "");
            formData.append("descriptionAr", params.row.descriptionAr);
            formData.append("descriptionEn", params.row.descriptionEn);
            formData.append(
              "expiredAt",
              moment(params.row.expiredAt).format("YYYY-MM-DD")
            );
            formData.append("isActive", params.row.isActive);
            formData.append("large", params.row.large);
            formData.append("type", params.row.type);
            formData.append("id", params.row.id);
            formData.append("attachmentId", params.row.attachmentId);
            _PopUpPosts.data.length >= 4
              ? dispatch(
                  showErrorSnackbar("Can't create more than 4 popup post")
                )
              : dispatch(createPopUpPost(formData, pageNo));
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          hidden={!deleteRole}
          showInMenu
          onClick={(e) => {
            setOpenDeleteDialog(true);
            setOpenOrderReasonDialog(params.row);
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedPromo(openOrderReasonDialog.id)}
            selectedObject={openOrderReasonDialog.title}
            maxWidth="sm"
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box
                sx={{
                  height: { md: "calc(100vh - 220px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  columns={columns}
                  rows={
                    _PopUpPosts?.data.map((data, index) => {
                      return { ...data, index: index + 1 };
                    }) || []
                  }
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={200}
                  pagination
                  paginationMode="server"
                  rowCount={_PopUpPosts?.rowCount}
                  pageSize={10}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  rowsPerPageOptions={[10]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
