import {
  USER_DEACTIVATION_REASONS_RESET_FORM,
  USER_DEACTIVATION_REASONS_GET_BY_ID,
  USER_DEACTIVATION_REASONS_PAGE_NUMBER,
  USER_DEACTIVATION_REASONS_OPEN_DIALOG,
  USER_DEACTIVATION_REASONS_RELOAD,
  USER_DEACTIVATION_REASONS_GET_ALL,
  USER_DEACTIVATION_REASONS_GET_ALL_LIST,
} from "./type";

import UserDeactivationDataService from "../api/services/userDeactivation.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const openDialog = () => (dispatch) => {
  dispatch({
    type: USER_DEACTIVATION_REASONS_OPEN_DIALOG,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: USER_DEACTIVATION_REASONS_RESET_FORM,
  });
};

export const reloadUserDeactivation = () => (dispatch) => {
  dispatch({
    type: USER_DEACTIVATION_REASONS_RELOAD,
  });
};

export const saveUserDeactivationPageNum = (data) => (dispatch) => {
  dispatch({
    type: USER_DEACTIVATION_REASONS_PAGE_NUMBER,
    payload: data,
  });
};

export const createUserDeactivation =
  (data, pagNo, pageSize) => async (dispatch) => {
    try {
      const res = await UserDeactivationDataService.create(data);
      dispatch(showSuccessSnackbar("Created successfully"));
      dispatch(getAllUserDeactivation(pagNo, pageSize));
      dispatch(openDialog());
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getAllUserDeactivation =
  (pageNo, pageSize) => async (dispatch) => {
    try {
      dispatch(reloadUserDeactivation());
      const res = await UserDeactivationDataService.getAll(pageNo, pageSize);
      dispatch({
        type: USER_DEACTIVATION_REASONS_GET_ALL,
        payload: res.data.data,
      });
      dispatch(reloadUserDeactivation());
    } catch (err) {
      dispatch(reloadUserDeactivation());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getAllListUserDeactivation = () => async (dispatch) => {
  try {
    dispatch(reloadUserDeactivation());
    const res = await UserDeactivationDataService.getAllList();
    dispatch({
      type: USER_DEACTIVATION_REASONS_GET_ALL_LIST,
      payload: res.data.data,
    });
    dispatch(reloadUserDeactivation());
  } catch (err) {
    dispatch(reloadUserDeactivation());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateUserDeactivation =
  (data, pagNo, pageSize) => async (dispatch) => {
    try {
      const res = await UserDeactivationDataService.update(data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllUserDeactivation(pagNo, pageSize));
      dispatch(openDialog());
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteUserDeactivation = (id, pagNo) => async (dispatch) => {
  try {
    const res = await UserDeactivationDataService.delete(id);
    dispatch(showSuccessSnackbar("Deleted successfully!"));
    dispatch(getAllUserDeactivation(pagNo));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadUserDeactivation());
    const res = await UserDeactivationDataService.getById(id);
    dispatch({
      type: USER_DEACTIVATION_REASONS_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadUserDeactivation());
  } catch (err) {
    dispatch(reloadUserDeactivation());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(reloadUserDeactivation());
    const res = await UserDeactivationDataService.getById(id);
    dispatch({
      type: USER_DEACTIVATION_REASONS_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadUserDeactivation());
    dispatch(openDialog());
  } catch (err) {
    dispatch(reloadUserDeactivation());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
