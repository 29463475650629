import axiosService from "../../axiosService";

class TicketService {
    getAllTickets(pageNo,data) {
        let params = "";
        if (data) {
            Object.keys(data).forEach(key => {
                params += `${key}=${data[key]}&`;
            });
        }
        return axiosService.get(`/ticket-service/api/ticket/${pageNo}?${params}`);
    }

    getTicketById(id) {
        return axiosService.get(`/ticket-service/api/ticket/byid/${id}`);
    }

    getTicketAttachment(id) {
        return axiosService.get(`/ticket-service/api/ticket/attachment/${id}`);
    }

    statusHistories(id) {
        return axiosService.get(`/ticket-service/api/ticket/statushistories/${id}`);
    }

    changeStatus(data) {
        return axiosService.put(`/ticket-service/api/ticket/changestatus`, data);
    }

    changePriority(data) {
        return axiosService.put(`/ticket-service/api/ticket/priority`, data);
    }

    assignTicket(data) {
        return axiosService.put(`/ticket-service/api/ticket/assign`, data);
    }

    unAssignTicket(data) {
        return axiosService.put(`/ticket-service/api/ticket/unassign`, data);
    }

    department(data) {
        return axiosService.put(`/ticket-service/api/ticket/department`, data);
    }

    deleteAttachment(id) {
        return axiosService.delete(`/ticket-service/api/ticket/attachment/${id}`);
    }

    getPersonal(data) {
        let pageNo = data.pageNo ? data.pageNo : ''
        let status = data.pageNo ? '?status=' + data.status : ''
        let pageSize = data.pageSize ? '&pageSize=' + data.pageSize : ''
        return axiosService.get(`/ticket-service/api/ticket/${pageNo}${status}${pageSize}`);
    }

    createTicket(data) {
        return axiosService.post(`/ticket-service/api/ticket`, data);
    }

    addAttachment(data) {
        return axiosService.post(`/ticket-service/api/ticket/attachment`, data,{headers:{"Content-Type": "multipart/form-data"}});
    }
}

export default new TicketService()