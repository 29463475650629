import {
  SAVE_SEARCH_BODY_REFERRAL_CODE,
  RELOAD_REFERRAL_CODE,
  GET_ALL_REFERRAL_CODE,
  SAVE_REFERRAL_CODE_PAGE_NO
} from "../actions/type";

const initialState = {
  reload: false,
  rowCount: 0,
  pagNo: 0,
  data: [],
  searchBody: {
    pageSize: 15,
    codeName: "",
    phoneNumber: "",
  },
};

function referralCodeReducer(referralCode = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_REFERRAL_CODE:
      referralCode.data = payload.data
      referralCode.rowCount = payload.rowCount
      return referralCode;

    case SAVE_SEARCH_BODY_REFERRAL_CODE:
      return { ...referralCode, searchBody: payload };

    case RELOAD_REFERRAL_CODE:
      return { ...referralCode, reload: !referralCode.reload };

    case SAVE_REFERRAL_CODE_PAGE_NO:
      return { ...referralCode, pagNo: payload };

    default:
      return referralCode;
  }
}

export default referralCodeReducer;
