import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  TextField,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  InputAdornment,
  Typography,
  Divider,
  Autocomplete,
} from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import IconButton from "@mui/material/IconButton";
import { CustomDialog } from "src/components/CustomDialog";
import CloseIcon from "@mui/icons-material/Close";
import {
  deleteLocker,
  getAllLockers,
  openLockerHistoryDialog,
  resetForm,
  saveLockerHistoryPageNo,
  saveLockerPageNo,
  saveLockerSearchBody,
  toggleActivation,
} from "src/actions/locker";
import { is_active, is_empty } from "src/utils/common";
import { MdHistory, MdOutlineSearch } from "react-icons/md";
import { IoMdLock, IoMdUnlock } from "react-icons/io";
import RolePermission from "src/components/RolePermission";

const LockerTable = () => {
  const dispatch = useDispatch();

  const lockerData = useSelector((state) => state.locker.lockerData);
  const locker = useSelector((state) => state.locker);
  const loading = useSelector((state) => state.locker.reload);
  const searchBody = useSelector((state) => state.locker.searchBody);
  const _PageNo = useSelector((state) => state.locker.pageNo);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(_PageNo ?? 0);
  const [search, setSearch] = useState({
    number: searchBody.number,
    orderNumber: searchBody.orderNumber,
    phoneNumber: searchBody.phoneNumber,
    isActive: searchBody.isActive,
    isEmpty: searchBody.isEmpty,
  });

  useEffect(() => {
    dispatch(
      getAllLockers(page + 1, {
        ...searchBody,
        pageSize: 1000,
      })
    );
  }, [page, dispatch, searchBody]);

  function deleteSelectedVendorCategory(id) {
    dispatch(deleteLocker(id, page + 1, searchBody)).then((x) => {
      dispatch(resetForm());
      setOpenDeleteDialog(false);
    });
  }

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
              dispatch(resetForm());
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            maxWidth="sm"
            onDelete={() => deleteSelectedVendorCategory(locker.form.id)}
            selectedObject={`${locker.form.name}`}
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Phone Number"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={search.phoneNumber}
              onChange={(e) => {
                setSearch({
                  ...search,
                  phoneNumber: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveLockerSearchBody({
                      ...searchBody,
                      phoneNumber: search.phoneNumber,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Order Number"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={search.orderNumber}
              onChange={(e) => {
                setSearch({
                  ...search,
                  orderNumber: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveLockerSearchBody({
                      ...searchBody,
                      orderNumber: search.orderNumber,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Locker Number"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={search.number}
              onChange={(e) => {
                setSearch({
                  ...search,
                  number: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveLockerSearchBody({
                      ...searchBody,
                      number: search.number,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              options={is_active}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Is Active" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveLockerSearchBody({
                    ...searchBody,
                    ...search,
                    isActive: value ? value.id : null,
                  })
                );
              }}
              value={
                is_active.filter((x) => x.id === searchBody.isActive)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              options={is_empty}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Is Empty" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveLockerSearchBody({
                    ...searchBody,
                    ...search,
                    isEmpty: value ? value.id : null,
                  })
                );
              }}
              value={
                is_empty.filter((x) => x.id === searchBody.isEmpty)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Tooltip title="Search">
              <IconButton
                onClick={() => {
                  dispatch(
                    saveLockerSearchBody({
                      ...searchBody,
                      ...search,
                    })
                  );
                }}
              >
                <MdOutlineSearch />
              </IconButton>
            </Tooltip>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  setPage(0);
                  dispatch(saveLockerPageNo(0));
                  setSearch({
                    number: "",
                    orderNumber: "",
                    phoneNumber: "",
                    isActive: null,
                    isEmpty: null,
                  });
                  dispatch(
                    saveLockerSearchBody({
                      pageSize: 1000,
                      isActive: null,
                      isEmpty: null,
                      orderNumber: "",
                      phoneNumber: "",
                      number: "",
                    })
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Divider />
            {loading ? <LinearProgress /> : null}
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              // justifyContent="center"
              // alignItems="center"
              flexWrap="wrap"
              sx={{
                height: { md: "calc(100vh - 300px)", xs: 1000 },
                overflow: "auto",
              }}
            >
              {lockerData?.data.map((item, index) => (
                <Tooltip
                  key={index}
                  title={
                    item.order ? (
                      <Box>
                        <Typography variant="h5">
                          {item.order.number}
                        </Typography>
                        <Typography variant="h5">{item.owner.name}</Typography>
                        <Typography variant="h5">
                          {item.owner.phoneNumber}
                        </Typography>
                      </Box>
                    ) : null
                  }
                >
                  <Box
                    textAlign="center"
                    sx={{ cursor: item.order ? "pointer" : null }}
                    onClick={() => {
                      if (item.order) {
                        window.open(
                          `/app/order/${item.order.id ? item.order.id : ""}`,
                          "_blank"
                        );
                      }
                    }}
                  >
                    <Card
                      sx={{
                        m: 1,
                        width: 150,
                        height: 150,
                        backgroundColor:
                          item.isActive === false
                            ? "#F29C9C"
                            : item.isEmpty === false
                            ? "#D1B3FF"
                            : "#81c784",
                      }}
                    >
                      <CardContent>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item xs={12} md={12}>
                            <Typography variant="h5">{item.number}</Typography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Typography variant="h5">
                              {item.isActive === false
                                ? "Inactive"
                                : item.isEmpty === false
                                ? "Order"
                                : "Available"}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <RolePermission permission="71-04">
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(
                                    toggleActivation(
                                      item.id,
                                      page + 1,
                                      searchBody
                                    )
                                  );
                                }}
                              >
                                {item.isActive ? <IoMdLock /> : <IoMdUnlock />}
                              </IconButton>
                            </RolePermission>
                            <RolePermission permission="71-08">
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(saveLockerHistoryPageNo(0));
                                  dispatch(openLockerHistoryDialog(item.id));
                                }}
                              >
                                <MdHistory />
                              </IconButton>
                            </RolePermission>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                </Tooltip>
              ))}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LockerTable;
