import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Card, CardHeader, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { FaUsers } from "react-icons/fa";
import ShippingConfigsForm from "src/pages/cross/Configuration/shippingConfig/Form";

const ShippingConfigsHeader = () => {
  const _ShippingConfigs = useSelector(
    (state) => state.shippingConfig.openDialog
  );

  return (
    <>
      <Helmet>
        <title>Shipping Configs | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Shipping Configuration
              </Typography>
            }
            avatar={<FaUsers size={20} />}
          />
          {_ShippingConfigs ? <ShippingConfigsForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default ShippingConfigsHeader;
