import {
  CREATE_COMMENT, UPLOAD_ATTACHMENT, DELETE_COMMENT, GET_COMMENT_DEATAILS
} from "../actions/type";

const initialState = {
  data: [],
  attachment: [],
  dialog: false,
  reload: false
}

function ticketComment(comment = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_COMMENT:
      comment.data.push(payload);
      return comment;

    case GET_COMMENT_DEATAILS:
      return {
        ...comment,
        data: payload
      }

    case UPLOAD_ATTACHMENT:
      comment.attachment.push(payload);
      return comment

    case DELETE_COMMENT:
      comment.data = comment.data.filter((x) => x.id !== payload);
      return comment;

    default:
      return comment;
  }
}

export default ticketComment;