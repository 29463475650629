import { Grid, Box } from "@mui/material";
import React from "react";
import RatingQuestionsHeader from "./home/header/header";
import RatingQuestionsTable from "./home/table/List";

const RatingQuestions = () => {
  return (
    <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <RatingQuestionsHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <RatingQuestionsTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default RatingQuestions;
