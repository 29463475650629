import {
  SHIPMENTS_RESET_FORM,
  SHIPMENTS_GET_BY_ID,
  SHIPMENTS_PAGE_NUMBER,
  SHIPMENTS_OPEN_DIALOG,
  SHIPMENTS_RELOAD,
  SHIPMENTS_GET_ALL,
  SHIPMENTS_SEARCH_BODY,
  ORDER_ITEM_EXIST_IN_SHIPMENT,
  PRINT_PACKING_LIST,
} from "../actions/type";

const initialState = {
  type: "",
  reload: false,
  openDialog: false,
  pageNo: 0,
  searchBody: {
    pageSize: 15,
    fromCreatedAt: null,
    toCreatedAt: null,
    shippingNumber: "",
    orderiiNumber: "",
    itemName: "",
    isVendorExcluded: null,
    status: null,
    userName: "",
    phoneNumber: "",
    vendorIds: null,
    internationalTrackingNumber: "",
    region: null,
    isDamaged: null,
    isDangerousGoods: null,
  },
  form: {},
  shipmentsData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  OrderItemsExistInShipments: {
    dialog: false,
    data: [],
  },
  printPackingList: {
    dialog: false,
    data: {},
  },
  getById: {},
};

function shipmentsReducer(shipments = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHIPMENTS_GET_ALL:
      shipments.shipmentsData.data = payload.data;
      shipments.shipmentsData.total = payload.pageCount;
      shipments.shipmentsData.rowCount = payload.rowCount;
      return shipments;

    case ORDER_ITEM_EXIST_IN_SHIPMENT:
      return {
        ...shipments,
        OrderItemsExistInShipments: {
          dialog: !shipments.OrderItemsExistInShipments.dialog,
          data: payload,
        },
      };

    case SHIPMENTS_GET_BY_ID:
      shipments.form = payload;
      return shipments;

    case SHIPMENTS_RESET_FORM:
      shipments.form = initialState.form;
      return shipments;

    case SHIPMENTS_PAGE_NUMBER:
      return { ...shipments, pageNo: payload };

    case SHIPMENTS_SEARCH_BODY:
      return { ...shipments, searchBody: payload };

    case SHIPMENTS_OPEN_DIALOG:
      return {
        ...shipments,
        openDialog: !shipments.openDialog,
        type: payload,
      };

    case SHIPMENTS_RELOAD:
      return { ...shipments, reload: !shipments.reload };

    case PRINT_PACKING_LIST:
      return {
        ...shipments,
        printPackingList: {
          dialog: !shipments.printPackingList.dialog,
          data: payload,
        },
      };

    default:
      return shipments;
  }
}

export default shipmentsReducer;
