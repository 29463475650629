import axios from "../axios";

class LockerService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/locker/${pageNo}?${params}`);
  }

  getLockerHistory(pageNo, lockerId, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(
      `/locker/getlockerhistory/${pageNo}/${lockerId}?${params}`
    );
  }

  getById(id) {
    return axios.get(`/locker/getById/${id}`);
  }

  create(data) {
    return axios.post("/locker", data);
  }

  update(data) {
    return axios.put(`/locker`, data);
  }

  delete(data) {
    return axios.delete(`/locker`, { data });
  }

  assignToLocker(data) {
    return axios.post("/locker/assigntolocker", data);
  }

  clearLocker(orderId) {
    return axios.put(`/locker/clear/${orderId}`);
  }

  toggleActivation(orderId) {
    return axios.put(`/locker/toggleactivation/${orderId}`);
  }

  lockersList(orderId) {
    return axios.get(`/locker/getalllist/${orderId}`);
  }
}
export default new LockerService();
