import { Grid, Box } from "@mui/material";
import React from "react";
import CartReviewTable from "./home/table";
import CartReviewHeader from "./home/header/header";

const CartReview = () => {
  return (
    <Box sx={{ minWidth: { lg: '100%', xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <CartReviewHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <CartReviewTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CartReview;
