import { Grid, Box } from "@mui/material";
import React from "react";
import PopUpPostHeader from "./home/header/header";
import PopUpPostTable from "./home/table";

const PopUpPost = () => {
  return (
    <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <PopUpPostHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <PopUpPostTable />
        </Grid>
      </Grid>
      <Box sx={{ paddingX: 2 }}></Box>
    </Box>
  );
};

export default PopUpPost;
