import moment from "moment";
import {
  GET_ALL_AGENT_REQUEST,
  SAVE_SEARCH_BODY_AGENT_REQUEST,
  UPDATE_STATUS_AGENT_REQUEST,
  ASSIGN_AGENT_REQUEST,
  RELOAD_AGENT_REQUEST,
  SAVE_SEARCH_PAGE_NO_AR,
  GET_ALL_AGENTS
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageCount: 0,
  rowCount: 0,
  pageSize: 10,
  pageNo: 0,
  searchBody: {
    pageSize: 15,
    status: null,
    customerPhoneNumber: "",
    agentId: null,
  },
  data: [],
  agentData: [],
};

function AgentRequestReducer(agentRequest = initialState, action) {
  const { type, payload } = action;
  switch (type) {

    case GET_ALL_AGENT_REQUEST:
      var _AgentRequest = {
        reload: agentRequest.reload,
        data: payload.data,
        pageCount: payload.pageCount,
        rowCount: payload.rowCount,
        pageSize: payload.pageSize,
        searchBody: agentRequest.searchBody,
        pageNo: agentRequest.pageNo,
        agentRequest: agentRequest.createagentRequest,
        openDialog: false,
        savedPageNumber: agentRequest.savedPageNumber,
        statusHistory: agentRequest.statusHistory,
        agentData: agentRequest.agentData
      }
      return _AgentRequest;

    case GET_ALL_AGENTS:
      return { ...agentRequest, agentData: payload };

    case SAVE_SEARCH_BODY_AGENT_REQUEST:
      return { ...agentRequest, searchBody: payload };

    case SAVE_SEARCH_PAGE_NO_AR:
      agentRequest.pageNo = payload;
      return agentRequest

    case RELOAD_AGENT_REQUEST:
      return { ...agentRequest, reload: !payload };

    case UPDATE_STATUS_AGENT_REQUEST:
      var indexToChange = agentRequest.data.indexOf(
        agentRequest.data.filter((x) => x.id === payload.id)[0]
      );
      agentRequest.data[indexToChange].status = payload.status;
      agentRequest.data[indexToChange].id = payload.id;
      return agentRequest;

    case ASSIGN_AGENT_REQUEST:
      var indexToChange = agentRequest.data.indexOf(
        agentRequest.data.filter((x) => x.id === payload.id)[0]
      );
      agentRequest.data[indexToChange].agentId = payload.agentId;
      agentRequest.data[indexToChange].id = payload.id;
      return agentRequest;

    default:
      return agentRequest;
  }
}



export default AgentRequestReducer;
