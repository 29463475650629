/* eslint-disable radix */
import React, { useEffect, useRef } from "react";
import {
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { Box } from "@mui/system";

export default function GenerateInvoice({ data = {} }) {
  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: `@media print {
          @page {
            size: A4;
            margin: 10
          }
        }`,
  });

  useEffect(() => {
    handlePrint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Button
        onClick={() => {
          handlePrint();
        }}
        color="primary"
        variant="contained"
        fullWidth
      >
        print
      </Button>
      <div ref={printRef} style={{ padding: "15px" }}>
        <div>
          <Box
            component={"img"}
            src={"/static/images/logo/orderii_gifts_label.png"}
            style={{ width: "100px", float: "right" }}
          />
          <div style={{ float: "left" }}>
            <Typography variant="h5">
              Sender Name: {data?.senderName ?? ""}
            </Typography>
            <Typography variant="h5">
              Recipient Name: {data?.receiverName ?? ""}
            </Typography>
            <Typography variant="h5">
              Recipient Phone Number: {data?.phoneNumber ?? ""}
            </Typography>
            <Typography variant="h5">
              Address:{" "}
              {data?.receiverAddress?.city?.province?.nameUS +
                ", " +
                data?.receiverAddress?.city?.nameUS +
                ", " +
                data?.receiverAddress?.neighborhood +
                ", " +
                data?.receiverAddress?.nearestLocation}
            </Typography>
            <Typography variant="h5">
              Delivery Date:{" "}
              {moment(data?.deliveryDate).format("yyyy-MM-DD, dddd")}
            </Typography>
            <Typography variant="h5">
              Print Date: {moment(new Date()).format("yyyy-MM-DD, dddd")}
            </Typography>
          </div>
        </div>

        {data ? (
          <Table style={{ marginTop: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell>Item details</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Quantity</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.giftItems.map((item) => (
                <TableRow key={item?.id}>
                  <TableCell
                    sx={
                      item?.isCanceled ? { textDecoration: "line-through" } : {}
                    }
                  >{`${
                    item?.giftItem?.nameEn
                  } - ${item?.totalPrice?.toLocaleString()} IQD`}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item?.quantity}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
        <Divider />
        <Box sx={{ margin: 1.5 }}>
          <Typography variant="h4">
            Subtotal: {data?.totalAmountBeforeDiscount?.toLocaleString() ?? 0}{" "}
            IQD
          </Typography>
          {data?.totalDiscount > 0 ? (
            <Typography variant="h4" color="error">
              Discount: {data?.totalDiscount?.toLocaleString() ?? 0} IQD
            </Typography>
          ) : null}
          <Typography variant="h4">
            Delivery Price: {data?.deliveryPrice?.toLocaleString() ?? 0} IQD
          </Typography>
          {data?.serviceTaxes > 0 ? (
            <Typography variant="h4">
              Service Fee: {data?.serviceTaxes?.toLocaleString() ?? 0} IQD
            </Typography>
          ) : null}
          <Typography variant="h4" color="green">
            Total amount:{" "}
            {data?.totalAmountAfterDiscount?.toLocaleString() ?? 0} IQD
          </Typography>
          <Divider sx={{ marginTop: 3, marginBottom: 1 }} />
        </Box>
      </div>
    </div>
  );
}
