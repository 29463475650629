import {
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { showErrorSnackbar } from "src/actions/snackbarMessages";
import {
  createLocker,
  openDialog,
  resetForm,
  deleteLocker,
} from "src/actions/locker";

const LockerForm = () => {
  const dispatch = useDispatch();

  const _Locker = useSelector((state) => state.locker);
  const loading = useSelector((state) => state.locker.reload);
  const searchBody = useSelector((state) => state.locker.searchBody);
  const pageNo = useSelector((state) => state.locker.pageNo);
  const isAdd = useSelector((state) => state.locker.isAdd);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
    // setValue,
  } = useForm({
    defaultValues: _Locker.form,
  });

  const handleDialog = () => {
    dispatch(openDialog());
  };

  useEffect(() => {
    reset({ ..._Locker.form });
  }, [reset, _Locker.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(createLocker(data, pageNo + 1, searchBody))
        .then((x) => {
          reset({ ..._Locker.form });
          dispatch(resetForm());
          handleDialog();
        })
        .catch(() => dispatch(showErrorSnackbar("Network error")));
    } else {
      dispatch(deleteLocker(data, pageNo + 1, searchBody))
        .then((x) => {
          reset({ ..._Locker.form });
          dispatch(resetForm());
          handleDialog();
        })
        .catch(() => dispatch(showErrorSnackbar("Network error")));
    }
  };

  return (
    <Dialog
      open={_Locker.openDialog}
      keepMounted
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <DialogTitle>
        <Typography color="primary">
          {isAdd ? "Add Lockers" : "Delete Lockers"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            py={2}
          >
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                * Please enter range of locker number
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="startIndex"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    inputProps={{
                      minLength: 4,
                    }}
                    size="small"
                    label="From Locker Number"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="endIndex"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    inputProps={{
                      minLength: 4,
                    }}
                    size="small"
                    label="To Locker Number"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          color="primary"
          loading={loading}
          disabled={!isDirty}
          sx={{ width: "25%", my: 2 }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default LockerForm;
