import { Helmet } from "react-helmet";
import { Box, Stack } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import RolePermission from "src/components/RolePermission";
import {
  openWishCardDialog,
  openWrappingTypeDialog,
} from "src/actions/gifts/wrapping";
import { FaGift, FaRegCreditCard } from "react-icons/fa";
import WishCardForm from "src/pages/gifts/wrapping/from/wishCardForm";
import WrappingForm from "../../from/wrappingForm";

const WrappingHeader = () => {
  const dispatch = useDispatch();

  const _Dialog = useSelector((state) => state.wrapping);

  return (
    <>
      <Helmet>
        <title>Wrapping | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Card sx={{ width: "50%" }}>
            <CardHeader
              title={
                <Typography variant="h4" component="div">
                  Wish Card
                </Typography>
              }
              avatar={<FaGift size={20} />}
              action={
                <RolePermission permission="44-01">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<FaRegCreditCard />}
                    onClick={() => dispatch(openWishCardDialog())}
                  >
                    Add Wish Card
                  </Button>
                </RolePermission>
              }
            />
          </Card>
          <Card sx={{ width: "50%" }}>
            <CardHeader
              title={
                <Typography variant="h4" component="div">
                  Wrapping Type
                </Typography>
              }
              avatar={<FaGift size={20} />}
              action={
                <RolePermission permission="45-01">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<FaGift />}
                    onClick={() => dispatch(openWrappingTypeDialog())}
                  >
                    Add Wrapping Type
                  </Button>
                </RolePermission>
              }
            />
          </Card>
        </Stack>
        {_Dialog.wishCardDialog ? <WishCardForm /> : null}
        {_Dialog.wrappingDialog ? <WrappingForm /> : null}
      </Box>
    </>
  );
};

export default WrappingHeader;
