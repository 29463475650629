import { TextField } from "@mui/material";

export default function CustomText(props) {
  return (
    <TextField
    {...props}
      disabled={props.disabled ? props.disabled : false}
      style={props.style ? props.style : null}
      size={props.size ? props.size : "small"}
      type={props.type ? props.type : "text"}
      name={props.name ? props.name : props.label}
      margin="normal"
      variant="outlined"
      placeholder={props.placeholder ? props.placeholder : ''}
      InputProps={props.InputProps ? props.InputProps : {}}
      
    />
  );
}
