import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  TextField,
  InputAdornment,
  Typography,
  Autocomplete,
  Stack,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import {
  getAllFavorite,
  saveFavoritePageNum,
  saveFavoriteSearchBody,
} from "src/actions/gifts/favorite";
import { AiOutlineSearch } from "react-icons/ai";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { getGiftsCategoryList } from "src/actions/gifts/giftCategory";
import { CustomDialog } from "src/components/CustomDialog";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import theme from "src/theme";
import { country_codes } from "src/utils/common";
import ReactCountryFlag from "react-country-flag";

export default function FavoriteTable() {
  const dispatch = useDispatch();

  const _Favorite = useSelector((state) => state.favorite.favoriteData);
  const loading = useSelector((state) => state.favorite.reload);
  const pageNo = useSelector((state) => state.favorite.pageNo);
  const _GiftCategory = useSelector(
    (state) => state.giftItemCategory.giftItemCategoryList
  );

  const [page, setPage] = useState(pageNo);
  const searchBody = useSelector((state) => state.favorite.searchBody);
  const [searchName, setSearchName] = useState(searchBody.name);
  const [phoneNumber, setPhoneNumber] = useState(searchBody.phoneNumber);
  const [showPanZoom, setShowPanZoom] = useState("");
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);
  const [supplierData, setSupplierData] = useState({});

  const handleDialogClose = () => {
    setOpenImageDialog(false);
  };

  const handleOpenPanZoom = (url) => {
    setOpenImageDialog(true);
    setShowPanZoom(url);
  };

  useEffect(() => {
    dispatch(getAllFavorite(page + 1, searchBody));
  }, [dispatch, page, searchBody]);

  useEffect(() => {
    dispatch(getGiftsCategoryList());
  }, [dispatch]);

  const handlePageChange = (newPage) => {
    dispatch(saveFavoritePageNum(newPage));
    setPage(newPage);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "userName",
      headerName: "Customer Info",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body" component="div">
            {params.row.user?.name}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <ReactCountryFlag
              style={{
                width: "40px",
                height: "30px",
                border: "solid 1px #dedede",
              }}
              svg
              countryCode={
                country_codes.filter(
                  (code) => code.dial_code === params.row.user?.countryCode
                )[0]?.code
              }
            />
            <Typography variant="body">
              {params.row.user?.phoneNumber}
            </Typography>
          </Stack>
        </Stack>
      ),
    },
    // {
    //   field: "currentCountry",
    //   headerName: "Current Country",
    //   flex: 1,
    //   renderCell: (params) => params.row.user?.currentCountry,
    // },
    {
      field: "nameEn",
      headerName: "Item Name",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={<h5>{params.row.item?.nameEn}</h5>}>
          <Typography variant="body" component="div" noWrap>
            {params.row.item?.nameEn}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "giftItemAttachments",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => (
        <Box
          onClick={() =>
            handleOpenPanZoom(params.row.item?.giftItemAttachments[0]?.url)
          }
          padding={1}
          width={150}
          component="img"
          src={params.row.item?.giftItemAttachments[0]?.url}
        />
      ),
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body" color="secondary" component="div">
          {params.row.item?.giftItemCategories?.map(
            (x, i) =>
              `${x?.nameEn?.toUpperCase()}${
                i < params.row.item?.giftItemCategories.length - 1 ? " - " : ""
              }`
          )}
        </Typography>
      ),
    },
    {
      field: "brand",
      headerName: "Brand",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" spacing={0} height={40}>
          <Typography variant="body">
            {params.row.item?.giftBrand?.nameEn}
          </Typography>
          <Box
            onClick={() =>
              handleOpenPanZoom(params.row.item?.giftBrand?.attachment?.url)
            }
            component="img"
            src={params.row.item?.giftBrand?.attachment?.url}
            alt=""
            style={{
              cursor: "pointer",
              height: "100%",
              width: "auto",
              padding: "3px",
              objectFit: "contain",
              border: "2px solid",
              borderRadius: "10px",
              marginLeft: "20px",
              borderColor: theme.palette.primary.main,
            }}
          />
        </Stack>
      ),
    },
    {
      field: "giftSupplier",
      headerName: "Supplier",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Button
          onClick={() => {
            setOpenSupplierDialog(true);
            setSupplierData(params.row.item?.giftSupplier);
          }}
        >
          <Typography variant="body">
            {params.row.item?.giftSupplier?.nameEn}
          </Typography>
        </Button>
      ),
    },
  ];

  return (
    <Card>
      <CustomDialog
        isOpen={openSupplierDialog}
        handleClose={() => setOpenSupplierDialog(false)}
        title="Supplier  Information"
        maxWidth="sm"
      >
        {!supplierData?.isActive ? (
          <Typography color="error" variant="h5" sx={{ my: 1 }}>
            Deactivated
          </Typography>
        ) : null}
        <Typography variant="h5" sx={{ my: 1 }}>
          Name:{" "}
          <span style={{ fontWeight: "bolder" }}>{supplierData?.nameEn}</span>
        </Typography>
        <Typography variant="h5" sx={{ my: 1 }}>
          Phone number:{" "}
          <span style={{ fontWeight: "bolder" }}>
            {" "}
            {supplierData?.phoneNumber}
          </span>
        </Typography>
        <Typography variant="h5" sx={{ my: 1 }}>
          Address:{" "}
          <span style={{ fontWeight: "bolder" }}>
            {" "}
            {supplierData?.province?.nameEn}-{supplierData?.city?.nameEn}
          </span>
        </Typography>
        <Typography variant="h5" sx={{ my: 1 }}>
          Note:{" "}
          <span style={{ fontWeight: "bolder" }}>{supplierData?.note}</span>
        </Typography>
      </CustomDialog>
      <CustomDialog
        isOpen={openImageDialog}
        handleClose={() => handleDialogClose()}
        title="Images"
        maxWidth="lg"
      >
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              width: "50%",
              height: "auto",
            }}
          >
            <ReactPanZoom image={showPanZoom} alt="Image alt text" />
          </div>
        </div>
      </CustomDialog>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Phone Number"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value.replace(/^0+/, ""));
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveFavoriteSearchBody({
                      ...searchBody,
                      phoneNumber: phoneNumber,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Item Name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveFavoriteSearchBody({
                      ...searchBody,
                      name: searchName,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              disabled={loading}
              options={_GiftCategory || []}
              getOptionLabel={(option) => option.nameEn}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveFavoriteSearchBody({
                    ...searchBody,
                    categoryIds: value ? value.id : [],
                  })
                );
              }}
              value={
                _GiftCategory?.filter(
                  (x) => x.id === searchBody.categoryIds
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  setSearchName("");
                  setPhoneNumber("");
                  dispatch(
                    saveFavoriteSearchBody({
                      fromDate: moment().format("2024-01-01"),
                      name: "",
                      phoneNumber: "",
                      cityId: null,
                      provinceId: null,
                      pageSize: 15,
                    })
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box
                sx={{
                  height: { md: "calc(100vh - 270px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  columns={columns}
                  rows={
                    _Favorite?.data.map((data, index) => {
                      return { ...data, index: index + 1 };
                    }) || []
                  }
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={100}
                  pagination
                  paginationMode="server"
                  rowCount={_Favorite?.rowCount}
                  pageSize={10}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  rowsPerPageOptions={[10]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
