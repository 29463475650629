import axios from "../axios";

class GoIpService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/goipmessages/${pageNo}?${params}`);
  }

  getCredit() {
    return axios.get(`/goipmessages/creditbalance`);
  }

  sendMessage(data) {
    const encodedData = encodeURIComponent(data);
    return axios.post(`/goipmessages/sendmessage/${encodedData}`);
  }
}
export default new GoIpService();
