import axios from "../axios";

class NotificationService {
  create(data) {
    return axios.post("/notification/notifyall", data);
  }

  notificationUerById(data) {
    return axios.post("/notification/notifygroup", data);
  }

  getPersonalNotifications() {
    return axios.get("/notification/getpersonalnotifications");
  }

  markAllNotificationsAsRead() {
    return axios.put("/notification/setallasread");
  }

  getById(id) {
    return axios.get(`/notificationschedule /getbyid/${id}`);
  }

  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`/notificationschedule/${pageNo}?${params}`);
  }

  update(id, data) {
    return axios.put(`/notificationschedule/${id}`, data);
  }

  delete(id) {
    return axios.delete(`/notificationschedule/${id}`);
  }
}

export default new NotificationService();
