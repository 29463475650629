import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateStatusRequestAgent } from "src/actions/agentrequests";
import { agent_request_statuses } from "src/utils/common";
import { Box } from "@mui/material";

export default function RequestAgentStatusMenu({
  status = "",
  onSuccess = function () {},
  requestAgentId = null,
}) {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [_status, setStatus] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  function updateOrderStatus(e) {
    dispatch(
      updateStatusRequestAgent({
        id: requestAgentId,
        status: e.target.id,
      })
    ).then((res) => {
      onSuccess();
    });
  }

  const getStatusIcon = (status) => {
    var color;
    status == 0
      ? (color = "info")
      : status == 1
      ? (color = "warning")
      : status == 2
      ? (color = "success")
      : status == 9
      ? (color = "success")
      : status == 4
      ? (color = "secondary")
      : (color = "error");
    return (
      <Chip
        label={
          agent_request_statuses.filter((x) => x.id == status)[0]?.name ?? ""
        }
        color={color ?? "default"}
        clickable
        onClick={handleClick}
      />
    );
  };

  return (
    <Box>
      {getStatusIcon(_status ? _status : status)}
      <Menu
        anchorEl={anchorEl}
        open={
          _UserInfo?.roleOEndpoint?.filter((api) => api.Code === "04-04")[0]
            ? open
            : false
        }
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {agent_request_statuses.map((status) =>
          status.id ? (
            <MenuItem
              key={status.id}
              id={status.id}
              onClick={updateOrderStatus}
            >
              {status.name}
            </MenuItem>
          ) : (
            <div key={status.id}></div>
          )
        )}
      </Menu>
    </Box>
  );
}
