import {
  Chip,
  Divider,
  Grid,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import React from "react";

export default function Footer() {
  return (
    <Box>
      <Divider variant="" />
      <Box sx={{ m: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" justifyContent="end">
            <IconButton
              onClick={() =>
                window.open("https://www.facebook.com/orderii/", "_blank")
              }
            >
              <FacebookRoundedIcon sx={{ color: "#8444FF" }} />
            </IconButton>
            <IconButton
              color="secondary"
              onClick={() =>
                window.open("https://www.instagram.com/orderii.co/", "_blank")
              }
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              color="info"
              onClick={() => window.open("https://orderii.co", "_blank")}
            >
              <Icon>language</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
