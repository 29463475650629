import {
  TICKET_SUBMITTED, TICKET_SUMMARY, TICKET_LAST_DAYS
} from "../actions/type";

const initialState = {
  submitted: [],
  summary: {},
  lastDaysSummary: [],
}

function ticketStatistic(ticketStatistic = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TICKET_SUBMITTED:
      return {...ticketStatistic, submitted: payload};;

    case TICKET_SUMMARY:
      return {...ticketStatistic, summary: payload};

    case TICKET_LAST_DAYS:
      return {...ticketStatistic, lastDaysSummary: payload};


    default:
      return ticketStatistic;
  }
}

export default ticketStatistic;