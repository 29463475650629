import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  LinearProgress,
  Typography,
  Stack,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";
import { CustomDialog } from "src/components/CustomDialog";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { MdEdit } from "react-icons/md";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import moment from "moment";
import useCanShow from "src/components/CanShow";
import {
  deleteScheduledAction,
  getAllScheduledActions,
  getByIdForUpdate,
  saveScheduledActionPageNum,
  saveScheduledActionSearchBody,
} from "src/actions/scheduledAction";
import {
  setNotifyAllDialog,
  setNotifyByUserIdDialog,
  setNotifySegmentDialog,
} from "src/actions/notification";

export default function NotificationTable() {
  const dispatch = useDispatch();

  const editRole = useCanShow("70-04");
  const deleteRole = useCanShow("70-05");

  const _NotificationData = useSelector(
    (state) => state.scheduledActions.scheduledActionData
  );
  const _Notification = useSelector((state) => state.scheduledActions);
  const searchBody = useSelector((state) => state.scheduledActions.searchBody);
  const loading = useSelector((state) => state.scheduledActions.reload);
  const PageNo = useSelector((state) => state.scheduledActions.pageNo);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const urlTypes = [
    {
      url: "notifysegment",
      name: "Segment",
    },
    {
      url: "notifygroup",
      name: "Notify By User Id",
    },
    {
      url: "notifyall",
      name: "Notify All",
    },
  ];

  const statusTypes = [
    {
      id: 0,
      name: "Done",
    },
    {
      id: 1,
      name: "Failed",
    },
    {
      id: 2,
      name: "Pending",
    },
  ];

  useEffect(() => {
    dispatch(getAllScheduledActions(PageNo, searchBody));
  }, [searchBody, PageNo, dispatch]);

  const handlePageChange = (newPage) => {
    // setPage(newPage);
    dispatch(saveScheduledActionPageNum(newPage + 1));
  };

  function deleteSelectedItem() {
    setDeleteLoading(true);
    dispatch(deleteScheduledAction(selectedItemId, PageNo, searchBody)).then(
      (res) => {
        setDeleteLoading(false);
        // dispatch(resetForm());
        setOpenDeleteDialog(false);
        setSelectedItemId(null);
      }
    );
  }

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "type",
      headerName: "Notification Type",
      flex: 1,
      renderCell: (params) =>
        params.row?.url?.includes("notifysegment")
          ? "Segment"
          : params.row?.url?.includes("notifygroup")
          ? "Notify By User Id"
          : "Notify All",
    },
    {
      field: "isOneTime",
      headerName: "Schedule Type",
      flex: 1,
      renderCell: (params) =>
        params.row?.isOneTime ? "One Time" : "Recurring",
    },
    {
      field: "titleEn",
      headerName: "Title English",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={
            <Typography variant="h5">{params.row?.body?.titleEn}</Typography>
          }
          aria-label="edit"
        >
          <Typography noWrap>{params.row?.body?.titleEn}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "titleAr",
      headerName: "Title Arabic",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={
            <Typography variant="h5">{params.row?.body?.titleAr}</Typography>
          }
          aria-label="edit"
        >
          <Typography noWrap>{params.row?.body?.titleAr}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "lastStatusName",
      headerName: "Last Status ",
      flex: 1,
      renderCell: (params) =>
        params.row?.lastStatus !== null
          ? params.row?.lastStatusName
          : "Pending",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {moment(params.row?.createdAt).format("YYYY-MM-DD")}
        </Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<MdEdit size={20} />}
          label="Edit"
          hidden={!editRole}
          showInMenu
          onClick={(e) => {
            dispatch(getByIdForUpdate(params.row?.id)).then((x) => {
              if (params.row.url.includes("notifysegment")) {
                dispatch(setNotifySegmentDialog());
              } else if (params.row.url.includes("notifygroup")) {
                dispatch(setNotifyByUserIdDialog());
              } else {
                dispatch(setNotifyAllDialog());
              }
            });
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          color="error"
          hidden={!deleteRole}
          showInMenu
          onClick={(e) => {
            setOpenDeleteDialog(true);
            setSelectedItemId(params.row?.id);
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            maxWidth="sm"
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
              setSelectedItemId(null);
              // dispatch(resetForm());
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedItem()}
            selectedObject={_Notification.form.questionEn}
            loading={deleteLoading}
          ></CustomDialog>
        )}
      </Box>
      <PerfectScrollbar>
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12} md={4}>
            <Autocomplete
              options={urlTypes || []}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField required {...params} label="Type" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveScheduledActionSearchBody({
                    ...searchBody,
                    url: value ? value.url : null,
                  })
                );
              }}
              value={
                urlTypes?.filter((x) => x.url === searchBody.url)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              options={statusTypes || []}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField required {...params} label="Status" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveScheduledActionSearchBody({
                    ...searchBody,
                    status: value ? value.id : null,
                  })
                );
              }}
              value={
                statusTypes?.filter((x) => x.id === searchBody.status)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={3} lg={3}>
            <Stack direction="row" sx={{ justifyContent: "start" }}>
              <IconButton
                disabled={loading}
                onClick={() => {
                  dispatch(saveScheduledActionSearchBody({ ...searchBody }));
                  dispatch(saveScheduledActionPageNum(1));
                }}
              >
                <Tooltip title="Search with filter">
                  <SearchIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                disabled={loading}
                onClick={() => {
                  dispatch(saveScheduledActionPageNum(1));
                  dispatch(
                    saveScheduledActionSearchBody({
                      pageSize: 15,
                      title: "",
                      isActive: null,
                      type: 2,
                    })
                  );
                }}
              >
                <Tooltip title="Clear filter">
                  <Close />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                height: { md: "calc(100vh - 270px)", xs: 1000 },
                minWidth: 1050,
              }}
            >
              <DataGrid
                columns={columns}
                rows={
                  _NotificationData?.data.map((data, index) => {
                    return { ...data, index: index + 1 };
                  }) || []
                }
                loading={loading}
                disableSelectionOnClick
                rowHeight={65}
                pagination
                paginationMode="server"
                rowCount={_NotificationData?.rowCount}
                pageSize={10}
                page={PageNo}
                onPageChange={(newPage) => handlePageChange(newPage)}
                rowsPerPageOptions={[10]}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Card>
  );
}
