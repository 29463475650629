import {
  Box,
  Card,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomSpinner from "src/components/CustomSpinner";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment from "moment";
import { giftAllInfo } from "src/actions/gifts/reports";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";

export default function GiftAllInformation(props) {
  const dispatch = new useDispatch();
  const [loading, setLoading] = useState(false);
  const [allInformation, setAllInformation] = useState([]);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    allInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date, props.setSearch]);

  function allInfo() {
    setLoading(true);
    dispatch(
      giftAllInfo({
        startDate: props.date.startDate,
        endDate: props.date.endDate,
        forCancelled: props.forCancelled,
      })
    ).then((res) => {
      setAllInformation(res.data);
      setLoading(false);
    });
  }

  function CustomToolbar() {
    return (
      <Box>
        <Box>
          <Box>
            <Card>
              <CardHeader
                title="Gift All Information Reports"
                subheader={
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption">
                      {props.date.startDate + " - " + props.date.endDate}
                    </Typography>
                  </Stack>
                }
              />
              <Divider />
            </Card>
          </Box>
        </Box>
        <Box>
          <GridToolbarContainer>
            <GridToolbarExport
              printOptions={{
                disableToolbarButton: true,
                hideFooter: true,
                hideToolbar: true,
              }}
              csvOptions={{
                fileName: `Gift All Information Reports || Orderii || ${
                  moment(props.date.startDate).format("YYYY-MM-DD") +
                  " - " +
                  moment(props.date.endDate).format("YYYY-MM-DD")
                }`,
                utf8WithBom: true,
                allColumns: true,
              }}
            />
          </GridToolbarContainer>
        </Box>
      </Box>
    );
  }
  return (
    <Card style={{ marginTop: 10 }}>
      <PerfectScrollbar>
        <Box>
          {loading ? (
            <CustomSpinner />
          ) : (
            <Box sx={{ height: "calc(100vh - 320px)" }}>
              <DataGrid
                sx={{
                  "& .MuiDataGrid-row ": {
                    textAlign: "center",
                  },
                  "& .css-204u17-MuiDataGrid-main": {
                    paddingLeft: "15px",
                  },
                  "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                    paddingLeft: "15px",
                  },
                }}
                columns={[
                  {
                    field: "number",
                    width: 200,
                    headerName: "Order Number",
                  },
                  {
                    field: "giftItemName",
                    width: 300,
                    headerName: "Name",
                    renderCell: (params) => params.row.giftItem?.nameEn ?? "",
                    valueGetter: (params) => params.row.giftItem?.nameEn ?? "",
                  },
                  {
                    field: "sku",
                    width: 300,
                    headerName: "SKU",
                  },
                  {
                    field: "giftItemCategories",
                    width: 200,
                    headerName: "Categories",
                    renderCell: (params) => {
                      const categories =
                        params.row.giftItem?.giftItemCategories?.map(
                          (data) => data.nameEn
                        );
                      return categories.join(", ");
                    },
                    valueGetter: (params) => {
                      const categories =
                        params.row.giftItem?.giftItemCategories?.map(
                          (data) => data.nameEn
                        );
                      return categories.join(", ");
                    },
                  },
                  {
                    field: "giftBrand",
                    width: 200,
                    headerName: "Brand",
                    renderCell: (params) =>
                      params.row.giftItem?.giftBrand?.nameEn ?? "",
                    valueGetter: (params) =>
                      params.row.giftItem?.giftBrand?.nameEn ?? "",
                  },
                  {
                    field: "giftSupplier",
                    width: 200,
                    headerName: "Supplier",
                    renderCell: (params) =>
                      params.row.giftItem?.giftSupplier?.nameEn ?? "",
                    valueGetter: (params) =>
                      params.row.giftItem?.giftSupplier?.nameEn ?? "",
                  },
                  {
                    field: "quantity",
                    width: 200,
                    headerName: "Quantity",
                  },
                  {
                    field: "costPrice",
                    width: 200,
                    headerName: "Cost Price (IQD)",
                    renderCell: (params) =>
                      params.row?.costPrice?.toLocaleString() ?? "",
                    valueGetter: (params) => params.row?.costPrice ?? "",
                  },
                  {
                    field: "unitPrice",
                    width: 200,
                    headerName: "Unit Price (IQD)",
                    renderCell: (params) =>
                      params.row?.unitPrice?.toLocaleString() ?? "",
                    valueGetter: (params) => params.row?.unitPrice ?? "",
                  },
                  {
                    field: "totalPrice",
                    width: 200,
                    headerName: "Total Price (IQD)",
                    renderCell: (params) =>
                      params.row?.totalPrice?.toLocaleString() ?? "",
                    valueGetter: (params) => params.row?.totalPrice ?? "",
                  },
                  {
                    field: "totalAmountBeforeDiscount",
                    width: 200,
                    headerName: "Total Amount Before Discount (IQD)",
                    renderCell: (params) =>
                      params.row?.totalAmountBeforeDiscount?.toLocaleString() ??
                      "",
                    valueGetter: (params) =>
                      params.row?.totalAmountBeforeDiscount ?? "",
                  },
                  {
                    field: "totalDiscount",
                    width: 200,
                    headerName: "Total Discount(IQD)",
                    renderCell: (params) =>
                      params.row?.totalDiscount?.toLocaleString() ?? "",
                    valueGetter: (params) => params.row?.totalDiscount ?? "",
                  },
                  {
                    field: "deliveryPrice",
                    width: 200,
                    headerName: "Delivery Price (IQD)",
                    renderCell: (params) =>
                      params.row?.deliveryPrice?.toLocaleString() ?? "",
                    valueGetter: (params) => params.row?.deliveryPrice ?? "",
                  },
                  {
                    field: "serviceTaxes",
                    width: 200,
                    headerName: "Service Taxes (IQD)",
                    renderCell: (params) =>
                      params.row?.serviceTaxes?.toLocaleString() ?? "",
                    valueGetter: (params) => params.row?.serviceTaxes ?? "",
                  },
                  {
                    field: "totalAmountAfterDiscount",
                    width: 200,
                    headerName: "Order Total Amount (IQD)",
                    renderCell: (params) =>
                      params.row?.totalAmountAfterDiscount?.toLocaleString() ??
                      "",
                    valueGetter: (params) =>
                      params.row?.totalAmountAfterDiscount ?? "",
                  },
                  {
                    field: "promoCode",
                    width: 200,
                    headerName: "Promo Code",
                    renderCell: (params) => params.row?.promoCode?.name ?? "",
                    valueGetter: (params) => params.row?.promoCode?.name ?? "",
                  },
                  {
                    field: "exchangeRatePrice",
                    width: 200,
                    headerName: "Exchange Rate Price (IQD)",
                    renderCell: (params) =>
                      params.row?.exchangeRatePrice?.toLocaleString() ?? "",
                    valueGetter: (params) =>
                      params.row?.exchangeRatePrice ?? "",
                  },
                  {
                    field: "paymentType",
                    width: 200,
                    headerName: "Payment Type",
                  },
                  {
                    field: "codPaidAmount",
                    width: 200,
                    headerName: "COD Paid Amount (IQD)",
                    renderCell: (params) =>
                      params.row?.codPaidAmount?.toLocaleString() ?? "",
                    valueGetter: (params) => params.row?.codPaidAmount ?? "",
                  },
                  {
                    field: "remainingCODAmount",
                    width: 200,
                    headerName: "Remaining COD Amount (IQD)",
                    renderCell: (params) =>
                      params.row?.remainingCODAmount?.toLocaleString() ?? "",
                    valueGetter: (params) =>
                      params.row?.remainingCODAmount ?? "",
                  },
                  {
                    field: "status",
                    width: 200,
                    headerName: "Status",
                  },
                  {
                    field: "isCanceled",
                    width: 200,
                    headerName: "Is Canceled",
                  },
                  {
                    field: "isMoneyRefunded",
                    width: 200,
                    headerName: "Is Money Refunded",
                  },
                  {
                    field: "isFastDelivery",
                    width: 200,
                    headerName: "Is Fast Delivery",
                  },
                  {
                    field: "isMoneyRefunded",
                    width: 200,
                    headerName: "Is Money Refunded",
                  },
                  {
                    field: "orderCreatedAt",
                    width: 200,
                    headerName: "Order Created At",
                    renderCell: (params) =>
                      moment(params.row?.createdAt).format(
                        "YYYY-MM-DD hh:mm a"
                      ) ?? "",
                    valueGetter: (params) => params.row?.createdAt ?? "",
                  },
                  {
                    field: "deliveryDate",
                    width: 200,
                    headerName: "Delivery Date",
                    renderCell: (params) =>
                      moment(params.row?.deliveryDate).format(
                        "YYYY-MM-DD hh:mm a"
                      ) ?? "",
                    valueGetter: (params) => params.row?.deliveryDate ?? "",
                  },
                  {
                    field: "userName",
                    width: 300,
                    headerName: "Sender Name",
                    renderCell: (params) => params.row.user?.name ?? "",
                    valueGetter: (params) => params.row.user?.name ?? "",
                  },
                  {
                    field: "showName",
                    width: 300,
                    headerName: "Show Sender Name",
                    renderCell: (params) => params.row.showSenderName ?? "",
                    valueGetter: (params) => params.row.showSenderName ?? "",
                  },
                  {
                    field: "userCountryCode",
                    width: 200,
                    headerName: "Sender Country Code",
                    renderCell: (params) => params.row.user?.countryCode ?? "",
                    valueGetter: (params) => params.row.user?.countryCode ?? "",
                  },
                  {
                    field: "userPhoneNumber",
                    width: 200,
                    headerName: "Sender Phone Number",
                    renderCell: (params) => params.row.user?.phoneNumber ?? "",
                    valueGetter: (params) => params.row.user?.phoneNumber ?? "",
                  },
                  {
                    field: "recipientName",
                    width: 200,
                    headerName: "Recipient Name",
                    renderCell: (params) => params.row.receiverName ?? "",
                    valueGetter: (params) => params.row?.receiverName ?? "",
                  },
                  {
                    field: "recipientPhone",
                    width: 200,
                    headerName: "Recipient Phone Number",
                    renderCell: (params) => params.row.phoneNumber ?? "",
                    valueGetter: (params) => params.row?.phoneNumber ?? "",
                  },
                  {
                    field: "AskRecipient",
                    width: 200,
                    headerName: "Ask Recipient",
                    renderCell: (params) => params.row.askReceiver ?? "",
                    valueGetter: (params) => params.row?.askReceiver ?? "",
                  },
                  {
                    field: "recipientAddress",
                    width: 300,
                    headerName: "Recipient Address",
                    renderCell: (params) =>
                      params.row.receiverAddress?.name +
                        ", " +
                        params.row.receiverAddress?.city.province.nameUS +
                        ", " +
                        params.row.receiverAddress?.city.nameUS +
                        ", " +
                        params.row.receiverAddress?.neighborhood ?? "",
                    valueGetter: (params) =>
                      params.row.receiverAddress?.name +
                        ", " +
                        params.row.receiverAddress?.city.province.nameUS +
                        ", " +
                        params.row.receiverAddress?.city.nameUS +
                        ", " +
                        params.row.receiverAddress?.neighborhood ?? "",
                  },
                ]}
                rows={allInformation.map((data, index) => {
                  return { ...data, id: index + 1 };
                })}
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                pageSize={pageSize}
                disableSelectionOnClick
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[25, 75, 100]}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
