import {
  TextField,
  Grid,
  Stack,
  Button,
  Tooltip,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  createSuggestedEvent,
  openEventsDialog,
  resetEventForm,
  updateSuggestedEvent,
} from "src/actions/gifts/events";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

const SuggestedEventsForm = () => {
  const dispatch = useDispatch();

  const _Events = useSelector((state) => state.events);
  const loading = useSelector((state) => state.events.reload);
  const searchBody = useSelector((state) => state.events.searchBody);
  const pageNo = useSelector((state) => state.events.pageNo);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
    setValue,
  } = useForm({
    defaultValues: _Events.form,
  });

  const isAdd = _Events.form.id ? false : true;

  const handleDialog = () => {
    dispatch(openEventsDialog());
  };

  useEffect(() => {
    reset({ ..._Events.form });
  }, [reset, _Events.form]);

  useEffect(() => {
    return () => {
      dispatch(resetEventForm());
    };
  }, [dispatch]);

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        createSuggestedEvent(data, pageNo + 1, {
          searchBody,
          isPublicEvent: true,
        })
      );
    } else {
      dispatch(
        updateSuggestedEvent(data.id, data, pageNo + 1, {
          searchBody,
          isPublicEvent: true,
        })
      );
    }
  };

  return (
    <CustomDialog
      title={isAdd ? "Create suggested events" : "Update suggested events"}
      isOpen={_Events.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="nameEn"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextField
                  multiline
                  rows={4}
                  size="small"
                  label="Note Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="noteEn"
              control={control}
              render={({ field }) => (
                <TextField
                  multiline
                  rows={4}
                  size="small"
                  label="Note English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    label="Date"
                    value={field.value}
                    onChange={(newValue) => {
                      setValue("date", moment(newValue).format("YYYY-MM-DD"), {
                        shouldDirty: true,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        size="small"
                        {...params}
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "YYYY-MM-DD",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="isAnnually"
              control={control}
              render={({ field }) => (
                <Tooltip title="FALSE | TRUE" aria-label="active_deactivate">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("isAnnually", e.target.checked);
                        }}
                        {...field}
                      />
                    }
                    label="Is Annually !"
                  />
                </Tooltip>
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
              {
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => reset({ ..._Events.form })}
                >
                  Clear Text
                </Button>
              }
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};
export default SuggestedEventsForm;
