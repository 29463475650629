import {
  Grid,
  Stack,
  Chip,
  Typography,
  Divider,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { getAll, getByIdUpdate, openDialog } from "src/actions/externalItem";
import moment from "moment";
import ExternalItemStatusMenu from "../home/table/menu";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const ExternalItemForm = () => {
  const _ExternalItem = useSelector((state) => state.externalItem);
  const getByIdData = useSelector((state) => state.externalItem.getById);
  const itemReasons = useSelector(
    (state) => state.itemCancelReason.itemCancelReasonDataList
  );
  const searchFilter = useSelector((state) => state.externalItem.searchBody);
  const PageNo = useSelector((state) => state.externalItem.pageNo);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleDialog = () => {
    dispatch(openDialog());
  };

  return (
    <CustomDialog
      title={"External Item"}
      isOpen={_ExternalItem.openDialog}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={3.33}>
              <Stack direction="column" spacing={1}>
                <Chip label="Customer Information" />
                <Stack direction="column" spacing={1} alignItems="center">
                  <Typography>
                    {getByIdData.customer ? getByIdData.customer.fullName : ""}
                  </Typography>
                  <Typography>
                    {getByIdData.customer
                      ? getByIdData.customer.phoneNumber
                      : ""}
                  </Typography>
                  <IconButton
                    color="secondary"
                    onClick={(e) => {
                      navigate(`/app/cart/${getByIdData.customer.cartId}`);
                    }}
                  >
                    <Tooltip title="User Cart">
                      <ShoppingCartIcon />
                    </Tooltip>
                  </IconButton>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={3.33}>
              <Stack direction="column" spacing={1}>
                <Chip label="Item Information" />
                <Stack direction="column" spacing={1} alignItems="center">
                  <Typography>
                    <Tooltip title={getByIdData.url}>
                      <a href={getByIdData.url} target="_blank">
                        <Typography variant="body1" noWrap>
                          Item URL
                        </Typography>
                      </a>
                    </Tooltip>
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <ExternalItemStatusMenu
                      externalItemId={getByIdData.id}
                      status={getByIdData.statusString}
                      rejectReasonId={getByIdData.rejectReasonId}
                      cartId={getByIdData.cartId}
                      onSuccess={(newStatus) => {
                        if (newStatus == 2) {
                          dispatch(openDialog());
                          navigate(`/app/cart/${getByIdData.customer?.cartId}`);
                        } else {
                          dispatch(getByIdUpdate(getByIdData.id));
                          dispatch(getAll(PageNo + 1, searchFilter));
                        }
                      }}
                    />
                    {getByIdData.rejectReasonId ? (
                      <IconButton>
                        <Tooltip
                          title={
                            itemReasons?.filter(
                              (x) => x.id == getByIdData.rejectReasonId
                            )[0]
                              ? itemReasons?.filter(
                                  (x) => x.id == getByIdData.rejectReasonId
                                )[0].nameEN
                              : null
                          }
                          aria-label="cancel"
                        >
                          <InfoIcon />
                        </Tooltip>
                      </IconButton>
                    ) : null}
                  </Stack>
                  <Typography
                    color={getByIdData.quantity > 1 ? "#F18935" : null}
                  >
                    Quantity :{" "}
                    {getByIdData.customer ? getByIdData.quantity : ""}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={3.33}>
              <Stack direction="column" spacing={1}>
                <Chip label="Reviewer Information" />
                {getByIdData.reviewer ? (
                  <Stack direction="column" spacing={1} alignItems="center">
                    <Typography>
                      {getByIdData.reviewer ? getByIdData.reviewer : ""}
                    </Typography>
                    <Typography>
                      {getByIdData.reviewer
                        ? moment(getByIdData.createdAt).format(
                            "YYYY-MM-DD / hh:mm a"
                          )
                        : ""}
                    </Typography>
                  </Stack>
                ) : null}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Divider>
                <Chip label="Item Description" />
              </Divider>
              <Typography>
                {getByIdData.description ? getByIdData.description : ""}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default ExternalItemForm;
