import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import RolePermission from "src/components/RolePermission";
import GiftBrandForm from "../../from/Form";
import { openGiftsBrandDialog } from "src/actions/gifts/brand";
import { MdBrandingWatermark } from "react-icons/md";

const GiftItemBrandHeader = () => {
  const dispatch = useDispatch();

  const _GiftItemBrand = useSelector((state) => state.giftItemBrand.openDialog);

  return (
    <>
      <Helmet>
        <title>Product Brand | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Product Brand
              </Typography>
            }
            avatar={<MdBrandingWatermark size={20} />}
            action={
              <RolePermission permission="40-01">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => dispatch(openGiftsBrandDialog())}
                >
                  Create
                </Button>
              </RolePermission>
            }
          />
          {_GiftItemBrand ? <GiftBrandForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default GiftItemBrandHeader;
