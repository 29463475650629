import axios from "../axios";

class AgentRequestsService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`/agentrequest/getall/${pageNo}?${params}`);
    // if (status === 'null' || status === undefined) {
    //   return axios.get(`/agentrequest/getall/${pageNo}?pageSize=${pageSize}`);
    // }
    // else {
    //   return axios.get(`/agentrequest/getall/${pageNo}?pageSize=${pageSize}&status=${status}`);
    // }
  }

  getAgents() {
    return axios.get(`/agentrequest/getagents`);
  }

  assignAgent(data) {
    return axios.put(`/agentrequest/assign`, data);
  }

  updateStatus(data) {
    return axios.put(`/agentrequest/changestatus`, data);
  }

  addoptimoroute(data) {
    return axios.post("/agentrequest/addoptimoroute", data);
  }
}

export default new AgentRequestsService();
