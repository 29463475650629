import { Grid, Box } from "@mui/material";
import React from "react";
import ProhibitedItemsHeader from "./home/header";
import ProhibitedItemsTable from "./home/table";

const ProhibitedItems = () => {
  return (
    <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12}>
          <ProhibitedItemsHeader />
        </Grid>
        <Grid item xs={12} sx={{ py: 2 }}>
          <ProhibitedItemsTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProhibitedItems;
