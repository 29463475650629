import { Grid, Box, Dialog, DialogContent } from "@mui/material";
import React from "react";
import ShippingOptionsHeader from "./home/header/header";
import ShippingOptionsTable from "./home/table";
import { useDispatch, useSelector } from "react-redux";
import { openShippingOptionsDialog } from "src/actions/shippingOptions";

const ShippingOptions = () => {
  const dispatch = useDispatch();
  const _ShippingOption = useSelector((state) => state.shippingOption);

  return (
    <Dialog
      open={_ShippingOption.pageDialog}
      keepMounted
      onClose={() => {
        dispatch(openShippingOptionsDialog());
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogContent>
        <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
          <Grid container>
            <Grid item xs={12} sx={{ p: 2 }}>
              <ShippingOptionsHeader />
            </Grid>
            <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
              <ShippingOptionsTable />
            </Grid>
          </Grid>
          <Box sx={{ paddingX: 2 }}></Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ShippingOptions;
