import { Helmet } from "react-helmet";
import { Box, Stack } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { exportToCsv, openDialog } from "src/actions/purchasingBill";
import RolePermission from "src/components/RolePermission";
import PurchasingBillForm from "src/pages/cross/purchasingBill/from/Form";
import { FaReceipt } from "react-icons/fa";
import moment from "moment";

const PurchasingBillHeader = () => {
  const dispatch = useDispatch();

  const _PurchasingBill = useSelector(
    (state) => state.purchasingBill.openDialog
  );
  const _SearchBody = useSelector((state) => state.purchasingBill.searchBody);

  return (
    <>
      <Helmet>
        <title>Purchasing Bill | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Purchasing Bill
              </Typography>
            }
            avatar={<FaReceipt size={20} />}
            action={
              <Stack direction="row" spacing={2}>
                <RolePermission permission="61-07">
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                      dispatch(exportToCsv(_SearchBody))
                        .then((response) => {
                          const url = window.URL.createObjectURL(
                            new Blob([response.data])
                          );
                          const link = document.createElement("a");
                          link.href = url;
                          link.setAttribute(
                            "download",
                            `Purchasing Bill_${moment().format(
                              "YYYY-MM-DD"
                            )}.csv`
                          );
                          document.body.appendChild(link);
                          link.click();
                        })
                        .catch((error) => {
                          console.error("Error:", error);
                        });
                    }}
                  >
                    Export To CSV
                  </Button>
                </RolePermission>
                <RolePermission permission="61-03">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={() => dispatch(openDialog())}
                  >
                    CREATE
                  </Button>
                </RolePermission>
              </Stack>
            }
          />
          {_PurchasingBill ? <PurchasingBillForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default PurchasingBillHeader;
