import {
  GET_ALL_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  CREATE_CATEGORY,
  OPEN_CATEGORY_DIALOG,
  RELOAD_CATEGORY,
  GET_CATEGORY_BYID,
  TICKET_CATEGORY_PAGE_NO,
  GET_ALL_CATEGORY_LIST,
  RESET_FORM_CATEGORY
} from "../actions/type";

const initialState = {
  savePageNo: 0,
  form: {
    id: null,
    nameAr: "",
    nameEn: "",
    isActive: true,
  },
  data: [],
  total: 0,
  rowCount: 0,
  dialog: false,
  reload: false,
  dataList: []
}

function categoryReducer(category = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_CATEGORY:
      category.data.push(payload);
      return category;

    case OPEN_CATEGORY_DIALOG:
      return {
        ...category,
        dialog: !category.dialog
      }

    case GET_ALL_CATEGORY:
      category.data = payload.data
      category.total = payload.totalPages
      category.rowCount = payload.totalCount
      return category

    case GET_ALL_CATEGORY_LIST:
      category.dataList = payload
      return category

    case GET_CATEGORY_BYID:
      category.form.id = payload.id
      category.form.nameAr = payload.nameAr
      category.form.nameEn = payload.nameEn
      category.form.isActive = payload.isActive
      return category

    case RESET_FORM_CATEGORY:
      category.form.id = null
      category.form.nameAr = ""
      category.form.nameEn = ""
      category.form.isActive = true
      return category

    case UPDATE_CATEGORY:
      category.data.filter(x => x.nameEn == payload.nameEn)
      category.data.filter(x => x.nameAr == payload.nameAr)
      return category;

    case DELETE_CATEGORY:
      category.data = category.data.filter((x) => x.id !== payload);
      return category;

    case RELOAD_CATEGORY:
      return { ...category, reload: !category.reload };

    case TICKET_CATEGORY_PAGE_NO:
      category.savePageNo = payload;
      return category;

    default:
      return category;
  }
}

export default categoryReducer;