import axios from "../axios";

class ItemCancelREasonService {
  getAll(pageNo) {
    return axios.get(`/itemcancelreason/All/${pageNo}`);
  }

  getList() {
    return axios.get(`/itemcancelreason/list`);
  }

  getById(id) {
    return axios.get(`/itemcancelreason/getById/${id}`);
  }

  create(data) {
    return axios.post("/itemcancelreason/create", data);
  }

  update(data) {
    return axios.put(`/itemcancelreason/update`, data);
  }

  delete(id) {
    return axios.delete(`/itemcancelreason/delete/${id}`);
  }
  
}

export default new ItemCancelREasonService()