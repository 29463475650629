import axios from "axios";
import Cookies from "universal-cookie";
import host from "./host";
const cookies = new Cookies();
var _headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  "App-Version": "web",
  "Accept-Language": "en",
};
if (cookies.get("token"))
  _headers.authorization = `Bearer ${cookies.get("token")}`;

const api = axios.create({
  baseURL: host + "/api",
  headers: _headers,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // eslint-disable-next-line default-case
    switch (error.response.status) {
      case 401:
        cookies.remove("token");
        window.location.href = "/login";
        break;
    }

    return Promise.reject(error);
  }
);
export default api;
