import { Grid, Box } from "@mui/material";
import React from "react";
import EPaymentsHeader from "./home/header/header";
import EPaymentsTable from "./home/table/List";

const EPayments = () => {
  return (
    <Box sx={{ minWidth: { md: '100%', xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <EPaymentsHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <EPaymentsTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EPayments;
