import {
  AGENT_CITY_CONFIG_RESET_FORM,
  AGENT_CITY_CONFIG_GET_BY_ID,
  AGENT_CITY_CONFIG_PAGE_NUMBER,
  AGENT_CITY_CONFIG_OPEN_DIALOG,
  AGENT_CITY_CONFIG_RELOAD,
  AGENT_CITY_CONFIG_GET_ALL,
  AGENT_CITY_CONFIG_SEARCH_BODY,
  AGENT_CITY_CONFIG_TRANSFER_LIST_OPEN_DIALOG,
  AGENT_CITY_CONFIG_TRANSFER_LIST_DATA,
  AGENT_CITY_CONFIG_AGENT_ID,
  AGENT_CITY_CONFIG_RELOAD_ADD_DELETE,
  AGENT_REQUEST_GET_ALL,
} from "./type";

import AgentCityConfigService from "../api/services/agentCityConfig.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const setAgentId = (data) => (dispatch) => {
  dispatch({
    type: AGENT_CITY_CONFIG_AGENT_ID,
    payload: data,
  });
};

export const openDialog = () => (dispatch) => {
  dispatch({
    type: AGENT_CITY_CONFIG_OPEN_DIALOG,
  });
};

export const loading = () => (dispatch) => {
  dispatch({
    type: AGENT_CITY_CONFIG_RELOAD_ADD_DELETE,
  });
};

export const openTransferListDialog = () => (dispatch) => {
  dispatch({
    type: AGENT_CITY_CONFIG_TRANSFER_LIST_OPEN_DIALOG,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: AGENT_CITY_CONFIG_RESET_FORM,
  });
};

export const reloadAgentCityConfig = () => (dispatch) => {
  dispatch({
    type: AGENT_CITY_CONFIG_RELOAD,
  });
};

export const saveAgentCityConfigPageNo = (data) => (dispatch) => {
  dispatch({
    type: AGENT_CITY_CONFIG_PAGE_NUMBER,
    payload: data,
  });
};

export const saveAgentCityConfigSearchBody = (data) => (dispatch) => {
  dispatch({
    type: AGENT_CITY_CONFIG_SEARCH_BODY,
    payload: data,
  });
};

export const createAgentCityConfig = (data) => async (dispatch) => {
  try {
    const res = await AgentCityConfigService.create(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(getAllAgentCityConfig());
    dispatch(openDialog());
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const createAgentCityConfigFromTransferList =
  (data, agentId) => async (dispatch) => {
    try {
      dispatch(reloadAgentCityConfig());
      const res = await AgentCityConfigService.create(data);
      dispatch(showSuccessSnackbar("Created successfully"));
      dispatch(getAllTransferList(agentId));
      dispatch(reloadAgentCityConfig());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadAgentCityConfig());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getAllAgentCityConfig = () => async (dispatch) => {
  try {
    dispatch(reloadAgentCityConfig());
    const res = await AgentCityConfigService.getAllList();
    dispatch({
      type: AGENT_CITY_CONFIG_GET_ALL,
      payload: res.data,
    });
    dispatch(reloadAgentCityConfig());
  } catch (err) {
    dispatch(reloadAgentCityConfig());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllTransferList = (agentId) => async (dispatch) => {
  try {
    dispatch(reloadAgentCityConfig());
    const res = await AgentCityConfigService.getAllList(agentId);
    dispatch({
      type: AGENT_CITY_CONFIG_TRANSFER_LIST_DATA,
      payload: res.data,
    });
    dispatch(reloadAgentCityConfig());
  } catch (err) {
    dispatch(reloadAgentCityConfig());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteAgentCityConfig = (id, agentId) => async (dispatch) => {
  try {
    dispatch(reloadAgentCityConfig());
    const res = await AgentCityConfigService.delete(id);
    dispatch(showSuccessSnackbar("Deleted successfully!"));
    dispatch(getAllTransferList(agentId));
    dispatch(reloadAgentCityConfig());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadAgentCityConfig());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadAgentCityConfig());
    const res = await AgentCityConfigService.getById(id);
    dispatch({
      type: AGENT_CITY_CONFIG_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadAgentCityConfig());
  } catch (err) {
    dispatch(reloadAgentCityConfig());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(reloadAgentCityConfig());
    const res = await AgentCityConfigService.getById(id);
    dispatch({
      type: AGENT_CITY_CONFIG_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadAgentCityConfig());
    dispatch(openDialog());
  } catch (err) {
    dispatch(reloadAgentCityConfig());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

// ======================= Update Agent provinces =======================

export const getAllAgentRequestProvinces = () => async (dispatch) => {
  try {
    dispatch(reloadAgentCityConfig());
    const res = await AgentCityConfigService.getAllAgentRequestProvinces();
    dispatch({
      type: AGENT_REQUEST_GET_ALL,
      payload: res.data,
    });
    dispatch(reloadAgentCityConfig());
  } catch (err) {
    dispatch(reloadAgentCityConfig());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateAgentRequestProvincesActivation =
  (data, searchBody) => async (dispatch) => {
    try {
      const res =
        await AgentCityConfigService.updateAgentRequestProvincesActivation(
          data
        );
      dispatch(showSuccessSnackbar("Updated successfully"));
      dispatch(getAllAgentRequestProvinces(searchBody));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };
