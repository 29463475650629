import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { BiCategoryAlt } from "react-icons/bi";
import RolePermission from "src/components/RolePermission";
import GiftCategoryForm from "../../from/Form";
import { openGiftsCategoryDialog } from "src/actions/gifts/giftCategory";
import BrandsCollectionForm from "src/pages/gifts/category/from/brandsCollections";

const GiftItemCategoryHeader = () => {
  const dispatch = useDispatch();

  const _GiftItemCategory = useSelector(
    (state) => state.giftItemCategory.openDialog
  );
  const _GiftItemCategoryBrand = useSelector(
    (state) => state.categoryBrands.openDialog
  );

  return (
    <>
      <Helmet>
        <title>Product Category | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Product Category
              </Typography>
            }
            avatar={<BiCategoryAlt size={20} />}
            action={
              <RolePermission permission="40-01">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => dispatch(openGiftsCategoryDialog())}
                >
                  Create
                </Button>
              </RolePermission>
            }
          />
          {_GiftItemCategory ? <GiftCategoryForm /> : null}
          {_GiftItemCategoryBrand ? <BrandsCollectionForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default GiftItemCategoryHeader;
