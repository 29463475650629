import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  LinearProgress,
  Typography,
  Stack,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Pagination,
  Select,
  MenuItem,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFAQ,
  getAllFAQ,
  getById,
  getByIdForUpdate,
  resetForm,
  savePageNo,
  saveSearchBody,
} from "src/actions/faq";
import RolePermission from "src/components/RolePermission";
import SearchIcon from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";
import { CustomDialog } from "src/components/CustomDialog";
import { RATING_QUESTIONS_TYPES } from "src/utils/common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider } from "rsuite";
import { DeleteForever } from "@mui/icons-material";

export default function FAQTable() {
  const dispatch = useDispatch();

  const _FAQData = useSelector((state) => state.faq.FAQData);
  const _FAQ = useSelector((state) => state.faq);
  const searchFilter = useSelector((state) => state.faq.searchBody);
  const reload = useSelector((state) => state.faq.reload);
  const PageNo = useSelector((state) => state.faq.pageNo);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(PageNo ?? 1);
  const [question, setQuestion] = useState(searchFilter.question ?? "");
  const [answer, setAnswer] = useState(searchFilter.answer ?? "");
  const [searchBody, setSearchBody] = useState({
    type: 2,
    pageSize: 15,
  });

  useEffect(() => {
    dispatch(getAllFAQ(page, searchFilter));
  }, [searchFilter, page, dispatch]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    dispatch(savePageNo(newPage));
  };

  function deleteSelectedItem() {
    setDeleteLoading(true);
    dispatch(deleteFAQ(_FAQ.form.id, page, searchFilter)).then((res) => {
      setDeleteLoading(false);
      dispatch(resetForm());
      setOpenDeleteDialog(false);
    });
  }

  return (
    <Card>
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            maxWidth="sm"
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
              dispatch(resetForm());
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedItem()}
            selectedObject={_FAQ.form.questionEn}
            loading={deleteLoading}
          ></CustomDialog>
        )}
      </Box>
      <PerfectScrollbar>
        <Grid container spacing={2} sx={{ padding: 3 }}>
          <Grid item xs={3}>
            <TextField
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder="Question"
              variant="outlined"
              value={question}
              onChange={(e) => {
                setQuestion(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBody({
                      ...searchFilter,
                      question: question,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder="Answer"
              variant="outlined"
              value={answer}
              onChange={(e) => {
                setAnswer(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBody({
                      ...searchFilter,
                      answer: answer,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              options={RATING_QUESTIONS_TYPES || []}
              getOptionLabel={(option) => option.value}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Type" />}
              onChange={(event, value) => {
                dispatch(
                  saveSearchBody({
                    ...searchFilter,
                    type: value ? value.key : 2,
                  })
                );
                setSearchBody({
                  ...searchBody,
                  type: value ? value.key : null,
                });
              }}
              value={
                RATING_QUESTIONS_TYPES?.filter(
                  (x) => x.key === searchFilter.type
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={3} lg={3}>
            <Stack direction="row" sx={{ justifyContent: "start" }}>
              <IconButton
                disabled={reload}
                onClick={() => {
                  dispatch(saveSearchBody({ ...searchBody }));
                  setPage(1);
                }}
              >
                <Tooltip title="Search with filter">
                  <SearchIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                disabled={reload}
                onClick={() => {
                  setPage(1);
                  setQuestion("");
                  setAnswer("");
                  dispatch(
                    saveSearchBody({
                      pageSize: 15,
                      question: "",
                      isActive: null,
                      type: 2,
                    })
                  );
                  setSearchBody({
                    pageSize: 15,
                    question: "",
                    isActive: null,
                    type: 2,
                  });
                }}
              >
                <Tooltip title="Clear filter">
                  <Close />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {reload && <LinearProgress />}
          </Grid>
          {_FAQData?.data?.map((row, index) => (
            <Grid item xs={6} key={index}>
              <Accordion
                sx={{
                  backgroundColor:
                    row.type === "Orderii" ? "#E6E0FF" : "#FDF1E0",
                }}
              >
                <AccordionSummary
                  color="success"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Stack
                    width="100%"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack spacing={2}>
                      <Typography variant="body">{row.questionEn}</Typography>
                      <Typography variant="body">{row.questionAr}</Typography>
                      <Typography variant="body">{row.questionKu}</Typography>
                    </Stack>
                    <Stack
                      marginRight={5}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <RolePermission permission="41-05">
                        <IconButton
                          color="primary"
                          aria-label="edit"
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(getByIdForUpdate(row.id));
                          }}
                        >
                          <Tooltip title="Edit" aria-label="edit">
                            <EditIcon />
                          </Tooltip>
                        </IconButton>
                      </RolePermission>
                      <RolePermission permission="41-06">
                        <IconButton
                          color="error"
                          aria-label="delete"
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(getById(row.id)).then(() => {
                              setOpenDeleteDialog(true);
                            });
                          }}
                        >
                          <Tooltip title="Delete" aria-label="delete">
                            <DeleteForever />
                          </Tooltip>
                        </IconButton>
                      </RolePermission>
                    </Stack>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2}>
                    <Typography variant="body">{row.answerEn}</Typography>
                    <Typography variant="body">{row.answerAr}</Typography>
                    <Typography variant="body">{row.answerKu}</Typography>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Divider />
            <Stack
              sx={{ mt: 2 }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Pagination
                count={Math.ceil(_FAQData.rowCount / searchFilter.pageSize)}
                page={page}
                onChange={handlePageChange}
              />
              <Select
                value={searchFilter.pageSize}
                onChange={(event) =>
                  dispatch(
                    saveSearchBody({
                      ...searchFilter,
                      pageSize: event.target.value,
                    })
                  )
                }
              >
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
              <Typography>Total: {_FAQData.rowCount}</Typography>
            </Stack>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Card>
  );
}
