import { Grid, Box } from "@mui/material";
import React from "react";
import ExternalItemHeader from "./home/header/header";
import EXternalItemTable from "./home/table/List";

const ExternallItem = () => {
  return (
    <Box sx={{ minWidth: { md: '100%', xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <ExternalItemHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <EXternalItemTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExternallItem;
