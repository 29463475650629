import axiosService from "src/api/axiosService";


class GeneralSettingService {
  addGeneralSetting(data) {
    return axiosService.post(`/api/generalsetting/add`, data);
  }

  getGeneralSettingById(id) {
    return axiosService.get(`/api/generalsetting/getbyid/${id}`);
  }

  getGeneralSettingPageNo(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach(key => {
        params += `${key}=${data[key] == null ? "" : data[key]}&`;
      });
    }
    return axiosService.get(`/api/generalsetting/getall/${pageNo}?${params}`);
  }

  UpdateGeneralSetting(data) {
    return axiosService.put(`/api/generalsetting/update`, data);
  }

  deleteGeneralSetting(id) {
    return axiosService.delete(`/api/generalsetting/delete?id=${id}`);
  }

}

export default new GeneralSettingService();
