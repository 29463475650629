import { Helmet } from "react-helmet";
import { Box, IconButton, Stack } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { MdBrandingWatermark, MdCategory } from "react-icons/md";
import {
  openCategoryVendorDialog,
  openDialog,
  uploadFile,
} from "src/actions/vendors";
import VendorsForm from "../../from/Form";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RolePermission from "src/components/RolePermission";
import VendorsCategoryForm from "src/pages/cross/vendors/Vendors/from/categories";
import VendorsCategory from "src/pages/cross/vendors/VendorsCatagory";
import ShippingOptions from "src/pages/cross/vendors/shippingOptions";

const VendorsHeader = () => {
  const dispatch = useDispatch();

  const _Vendors = useSelector((state) => state.vendors.openDialog);
  const dialog = useSelector((state) => state.vendors.openVendorCategoryDialog);
  const vendorCategory = useSelector(
    (state) => state.vendors.openCategoryDialog
  );
  const _ShippingOption = useSelector(
    (state) => state.shippingOption.pageDialog
  );
  const [selectedFile, setSelectedFile] = useState(null);

  function uploadVendorFile() {
    const formData = new FormData();
    formData.append("file", selectedFile);
    dispatch(uploadFile(formData)).then((res) => {
      setSelectedFile(null);
    });
  }

  return (
    <>
      <Helmet>
        <title>Vendors | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Vendors
              </Typography>
            }
            avatar={<MdBrandingWatermark size={20} />}
            action={
              <Stack direction="row" spacing={2}>
                <RolePermission permission="35-04">
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    startIcon={<MdCategory />}
                    onClick={() => dispatch(openCategoryVendorDialog())}
                  >
                    VENDOR CATEGORY
                  </Button>
                </RolePermission>
                <RolePermission permission="33-08">
                  {!selectedFile && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      startIcon={<AddIcon />}
                      onClick={() => dispatch(openDialog())}
                    >
                      CREATE
                    </Button>
                  )}
                  {!selectedFile && (
                    <Button
                      variant="contained"
                      color="warning"
                      size="medium"
                      startIcon={<AttachFileIcon />}
                      component="label"
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                    >
                      CHOOSE FILE
                      <input
                        type="file"
                        accept="application/json"
                        style={{ display: "none" }}
                      />
                    </Button>
                  )}

                  {selectedFile && (
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<CloudUploadIcon />}
                        onClick={uploadVendorFile}
                      >
                        Upload
                      </Button>
                      <Box sx={{ display: "flex" }}>
                        <Typography sx={{ marginTop: "5px" }}>
                          File name: {selectedFile.name} , Size:{" "}
                          {selectedFile.size / 1000} KB
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSelectedFile(null);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </Stack>
                  )}
                </RolePermission>
              </Stack>
            }
          />
          {_Vendors ? <VendorsForm /> : null}
          {dialog ? <VendorsCategoryForm /> : null}
          {vendorCategory ? <VendorsCategory /> : null}
          {_ShippingOption ? <ShippingOptions /> : null}
        </Card>
      </Box>
    </>
  );
};

export default VendorsHeader;
