import helper from "src/utils/helper";
import ExchangeRate from "src/api/services/exchangeRate.service";
import { openAlertDialog, showErrorSnackbar } from "./snackbarMessages";
import { GET_EXCHANGE_RATE } from "src/actions/type";

export const getExchangeRate = () => async (dispatch) => {
  try {
    const res = await ExchangeRate.getExchangeRate();
    dispatch({
      type: GET_EXCHANGE_RATE,
      payload: res.data.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateExchangeRate = (data) => async (dispatch) => {
  try {
    const res = await ExchangeRate.updateExchangeRate(data);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
