import axios from "../axios";

class FAQService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`/faq/${pageNo}?${params}`);
  }

  getList() {
    return axios.get(`/faq/list`);
  }

  getById(id) {
    return axios.get(`/faq/getbyid/${id}`);
  }

  create(data) {
    return axios.post("/faq", data);
  }

  update(id, data) {
    return axios.put(`/faq/${id}`, data);
  }

  delete(id) {
    return axios.delete(`/faq/${id}`);
  }
}

export default new FAQService();
