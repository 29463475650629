import { Grid, Box } from "@mui/material";
import React from "react";
import GiftItemBrandHeader from "src/pages/gifts/brand/home/header/header";
import GiftItemBrandTable from "src/pages/gifts/brand/home/table";

const GiftItemBrand = () => {
  return (
    <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <GiftItemBrandHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <GiftItemBrandTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GiftItemBrand;
