import {
  SAVE_SEARCH_BODY_HOME_ITEMS,
  RELOAD_HOME_ITEMS,
  GET_ALL_HOME_ITEMS,
  OPEN_DIALOG_HOME_ITEMS,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  rowCount: 0,
  data: [],
  vendorId: null,
};

function homeItemsReducer(homeItems = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_HOME_ITEMS:
      homeItems.data = payload.data;
      // homeItems.rowCount = payload.rowCount;
      return homeItems;

    case SAVE_SEARCH_BODY_HOME_ITEMS:
      return { ...homeItems, vendorId: payload };

    case RELOAD_HOME_ITEMS:
      return { ...homeItems, reload: !homeItems.reload };

    case OPEN_DIALOG_HOME_ITEMS:
      return { ...homeItems, openDialog: !homeItems.openDialog };

    default:
      return homeItems;
  }
}

export default homeItemsReducer;
