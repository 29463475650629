import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import { indigo } from "@mui/material/colors";
import { BiCategory } from "react-icons/bi";
import { useSelector } from "react-redux";

const TotalCategories = () => {
  const data = useSelector(
    (state) => state.giftDashboard.allCount.categoriesCount
  );
  return (
    <Card>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              TOTAL CATEGORIES
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {data?.toLocaleString() ?? ""}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: indigo[600],
                height: 56,
                width: 56,
              }}
            >
              <BiCategory size={30} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TotalCategories;
