import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { openCategoryDialog } from "src/actions/ticket/category";
import CategoryIcon from "@mui/icons-material/Category";
import RolePermission from "src/components/RolePermission";
import TicketCategoryForm from "src/pages/cross/Ticket/category/Form";
import TicketCategoryList from "src/pages/cross/Ticket/category/List";

const TicketCategory = () => {
  const ticketCategory = useSelector((state) => state.ticketCategory);
  const dispatch = useDispatch();

  return (
    <>
      <Helmet>
        <title>Ticket Category| Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false} sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
          <Card>
            <CardHeader
              title={
                <Typography variant="h4" component="div">
                  Ticket Category
                </Typography>
              }
              avatar={<CategoryIcon size={20} />}
              action={
                <RolePermission permission="T1-01">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={() => dispatch(openCategoryDialog())}
                  >
                    Create Ticket Category
                  </Button>
                </RolePermission>
              }
            />
            {ticketCategory.dialog ? <TicketCategoryForm /> : null}
          </Card>

          <Box sx={{ pt: 2 }}>
            <TicketCategoryList />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default TicketCategory;
