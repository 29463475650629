import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import RolePermission from "src/components/RolePermission";
import { openShippingOptionsFormDialog } from "src/actions/shippingOptions";
import { MdFlight } from "react-icons/md";
import ShippingOptionsForm from "src/pages/cross/vendors/shippingOptions/from/Form";
import theme from "src/theme";

const ShippingOptionsHeader = () => {
  const dispatch = useDispatch();

  const _ShippingOption = useSelector(
    (state) => state.shippingOption.formDialog
  );
  const selectedVendor = useSelector(
    (state) => state.shippingOption.selectedVendor
  );

  return (
    <>
      <Helmet>
        <title>Shipping Options | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Shipping Options For{" "}
                <span
                  style={{
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                  }}
                >
                  {selectedVendor?.name}
                </span>
              </Typography>
            }
            avatar={<MdFlight size={20} />}
            action={
              <RolePermission permission="63-01">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => dispatch(openShippingOptionsFormDialog())}
                >
                  Add
                </Button>
              </RolePermission>
            }
          />
          {_ShippingOption ? <ShippingOptionsForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default ShippingOptionsHeader;
