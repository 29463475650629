import axiosService from "src/api/axiosService";


class CategoryService {
  createCategory(data) {
    return axiosService.post(`/ticket-service/api/category/`, data);
  }

  getCategory() {
    return axiosService.get(`/ticket-service/api/category`);
  }

  getCategoryById(id) {
    return axiosService.get(`/ticket-service/api/category/byid/${id}`);
  }

  getCategoryPageNo(pageNo) {
    return axiosService.get(`/ticket-service/api/category/${pageNo}`);
  }

  UpdateCategory(id, data) {
    return axiosService.put(`/ticket-service/api/category/${id}`, data);
  }

  deleteCategory(id) {
    return axiosService.delete(`/ticket-service/api/category/${id}`);
  }

}

export default new CategoryService();
