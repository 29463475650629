import {
  Autocomplete,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useEffect, forwardRef, useState } from "react";
import * as React from "react";
import BrandService from "src/api/giftServices/brand.service";
import useDebounce from "src/hooks/useDebounce";

const SelectGiftBrand = forwardRef(({ ...props }, ref) => {
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const debouncedSearch = useDebounce(search, 500);

  const fetchGiftBrand = async () => {
    setLoading(true);
    try {
      const res = await BrandService.getGiftsBrandList();
      setOptions(res.data.data);
      setLoading(false);
    } catch (error) {
      setOptions([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGiftBrand(debouncedSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <Autocomplete
      {...props}
      ref={ref}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderOption={(props, option) => (
        <div key={option.id}>
          <Typography variant="subtitle2" {...props}>
            {option.nameEn}
          </Typography>
        </div>
      )}
      isOptionEqualToValue={(option, value) => option?.id === value?.id || ""}
      getOptionLabel={(option) => `${option.nameEn}`}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          error={props.error}
          helperText={props.helpertext}
          label={props.label || "Brands"}
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
});

export default SelectGiftBrand;
