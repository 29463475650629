import { RELOAD_ATTACHMENTS, UPLOAD_ATTACHMENTS } from "../actions/type";

const initialState = {
  reload: false,
  uploadAttachments: {
    file: "",
  },
  data: {
    id: null,
  },
};

function uploadAttachmentReducer(uploadAttachments = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RELOAD_ATTACHMENTS:
      return { ...uploadAttachments, reload: !payload };

    case UPLOAD_ATTACHMENTS:
      uploadAttachments.data = payload.data;
      return uploadAttachments;

    default:
      return uploadAttachments;
  }
}

export default uploadAttachmentReducer;
