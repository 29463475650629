import {
  DYNAMIC_AUTH_RESET_FORM,
  DYNAMIC_AUTH_GET_BY_ID,
  DYNAMIC_AUTH_PAGE_NUMBER,
  DYNAMIC_AUTH_OPEN_DIALOG,
  DYNAMIC_AUTH_RELOAD,
  DYNAMIC_AUTH_SEARCH_BODY,
  DYNAMIC_AUTH_GET_ALL_ROLE,
  DYNAMIC_AUTH_GET_ALL_ROLE_END_POINT,
  DYNAMIC_AUTH_GET_ALL_ORDERII_END_POINT,
  DYNAMIC_AUTH_GET_ALL_STAFF_USERS,
  DYNAMIC_AUTH_OPEN_STAFF_USERS_DIALOG,
  DYNAMIC_AUTH_RESET_STAFF_USERS_FORM,
  DYNAMIC_AUTH_GET_BY_ID_STAFF_USERS,
} from "./type";

import DynamicAuthService from "../api/services/dynamicAuth.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const openDialog = (data) => (dispatch) => {
  dispatch({
    type: DYNAMIC_AUTH_OPEN_DIALOG,
    payload: data,
  });
};

export const openStaffUsersDialog = () => async (dispatch) => {
  try {
    await dispatch({
      type: DYNAMIC_AUTH_OPEN_STAFF_USERS_DIALOG,
    });
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getByIdStaffUsers = (data) => (dispatch) => {
  dispatch({
    type: DYNAMIC_AUTH_GET_BY_ID_STAFF_USERS,
    payload: data,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: DYNAMIC_AUTH_RESET_FORM,
  });
};

export const resetStaffUsersForm = () => (dispatch) => {
  dispatch({
    type: DYNAMIC_AUTH_RESET_STAFF_USERS_FORM,
  });
};

export const reloadRoles = () => (dispatch) => {
  dispatch({
    type: DYNAMIC_AUTH_RELOAD,
  });
};

export const saveDynamicAuthPageNum = (data) => (dispatch) => {
  dispatch({
    type: DYNAMIC_AUTH_PAGE_NUMBER,
    payload: data,
  });
};

export const saveDynamicAuthSearchBody = (data) => (dispatch) => {
  dispatch({
    type: DYNAMIC_AUTH_SEARCH_BODY,
    payload: data,
  });
};

export const createRole = (data, pagNo, pageSize) => async (dispatch) => {
  try {
    const res = await DynamicAuthService.createRole(data);
    dispatch(showSuccessSnackbar("Created successfully!"));
    dispatch(getAllRoles(pagNo, pageSize));
    dispatch(openDialog());
    dispatch(resetForm());
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllRoles = (pageNumber, pageSize) => async (dispatch) => {
  try {
    dispatch(reloadRoles());
    const res = await DynamicAuthService.getAllRoles(pageNumber, pageSize);
    dispatch({
      type: DYNAMIC_AUTH_GET_ALL_ROLE,
      payload: res.data,
    });
    dispatch(reloadRoles());
  } catch (err) {
    dispatch(reloadRoles());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getByIdRole = (id) => async (dispatch) => {
  try {
    dispatch(reloadRoles());
    const res = await DynamicAuthService.getByIdRole(id);
    dispatch(reloadRoles());
    dispatch({
      type: DYNAMIC_AUTH_GET_BY_ID,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch(reloadRoles());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
export const getByIdRoleForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(reloadRoles());
    const res = await DynamicAuthService.getByIdRole(id);
    dispatch({
      type: DYNAMIC_AUTH_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadRoles());
    dispatch(openDialog());
  } catch (err) {
    dispatch(reloadRoles());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteRole = (id, pagNo, pageSize) => async (dispatch) => {
  try {
    const res = await DynamicAuthService.deleteRole(id);
    dispatch(showSuccessSnackbar("Deleted successfully!"));
    dispatch(getAllRoles(pagNo, pageSize));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const createRoleEndPoint =
  (data, pagNo, pageSize) => async (dispatch) => {
    try {
      const res = await DynamicAuthService.createRoleEndPoint(data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllRoles(pagNo, pageSize));
      dispatch(openDialog());
      dispatch(resetForm());
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getAllRoleEndPoint =
  (pageNumber, pageSize) => async (dispatch) => {
    try {
      dispatch(reloadRoles());
      const res = await DynamicAuthService.getAllRoleEndPoint(
        pageNumber,
        pageSize
      );
      dispatch({
        type: DYNAMIC_AUTH_GET_ALL_ROLE_END_POINT,
        payload: res.data.data,
      });
      dispatch(reloadRoles());
    } catch (err) {
      dispatch(reloadRoles());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const updateRoleEndPoint =
  (data, pagNo, pageSize) => async (dispatch) => {
    try {
      const res = await DynamicAuthService.updateRoleEndPoint(data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllRoleEndPoint(pagNo, pageSize));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteRoleEndPoint = (id, pagNo, pageSize) => async (dispatch) => {
  try {
    const res = await DynamicAuthService.deleteRoleEndPoint(id);
    dispatch(showSuccessSnackbar("Deleted successfully!"));
    dispatch(getAllRoleEndPoint(pagNo, pageSize));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllEndPoints = (pageNumber, pageSize) => async (dispatch) => {
  try {
    dispatch(reloadRoles());
    const res = await DynamicAuthService.getAllOrderiiEndPont(
      pageNumber,
      pageSize
    );
    dispatch({
      type: DYNAMIC_AUTH_GET_ALL_ORDERII_END_POINT,
      payload: res.data,
    });
    dispatch(reloadRoles());
  } catch (err) {
    dispatch(reloadRoles());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllStaffUsers = (data) => async (dispatch) => {
  try {
    dispatch(reloadRoles());
    const res = await DynamicAuthService.getAllStaffUsers(data);
    dispatch({
      type: DYNAMIC_AUTH_GET_ALL_STAFF_USERS,
      payload: res.data.data,
    });
    dispatch(reloadRoles());
  } catch (err) {
    dispatch(reloadRoles());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateUserRoles = (data, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadRoles());
    const res = await DynamicAuthService.updateUserRoles(data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    dispatch(openStaffUsersDialog());
    dispatch(getAllStaffUsers(searchBody));
    dispatch(reloadRoles());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadRoles());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
