import { Grid, Box } from "@mui/material";
import React from "react";
import FavoriteHeader from "./home/header/header";
import FavoriteTable from "./home/table";

const Favorite = () => {
  return (
    <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <FavoriteHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <FavoriteTable />
        </Grid>
      </Grid>
      <Box sx={{ paddingX: 2 }}></Box>
    </Box>
  );
};

export default Favorite;
