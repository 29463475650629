import EPaymentsService from "src/api/services/EPayments.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import {
  OPEN_EPAYMENTS_DIALOG,
  GET_ALL_EPAYMENTS,
  SAVE_SEARCH_BODY_EPAYMENTS,
  GET_BY_ID_EPAYMENTS,
  RELOAD_EPAYMENTS_,
  RESET_FORM_EPAYMENTS,
} from "./type";

export const openEPaymentsDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_EPAYMENTS_DIALOG,
    payload: data,
  });
};

export const reloadEPayments = (data) => (dispatch) => {
  dispatch({
    type: RELOAD_EPAYMENTS_,
    payload: data,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: RESET_FORM_EPAYMENTS,
  });
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_BODY_EPAYMENTS,
    payload: data,
  });
};

export const createEPayments = (data, type) => async (dispatch) => {
  try {
    const res = await EPaymentsService.create(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(getAllEPayments(type));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllEPayments = (type) => async (dispatch) => {
  try {
    dispatch(reloadEPayments());
    const res = await EPaymentsService.getAll(type);
    dispatch({
      type: GET_ALL_EPAYMENTS,
      payload: res.data.data,
    });
    dispatch(reloadEPayments());
  } catch (err) {
    dispatch(reloadEPayments());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getEPaymentsById = (data) => async (dispatch) => {
  dispatch({
    type: GET_BY_ID_EPAYMENTS,
    payload: data,
  });
};

export const UpdateEPayments = (data, type) => async (dispatch) => {
  try {
    const res = await EPaymentsService.update(data);
    dispatch(showSuccessSnackbar("Updated successfully"));
    dispatch(getAllEPayments(type));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteEPayments = (id, type) => async (dispatch) => {
  try {
    const res = await EPaymentsService.delete(id);
    dispatch(showSuccessSnackbar("Deleted successfully"));
    dispatch(getAllEPayments(type));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
