import { TextField, Grid, Stack, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  createProhibitedItems,
  openDialog,
  resetForm,
  updateProhibitedItems,
} from "src/actions/prohibitedItems";

const ProhibitedItemsForm = () => {
  const dispatch = useDispatch();

  const _ProhibitedItems = useSelector((state) => state.prohibitedItems);
  const loading = useSelector((state) => state.prohibitedItems.reload);
  const pageNo = useSelector((state) => state.prohibitedItems.pageNo);

  const isAdd = _ProhibitedItems.form.id ? false : true;

  const { control, reset, handleSubmit } = useForm({
    defaultValues: _ProhibitedItems.form,
  });

  useEffect(() => {
    reset({ ..._ProhibitedItems.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_ProhibitedItems.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(createProhibitedItems(data, pageNo));
    } else {
      dispatch(updateProhibitedItems(data, pageNo));
    }
  };

  const handleDialog = () => {
    dispatch(openDialog());
  };

  return (
    <CustomDialog
      title={
        isAdd
          ? "Create Prohibited Items Reason"
          : "Update Prohibited Items Reason"
      }
      isOpen={_ProhibitedItems.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="nameEn"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="nameAr"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="nameKu"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name Kurdish"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
              >
                Save
              </LoadingButton>
              {isAdd && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => reset({ ..._ProhibitedItems.form })}
                >
                  Clear Text
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default ProhibitedItemsForm;
