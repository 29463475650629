import {
  Grid,
  Stack,
  FormControlLabel,
  Divider,
  Chip,
  Checkbox,
  Typography,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  getAllGiftItem,
  openUpdateMultiFilterCollectionDialog,
  resetGiftItemEditAfterOrderForm,
  updateMultiFilterCollection,
} from "src/actions/gifts/giftItem";
import { getGiftsCategoryList } from "src/actions/gifts/giftCategory";

const UpdateMultiFilterCollectionForm = (props) => {
  const dispatch = useDispatch();

  const giftItem = useSelector(
    (state) => state.giftItems.updateMultiFilterCollection
  );
  const loading = useSelector((state) => state.giftItems.reload);
  const pageNo = useSelector((state) => state.giftItems.saveGiftItemsPageNum);
  const searchBody = useSelector((state) => state.giftItems.searchBody);

  useEffect(() => {
    dispatch(getGiftsCategoryList());
  }, [dispatch]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
    setValue,
  } = useForm({
    defaultValues: giftItem.form,
  });

  const handleDialog = () => {
    dispatch(openUpdateMultiFilterCollectionDialog());
  };

  useEffect(() => {
    reset({ ...giftItem.form });
  }, [reset, giftItem.form]);

  useEffect(() => {
    return () => {
      dispatch(resetGiftItemEditAfterOrderForm());
    };
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(
      updateMultiFilterCollection({
        giftItemIds: props.selectedItems.map((item) => item.id),
        filters: data.filters,
      })
    ).then((x) => {
      reset({ ...giftItem.form });
      dispatch(resetGiftItemEditAfterOrderForm());
      handleDialog();
      dispatch(getAllGiftItem(pageNo, searchBody));
    });
  };

  return (
    <CustomDialog
      title={"Add Filter Collection"}
      isOpen={giftItem.dialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Divider>
              <Chip label="Selected Items" sx={{ my: 2 }} />
            </Divider>
          </Grid>
          <Grid item xs={12}>
            {props.selectedItems?.map((item, index) => (
              <Stack
                key={index}
                direction="row"
                spacing={2}
                sx={{ my: 1 }}
                justifyContent="space-between"
              >
                <Typography variant="h4" key={item.id} sx={{ my: 1 }}>
                  {index + 1}: {item.nameEn}
                </Typography>
                <Box
                  component="img"
                  src={item.giftItemAttachments[0]?.url}
                  width={150}
                  sx={{ objectFit: "contain" }}
                />
              </Stack>
            ))}
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider>
              <Chip label="Filter Collection" sx={{ my: 2 }} />
            </Divider>
          </Grid>
          <Grid item xs={12} md={12}>
            {props.selectedFilterCollection?.map((filter, index) => (
              <Box key={index} sx-={{ py: 25 }}>
                <Typography variant="h4" key={filter.id}>
                  {filter.nameEn}:
                </Typography>
                <Grid container spacing={2} padding={2}>
                  <Grid item xs={12} md={12}>
                    {filter?.values?.map((value, index) => (
                      <Controller
                        key={index}
                        name="filters"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            key={value.id}
                            control={
                              <Checkbox
                                value={value.id}
                                checked={field.value.includes(value.id)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setValue(
                                      "filters",
                                      [...field.value, value.id],
                                      {
                                        shouldDirty: true,
                                      }
                                    );
                                  } else {
                                    setValue(
                                      "filters",
                                      field.value.filter(
                                        (id) => id !== value.id
                                      ),
                                      {
                                        shouldDirty: true,
                                      }
                                    );
                                  }
                                }}
                              />
                            }
                            label={value.nameEn}
                          />
                        )}
                      />
                    ))}
                  </Grid>
                </Grid>
                <Divider sx={{ my: 4 }} />
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};
export default UpdateMultiFilterCollectionForm;
