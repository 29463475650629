import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Card, CardHeader, Typography } from "@mui/material";
import { FaShoppingBag } from "react-icons/fa";

const UserOrdersHeader = () => {
  return (
    <>
      <Helmet>
        <title>Customer Orders | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Customer Orders
              </Typography>
            }
            avatar={<FaShoppingBag size={20} />}
          />
        </Card>
      </Box>
    </>
  );
};

export default UserOrdersHeader;
