import axios from "../axios";

class OrderCancelReasonService {
  getAll(pageNo) {
    return axios.get(`/ordercancelreason/All/${pageNo}`);
  }

  getList() {
    return axios.get(`/ordercancelreason/list`);
  }

  getById(id) {
    return axios.get(`/ordercancelreason/getById/${id}`);
  }

  create(data) {
    return axios.post("/ordercancelreason/create", data);
  }

  update(data) {
    return axios.put(`/ordercancelreason/update`, data);
  }

  delete(id) {
    return axios.delete(`/ordercancelreason/delete/${id}`);
  }
  
}

export default new OrderCancelReasonService()