import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  Typography,
  Stack,
  Icon,
  Chip,
  Divider,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { getUserOrders } from "src/actions/orders";
import { MdFactCheck } from "react-icons/md";

export default function UserOrdersTable() {
  const _UserOrders = useSelector((state) => state.orders.userOrdersData);
  const loading = useSelector((state) => state.orders.orderDetailsReload);

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname.replace("/app/user_orders/", "")) {
      dispatch(
        getUserOrders(window.location.pathname.replace("/app/user_orders/", ""))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "number",
      headerName: "Number",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.number}{" "}
          <Tooltip title="order details">
            <IconButton
              href={`/app/order/${params.row.id ? params.row.id : ""}`}
              target="_blank"
              rel="noreferrer"
            >
              <MdFactCheck color="primary" />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdAt).format("YYYY-MM-DD")}
        </Typography>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount $",
      flex: 1,
      renderCell: (params) => params.row.totalAmount?.toLocaleString(),
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Box>
          {params.row.status === "Paid" ? (
            <Chip label="Paid" color="warning" size="small" />
          ) : params.row.status === "Canceled" ? (
            <Chip label="Canceled" color="error" size="small" />
          ) : params.row.status === "Delivered" ? (
            <Chip label="Delivered" color="success" size="small" />
          ) : params.row.status === "Out for delivery" ? (
            <Chip label="Out for delivery" color="success" size="small" />
          ) : params.row.status === "In Review" ? (
            <Chip label="In Review" color="info" size="small" />
          ) : (
            <Chip
              label={params.row.status?.replace(/([a-z])([A-Z])/g, "$1 $2")}
              color="secondary"
              size="small"
            />
          )}
        </Box>
      ),
    },
    {
      field: "totalAmountAtCreationDate",
      headerName: "Total Amount At Creation Date $",
      flex: 1,
      renderHeader: (params) => (
        <Typography
          sx={{
            whiteSpace: "pre-wrap",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Total Amount At Creation Date $
        </Typography>
      ),
      renderCell: (params) =>
        params.row.totalAmountAtCreationDate?.toLocaleString(),
    },
  ];

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction="column" margin={2} spacing={1}>
              <Stack direction={"row"} spacing={1} marginLeft={0.5}>
                <Tooltip title="Customer name">
                  <Icon>account_circle</Icon>
                </Tooltip>
                <Typography variant="h5">{_UserOrders?.fullName}</Typography>
              </Stack>
              <Stack direction={"row"} spacing={1} marginLeft={0.5}>
                <Tooltip title="Customer phoneNumber">
                  <Icon>call</Icon>
                </Tooltip>
                <Typography variant="h5">0{_UserOrders?.phone}</Typography>
              </Stack>
              <Stack
                direction={"row"}
                spacing={1}
                justifyItems="center"
                alignItems="center"
              >
                <Tooltip title="Customer wallet">
                  <IconButton
                    size="small"
                    color="primary"
                    href={`/app/wallet/${_UserOrders?.id}`}
                  >
                    <Icon>account_balance_wallet</Icon>
                  </IconButton>
                </Tooltip>
                <Typography variant="h5">${_UserOrders?.balance}</Typography>
              </Stack>
              <Typography variant="h5" marginLeft={0.5}>
                Number Of Orders : {_UserOrders?.orders?.length}
              </Typography>
            </Stack>

            <Divider />
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box
                sx={{
                  height: { md: "calc(100vh - 390px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  columns={columns}
                  rows={
                    _UserOrders?.orders?.map((data, index) => {
                      return { ...data, index: index + 1 };
                    }) || []
                  }
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={40}
                  pagination
                  pageSize={100}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
