import {
  GET_ALL_MOBILE_APP_VERSIONS,
  CREATE_MOBILE_APP_VERSION,
  DELETE_MOBILE_APP_VERSION,
  UPDATE_MOBILE_APP_VERSION,
} from "./type";

import MobileAppVersionService from "../api/services/mobileAppVersion.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const createMobileAppVersion = (data) => async (dispatch) => {
  try {
    const res = await MobileAppVersionService.create(data);
    dispatch({
      type: CREATE_MOBILE_APP_VERSION,
      payload: res.data.data,
    });
    dispatch(showSuccessSnackbar("Created successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllMobileAppVersions = (pageNo) => async (dispatch) => {
  try {
    const res = await MobileAppVersionService.getAll(pageNo);
    dispatch({
      type: GET_ALL_MOBILE_APP_VERSIONS,
      payload: res.data.data,
    });
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateMobileAppVersion = (data) => async (dispatch) => {
  try {
    const res = await MobileAppVersionService.update(data);
    dispatch({
      type: UPDATE_MOBILE_APP_VERSION,
      payload: data,
    });
    dispatch(showSuccessSnackbar("Updated successfully!"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteMobileAppVersion = (id) => async (dispatch) => {
  try {
    await MobileAppVersionService.delete(id);
    dispatch({
      type: DELETE_MOBILE_APP_VERSION,
      payload: { id },
    });
    dispatch(
      showSuccessSnackbar("Mobile App Version Code deleted successfully")
    );
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getById = (id) => async (dispatch) => {
  try {
    const res = await MobileAppVersionService.getById(id);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
