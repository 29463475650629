import moment from "moment";
import {
  OPEN_IMAGE_UPLOAD_DEALOG,
  CREATE_SHARE_CENTER,
  GET_ALL_SHARE_CENTER,
  GET_BY_ID,
  UPDATE_SHARE_CENTER,
  DELETE_SHARE_CENTER,
  RELOAD_SHARE_CENTER,
  SAVE_SEARCH_BODY_SHARE_CENTER,
  SAVE_SEARCH_PAGE_NO_SC,
  SHARE_CENTER_RELOAD
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageCount: 0,
  rowCount: 0,
  pageSize: 10,
  pageNo: 0,
  createShareCenter: {
    description: '',
    attachmentIds: [],
    departmentId: 0,
    categoryId: 0
  },
  data: {
    id: null,
    name: '',
    fileType: null,
    url: '',
    createdAt: '',
    uploadDate: ''
  },
  searchBody: {
    pageSize: 10
  },
  getById: {}
};

function shareCenterReducer(ShareCenter = initialState, action) {
  const { type, payload } = action;
  switch (type) {

    case GET_ALL_SHARE_CENTER:
      var _ShareCenter = {
        reload: ShareCenter.reload,
        data: payload.data,
        pageCount: payload.pageCount,
        rowCount: payload.rowCount,
        pageSize: payload.pageSize,
        searchBody: ShareCenter.searchBody,
        pageNo: ShareCenter.pageNo,
        getById: ShareCenter.getById,
        createShareCenter: ShareCenter.createShareCenter,
        openDialog: false,
        savedPageNumber: ShareCenter.savedPageNumber,
        statusHistory: ShareCenter.statusHistory
      }
      return _ShareCenter;

    case GET_BY_ID:
      ShareCenter.getById = payload
      return ShareCenter

    case SHARE_CENTER_RELOAD:
      return { ...ShareCenter, reload: !payload };


    case CREATE_SHARE_CENTER:
      ShareCenter.data = payload.data
      return ShareCenter

    case SAVE_SEARCH_BODY_SHARE_CENTER:
      return { ...ShareCenter, searchBody: payload };

    case SAVE_SEARCH_PAGE_NO_SC:
      ShareCenter.pageNo = payload;
      return ShareCenter

    case OPEN_IMAGE_UPLOAD_DEALOG:
      return { ...ShareCenter, openDialog: !payload };

    case RELOAD_SHARE_CENTER:
      return { ...ShareCenter, reload: !payload };

    case UPDATE_SHARE_CENTER:
      // ShareCenter.data = action.id
      // ShareCenter.data = action.status
      var indexToChange = ShareCenter.data.indexOf(
        ShareCenter.data.filter((x) => x.id === payload.id)[0]
      );
      ShareCenter.data[indexToChange].name = payload.name;
      ShareCenter.data[indexToChange].fileType = payload.fileType;
      return ShareCenter;

    case DELETE_SHARE_CENTER:
      ShareCenter.data.filter((x) => x.id == payload.id)
      return ShareCenter;

    default:
      return ShareCenter;
  }
}



export default shareCenterReducer;
