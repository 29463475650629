import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, Grid, LinearProgress, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DataGrid } from "@mui/x-data-grid";
import {
  deleteRole,
  getAllEndPoints,
  getAllRoles,
  getByIdRole,
  getByIdRoleForUpdate,
  resetForm,
} from "src/actions/dynamicAuth";
import { FaUsersCog } from "react-icons/fa";
import { CustomDialog } from "src/components/CustomDialog";
import CustomSpinner from "src/components/CustomSpinner";

export default function DynamicAuthTable() {
  const _DynamicAuth = useSelector((state) => state.dynamicAuth);

  const [refresh, setRefresh] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllRoles());
  }, [dispatch, refresh]);

  useEffect(() => {
    dispatch(getAllEndPoints());
  }, []);

  function deleteDynamicAuth() {
    dispatch(deleteRole(_DynamicAuth.roleForm.id)).then((res) => {
      setOpenDeleteDialog(false);
    });
  }

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "userCount",
      headerName: "User Count",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Grid container justifyContent='center'>
          <Grid item xs={2}>
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={() => {
                dispatch(getByIdRoleForUpdate(params.row.id));
              }}
            >
              <FaUsersCog size={20} />
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            {params.row.userCount > 0 ? null : (
              <IconButton
                color="error"
                aria-label="delete"
                onClick={() => {
                  dispatch(getByIdRole(params.row.id)).then((res) => {
                    setOpenDeleteDialog(true);
                  });
                }}
              >
                <DeleteForeverIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ),
    },
  ];

  return (
    <Card>
      {openDeleteDialog && (
        <CustomDialog
          isOpen={openDeleteDialog}
          handleClose={() => {
            setOpenDeleteDialog(false);
            dispatch(resetForm());
          }}
          isActiveDelete
          isRequierdActions
          title="Delete confirmation"
          onDelete={() => deleteDynamicAuth()}
          selectedObject={_DynamicAuth.roleForm.name}
        ></CustomDialog>
      )}
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {!_DynamicAuth.dynamicAuthRoleData ? (
            <CustomSpinner />
          ) : (
            <Box sx={{ height: { md: "calc(100vh - 270px)", xs: 1000 } }}>
              <DataGrid
                sx={{
                  "& .css-204u17-MuiDataGrid-main": {
                    paddingLeft: "15px",
                  },
                  "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                    paddingLeft: "15px",
                  },
                  ".MuiDataGrid-cell": {
                    justifyContent: "center",
                  },
                }}
                rows={_DynamicAuth.dynamicAuthRoleData.data || []}
                loading={_DynamicAuth.reload}
                columns={columns}
                rowHeight={50}
                disableSelectionOnClick
                pagination
                rowsPerPageOptions={[10, 25, 100]}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
