import axios from "../axios";

class HomeItemsService {
  getAll(id) {
    var vendorId = id ? `?vendorId=${id}` : "";
    return axios.get(`/homeitems/list${vendorId}`);
  }
  delete(id) {
    return axios.delete(`/homeitems/${id}`);
  }
  update(data) {
    return axios.put(`/homeitems/`, data);
  }
}
export default new HomeItemsService();
