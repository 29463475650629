import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Chip, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { updateProhibitedCartItems } from "src/actions/cart";

export default function ProhibitedItemsReasonsMenu({
  onCancel = function () {},
  reasonNumber = "",
  onSuccess = function () {},
  id,
  cartId,
}) {
  const dispatch = useDispatch();

  const _UserInfo = useSelector((state) => state.users.userInfo);
  const prohibitedItems = useSelector(
    (state) => state.prohibitedItems.prohibitedItemsDataList
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleUpdateReason(e) {
    dispatch(
      updateProhibitedCartItems({
        cartId: cartId,
        itemId: id,
        prohibitedItemReasonId: e.target.id,
      })
    ).then((res) => {
      onSuccess("Cart Updated successfully");
    });
  }

  const getReasonIcon = (reasonNumber) => {
    return (
      <Tooltip
        title={
          prohibitedItems.filter((data) => data.id === reasonNumber)[0]
            ?.nameEn ? (
            <Typography>
              {
                prohibitedItems.filter((data) => data.id === reasonNumber)[0]
                  ?.nameEn
              }
            </Typography>
          ) : (
            ""
          )
        }
      >
        <Chip
          sx={{ my: 1 }}
          size="small"
          label={
            prohibitedItems.filter((data) => data.id === reasonNumber)[0]
              ?.nameEn ?? "Approved"
          }
          color={reasonNumber ? "primary" : "default"}
          clickable
          onClick={handleClick}
        />
      </Tooltip>
    );
  };

  return (
    <React.Fragment>
      {getReasonIcon(reasonNumber)}
      <Menu
        anchorEl={anchorEl}
        open={
          !_UserInfo.roleOEndpoint.map((data) => data.Code).includes("06-23")
            ? false
            : open
        }
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem id={null} onClick={handleUpdateReason}>
          {/* <ListItemIcon>
              <Icon>{reason?.icon}</Icon>
            </ListItemIcon> */}
          Approved
        </MenuItem>
        {prohibitedItems.map((reason) => (
          <MenuItem
            key={reason.id}
            id={reason.id}
            onClick={
              reason.nameEn === "Canceled" ? onCancel : handleUpdateReason
            }
          >
            {/* <ListItemIcon>
              <Icon>{reason?.icon}</Icon>
            </ListItemIcon> */}
            {reason.nameEn}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
