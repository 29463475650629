import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  LinearProgress,
  Autocomplete,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showErrorSnackbar } from "src/actions/snackbarMessages";
import { assignTicket } from "src/actions/ticket/ticket";
import { CustomDialog } from "src/components/CustomDialog";
import CustomSpinner from "src/components/CustomSpinner";

export default function AssignessList(props) {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const [employeeId, setEmployeeId] = useState("");

  const ticketDetailsData = useSelector((state) => state.tickets.ticketDetails);

  const employeeData = useSelector((state) => state.employee.data);

  const ticketId = window.location.pathname.replace(
    "/app/ticket/TicketDetails/",
    ""
  );

  const handleAssignees = () => {
    if (employeeId == "") dispatch(showErrorSnackbar("Please select assignee"));
    else {
      setReload(true);
      dispatch(
        assignTicket(
          {
            ticketId: ticketDetailsData.id,
            userId: employeeId.userId,
          },
          ticketId
        )
      ).then((x) => {
        setReload(false);
      });
    }
  };

  return (
    <Box>
      {!ticketDetailsData ? (
        <CustomSpinner />
      ) : (
        <CustomDialog
          isOpen={props.isOpen}
          handleClose={props.isClosed}
          title="Assignees"
          maxWidth="sm"
        >
          {reload && <LinearProgress />}
          <Grid container spacing={2} sx={{ marginTop: 0 }}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                sx={{ justifyContent: "space-between" }}
              >
                <Autocomplete
                  id={employeeData.id}
                  options={employeeData}
                  getOptionLabel={(option) => option.username}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Employees" />
                  )}
                  onChange={(event, value) => {
                    setEmployeeId(value ?? null);
                  }}
                  // value={employeeId}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                onClick={handleAssignees}
                variant="contained"
                color="primary"
              >
                Assign
              </Button>
            </Grid>
          </Grid>
        </CustomDialog>
      )}
    </Box>
  );
}
