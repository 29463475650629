import { useState, useEffect } from "react";
import { Button, TextField, Grid, LinearProgress, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import {
  createEmployee,
  openEmployeeDialog,
} from "src/actions/department/employee";
import { getByPhoneNumber } from "src/actions/users";
import { showErrorSnackbar } from "src/actions/snackbarMessages";

const EmployeeForm = () => {
  const [employee, setEmployee] = useState({
    phoneNumber: "",
    role: "",
  });
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [viewConfirm, setViewConfirm] = useState(false);

  const dispatch = useDispatch();
  const employeeDialog = useSelector((state) => state.employee.dialog);

  const getUserID = (e) => {
    e.preventDefault();
    setUserData({});
    setIsLoading(true);
    dispatch(getByPhoneNumber(employee.phoneNumber))
      .then((res) => {
        if (res.data == null) {
          dispatch(showErrorSnackbar("No user name with this phone number"));
          setIsLoading(false);
        } else {
          setUserData(res.data);
          setViewConfirm(!viewConfirm);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (userData) {
      dispatch(
        createEmployee({
          userId: userData.id,
          role: employee.role,
        })
      )
        .then((res) => {
          setEmployee({
            phoneNumber: "",
            role: "",
          });
          setUserData(null);
          setIsLoading(false);
          handlDialog();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [viewConfirm]);

  const handlDialog = () => {
    dispatch(openEmployeeDialog(employeeDialog));
  };

  return (
    <CustomDialog
      title={"CREATE Employee"}
      isOpen={employeeDialog}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      {isLoading ? <LinearProgress sx={{ marginBottom: 2 }} /> : null}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <form onSubmit={getUserID}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  type="number"
                  size="small"
                  label="Phone Number"
                  fullWidth
                  value={employee.phoneNumber}
                  onChange={(e) => {
                    setEmployee({ ...employee, phoneNumber: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  size="small"
                  label="Role"
                  fullWidth
                  value={employee.role}
                  onChange={(e) => {
                    setEmployee({ ...employee, role: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack
                  sx={{ marginTop: 2 }}
                  direction="row"
                  spacing={2}
                  justifyContent="end"
                  alignItems="center"
                >
                  <Button type="submit" variant="contained" color="primary">
                    Create
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() =>
                      setEmployee({
                        phoneNumber: "",
                        role: "",
                      })
                    }
                  >
                    Clear Text
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default EmployeeForm;
