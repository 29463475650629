import {
  Autocomplete,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import DateRangepicker from "src/components/DateRangePicker";
import moment from "moment";
import { saveSearchBody } from "src/actions/externalItem";
import SearchIcon from "@mui/icons-material/Search";
import { External_Item_Status } from "src/utils/common";

export default function ShareCenterSearchFilter({ pageNo = function () {} }) {
  const searchFilter = useSelector((state) => state.externalItem.searchBody);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [note, setNote] = useState("");

  const dispatch = useDispatch();

  return (
    <Card>
      {!searchFilter ? null : (
        <CardContent style={{ paddingBottom: 15 }}>
          <div
            style={{
              maxWidth: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{ alignItems: "center", display: "flex" }}
            >
              <Grid item xs={2}>
                <TextField
                  type="number"
                  fullWidth
                  size="small"
                  value={phoneNumber}
                  name="name"
                  label="Phone Number"
                  onChange={(e) => {
                    var phonenumber = e.target.value;
                    setPhoneNumber(
                      phonenumber[0] == "0"
                        ? phonenumber.substring(1)
                        : phonenumber
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      dispatch(
                        saveSearchBody({
                          ...searchFilter,
                          phoneNumber: phoneNumber,
                        })
                      );
                      pageNo(0);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size="small"
                  value={note}
                  name="name"
                  label="Note"
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      dispatch(
                        saveSearchBody({
                          ...searchFilter,
                          note: note,
                        })
                      );
                      pageNo(0);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  options={External_Item_Status}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Status" />
                  )}
                  onChange={(event, value) => {
                    dispatch(
                      saveSearchBody({
                        ...searchFilter,
                        status: value ? value.id : "",
                      })
                    );
                    pageNo(0);
                  }}
                  value={
                    External_Item_Status.filter(
                      (x) => x.id == searchFilter.status
                    )[0]
                      ? External_Item_Status.filter(
                          (x) => x.id == searchFilter.status
                        )[0]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <DateRangepicker
                  right={0}
                  startDate={searchFilter.from}
                  endDate={searchFilter.to}
                  handleOnChange={(searchDate) => {
                    const updatedFilter = {
                      ...searchFilter,
                      from: moment(searchDate[0].startDate).format(
                        "YYYY-MM-DD 00:00:00"
                      ),
                      to: moment(searchDate[0].endDate).format(
                        "YYYY-MM-DD 23:59:59"
                      ),
                    };

                    if (
                      updatedFilter.from !== searchFilter.from ||
                      updatedFilter.to !== searchFilter.to
                    ) {
                      dispatch(saveSearchBody(updatedFilter));
                      pageNo(0);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Tooltip title="Search">
                  <IconButton
                    onClick={() => {
                      dispatch(
                        saveSearchBody({
                          ...searchFilter,
                          phoneNumber: phoneNumber,
                        })
                      );
                      pageNo(0);
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Clear filter">
                  <IconButton
                    onClick={() => {
                      dispatch(
                        saveSearchBody({
                          pageSize: 15,
                          status: "",
                          from: moment().format("2022-01-01 00:00:00"),
                          to: moment().format("YYYY-MM-DD 23:59:59"),
                          phoneNumber: "",
                          note: "",
                        })
                      );
                      setPhoneNumber("");
                      setNote("");
                      pageNo(0);
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        </CardContent>
      )}
    </Card>
  );
}
