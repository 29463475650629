import {
  GET_ALL_UNCOMPLETED_CART,
  RELOAD_UNCOMPLETED_CART,
  UNCOMPLETED_CART_SEARCH_BODY,
  UNCOMPLETED_CART_PAGE_NUMBER,
} from "src/actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  searchBody: {
    pageSize: 15,
    fromLastModifiedAt: null,
    toLastModifiedAt: null,
  },
  usersData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
};

function uncompletedCartsReducer(uncompletedCarts = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_UNCOMPLETED_CART:
      uncompletedCarts.usersData = payload;
      return uncompletedCarts;

    case RELOAD_UNCOMPLETED_CART:
      return { ...uncompletedCarts, reload: !uncompletedCarts.reload };

    case UNCOMPLETED_CART_PAGE_NUMBER:
      return { ...uncompletedCarts, pageNo: payload };

    case UNCOMPLETED_CART_SEARCH_BODY:
      return { ...uncompletedCarts, searchBody: payload };

    default:
      return uncompletedCarts;
  }
}

export default uncompletedCartsReducer;
