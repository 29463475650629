import axios from "../axios";

class ItemOptionService {
  createItemOptions(data) {
    return axios.post("/giftOptions", data);
  }

  getItemOptionsById(id) {
    return axios.get(`/giftOptions/getById/${id}`);
  }

  getItemOptionsList() {
    return axios.get(`/giftOptions/list`);
  }

  getItemOptionsAll(pageNO, pageSize, name) {
    return axios.get(
      `/giftOptions/${pageNO}?pageSize=${pageSize}&name=${name}`
    );
  }

  deleteItemOptions(id) {
    return axios.delete(`/giftOptions/${id}`);
  }

  updateItemOptions(id, data) {
    return axios.put(`/giftOptions/${id}`, data);
  }
}

export default new ItemOptionService();
