import {
  Autocomplete,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { saveSearchBody } from "src/actions/imageUploadCenter";
import { Share_Center_Types } from "src/utils/common";

export default function ShareCenterSearchFilter({ pageNo = function () {} }) {
  const [name, setName] = useState("");

  const searchFilter = useSelector((state) => state.shareCenter.searchBody);

  const dispatch = useDispatch();

  return (
    <Card>
      {!searchFilter ? null : (
        <CardContent style={{ paddingBottom: 15 }}>
          <div
            style={{
              maxWidth: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{ alignItems: "center", display: "flex" }}
            >
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  size="small"
                  value={name}
                  name="name"
                  label="Search Name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      dispatch(saveSearchBody({ ...searchFilter, name: name }));
                      pageNo(0);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={Share_Center_Types}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Search Type" />
                  )}
                  onChange={(event, value) => {
                    dispatch(
                      saveSearchBody({
                        ...searchFilter,
                        type: value ? value.id : "",
                      })
                    );
                    pageNo(0);
                  }}
                  value={
                    Share_Center_Types.filter(
                      (x) => x.id == searchFilter.type
                    )[0]
                      ? Share_Center_Types.filter(
                          (x) => x.id == searchFilter.type
                        )[0]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Tooltip title="Clear filter">
                  <IconButton
                    onClick={() => {
                      dispatch(saveSearchBody({}));
                      pageNo(0);
                      setName("");
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
          {/* </div> */}
        </CardContent>
      )}
    </Card>
  );
}
