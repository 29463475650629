import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Card, CardHeader, Typography } from "@mui/material";
import { MdDateRange } from "react-icons/md";

const EventsHeader = () => {
  return (
    <>
      <Helmet>
        <title>Events | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Events
              </Typography>
            }
            avatar={<MdDateRange size={20} />}
          />
        </Card>
      </Box>
    </>
  );
};

export default EventsHeader;
