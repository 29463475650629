import moment from "moment";
import {
  GET_GIFT_DASHBOARD_ALL_COUNTS,
  GET_GIFT_DASHBOARD_ORDER_PER_STATUS,
  GET_GIFT_DASHBOARD_ORDER_PER_PROVINCE,
} from "src/actions/gifts/giftTypes";

const initialState = {
  reload: false,
  searchBody: {
    startDate: moment().format("2024-01-01"),
  },
  allCount: {
    ordersCount: 0,
    ordersCurrentMonthCount: 0,
    productsCount: 0,
    categoriesCount: 0,
    suppliersCount: 0,
    brandsCount: 0,
    ratingAverage: 0,
  },
  ordersPerStatus: {
    ordersPerStatus: {
      data: [],
      labels: [],
    },
    ordersCount: 0,
    revenue: 0,
  },
  OrdersPerProvince: {
    data: [],
    province: [],
    ordersCount: [],
  },
};

function giftDashboardReducer(giftDashboard = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_GIFT_DASHBOARD_ALL_COUNTS:
      giftDashboard.allCount = payload;
      return giftDashboard;

    case GET_GIFT_DASHBOARD_ORDER_PER_STATUS:
      const data = payload.statusStatistics.map((row) => row.count);
      const labels = payload.statusStatistics.map((row) => row.status);
      giftDashboard.ordersPerStatus.ordersCount = payload.ordersCount;
      giftDashboard.ordersPerStatus.revenue = payload.revenue;
      giftDashboard.ordersPerStatus.ordersPerStatus = { data, labels };
      return giftDashboard;

    case GET_GIFT_DASHBOARD_ORDER_PER_PROVINCE:
      giftDashboard.OrdersPerProvince.data = payload;
      giftDashboard.OrdersPerProvince.province = payload.map(
        (row) => row.province
      );
      giftDashboard.OrdersPerProvince.ordersCount = payload.map(
        (row) => row.count
      );
      return giftDashboard;

    default:
      return giftDashboard;
  }
}

export default giftDashboardReducer;
