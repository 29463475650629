import axios from "../axios";

class ProhibitedItemsService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`/prohibiteditemsreasons/${pageNo}?${params}`);
  }

  getById(id) {
    return axios.get(`/prohibiteditemsreasons/getById/${id}`);
  }

  create(data) {
    return axios.post("/prohibiteditemsreasons", data);
  }

  update(id, data) {
    return axios.put(`/prohibiteditemsreasons/${id}`, data);
  }

  delete(id) {
    return axios.delete(`/prohibiteditemsreasons/${id}`);
  }

  getAllList() {
    return axios.get(`/prohibiteditemsreasons/getall`);
  }
}
export default new ProhibitedItemsService();
