import React from "react";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Paper,
} from "@mui/material";
import { DialogActions } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Draggable from "react-draggable";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    margin: theme.spacing(1),
  },
  centerObject: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
}));

export const CustomDialog = ({
  isConfirm,
  confirmTitle,
  fullScreen = false,
  isOpen,
  handleClose,
  title,
  children,
  isConfirmation,
  maxWidth = "md",
  selectedObject,
  onClear = function () {},
  onEdit = function () {},
  onAdd = function () {},
  onDelete = function () {},
  onCancel = function () {},
  isRequierdActions = false,
  isRequierdAdd = false,
  isRequierdEdit = false,
  isActiveDelete = false,
  loading = false,
  Draggable = false,
  dir,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        open={isOpen}
        onClose={handleClose}
        // aria-labelledby="customized-dialog-title"
        aria-labelledby="draggable-dialog-title"
        PaperComponent={Draggable ? PaperComponent : null}
        dir={dir ? dir : "ltr"}
      >
        <MuiDialogTitle
          className={classes.root}
          sx={{ cursor: Draggable ? "move" : null }}
        >
          <Typography>{title}</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CancelPresentationIcon />
          </IconButton>
        </MuiDialogTitle>
        <DialogContent dividers>
          {isActiveDelete ? (
            <div>
              <div className={classes.centerObject}>
                <HighlightOffIcon
                  style={{
                    color: "red",
                    fontSize: 100,
                  }}
                />
              </div>
              <div className={classes.centerObject}>
                <Typography variant="h4">
                  Are you sure you want to{" "}
                  {isConfirm
                    ? `${confirmTitle} `
                    : isConfirmation
                    ? "cancel "
                    : "delete "}
                  <b>{selectedObject}</b> ?
                </Typography>
              </div>
            </div>
          ) : (
            children
          )}
        </DialogContent>
        {isRequierdActions ? (
          isActiveDelete ? (
            <div
              className={classes.centerObject}
              style={{
                display: "flex",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div>
                <LoadingButton
                  color="error"
                  variant="contained"
                  className={classes.button}
                  startIcon={<CheckCircleOutlineIcon />}
                  onClick={isConfirmation ? onCancel : onDelete}
                  loading={loading}
                >
                  YES
                </LoadingButton>
              </div>
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  startIcon={<BlockIcon />}
                  onClick={handleClose}
                >
                  NO
                </Button>
              </div>
            </div>
          ) : (
            <DialogActions>
              <div style={{ display: "flex" }}>
                {isRequierdAdd ? (
                  <div>
                    <LoadingButton
                      color="success"
                      variant="contained"
                      className={classes.button}
                      startIcon={<AddIcon />}
                      onClick={onAdd}
                      loading={loading}
                    >
                      Add
                    </LoadingButton>
                  </div>
                ) : null}
                {isRequierdEdit ? (
                  <div>
                    <LoadingButton
                      color="info"
                      variant="contained"
                      className={classes.button}
                      startIcon={<EditIcon />}
                      onClick={onEdit}
                      loading={loading}
                    >
                      Edit
                    </LoadingButton>
                  </div>
                ) : null}
                {onClear ? (
                  <div>
                    <Button
                      color="warning"
                      variant="contained"
                      className={classes.button}
                      startIcon={<ClearAllIcon />}
                      onClick={onClear}
                    >
                      Clear All
                    </Button>
                  </div>
                ) : null}
              </div>
            </DialogActions>
          )
        ) : null}
      </Dialog>
    </div>
  );
};
