import {
  ORDER_CANCEL_REASONS_RESET_FORM,
  ORDER_CANCEL_REASONS_GET_BY_ID,
  ORDER_CANCEL_REASONS_PAGE_NUMBER,
  ORDER_CANCEL_REASONS_OPEN_DIALOG,
  ORDER_CANCEL_REASONS_RELOAD,
  ORDER_CANCEL_REASONS_GET_ALL,
  ORDER_CANCEL_REASONS_GET_ALL_LIST,
} from "./type";

import OrderCancelReasonService from "../api/services/orderCancelReason.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const openDialog = () => (dispatch) => {
  dispatch({
    type: ORDER_CANCEL_REASONS_OPEN_DIALOG,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: ORDER_CANCEL_REASONS_RESET_FORM,
  });
};

export const reloadCancelOrderReasons = () => (dispatch) => {
  dispatch({
    type: ORDER_CANCEL_REASONS_RELOAD,
  });
};

export const saveCancelOrderReasonsPageNum = (data) => (dispatch) => {
  dispatch({
    type: ORDER_CANCEL_REASONS_PAGE_NUMBER,
    payload: data,
  });
};

export const createCancelOrderReasons =
  (data, pagNo, pageSize) => async (dispatch) => {
    try {
      const res = await OrderCancelReasonService.create(data);
      dispatch(showSuccessSnackbar("Created successfully"));
      dispatch(getAllCancelOrderReasons(pagNo, pageSize));
      dispatch(openDialog());
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getAllCancelOrderReasons = (pageNo) => async (dispatch) => {
  try {
    dispatch(reloadCancelOrderReasons());
    const res = await OrderCancelReasonService.getAll(pageNo);
    dispatch({
      type: ORDER_CANCEL_REASONS_GET_ALL,
      payload: res.data.data,
    });
    dispatch(reloadCancelOrderReasons());
  } catch (err) {
    dispatch(reloadCancelOrderReasons());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getListOrderCancelReasons = () => async (dispatch) => {
  try {
    dispatch(reloadCancelOrderReasons());
    const res = await OrderCancelReasonService.getList();
    dispatch({
      type: ORDER_CANCEL_REASONS_GET_ALL_LIST,
      payload: res.data.data,
    });
    dispatch(reloadCancelOrderReasons());
  } catch (err) {
    dispatch(reloadCancelOrderReasons());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateCancelOrderReasons =
  (data, pagNo, pageSize) => async (dispatch) => {
    try {
      const res = await OrderCancelReasonService.update(data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllCancelOrderReasons(pagNo, pageSize));
      dispatch(openDialog());
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteCancelOrderReasons = (id, pagNo) => async (dispatch) => {
  try {
    const res = await OrderCancelReasonService.delete(id);
    dispatch(showSuccessSnackbar("Deleted successfully!"));
    dispatch(getAllCancelOrderReasons(pagNo));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadCancelOrderReasons());
    const res = await OrderCancelReasonService.getById(id);
    dispatch({
      type: ORDER_CANCEL_REASONS_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadCancelOrderReasons());
  } catch (err) {
    dispatch(reloadCancelOrderReasons());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(reloadCancelOrderReasons());
    const res = await OrderCancelReasonService.getById(id);
    dispatch({
      type: ORDER_CANCEL_REASONS_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadCancelOrderReasons());
    dispatch(openDialog());
  } catch (err) {
    dispatch(reloadCancelOrderReasons());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
