import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import BadgeIcon from "@mui/icons-material/Badge";
import { openEmployeeDialog } from "src/actions/department/employee";
import EmployeeForm from "./Form";
import EmployeeList from "./List";
import RolePermission from "src/components/RolePermission";

const Employee = () => {
  const dispatch = useDispatch();

  const employee = useSelector((state) => state.employee);

  return (
    <>
      <Helmet>
        <title>Employee | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false} sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
          <Card>
            <CardHeader
              title={
                <Typography variant="h4" component="div">
                  Employee
                </Typography>
              }
              avatar={<BadgeIcon size={20} />}
              action={
                <RolePermission permission="D3-01">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={() =>
                      dispatch(openEmployeeDialog(employee.dialog))
                    }
                  >
                    Create Employee
                  </Button>
                </RolePermission>
              }
            />
            {employee.dialog ? <EmployeeForm /> : null}
          </Card>

          <Box sx={{ pt: 2 }}>
            <EmployeeList />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Employee;
