import helper from "src/utils/helper";
import {
  EVENTS_PAGE_NUMBER,
  EVENTS_RELOAD,
  EVENTS_GET_ALL,
  EVENTS_SEARCH_BODY,
  EVENTS_OPEN_DIALOG,
  EVENTS_RESET_FORM,
  EVENTS_GET_BY_ID,
} from "src/actions/gifts/giftTypes";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";
import eventsService from "src/api/giftServices/events.service";

export const reloadEvents = () => (dispatch) => {
  dispatch({
    type: EVENTS_RELOAD,
  });
};

export const getAllEvents = (pageNO, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadEvents());
    const res = await eventsService.getAllEvents(pageNO, searchBody);
    dispatch({
      type: EVENTS_GET_ALL,
      payload: res.data.data,
    });
    dispatch(reloadEvents());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadEvents());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getSuggestedEvents = (pageNO, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadEvents());
    const res = await eventsService.getAllEvents(pageNO, searchBody);
    dispatch({
      type: EVENTS_GET_ALL,
      payload: res.data.data,
    });
    dispatch(reloadEvents());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadEvents());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const createSuggestedEvent =
  (data, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await eventsService.createSuggestedEvent(data);
      dispatch(showSuccessSnackbar("Created successfully!"));
      dispatch(openEventsDialog());
      dispatch(getAllEvents(pageNo, searchBody));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const updateSuggestedEvent =
  (id, data, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await eventsService.updateSuggestedEvent(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(openEventsDialog());
      dispatch(getAllEvents(pageNo, searchBody));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteSuggestedEvent =
  (id, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await eventsService.deleteSuggestedEvent(id);
      dispatch(showSuccessSnackbar("Deleted successfully!"));
      dispatch(getAllEvents(pageNo, searchBody));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getByIdEvents = (id, openDialog) => async (dispatch) => {
  try {
    dispatch(reloadEvents());
    const res = await eventsService.getById(id);
    dispatch(reloadEvents());
    dispatch({
      type: EVENTS_GET_BY_ID,
      payload: res.data.data,
    });
    if (openDialog) dispatch(openEventsDialog());
    // return Promise.resolve(res.data.data
  } catch (err) {
    dispatch(reloadEvents());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const saveEventsPageNum = (data) => (dispatch) => {
  dispatch({
    type: EVENTS_PAGE_NUMBER,
    payload: data,
  });
};

export const saveEventsSearchBody = (data) => (dispatch) => {
  dispatch({
    type: EVENTS_SEARCH_BODY,
    payload: data,
  });
};

export const openEventsDialog = (data) => (dispatch) => {
  dispatch({
    type: EVENTS_OPEN_DIALOG,
    payload: data,
  });
};

export const resetEventForm = (data) => (dispatch) => {
  dispatch({
    type: EVENTS_RESET_FORM,
    payload: data,
  });
};
