import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Card, CardHeader, Typography } from "@mui/material";
import { MdPriceChange } from "react-icons/md";

const BusinessPromosHeader = () => {
  return (
    <>
      <Helmet>
        <title>Business Promos | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Business Promos
              </Typography>
            }
            avatar={<MdPriceChange size={20} />}
          />
        </Card>
      </Box>
    </>
  );
};

export default BusinessPromosHeader;
