import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Typography,
  Tooltip,
  Avatar,
  Stack,
  Icon,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllTickets, savePageNo } from "src/actions/ticket/ticket";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useNavigate } from "react-router";
import { LinearProgress } from "@mui/material";
import TicketSearchFilter from "./SearchFilter";
import { saveSearchBody } from "src/actions/ticket/ticket";
import CustomSpinner from "src/components/CustomSpinner";
import { Ticket_Status } from "src/utils/common";
import PersonIcon from "@mui/icons-material/Person";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";

export default function TicketContent() {
  const navigate = useNavigate();

  const [reload, setReload] = useState(false);

  const tickets = useSelector((state) => state.tickets);
  const ticketSearchFilter = useSelector((state) => state.tickets.searchBody);
  const tikcetPageNo = useSelector((state) => state.tickets.pageNo);

  const dispatch = useDispatch();

  const [page, setPage] = useState(tikcetPageNo ? tikcetPageNo : 0);

  useEffect(() => {
    setReload(true);
    dispatch(getAllTickets(page + 1, ticketSearchFilter)).then((res) => {
      setReload(false);
    });
  }, [page, ticketSearchFilter]);

  useEffect(() => {
    dispatch(savePageNo(page));
  }, [page]);

  function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${
        name.split(" ")[2][0] ?? name.split(" ")[1][0]
      }`,
    };
  }

  const columns = [
    {
      field: "#",
      headerName: "#",
      width: 5,
      headerAlign: "center",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "number",
      headerName: "Number",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "issuerInfo",
      headerName: "Issuer Information",
      flex: 1.5,
      headerAlign: "center",
      renderCell: (params) => (
        <Stack spacing={1} direction="column" sx={{ alignItems: "center" }}>
          <Typography variant="h5">{params.value.fullName} </Typography>
          <Typography>{params.value.phoneNumber}</Typography>
        </Stack>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1.5,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography>
          {moment(params.value).format("yyyy-MM-DD / hh:mma")}{" "}
        </Typography>
      ),
    },
    {
      field: "categoryNameEn",
      headerName: "Category",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "departmentName",
      headerName: "Department",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "priority",
      headerName: "Priority",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography>
          {params.row.priority == 1 ? (
            <Tooltip placement="top" arrow title={"Normal Priority"}>
              <Icon color="primary">directions_walk</Icon>
            </Tooltip>
          ) : params.row.priority == 2 ? (
            <Tooltip placement="top" arrow title={"High Priority"}>
              <Icon sx={{ color: "rgb(255, 204, 0)" }}>directions_run</Icon>
            </Tooltip>
          ) : (
            <Tooltip placement="top" arrow title={"Urgent Priority"}>
              <Icon color="error">directions_bike</Icon>
            </Tooltip>
          )}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) =>
        Ticket_Status.map((x, index) =>
          x.id == params.row.status ? (
            <Typography
              key={index}
              color={
                x.name == "New"
                  ? "primary.main"
                  : x.name == "Canceled"
                  ? "error"
                  : x.name == "In Progress"
                  ? "warning.main"
                  : x.name == "Solved"
                  ? "success.main"
                  : ""
              }
            >
              {x.name}
            </Typography>
          ) : null
        ),
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography color={params.row.internal == true ? "primary.main" : ""}>
          {params.row.internal ? "Office" : "Customer"}
        </Typography>
      ),
    },
    {
      field: "assignees",
      headerName: "Assignees",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Stack direction="row" spacing={0}>
          {params.value <= 0 ? (
            <Typography noWrap>
              <PersonIcon fontSize="large" sx={{ color: "#d5d6d7" }} />
            </Typography>
          ) : (
            params.value.map((data, index) => (
              <Tooltip key={index} placement="top" arrow title={data.fullName}>
                <Avatar key={index} {...stringAvatar(data.fullName)} />
              </Tooltip>
            ))
          )}
        </Stack>
      ),
    },
  ];

  return (
    <Card>
      <TicketSearchFilter
        filter={tickets.searchBody}
        pageNo={(newPage) => setPage(newPage)}
      />
      <PerfectScrollbar>
        <Box>
          {!tickets.data ? (
            <CustomSpinner />
          ) : (
            <Box sx={{ height: { md: "calc(100vh - 320px)", xs: 1000 }, p: 2 }}>
              <DataGrid
                sx={{
                  "& .css-204u17-MuiDataGrid-main": {
                    paddingLeft: "15px",
                  },
                  "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                    paddingLeft: "15px",
                  },
                  ".MuiDataGrid-cell": {
                    justifyContent: "center",
                  },
                  "& .MuiDataGrid-cell": { cursor: "pointer" },
                }}
                rows={tickets.data}
                onRowClick={(params) =>
                  navigate(`/app/ticket/TicketDetails/${params.id}`)
                }
                columns={columns}
                rowHeight={59}
                disableSelectionOnClick
                pagination
                pageSize={ticketSearchFilter.pageSize}
                onPageSizeChange={(newPageSize) => {
                  dispatch(
                    saveSearchBody({
                      ...ticketSearchFilter,
                      pageSize: newPageSize,
                    })
                  );
                }}
                page={page}
                onPageChange={(newPage) => {
                  setPage(newPage);
                }}
                rowCount={tickets.rowCount}
                loading={reload}
                paginationMode="server"
                rowsPerPageOptions={[15, 25, 75, 100]}
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
