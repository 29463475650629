import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TextField,
  Tooltip,
  Button,
  LinearProgress,
  Grid,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DataGrid } from "@mui/x-data-grid";
import { Controller, useForm } from "react-hook-form";
import {
  deleteEmployee,
  getByIdEmployee,
  getEmployee,
  updateEmployee,
} from "src/actions/department/employee";
import moment from "moment";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import { CustomDialog } from "src/components/CustomDialog";
import CustomSpinner from "src/components/CustomSpinner";
import RolePermission from "src/components/RolePermission";

export default function EmployeeList() {
  const [reload, setReload] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openEmployeeDialog, setOpendEmployeeDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const _UserInfo = useSelector((state) => state.users.userInfo);
  const employeeData = useSelector((state) => state.employee.data);
  const employeeById = useSelector((state) => state.employee.getByID);

  const dispatch = useDispatch();

  const { control, reset, handleSubmit } = useForm({
    defaultValues: employeeById,
  });

  const handleEmployeeByID = (id) => {
    setReload(true);
    dispatch(getByIdEmployee(id)).then((res) => {
      setReload(false);
    });
  };

  useEffect(() => {
    reset(employeeById);
  }, [employeeById]);

  useEffect(() => {
    setReload(true);
    dispatch(getEmployee()).then((res) => {
      setReload(false);
    });
  }, [refresh]);

  function clearTexts() {
    reset();
  }

  const handleEmployeeDialogOpen = () => {
    setOpendEmployeeDialog(true);
    reset();
  };

  const handleEmployeeDialogClose = () => {
    setOpendEmployeeDialog(false);
  };

  function updateSelectedEmployee(data) {
    setOpendEmployeeDialog(false);
    dispatch(updateEmployee(employeeById.id, data)).then((res) => {
      setRefresh(!refresh);
    });
  }

  function deleteSelectedEmployee() {
    setOpenDeleteDialog(false);
    dispatch(deleteEmployee(employeeById.id)).then((res) => {
      setRefresh(!refresh);
    });
  }

  const employeeToolbar = () => {
    return (
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedEmployee()}
            selectedObject={employeeById.name}
          ></CustomDialog>
        )}
        <CustomDialog
          isOpen={openEmployeeDialog}
          handleClose={handleEmployeeDialogClose}
          title={"UPDATE Employee"}
          maxWidth="md"
          // onClear={clearTexts}
        >
          {reload ? <LinearProgress /> : null}
          {!employeeById.role ? (
            <LinearProgress />
          ) : (
            <form onSubmit={handleSubmit(updateSelectedEmployee)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        required
                        size="small"
                        label="Role"
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Edit Employee
                  </Button>
                  <Button
                    onClick={clearTexts}
                    variant="contained"
                    color="primary"
                    sx={{ marginX: 2 }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </CustomDialog>
      </Box>
    );
  };

  var columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "username",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography>
          {moment(params.value).format("yyyy-MM-DD / hh:mma")}
        </Typography>
      ),
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Box>
          <RolePermission permission="D3-02">
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={(e) => {
                handleEmployeeByID(params.id);
                handleEmployeeDialogOpen();
              }}
            >
              <Tooltip title="Edit" aria-label="edit">
                <EditIcon />
              </Tooltip>
            </IconButton>
          </RolePermission>
          <RolePermission permission="D3-03">
            <IconButton
              color="error"
              aria-label="delete"
              onClick={(e) => {
                handleEmployeeByID(params.id);
                setOpenDeleteDialog(true);
              }}
            >
              <Tooltip title="Delete" aria-label="delete">
                <DeleteForeverIcon />
              </Tooltip>
            </IconButton>
          </RolePermission>
        </Box>
      ),
    },
  ];

  columns =
    _UserInfo.roleOEndpoint.map((data) => data.Code).includes("D3-02") ||
    _UserInfo.roleOEndpoint.map((data) => data.Code).includes("D3-03")
      ? columns
      : columns.filter((x) => x.field !== "operations");

  return (
    <Card>
      {employeeToolbar()}
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {!employeeData ? (
            <CustomSpinner />
          ) : (
            <Box sx={{ height: "calc(100vh - 200px)" }}>
              <DataGrid
                sx={{
                  "& .css-204u17-MuiDataGrid-main": {
                    paddingLeft: "15px",
                  },
                  "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                    paddingLeft: "15px",
                  },
                  ".MuiDataGrid-cell": {
                    justifyContent: "center",
                  },
                }}
                loading={reload}
                rows={employeeData}
                columns={columns}
                rowHeight={65}
                disableSelectionOnClick
                pagination
                paginationMode="client"
                rowsPerPageOptions={[10, 25, 100]}
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
