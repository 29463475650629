import { Grid, Stack, FormControlLabel, Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import {
  openVendorCategoryDialog,
  resetForm,
  updateVendorCategories,
} from "src/actions/vendors";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";

const VendorsCategoryForm = () => {
  const dispatch = useDispatch();

  const vendor = useSelector((state) => state.vendors.form);
  const dialog = useSelector((state) => state.vendors.openVendorCategoryDialog);
  const loading = useSelector((state) => state.vendors.reload);
  const ListData = useSelector(
    (state) => state.vendorsCategory.vendorsCategoryList
  );

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: ListData.reduce(
      (values, item, index) => ({
        ...values,
        [`item-${index}`]: vendor.categories.includes(item.id),
      }),
      {}
    ),
  });

  useEffect(() => {
    reset();
  }, [dialog, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    const checkedIds = [];

    for (let [key, value] of Object.entries(data)) {
      if (value) {
        const index = parseInt(key.split("-")[1]);
        checkedIds.push(ListData[index].id);
      }
    }

    dispatch(
      updateVendorCategories(vendor.id, { categoriesIds: checkedIds })
    ).then(() => {
      handleDialog();
    });
  };

  const handleDialog = () => {
    dispatch(openVendorCategoryDialog());
  };

  return (
    <CustomDialog
      title={"UPDATE VENDOR CATEGORIES"}
      isOpen={dialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container spacing={2}>
              {ListData.map((item, index) => (
                <Grid item xs={4} key={index}>
                  <Controller
                    name={`item-${index}`}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.value}
                            onChange={() => {
                              setValue(`item-${index}`, !field.value, {
                                shouldDirty: true,
                              });
                            }}
                            {...field}
                          />
                        }
                        label={item.nameEn}
                      />
                    )}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default VendorsCategoryForm;
