import { Grid, Box } from "@mui/material";
import React from "react";
import UserDeactivationReasonTable from "./home/table";
import UserDeactivationReasonsHeader from "./home/header";

const UserDeactivationReasons = () => {
  return (
    <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12}>
          <UserDeactivationReasonsHeader />
        </Grid>
        <Grid item xs={12} sx={{ py: 2 }}>
          <UserDeactivationReasonTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserDeactivationReasons;
