import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Card,
  Typography,
  TextField,
  Autocomplete,
  Divider,
  LinearProgress,
} from "@mui/material";
import CustomizedSnackbars from "../../Snackbar";
import { useDispatch } from "react-redux";
import {
  getBankAndWalletTransactions,
  getOrderiiBalance,
} from "../../../actions/wallet";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { Transaction_Reason, EPayment_Types } from "src/utils/common";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import { red } from "@mui/material/colors";
import { Stack } from "@mui/system";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import DateRangepicker from "src/components/DateRangePicker";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";

export default function ReportsList() {
  const [reportsFilter, setReportsFilter] = useState({
    start: moment().format("YYYY-MM-01 HH:mm:ss"),
    end: moment().format("YYYY-MM-DD 23:59:59"),
    transactionStatus: 1,
    walletType: null,
    transactionReason: null,
    ePaymentType: null,
  });
  const [reports, setReports] = useState(null);
  const [currentBalance, setCurrentBalance] = useState({
    wallet: 0,
    cash: 0,
  });
  const [TotalAmount, setTotalAmount] = useState(0);
  const [reload, setReload] = useState(false);

  const [alert, setAlert] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (reportsFilter.walletType) {
      setReload(true);
      dispatch(getBankAndWalletTransactions(reportsFilter)).then((res) => {
        setReports(res.data);
        setReload(false);
      });
    }
  }, [reportsFilter]);

  useEffect(() => {
    if (reports) {
      var result = 0;
      if (reports.length > 0) {
        reports.map((x) => (result += x.amount));
      }
      setTotalAmount(result);
    }
  }, [reports]);

  useEffect(() => {
    dispatch(getOrderiiBalance()).then((res) => {
      setCurrentBalance(res.data);
    });
  }, []);

  const columns = [
    {
      field: "transactionNumber",
      headerName: "T-Number",
      width: 120,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography>{params.row.transactionNumber}</Typography>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography sx={params.row.amount < 0 ? { color: "red" } : {}}>
          {params.row.amount} $
        </Typography>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Stack direction="column" spacing={1}>
          {params.row.fromUser ? (
            <Stack direction="column" spacing={1} sx={{ alignItems: "center" }}>
              <Typography>
                {params.row.fromUser ? params.row.fromUser.name : null}
              </Typography>
            </Stack>
          ) : (
            <Stack direction="column" spacing={1} sx={{ alignItems: "center" }}>
              <Typography>
                {params.row.toUser ? params.row.toUser.name : null}
              </Typography>
            </Stack>
          )}
        </Stack>
      ),
      valueGetter: (params) =>
        params.row.fromUser
          ? params.row.fromUser?.name ?? ""
          : params.row.toUser?.name ?? "",
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Stack direction="column" spacing={1}>
          {params.row.fromUser ? (
            <Stack direction="column" spacing={1} sx={{ alignItems: "center" }}>
              <Typography>
                {params.row.fromUser
                  ? "0" + params.row.fromUser.phoneNumber
                  : null}
              </Typography>
            </Stack>
          ) : (
            <Stack direction="column" spacing={1} sx={{ alignItems: "center" }}>
              <Typography>
                {params.row.toUser ? "0" + params.row.toUser.phoneNumber : null}
              </Typography>
            </Stack>
          )}
        </Stack>
      ),
      valueGetter: (params) =>
        params.row.fromUser
          ? params.row.fromUser?.phoneNumber ?? ""
          : params.row.toUser?.phoneNumber ?? "",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 180,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography>
          {moment(params.value).format("yyyy-MM-DD | hh:mm")}
        </Typography>
      ),
    },
    {
      field: "reason",
      headerName: "Reason",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (params) => <Typography>{params.row.reason}</Typography>,
    },
    {
      field: "ePaymentType",
      headerName: "EPayment",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography>{params.row.ePaymentType}</Typography>
      ),
    },
    {
      field: "info",
      headerName: "Information",
      flex: 2,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography style={{ whiteSpace: "pre-wrap" }}>
          {params.row.info}
        </Typography>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <Card>
      <CustomizedSnackbars
        open={alert.open}
        type={alert.type}
        message={alert.message}
        onClose={() =>
          setAlert((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
      />
      <Box>
        {reload ? <LinearProgress /> : null}
        <Box padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <DateRangepicker
                disabled={reload}
                startDate={reportsFilter.start}
                endDate={reportsFilter.end}
                handleOnChange={(searchDate) => {
                  const updatedFilter = {
                    from: moment(searchDate[0].startDate).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                    to: moment(searchDate[0].endDate).format(
                      "YYYY-MM-DD 23:59:59"
                    ),
                  };
                  if (
                    updatedFilter.from !== reportsFilter.start ||
                    updatedFilter.to !== reportsFilter.end
                  ) {
                    setReportsFilter({
                      ...reportsFilter,
                      start: moment(searchDate[0].startDate).format(
                        "YYYY-MM-DD HH:mm:ss"
                      ),
                      end: moment(searchDate[0].endDate).format(
                        "YYYY-MM-DD 23:59:59"
                      ),
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Autocomplete
                options={Transaction_Reason}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Transaction Reason" />
                )}
                onChange={(event, value) => {
                  setReportsFilter({
                    ...reportsFilter,
                    transactionReason: value ? value.id : null,
                  });
                }}
                value={
                  Transaction_Reason.filter(
                    (x) => x.id == reportsFilter.transactionReason
                  )[0]
                    ? Transaction_Reason.filter(
                        (x) => x.id == reportsFilter.transactionReason
                      )[0]
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Autocomplete
                options={EPayment_Types}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="EPayment Types" />
                )}
                onChange={(event, value) => {
                  setReportsFilter({
                    ...reportsFilter,
                    ePaymentType: value ? value.id : null,
                  });
                }}
                value={
                  EPayment_Types.filter(
                    (x) => x.id == reportsFilter.ePaymentType
                  )[0]
                    ? EPayment_Types.filter(
                        (x) => x.id == reportsFilter.ePaymentType
                      )[0]
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack direction="row" spacing={2}>
                <LoadingButton
                  fullWidth
                  onClick={() => {
                    setReportsFilter({ ...reportsFilter, walletType: 2 });
                  }}
                  size="large"
                  variant="contained"
                  loading={reportsFilter.walletType == 2 ? reload : null}
                >
                  {"Wallet ($) " + currentBalance.wallet.toLocaleString()}
                </LoadingButton>

                <LoadingButton
                  fullWidth
                  onClick={() => {
                    setReportsFilter({ ...reportsFilter, walletType: 3 });
                  }}
                  size="large"
                  variant="contained"
                  loading={reportsFilter.walletType == 3 ? reload : null}
                >
                  {"Cash ($) " + currentBalance.cash.toLocaleString()}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        {reports ? (
          <Box>
            <Box sx={{ padding: 2, marginBottom: 2 }}>
              <Stack
                spacing={1}
                direction="column"
                sx={{ alignItems: "center" }}
              >
                <Typography variant="h4">
                  {reportsFilter.walletType == 2
                    ? "Wallet Report"
                    : "Cash Report"}
                </Typography>
                <Typography variant="h5">
                  {"Total Amount($): " + TotalAmount}
                </Typography>
                <Typography variant="h5">
                  {"Period: " +
                    moment(reportsFilter.start).format("yyyy-MM-DD") +
                    " - " +
                    moment(reportsFilter.end).format("yyyy-MM-DD")}
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ height: { md: "calc(100vh - 400px)", xs: 1000 } }}>
              <DataGrid
                sx={{
                  ".MuiDataGrid-cell": {
                    justifyContent: "center",
                  },
                }}
                getRowId={(params) => params.transactionNumber}
                rows={reports ?? []}
                columns={columns}
                rowHeight={65}
                disableSelectionOnClick
                pagination
                autoRowHeight
                paginationMode="client"
                rowsPerPageOptions={[10, 25, 100]}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            </Box>
          </Box>
        ) : null}
      </Box>
    </Card>
  );
}
