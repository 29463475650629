import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  TextField,
  InputAdornment,
  Typography,
  Stack,
  Divider,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";

import { AiOutlineSearch } from "react-icons/ai";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { CustomDialog } from "src/components/CustomDialog";
import {
  getAllOrderRating,
  saveSearchBodyOrderRating,
} from "src/actions/ratingQuestions";
import { useNavigate } from "react-router";
import ReactStars from "react-rating-stars-component";
import { GoPackage } from "react-icons/go";
import DateRangepicker from "src/components/DateRangePicker";

export default function OrderRatingTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const _UserInfo = useSelector((state) => state.users.userInfo);
  const _OrderRating = useSelector(
    (state) => state.ratingQuestions.orderRatingData
  );
  const loading = useSelector(
    (state) => state.ratingQuestions.orderRatingReload
  );
  const pageNo = useSelector(
    (state) => state.ratingQuestions.orderRatingPageNo
  );
  const searchBody = useSelector(
    (state) => state.ratingQuestions.orderRatingSearchBody
  );

  const [page, setPage] = useState(pageNo);
  const [searchOrderNumber, setSearchOrderNumber] = useState(
    searchBody.orderNumber
  );
  const [rate, setRate] = useState(searchBody.rate);
  const [phoneNumber, setPhoneNumber] = useState(searchBody.phoneNumber);
  const [openRatingDialog, setOpenRatingDialog] = useState(false);
  const [ratingData, setRatingData] = useState({});

  useEffect(() => {
    dispatch(
      getAllOrderRating(
        page + 1,
        searchBody,
        _UserInfo.serviceType === "Vertical" ? true : false
      )
    );
  }, [_UserInfo.serviceType, dispatch, page, searchBody]);

  const handlePageChange = (newPage) => {
    dispatch(saveSearchBodyOrderRating(newPage));
    setPage(newPage);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "userName",
      headerName: "Customer Info",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body" component="div">
            {params.row.user?.name}
          </Typography>
          <Typography variant="body" component="div">
            {params.row.user?.phoneNumber}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "isGiftOrder",
      headerName: "Order Type",
      flex: 1,
      renderCell: (params) => (params.row.isGiftOrder ? "Gift" : "Global"),
    },
    {
      field: "orderNumber",
      headerName: "Order Number",
      flex: 1,
    },
    {
      field: "rateAverage",
      headerName: "Rating Average",
      flex: 1,
      renderCell: (params) => (
        <Button
          onClick={() => {
            setOpenRatingDialog(true);
            setRatingData(params.row);
          }}
        >
          {params.row.rateAverage}
        </Button>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      renderCell: (params) =>
        moment(params.row.createdAt).format("YYYY-MM-DD / HH:mm a"),
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      renderCell: (params) =>
        _UserInfo.serviceType === "Vertical" && params.row.isGiftOrder ? (
          <Tooltip title="Order details">
            <IconButton
              onClick={(e) => {
                navigate(`/app/gifts/order/${params.row.orderId}`);
              }}
            >
              <GoPackage />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Order details">
            <IconButton
              onClick={(e) => {
                navigate(`/app/order/${params.row.orderId}`);
              }}
            >
              <GoPackage />
            </IconButton>
          </Tooltip>
        ),
    },
  ];

  return (
    <Card>
      <CustomDialog
        isOpen={openRatingDialog}
        handleClose={() => setOpenRatingDialog(false)}
        title="Order Rating"
        maxWidth="md"
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h3">Average:</Typography>
          <ReactStars
            edit={false}
            value={ratingData?.ratingAverage}
            size={40}
          />
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2}>
          {ratingData?.rates?.map((data, index) => (
            <Grid item xs={12} key={index}>
              <Typography variant="h4">
                {data?.ratingQuestion?.questionEn}
              </Typography>
              <ReactStars edit={false} value={data.rate} size={32} />
              {index !== ratingData?.rates?.length - 1 && <Divider />}
            </Grid>
          ))}
        </Grid>
      </CustomDialog>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Order Number"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={searchOrderNumber}
              onChange={(e) => {
                setSearchOrderNumber(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBodyOrderRating({
                      ...searchBody,
                      orderNumber: searchOrderNumber,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Phone Number"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value.replace(/^0+/, ""));
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBodyOrderRating({
                      ...searchBody,
                      phoneNumber: phoneNumber,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Rate"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={rate}
              onChange={(e) => {
                setRate(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBodyOrderRating({
                      ...searchBody,
                      rate: rate,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={searchBody.fromDate}
              endDate={searchBody.toDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  fromDate: moment(searchDate[0].startDate).format(
                    "YYYY-MM-DD"
                  ),
                  toDate: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.fromDate !== searchBody.fromDate ||
                  updatedFilter.toDate !== searchBody.toDate
                ) {
                  dispatch(
                    saveSearchBodyOrderRating({
                      ...searchBody,
                      fromDate: moment(searchDate[0].startDate).format(
                        "YYYY-MM-DD"
                      ),
                      toDate: moment(searchDate[0].endDate).format(
                        "YYYY-MM-DD"
                      ),
                    })
                  );
                }
                setPage(0);
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  setRate("");
                  setPhoneNumber("");
                  setSearchOrderNumber("");
                  dispatch(
                    saveSearchBodyOrderRating({
                      fromDate: moment().format("2024-01-01"),
                      rate: "",
                      phoneNumber: "",
                      orderNumber: "",
                      pageSize: 15,
                    })
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box
                sx={{
                  height: { md: "calc(100vh - 270px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  columns={columns}
                  rows={
                    _OrderRating?.data.map((data, index) => {
                      return { ...data, index: index + 1 };
                    }) || []
                  }
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={100}
                  pagination
                  paginationMode="server"
                  rowCount={_OrderRating?.rowCount}
                  pageSize={10}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  rowsPerPageOptions={[10]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
