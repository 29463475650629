const { default: cookie } = require("./cookie");

class helper {
  logout() {
    cookie.clear("token");
    localStorage.removeItem("decodedIems");
    window.location.href = "/login";

  }
}
export default new helper();
