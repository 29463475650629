import {
  CREATE_EXPENSE_TYPE,
  DELETE_EXPENSE_TYPE,
  GET_ALL_EXPENSE_TYPES,
} from "../actions/type";

const initialState = [];

function expenseReducer(expenses = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_EXPENSE_TYPE:
      expenses.data.push(payload.data);
      expenses.totalCount += 1;
      return [...expenses, payload];

    case GET_ALL_EXPENSE_TYPES: 
      return payload;

    case DELETE_EXPENSE_TYPE:
      expenses = expenses.filter((x) => x.id !== payload);
      return expenses;

    default:
      return expenses;
  }
}

export default expenseReducer;
