import axios from "../axios";

class DashboardService {
  getAll() {
    return axios.get(`/dashboard/`);
  }

  getOrders(filter) {
    let uri = `/dashboard/dailyordercount`;
    var filterLink = "";
    filterLink += filter.startDate ? `?from=${filter.startDate}` : "?";
    filterLink += filter.endDate ? `&to=${filter.endDate}` : "";


    return axios.get(uri + filterLink);
  }

  getOrderCountByStatus(from, to) {
    return axios.get(`/dashboard/ordersbystatus/${from}/${to}`);
  }

  getDailySignUp(filter) {
    let uri = `/dashboard/dailysignup`;
    var filterLink = "";
    filterLink += filter.startDate ? `?from=${filter.startDate}` : "?";
    filterLink += filter.endDate ? `&to=${filter.endDate}` : "";

    return axios.get(uri + filterLink);
  }

  getbalance(from, to) {
    return axios.get(`/dashboard/getbalance`);
  }

  getOrdersPerProvince(filter) {
    let uri = `/dashboard/ordersbyprovince`;
    var filterLink = "";
    filterLink += filter.startDate ? `?from=${filter.startDate}` : "?";
    filterLink += filter.endDate ? `&to=${filter.endDate}` : "";
    filterLink += filter.someProvinceId ? `&someProvinceId=${filter.someProvinceId}` : "";


    return axios.get(uri + filterLink);
  }

  getNewUsersPerProvince(filter) {
    let uri = `/dashboard/newusersperprovince`;
    var filterLink = "";
    filterLink += filter.startDate ? `?from=${filter.startDate}` : "?";
    filterLink += filter.endDate ? `&to=${filter.endDate}` : "";
    filterLink += filter.someProvinceId ? `&someProvinceId=${filter.someProvinceId}` : "";


    return axios.get(uri + filterLink);
  }

}

export default new DashboardService();
