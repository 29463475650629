import {
  Grid,
  Stack,
  Button,
  Autocomplete,
  TextField,
  Box,
  Typography,
  IconButton,
  LinearProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  bulkUpload,
  openBulkUploadDialog,
  resetBulkUploadForm,
} from "src/actions/gifts/giftItem";
import { getItemSupplierList } from "src/actions/gifts/supplier";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";

const BulkUpload = (props) => {
  const dispatch = useDispatch();

  const giftItem = useSelector((state) => state.giftItems);
  const loading = useSelector((state) => state.giftItems.reload);
  const pageNo = useSelector((state) => state.giftItems.saveGiftItemsPageNum);
  const searchBody = useSelector((state) => state.giftItems.searchBody);
  const _GiftSupplier = useSelector(
    (state) => state.itemSupplier.itemSupplierDataList
  );

  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    dispatch(getItemSupplierList());
  }, [dispatch]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
    setValue,
    // watch,
  } = useForm({
    defaultValues: giftItem.bulkUploadForm,
  });

  const handleDialog = () => {
    dispatch(openBulkUploadDialog());
  };

  useEffect(() => {
    reset({ ...giftItem.bulkUploadForm });
  }, [reset, giftItem.bulkUploadForm]);

  useEffect(() => {
    return () => {
      dispatch(resetBulkUploadForm());
    };
  }, [dispatch]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    dispatch(bulkUpload(data.supplierId, formData, pageNo, searchBody))
      .then((x) => {
        reset({ ...giftItem.bulkUploadForm });
        dispatch(resetBulkUploadForm());
        handleDialog();
        setSelectedFile(null);
      })
      .catch((e) => {});
  };

  return (
    <CustomDialog
      title={"Add Bulk Products"}
      isOpen={giftItem.openBulkUploadDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      {loading && <LinearProgress sx={{ mb: 1 }} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="supplierId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disabled={loading}
                  options={_GiftSupplier || []}
                  getOptionLabel={(option) => option.nameEn}
                  size="small"
                  renderInput={(params) => (
                    <TextField required {...params} label="Supplier" />
                  )}
                  onChange={(event, value) => {
                    setValue("supplierId", value ? value.id : null, {
                      shouldDirty: true,
                    });
                  }}
                  value={
                    _GiftSupplier?.filter((x) => x.id === field.value)[
                      0 ?? null
                    ]
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {!selectedFile && (
              <Button
                variant="contained"
                color="warning"
                size="medium"
                startIcon={<AttachFileIcon />}
                component="label"
                onChange={(e) => setSelectedFile(e.target.files[0])}
              >
                CHOOSE FILE
                <input
                  type="file"
                  accept=".xlsx,.xls"
                  style={{ display: "none" }}
                />
              </Button>
            )}
            {selectedFile && (
              <Stack direction="row" spacing={2}>
                <LoadingButton
                  startIcon={<CloudUploadIcon />}
                  variant="contained"
                  type="submit"
                  color="primary"
                  loading={loading}
                  disabled={!isDirty}
                >
                  Upload
                </LoadingButton>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ marginTop: "5px" }}>
                    File name: {selectedFile.name} , Size:{" "}
                    {selectedFile.size / 1000} KB
                  </Typography>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSelectedFile(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Stack>
            )}
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};
export default BulkUpload;
