
import axios from "../axios";

class ShippingConfigService {
  getAll() {
    return axios.get(`/shippingconfiguration/all`);
  }

  getById(id) {
    return axios.get(`/shippingconfiguration/getById/${id}`);
  }

  create(data) {
    return axios.post("/shippingconfiguration/create", data);
  }

  update(data) {
    return axios.put(`/shippingconfiguration/update`, data);
  }

  delete(id) {
    return axios.delete(`/shippingconfiguration/delete?id=${id}`);
  }

}
export default new ShippingConfigService()