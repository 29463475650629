import {
  ONBOARDING_RESET_FORM,
  ONBOARDING_GET_BY_ID,
  ONBOARDING_OPEN_DIALOG,
  ONBOARDING_RELOAD,
  ONBOARDING_GET_ALL,
  ONBOARDING_SEARCH_BODY,
} from "./type";

import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import OnboardingService from "src/api/services/onboarding.service";

export const openDialog = () => (dispatch) => {
  dispatch({
    type: ONBOARDING_OPEN_DIALOG,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: ONBOARDING_RESET_FORM,
  });
};

export const reloadOnboarding = () => (dispatch) => {
  dispatch({
    type: ONBOARDING_RELOAD,
  });
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: ONBOARDING_SEARCH_BODY,
    payload: data,
  });
};

export const getAllOnboarding = (pageNo, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadOnboarding());
    const res = await OnboardingService.getAll(pageNo, searchBody);
    dispatch({
      type: ONBOARDING_GET_ALL,
      payload: res.data,
    });
    dispatch(reloadOnboarding());
  } catch (err) {
    dispatch(reloadOnboarding());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateOnboarding =
  (id, data, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await OnboardingService.update(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllOnboarding(pageNo, searchBody));
      dispatch(openDialog());
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(reloadOnboarding());
    const res = await OnboardingService.getById(id);
    dispatch({
      type: ONBOARDING_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadOnboarding());
    dispatch(openDialog());
  } catch (err) {
    dispatch(reloadOnboarding());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
