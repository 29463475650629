import {
  GET_ALL_LOCKERS,
  RESET_FORM_LOCKER,
  GET_BY_ID_LOCKER,
  OPEN_LOCKER_DIALOG,
  RELOAD_LOCKER,
  SAVE_LOCKER_SAVE_NO,
  SAVE_LOCKER_SEARCH_BODY,
  GET_LOCKERS_LIST,
  OPEN_AVAILABLE_LOCKER_DIALOG,
  GET_LOCKER_HISTORY,
  OPEN_LOCKER_HISTORY_DIALOG,
  SAVE_LOCKER_HISTORY_SAVE_NO,
  SAVE_LOCKER_HISTORY_SEARCH_BODY,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  availableLockersDialog: false,
  lockerHistoryDialog: false,
  isAdd: false,
  pageNo: 0,
  lockerHistoryPageNo: 0,
  selectedLocker: 0,
  pageSize: 1000,
  form: {
    id: null,
    isActive: false,
    startIndex: "",
    endIndex: "",
  },
  lockerData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  lockersList: [],
  lockerHistory: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  lockerHistorySearchBody: {
    pageSize: 100,
  },
  searchBody: {
    pageSize: 1000,
    isActive: null,
    isEmpty: null,
    orderNumber: "",
    phoneNumber: "",
    number: "",
  },
  getById: {},
};

function lockerReducer(locker = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_LOCKERS:
      locker.lockerData.data = payload.data;
      locker.lockerData.total = payload.pageCount;
      locker.lockerData.rowCount = payload.rowCount;
      return locker;

    case GET_LOCKER_HISTORY:
      locker.lockerHistory.data = payload.data;
      locker.lockerHistory.total = payload.pageCount;
      locker.lockerHistory.rowCount = payload.rowCount;
      return locker;

    case GET_LOCKERS_LIST:
      locker.lockersList = payload;
      return locker;

    case GET_BY_ID_LOCKER:
      locker.form = payload;
      return locker;

    case RESET_FORM_LOCKER:
      locker.form = initialState.form;
      return locker;

    case SAVE_LOCKER_SAVE_NO:
      locker.pageNo = payload;
      return locker;

    case SAVE_LOCKER_HISTORY_SAVE_NO:
      return { ...locker, lockerHistoryPageNo: payload };

    case SAVE_LOCKER_SEARCH_BODY:
      return { ...locker, searchBody: payload };

    case SAVE_LOCKER_HISTORY_SEARCH_BODY:
      return { ...locker, lockerHistorySearchBody: payload };

    case OPEN_LOCKER_DIALOG:
      return {
        ...locker,
        openDialog: !locker.openDialog,
        isAdd: payload === "delete" ? false : true,
      };

    case OPEN_AVAILABLE_LOCKER_DIALOG:
      return {
        ...locker,
        availableLockersDialog: !locker.availableLockersDialog,
      };

    case OPEN_LOCKER_HISTORY_DIALOG:
      return {
        ...locker,
        lockerHistoryDialog: !locker.lockerHistoryDialog,
        selectedLocker: payload,
      };

    case RELOAD_LOCKER:
      return { ...locker, reload: !locker.reload };

    default:
      return locker;
  }
}

export default lockerReducer;
