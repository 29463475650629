import DealsService from "src/api/services/deals.service";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import helper from "src/utils/helper";
import {
  GET_ALL_DEALS,
  GET_BY_ID_DEALS,
  OPEN_DEALS_DIALOG,
  RELOAD_DEALS,
  RESET_DEALS_FORM,
  SAVE_DEALS_PAGE_NO,
  SAVE_DEALS_SEARCH_BODY,
} from "./type";

export const openDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_DEALS_DIALOG,
  });
};

export const create = (data, pageNo, searchBody) => async (dispatch) => {
  try {
    const res = await DealsService.create(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(getAll(pageNo, searchBody));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteDeals = (id, pageNo, searchBody) => async (dispatch) => {
  try {
    const res = await DealsService.delete(id);
    dispatch(showSuccessSnackbar("Deleted successfully!"));
    dispatch(getAll(pageNo, searchBody));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const reload = () => (dispatch) => {
  dispatch({
    type: RELOAD_DEALS,
  });
};

export const getById = (id, dialog) => async (dispatch) => {
  try {
    dispatch(reload());
    const res = await DealsService.getById(id);
    dispatch({
      type: GET_BY_ID_DEALS,
      payload: res.data.data,
    });
    if (dialog) dispatch(openDialog());
    dispatch(reload());
    // return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(reload());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAll = (pageNO, searchBody) => async (dispatch) => {
  try {
    dispatch(reload());
    const res = await DealsService.getAll(pageNO, searchBody);
    dispatch({
      type: GET_ALL_DEALS,
      payload: res.data.data,
    });
    dispatch(reload());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reload());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const savePageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_DEALS_PAGE_NO,
    payload: data,
  });
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_DEALS_SEARCH_BODY,
    payload: data,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: RESET_DEALS_FORM,
  });
};

export const update = (id, data, pageNo, searchBody) => async (dispatch) => {
  try {
    const res = await DealsService.update(id, data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    dispatch(getAll(pageNo, searchBody));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
