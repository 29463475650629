import axios from "../axios";

class ItemService {
  addExternal(data) {
    return axios.post(`/item/addexternalitem`,data);
  }



}

export default new ItemService()