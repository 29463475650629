import axios from "../axios";

class EventsService {
  getAllEvents(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`giftevents/${pageNo}?${params}`);
  }

  getById(id) {
    return axios.get(`/giftevents/getbyid/${id}`);
  }

  createSuggestedEvent(data) {
    return axios.post("/giftevents/createpublic", data);
  }

  updateSuggestedEvent(id, data) {
    return axios.put(`/giftevents/updatepublicevent/${id}`, data);
  }

  deleteSuggestedEvent(id) {
    return axios.delete(`/giftevents/publicevent/${id}`);
  }
}

export default new EventsService();
