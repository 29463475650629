import {
  TextField,
  Grid,
  Stack,
  FormControlLabel,
  Switch,
  Autocomplete,
  InputAdornment,
  Tooltip,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Badge,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  createPromo,
  openDialog,
  resetForm,
  updatePromo,
} from "src/actions/promos";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { getAllVendors } from "src/actions/vendors";
import { DataGrid } from "@mui/x-data-grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { getByPhoneNumber } from "src/actions/users";
import { showErrorSnackbar } from "src/actions/snackbarMessages";
import { useNavigate } from "react-router";
import { IoMdCart } from "react-icons/io";
import { IoWallet } from "react-icons/io5";
import styled from "@emotion/styled";
import { TbRefresh } from "react-icons/tb";
import ReactCountryFlag from "react-country-flag";
import { country_codes } from "src/utils/common";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 4,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const CustomerPromosForm = (props) => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const _Promos = useSelector((state) => state.promos);
  const loading = useSelector((state) => state.promos.reload);
  const savePromoPageNO = useSelector((state) => state.promos.savePromoPageNum);
  const _SearchBody = useSelector((state) => state.promos.searchBody);
  const _Vendors = useSelector((state) => state.vendors.dataList);

  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [userIds, setUserIds] = useState([]);

  const isAdd = _Promos.form.id ? false : true;
  const { control, reset, watch, handleSubmit, setValue } = useForm({
    defaultValues: _Promos.form,
  });

  const handleChange = (event, newType) => {
    setValue("promoCodeType", newType, { shouldValidate: true });
  };

  useEffect(() => {
    reset({ ..._Promos.form });
    if (props.name) {
      setValue("name", props.name, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_Promos.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getAllVendors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        createPromo(
          {
            name: data.name,
            discount: data.discount,
            discountLimit: data.discountLimit,
            validUntil: data.validUntil,
            isActive: data.isActive,
            newUsersOnly: data.newUsersOnly,
            allowedPerUser: parseInt(data.allowedPerUser),
            limitedUserCount: data.limitedUserCount,
            totalCost: data.totalCost,
            promoCodeType: data.promoCodeType,
            vendordId: data.vendordId,
            region: data.region,
            isCashback: data.isCashback,
            note: data.note,
            userIds: props.name
              ? [props.userId]
              : watch("userIds")
              ? userIds
              : data.users.map((x) => x.id),
            isFreeDelivery: data.isFreeDelivery,
          },
          savePromoPageNO,
          { ..._SearchBody, isBusiness: false }
        )
      );
    } else {
      dispatch(
        updatePromo(
          {
            id: data.id,
            name: data.name,
            discount: data.discount,
            discountLimit: data.discountLimit,
            validUntil: data.validUntil,
            isActive: data.isActive,
            newUsersOnly: data.newUsersOnly,
            allowedPerUser: parseInt(data.allowedPerUser),
            limitedUserCount: data.limitedUserCount,
            totalCost: data.totalCost,
            promoCodeType: data.promoCodeType,
            vendordId: data.vendordId,
            region: data.region,
            isCashback: data.isCashback,
            note: data.note,
            userIds: props.name
              ? [props.userId]
              : watch("userIds")
              ? userIds
              : data.users.map((x) => x.id),
            isFreeDelivery: data.isFreeDelivery,
          },
          savePromoPageNO,
          { ..._SearchBody, isBusiness: false }
        )
      );
    }
  };

  const handleDialog = () => {
    dispatch(openDialog());
  };

  const handleGetUsers = () => {
    if (
      watch("users")
        .map((x) => x.phoneNumber)
        .includes(userPhoneNumber)
    ) {
      return dispatch(showErrorSnackbar("User already added!"));
    } else {
      if (userPhoneNumber.length > 0) {
        dispatch(getByPhoneNumber(userPhoneNumber)).then((res) => {
          if (res?.data === null) {
            return dispatch(showErrorSnackbar("User not found!"));
          } else {
            setValue("users", [...watch("users"), res?.data]);
          }
        });
      } else {
        return dispatch(showErrorSnackbar("Please enter a phone number!"));
      }
    }
  };

  const columns = [
    {
      field: "firstName",
      headerName: "Full Name",
      flex: 1,
      renderCell: (params) => params.row.firstName + " " + params.row.lastName,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={1} alignItems="center">
          <ReactCountryFlag
            style={{
              width: "40px",
              height: "30px",
              border: "solid 1px #dedede",
            }}
            svg
            countryCode={
              country_codes.filter(
                (code) => code.dial_code === params.row?.countryCode
              )[0]?.code
            }
          />
          <Typography variant="body">{params.row?.phoneNumber}</Typography>
        </Stack>
      ),
    },
    {
      field: "Operations",
      headerName: "Operations",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={2}>
          <IconButton
            color="error"
            aria-label="delete"
            onClick={(e) => {
              setValue(
                "users",
                watch("users").filter((x) => x.id !== params.row.id)
              );
            }}
          >
            <Tooltip title="Delete" aria-label="delete">
              <DeleteForeverIcon />
            </Tooltip>
          </IconButton>
          <Tooltip title="Global Cart">
            <StyledBadge
              badgeContent={params.row.orderiiCartItemsCount}
              color="error"
            >
              <IconButton
                onClick={(e) => {
                  navigate(`/app/cart/${params.row.cartId}`);
                }}
              >
                <IoMdCart style={{ color: "#8444FF" }} />
              </IconButton>
            </StyledBadge>
          </Tooltip>
          <Tooltip title="Gift Cart">
            <StyledBadge
              badgeContent={params.row.giftCartItemsCount}
              color="error"
            >
              <IconButton
                onClick={(e) => {
                  navigate(`/app/gift_cart/${params.row.giftCartId}`);
                }}
              >
                <IoMdCart style={{ color: "#00B393" }} />
              </IconButton>
            </StyledBadge>
          </Tooltip>
          <Tooltip title="User Wallet">
            <IconButton
              color="info"
              onClick={(e) => {
                navigate(`/app/wallet/${params.row.id}`);
              }}
            >
              <IoWallet />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <CustomDialog
      title={isAdd ? "CREATE PROMO" : "UPDATE PROMO"}
      isOpen={_Promos.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {isAdd && (
            <Grid item xs={12} md={12}>
              <Controller
                name="promoCodeType"
                control={control}
                render={({ field }) => (
                  <ToggleButtonGroup
                    color="primary"
                    size="small"
                    {...field}
                    onChange={handleChange}
                    exclusive
                  >
                    <ToggleButton
                      value={0}
                      onClick={() =>
                        setValue("promoCodeType", 0, { shouldValidate: true })
                      }
                    >
                      Global
                    </ToggleButton>
                    <ToggleButton
                      value={1}
                      onClick={() =>
                        setValue("promoCodeType", 1, { shouldValidate: true })
                      }
                    >
                      Gift
                    </ToggleButton>
                    {/* <ToggleButton
                      value={2}
                      onClick={() =>
                        setValue("promoCodeType", 2, { shouldValidate: true })
                      }
                    >
                      Global And Gift
                    </ToggleButton> */}
                  </ToggleButtonGroup>
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  disabled={props.name ? true : false}
                  required
                  size="small"
                  label="Name"
                  fullWidth
                  {...field}
                  inputProps={{ maxLength: 6 }}
                  helperText={
                    field.value.length < 6 ? "Maximum 6 Characters" : null
                  }
                  InputProps={{
                    endAdornment: props.name ? (
                      <InputAdornment position="end" sx={{ marginY: 2 }}>
                        <Tooltip title="Generate new promo code">
                          <IconButton
                            fullWidth
                            variant="contained"
                            onClick={(e) => {
                              e.preventDefault();
                              setValue(
                                "name",
                                `CR${Math.random()
                                  .toString(36)
                                  .substring(2, 6)
                                  .toUpperCase()
                                  .toUpperCase()}`
                              );
                            }}
                          >
                            <TbRefresh />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ) : null,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="validUntil"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    label="Expiration date"
                    value={field.value}
                    onChange={(newValue) => {
                      setValue("validUntil", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        size="small"
                        {...params}
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "YYYY-MM-DD",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          {isAdd && watch("promoCodeType") === 0 && (
            <Grid item xs={12} md={6}>
              <Controller
                name="region"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    disabled={watch("vendordId") ? true : false}
                    options={["TR", "US", "EU", "AG", "WSM"]}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Region" />
                    )}
                    onChange={(event, value) => {
                      setValue("region", value ?? null);
                    }}
                    value={field.value}
                  />
                )}
              />
            </Grid>
          )}
          {isAdd && watch("promoCodeType") === 0 && (
            <Grid item xs={12} md={6}>
              <Controller
                name="vendordId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    disabled={watch("region") ? true : false}
                    options={_Vendors || []}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Vendors" />
                    )}
                    onChange={(event, value) => {
                      setValue("vendordId", value ? value.id : null);
                    }}
                    value={
                      _Vendors.filter((x) => x.id === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Controller
              name="discount"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Discount"
                  fullWidth
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="discountLimit"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Discount Limit"
                  fullWidth
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {watch("promoCodeType") === 0 ? "$" : "IQD"}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="allowedPerUser"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Max usage per user"
                  fullWidth
                  {...field}
                  InputProps={{
                    type: "number",
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="limitedUserCount"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Limited Users"
                  fullWidth
                  {...field}
                  helperText="Put 0 for unlimited usage"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextField
                  multiline
                  rows={4}
                  required
                  size="small"
                  label="Note"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          {props.name ? null : (
            <Grid item xs={12} md={6}>
              <Controller
                name="isAllUsers"
                control={control}
                render={({ field }) => (
                  <Tooltip title="FALSE | TRUE" aria-label="active_deactive">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={field.value}
                          onChange={(e) => {
                            setValue("isAllUsers", e.target.checked);
                            if (e.target.checked === true) {
                              setValue("users", []);
                              setUserIds([]);
                            }
                          }}
                        />
                      }
                      label="For All Users"
                    />
                  </Tooltip>
                )}
              />
            </Grid>
          )}
          {!watch("isAllUsers") && (
            <Grid item xs={12} md={6}>
              <Controller
                name="userIds"
                control={control}
                render={({ field }) => (
                  <Tooltip title="FALSE | TRUE" aria-label="active_deactive">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={field.value}
                          onChange={(e) => {
                            setValue("userIds", e.target.checked);
                            if (e.target.checked) {
                              setUserIds(watch("users").map((x) => x.id));
                              // setValue("users", []);
                            } else {
                              setUserIds([]);
                            }
                          }}
                        />
                      }
                      label="User Ids"
                    />
                  </Tooltip>
                )}
              />
            </Grid>
          )}
          {!watch("isAllUsers") && watch("userIds") ? (
            <Grid item xs={12}>
              <TextField
                size="small"
                label="Add User Ids"
                fullWidth
                multiline
                rows={4}
                value={userIds.join(", ")}
                onChange={(e) => {
                  setUserIds(
                    e.target.value
                      .replace(/\s+/g, " ")
                      .split(",")
                      .map((id) => id.trim())
                  );
                }}
              />
            </Grid>
          ) : null}
          {!watch("isAllUsers") && !watch("userIds") ? (
            <Grid item xs={12}>
              <TextField
                size="small"
                label="Search user by phone number"
                fullWidth
                value={userPhoneNumber}
                onChange={(e) => {
                  if (e.target.value.startsWith("0")) {
                    setUserPhoneNumber(e.target.value.substring(1));
                  } else {
                    setUserPhoneNumber(e.target.value);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleGetUsers();
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
            </Grid>
          ) : null}
          {!watch("isAllUsers") && !watch("userIds") ? (
            <Grid item xs={12}>
              <DataGrid
                columns={columns}
                rows={watch("users") || []}
                loading={loading}
                disableSelectionOnClick
                rowHeight={50}
                autoHeight
                rowsPerPageOptions={[15, 25, 75, 100]}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} md={6}>
            <Controller
              name="isFreeDelivery"
              control={control}
              render={({ field }) => (
                <Tooltip title="FALSE | TRUE" aria-label="active_deactive">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("isFreeDelivery", e.target.checked);
                        }}
                      />
                    }
                    label="Free Delivery Only"
                  />
                </Tooltip>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="newUsersOnly"
              control={control}
              render={({ field }) => (
                <Tooltip title="FALSE | TRUE" aria-label="active_deactive">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("newUsersOnly", e.target.checked);
                        }}
                      />
                    }
                    label="New Users Only"
                  />
                </Tooltip>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="totalCost"
              control={control}
              render={({ field }) => (
                <Tooltip
                  title="Shipping Only | Total Amount"
                  aria-label="active_deactive"
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("totalCost", e.target.checked);
                        }}
                      />
                    }
                    label="Discount For Order Amount"
                  />
                </Tooltip>
              )}
            />
          </Grid>
          {isAdd && (
            <Grid item xs={12} md={6}>
              <Controller
                name="isCashback"
                control={control}
                render={({ field }) => (
                  <Tooltip title="FALSE | TRUE" aria-label="active_deactive">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={field.value}
                          onChange={(e) => {
                            setValue("isCashback", e.target.checked);
                          }}
                        />
                      }
                      label="Is Cashback !"
                    />
                  </Tooltip>
                )}
              />
            </Grid>
          )}
          {!isAdd ? (
            <Grid item xs={12} md={12}>
              <Controller
                name="createdAt"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Creation date"
                      value={field.value}
                      onChange={(newValue) => {}}
                      readOnly
                      renderInput={(params) => (
                        <TextField size="small" {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
              >
                Save
              </LoadingButton>
              {isAdd && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => reset({ ..._Promos.form })}
                >
                  Clear Text
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default CustomerPromosForm;
