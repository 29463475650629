import moment from "moment";
import {
  GET_ALL_SCHEDULED_ACTION,
  GET_BY_ID_SCHEDULED_ACTION,
  OPEN_SCHEDULED_ACTION_DIALOG,
  RELOAD_SCHEDULED_ACTION,
  RESET_FORM_SCHEDULED_ACTION,
  SAVE_SCHEDULED_ACTION_SAVE_NO,
  SAVE_SCHEDULED_ACTION_SEARCH_BODY,
} from "src/actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 1,
  searchBody: {
    pageSize: 10,
    status: null,
    url: null,
  },
  form: {
    id: null,
    isOneTime: true,
    actionTimes: [
      {
        weekDay: 0,
        checked: false,
        times: [],
      },
      {
        weekDay: 1,
        checked: false,
        times: [],
      },
      {
        weekDay: 2,
        checked: false,
        times: [],
      },
      {
        weekDay: 3,
        checked: false,
        times: [],
      },
      {
        weekDay: 4,
        checked: false,
        times: [],
      },
      {
        weekDay: 5,
        checked: false,
        times: [],
      },
      {
        weekDay: 6,
        checked: false,
        times: [],
      },
    ],
    actionTime: moment().format("YYYY-MM-DD"),
    headers: "",
    body: "",
    url: "",
    methodType: 0,
  },
  scheduledActionData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  getById: {},
};

function scheduledActionsReducer(scheduledActions = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_SCHEDULED_ACTION:
      scheduledActions.scheduledActionData.data = payload.data.map((item) => {
        return {
          ...item,
          body: JSON.parse(item.body) ?? "",
        };
      });
      scheduledActions.scheduledActionData.total = payload.pageCount;
      scheduledActions.scheduledActionData.rowCount = payload.rowCount;
      return scheduledActions;

    case GET_BY_ID_SCHEDULED_ACTION:
      scheduledActions.form = payload;
      return scheduledActions;

    case RESET_FORM_SCHEDULED_ACTION:
      scheduledActions.form = initialState.form;
      return scheduledActions;

    case SAVE_SCHEDULED_ACTION_SAVE_NO:
      return { ...scheduledActions, pageNo: payload };

    case SAVE_SCHEDULED_ACTION_SEARCH_BODY:
      return { ...scheduledActions, searchBody: payload };

    case OPEN_SCHEDULED_ACTION_DIALOG:
      return { ...scheduledActions, openDialog: !scheduledActions.openDialog };

    case RELOAD_SCHEDULED_ACTION:
      return { ...scheduledActions, reload: !scheduledActions.reload };

    default:
      return scheduledActions;
  }
}

export default scheduledActionsReducer;
