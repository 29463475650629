import { Grid, Stack, Box, FormControlLabel, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { uploadAttachments } from "src/actions/uploadAttachments";
import {
  createWishCard,
  openWishCardDialog,
  resetWishCardForm,
  updateWishCard,
} from "src/actions/gifts/wrapping";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const WishCardForm = () => {
  const dispatch = useDispatch();

  const wishCard = useSelector((state) => state.wrapping);
  const loading = useSelector((state) => state.wrapping.wishCardReload);
  const searchBody = useSelector((state) => state.wrapping.wishCardSearchBody);
  const pageNo = useSelector((state) => state.wrapping.wishCardPageNo);
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
    setValue,
    watch,
  } = useForm({
    defaultValues: wishCard.wishCardForm,
  });

  const [attachmentsFiles, setAttachmentsFiles] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (attachmentsFiles.length > 0) {
      setFiles(attachmentsFiles.map((files) => files.file));
      setValue("attachmentId", attachmentsFiles.map((files) => files.file)[0], {
        shouldDirty: true,
      });
    } else {
      setValue("attachmentId", watch("attachmentId"), {
        shouldDirty: true,
      });
    }
  }, [attachmentsFiles, setValue, watch]);

  const isAdd = wishCard.wishCardForm.id ? false : true;

  const handleDialog = () => {
    dispatch(openWishCardDialog());
  };

  useEffect(() => {
    reset({ ...wishCard.wishCardForm });
  }, [reset, wishCard.wishCardForm]);

  useEffect(() => {
    return () => {
      dispatch(resetWishCardForm());
    };
  }, [dispatch]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("type", 6);
    if (isAdd) {
      dispatch(uploadAttachments(formData))
        .then((response) => {
          if (response.message === "Success") {
            dispatch(
              createWishCard(
                {
                  descriptionAr: "",
                  descriptionEn: "",
                  isActive: data.isActive,
                  attachmentId: response.data.id,
                },
                pageNo + 1,
                searchBody
              )
            )
              .then(() => {
                reset({ ...wishCard.wishCardForm });
                dispatch(resetWishCardForm());
                handleDialog();
              })
              .catch((err) => {});
          }
        })
        .catch((err) => {});
    } else {
      if (files[0]) {
        dispatch(uploadAttachments(formData))
          .then((res) => {
            if (res.message === "Success") {
              dispatch(
                updateWishCard(
                  data.id,
                  {
                    descriptionEn: "",
                    descriptionAr: "",
                    isActive: data.isActive,
                    attachmentId: res.data.id,
                  },
                  pageNo + 1,
                  searchBody
                )
              ).then((x) => {
                reset({ ...wishCard.form });
                dispatch(resetWishCardForm());
                handleDialog();
              });
            }
          })
          .catch((err) => {});
      } else {
        dispatch(
          updateWishCard(
            data.id,
            {
              descriptionEn: "",
              descriptionAr: "",
              isActive: data.isActive,
              attachmentId: data.attachmentId?.id,
            },
            pageNo + 1,
            searchBody
          )
        )
          .then((x) => {
            reset({ ...wishCard.wishCardForm });
            dispatch(resetWishCardForm());
            handleDialog();
          })
          .catch((err) => {});
      }
    }
  };

  return (
    <CustomDialog
      title={isAdd ? "Create order wish card" : "Update order wish card"}
      isOpen={wishCard.wishCardDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={6}>
            <Controller
              name="nameEn"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="nameAr"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12} md={12} display="flex" justifyContent="center">
            <Controller
              name={"isActive"}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={field.value}
                      onChange={(e) => {
                        setValue("isActive", e.target.checked, {
                          shouldDirty: true,
                        });
                      }}
                    />
                  }
                  label="Is Active!"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="attachmentId"
              control={control}
              render={({ field }) => (
                <FilePond
                  required={watch("attachmentId") === "" ? true : false}
                  files={attachmentsFiles}
                  onupdatefiles={setAttachmentsFiles}
                  maxFiles={10}
                  name="file"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  credits={false}
                />
              )}
            />
          </Grid>
          {!isAdd ? (
            <Grid
              item
              xs={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                component="img"
                src={wishCard.wishCardForm.attachmentId?.url}
                width={300}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};
export default WishCardForm;
