import {
  OPEN_EPAYMENTS_DIALOG,
  GET_ALL_EPAYMENTS,
  SAVE_SEARCH_BODY_EPAYMENTS,
  GET_BY_ID_EPAYMENTS,
  RELOAD_EPAYMENTS_,
  RESET_FORM_EPAYMENTS,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  logo: null,
  form: {
    id: null,
    name: "",
    logo: "",
    isActive: false,
    type: null,
    isPersonal: false,
    isInternational: false,
    provinces: [],
  },
  data: [],
  type: 0,
  getById: {},
};

function ePaymentsReducer(ePayments = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_EPAYMENTS:
      var _GeneralSetting = {
        reload: ePayments.reload,
        data: payload,
        type: ePayments.type,
        getById: ePayments.getById,
        form: ePayments.form,
        openDialog: false,
      };
      return _GeneralSetting;

    case RESET_FORM_EPAYMENTS:
      ePayments.form.id = null;
      ePayments.form.name = "";
      ePayments.form.logo = "";
      ePayments.form.type = null;
      ePayments.form.isActive = false;
      ePayments.form.isPersonal = false;
      ePayments.form.isInternational = false;
      ePayments.form.provinces = [];
      return ePayments;

    case GET_BY_ID_EPAYMENTS:
      ePayments.form = payload;
      return ePayments;

    case RELOAD_EPAYMENTS_:
      return { ...ePayments, reload: !ePayments.reload };

    case SAVE_SEARCH_BODY_EPAYMENTS:
      return { ...ePayments, type: payload };

    case OPEN_EPAYMENTS_DIALOG:
      return { ...ePayments, openDialog: !ePayments.openDialog, logo: payload };

    default:
      return ePayments;
  }
}

export default ePaymentsReducer;
