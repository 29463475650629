import { Helmet } from "react-helmet";
import { Box, Button } from "@mui/material";
import { Card, CardHeader, Typography } from "@mui/material";
import { FaHome } from "react-icons/fa";
import RolePermission from "src/components/RolePermission";
import { useDispatch, useSelector } from "react-redux";
import { openDialogHomeItems } from "src/actions/homeItems";
import AddIcon from "@mui/icons-material/Add";
import HomeItemsForm from "src/pages/cross/homeItems/from/Form";

const HomeItemsHeader = () => {
  const dispatch = useDispatch();

  const homeItems = useSelector((state) => state.homeItems.openDialog);

  return (
    <>
      <Helmet>
        <title>Home Items | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Home Items
              </Typography>
            }
            avatar={<FaHome size={20} />}
            action={
              <RolePermission permission="38-03">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => dispatch(openDialogHomeItems())}
                >
                  Update
                </Button>
              </RolePermission>
            }
          />
          {homeItems ? <HomeItemsForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default HomeItemsHeader;
