import { Grid, Box } from "@mui/material";
import React from "react";
import ShipmentsTable from "./home/table";
import ShipmentsHeader from "./home/header";

const Shipments = () => {
  return (
    <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <ShipmentsHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <ShipmentsTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Shipments;
