import WrappingService from "src/api/giftServices/wrapping.service";
import helper from "src/utils/helper";
import uploadAttachmentsService from "src/api/services/uploadAttachments.service";
import {
  RESET_WISH_CARD_FORM,
  SAVE_WISH_CARD_SEARCH_BODY,
  SAVE_WISH_CARD_PAGE_NO,
  RELOAD_WISH_CARD,
  GET_BY_ID_WISH_CARD,
  OPEN_WISH_CARD_DIALOG,
  GET_ALL_WISH_CARD,
  RESET_WRAPPING_FORM,
  SAVE_WRAPPING_SEARCH_BODY,
  SAVE_WRAPPING_PAGE_NO,
  RELOAD_WRAPPING,
  GET_BY_ID_WRAPPING,
  OPEN_WRAPPING_DIALOG,
  GET_ALL_WRAPPING,
} from "src/actions/gifts/giftTypes";
import { UPLOAD_ATTACHMENTS } from "src/actions/type";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";

export const openWishCardDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_WISH_CARD_DIALOG,
    payload: data,
  });
};

export const saveWishCardPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_WISH_CARD_PAGE_NO,
    payload: data,
  });
};

export const saveWishCardSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_WISH_CARD_SEARCH_BODY,
    payload: data,
  });
};

export const resetWishCardForm = () => (dispatch) => {
  dispatch({
    type: RESET_WISH_CARD_FORM,
  });
};

export const reloadWishCard = () => (dispatch) => {
  dispatch({
    type: RELOAD_WISH_CARD,
  });
};

export const getAllWishCard = (pageNO, pageSize) => async (dispatch) => {
  try {
    dispatch(reloadWishCard());
    const res = await WrappingService.getAllWishCard(pageNO, pageSize);
    dispatch({
      type: GET_ALL_WISH_CARD,
      payload: res.data.data,
    });
    dispatch(reloadWishCard());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadWishCard());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const createWishCard = (data, pageNO, pageSize) => async (dispatch) => {
  try {
    const res = await WrappingService.createWishCard(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(getAllWishCard(pageNO, pageSize));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteWishCard = (id, pageNO, pageSize) => async (dispatch) => {
  try {
    const res = await WrappingService.deleteWishCard(id);
    dispatch(showSuccessSnackbar("Wish card deleted successfully!"));
    dispatch(getAllWishCard(pageNO, pageSize));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getWishCardById = (id, openDialog) => async (dispatch) => {
  try {
    dispatch(reloadWishCard());
    const res = await WrappingService.getWishCardById(id);
    dispatch({
      type: GET_BY_ID_WISH_CARD,
      payload: res.data.data,
    });
    if (openDialog) dispatch(openWishCardDialog());
    dispatch(reloadWishCard());
    // return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(reloadWishCard());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateWishCard =
  (id, data, pageNO, pageSize) => async (dispatch) => {
    try {
      const res = await WrappingService.updateWishCard(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllWishCard(pageNO, pageSize));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

// -------------------------------- Wrapping types actions --------------------------------//

export const openWrappingTypeDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_WRAPPING_DIALOG,
    payload: data,
  });
};

export const saveWrappingTypePageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_WRAPPING_PAGE_NO,
    payload: data,
  });
};

export const saveWrappingTypeSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_WRAPPING_SEARCH_BODY,
    payload: data,
  });
};

export const resetWrappingTypeForm = () => (dispatch) => {
  dispatch({
    type: RESET_WRAPPING_FORM,
  });
};

export const reloadWrappingType = () => (dispatch) => {
  dispatch({
    type: RELOAD_WRAPPING,
  });
};

export const getAllIWrappingType = (pageNO, pageSize) => async (dispatch) => {
  try {
    dispatch(reloadWrappingType());
    const res = await WrappingService.getAllWrapping(pageNO, pageSize);
    dispatch({
      type: GET_ALL_WRAPPING,
      payload: res.data.data,
    });
    dispatch(reloadWrappingType());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadWrappingType());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const createWrappingType =
  (data, pageNO, pageSize) => async (dispatch) => {
    try {
      const res = await WrappingService.createWrapping(data);
      dispatch(showSuccessSnackbar("Created successfully"));
      dispatch(getAllIWrappingType(pageNO, pageSize));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteWrappingType =
  (id, pageNO, pageSize) => async (dispatch) => {
    try {
      const res = await WrappingService.deleteWrapping(id);
      dispatch(showSuccessSnackbar("Wrapping type deleted successfully!"));
      dispatch(getAllIWrappingType(pageNO, pageSize));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getWrappingTypeById = (id, openDialog) => async (dispatch) => {
  try {
    dispatch(reloadWrappingType());
    const res = await WrappingService.getWrappingById(id);
    dispatch({
      type: GET_BY_ID_WRAPPING,
      payload: res.data.data,
    });
    if (openDialog) dispatch(openWrappingTypeDialog());
    dispatch(reloadWrappingType());
    // return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(reloadWrappingType());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateWrappingType =
  (id, data, pageNO, pageSize) => async (dispatch) => {
    try {
      const res = await WrappingService.updateWrapping(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllIWrappingType(pageNO, pageSize));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const uploadAttachments = (data) => async (dispatch) => {
  try {
    dispatch(reloadWrappingType());
    const res = await uploadAttachmentsService.uploadAttachments(data);
    dispatch(showSuccessSnackbar("Uploaded successfully"));
    dispatch({
      type: UPLOAD_ATTACHMENTS,
      data: res.data,
    });
    dispatch(reloadWrappingType());
    // return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadWrappingType());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
