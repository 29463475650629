import {
  Box,
  Button,
  Card,
  ClickAwayListener,
  MenuList,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

export default function DateRangepicker({
  handleOnChange = function () {},
  startDate,
  endDate,
  disabled,
  title,
}) {
  const [searchDate, setSearchDate] = useState([
    {
      // startDate: new Date(startDate),
      // endDate: new Date(endDate),
      startDate: startDate ? moment(startDate).toDate() : null,
      endDate: endDate ? moment(endDate).toDate() : new Date(""),
      key: "selection",
    },
  ]);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    if (!disabled) {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.stopPropagation();
    }
  }

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack>
      <Box>
        <Popover
          open={open}
          anchorEl={anchorRef.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Card>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id="composition-menu"
                aria-labelledby="composition-button"
                onKeyDown={handleListKeyDown}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="start"
                >
                  <DateRangePicker
                    onChange={(item) => setSearchDate([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={searchDate}
                    direction="horizontal"
                    editableDateInputs={true}
                    dateDisplayFormat="yyyy-MM-dd"
                  />
                  <Button
                    onClick={() => {
                      handleOnChange(searchDate);
                      setOpen(false);
                    }}
                    variant="contained"
                    sx={{ margin: 2 }}
                  >
                    OK
                  </Button>
                </Stack>
              </MenuList>
            </ClickAwayListener>
          </Card>
        </Popover>
      </Box>
      <Stack
        ref={anchorRef}
        direction="row"
        spacing={2}
        sx={{ alignItems: "center", cursor: "pointer" }}
        onClick={() => handleToggle()}
      >
        <TextField
          disabled={disabled ?? false}
          autoComplete="off"
          fullWidth
          size="small"
          value={startDate ? `${moment(startDate).format("YYYY-MM-DD")}` : ""}
          name="dateRange"
          label={`${title ? title + " Start Date" : "Start Date"}`}
        />
        <Typography>To</Typography>
        <TextField
          disabled={disabled ?? false}
          autoComplete="off"
          fullWidth
          size="small"
          value={endDate ? `${moment(endDate).format("YYYY-MM-DD")}` : ""}
          name="dateRange"
          label={`${title ? title + " End Date" : "End Date"}`}
        />
      </Stack>
    </Stack>
  );
}
