import {
  Box,
  Card,
  Typography,
  Checkbox,
  CardHeader,
  Grid,
  Autocomplete,
  CardContent,
  TextField,
  Divider,
} from "@mui/material";
import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import {
  getTopTenVendersByClick,
  getTopTenVendersByOrder,
} from "src/actions/reports";
import { useEffect } from "react";
import CustomSpinner from "src/components/CustomSpinner";
import { FormControlLabel, Stack } from "@mui/material";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";


const TopTenVendors = (props) => {
  const dispatch = new useDispatch();

  const [vendorDataClick, setVendorDataClick] = useState([]);
  const [vendorDataOrder, setVendorDataOrder] = useState([]);
  const [value, setValue] = useState(0);
  const [reloadClick, setReloadClick] = useState(false);
  const [reloadOrder, setReloadOrder] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState({ tr: true, us: true });
  const [pageSize, setPageSize] = useState(10);
  const [region, setRegion] = useState('');


  useEffect(() => {
    // var region =
    //   selectedRegion.tr && selectedRegion.us
    //     ? ""
    //     : selectedRegion.tr
    //       ? "TR"
    //       : "US";
    getTopVendorsByOrder(region);
    getTopVendorsByClick(region);
  }, [region, props.setSearch]);

  const handleChange = (event, newValue) => {
    setVendorDataClick([]);
    setVendorDataOrder([]);
    setValue(newValue);
  };

  function getTopVendorsByClick(region) {
    setReloadClick(true);
    dispatch(
      getTopTenVendersByClick({
        region: region,
        count: 10,
      })
    ).then((res) => {
      if (res.data) setVendorDataClick(res.data);
      setReloadClick(false);
    }).catch((err) => { })
  }

  function getTopVendorsByOrder(region) {
    setReloadOrder(true);
    dispatch(
      getTopTenVendersByOrder({
        region: region,
        count: 10,
      })
    ).then((res) => {
      if (res.data) setVendorDataOrder(res.data);
      setReloadOrder(false);
    }).catch((err) => { })
  }
  const title = () => {
    return (
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"space-between"}
        sx={{ marginBottom: "-15px" }}
      >
        <Typography variant="h5" sx={{ marginRight: 5 }}>
          Top Vendors
        </Typography>
        <Stack spacing={1} direction="row">
          <FormControlLabel
            control={
              <Checkbox
                label="TR"
                checked={selectedRegion.tr}
                onChange={(e) => {
                  setSelectedRegion({
                    ...selectedRegion,
                    tr: e.target.checked,
                  });
                }}
                size="small"
              />
            }
            label="TR"
          />
          <FormControlLabel
            control={
              <Checkbox
                label="US"
                checked={selectedRegion.us}
                size="small"
                onChange={(e) => {
                  setSelectedRegion({
                    ...selectedRegion,
                    us: e.target.checked,
                  });
                }}
              />
            }
            label="US"
          />
        </Stack>
      </Stack>
    );
  };

  function CustomExport() {
    return (
      <Box>
        <Box>
          <Box>
          </Box>
        </Box>
        <Box>
          <GridToolbarContainer>
            <GridToolbarExport
              csvOptions={{
                fileName: "Top Vendors || Orderii",
                utf8WithBom: true,
              }}
            />
          </GridToolbarContainer>
        </Box>
      </Box>
    );
  }
  return (
    <Card>
      <Card>
        <CardHeader
          title="Top Vendors"
        />
        <CardContent>
          <Grid container>
            <Grid item xs={3}>
              <Autocomplete
                sx={{ widows: '25%' }}
                options={["TR", "US", 'EU', "AG", "WSM"]}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Region" />
                )}
                onChange={(event, value) => {
                  setRegion(value ?? "");
                }}
                value={region}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </Card>
      <PerfectScrollbar>
        <Box sx={{ height: 'calc(100vh - 410px)' }}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12} xl={6} xs={12}>
              {reloadOrder ? (
                <CustomSpinner />
              ) : (
                <Box sx={{ height: 'calc(100vh - 490px)' }}>
                  <Card sx={{ margin: "5px 0" }}>
                    <CardHeader title="Top Vendors By Order Count" />
                  </Card>
                  <DataGrid
                    sx={{
                      "& .MuiDataGrid-row ": {
                        textAlign: "center",
                      },
                      "& .css-204u17-MuiDataGrid-main": {
                        paddingLeft: "15px",
                      },
                      "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                        paddingLeft: "15px",
                      },
                    }}
                    disableSelectionOnClick={true}
                    columns={[
                      { field: "#", headerName: "#", flex: 0.3, },
                      {
                        field: "vendorName",
                        headerName: "Vendor Name",
                        flex: 1,
                      },
                      {
                        field: "region",
                        headerName: "Region",
                        flex: 1,
                      },
                      {
                        field: "totalOrders",
                        headerName: "Total Orders",
                        flex: 1,
                      },
                      {
                        field: "totalAmount",
                        headerName: "Total Amount ($)",
                        flex: 1,
                      },
                    ]}
                    rows={vendorDataOrder.map((x, index) => {
                      return {
                        id: index,
                        "#": index + 1,
                        vendorName: x.vendorName,
                        region: x.region,
                        totalOrders: x.totalOrders,
                        totalAmount: x.totalAmount,
                      };
                    })}
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 25, 100]}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    components={{
                      Toolbar: CustomExport,
                    }}
                  />
                </Box>
              )}
            </Grid>
            <Grid item lg={6} md={12} xl={6} xs={12}>
              {reloadClick ? (
                <CustomSpinner />
              ) : (
                <Box sx={{ height: 'calc(100vh - 490px)' }}>
                  <Card sx={{ margin: "5px 0" }}>
                    <CardHeader title="Top Vendors By Click Count" />
                  </Card>
                  <DataGrid
                    sx={{
                      "& .MuiDataGrid-row ": {
                        textAlign: "center",
                      },
                      "& .css-204u17-MuiDataGrid-main": {
                        paddingLeft: "15px",
                      },
                      "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                        paddingLeft: "15px",
                      },
                    }}
                    disableSelectionOnClick={true}
                    columns={[
                      { field: "#", headerName: "#", flex: 0.3 },
                      {
                        field: "vendorName",
                        headerName: "Vendor Name",
                        flex: 1,
                      },
                      {
                        field: "region",
                        headerName: "Region",
                        flex: 1,
                      },
                      {
                        field: "callCount",
                        headerName: "Call Count",
                        flex: 1,
                      },
                    ]}
                    rows={vendorDataClick.map((x, index) => {
                      return {
                        id: index,
                        "#": index + 1,
                        vendorName: x.vendorName,
                        region: x.region,
                        callCount: x.callCount,
                      };
                    })}
                    components={{
                      Toolbar: CustomExport,
                    }}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default TopTenVendors;
