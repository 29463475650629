import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  LinearProgress,
  Typography,
  Stack,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Autocomplete,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import {
  deletePromotedItems,
  getAllPromotedItems,
  getById,
  openDialog,
  resetForm,
  savePageNo,
  saveSearchBody,
} from "src/actions/promotedItems";
import SearchIcon from "@mui/icons-material/Search";
import { getAllVendors } from "src/actions/vendors";
import Close from "@mui/icons-material/Close";
import { CustomDialog } from "src/components/CustomDialog";
import useCanShow from "src/components/CanShow";

export default function PromotedItemsTable() {
  const dispatch = useDispatch();

  const updateRole = useCanShow("36-05");
  const deleteRole = useCanShow("36-06");

  const _PromotedItems = useSelector(
    (state) => state.promotedItems.promotedItemsData
  );
  const _PromotedItem = useSelector((state) => state.promotedItems);
  const searchFilter = useSelector((state) => state.promotedItems.searchBody);
  const reload = useSelector((state) => state.promotedItems.reload);
  const PageNo = useSelector((state) => state.promotedItems.pageNo);
  const vendors = useSelector((state) => state.vendors.dataList);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(PageNo ? PageNo : 0);
  const [searchBody, setSearchBody] = useState({
    price: "",
    name: "",
    vendorId: null,
  });

  useEffect(() => {
    dispatch(getAllVendors());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllPromotedItems(page + 1, searchFilter));
  }, [searchFilter, page, dispatch]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    dispatch(savePageNo(newPage));
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(saveSearchBody({ ...searchFilter, pageSize: newPageSize }));
  };

  function deleteSelectedItem() {
    setDeleteLoading(true);
    dispatch(
      deletePromotedItems(_PromotedItem.form.id, page + 1, searchFilter)
    ).then((res) => {
      setDeleteLoading(false);
      dispatch(resetForm());
      setOpenDeleteDialog(false);
    });
  }

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.name}>
          <a
            rel="noreferrer"
            href={
              params.row.url.includes("shein")
                ? params.row.url.replace(/(https:\/\/\s*)m/g, "$1ar")
                : params.row.url
            }
            target="_blank"
          >
            <Typography variant="body" noWrap sx={{ textAlign: "start" }}>
              {params.row.name}
            </Typography>
          </a>
        </Tooltip>
      ),
    },
    {
      field: "price",
      headerName: "Price ($)",
      flex: 1,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Box component="img" src={params.row.vendor?.logoUrl} width={90} />
          <Typography variant="body">{params.row.vendor?.name}</Typography>
        </Stack>
      ),
    },
    {
      field: "imageUrl",
      headerName: "Image Url",
      flex: 1,
      renderCell: (params) => (
        <Box component="img" src={params.row.imageUrl} width={90} />
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {moment(params.value).format("yyyy-MM-DD / hh:mma")}
        </Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          hidden={updateRole ? false : true}
          showInMenu
          onClick={(e) => {
            dispatch(getById(params.row.id)).then((x) => {
              dispatch(openDialog());
            });
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          hidden={deleteRole ? false : true}
          showInMenu
          onClick={(e) => {
            dispatch(getById(params.row.id)).then((res) => {
              setOpenDeleteDialog(true);
            });
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
              dispatch(resetForm());
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedItem()}
            selectedObject={_PromotedItem.form.name}
            loading={deleteLoading}
          ></CustomDialog>
        )}
      </Box>
      <PerfectScrollbar>
        <Grid container spacing={2} sx={{ padding: 3 }}>
          <Grid item xs={3}>
            <TextField
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder="Name"
              variant="outlined"
              value={searchBody.name}
              onChange={(e) => {
                setSearchBody({
                  ...searchBody,
                  name: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBody({
                      ...searchFilter,
                      name: searchBody.name,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              fullWidth
              placeholder="Price"
              variant="outlined"
              value={searchBody.price}
              onChange={(e) => {
                setSearchBody({
                  ...searchBody,
                  price: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBody({
                      ...searchFilter,
                      price: searchBody.price,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              options={vendors || []}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Vendors" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveSearchBody({
                    ...searchFilter,
                    vendorId: value ? value.id : null,
                  })
                );
                setSearchBody({
                  ...searchBody,
                  vendorId: value ? value.id : null,
                });
              }}
              value={
                vendors?.filter((x) => x.id === searchFilter.vendorId)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={3} lg={3}>
            <Stack direction="row" sx={{ justifyContent: "start" }}>
              <IconButton
                disabled={reload}
                onClick={() => {
                  dispatch(saveSearchBody({ ...searchBody }));
                  setPage(0);
                }}
              >
                <Tooltip title="Search with filter">
                  <SearchIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                disabled={reload}
                onClick={() => {
                  setPage(0);
                  dispatch(
                    saveSearchBody({
                      pageSize: 15,
                      price: "",
                      name: "",
                      vendorId: null,
                    })
                  );
                  setSearchBody({
                    pageSize: 15,
                    price: "",
                    name: "",
                    vendorId: null,
                  });
                }}
              >
                <Tooltip title="Clear filter">
                  <Close />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ minWidth: 1050 }}>
              <Box sx={{ height: { md: "calc(100vh - 290px)", xs: 1000 } }}>
                <DataGrid
                  sx={{
                    "& .css-204u17-MuiDataGrid-main": {
                      paddingLeft: "15px",
                    },
                    "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                      paddingLeft: "15px",
                    },
                  }}
                  loading={reload}
                  rows={_PromotedItems.data}
                  columns={columns}
                  rowHeight={100}
                  disableSelectionOnClick
                  pagination
                  pageSize={searchFilter.pageSize}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    handleLimitChange(newPageSize)
                  }
                  rowCount={_PromotedItems.rowCount}
                  paginationMode="server"
                  rowsPerPageOptions={[15, 25, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Card>
  );
}
