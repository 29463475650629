import * as React from "react";
import { Card, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import Details from "./details";
import OrderDetailsTable from "./table";
import { getOrderById } from "src/actions/gifts/orders";

export default function OrderDetails() {
  const orderId = window.location.pathname.replace("/app/gifts/order/", "");

  const dispatch = useDispatch();

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    dispatch(getOrderById(orderId));
  }, [dispatch, orderId, refresh]);

  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Details refresh={() => setRefresh(!refresh)} />
        </Grid>
        <Grid item xs={12}>
          <OrderDetailsTable refresh={() => setRefresh(!refresh)} />
        </Grid>
      </Grid>
    </Card>
  );
}
