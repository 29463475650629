import {
  ADDITIONAL_FEES_RESET_FORM,
  ADDITIONAL_FEES_OPEN_DIALOG,
  ADDITIONAL_FEES_OPEN_FORM_DIALOG,
} from "../actions/type";

const initialState = {
  type: "",
  reload: false,
  openDialog: false,
  openFormDialog: false,
  pageNo: 0,
  pageSize: 10,
  orderData: {},
  searchBody: {
    pageSize: 15,
    price: "",
    name: "",
    vendorId: null,
  },
  form: {
    id: null,
    orderId: null,
    amount: "",
    notes: "",
  },
};

function additionalFeesReducer(additionalFees = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADDITIONAL_FEES_RESET_FORM:
      additionalFees.form.id = null;
      additionalFees.form.orderId = null;
      additionalFees.form.amount = "";
      additionalFees.form.notes = "";
      return additionalFees;

    case ADDITIONAL_FEES_OPEN_DIALOG:
      return {
        ...additionalFees,
        openDialog: !additionalFees.openDialog,
        type: payload,
      };

    case ADDITIONAL_FEES_OPEN_FORM_DIALOG:
      return {
        ...additionalFees,
        openFormDialog: !additionalFees.openFormDialog,
      };

    default:
      return additionalFees;
  }
}

export default additionalFeesReducer;
