import axios from "../axios";

class CategoryBrandsService {
  getGiftCollectionAll(categoryId) {
    return axios.get(`giftitemcategorybrands/${categoryId}`);
  }

  createCategoryBrands(data) {
    return axios.put("/giftitemcategorybrands", data);
  }
}

export default new CategoryBrandsService();
