import {
  GET_ALL_USERS,
  SAVE_SEARCH_BODY_USERS,
  SAVE_USERS_PAGE_NUM,
  OPEN_USERS_DEALOG,
  RESET_FORM_USERS,
  GET_BY_ID_USERS,
  USER_INFO,
  SIDEBAR_DASHBOARD,
  USER_STATUS,
  RELOAD_USER_INFO,
  RELOAD_USER_GET_BY_TOKEN,
  OPEN_NOTIFY_USERS_DIALOG,
  FILTERS_TYPE,
  OPEN_PHONE_CODE_DIALOG,
  OPEN_PHONE_CODE_DATA,
  LOADING_USERS,
  OPEN_DEVICE_INFO_DIALOG,
} from "./type";

import UserDataService from "../api/services/user.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const createUser = (data) => async (dispatch) => {
  try {
    const res = await UserDataService.create(data);
    dispatch(showSuccessSnackbar("Added successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const reloadUsers = () => (dispatch) => {
  dispatch({
    type: RELOAD_USER_INFO,
  });
};

export const loadingUsers = () => (dispatch) => {
  dispatch({
    type: LOADING_USERS,
  });
};

export const reloadUserInfo = () => (dispatch) => {
  dispatch({
    type: RELOAD_USER_GET_BY_TOKEN,
  });
};

export const openUsersDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_USERS_DEALOG,
    payload: data,
  });
};

export const openNotifyUserDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_NOTIFY_USERS_DIALOG,
    payload: data,
  });
};

export const openUsersStatusDialog = () => (dispatch) => {
  dispatch({
    type: USER_STATUS,
  });
};

export const openDeviceInfoDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_DEVICE_INFO_DIALOG,
    payload: data,
  });
};

export const sidebarDashboard = (data) => (dispatch) => {
  dispatch({
    type: SIDEBAR_DASHBOARD,
    payload: data,
  });
};

export const changeFiltersType = (data) => (dispatch) => {
  dispatch({
    type: FILTERS_TYPE,
    payload: data,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: RESET_FORM_USERS,
  });
};

export const getAllUsers = (pageNumber, pageSize, data) => async (dispatch) => {
  try {
    dispatch(loadingUsers());
    const res = await UserDataService.getAllFiltered(
      pageNumber,
      pageSize,
      data
    );
    dispatch({
      type: GET_ALL_USERS,
      payload: res.data.data,
    });
    dispatch(loadingUsers());
    return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(loadingUsers());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getUserPhoneCodeHistory = (pageNumber) => async (dispatch) => {
  try {
    dispatch(loadingUsers());
    const res = await UserDataService.getPhoneCodeHistory(pageNumber);
    dispatch({
      type: OPEN_PHONE_CODE_DATA,
      payload: res.data.data,
    });
    dispatch(loadingUsers());
    return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(loadingUsers());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const userPhoneCodeHistoryDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_PHONE_CODE_DIALOG,
  });
};

export const getFilteredOnly =
  (pageNumber, pageSize, data) => async (dispatch) => {
    try {
      const res = await UserDataService.getFilteredOnly(
        pageNumber,
        pageSize,
        data
      );
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data.data,
      });
      return Promise.resolve(res.data.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getById = (id) => async (dispatch) => {
  try {
    const res = await UserDataService.getById(id);
    dispatch({
      type: GET_BY_ID_USERS,
      payload: res.data.data,
    });
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const userInfo = () => async (dispatch) => {
  try {
    dispatch(reloadUserInfo());
    const res = await UserDataService.getByToken();
    dispatch({
      type: USER_INFO,
      payload: res.data.data,
    });
    dispatch(reloadUserInfo());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(reloadUserInfo());
    // if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const loginHistory = (id) => async (dispatch) => {
  try {
    const res = await UserDataService.loginHistory(id);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateUser = (data) => async (dispatch) => {
  try {
    const res = await UserDataService.update(data);
    dispatch(showSuccessSnackbar("Update User successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const resetUserPassword = (data) => async (dispatch) => {
  try {
    const res = await UserDataService.resetPassword(data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const changeStatus =
  (id, status, userDeactivationReasonId) => async (dispatch) => {
    try {
      const res = await UserDataService.changeStatus(
        id,
        status,
        userDeactivationReasonId
      );
      dispatch(showSuccessSnackbar("Update user status successfully"));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const changePassword = () => async (dispatch) => {
  try {
    const res = await UserDataService.changePassword();

    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const saveUsersSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_BODY_USERS,
    payload: data,
  });
};

export const saveUsersPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_USERS_PAGE_NUM,
    payload: data,
  });
};

export const getByPhoneNumber = (phoneNumber) => async (dispatch) => {
  try {
    const res = await UserDataService.getByPhoneNumber(phoneNumber);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const changeServiceType = (data) => async (dispatch) => {
  try {
    const res = await UserDataService.changeServiceType(data);
    // dispatch(showSuccessSnackbar("Updated successfully!"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const modifyCustomerTier = (id, data) => async (dispatch) => {
  try {
    const res = await UserDataService.modifyCustomerTier(id, data);
    dispatch(showSuccessSnackbar("Updated successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
