import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardHeader,
  Divider,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import { cac } from "src/actions/reports";
import CustomSpinner from "src/components/CustomSpinner";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

export default function Cac(props) {
  const dispatch = new useDispatch();
  const [promoId, setPromoID] = useState("");
  const [cacData, setCacData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(cac(promoId)).then((res) => {
      setCacData(res.data);
      setLoading(false);
    });
  }, [props.date, props.setSearch]);

  const dataGrid = cacData.filter((x) => x.promoCodeName !== null)


  function CustomToolbar() {
    return (
      <div>
        <Box>
          <Box>
            <Card>
              <CardHeader title="Customer Acquisition Cost Report" />
              <Divider />
            </Card>
          </Box>
          <Box>
            <GridToolbarContainer>
              <GridToolbarExport
                csvOptions={{
                  fileName: "Customer Acquisition Cost Report || Orderii",
                  utf8WithBom: true,
                }}
              />
            </GridToolbarContainer>
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <Card style={{ marginTop: 10 }} ref={props.print}>
      <PerfectScrollbar>
        <Box>
          {loading ? (
            <CustomSpinner />
          ) : (
            <Box sx={{ height: 'calc(100vh - 320px)' }}>
              <DataGrid
                sx={{
                  "& .MuiDataGrid-row ": {
                    textAlign: "center",
                  },
                  "& .css-204u17-MuiDataGrid-main": {
                    paddingLeft: "15px",
                  },
                  "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                    paddingLeft: "15px",
                  },
                }}
                disableSelectionOnClick={true}
                columns={[
                  { field: "#", headerName: "#" },
                  {
                    field: "promoCodeName",
                    headerName: "Promo Code Name",
                    flex: 1
                  },
                  {
                    field: "cac",
                    headerName: "Customer Acquistion Cost ($)",
                    flex: 1
                  },
                ]}
                rows={dataGrid
                  .filter((x) => x.promoCodeName != null)
                  .map((x, index) => {
                    return {
                      id: index,
                      "#": index + 1,
                      promoCodeName: x.promoCodeName,
                      cac: x.cac,
                    };
                  })}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
