import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  TextField,
  InputAdornment,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { CustomDialog } from "src/components/CustomDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import host from "src/api/host";
import {
  deleteItemOptions,
  getAllItemOptions,
  getItemOptionsById,
  resetItemOptionsForm,
  saveItemOptionsPageNum,
  saveItemOptionsSearchBody,
} from "src/actions/gifts/itemOptions";
import { AiOutlineSearch } from "react-icons/ai";
import CloseIcon from "@mui/icons-material/Close";
import useCanShow from "src/components/CanShow";

export default function ItemOptionsTable() {
  const dispatch = useDispatch();

  const editItemOptionsRole = useCanShow("43-05");
  const deleteItemOptionsRole = useCanShow("43-06");

  const _ItemOptions = useSelector(
    (state) => state.itemOptions.itemOptionsData
  );
  const loading = useSelector((state) => state.itemOptions.reload);
  const pageNo = useSelector((state) => state.itemOptions.pageNo);

  const [page, setPage] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openOrderReasonDialog, setOpenOrderReasonDialog] = useState(false);
  const searchBody = useSelector((state) => state.itemOptions.searchBody);
  const [searchName, setSearchName] = useState(searchBody.name);

  useEffect(() => {
    dispatch(saveItemOptionsPageNum(page + 1));
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(getAllItemOptions(page + 1, searchBody.pageSize, searchBody.name));
  }, [dispatch, page, searchBody.name, searchBody.pageSize]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  function deleteSelectedPromo(id) {
    setOpenDeleteDialog(false);
    dispatch(
      deleteItemOptions(id, {
        pageNo: pageNo,
        pageSize: searchBody.pageSize,
        name: searchBody.name,
      })
    ).then((x) => {
      dispatch(resetItemOptionsForm());
    });
  }

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "nameEn",
      headerName: "Name English",
      flex: 1,
    },
    {
      field: "nameAr",
      headerName: "Name Arabic",
      flex: 1,
    },
    {
      field: "nameKu",
      headerName: "Name Kurdish",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          hidden={!editItemOptionsRole}
          showInMenu
          onClick={(e) => {
            dispatch(getItemOptionsById(params.row.id, host + params.row.url));
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          hidden={!deleteItemOptionsRole}
          showInMenu
          onClick={(e) => {
            setOpenDeleteDialog(true);
            setOpenOrderReasonDialog(params.row);
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedPromo(openOrderReasonDialog.id)}
            selectedObject={openOrderReasonDialog.title}
            maxWidth="sm"
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveItemOptionsSearchBody({
                      ...searchBody,
                      name: searchName,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  setSearchName("");
                  dispatch(
                    saveItemOptionsSearchBody({
                      ...searchBody,
                      name: "",
                      pageSize: 15,
                    })
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box
                sx={{
                  height: { md: "calc(100vh - 220px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  columns={columns}
                  rows={
                    _ItemOptions?.data.map((data, index) => {
                      return { ...data, index: index + 1 };
                    }) || []
                  }
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  rowCount={_ItemOptions?.rowCount}
                  pageSize={10}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  rowsPerPageOptions={[10]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
