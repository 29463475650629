import { useSelector } from "react-redux";

const HideFromFedExAccount = ({ children }) => {
  const userRoles = useSelector((state) => state.users.userInfo);

  const FedExAccountUserIds = [
    "7d059cd8-f0fb-45db-ae1b-832e80a047da",
    "f441e0d3-abf1-4a67-a57e-4b5f5d4f1106",
    // "df685af3-37e9-46d4-83f9-922cd8d61c9c",
  ];

  return FedExAccountUserIds.includes(userRoles.id) ? null : children;
};

export default HideFromFedExAccount;
