import axios from "../axios";

class WalletService {
  deposit(data) {
    return axios.post(`/wallet/deposit`, data);
  }

  depositByAdmin(data) {
    return axios.post(`/wallet/depositbyadmin`, data);
  }

  depositWithCash(data) {
    return axios.post(`/wallet/depositWithCash`, data);
  }

  withDrawWithCash(data) {
    return axios.post(`/wallet/withDrawWithCash`, data);
  }

  withDrawByAdmin(data) {
    return axios.post(`/wallet/withdrawbyadmin`, data);
  }

  getById(id) {
    return axios.get(`/wallet/getById/${id}`);
  }

  update(data) {
    return axios.put(`/wallet/UpdateWallet`, data);
  }

  getOrderiiBalace(data) {
    return axios.get(`/wallet/orderiibalance`, data);
  }

  getBankandWalletTransactions(data) {
    let start = data.start ? '&start=' + data.start : ''
    let end = data.end ? '&end=' + data.end : ''
    let walletType = data.walletType ? '?walletType=' + data.walletType : ''
    let transactionReason = data.transactionReason ? '&transactionReason=' + data.transactionReason : ''
    let ePaymentType = data.ePaymentType ? '&ePaymentType=' + data.ePaymentType : ''
    return axios.get(`/wallet/getbankandwallettransactions${walletType}${start}${end}${transactionReason}${ePaymentType}`);
  }

}

export default new WalletService()