import {
  GET_ALL_DEPARTMENT, UPDATE_DEPARTMENT, DELETE_DEPARTMENT, CREATE_DEPARTMENT, OPEN_DEPARTMENT_DIALOG, GET_DEPARTMENT_BYID
} from "../../actions/type";

const initialState = {
  data: [],
  getByID: {
    id: null,
    name: ''
  },
  dialog: false,
  reload: false
}

function department(department = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case OPEN_DEPARTMENT_DIALOG:
      return {
        ...department,
        dialog: !payload
      }

    case GET_ALL_DEPARTMENT:
      return {
        ...department,
        data: payload
      }

    case GET_DEPARTMENT_BYID:
      return {
        ...department,
        getByID: payload
      }

    case UPDATE_DEPARTMENT:
      department.data.filter(x => x.name == payload)
      return department;

    case DELETE_DEPARTMENT:
      department.data = department.data.filter((x) => x.id !== payload);
      return department;


    default:
      return department;
  }
}

export default department;