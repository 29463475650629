import axiosService from "src/api/axiosService";

class DealsService {
  create(data) {
    return axiosService.post(`/api/deals`, data);
  }

  getById(id) {
    return axiosService.get(`/api/deals/getbyid/${id}`);
  }

  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axiosService.get(`api/deals/${pageNo}?${params}`);
  }

  update(id, data) {
    return axiosService.put(`/api/deals/${id}`, data);
  }

  delete(id) {
    return axiosService.delete(`/api/deals/${id}`);
  }
}

export default new DealsService();
