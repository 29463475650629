import {
  PURCHASING_BILL_RESET_FORM,
  PURCHASING_BILL_GET_BY_ID,
  PURCHASING_BILL_PAGE_NUMBER,
  PURCHASING_BILL_OPEN_DIALOG,
  PURCHASING_BILL_RELOAD,
  PURCHASING_BILL_GET_ALL,
  PURCHASING_BILL_SEARCH_BODY,
  PURCHASING_BILL_EXPORT_TO_CSV,
} from "./type";

import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import PurchasingBillService from "src/api/services/purchasingBills.service";

export const openDialog = (data) => (dispatch) => {
  dispatch({
    type: PURCHASING_BILL_OPEN_DIALOG,
    payload: data,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: PURCHASING_BILL_RESET_FORM,
  });
};

export const reloadPurchasingBill = () => (dispatch) => {
  dispatch({
    type: PURCHASING_BILL_RELOAD,
  });
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: PURCHASING_BILL_SEARCH_BODY,
    payload: data,
  });
};

export const savePageNo = (data) => (dispatch) => {
  dispatch({
    type: PURCHASING_BILL_PAGE_NUMBER,
    payload: data,
  });
};

export const getAllPurchasingBill =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadPurchasingBill());
      const res = await PurchasingBillService.getAll(pageNo, searchBody);
      dispatch({
        type: PURCHASING_BILL_GET_ALL,
        payload: res.data.data,
      });
      dispatch(reloadPurchasingBill());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadPurchasingBill());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const createPurchasingBill =
  (data, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await PurchasingBillService.create(data);
      dispatch(showSuccessSnackbar("Created successfully!"));
      dispatch(getAllPurchasingBill(pageNo, searchBody));
      dispatch(openDialog());
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const updatePurchasingBill =
  (id, data, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await PurchasingBillService.update(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllPurchasingBill(pageNo, searchBody));
      dispatch(openDialog());
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const updatePurchasingBillStatus = (id, status) => async (dispatch) => {
  try {
    const res = await PurchasingBillService.changeStatus(id, status);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deletePurchasingBill =
  (id, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await PurchasingBillService.delete(id);
      dispatch(showSuccessSnackbar("Deleted successfully!"));
      dispatch(getAllPurchasingBill(pageNo, searchBody));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadPurchasingBill());
    const res = await PurchasingBillService.getById(id);
    dispatch({
      type: PURCHASING_BILL_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadPurchasingBill());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(reloadPurchasingBill());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const exportToCsv = (searchBody) => async (dispatch) => {
  try {
    dispatch(reloadPurchasingBill());
    const res = await PurchasingBillService.exportToCsv(searchBody);
    dispatch({
      type: PURCHASING_BILL_EXPORT_TO_CSV,
      payload: res.data.data,
    });
    dispatch(reloadPurchasingBill());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(reloadPurchasingBill());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
