import moment from "moment";
import {
  GET_ALL_SHIPPING_CONFIGS,
  UPDATE_SHIPPING_CONFIGS,
  SAVE_USERS_PAGE_SC,
  SAVE_SEARCH_BODY_SC,
  OPEN_SHIPPING_CONFIGS_DIALOG,
  GET_BY_ID_SHIPPING_CONFIGS,
  RESET_FORM_SHIPPING_CONFIGS,
  SHIPPINGS_CONFIGS_RELOAD
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageCount: 0,
  rowCount: 0,
  pageSize: 10,
  searchSCBody: null,
  saveSCPageNum: 0,
  form: {
    id: null,
    type: null,
    notes: "",
    value: "",
    isPercentage: false,
  },
  // data: {
  //   generalConfiguration: [],
  //   agConfiguration: [],
  //   euConfiguration: [],
  //   trConfiguration: [],
  //   usConfiguration: [],
  //   usConfiguration: [],
  // },
  data: {},
  getById: {},
};


function shippingConfigReducer(shippingConfig = initialState, action) {
  const { type, payload } = action;

  switch (type) {

    case GET_ALL_SHIPPING_CONFIGS:
      var _ShippingConfig = {
        data: payload,
        saveSCPageNum: shippingConfig.saveSCPageNum,
        form: shippingConfig.form,
        openDialog: false,
        reload: shippingConfig.reload,
        getById: shippingConfig.getById,
      }
      return _ShippingConfig;

    case GET_BY_ID_SHIPPING_CONFIGS:
      shippingConfig.form.id = payload.id
      shippingConfig.form.type = payload.type
      shippingConfig.form.notes = payload.notes
      shippingConfig.form.value = payload.value
      shippingConfig.form.isPercentage = payload.isPercentage
      return shippingConfig;

    case RESET_FORM_SHIPPING_CONFIGS:
      shippingConfig.form.id = null
      shippingConfig.form.type = null
      shippingConfig.form.notes = ''
      shippingConfig.form.value = ''
      shippingConfig.form.isPercentage = false
      return shippingConfig

    case SHIPPINGS_CONFIGS_RELOAD:
      return { ...shippingConfig, reload: !shippingConfig.reload };

    case SAVE_SEARCH_BODY_SC:
      return { ...shippingConfig, searchSCBody: payload };

    case SAVE_USERS_PAGE_SC:
      shippingConfig.saveSCPageNum = payload;
      return shippingConfig;

    case OPEN_SHIPPING_CONFIGS_DIALOG:
      return { ...shippingConfig, openDialog: !payload };

    // case UPDATE_SHIPPING_CONFIGS:
    //   var indexToChange = shippingConfig.data.indexOf(
    //     shippingConfig.data.filter((x) => x.id === payload.id)[0]
    //   );
    //   shippingConfig.data.splice(indexToChange, 1, payload);
    //   return shippingConfig;


    default:
      return shippingConfig;
  }
}

export default shippingConfigReducer;
