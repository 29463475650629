import {
  Grid,
  Stack,
  Divider,
  Chip,
  Box,
  Typography,
  useTheme,
  InputAdornment,
  Button,
  LinearProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import {
  getGiftItemVariants,
  openProductInfoDialog,
  printSku,
  updateLockerNumber,
} from "src/actions/gifts/giftItem";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import RolePermission from "src/components/RolePermission";
import CustomText from "src/components/CustomText";
import { GiLockers } from "react-icons/gi";
import PrintSKUForm from "src/pages/gifts/item/from/printDialog";

const ProductInfo = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const giftItemDialog = useSelector(
    (state) => state.giftItems.openProductInfoDialog
  );
  const giftItem = useSelector((state) => state.giftItems.getProductInfo);
  const searchBody = useSelector((state) => state.giftItems.searchBody);
  const pageNo = useSelector((state) => state.giftItems.saveGiftItemsPageNum);
  const _PrintSku = useSelector((state) => state.giftItems.printSkuForm);

  const [showPanZoom, setShowPanZoom] = useState("");
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [lockerNumber, setLockerNumber] = useState(giftItem.lockerNumber ?? "");

  const [loadingSku, setLoadingSku] = useState(false);

  useEffect(() => setLockerNumber(giftItem.lockerNumber ?? ""), [giftItem]);

  function handleLockerNumber() {
    dispatch(
      updateLockerNumber(giftItem.id, lockerNumber, pageNo, searchBody)
    ).then(() => {
      handleDialog();
    });
  }

  const handleDialogClose = () => {
    setOpenImageDialog(false);
  };

  const handleOpenPanZoom = (url) => {
    setOpenImageDialog(true);
    setShowPanZoom(url);
  };

  const handleDialog = () => {
    dispatch(openProductInfoDialog());
  };
  return (
    <CustomDialog
      title={"Product Information"}
      isOpen={giftItemDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
    >
      {loadingSku ? <LinearProgress /> : null}
      <CustomDialog
        isOpen={openImageDialog}
        handleClose={() => handleDialogClose()}
        title="Images"
        maxWidth="lg"
      >
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              width: "50%",
              height: "auto",
            }}
          >
            <ReactPanZoom image={showPanZoom} alt="Image alt text" />
          </div>
        </div>
      </CustomDialog>
      {_PrintSku.dialog ? <PrintSKUForm /> : null}
      <Grid container spacing={2}>
        <Grid item xs={6} display="flex" justifyContent="center">
          <Carousel
            swipeable
            emulateTouch
            infiniteLoop
            width={500}
            renderThumbs={(children) =>
              children.map((child, index) => (
                <img
                  key={index}
                  src={child.props.src}
                  alt=""
                  style={{
                    height: "50px",
                    width: "auto",
                    objectFit: "contain",
                  }}
                />
              ))
            }
          >
            {giftItem.giftItemAttachments?.map((attachment, index) => (
              <Box key={index} component="img" src={attachment.url} alt="" />
            ))}
          </Carousel>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5" color="secondary">
                  {giftItem.giftItemCategories?.map(
                    (x, i) =>
                      `${x?.nameEn?.toUpperCase()}${
                        i < giftItem.giftItemCategories.length - 1 ? " - " : ""
                      }`
                  )}
                </Typography>
                <Typography variant="h5">
                  {moment(giftItem.createdAt).format("YYYY-MM-DD / hh:mm a")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" color="primary">
                {giftItem.nameEn}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                align="justify"
                style={{ color: "#333333" }}
              >
                {giftItem.descriptionEn}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <Typography variant="h4">SKU:</Typography>
                {giftItem?.sku ? (
                  <Typography variant="h4">{giftItem?.sku}</Typography>
                ) : (
                  <Typography sx={{ color: "#ffb74d" }} variant="h4">
                    THIS PRODUCT HAVE MULTI SKU
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                height={40}
              >
                <Typography variant="h4">Brand:</Typography>
                <Typography variant="h4">
                  {giftItem.giftBrand?.nameEn}
                </Typography>
                <Box
                  onClick={() =>
                    handleOpenPanZoom(giftItem.giftBrand?.attachment?.url)
                  }
                  component="img"
                  src={giftItem.giftBrand?.attachment?.url}
                  alt=""
                  style={{
                    cursor: "pointer",
                    height: "100%",
                    width: "auto",
                    padding: "3px",
                    objectFit: "contain",
                    border: "2px solid",
                    borderRadius: "10px",
                    marginLeft: "20px",
                    borderColor: theme.palette.primary.main,
                  }}
                />
                {giftItem.giftBrand.isActive ? null : (
                  <span style={{ color: "red" }}> (inActive)</span>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <Typography
                  variant="h4"
                  sx={
                    {
                      // fontWeight: "bold",
                      // color: giftItem.availableTime?.isAvailable
                      //   ? null
                      //   : "#d32f2f",
                    }
                  }
                >
                  Supplier: {giftItem.giftSupplier.nameEn.toUpperCase()}{" "}
                  {/* {giftItem.availableTime?.isAvailable &&
                  giftItem.availableTime?.startTime &&
                  giftItem.availableTime?.endTime
                    ? null
                    : !giftItem.availableTime?.isAvailable &&
                      !giftItem.availableTime?.startTime &&
                      !giftItem.availableTime?.endTime
                    ? " (Closed All Day)"
                    : " (Closed Time)"}{" "} */}
                </Typography>
                {/* {!giftItem.availableTime?.isAvailable &&
                giftItem.availableTime?.startTime &&
                giftItem.availableTime?.endTime ? (
                  <Tooltip
                    title={`${giftItem.availableTime?.startTime} - ${giftItem.availableTime?.endTime}`}
                  >
                    <span>
                      <RiInformationLine size={25} />
                    </span>
                  </Tooltip>
                ) : null} */}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">
                Quantity:{" "}
                {giftItem.totalQuantity <= 0 ? (
                  <span
                    style={{
                      color: giftItem.totalQuantity <= 0 ? "red" : null,
                    }}
                  >
                    Out Of Stock
                  </span>
                ) : (
                  giftItem.totalQuantity
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", color: "#00A5FF" }}
              >
                Cost Price: {giftItem.costPrice?.toLocaleString()} IQD
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                color={"success"}
                sx={{ fontWeight: "bold", color: "green" }}
              >
                Sell Price: {giftItem.sellPrice?.toLocaleString()} IQD
              </Typography>
            </Grid>
            {giftItem.discountPrice ? (
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  color={"error"}
                  sx={{ fontWeight: "bold" }}
                >
                  Price After Discount:
                  {giftItem.discountPrice?.toLocaleString()} IQD
                </Typography>
              </Grid>
            ) : null}
            {giftItem.giftItemOptions?.length > 0 ? (
              <Grid item xs={12}>
                <Divider>
                  <Chip label="Options" />
                </Divider>
              </Grid>
            ) : null}
            {giftItem.giftItemOptions?.length > 0 ? (
              <Grid item xs={12}>
                {Object.keys(giftItem.groupedOptions).map((key) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    key={key}
                  >
                    <Typography variant="h4">
                      {giftItem.groupedOptions[key][0].options.nameEn}:
                    </Typography>
                    {giftItem.groupedOptions[key].map((option) => (
                      <Stack
                        key={option.id}
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        style={{
                          border: "2px solid orange",
                          borderRadius: "10px",
                          padding: "5px",
                          margin: "5px",
                        }}
                      >
                        <Typography variant="h5" component="div">
                          {option.nameEn}
                        </Typography>
                        {option.attachments?.length > 0 && (
                          <Box
                            onClick={() =>
                              handleOpenPanZoom(option.attachments[0]?.url)
                            }
                            component="img"
                            src={option.attachments[0]?.url}
                            alt={option.nameEn}
                            width={100}
                            height={100}
                            style={{ objectFit: "contain", cursor: "pointer" }}
                          />
                        )}
                      </Stack>
                    ))}
                    <Divider sx={{ my: 2 }} />
                  </Stack>
                ))}
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <RolePermission permission="41-13">
                <CustomText
                  fullWidth
                  label="Locker"
                  name="lockerNumber"
                  value={lockerNumber}
                  onChange={(e) => {
                    setLockerNumber(e.target.value);
                  }}
                  onKeyDown={(e) =>
                    e.key === "Enter" ? handleLockerNumber() : null
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ marginY: 3 }}>
                        <GiLockers size={25} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" sx={{ marginY: 2 }}>
                        <Button
                          variant="contained"
                          onClick={handleLockerNumber}
                        >
                          Update
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </RolePermission>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={(e) => {
                  if (giftItem.sku) {
                    dispatch(printSku([giftItem]));
                  } else {
                    setLoadingSku(true);
                    dispatch(getGiftItemVariants(giftItem.id)).then((res) => {
                      setLoadingSku(false);
                      dispatch(printSku(res));
                    });
                  }
                }}
              >
                Print Product SKU
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};
export default ProductInfo;
