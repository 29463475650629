import React, { useState, useEffect } from "react";
import { Box, Card, CardHeader, Divider } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import { getUnsedPromo } from "src/actions/reports";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";

export default function UnusedPromo(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [unsedPromo, setUnsedPromo] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getUnsedPromo("")).then((res) => {
      setLoading(false);
      setUnsedPromo(res?.data);
    });
  }, [dispatch, props.setSearch]);

  function CustomToolbar() {
    return (
      <div>
        <Box>
          <Box>
            <Card>
              <CardHeader title="Unsed Promo Codes Report" />
              <Divider />
            </Card>
          </Box>
          <Box>
            <GridToolbarContainer>
              <GridToolbarExport
                csvOptions={{
                  fileName: "Unsed Promo Codes Report || Orderii",
                  utf8WithBom: true,
                }}
              />
            </GridToolbarContainer>
          </Box>
        </Box>
      </div>
    );
  }
  return (
    <Card style={{ marginTop: 10 }} ref={props.print}>
      <PerfectScrollbar>
        <Box sx={{ height: "calc(100vh - 320px)" }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-row ": {
                textAlign: "center",
              },
              "& .css-204u17-MuiDataGrid-main": {
                paddingLeft: "15px",
              },
              "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                paddingLeft: "15px",
              },
            }}
            columns={[
              { field: "#" },
              { field: "Promo Count", width: 200 },
              { field: "Promo Name", width: 200 },
            ]}
            rows={unsedPromo
              .filter((x) => x.promoName !== null)
              .map((data, index) => {
                return {
                  id: index + 1,
                  "#": index + 1,
                  "Promo Count": data.promoCount,
                  "Promo Name": data.promoName,
                };
              })}
            loading={loading}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
