import axios from "../axios";

class OrderAdditionalFeesService {
  create(data) {
    return axios.post("/orderadditionalfees", data);
  }

  update(id, data) {
    return axios.put(`/orderadditionalfees/${id}`, data);
  }

  delete(id) {
    return axios.delete(`/orderadditionalfees/${id}`);
  }
}

export default new OrderAdditionalFeesService();
