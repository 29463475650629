import {
  CREATE_EXPENSE,
  UPDATE_EXPENSE,
  GET_ALL_EXPENSES,
  DELETE_EXPENSE,
} from "./type";

import ExpenseDataService from "../api/services/expense.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const createExpense = (data) => async (dispatch) => {
  try {
    const res = await ExpenseDataService.create(data);
    // dispatch({
    //   type: CREATE_EXPENSE,
    //   payload: res.data.data,
    // });
    dispatch(showSuccessSnackbar("Created successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
    if (data.id == null)
      dispatch(showErrorSnackbar("please fill the required information"));
  }
};

export const getAllExpenses = (pageNo) => async (dispatch) => {
  try {
    const res = await ExpenseDataService.getAll(pageNo);
    dispatch({
      type: GET_ALL_EXPENSES,
      payload: res.data.data,
    });
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateExpense = (data) => async (dispatch) => {
  try {
    const res = await ExpenseDataService.update(data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    dispatch({
      type: UPDATE_EXPENSE,
      payload: data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteExpense = (id) => async (dispatch) => {
  try {
    await ExpenseDataService.delete(id);
    dispatch(showSuccessSnackbar("Expense deleted successfully"));
    dispatch({
      type: DELETE_EXPENSE,
      payload: { id },
    });
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getById = (id) => async (dispatch) => {
  try {
    const res = await ExpenseDataService.getById(id);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
