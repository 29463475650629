import moment from "moment";
import {
  NOTIFICATION_RELOAD,
  PERSONAL_NOTIFICATION_GET_ALL,
  GET_EXCHANGE_RATE,
  NOTIFICATION_GET_ALL,
  NOTIFICATION_GET_BY_ID,
  NOTIFICATION_SEARCH_BODY,
  SEGMENT_NOTIFICATION_RESET_FORM,
  NOTIFY_ALL_NOTIFICATION_RESET_FORM,
  NOTIFY_SEGMENT_DIALOG,
  NOTIFY_BY_USER_ID_DIALOG,
  NOTIFY_ALL_DIALOG,
  NOTIFY_BY_USER_ID_NOTIFICATION_RESET_FORM,
} from "../actions/type";

const initialState = {
  reload: false,
  notifyAllDialog: false,
  notifySegmentDialog: false,
  notifyByUserIdDialog: false,
  personalNotificationData: [],
  exchangeRate: "",
  searchBody: {},
  notifySegmentForm: {
    id: null,
    segmentType: 0,
    region: null,
    startDate: null,
    endDate: null,
    provinceId: null,
    cityId: null,
    minWalletBallance: 0,
    maxWalletBalance: 0,
    hasBalance: false,
    gender: null,
    hasOrders: false,
    titleKU: "",
    titleEn: "",
    titleAr: "",
    messageKu: "",
    messageEn: "",
    messageAr: "",
    imageUrl: "",
    actionTime: null,
    isOneTime: true,
    actionTimes: [
      {
        weekDay: 0,
        checked: false,
        times: [],
      },
      {
        weekDay: 1,
        checked: false,
        times: [],
      },
      {
        weekDay: 2,
        checked: false,
        times: [],
      },
      {
        weekDay: 3,
        checked: false,
        times: [],
      },
      {
        weekDay: 4,
        checked: false,
        times: [],
      },
      {
        weekDay: 5,
        checked: false,
        times: [],
      },
      {
        weekDay: 6,
        checked: false,
        times: [],
      },
    ],
  },
  notifyAllForm: {
    id: null,
    titleKU: "",
    titleEn: "",
    titleAr: "",
    contentEn: "",
    contentKu: "",
    contentAr: "",
    isIos: true,
    isAndroid: true,
    imageUrl: "",
    actionTime: null,
    isOneTime: false,
    actionTimes: [
      {
        weekDay: 0,
        checked: false,
        times: [],
      },
      {
        weekDay: 1,
        checked: false,
        times: [],
      },
      {
        weekDay: 2,
        checked: false,
        times: [],
      },
      {
        weekDay: 3,
        checked: false,
        times: [],
      },
      {
        weekDay: 4,
        checked: false,
        times: [],
      },
      {
        weekDay: 5,
        checked: false,
        times: [],
      },
      {
        weekDay: 6,
        checked: false,
        times: [],
      },
    ],
  },
  notifyByUserIdForm: {
    id: null,
    titleKU: "",
    titleEn: "",
    titleAr: "",
    contentEn: "",
    contentKu: "",
    contentAr: "",
    userIds: [],
    actionTime: null,
    isOneTime: true,
    actionTimes: [
      {
        weekDay: 0,
        checked: false,
        times: [],
      },
      {
        weekDay: 1,
        checked: false,
        times: [],
      },
      {
        weekDay: 2,
        checked: false,
        times: [],
      },
      {
        weekDay: 3,
        checked: false,
        times: [],
      },
      {
        weekDay: 4,
        checked: false,
        times: [],
      },
      {
        weekDay: 5,
        checked: false,
        times: [],
      },
      {
        weekDay: 6,
        checked: false,
        times: [],
      },
    ],
  },
  data: {
    data: [],
    total: 0,
    rowCount: 0,
  },
};

function notificationReducer(notification = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PERSONAL_NOTIFICATION_GET_ALL:
      notification.personalNotificationData = payload;
      return notification;

    case GET_EXCHANGE_RATE:
      notification.exchangeRate = payload;
      return notification;

    case NOTIFICATION_GET_ALL:
      notification.data.data = payload.data;
      notification.data.total = payload.total;
      notification.data.rowCount = payload.rowCount;
      return notification;

    case NOTIFICATION_GET_BY_ID:
      if (payload.url.includes("notifysegment")) {
        const parsedBody = JSON.parse(payload.body);
        const allWeekDays = Array.from({ length: 7 }, (_, i) => i);

        const filledActionTimes = allWeekDays.map((weekDay) => {
          const existingItem = payload.actionTimes?.find(
            (item) => item.weekDay === weekDay
          );
          if (existingItem) {
            return {
              ...existingItem,
              times: existingItem.times?.map((time) => {
                return moment(time, "HH:mm");
              }),
              checked: existingItem.times?.length > 0 ? true : false,
            };
          } else {
            return {
              weekDay,
              checked: false,
              times: [],
            };
          }
        });

        notification.notifySegmentForm = {
          ...payload,
          ...parsedBody,
          contentAr: parsedBody.messageAr,
          contentEn: parsedBody.messageEn,
          contentKu: parsedBody.messageKu,
          actionTimes: filledActionTimes,
        };

        return notification;
      } else if (payload.url.includes("notifyall")) {
        const parsedBody = JSON.parse(payload.body);
        const allWeekDays = Array.from({ length: 7 }, (_, i) => i);

        const filledActionTimes = allWeekDays.map((weekDay) => {
          const existingItem = payload.actionTimes?.find(
            (item) => item.weekDay === weekDay
          );
          if (existingItem) {
            return {
              ...existingItem,
              times: existingItem.times?.map((time) => {
                return moment(time, "HH:mm");
              }),
              checked: existingItem.times?.length > 0 ? true : false,
            };
          } else {
            return {
              weekDay,
              checked: false,
              times: [],
            };
          }
        });

        notification.notifyAllForm = {
          ...payload,
          ...parsedBody,
          actionTimes: filledActionTimes,
        };

        return notification;
      } else {
        const parsedBody = JSON.parse(payload.body);
        const allWeekDays = Array.from({ length: 7 }, (_, i) => i);

        const filledActionTimes = allWeekDays.map((weekDay) => {
          const existingItem = payload.actionTimes?.find(
            (item) => item.weekDay === weekDay
          );
          if (existingItem) {
            return {
              ...existingItem,
              times: existingItem.times?.map((time) => {
                return moment(time, "HH:mm");
              }),
              checked: existingItem.times?.length > 0 ? true : false,
            };
          } else {
            return {
              weekDay,
              checked: false,
              times: [],
            };
          }
        });

        notification.notifyByUserIdForm = {
          ...payload,
          ...parsedBody,
          actionTimes: filledActionTimes,
        };

        return notification;
      }

    case SEGMENT_NOTIFICATION_RESET_FORM:
      notification.notifySegmentForm = initialState.notifySegmentForm;
      return notification;

    case NOTIFY_ALL_NOTIFICATION_RESET_FORM:
      notification.notifyAllForm = initialState.notifyAllForm;
      return notification;

    case NOTIFY_BY_USER_ID_NOTIFICATION_RESET_FORM:
      notification.notifyByUserIdForm = initialState.notifyByUserIdForm;
      return notification;

    case NOTIFICATION_RELOAD:
      return { ...notification, reload: !notification.reload };

    case NOTIFY_ALL_DIALOG:
      return {
        ...notification,
        notifyAllDialog: !notification.notifyAllDialog,
      };

    case NOTIFY_SEGMENT_DIALOG:
      return {
        ...notification,
        notifySegmentDialog: !notification.notifySegmentDialog,
      };

    case NOTIFY_BY_USER_ID_DIALOG:
      return {
        ...notification,
        notifyByUserIdDialog: !notification.notifyByUserIdDialog,
      };

    case NOTIFICATION_SEARCH_BODY:
      return { ...notification, searchBody: payload };

    default:
      return notification;
  }
}

export default notificationReducer;
