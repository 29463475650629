import {
  Box,
  Card,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { userBalances } from "src/actions/reports";
import CustomSpinner from "src/components/CustomSpinner";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { red } from "@mui/material/colors";

export default function UserBalances(props) {
  const dispatch = new useDispatch();
  const [loading, setLoading] = useState(false);
  const [userBalaceData, setUserBalaceData] = useState([]);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    handleTransactions();
  }, [props.setSearch]);

  function handleTransactions() {
    setLoading(true);
    dispatch(
      userBalances({
        from: props.date.startDate,
        to: props.date.endDate,
      })
    ).then((res) => {
      setUserBalaceData(res.data);
      setLoading(false);
    });
  }

  const columns = [
    {
      field: "sequence",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "username",
      flex: 1,
      headerName: "User Name",
    },
    {
      field: "phoneNumber",
      flex: 1,
      headerName: "Phone Number",
    },
    {
      field: "walletAmount",
      flex: 1,
      headerName: "Wallet Amount ($)",
    },
    {
      field: "walletType",
      flex: 1,
      headerName: "wallet Type",
    },
    {
      field: "hasOrder",
      flex: 1,
      headerName: "Has Order !",
      renderCell: (params) => (
        <Typography>{params.row.hasOrder ? 'True' : 'False'} </Typography>
      ),
    },
    {
      field: "hasValidOrder",
      flex: 1,
      headerName: "Has Vaild Order!",
      renderCell: (params) => (
        <Typography>{params.row.hasValidOrder ? 'True' : 'False'} </Typography>
      ),
    },
  ]

  function CustomToolbar() {
    return (
      <Box>
        <Box>
          <Box>
            <Card>
              <CardHeader
                title="User Balances Reports"
              />
              <Divider />
            </Card>
          </Box>
        </Box>
        <Box>
          <GridToolbarContainer>
            <GridToolbarExport
              printOptions={{
                disableToolbarButton: true,
                hideFooter: true,
                hideToolbar: true,
              }}
              csvOptions={{
                fileName: "User Balances Report || Orderii",
                utf8WithBom: true,
              }}
            />
          </GridToolbarContainer>
        </Box>
      </Box>
    );
  }
  return (
    <Card style={{ marginTop: 10 }}>
      <PerfectScrollbar>
        <Box>
          {loading ? (
            <CustomSpinner />
          ) : (
            <Box>
              {userBalaceData.length == 0 ? (
                <Box sx={{ textAlign: "center", marginY: 2 }}>
                  <Typography sx={{ color: red[600] }}>
                    <DangerousOutlinedIcon fontSize="large" />
                  </Typography>
                  <Typography>No Data</Typography>
                </Box>
              ) : (
                <Box sx={{ height: 'calc(100vh - 320px)' }}>
                  <DataGrid
                    sx={{
                      "& .MuiDataGrid-row ": {
                        textAlign: "center",
                      },
                      "& .css-204u17-MuiDataGrid-main": {
                        paddingLeft: "15px",
                      },
                      "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                        paddingLeft: "15px",
                      },
                    }}
                    columns={columns}
                    rows={userBalaceData.map((row, index) => {
                      return { ...row, sequence: index + 1 };
                    })}
                    getRowId={(params) => params.userId}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                    pageSize={pageSize}
                    disableSelectionOnClick
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[25, 75, 100]}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
