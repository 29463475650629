import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "src/actions/prohibitedItems";
import { FaUserLock } from "react-icons/fa";
import RolePermission from "src/components/RolePermission";
import ProhibitedItemsForm from "src/pages/cross/Configuration/reasons/prohibitedItemsReason/form";

const ProhibitedItemsHeader = () => {
  const dispatch = useDispatch();

  const _ProhibitedItems = useSelector(
    (state) => state.prohibitedItems.openDialog
  );
  return (
    <>
      <Helmet>
        <title> Prohibited Items Reasons | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Prohibited Items Reasons
              </Typography>
            }
            avatar={<FaUserLock size={20} />}
            action={
              <RolePermission permission="32-01">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => dispatch(openDialog())}
                >
                  CREATE
                </Button>
              </RolePermission>
            }
          />
          {_ProhibitedItems && <ProhibitedItemsForm />}
        </Card>
      </Box>
    </>
  );
};

export default ProhibitedItemsHeader;
