import helper from "src/utils/helper";
import {
  USER_ADDRESSES_PAGE_NUMBER,
  USER_ADDRESSES_RELOAD,
  USER_ADDRESSES_GET_ALL,
  USER_ADDRESSES_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";
import {
  openAlertDialog,
  showErrorSnackbar,
} from "src/actions/snackbarMessages";
import userAddressesService from "src/api/giftServices/userAddresses.service";

export const reloadUserAddresses = () => (dispatch) => {
  dispatch({
    type: USER_ADDRESSES_RELOAD,
  });
};

export const getAllUserAddresses = (pageNO, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadUserAddresses());
    const res = await userAddressesService.getAllReceiverAddresses(
      pageNO,
      searchBody
    );
    dispatch({
      type: USER_ADDRESSES_GET_ALL,
      payload: res.data.data,
    });
    dispatch(reloadUserAddresses());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadUserAddresses());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const saveUserAddressesPageNum = (data) => (dispatch) => {
  dispatch({
    type: USER_ADDRESSES_PAGE_NUMBER,
    payload: data,
  });
};

export const saveUserAddressesSearchBody = (data) => (dispatch) => {
  dispatch({
    type: USER_ADDRESSES_SEARCH_BODY,
    payload: data,
  });
};
