import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { MdPriceChange } from "react-icons/md";
import { openDialog } from "src/actions/promos";
import RolePermission from "src/components/RolePermission";
import CustomerPromosForm from "src/pages/cross/promos/customer/from/Form";

const CustomerPromosHeader = () => {
  const dispatch = useDispatch();

  const _Promos = useSelector((state) => state.promos.openDialog);

  return (
    <>
      <Helmet>
        <title>Customer Promos | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Customer Promos
              </Typography>
            }
            avatar={<MdPriceChange size={20} />}
            action={
              <RolePermission permission="25-01">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => dispatch(openDialog())}
                >
                  CREATE
                </Button>
              </RolePermission>
            }
          />
          {_Promos ? <CustomerPromosForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default CustomerPromosHeader;
