import axios from "axios";
import Cookies from "universal-cookie";
import host from "./host";
const cookies = new Cookies();
var _headers = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  "App-Version": "web",
  "Accept-Language": "en",
}
if(cookies.get("token")) _headers.authorization = `Bearer ${cookies.get("token")}`
export default axios.create({
  baseURL: host,
  headers: _headers,
});
