import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineAppSettingsAlt } from "react-icons/md";
import { openGeneralSettingDialog } from "src/actions/generalSetting";
import RolePermission from "src/components/RolePermission";
import SettingsForm from "src/pages/cross/Configuration/Settings/form/Form";

const SettingsHeader = () => {
  const dispatch = useDispatch();

  const openGeneralSetting = useSelector(
    (state) => state.generalSetting.openDialog
  );

  return (
    <>
      <Helmet>
        <title>Settings | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Settings
              </Typography>
            }
            avatar={<MdOutlineAppSettingsAlt size={20} />}
            action={
              <RolePermission permission="15-01">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() =>
                    dispatch(openGeneralSettingDialog(openGeneralSetting))
                  }
                >
                  Create
                </Button>
              </RolePermission>
            }
          />
          {openGeneralSetting ? <SettingsForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default SettingsHeader;
