import shareCenterService from "src/api/services/shareCenter.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import {
  OPEN_IMAGE_UPLOAD_DEALOG,
  CREATE_SHARE_CENTER,
  GET_ALL_SHARE_CENTER,
  GET_BY_ID,
  UPDATE_SHARE_CENTER,
  DELETE_SHARE_CENTER,
  RELOAD_SHARE_CENTER,
  SAVE_SEARCH_BODY_SHARE_CENTER,
  SAVE_SEARCH_PAGE_NO_SC,
} from "./type";

export const createIamge = (data, pageNo, searchBody) => async (dispatch) => {
  try {
    const res = await shareCenterService.createShareCenter(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch({
      type: CREATE_SHARE_CENTER,
      payload: res.data.data,
    });
    dispatch(getAllImages(pageNo, searchBody));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const openUploadImgDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_IMAGE_UPLOAD_DEALOG,
    payload: data,
  });
};

export const reloadShareCenter = (data) => (dispatch) => {
  dispatch({
    type: RELOAD_SHARE_CENTER,
    payload: data,
  });
};

export const getAllImages = (pageNo, data) => async (dispatch) => {
  try {
    const res = await shareCenterService.getShareCenterPageNo(pageNo, data);
    dispatch({
      type: GET_ALL_SHARE_CENTER,
      payload: res.data.data,
    });
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_BODY_SHARE_CENTER,
    payload: data,
  });
};

export const savePageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_PAGE_NO_SC,
    payload: data,
  });
};

export const getShareCenterById = (id) => async (dispatch) => {
  try {
    const res = await shareCenterService.getShareCenterById(id);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const UpdateShareCenter = (data) => async (dispatch) => {
  try {
    const res = await shareCenterService.UpdateShareCenter(data);
    dispatch({
      type: UPDATE_SHARE_CENTER,
      payload: data,
    });
    dispatch(showSuccessSnackbar("Updated successfully"));
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteShareCenter = (id) => async (dispatch) => {
  try {
    const res = await shareCenterService.deleteShareCenter(id);
    dispatch({
      type: DELETE_SHARE_CENTER,
      payload: id,
    });
    // return Promise.resolve(res.data);
    dispatch(showSuccessSnackbar("Deleted successfully"));
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
