import { Grid, Box } from "@mui/material";
import React from "react";
import GiftCartTable from "./home/table";
import GiftCartHeader from "./home/header/header";

const GiftCart = () => {
  return (
    <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <GiftCartHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <GiftCartTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GiftCart;
