import helper from "src/utils/helper";
import {
  FAVORITE_PAGE_NUMBER,
  FAVORITE_RELOAD,
  FAVORITE_GET_ALL,
  FAVORITE_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";
import {
  openAlertDialog,
  showErrorSnackbar,
} from "src/actions/snackbarMessages";
import favoriteService from "src/api/giftServices/favorite.service";

export const reloadFavorite = () => (dispatch) => {
  dispatch({
    type: FAVORITE_RELOAD,
  });
};

export const getAllFavorite = (pageNO, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadFavorite());
    const res = await favoriteService.getAllFavorite(pageNO, searchBody);
    dispatch({
      type: FAVORITE_GET_ALL,
      payload: res.data.data,
    });
    dispatch(reloadFavorite());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadFavorite());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const saveFavoritePageNum = (data) => (dispatch) => {
  dispatch({
    type: FAVORITE_PAGE_NUMBER,
    payload: data,
  });
};

export const saveFavoriteSearchBody = (data) => (dispatch) => {
  dispatch({
    type: FAVORITE_SEARCH_BODY,
    payload: data,
  });
};
