import {
  GET_ALL_ORDERS,
  SAVE_SEARCH_BODY_ORDERS,
  SAVE_PAGE_NUM,
  GET_ORDER_DETAILS,
  RELOAD_ORDER_ORDER_DETAILS,
  GET_USER_ORDERS,
} from "./type";
import OrderDataService from "../api/services/order.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const reloadOrderDetails = () => (dispatch) => {
  dispatch({
    type: RELOAD_ORDER_ORDER_DETAILS,
  });
};

export const getAllOrders = (pageNo, pageSize, data) => async (dispatch) => {
  try {
    const res = await OrderDataService.getAll(pageNo, pageSize, data);
    dispatch({
      type: GET_ALL_ORDERS,
      payload: res.data.data,
    });
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getUserOrders = (userId) => async (dispatch) => {
  try {
    dispatch(reloadOrderDetails());
    const res = await OrderDataService.getAllUserOrders(userId);
    dispatch({
      type: GET_USER_ORDERS,
      payload: res.data.data,
    });
    dispatch(reloadOrderDetails());
    // return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadOrderDetails());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getDetails =
  (id, justNew = false) =>
  async (dispatch) => {
    try {
      dispatch(reloadOrderDetails());
      const res = await OrderDataService.getDetails(id, justNew);
      dispatch({
        type: GET_ORDER_DETAILS,
        payload: res.data,
      });
      dispatch(reloadOrderDetails());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadOrderDetails());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getItemDetails = (id) => async (dispatch) => {
  try {
    const res = await OrderDataService.getItemDetails(id);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateStatus = (data) => async (dispatch) => {
  try {
    const res = await OrderDataService.updateStatus(data);
    dispatch(showSuccessSnackbar("Status changed successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateItem = (orderId, data) => async (dispatch) => {
  try {
    const res = await OrderDataService.updateOrderItem(orderId, data);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateOrder = (data) => async (dispatch) => {
  try {
    const res = await OrderDataService.updateOrder(data);
    dispatch(showSuccessSnackbar("Order updated successfully!"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const rejectItem =
  (itemId, reasonId, orderId, cancelNote) => async (dispatch) => {
    try {
      const res = await OrderDataService.rejectItem(
        itemId,
        reasonId,
        orderId,
        cancelNote
      );
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getOrderHistory = (orderId) => async (dispatch) => {
  try {
    const res = await OrderDataService.getHistory(orderId);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_BODY_ORDERS,
    payload: data,
  });
};

export const savePageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_PAGE_NUM,
    payload: data,
  });
};

export const updateDelivery = (data, id) => async (dispatch) => {
  try {
    await OrderDataService.updateDelivery(data);
    dispatch(showSuccessSnackbar("Delivery to home updated successfully!"));
    dispatch(getDetails(id));
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getUserByOrderNumber = (OrderNumber) => async (dispatch) => {
  try {
    const res = await OrderDataService.getUserByOrderNumber(OrderNumber);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateLockerNumber = (id, LockerNumber) => async (dispatch) => {
  try {
    const res = await OrderDataService.updateLockerNumber(id, LockerNumber);
    dispatch(showSuccessSnackbar("Locker Number updated successfully!"));
    dispatch(getDetails(id));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
