import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import { Card, CardHeader, Typography } from '@mui/material';
import { FaUserFriends } from 'react-icons/fa';

const ReferralCodeHeader = () => {
  return (
    <>
      <Helmet>
        <title>Referral Code | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Referral Code
              </Typography>
            }
            avatar={<FaUserFriends size={20} />}
          />
        </Card>
      </Box>
    </>
  );
}

export default ReferralCodeHeader;
