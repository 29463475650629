import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { shipments_status } from "src/utils/common";
import Icon from "@mui/material/Icon";
import { updateShipmentStatus } from "src/actions/shipments";

export default function ShipmentStatusMenu({
  status = "",
  onSuccess = function () {},
  shipmentId = null,
  statusId,
}) {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  function handleUpdateShipmentStatus(e) {
    var newStatus = e.target.id;
    dispatch(updateShipmentStatus(shipmentId, newStatus)).then((res) => {
      onSuccess(newStatus);
    });
  }

  const getStatusIcon = (status) => {
    var color;
    status === "Preparing"
      ? (color = "warning")
      : status === "Damaged" || status === "Lost"
      ? (color = "error")
      : status === "In Baghdad Warehouse"
      ? (color = "info")
      : status === "Complete"
      ? (color = "success")
      : (color = "secondary");

    return (
      <Chip
        size="small"
        label={status}
        color={color}
        clickable
        onClick={handleClick}
      />
    );
  };

  return (
    <React.Fragment>
      {getStatusIcon(
        shipments_status.filter((status) => status.id === statusId)[0]?.name
      )}
      <Menu
        anchorEl={anchorEl}
        open={
          _UserInfo.roleOEndpoint.map((data) => data.Code).includes("59-07")
            ? open
            : false
        }
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {shipments_status.map((status) => (
          <MenuItem
            key={status.id}
            id={status.id}
            onClick={handleUpdateShipmentStatus}
          >
            <ListItemIcon>
              <Icon>{status.icon}</Icon>
            </ListItemIcon>
            {status.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
