import axiosService from "src/api/axiosService";


class ShareCenterService {
  createShareCenter(data) {
    return axiosService.post(`/api/sharecenter/create`, data);
  }

  getShareCenterById(id) {
    return axiosService.get(`/api/sharecenter/getbyid/${id}`);
  }

  // getShareCenterPageNo(pageNo) {
  //   return axiosService.get(`/api/sharecenter/all/${pageNo}`);
  // }

  getShareCenterPageNo(pageNo,data) {
    let params = "";
    if (data) {
        Object.keys(data).forEach(key => {
            params += `${key}=${data[key]}&`;
        });
    }
    return axiosService.get(`/api/sharecenter/all/${pageNo}?${params}`);
}

  UpdateShareCenter(data) {
    return axiosService.put(`/api/sharecenter/update`, data);
  }

  deleteShareCenter(id) {
    return axiosService.delete(`/api/sharecenter/delete/${id}`);
  }

}

export default new ShareCenterService();
