import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "src/actions/popUpPost";
import PopUpPostForm from "../../from/Form";
import { MdFeaturedVideo } from "react-icons/md";
import RolePermission from "src/components/RolePermission";
import { showErrorSnackbar } from "src/actions/snackbarMessages";

const PopUpPostHeader = () => {
  const dispatch = useDispatch();

  const popUpPost = useSelector((state) => state.popUpPost.openDialog);
  const _PopUpPosts = useSelector(
    (state) => state.popUpPost.popUpPostData.data
  );

  return (
    <>
      <Helmet>
        <title>Popup Post | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Popup Post
              </Typography>
            }
            avatar={<MdFeaturedVideo size={20} />}
            action={
              <RolePermission permission="24-01">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() =>
                    _PopUpPosts.length >= 4
                      ? dispatch(
                          showErrorSnackbar(
                            "Can't create more than 4 popup post"
                          )
                        )
                      : dispatch(openDialog())
                  }
                >
                  CREATE
                </Button>
              </RolePermission>
            }
          />
          {popUpPost && <PopUpPostForm />}
        </Card>
      </Box>
    </>
  );
};

export default PopUpPostHeader;
