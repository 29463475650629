import {
  GET_ALL_REFERRAL_CODE,
  RELOAD_REFERRAL_CODE,
  SAVE_SEARCH_BODY_REFERRAL_CODE,
  SAVE_REFERRAL_CODE_PAGE_NO,
} from "./type";

import helper from "src/utils/helper";
import { openAlertDialog, showErrorSnackbar } from "./snackbarMessages";
import ReferralCodeService from "src/api/services/ReferralCode.service";

export const getAllReferralCode = (pageNo, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadReferralCode());
    const res = await ReferralCodeService.getAll(pageNo, searchBody);
    dispatch({
      type: GET_ALL_REFERRAL_CODE,
      payload: res.data.data,
    });
    dispatch(reloadReferralCode());
  } catch (err) {
    dispatch(reloadReferralCode());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
export const reloadReferralCode = () => (dispatch) => {
  dispatch({
    type: RELOAD_REFERRAL_CODE,
  });
};

export const saveReferralCodeSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_BODY_REFERRAL_CODE,
    payload: data,
  });
};

export const saveReferralCodePageNO = (data) => (dispatch) => {
  dispatch({
    type: SAVE_REFERRAL_CODE_PAGE_NO,
    payload: data,
  });
};
