import {
  Autocomplete,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { saveSearchBody } from "src/actions/generalSetting";
import { GENERAL_SETTINGS_TYPES } from "src/utils/common";

export default function GeneralSettingSearchFilter({
  pageNo = function () {},
}) {
  const searchFilter = useSelector((state) => state.generalSetting.searchBody);

  const [searchBody, setSearchBody] = useState({
    key: "",
    type: null,
    isActive: true,
  });

  const dispatch = useDispatch();

  const isActive = [
    { key: "", name: "All" },
    { key: "true", name: "Active" },
    { key: "false", name: "Deactive" },
  ];

  return (
    <Card>
      {!searchFilter ? null : (
        <CardContent style={{ paddingBottom: 15 }}>
          <div
            style={{
              maxWidth: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{ alignItems: "center", display: "flex" }}
            >
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  size="small"
                  value={searchBody.key}
                  name="key"
                  label="Search Key"
                  onChange={(e) => {
                    setSearchBody({
                      ...searchBody,
                      key: e.target.value,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      dispatch(saveSearchBody(searchBody));
                      pageNo(0);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={GENERAL_SETTINGS_TYPES}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Search Type" />
                  )}
                  onChange={(event, value) => {
                    dispatch(
                      saveSearchBody({
                        ...searchBody,
                        type: value ? value.id : null,
                      })
                    );
                    setSearchBody({
                      ...searchBody,
                      type: value ? value.id : null,
                    });
                    pageNo(0);
                  }}
                  value={
                    GENERAL_SETTINGS_TYPES.filter(
                      (x) => x.id == searchBody.type
                    )[0]
                      ? GENERAL_SETTINGS_TYPES.filter(
                          (x) => x.id == searchBody.type
                        )[0]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={isActive}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Is Active !" />
                  )}
                  onChange={(event, value) => {
                    dispatch(
                      saveSearchBody({
                        ...searchBody,
                        isActive: value ? value.key : "",
                      })
                    );
                    setSearchBody({
                      ...searchBody,
                      isActive: value ? value.key : "",
                    });
                    pageNo(0);
                  }}
                  value={
                    isActive.filter((x) => x.key == searchBody.isActive)[0]
                      ? isActive.filter((x) => x.key == searchBody.isActive)[0]
                      : null
                  }
                />

                {/* <FormControlLabel
                  control={
                    <Switch
                      checked={searchBody.isActive}
                      onClick={(e) => {
                        dispatch(saveSearchBody({ ...searchBody, isActive: e.target.checked }));
                        setSearchBody({
                          ...searchBody,
                          isActive: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Is Active !"
                /> */}
              </Grid>
              <Grid item xs={3}>
                <Tooltip title="Clear filter">
                  <IconButton
                    onClick={() => {
                      dispatch(
                        saveSearchBody({
                          pageSize: 10,
                        })
                      );
                      setSearchBody({
                        key: "",
                        type: null,
                        isActive: "",
                      });
                      pageNo(0);
                    }}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
          {/* </div> */}
        </CardContent>
      )}
    </Card>
  );
}
