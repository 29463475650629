import {
  GET_ALL_GIFTS_CATEGORY,
  GET_BY_ID_GIFTS_CATEGORY,
  GET_GIFTS_CATEGORY_LIST,
  OPEN_GIFTS_CATEGORY_DIALOG,
  RELOAD_GIFTS_CATEGORY,
  RESET_GIFTS_CATEGORY_FORM,
  SAVE_GIFTS_CATEGORY_PAGE_NO,
  SAVE_GIFTS_CATEGORY_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";

const initialState = {
  reload: false,
  openDialog: false,
  form: {
    id: null,
    nameAr: "",
    nameEn: "",
    nameKu: "",
    attachmentId: "",
    isActive: true,
    sequence: null,
    type: "",
    provinces: [],
    filterCollection: [
      {
        nameAr: "",
        nameEn: "",
        nameKu: "",
        values: [{ nameAr: "", nameEn: "", nameKu: "" }],
      },
    ],
  },
  data: {
    data: [],
    pageCount: 0,
    rowCount: 0,
  },
  giftItemCategoryList: [],
  searchBody: {
    pageSize: 15,
    name: "",
    isActive: null,
    type: null,
  },
  getById: {},
  saveGiftItemCategoryPageNum: 0,
};

function giftItemCategoryReducer(giftItemCategory = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_GIFTS_CATEGORY:
      giftItemCategory.data = payload.data;
      giftItemCategory.pageCount = payload.pageCount;
      giftItemCategory.rowCount = payload.rowCount;
      return giftItemCategory;

    case GET_GIFTS_CATEGORY_LIST:
      giftItemCategory.giftItemCategoryList = payload;
      return giftItemCategory;

    case GET_BY_ID_GIFTS_CATEGORY:
      giftItemCategory.form.id = payload.id;
      giftItemCategory.form.nameAr = payload.nameAr;
      giftItemCategory.form.nameEn = payload.nameEn;
      giftItemCategory.form.nameKu = payload.nameKu;
      giftItemCategory.form.imageUrl = payload.imageUrl;
      giftItemCategory.form.attachmentId = payload.attachmentId;
      giftItemCategory.form.sequence = payload.sequence;
      giftItemCategory.form.type = payload.type;
      giftItemCategory.form.isActive = payload.isActive;
      giftItemCategory.form.provinces = payload.provinces;
      giftItemCategory.form.filterCollection = payload.filterCollections;
      return giftItemCategory;

    case OPEN_GIFTS_CATEGORY_DIALOG:
      return { ...giftItemCategory, openDialog: !giftItemCategory.openDialog };

    case SAVE_GIFTS_CATEGORY_PAGE_NO:
      return { ...giftItemCategory, saveGiftItemCategoryPageNum: payload };

    case RELOAD_GIFTS_CATEGORY:
      return { ...giftItemCategory, reload: !giftItemCategory.reload };

    case SAVE_GIFTS_CATEGORY_SEARCH_BODY:
      return { ...giftItemCategory, searchBody: payload };

    case RESET_GIFTS_CATEGORY_FORM:
      giftItemCategory.form.id = null;
      giftItemCategory.form.nameEn = "";
      giftItemCategory.form.nameAr = "";
      giftItemCategory.form.nameKu = "";
      giftItemCategory.form.attachmentId = "";
      giftItemCategory.form.sequence = "";
      giftItemCategory.form.type = null;
      giftItemCategory.form.isActive = true;
      giftItemCategory.form.provinces = [];
      giftItemCategory.form.filterCollection = [
        {
          nameAr: "",
          nameEn: "",
          nameKu: "",
          values: [{ nameAr: "", nameEn: "", nameKu: "" }],
        },
      ];
      return giftItemCategory;

    default:
      return giftItemCategory;
  }
}

export default giftItemCategoryReducer;
