import { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { Controller, useForm } from "react-hook-form";
import {
  createAdditionalFees,
  deleteAdditionalFees,
  openDialog,
  openFormDialog,
  resetForm,
  updateAdditionalFees,
} from "src/actions/orderAddtionalFees";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import CustomEmpty from "src/components/CustomEmpty";
import RolePermission from "src/components/RolePermission";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const AdditionalFeesForm = () => {
  const dispatch = useDispatch();

  const _AdditionalFees = useSelector((state) => state.additionalFees);
  const _OrderData = useSelector((state) =>
    _AdditionalFees.type === "global"
      ? state.orders.orderDetails
      : state.giftOrder.form
  );
  const reload = useSelector((state) =>
    _AdditionalFees.type === "global"
      ? state.orders.orderDetailsReload
      : state.giftOrder.reload
  );

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedAdditionalFee, setSelectedAdditionalFee] = useState({
    id: null,
    orderId: null,
    amount: "",
    notes: "",
  });

  const isAdd = selectedAdditionalFee.id ? false : true;
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: selectedAdditionalFee,
  });

  useEffect(() => {
    reset({ ...selectedAdditionalFee });
  }, [selectedAdditionalFee, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        createAdditionalFees(
          {
            orderId: _OrderData.id,
            isGiftOrder: _OrderData.orderiiFee ? false : true,
            amount: data.amount,
            notes: data.notes,
          },
          _OrderData.orderiiFee ? false : true
        )
      ).then(() => {
        setSelectedAdditionalFee({
          id: null,
          orderId: null,
          amount: "",
          notes: "",
        });
      });
    } else {
      dispatch(
        updateAdditionalFees(
          data.id,
          {
            amount: data.amount,
            notes: data.notes,
          },
          _OrderData.id,
          _OrderData.orderiiFee ? false : true
        )
      ).then(() => {
        setSelectedAdditionalFee({
          id: null,
          orderId: null,
          amount: "",
          notes: "",
        });
      });
    }
  };

  const handleDialog = () => {
    dispatch(openDialog());
  };

  function deleteSelectedAdditionalFees(id) {
    setOpenDeleteDialog(false);
    dispatch(
      deleteAdditionalFees(
        id,
        _OrderData.id,
        _OrderData.orderiiFee ? false : true
      )
    ).then((x) => {
      dispatch(resetForm());
      setSelectedAdditionalFee({
        id: null,
        orderId: null,
        amount: "",
        notes: "",
      });
    });
  }

  const callHistoryColumns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      renderCell: (params) => params.row.amount.toLocaleString(),
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 2,
      renderCell: (params) => params.row.notes,
    },
    {
      field: "user",
      headerName: "User",
      flex: 2,
      renderCell: (params) => params.row.user?.name,
    },
    {
      field: "createdAt",
      headerName: "CreatedAt",
      flex: 2,
      renderCell: (params) =>
        moment(params.row.createdAt).format("YYYY-MM-DD / HH:mm a"),
    },
    {
      field: "Operations",
      headerName: "Operations",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <RolePermission permission="58-02">
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={(e) => {
                setSelectedAdditionalFee(params.row);
                dispatch(openFormDialog());
              }}
            >
              <Tooltip title="Edit" aria-label="edit">
                <EditIcon />
              </Tooltip>
            </IconButton>
          </RolePermission>
          <RolePermission permission="58-03">
            <IconButton
              color="error"
              aria-label="delete"
              onClick={(e) => {
                setSelectedAdditionalFee(params.row);
                setOpenDeleteDialog(true);
              }}
            >
              <Tooltip title="Delete" aria-label="delete">
                <DeleteForeverIcon />
              </Tooltip>
            </IconButton>
          </RolePermission>
        </Box>
      ),
    },
  ];

  return (
    <CustomDialog
      title={"Order Additional Fees"}
      isOpen={_AdditionalFees.openDialog}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      {openDeleteDialog && (
        <CustomDialog
          isOpen={openDeleteDialog}
          handleClose={() => {
            setSelectedAdditionalFee({
              id: null,
              orderId: null,
              amount: "",
              notes: "",
            });
            setOpenDeleteDialog(false);
          }}
          isActiveDelete
          isRequierdActions
          maxWidth="sm"
          title="Delete confirmation"
          onDelete={() =>
            deleteSelectedAdditionalFees(selectedAdditionalFee.id)
          }
          selectedObject={`${selectedAdditionalFee.amount}`}
        ></CustomDialog>
      )}
      <CustomDialog
        title={
          selectedAdditionalFee.id
            ? "Update Order Additional Fees"
            : "Add Order Additional Fees"
        }
        isOpen={_AdditionalFees.openFormDialog}
        keepMounted
        fullWidth={true}
        handleClose={() => {
          setSelectedAdditionalFee({
            id: null,
            orderId: null,
            amount: "",
            notes: "",
          });
          dispatch(openFormDialog());
        }}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    required
                    size="small"
                    label="Amount"
                    fullWidth
                    {...field}
                    helperText={
                      _OrderData.orderiiFee
                        ? "العملة يجب ان تكون بالدولار فقط"
                        : "العملة يجب ان تكون بالدينار العراقي فقط"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    multiline
                    rows={3}
                    size="small"
                    label="notes"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isDirty}
              >
                {isAdd ? "Create" : "Update"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </CustomDialog>
      <Grid container spacing={2}>
        <Grid item xs={6} display="flex" justifyContent="start">
          <Typography variant="h4" component="div">
            {_OrderData.orderiiFee
              ? "العملة يجب ان تكون بالدولار فقط"
              : "العملة يجب ان تكون بالدينار العراقي فقط"}
          </Typography>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => dispatch(openFormDialog())}
          >
            Add Additional Fees
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: { md: "calc(100vh - 360px)", xs: 1000 } }}>
            <DataGrid
              rows={
                _OrderData.orderAdditionalFees.orderAdditionalFees.map(
                  (x, index) => {
                    return { ...x, index: index + 1 };
                  }
                ) ?? []
              }
              loading={reload}
              columns={callHistoryColumns}
              rowHeight={50}
              disableSelectionOnClick
              pagination
              paginationMode="client"
              components={{
                NoRowsOverlay: CustomEmpty,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default AdditionalFeesForm;
