import departmentService from "src/api/services/department/department.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../snackbarMessages";
import {
  OPEN_DEPARTMENT_DIALOG,
  GET_ALL_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT,
  GET_DEPARTMENT_BYID,
} from "../type";

export const createDepartment = (data) => async (dispatch) => {
  try {
    const res = await departmentService.createDepartment(data);
    dispatch(showSuccessSnackbar("Department Created successfully"));
    dispatch(getDepartment());
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const openDepartmentDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_DEPARTMENT_DIALOG,
    payload: data,
  });
};

export const getDepartment = () => async (dispatch) => {
  try {
    const res = await departmentService.getDepartment();
    dispatch({
      type: GET_ALL_DEPARTMENT,
      payload: res.data.data,
    });
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getByIdDepartment = (id) => async (dispatch) => {
  try {
    const res = await departmentService.getByIdDepartment(id);
    dispatch({
      type: GET_DEPARTMENT_BYID,
      payload: res.data.data,
    });
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateDepartment = (id, data) => async (dispatch) => {
  try {
    const res = await departmentService.updateDepartment(id, data);
    dispatch({
      type: UPDATE_DEPARTMENT,
      payload: res.data.data,
    });
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteDepartment = (id) => async (dispatch) => {
  try {
    const res = await departmentService.deleteDepartment(id);
    dispatch({
      type: DELETE_DEPARTMENT,
      payload: res.data.data,
    });
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
