import {
  CREATE_DEPARTMENTEMPLOYEE, GET_ALL_DEPARTMENTEMPLOYEE, DELETE_DEPARTMENTEMPLOYEE, ERROR_DEPARTMENTEMPLOYEE
} from "../../actions/type";

const initialState = {
  data: [],
  error: null
}

function departmentEmployee(departmentEmployee = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_DEPARTMENTEMPLOYEE:
      departmentEmployee.data.push(payload);
      return departmentEmployee;

    case GET_ALL_DEPARTMENTEMPLOYEE:
      return {
        ...departmentEmployee, 
        data: payload
      }

    case ERROR_DEPARTMENTEMPLOYEE:
      return {
        ...departmentEmployee,
        data: [],
        error: payload
      }

    case DELETE_DEPARTMENTEMPLOYEE:
      departmentEmployee.data = departmentEmployee.data.filter((x) => x.id !== payload);
      return departmentEmployee;


    default:
      return departmentEmployee;
  }
}

export default departmentEmployee;