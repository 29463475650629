import {
  PROMOTED_ITEMS_RESET_FORM,
  PROMOTED_ITEMS_GET_BY_ID,
  PROMOTED_ITEMS_PAGE_NUMBER,
  PROMOTED_ITEMS_OPEN_DIALOG,
  PROMOTED_ITEMS_RELOAD,
  PROMOTED_ITEMS_GET_ALL,
  PROMOTED_ITEMS_GET_ALL_LIST,
  PROMOTED_ITEMS_SEARCH_BODY,
} from "./type";

import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import promotedItemsService from "src/api/services/promotedItems.service";

export const openDialog = () => (dispatch) => {
  dispatch({
    type: PROMOTED_ITEMS_OPEN_DIALOG,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: PROMOTED_ITEMS_RESET_FORM,
  });
};

export const reloadPromotedItems = () => (dispatch) => {
  dispatch({
    type: PROMOTED_ITEMS_RELOAD,
  });
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: PROMOTED_ITEMS_SEARCH_BODY,
    payload: data,
  });
};

export const savePageNo = (data) => (dispatch) => {
  dispatch({
    type: PROMOTED_ITEMS_PAGE_NUMBER,
    payload: data,
  });
};

export const createPromotedItems =
  (data, pagNo, pageSize, searchBody) => async (dispatch) => {
    try {
      const res = await promotedItemsService.create(data);
      dispatch(showSuccessSnackbar("Created successfully"));
      dispatch(getAllPromotedItems(pagNo, searchBody));
      dispatch(openDialog());
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getAllPromotedItems = (pageNo, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadPromotedItems());
    const res = await promotedItemsService.getAll(pageNo, searchBody);
    dispatch({
      type: PROMOTED_ITEMS_GET_ALL,
      payload: res.data.data,
    });
    dispatch(reloadPromotedItems());
  } catch (err) {
    dispatch(reloadPromotedItems());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getListOrderCancelReasons = () => async (dispatch) => {
  try {
    dispatch(reloadPromotedItems());
    const res = await promotedItemsService.getList();
    dispatch({
      type: PROMOTED_ITEMS_GET_ALL_LIST,
      payload: res.data.data,
    });
    dispatch(reloadPromotedItems());
  } catch (err) {
    dispatch(reloadPromotedItems());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updatePromotedItems =
  (data, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await promotedItemsService.update(data.id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllPromotedItems(pageNo, searchBody));
      dispatch(openDialog());
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deletePromotedItems =
  (id, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await promotedItemsService.delete(id);
      dispatch(showSuccessSnackbar("Deleted successfully!"));
      dispatch(getAllPromotedItems(pageNo, searchBody));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadPromotedItems());
    const res = await promotedItemsService.getById(id);
    dispatch({
      type: PROMOTED_ITEMS_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadPromotedItems());
  } catch (err) {
    dispatch(reloadPromotedItems());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(reloadPromotedItems());
    const res = await promotedItemsService.getById(id);
    dispatch({
      type: PROMOTED_ITEMS_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadPromotedItems());
    dispatch(openDialog());
  } catch (err) {
    dispatch(reloadPromotedItems());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
