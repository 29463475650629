import { useState, forwardRef, useEffect } from "react";
import { TextField, Grid, Slide, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  createDepartment,
  openDepartmentDialog,
} from "src/actions/department/department";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DepartmentForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const department = useSelector((state) => state.department);

  const { control, reset, handleSubmit } = useForm({
    defaultValues: { name: "" },
  });

  useEffect(() => {
    reset();
  }, []);

  const onSubmit = (data) => {
    setIsLoading(true);
    dispatch(createDepartment(data)).then((x) => {
      setIsLoading(false);
      handlDialog();
    });
  };

  const handlDialog = () => {
    dispatch(openDepartmentDialog(department.dialog));
  };

  return (
    <CustomDialog
      title={"CREATE Department"}
      isOpen={department.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handlDialog();
      }}
      maxWidth="sm"
      aria-describedby="alert-dialog-slide-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={isLoading}
              >
                Add
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default DepartmentForm;
