import {
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Divider,
  LinearProgress,
  Box,
  Stack,
  Chip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import {
  getLockerHistory,
  openLockerHistoryDialog,
  saveLockerHistoryPageNo,
  saveLockerHistorySearchBody,
} from "src/actions/locker";
import { DataGrid } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";

const LockerHistoryDialog = (props) => {
  const dispatch = useDispatch();

  const _Locker = useSelector((state) => state.locker);
  const lockerData = useSelector((state) => state.locker.lockerHistory);
  const loading = useSelector((state) => state.locker.reload);
  const searchBody = useSelector(
    (state) => state.locker.lockerHistorySearchBody
  );
  const _PageNo = useSelector((state) => state.locker.lockerHistoryPageNo);

  useEffect(() => {
    dispatch(getLockerHistory(_PageNo + 1, _Locker.selectedLocker, searchBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_PageNo, searchBody]);

  const handlePageChange = (newPage) => {
    dispatch(saveLockerHistoryPageNo(newPage));
  };

  const handleDialog = () => {
    dispatch(openLockerHistoryDialog());
  };

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "creator",
      headerName: "Creator",
      flex: 0.5,
      renderCell: (params) => params.row.creator?.name,
    },
    {
      field: "order",
      headerName: "Order",
      flex: 0.5,
      renderCell: (params) => (
        <Typography variant="body">
          <a
            href={`/app/order/${params.row.order?.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.row.order?.number}
          </a>
        </Typography>
      ),
    },
    {
      field: "owner",
      headerName: "Order Owner",
      flex: 0.5,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body">{params.row.owner?.name}</Typography>
          <Typography variant="caption">
            {params.row.owner?.phoneNumber}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: (params) => (
        <Chip
          label={params.row.statusName}
          variant="outlined"
          size="small"
          color={
            params.row.statusName === "Assigned"
              ? "success"
              : params.row.statusName === "Updated"
              ? "primary"
              : "error"
          }
        />
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 0.5,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body">
            {moment(params.row.createdAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="caption">
            {moment(params.row.createdAt).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
  ];

  return (
    <Dialog
      open={_Locker.lockerHistoryDialog}
      keepMounted
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Typography color="primary">Locker History</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box sx={{ height: { md: "calc(100vh - 270px)", xs: 1000 } }}>
                <DataGrid
                  sx={{
                    "& .css-204u17-MuiDataGrid-main": {
                      padding: "15px",
                    },
                    "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                      padding: "15px",
                    },
                  }}
                  columns={columns}
                  rows={lockerData?.data}
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={65}
                  pagination
                  paginationMode="server"
                  rowCount={lockerData.rowCount}
                  pageSize={searchBody.pageSize}
                  page={_PageNo}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    dispatch(
                      saveLockerHistorySearchBody({
                        pageSize: newPageSize,
                      })
                    )
                  }
                  rowsPerPageOptions={[5, 15, 25, 75, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default LockerHistoryDialog;
