import { Helmet } from "react-helmet";
import { Box, Card, CardHeader, Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { AiOutlineMobile } from "react-icons/ai";
import MobileAppVersionsList from "src/components/pagesContent/mobileAppVersion/MobileAppVersionContent";

const MobileAppVersion = () => (
  <>
    <Helmet>
      <title>Mobile App Version | Orderii</title>
    </Helmet>
    <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <Card>
            <CardHeader
              title={
                <Typography variant="h4" component="div">
                  Mobile App Version
                </Typography>
              }
              avatar={<AiOutlineMobile size={20} />}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <Box>
            <MobileAppVersionsList />
          </Box>
        </Grid>
      </Grid>
    </Box>
  </>
);

export default MobileAppVersion;
