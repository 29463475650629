import {
  Autocomplete,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Close } from "@mui/icons-material";
import moment from "moment";
import { Ticket_Priority, Ticket_Status } from "src/utils/common";
import { useDispatch, useSelector } from "react-redux";
import { saveSearchBody } from "src/actions/ticket/ticket";
import { getEmployee } from "src/actions/department/employee";
import DateRangepicker from "src/components/DateRangePicker";
import { getDepartment } from "src/actions/department/department";
import { BiSearchAlt2 } from "react-icons/bi";

export default function TicketSearchFilter({ pageNo = function () {} }) {
  const ticketSearchFilter = useSelector((state) => state.tickets.searchBody);
  const employeeData = useSelector((state) => state.employee.data);
  const departmentData = useSelector((state) => state.department.data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEmployee());
  }, []);

  useEffect(() => {
    dispatch(getDepartment());
  }, []);

  const [number, setNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  return (
    <Card>
      {!ticketSearchFilter ? null : (
        <CardContent style={{ paddingBottom: 15 }}>
          <div
            style={{
              maxWidth: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{ alignItems: "center", display: "flex" }}
            >
              <Grid item xs={3}>
                <DateRangepicker
                  startDate={ticketSearchFilter.from}
                  endDate={ticketSearchFilter.to}
                  handleOnChange={(searchDate) => {
                    const updatedFilter = {
                      ...ticketSearchFilter,
                      from: moment(searchDate[0].startDate).format(
                        "YYYY-MM-DD"
                      ),
                      to: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
                    };

                    if (
                      updatedFilter.from !== ticketSearchFilter.from ||
                      updatedFilter.to !== ticketSearchFilter.to
                    ) {
                      dispatch(saveSearchBody(updatedFilter));
                      pageNo(0);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  size="small"
                  value={number}
                  name="number"
                  label="Ticket Number"
                  onChange={(e) => {
                    setNumber(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      dispatch(
                        saveSearchBody({
                          ...ticketSearchFilter,
                          number: number,
                          phoneNo: phoneNumber,
                        })
                      );
                      pageNo(0);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  size="small"
                  value={phoneNumber}
                  name="number"
                  label="Phone Number"
                  onChange={(e) => {
                    var phoneNumber = e.target.value;
                    setPhoneNumber(
                      phoneNumber[0] == "0"
                        ? phoneNumber.substring(1)
                        : phoneNumber
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      dispatch(
                        saveSearchBody({
                          ...ticketSearchFilter,
                          number: number,
                          phoneNo: phoneNumber,
                        })
                      );
                      pageNo(0);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={departmentData}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Select Departmet" />
                  )}
                  onChange={(event, value) => {
                    dispatch(
                      saveSearchBody({
                        ...ticketSearchFilter,
                        departmentId: value ? value.id : "",
                        number: number,
                        phoneNo: phoneNumber,
                      })
                    );
                    pageNo(0);
                  }}
                  value={
                    departmentData.filter(
                      (x) => x.id == ticketSearchFilter.departmentId
                    )[0]
                      ? departmentData.filter(
                          (x) => x.id == ticketSearchFilter.departmentId
                        )[0]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={employeeData}
                  getOptionLabel={(option) => option.username}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Select Assignee" />
                  )}
                  onChange={(event, value) => {
                    dispatch(
                      saveSearchBody({
                        ...ticketSearchFilter,
                        assigneeId: value ? value.userId : "",
                        number: number,
                        phoneNo: phoneNumber,
                      })
                    );
                    pageNo(0);
                  }}
                  value={
                    employeeData.filter(
                      (x) => x.userId == ticketSearchFilter.assigneeId
                    )[0]
                      ? employeeData.filter(
                          (x) => x.userId == ticketSearchFilter.assigneeId
                        )[0]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={Ticket_Status}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Status" />
                  )}
                  onChange={(event, value) => {
                    dispatch(
                      saveSearchBody({
                        ...ticketSearchFilter,
                        status: value ? value.id : "",
                        number: number,
                        phoneNo: phoneNumber,
                      })
                    );
                    pageNo(0);
                  }}
                  value={
                    Ticket_Status.filter(
                      (x) => x.id == ticketSearchFilter.status
                    )[0]
                      ? Ticket_Status.filter(
                          (x) => x.id == ticketSearchFilter.status
                        )[0]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  options={Ticket_Priority}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Priority" />
                  )}
                  onChange={(event, value) => {
                    dispatch(
                      saveSearchBody({
                        ...ticketSearchFilter,
                        priority: value ? value.id : "",
                        number: number,
                        phoneNo: phoneNumber,
                      })
                    );
                    pageNo(0);
                  }}
                  value={
                    Ticket_Priority.filter(
                      (x) => x.id == ticketSearchFilter.priority
                    )[0]
                      ? Ticket_Priority.filter(
                          (x) => x.id == ticketSearchFilter.priority
                        )[0]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={1}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Tooltip title="Search with filter">
                    <IconButton
                      onClick={() => {
                        dispatch(
                          saveSearchBody({
                            ...ticketSearchFilter,
                            number: number,
                            phoneNo: phoneNumber,
                          })
                        );
                      }}
                    >
                      <BiSearchAlt2 />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Clear filter">
                    <IconButton
                      onClick={() => {
                        dispatch(
                          saveSearchBody({
                            number: "",
                            status: "",
                            assigneeId: "",
                            from: moment().format("2023-01-01"),
                            to: moment().format("YYYY-MM-DD"),
                            priority: "",
                            pageSize: 15,
                          })
                        );
                        pageNo(0);
                        setNumber("");
                        setPhoneNumber("");
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
            </Grid>
          </div>
          {/* </div> */}
        </CardContent>
      )}
    </Card>
  );
}
