import { useSelector } from "react-redux";

const useCanShow = (code) => {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  return _UserInfo.roleOEndpoint.map((data) => data.Code).includes(code)
    ? true
    : false;
};

export default useCanShow;
