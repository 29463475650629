import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { openDepartmentDialog } from "src/actions/department/department";
import ApartmentIcon from "@mui/icons-material/Apartment";
import RolePermission from "src/components/RolePermission";
import DepartmentForm from "src/pages/cross/department/DepartmentsConfig/Form";
import DepartmentList from "src/pages/cross/department/DepartmentsConfig/List";

const Departments = () => {
  const department = useSelector((state) => state.department);
  const dispatch = useDispatch();

  return (
    <>
      <Helmet>
        <title>Departments | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false} sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
          <Card>
            <CardHeader
              title={
                <Typography variant="h4" component="div">
                  Departments
                </Typography>
              }
              avatar={<ApartmentIcon size={20} />}
              action={
                <RolePermission permission="D1-01">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={() =>
                      dispatch(openDepartmentDialog(department.dialog))
                    }
                  >
                    Create Department
                  </Button>
                </RolePermission>
              }
            />
            {department.dialog ? <DepartmentForm /> : null}
          </Card>

          <Box sx={{ pt: 2 }}>
            <DepartmentList />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Departments;
