import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { BiCategoryAlt } from "react-icons/bi";
import RolePermission from "src/components/RolePermission";
import VendorsCategoryForm from "../../from/Form";
import { openVendorsCategoryDialog } from "src/actions/vendors";
import AssignVendorsCategoryForm from "src/pages/cross/vendors/VendorsCatagory/from/assignCategoryForm";

const VendorsCategoryHeader = () => {
  const dispatch = useDispatch();

  const _VendorsCategory = useSelector(
    (state) => state.vendorsCategory.openDialog
  );
  const _VendorsCategories = useSelector(
    (state) => state.vendorsCategory.openVendorCategoriesDialog
  );

  return (
    <>
      <Helmet>
        <title>Vendors Category | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Vendors Category
              </Typography>
            }
            avatar={<BiCategoryAlt size={20} />}
            action={
              <RolePermission permission="35-01">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => dispatch(openVendorsCategoryDialog())}
                >
                  CREATE
                </Button>
              </RolePermission>
            }
          />
          {_VendorsCategory ? <VendorsCategoryForm /> : null}
          {_VendorsCategories ? <AssignVendorsCategoryForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default VendorsCategoryHeader;
