import axios from "../axios";

class shippingOptionsService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (
          data[key] !== "" &&
          (data[key] !== null) & (data[key] !== undefined)
        ) {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/shippingoptions/${pageNo}?${params}`);
  }

  getByVendor(id) {
    return axios.get(`/shippingoptions/getall/${id}`);
  }

  getById(id) {
    return axios.get(`/shippingoptions/getById/${id}`);
  }

  getList(id) {
    return axios.get(`/shippingoptions/getById/${id}`);
  }

  create(data) {
    return axios.post(`/shippingoptions`, data);
  }

  update(id, data) {
    return axios.put(`/shippingoptions/${id}`, data);
  }

  delete(id) {
    return axios.delete(`/shippingoptions/${id}`);
  }
}
export default new shippingOptionsService();
