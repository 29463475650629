import axios from "../axios";

class UploadAttachmentsService {
  uploadAttachments(data) {
    return axios.post("/attachments", data);
  }

  uploadMultiAttachments(data) {
    return axios.post("/attachments/list", data);
  }
}
export default new UploadAttachmentsService();
