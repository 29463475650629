import VariantService from "src/api/giftServices/variant.service";
import helper from "src/utils/helper";
import uploadAttachmentsService from "src/api/services/uploadAttachments.service";
import {
  RESET_VARIANT_FORM,
  SAVE_VARIANT_SEARCH_BODY,
  SAVE_VARIANT_PAGE_NO,
  FIND_VARIANT_BY_SKU,
  RELOAD_VARIANT,
  GET_BY_ID_VARIANT,
  OPEN_VARIANT_DIALOG,
} from "src/actions/gifts/giftTypes";
import { UPLOAD_ATTACHMENTS } from "src/actions/type";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";

export const openVariantDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_VARIANT_DIALOG,
    payload: data,
  });
};

export const createVariant = (data) => async (dispatch) => {
  try {
    dispatch(reloadVariant());
    const res = await VariantService.createVariant(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(reloadVariant());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadVariant());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteVariant = (id) => async (dispatch) => {
  try {
    const res = await VariantService.deleteVariant(id);
    dispatch(showSuccessSnackbar("Gift category deleted successfully!"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const reloadVariant = () => (dispatch) => {
  dispatch({
    type: RELOAD_VARIANT,
  });
};

export const getVariantById = (id, openDialog) => async (dispatch) => {
  try {
    dispatch(reloadVariant());
    const res = await VariantService.getVariantById(id);
    dispatch({
      type: GET_BY_ID_VARIANT,
      payload: res.data.data,
    });
    if (openDialog) dispatch(openVariantDialog());
    dispatch(reloadVariant());
    // return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(reloadVariant());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const findVariantBySku = () => async (dispatch) => {
  try {
    dispatch(reloadVariant());
    const res = await VariantService.findVariantBySku();
    dispatch({
      type: FIND_VARIANT_BY_SKU,
      payload: res.data.data,
    });
    dispatch(reloadVariant());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadVariant());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const saveVariantPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_VARIANT_PAGE_NO,
    payload: data,
  });
};

export const saveVariantSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_VARIANT_SEARCH_BODY,
    payload: data,
  });
};

export const resetVariantForm = () => (dispatch) => {
  dispatch({
    type: RESET_VARIANT_FORM,
  });
};

export const updateVariant = (id, data) => async (dispatch) => {
  try {
    const res = await VariantService.updateVariant(id, data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const uploadAttachments = (data) => async (dispatch) => {
  try {
    dispatch(reloadVariant());
    const res = await uploadAttachmentsService.uploadAttachments(data);
    dispatch(showSuccessSnackbar("Uploaded successfully"));
    dispatch({
      type: UPLOAD_ATTACHMENTS,
      data: res.data,
    });
    dispatch(reloadVariant());
    // return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadVariant());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
