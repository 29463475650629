import axiosService from "src/api/axiosService";

class EPaymentsService {
  create(data) {
    return axiosService.post(`/api/epaymentmethod/add`, data);
  }

  getAll(type) {
    return axiosService.get(`/api/epaymentmethod/getall/${type}`);
  }

  update(data) {
    return axiosService.put(`/api/epaymentmethod/update`, data);
  }
  delete(id) {
    return axiosService.delete(`/api/epaymentmethod/delete?id=${id}`);
  }
}

export default new EPaymentsService();
