import helper from "src/utils/helper";
import {
  ITEM_OPTIONS_RESET_FORM,
  ITEM_OPTIONS_GET_BY_ID,
  ITEM_OPTIONS_PAGE_NUMBER,
  ITEM_OPTIONS_OPEN_DIALOG,
  ITEM_OPTIONS_RELOAD,
  ITEM_OPTIONS_GET_ALL_LIST,
  ITEM_OPTIONS_GET_ALL,
  ITEM_OPTIONS_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";
import itemOptionsService from "src/api/giftServices/itemOptions.service";

export const openItemOptionsDialog = () => (dispatch) => {
  dispatch({
    type: ITEM_OPTIONS_OPEN_DIALOG,
  });
};

export const createItemOption = (data, searchBody) => async (dispatch) => {
  try {
    const res = await itemOptionsService.createItemOptions(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(openItemOptionsDialog());
    dispatch(
      getAllItemOptions(searchBody.pageNo, searchBody.pageSize, searchBody.name)
    );
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteItemOptions = (id, searchBody) => async (dispatch) => {
  try {
    const res = await itemOptionsService.deleteItemOptions(id);
    dispatch(showSuccessSnackbar("Gift category deleted successfully!"));
    dispatch(
      getAllItemOptions(searchBody.pageNo, searchBody.pageSize, searchBody.name)
    );
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const reloadItemOptions = () => (dispatch) => {
  dispatch({
    type: ITEM_OPTIONS_RELOAD,
  });
};

export const getItemOptionsById = (id, openDialog) => async (dispatch) => {
  try {
    dispatch(reloadItemOptions());
    const res = await itemOptionsService.getItemOptionsById(id);
    dispatch({
      type: ITEM_OPTIONS_GET_BY_ID,
      payload: res.data.data,
    });
    if (openDialog) dispatch(openItemOptionsDialog());
    dispatch(reloadItemOptions());
    // return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(reloadItemOptions());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllItemOptions =
  (pageNO, pageSize, name) => async (dispatch) => {
    try {
      dispatch(reloadItemOptions());
      const res = await itemOptionsService.getItemOptionsAll(
        pageNO,
        pageSize,
        name
      );
      dispatch({
        type: ITEM_OPTIONS_GET_ALL,
        payload: res.data.data,
      });
      dispatch(reloadItemOptions());
      // return Promise.resolve(res.data.data)
    } catch (err) {
      dispatch(reloadItemOptions());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getItemOptionsList = () => async (dispatch) => {
  try {
    dispatch(reloadItemOptions());
    const res = await itemOptionsService.getItemOptionsList();
    dispatch({
      type: ITEM_OPTIONS_GET_ALL_LIST,
      payload: res.data.data,
    });
    dispatch(reloadItemOptions());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadItemOptions());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const saveItemOptionsPageNum = (data) => (dispatch) => {
  dispatch({
    type: ITEM_OPTIONS_PAGE_NUMBER,
    payload: data,
  });
};

export const saveItemOptionsSearchBody = (data) => (dispatch) => {
  dispatch({
    type: ITEM_OPTIONS_SEARCH_BODY,
    payload: data,
  });
};

export const resetItemOptionsForm = () => (dispatch) => {
  dispatch({
    type: ITEM_OPTIONS_RESET_FORM,
  });
};

export const updateItemOptions = (id, data, searchBody) => async (dispatch) => {
  try {
    const res = await itemOptionsService.updateItemOptions(id, data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    dispatch(openItemOptionsDialog());
    dispatch(
      getAllItemOptions(searchBody.pageNo, searchBody.pageSize, searchBody.name)
    );
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
