import { Grid, Box } from "@mui/material";
import React from "react";
import UserOrdersHeader from "./home/header/header";
import UserOrdersTable from "./home/table";

const UserOrders = () => {
  return (
    <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <UserOrdersHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <UserOrdersTable />
        </Grid>
      </Grid>
      <Box sx={{ paddingX: 2 }}></Box>
    </Box>
  );
};

export default UserOrders;
