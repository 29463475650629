import {
  CLOSE_ALERT_DIALOG,
  CLOSE_SNACKBAR,
  OPEN_ALERT_DIALOG,
  SHOW_SNACKBAR,
} from "../actions/type";

const initialState = {
  message: "",
  type: "success",
  show: false,
  dialog: false,
};

function messageReducer(messages = initialState, action) {
  const { type, payload } = action;

  // return messages;

  switch (type) {
    case SHOW_SNACKBAR:
      return payload;

    case OPEN_ALERT_DIALOG:
      return payload;

    case CLOSE_ALERT_DIALOG:
      return payload;

    case CLOSE_SNACKBAR:
      return initialState;

    default:
      return messages;
  }
}

export default messageReducer;
