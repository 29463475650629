import helper from "src/utils/helper";
import UnCompletedCartDataService from "src/api/services/uncompletedCarts.service";
import { showErrorSnackbar } from "src/actions/snackbarMessages";
import {
  GET_ALL_UNCOMPLETED_CART,
  UNCOMPLETED_CART_PAGE_NUMBER,
  UNCOMPLETED_CART_SEARCH_BODY,
  RELOAD_UNCOMPLETED_CART,
} from "src/actions/type";

export const reloadUnCompletedCart = (data) => (dispatch) => {
  dispatch({
    type: RELOAD_UNCOMPLETED_CART,
    payload: data,
  });
};

export const saveUsersUnCompletedCartPageNum = (data) => (dispatch) => {
  dispatch({
    type: UNCOMPLETED_CART_PAGE_NUMBER,
    payload: data,
  });
};

export const saveUsersUnCompletedCartSearchBody = (data) => (dispatch) => {
  dispatch({
    type: UNCOMPLETED_CART_SEARCH_BODY,
    payload: data,
  });
};

export const getAllUnCompletedCart =
  (pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadUnCompletedCart());
      const res = await UnCompletedCartDataService.getAll(pageNo, searchBody);
      dispatch({
        type: GET_ALL_UNCOMPLETED_CART,
        payload: res.data.data,
      });
      dispatch(reloadUnCompletedCart());
      // return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadUnCompletedCart());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else dispatch(showErrorSnackbar("Network error"));
    }
  };
