import axios from "../axios";

class BrandService {
  createGiftsBrand(data) {
    return axios.post("/giftbrand", data);
  }

  getGiftsBrandById(id) {
    return axios.get(`/giftbrand/getById/${id}`);
  }

  getGiftsBrandList() {
    return axios.get(`/giftbrand`);
  }

  getGiftsBrandAll(pageNO, pageSize, name) {
    return axios.get(
      `/giftbrand/${pageNO}?pageSize=${pageSize}&name=${name}`
    );
  }

  deleteGiftsBrand(id) {
    return axios.delete(`/giftbrand/${id}`);
  }

  updateGiftsBrand(id, data) {
    return axios.put(`/giftbrand/${id}`, data);
  }
}

export default new BrandService();
