import { Helmet } from "react-helmet";
import { Box, Button } from "@mui/material";
import { Card, CardHeader, Typography } from "@mui/material";
import { AiOutlineShoppingCart } from "react-icons/ai";
import AddIcon from "@mui/icons-material/Add";
import { openCartDialog } from "src/actions/cart";
import { useDispatch, useSelector } from "react-redux";
import RolePermission from "src/components/RolePermission";
import CartExternalItemForm from "src/pages/cross/cart/form/Form";

const CartHeader = () => {
  const dispatch = useDispatch();

  const _Cart = useSelector((state) => state.cart.openDialog);

  return (
    <>
      <Helmet>
        <title>Cart | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Cart
              </Typography>
            }
            avatar={<AiOutlineShoppingCart size={20} />}
            action={
              <RolePermission permission="17-03">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => dispatch(openCartDialog())}
                >
                  ADD EXTERNAL ITEM
                </Button>
              </RolePermission>
            }
          />
        </Card>
        {_Cart && <CartExternalItemForm />}
      </Box>
    </>
  );
};

export default CartHeader;
