import axiosService from "src/api/axiosService";

class Department {
  createDepartment(data) {
    return axiosService.post(`/department-service/api/department`, data);
  }

  getDepartment() {
    return axiosService.get(`/department-service/api/department`);
  }

  updateDepartment(id, data) {
    return axiosService.put(`/department-service/api/department/${id}`, data);
  }

  deleteDepartment(id) {
    return axiosService.delete(`/department-service/api/department/${id}`);
  }

  getByIdDepartment(id) {
    return axiosService.get(`/department-service/api/department/byid/${id}`);
  }


}

export default new Department();
