import GeneralSettingsService from "src/api/services/generalSettings.service";
import helper from "src/utils/helper";
import { openAlertDialog, showErrorSnackbar, showSuccessSnackbar } from "./snackbarMessages";
import {
  OPEN_GENERAL_SETTING_DEALOG,
  CREATE_GENERAL_SETTINGR,
  GET_ALL_GENERAL_SETTINGR,
  SAVE_SEARCH_BODY_GENERAL_SETTINGR,
  GET_BY_ID_GENERAL_SETTING,
  UPDATE_GENERAL_SETTINGR,
  DELETE_GENERAL_SETTINGR,
  RELOAD_GENERAL_SETTINGR,
  SAVE_SEARCH_PAGE_NO_GS,
  RESET_FORM_GS,
} from "./type";

export const addGeneralSetting =
  (data, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await GeneralSettingsService.addGeneralSetting(data);
      dispatch(showSuccessSnackbar("Created successfully"));
      dispatch({
        type: CREATE_GENERAL_SETTINGR,
        payload: res.data.data,
      });
      dispatch(getAllGeneralSettings(pageNo, searchBody));
      // return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const openGeneralSettingDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_GENERAL_SETTING_DEALOG,
    payload: data,
  });
};

export const reloadGeneralSettings = (data) => (dispatch) => {
  dispatch({
    type: RELOAD_GENERAL_SETTINGR,
    payload: data,
  });
};

export const getAllGeneralSettings = (pageNo, data) => async (dispatch) => {
  try {
    const res = await GeneralSettingsService.getGeneralSettingPageNo(
      pageNo,
      data
    );
    dispatch({
      type: GET_ALL_GENERAL_SETTINGR,
      payload: res.data.data,
    });
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_BODY_GENERAL_SETTINGR,
    payload: data,
  });
};

export const savePageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_PAGE_NO_GS,
    payload: data,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: RESET_FORM_GS,
  });
};

export const getGeneralSettingsById = (id) => async (dispatch) => {
  try {
    const res = await GeneralSettingsService.getGeneralSettingById(id);
    dispatch({
      type: GET_BY_ID_GENERAL_SETTING,
      payload: res.data.data,
    });
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const UpdateGeneralSettings =
  (data, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await GeneralSettingsService.UpdateGeneralSetting(data);
      dispatch({
        type: UPDATE_GENERAL_SETTINGR,
        payload: data,
      });
      dispatch(showSuccessSnackbar("Updated successfully"));
      dispatch(getAllGeneralSettings(pageNo, searchBody));
      // return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteGeneralSettings = (id) => async (dispatch) => {
  try {
    await GeneralSettingsService.deleteGeneralSetting(id);
    dispatch({
      type: DELETE_GENERAL_SETTINGR,
      payload: id,
    });
    dispatch(showSuccessSnackbar("Deleted successfully"));
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
