import {
  AGENT_CITY_CONFIG_RESET_FORM,
  AGENT_CITY_CONFIG_GET_BY_ID,
  AGENT_CITY_CONFIG_PAGE_NUMBER,
  AGENT_CITY_CONFIG_OPEN_DIALOG,
  AGENT_CITY_CONFIG_RELOAD,
  AGENT_CITY_CONFIG_GET_ALL_LIST,
  AGENT_CITY_CONFIG_GET_ALL,
  AGENT_CITY_CONFIG_SEARCH_BODY,
  AGENT_CITY_CONFIG_TRANSFER_LIST_OPEN_DIALOG,
  AGENT_CITY_CONFIG_TRANSFER_LIST_DATA,
  AGENT_CITY_CONFIG_AGENT_ID,
  AGENT_CITY_CONFIG_RELOAD_ADD_DELETE,
  AGENT_REQUEST_GET_ALL,
} from "../actions/type";

const initialState = {
  searchBody: {
    pageSize: 10,
  },
  reload: false,
  AddDeleteReload: false,
  openDialog: false,
  pageNo: 0,
  pageSize: 10,
  agentId: null,
  form: {
    id: null,
    agentId: null,
    cityIds: null,
  },
  agentCityConfigData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  agentCityConfigNewData: [],
  transferList: {
    dialog: false,
    data: [],
  },
  agentCityConfigDataList: [],
  getById: {},

  // ================== Agent Request Provinces ==================

  agentRequestProvinces: [],
};

function agentCityConfigReducer(agentCityConfig = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case AGENT_CITY_CONFIG_GET_ALL:
      const agentCityMap = new Map();
      payload.data?.map((item) => {
        const agentId = item.agentId;
        const assignedCity = {
          cityNameAR: item.cityNameAR,
          cityNameUS: item.cityNameUS,
          cityId: item.cityId,
        };
        if (agentCityMap.has(agentId)) {
          agentCityMap.get(agentId).assignedCities.push(assignedCity);
        } else {
          agentCityMap.set(agentId, {
            agentId: agentId,
            agentName: item.agentName,
            createdAt: item.createdAt,
            id: item.id,
            provinceNameAR: item.provinceNameAR,
            provinceNameUS: item.provinceNameUS,
            assignedCities: [assignedCity],
          });
        }
        return item;
      });
      agentCityConfig.agentCityConfigNewData = [...agentCityMap.values()];

      agentCityConfig.agentCityConfigData.data = payload.data;
      agentCityConfig.agentCityConfigData.total = payload.pageCount;
      agentCityConfig.agentCityConfigData.rowCount = payload.rowCount;
      return agentCityConfig;

    case AGENT_CITY_CONFIG_TRANSFER_LIST_DATA:
      agentCityConfig.transferList.data = payload.data;
      return agentCityConfig;

    case AGENT_CITY_CONFIG_AGENT_ID:
      agentCityConfig.agentId = payload;
      return agentCityConfig;

    case AGENT_CITY_CONFIG_GET_ALL_LIST:
      agentCityConfig.agentCityConfigDataList = payload;
      return agentCityConfig;

    case AGENT_CITY_CONFIG_GET_BY_ID:
      agentCityConfig.form.id = payload.id;
      agentCityConfig.form.agentId = payload.agentId;
      agentCityConfig.form.cityIds = payload.cityIds;
      return agentCityConfig;

    case AGENT_CITY_CONFIG_RESET_FORM:
      agentCityConfig.form.id = null;
      agentCityConfig.form.agentId = null;
      agentCityConfig.form.cityIds = null;
      return agentCityConfig;

    case AGENT_CITY_CONFIG_PAGE_NUMBER:
      return { ...agentCityConfig, pageNo: payload };

    case AGENT_CITY_CONFIG_SEARCH_BODY:
      return { ...agentCityConfig, searchBody: payload };

    case AGENT_CITY_CONFIG_OPEN_DIALOG:
      return {
        ...agentCityConfig,
        openDialog: !agentCityConfig.openDialog,
      };

    case AGENT_CITY_CONFIG_TRANSFER_LIST_OPEN_DIALOG:
      agentCityConfig.transferList.dialog =
        !agentCityConfig.transferList.dialog;
      return { ...agentCityConfig };

    case AGENT_CITY_CONFIG_RELOAD:
      return { ...agentCityConfig, reload: !agentCityConfig.reload };

    case AGENT_CITY_CONFIG_RELOAD_ADD_DELETE:
      return {
        ...agentCityConfig,
        AddDeleteReload: !agentCityConfig.AddDeleteReload,
      };

    case AGENT_REQUEST_GET_ALL:
      agentCityConfig.agentRequestProvinces = payload.data;
      return agentCityConfig;

    default:
      return agentCityConfig;
  }
}

export default agentCityConfigReducer;
