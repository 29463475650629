import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme,
} from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Icon, IconButton, Stack } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { DateRangePicker } from "rsuite";
import moment from "moment";
import { useState } from "react";
import DateRangepicker from "src/components/DateRangePicker";

ChartJS.register(ArcElement, Tooltip, Legend);

const TopUp = (props) => {
  const theme = useTheme();
  const [viewCalendar, setViewCalendar] = useState(false);
  const data = {
    datasets: [
      {
        data: props.data.data,
        label: 'Topup',
        backgroundColor: [
          colors.green[200],
          colors.blueGrey[200],
          colors.yellow[600],
          colors.blue[300],
          colors.orange[500],
          colors.red[600],
          colors.red[900],
        ],
        borderWidth: 2,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: props.data.labels,
    count: props.data.counts,
  };

  const options = {
    animation: true,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  const getTotalAmount = (data) => {
    var amount = 0
    if (data.data)
      amount = data.data.reduce((sum, a) => sum + a, 0);

    return amount
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title="TopUp"
        subheader={
          <Stack direction="column" spacing={1}>
            <Typography variant="caption">
              {props.data.fromDate + " - " + props.data.toDate}
            </Typography>
            {viewCalendar && (
              <Box sx={{ width: { md: '70%', xs: '100%' } }}>
                <DateRangepicker
                  startDate={props.data.fromDate}
                  endDate={props.data.toDate}
                  handleOnChange={(searchDate) => {
                    const updatedFilter = {
                      from: moment(searchDate[0].startDate).format('YYYY-MM-DD'),
                      to: moment(searchDate[0].endDate).format('YYYY-MM-DD')
                    };
                    if (
                      updatedFilter.from !== props.data.fromDate ||
                      updatedFilter.to !== props.data.toDate
                    ) {
                      props.onDateChanged({
                        from: moment(searchDate[0].startDate).format('YYYY-MM-DD'),
                        to: moment(searchDate[0].endDate).format('YYYY-MM-DD')
                      })
                    }
                  }}
                />
              </Box>
            )}
          </Stack>
        }
        action={
          <IconButton onClick={() => setViewCalendar(!viewCalendar)}>
            <Icon aria-label="settings">calendar_month</Icon>
          </IconButton>
        }
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: "relative",
          }}
        >
          <Doughnut data={data} options={options} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 2,
          }}
        >
          <Box
            sx={{
              pt: 1,
              textAlign: "center",
            }}
          >
            <Typography variant="h4">
              {`Total Amount: ${getTotalAmount(props.data).toLocaleString()} $`}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TopUp;
