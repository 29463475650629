import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Card, CardHeader, Typography } from "@mui/material";
import { FaStarHalfAlt } from "react-icons/fa";

const OrderRatingHeader = () => {
  return (
    <>
      <Helmet>
        <title>Order Rating | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Order Rating
              </Typography>
            }
            avatar={<FaStarHalfAlt size={20} />}
          />
        </Card>
      </Box>
    </>
  );
};

export default OrderRatingHeader;
