import GiftItemService from "src/api/giftServices/item.service";
import helper from "src/utils/helper";
import uploadAttachmentsService from "src/api/services/uploadAttachments.service";
import {
  GET_ALL_GIFTS_ITEM,
  GET_BY_ID_GIFTS_ITEM,
  GET_BY_ID_GIFTS_ITEM_UPDATE_AFTER_ORDER,
  GET_GIFTS_ITEM_VARIANTS,
  GET_PRODUCT_INFO,
  OPEN_BULK_UPLOAD_DIALOG,
  OPEN_GIFTS_ITEM_DIALOG,
  OPEN_PRODUCT_INFO_DIALOG,
  RELOAD_GIFTS_ITEM,
  RESET_BULK_UPLOAD_FORM,
  RESET_GIFTS_ITEM_AFTER_ORDER_FORM,
  RESET_GIFTS_ITEM_FORM,
  SAVE_GIFTS_ITEM_PAGE_NO,
  SAVE_GIFTS_ITEM_SEARCH_BODY,
  SAVE_SCROLL_POSITION,
  OPEN_UPDATE_MULTI_FILTER_COLLECTION_DIALOG,
  SELECTED_FILTER_COLLECTION_DATA,
  GIFT_ITEM_FILTER_DIALOG,
  GIFT_ITEM_SORT_DIALOG,
  GIFT_ITEM_PRINT_SKU,
  CHANGE_VIEW_TYPE,
  RELOAD_UPDATE_GIFTS_ITEM,
} from "src/actions/gifts/giftTypes";
import { UPLOAD_ATTACHMENTS } from "src/actions/type";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";

export const openGiftItemDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_GIFTS_ITEM_DIALOG,
  });
};

export const saveScrollPosition = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SCROLL_POSITION,
    payload: data,
  });
};

export const openProductInfoDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_PRODUCT_INFO_DIALOG,
  });
};

export const openBulkUploadDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_BULK_UPLOAD_DIALOG,
  });
};

export const openUpdateMultiFilterCollectionDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_UPDATE_MULTI_FILTER_COLLECTION_DIALOG,
  });
};

export const updateSelectedFilterCollectionData = (data) => (dispatch) => {
  dispatch({
    type: SELECTED_FILTER_COLLECTION_DATA,
    payload: data,
  });
};

export const openGiftItemFilterDialog = () => (dispatch) => {
  dispatch({
    type: GIFT_ITEM_FILTER_DIALOG,
  });
};

export const openGiftItemSortDialog = () => (dispatch) => {
  dispatch({
    type: GIFT_ITEM_SORT_DIALOG,
  });
};

export const resetBulkUploadForm = () => (dispatch) => {
  dispatch({
    type: RESET_BULK_UPLOAD_FORM,
  });
};

export const saveGiftItemPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_GIFTS_ITEM_PAGE_NO,
    payload: data,
  });
};

export const saveGiftItemSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_GIFTS_ITEM_SEARCH_BODY,
    payload: data,
  });
};

export const resetGiftItemForm = () => (dispatch) => {
  dispatch({
    type: RESET_GIFTS_ITEM_FORM,
  });
};

export const resetGiftItemEditAfterOrderForm = () => (dispatch) => {
  dispatch({
    type: RESET_GIFTS_ITEM_AFTER_ORDER_FORM,
  });
};

export const reloadGiftItem = () => (dispatch) => {
  dispatch({
    type: RELOAD_GIFTS_ITEM,
  });
};

export const reloadUpdateGiftItem = () => (dispatch) => {
  dispatch({
    type: RELOAD_UPDATE_GIFTS_ITEM,
  });
};

export const printSku = (data) => (dispatch) => {
  dispatch({
    type: GIFT_ITEM_PRINT_SKU,
    payload: data,
  });
};

export const changeViewType = (data) => (dispatch) => {
  dispatch({
    type: CHANGE_VIEW_TYPE,
    payload: data,
  });
};

export const createGiftItems = (data, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadUpdateGiftItem());
    const res = await GiftItemService.createGiftItem(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    // dispatch(getAllGiftItem(searchBody.pageNo, searchBody));
    dispatch(reloadUpdateGiftItem());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadUpdateGiftItem());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const bulkUpload =
  (id, data, pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadGiftItem());
      const res = await GiftItemService.bulkUpload(id, data);
      dispatch(showSuccessSnackbar("Created successfully"));
      dispatch(getAllGiftItem(pageNo + 1, searchBody));
      dispatch(reloadGiftItem());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadGiftItem());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteGiftItem = (id, pageNo, searchBody) => async (dispatch) => {
  try {
    const res = await GiftItemService.deleteGiftItem(id);
    dispatch(showSuccessSnackbar("Gift category deleted successfully!"));
    dispatch(getAllGiftItem(pageNo, searchBody));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getGiftItemById = (id, openDialog) => async (dispatch) => {
  try {
    dispatch(reloadGiftItem());
    const res = await GiftItemService.getGiftItemById(id);
    dispatch({
      type: GET_BY_ID_GIFTS_ITEM,
      payload: res.data.data,
    });
    // if (openDialog) dispatch(openGiftItemDialog());
    dispatch(reloadGiftItem());
    return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(reloadGiftItem());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getProductInfo = (id) => async (dispatch) => {
  try {
    dispatch(reloadGiftItem());
    const res = await GiftItemService.getGiftItemById(id);
    dispatch({
      type: GET_PRODUCT_INFO,
      payload: res.data.data,
    });
    dispatch(openProductInfoDialog());
    dispatch(reloadGiftItem());
  } catch (err) {
    dispatch(reloadGiftItem());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getGiftItemByIdForEditAfterOrder = (id) => async (dispatch) => {
  try {
    dispatch(reloadGiftItem());
    const res = await GiftItemService.getGiftItemById(id);
    dispatch({
      type: GET_BY_ID_GIFTS_ITEM_UPDATE_AFTER_ORDER,
      payload: res.data.data,
    });
    dispatch(reloadGiftItem());
  } catch (err) {
    dispatch(reloadGiftItem());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllGiftItem = (pageNO, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadGiftItem());
    const res = await GiftItemService.getGiftItemAll(pageNO, searchBody);
    dispatch({
      type: GET_ALL_GIFTS_ITEM,
      payload: res.data.data,
    });
    dispatch(reloadGiftItem());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadGiftItem());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getGiftItemVariants = (id) => async (dispatch) => {
  try {
    dispatch(reloadGiftItem());
    const res = await GiftItemService.getGiftItemVariants(id);
    dispatch({
      type: GET_GIFTS_ITEM_VARIANTS,
      payload: res.data.data,
    });
    dispatch(reloadGiftItem());
    return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(reloadGiftItem());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateGiftItem = (id, data, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadUpdateGiftItem());
    const res = await GiftItemService.updateGiftItem(id, data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    // dispatch(
    //   getAllGiftItem(searchBody.pageNo, searchBody.pageSize, searchBody.name)
    // );
    dispatch(reloadUpdateGiftItem());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadUpdateGiftItem());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateGiftItemAfterOrder = (id, data) => async (dispatch) => {
  try {
    dispatch(reloadGiftItem());
    const res = await GiftItemService.updateGiftItemAfterOrder(id, data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    dispatch(reloadGiftItem());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(reloadGiftItem());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const uploadAttachments = (data) => async (dispatch) => {
  try {
    dispatch(reloadUpdateGiftItem());
    const res = await uploadAttachmentsService.uploadAttachments(data);
    dispatch(showSuccessSnackbar("Uploaded successfully"));
    dispatch({
      type: UPLOAD_ATTACHMENTS,
      data: res.data,
    });
    dispatch(reloadUpdateGiftItem());
    // return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadUpdateGiftItem());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateLockerNumber =
  (id, data, pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadGiftItem());
      const res = await GiftItemService.updateLockerNumber(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllGiftItem(pageNo, searchBody));
      dispatch(reloadGiftItem());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadGiftItem());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const updateMultiFilterCollection = (data) => async (dispatch) => {
  try {
    dispatch(reloadGiftItem());
    const res = await GiftItemService.updateMultiFilterCollection(data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    dispatch(reloadGiftItem());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadGiftItem());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const changeGiftItemStatus =
  (id, data, pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadGiftItem());
      const res = await GiftItemService.changeGiftItemStatus(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllGiftItem(pageNo, searchBody));
      dispatch(reloadGiftItem());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadGiftItem());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };
