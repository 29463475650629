import { Autocomplete, Grid, Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import {
  assignVendorCategories,
  openVendorsCategoriesDialog,
} from "src/actions/vendors";
import { useState } from "react";

const AssignVendorsCategoryForm = () => {
  const dispatch = useDispatch();

  const vendors = useSelector((state) => state.vendors.dataList);
  const loading = useSelector((state) => state.vendors.reload);
  const vendorsCategory = useSelector((state) => state.vendorsCategory.form);
  const dialog = useSelector(
    (state) => state.vendorsCategory.openVendorCategoriesDialog
  );
  const searchBody = useSelector((state) => state.vendorsCategory.searchBody);
  const pageNo = useSelector(
    (state) => state.vendorsCategory.saveVendorsCategoryPageNum
  );

  const [selectedVendors, setSelectedVendors] = useState(
    vendorsCategory?.vendorIds ?? []
  );

  const handleDialog = () => {
    dispatch(openVendorsCategoriesDialog());
  };

  const handleUpdate = () => {
    dispatch(
      assignVendorCategories(
        vendorsCategory?.id,
        {
          vendorsIds: selectedVendors,
        },
        {
          pageNo: pageNo + 1,
          pageSize: searchBody.pageSize,
          name: searchBody.name,
        }
      )
    ).then((x) => {
      handleDialog();
    });
  };

  return (
    <CustomDialog
      title={"ASSIGN CATEGORY TO VENDORS"}
      isOpen={dialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="vendors"
            options={vendors || []}
            getOptionLabel={(option) => option.name}
            fullWidth
            size="small"
            renderInput={(params) => <TextField {...params} label="Vendors" />}
            onChange={(event, value) => {
              var ids = value.map((x) => x.id);
              setSelectedVendors(value ? ids : []);
            }}
            value={vendors?.filter((x) =>
              selectedVendors ? selectedVendors.includes(x.id) : null
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack
            sx={{ marginTop: 2 }}
            direction="row"
            spacing={2}
            justifyContent="end"
            alignItems="center"
          >
            <LoadingButton
              variant="contained"
              onClick={handleUpdate}
              color="primary"
              loading={loading}
            >
              Save
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};
export default AssignVendorsCategoryForm;
