import { Box, Icon, Typography } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { CustomDialog } from "src/components/CustomDialog";
import { getOrderStatusHistories } from "src/actions/gifts/orders";

export default function TimelineDialog(props) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(
    () =>
      props.orderId && props.open
        ? dispatch(getOrderStatusHistories(props.orderId)).then((res) =>
            setData(res?.data)
          )
        : null,
    [dispatch, props.open, props.orderId]
  );

  return (
    <CustomDialog
      isOpen={props.open}
      handleClose={props.handleClose}
      title="Order timeline"
      maxWidth="md"
    >
      <Box>
        <Timeline position="alternate">
          {data?.map((status, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {moment(status.createdAt).format("yyyy-MM-DD HH:mm:ss")}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  color={status.status === "Canceled" ? "error" : "success"}
                >
                  <Icon>
                    {status.status === "Canceled" ? "close" : "check"}
                  </Icon>
                </TimelineDot>
                {status.id !== 2 ? <TimelineConnector /> : <></>}
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h5" component="span">
                  {status.status}
                </Typography>
                <Typography variant="body2">{status.userName}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    </CustomDialog>
  );
}
