import {
  GET_ALL_INVOICES,
  GET_INVOICE_DETAILS,
  GET_NEW_ORDERS,
  GET_NEW_ORDER_DETAILS
} from "../actions/type";

const initialState = {
  newOrders: {
    data: {},
    details: {items:[]}
  },
  invoices: {
    data: {},
    details: {items:[]}
  },
  // data: null,
  // pageCount: 0,
  // rowCount: 0,
  // invoiceDetails: {
  //   items: []
  // }
};

function inventoryReducer(inventory = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_NEW_ORDERS:
      inventory.newOrders.data = payload
      return { ...inventory };

    case GET_NEW_ORDER_DETAILS:
      inventory.newOrders.details = payload
      return { ...inventory };

    case GET_ALL_INVOICES:
      inventory.invoices.data = payload
      return { ...inventory };

    // case GET_ALL_INVOICES:
    //   var _invoices={
    //     data: payload.data,
    //     pageCount: payload.pageCount,
    //     rowCount: payload.rowCount,
    //     searchBody: inventory.searchBody,
    //     invoiceDetails: {
    //       items: []
    //     }
    //   }
    //   return _invoices;



    // case GET_INVOICE_DETAILS:
    //   inventory.invoiceDetails = payload.data
    //   return inventory;

    default:
      return inventory;
  }
}

export default inventoryReducer;