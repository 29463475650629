import { Grid, Box } from "@mui/material";
import React from "react";
import DynamicAuthTable from "./home/table/List";
import DynamicAuthHeader from "./home/header/header";

const DynamicAuth = () => {
  return (
    <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12}>
          <DynamicAuthHeader />
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: 2 }}>
          <DynamicAuthTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DynamicAuth;
