import { Helmet } from "react-helmet";
import {
  Box,
  Card,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import ProfileSettings from "../components/pagesContent/profile/ProfileSettings";
import { CgProfile } from "react-icons/cg";

const ProfileSettingsView = () => (
  <>
    <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <Helmet>
            <title>Settings | Orderii</title>
          </Helmet>

          <Box
            sx={{
              backgroundColor: "background.default",
              minHeight: "100%",
            }}
          >
            <Card>
              <CardHeader
                title={
                  <Typography variant="h4" component="div">
                    Profile
                  </Typography>
                }
                avatar={<CgProfile size={20} />}
              />
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <ProfileSettings />
        </Grid>
      </Grid>
    </Box>
  </>
);

export default ProfileSettingsView;
