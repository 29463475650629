import React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImagePreview);

const FilePondInput = ({
  required = false,
  allowMultiple = false,
  maxFiles,
  allowImagePreview,
  onupdatefiles,
  onBlur,
  files,
  name,
}) => {
  return (
    <FilePond
      required={required}
      name={name}
      allowMultiple={allowMultiple}
      files={files}
      credits={false}
      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      onupdatefiles={onupdatefiles}
      onBlur={onBlur}
      maxFiles={maxFiles}
      allowImagePreview={allowImagePreview}
    />
  );
};

export default FilePondInput;
