import { Grid, Box } from "@mui/material";
import React from "react";
import ItemCancelReasonsTable from "./home/table";
import ItemCancelReasonsHeader from "./home/header";

const ItemCancelReasons = () => {
  return (
    <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12}>
          <ItemCancelReasonsHeader />
        </Grid>
        <Grid item xs={12} sx={{ py: 2 }}>
          <ItemCancelReasonsTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ItemCancelReasons;
