import {
  GET_ALL_ORDERS,
  SAVE_SEARCH_BODY_ORDERS,
  UPDATE_ORDER_STATUS,
  SAVE_PAGE_NUM,
  GET_ORDER_DETAILS,
  RELOAD_ORDER_ORDER_DETAILS,
  GET_USER_ORDERS,
} from "../actions/type";

const initialState = {
  orderDetailsReload: false,
  data: null,
  pageCount: 0,
  rowCount: 0,
  searchBody: {
    number: "",
    itemName: "",
    phoneNumber: "",
    trackingNumber: "",
    from: null,
    to: null,
    status: null,
    region: null,
    hasAdditionalFees: null,
    vendorIds: null,
    pageSize: 15,
  },
  orderDetails: {
    items: [],
  },
  userOrdersData: {},
};

function orderReducer(orders = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_ORDERS:
      var _orders = {
        data: payload.data,
        pageCount: payload.pageCount,
        rowCount: payload.rowCount,
        searchBody: orders.searchBody,
        savePageNum: orders.savePageNum,
        userOrders: orders.userOrders,
        orderDetails: {
          items: [],
        },
      };
      return _orders;

    case GET_USER_ORDERS:
      orders.userOrdersData = payload;
      return orders;

    case RELOAD_ORDER_ORDER_DETAILS:
      orders.orderDetailsReload = !orders.orderDetailsReload;
      return { ...orders };

    case SAVE_SEARCH_BODY_ORDERS:
      return { ...orders, searchBody: payload };

    case SAVE_PAGE_NUM:
      orders.savePageNum = payload;
      return orders;

    case UPDATE_ORDER_STATUS:
      var indexToChange = orders.data.indexOf(
        orders.data.filter((x) => x.id === payload.id)[0]
      );
      orders.data[indexToChange].status = payload.newStatus;
      return orders;

    case GET_ORDER_DETAILS:
      orders.orderDetails = payload.data;
      return orders;

    default:
      return orders;
  }
}

export default orderReducer;
