import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getGoIpCredit } from "src/actions/goip";
import moment from "moment";
import { MdAttachMoney, MdDateRange } from "react-icons/md";

const GoIp = (props) => {
  const dispatch = useDispatch();
  const { creditBalance } = useSelector((state) => state.goIp);

  useEffect(() => {
    dispatch(getGoIpCredit());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={1}>
      <Grid item md={3} xs={12}>
        <Card sx={{ height: "100%" }} {...props}>
          <CardContent>
            <Grid
              container
              spacing={1}
              sx={{ justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  ASIA CELL BALANCE
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {creditBalance
                    ? creditBalance.asiaCell?.balance?.toLocaleString()
                    : ""}{" "}
                  IQD
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    <Typography variant="h3">
                      {creditBalance.asiaCell?.message}
                    </Typography>
                  }
                >
                  <Avatar
                    sx={{
                      backgroundColor: "#E9292F",
                      height: 56,
                      width: 56,
                      padding: 1,
                    }}
                  >
                    <MdAttachMoney size={30} />
                  </Avatar>
                </Tooltip>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={3} xs={12}>
        <Card sx={{ height: "100%" }} {...props}>
          <CardContent>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  ASIA CELL EXPIRY DATE
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {creditBalance
                    ? moment(creditBalance.asiaCell?.expireAt).format(
                        "YYYY-MM-DD"
                      )
                    : ""}{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar
                  sx={{
                    backgroundColor: "#E9292F",
                    height: 56,
                    width: 56,
                  }}
                >
                  <MdDateRange size={30} />
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={3} xs={12}>
        <Card sx={{ height: "100%" }} {...props}>
          <CardContent>
            <Grid
              container
              spacing={1}
              sx={{ justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  ZAIN BALANCE
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {creditBalance.zain?.balance
                    ? creditBalance.zain?.balance?.toLocaleString() + " IQD"
                    : "Not available"}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    <Typography variant="h3">
                      {creditBalance.zain?.message}
                    </Typography>
                  }
                >
                  <Avatar
                    sx={{
                      backgroundColor: "#6C55A7",
                      height: 56,
                      width: 56,
                      padding: 1,
                    }}
                  >
                    <MdAttachMoney size={30} />
                  </Avatar>
                </Tooltip>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={3} xs={12}>
        <Card sx={{ height: "100%" }} {...props}>
          <CardContent>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography color="textSecondary" gutterBottom variant="h6">
                  ZAIN EXPIRY DATE
                </Typography>
                <Typography color="textPrimary" variant="h3">
                  {creditBalance.zain?.expireAt
                    ? moment(creditBalance.zain?.expireAt).format("YYYY-MM-DD")
                    : "Not available"}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar
                  sx={{
                    backgroundColor: "#6C55A7",
                    height: 56,
                    width: 56,
                  }}
                >
                  <MdDateRange size={30} />
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default GoIp;
