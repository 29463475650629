import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { regionPromoVendorRevenue } from "src/actions/reports";
import CustomSpinner from "src/components/CustomSpinner";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

export default function RegionPromoVendorRevenue(props) {
  const dispatch = new useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [region, setRegion] = useState("");
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    regionPromoVendorrevenue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date, region, props.setSearch]);

  function regionPromoVendorrevenue() {
    setLoading(true);
    dispatch(
      regionPromoVendorRevenue({
        startDate: props.date.startDate,
        endDate: props.date.endDate,
        region: region,
      })
    ).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }
  const newData = data
    .filter((x) => x.region != null)
    .map((el, index) => {
      return el.vendors;
    });

  function CustomToolbar() {
    return (
      <Box>
        <Box>
          <Card>
            <CardHeader
              title="Region Promo Vendor Revenue Report"
              subheader={
                <Stack direction="column" spacing={1}>
                  <Typography variant="caption">
                    {props.date.startDate + " - " + props.date.endDate}
                  </Typography>
                </Stack>
              }
            />
            <CardContent>
              <Grid container>
                <Grid item xs={3}>
                  <Autocomplete
                    sx={{ widows: "25%" }}
                    options={["TR", "US", "EU", "AG", "WSM"]}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Region" />
                    )}
                    onChange={(event, value) => {
                      setRegion(value ?? "");
                    }}
                    value={region}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
          </Card>
        </Box>
        <Box>
          <GridToolbarContainer>
            <GridToolbarExport
              csvOptions={{
                fileName: `Region Promo Vendor Revenue Report|| Orderii || ${
                  props.date.startDate + " - " + props.date.endDate
                }`,
                utf8WithBom: true,
              }}
            />
          </GridToolbarContainer>
        </Box>
      </Box>
    );
  }

  const columns = [
    {
      field: "#",
      flex: 0.5,
    },
    {
      field: "vendorName",
      flex: 1,
      headerName: "Vendor Name",
    },
    {
      field: "totalRevenue",
      flex: 1,
      headerName: "Total Revenue",
    },
    {
      field: "region",
      flex: 1,
      headerName: "Region",
    },
  ];
  return (
    <Card style={{ marginTop: 10 }}>
      <PerfectScrollbar>
        <Box>
          {loading ? (
            <CustomSpinner />
          ) : (
            <Box sx={{ height: "calc(100vh - 320px)" }}>
              <DataGrid
                sx={{
                  "& .MuiDataGrid-row ": {
                    textAlign: "center",
                  },
                  "& .css-204u17-MuiDataGrid-main": {
                    paddingLeft: "15px",
                  },
                  "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                    paddingLeft: "15px",
                  },
                }}
                columns={columns}
                rows={newData.flat().map((el, index) => {
                  return {
                    id: index + 1,
                    "#": index + 1,
                    vendorName: el.vendorName,
                    totalRevenue: `${el.totalRevenue} $`,
                    region: !region ? "All" : region,
                  };
                })}
                components={{
                  Toolbar: CustomToolbar,
                }}
                pageSize={pageSize}
                disableSelectionOnClick
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[25, 100, 200]}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
