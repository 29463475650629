import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  TextField,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  InputAdornment,
} from "@mui/material";
import {
  getAllVendorsCategory,
  deleteVendorsCategory,
  getVendorsCategoryById,
  saveVendorsCategoryPageNum,
  saveVendorsCategorySearchBody,
  resetVendorsCategoryForm,
  getAllVendors,
  openVendorsCategoriesDialog,
} from "src/actions/vendors";
import RolePermission from "src/components/RolePermission";
import { DeleteForever } from "@mui/icons-material";
import { AiOutlineSearch } from "react-icons/ai";
import IconButton from "@mui/material/IconButton";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import { CustomDialog } from "src/components/CustomDialog";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { MdCategory } from "react-icons/md";

const VendorsCategoryTable = () => {
  const dispatch = useDispatch();
  const vendorsCategoryData = useSelector(
    (state) => state.vendorsCategory.data
  );
  const vendorsCategory = useSelector((state) => state.vendorsCategory);
  const loading = useSelector((state) => state.vendorsCategory.reload);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(0);
  const searchBody = useSelector((state) => state.vendorsCategory.searchBody);
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    dispatch(
      getAllVendorsCategory(page + 1, searchBody.pageSize, searchBody.name)
    );
  }, [page, dispatch, searchBody]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    dispatch(saveVendorsCategoryPageNum(newPage));
  };

  function deleteSelectedVendorCategory(id) {
    dispatch(
      deleteVendorsCategory(id, {
        pageNo: page + 1,
        pageSize: searchBody.pageSize,
        name: searchName,
      })
    ).then((x) => {
      dispatch(resetVendorsCategoryForm());
      setOpenDeleteDialog(false);
    });
  }

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "nameEn",
      headerName: "Name English",
      flex: 1,
    },
    {
      field: "nameAr",
      headerName: "Name Arabic",
      flex: 1,
    },
    {
      field: "nameKu",
      headerName: "Name Kurdish",
      flex: 1,
    },
    {
      field: "imageUrl",
      headerName: "Icon",
      flex: 1,
      renderCell: (params) =>
        params.row.imageUrl ? (
          <Box component="img" src={params.row.imageUrl} width={90} />
        ) : null,
    },
    {
      field: "Operations",
      headerName: "Operations",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <RolePermission permission="35-07">
            <Tooltip title="Update Category" aria-label="edit">
              <IconButton
                color="success"
                aria-label="edit"
                onClick={(e) => {
                  dispatch(getVendorsCategoryById(params.row.id)).then(() => {
                    dispatch(getAllVendors()).then(() => {
                      dispatch(openVendorsCategoriesDialog());
                    });
                  });
                }}
              >
                <MdCategory />
              </IconButton>
            </Tooltip>
          </RolePermission>
          <RolePermission permission="35-06">
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={(e) => {
                dispatch(getVendorsCategoryById(params.row.id, true));
              }}
            >
              <Tooltip title="Edit" aria-label="edit">
                <EditIcon />
              </Tooltip>
            </IconButton>
          </RolePermission>
          <RolePermission permission="35-02">
            <IconButton
              color="error"
              aria-label="delete"
              onClick={(e) => {
                dispatch(getVendorsCategoryById(params.row.id, false)).then(
                  (x) => setOpenDeleteDialog(true)
                );
              }}
            >
              <Tooltip title="Delete" aria-label="delete">
                <DeleteForever />
              </Tooltip>
            </IconButton>
          </RolePermission>
        </Box>
      ),
    },
  ];

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
              dispatch(resetVendorsCategoryForm());
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            maxWidth="sm"
            onDelete={() =>
              deleteSelectedVendorCategory(vendorsCategory.form.id)
            }
            selectedObject={`${vendorsCategory.form.nameEn}`}
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveVendorsCategorySearchBody({
                      ...searchBody,
                      name: searchName,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  setSearchName("");
                  dispatch(
                    saveVendorsCategorySearchBody({
                      ...searchBody,
                      name: "",
                      pageSize: 15,
                    })
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box sx={{ height: { md: "calc(100vh - 360px)", xs: 1000 } }}>
                <DataGrid
                  sx={{
                    "& .css-204u17-MuiDataGrid-main": {
                      padding: "15px",
                    },
                    "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                      padding: "15px",
                    },
                    ".highlight": {
                      backgroundColor: "#bdbdbd",
                      "&:hover": {
                        backgroundColor: "darkgrey",
                      },
                    },
                  }}
                  columns={columns}
                  rows={vendorsCategoryData?.data || []}
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={100}
                  pagination
                  paginationMode="client"
                  rowCount={vendorsCategoryData.rowCount}
                  pageSize={searchBody.pageSize}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    dispatch(
                      saveVendorsCategorySearchBody({
                        ...searchBody,
                        pageSize: newPageSize,
                      })
                    )
                  }
                  rowsPerPageOptions={[5, 15, 25, 75, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default VendorsCategoryTable;
