import { Helmet } from "react-helmet";
import { Box, Stack } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RolePermission from "src/components/RolePermission";
import { useNavigate } from "react-router";
import { FaGift, FaList } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import BulkUpload from "src/pages/gifts/item/from/bulkUpload";
import {
  changeViewType,
  openBulkUploadDialog,
} from "src/actions/gifts/giftItem";
import { RiFileExcel2Line } from "react-icons/ri";
import { IoGrid } from "react-icons/io5";

const GiftItemsHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const _OpenBulkUploadDialog = useSelector(
    (state) => state.giftItems.openBulkUploadDialog
  );
  const _ViewType = useSelector((state) => state.giftItems.viewType);

  return (
    <>
      <Helmet>
        <title>Products | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Products
              </Typography>
            }
            avatar={<FaGift size={20} />}
            action={
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="medium"
                  startIcon={_ViewType === "grid" ? <FaList /> : <IoGrid />}
                  onClick={() => {
                    dispatch(
                      changeViewType(_ViewType === "list" ? "grid" : "list")
                    );
                  }}
                >
                  {_ViewType === "list" ? "Grid View" : "List View"}
                </Button>
                <RolePermission permission="41-10">
                  <Button
                    variant="contained"
                    color="success"
                    size="medium"
                    startIcon={<RiFileExcel2Line />}
                    onClick={() => {
                      dispatch(openBulkUploadDialog());
                    }}
                  >
                    Bulk Upload
                  </Button>
                </RolePermission>
                <RolePermission permission="41-01">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      navigate("/app/gifts/items/add_new_item");
                    }}
                  >
                    Create
                  </Button>
                </RolePermission>
              </Stack>
            }
          />
          {_OpenBulkUploadDialog && <BulkUpload />}
        </Card>
      </Box>
    </>
  );
};

export default GiftItemsHeader;
