import axios from "../axios";

class RatingQuestionsService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`/ratingquestion/${pageNo}?${params}`);
  }

  getList() {
    return axios.get(`/ratingquestion/list`);
  }

  getById(id) {
    return axios.get(`/ratingquestion/getbyid/${id}`);
  }

  create(data) {
    return axios.post("/ratingquestion", data);
  }

  update(id, data) {
    return axios.put(`/ratingquestion/${id}`, data);
  }

  delete(id) {
    return axios.delete(`/ratingquestion/${id}`);
  }

  getAllOrderRating(pageNo, data, type) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`/orderrating/${pageNo}?isGiftOrder=${type}&${params}`);
  }
}

export default new RatingQuestionsService();
