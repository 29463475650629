import React, { useState, useEffect, useCallback } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
  Button,
  CardContent,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { CustomDialog } from "../../CustomDialog";
import CustomSpinner from "../../CustomSpinner";
import AddIcon from "@mui/icons-material/Add";
import CustomText from "../../CustomText";
import { useDispatch, useSelector } from "react-redux";
import {
  createExpenseType,
  getAllExpenseTypesList,
  updateExpenseType,
  deleteExpenseType,
  getById,
} from "../../../actions/expenseTypes";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function ExpenseTypesList() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [isAdd, setisAdd] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openExpenseTypeDialog, setOpenExpenseTypeDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loading, setloading] = useState(false);

  const [expenseType, setExpenseType] = useState({
    name: "",
  });

  const expenseTypes = useSelector((state) => state.expenseTypes);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllExpenseTypesList());
  }, [refresh]);

  // const errorAlert = (msg) =>
  //   setAlert({ open: true, message: msg, type: "error" });
  // const successAlert = (msg) =>
  //   setAlert({ open: true, message: msg, type: "success" });

  function clearTexts() {
    setExpenseType({
      ...expenseType,
      name: "",
    });
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleExpenseTypeDialogOpen = () => {
    setOpenExpenseTypeDialog(true);
  };

  const handleExpenseTypeDialogClose = () => {
    setOpenExpenseTypeDialog(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setExpenseType({ ...expenseType, [name]: value });
  };

  function addExpenseType() {
    setloading(true);
    dispatch(createExpenseType(expenseType))
      .then((res) => {
        setRefresh(!refresh);
        setloading(false);
        setOpenExpenseTypeDialog(false);
      })
      .catch((err) => {
        setloading(false);
      });
  }

  function updateSelectedExpenseType() {
    setloading(true);
    dispatch(updateExpenseType(expenseType))
      .then((res) => {
        setRefresh(!refresh);
        setloading(false);
        setOpenExpenseTypeDialog(false);
      })
      .catch((err) => {
        setloading(false);
      });
  }

  function getExpenseTypeById(id) {
    clearTexts();
    dispatch(getById(id)).then((res) => {
      setExpenseType(res.data);
    });
  }

  function deleteExpenseTypeCode(id) {
    setOpenDeleteDialog(false);
    dispatch(deleteExpenseType(id));
  }

  const expenseTypeToolbar = () => {
    return (
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteExpenseTypeCode(expenseType.id)}
            selectedObject={`this expenseType ${expenseType.name}`}
          >
            You will delete the expenseType with name <b>{expenseType.name}</b>
          </CustomDialog>
        )}
        <CustomDialog
          isOpen={openExpenseTypeDialog}
          handleClose={handleExpenseTypeDialogClose}
          title={isAdd ? "CREATE EXPENSE TYPE" : "UPDATE EXPENSE TYPE"}
          maxWidth="md"
          isRequierdActions={true}
          isRequierdAdd={isAdd ? true : false}
          isRequierdEdit={isAdd ? false : true}
          onAdd={addExpenseType}
          onEdit={updateSelectedExpenseType}
          onClear={clearTexts}
          loading={loading}
        >
          <form noValidate autoComplete="off">
            <Box>
              <CustomText
                fullWidth
                label="Name"
                name="name"
                value={expenseType.name}
                onChange={handleInputChange}
              />
            </Box>
          </form>
        </CustomDialog>
        <Box>
          <Card>
            <CardContent style={{ paddingBottom: 15 }}>
              <div
                style={{
                  maxWidth: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => {
                      setisAdd(true);
                      clearTexts();
                      handleExpenseTypeDialogOpen();
                    }}
                    startIcon={<AddIcon />}
                  >
                    Create Expense Type
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </Box>
      </Box>
    );
  };

  return (
    <Card>
      {expenseTypeToolbar()}
      {/* <CustomizedSnackbars
        open={alert.open}
        type={alert.type}
        message={alert.message}
        onClose={() =>
          setAlert((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
      /> */}
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {!expenseTypes ? (
            <CustomSpinner />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Operations</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expenseTypes.slice(0, limit).map((expenseType) => (
                  <TableRow hover key={expenseType.id}>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {expenseType.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        aria-label="edit"
                        onClick={(e) => {
                          setisAdd(false);
                          getExpenseTypeById(expenseType.id);
                          handleExpenseTypeDialogOpen();
                        }}
                      >
                        <Tooltip title="Edit" aria-label="edit">
                          <EditIcon />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        color="error"
                        aria-label="delete"
                        onClick={(e) => {
                          getExpenseTypeById(expenseType.id);
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <Tooltip title="Delete" aria-label="delete">
                          <DeleteForeverIcon />
                        </Tooltip>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={expenseTypes ? expenseTypes.length : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10]}
      />
    </Card>
  );
}
