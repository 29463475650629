import axios from "../axios";

class VendorService {
  getAll() {
    return axios.get(`/Vendor/All`);
  }

  getById(id) {
    return axios.get(`/Vendor/getById/${id}`);
  }

  getAllFixed() {
    return axios.get(`/Vendor/allexternal`);
  }

  getAllList() {
    return axios.get(`/Vendor/alllist`);
  }

  create(data) {
    return axios.post("/Vendor/create", data);
  }

  update(data) {
    return axios.put(`/Vendor/update`, data);
  }

  updateProperties(data) {
    return axios.put(`/Vendor/updateproperties`, data);
  }

  changeStatus(id) {
    return axios.put(`/Vendor/changestatus?id=${id}`);
  }

  changetranslationstatus(id) {
    return axios.put(`/Vendor/changetranslationstatus?id=${id}`);
  }

  uploadFile(data) {
    return axios.post(`/Vendor/upload`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  delete(id) {
    return axios.delete(`/Vendor/delete/${id}`);
  }

  getAllVendors() {
    return axios.get(`/Vendor/allvendors`);
  }

  updateVendorCategory(id, data) {
    return axios.put(`/Vendor/categories/${id}`, data);
  }

  ////////VendorsCategory///////////////
  createVendorsCategory(data) {
    return axios.post("/vendorcategory/create", data);
  }

  getVendorsCategoryById(id) {
    return axios.get(`/vendorcategory/getById/${id}`);
  }

  getVendorsCategoryList() {
    return axios.get(`/vendorcategory/list`);
  }

  getVendorsCategoryAll(pageNO, pageSize, name) {
    return axios.get(
      `/vendorcategory/all/${pageNO}?pageSize=${pageSize}&name=${name}`
    );
  }

  deleteVendorsCategory(id) {
    return axios.delete(`/vendorcategory/delete/${id}`);
  }

  updateVendorsCategory(id, data) {
    return axios.put(`/vendorcategory/update/${id}`, data);
  }

  assignVendorCategory(id, data) {
    return axios.put(`/vendorcategory/updatevendors/${id}`, data);
  }
}

export default new VendorService();
