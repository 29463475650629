import axios from "../axios";

class BannerService {
  getAll(type, pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`/advertisementbanner/all/${type}/${pageNo}?${params}`);
  }

  getById(id) {
    return axios.get(`/advertisementbanner/getById/${id}`);
  }

  create(data) {
    return axios.post("/advertisementbanner/create", data);
  }

  update(data) {
    return axios.put(`/advertisementbanner/update`, data);
  }

  delete(id) {
    return axios.delete(`/advertisementbanner/delete/${id}`);
  }

  changeStatus(id) {
    return axios.put(`/advertisementbanner/changestatus?id=${id}`);
  }

  uploadAttachment(data) {
    return axios.post(`/advertisementbanner/uploadAttachment`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}

export default new BannerService();
