import helper from "src/utils/helper";
import CartDataService from "src/api/giftServices/cart.service";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";
import {
  GET_ALL_GIFT_CART,
  GET_BY_ID_GIFT_CART,
  GIFT_CART_PAGE_NUMBER,
  GIFT_CART_SEARCH_BODY,
  OPEN_GIFT_CART_DIALOG,
  RELOAD_GIFT_CART,
  RESET_FORM_GIFT_CART,
} from "src/actions/gifts/giftTypes";

export const openCartDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_GIFT_CART_DIALOG,
    payload: data,
  });
};

export const reloadCart = (data) => (dispatch) => {
  dispatch({
    type: RELOAD_GIFT_CART,
    payload: data,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: RESET_FORM_GIFT_CART,
  });
};

// export const updateCart = (data) => async (dispatch) => {
//   try {
//     const res = await CartDataService.update(data);
//     dispatch(showSuccessSnackbar("Updated successfully!"));
//     return Promise.resolve(res.data);
//   } catch (err) {
//     if (err.message.includes(401)) helper.logout();
//     if (err.response.status === 401)
//       dispatch(showErrorSnackbar("Token expire"));
//     else if (err.response.status === 400)
//       dispatch(showErrorSnackbar(err.response.data.message));
//     else if (err.response.status === 500)
//       dispatch(showErrorSnackbar(err.response.data.message));
//     else if (err.response.status === 403)
//       dispatch(
//         openAlertDialog("You do not have role permission for this action")
//       );
//     else dispatch(showErrorSnackbar("Network error"));
//   }
// };

export const getAllGiftCart = (pageNo, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadCart());
    const res = await CartDataService.getAll(pageNo, searchBody);
    dispatch({
      type: GET_ALL_GIFT_CART,
      payload: res.data.data,
    });
    dispatch(reloadCart());
    // return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadCart());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getCartById = (id) => async (dispatch) => {
  try {
    dispatch(reloadCart());
    const res = await CartDataService.getById(id);
    dispatch({
      type: GET_BY_ID_GIFT_CART,
      payload: res.data.data,
    });
    dispatch(reloadCart());
    // return Promise.resolve(res.data);
  } catch (err) {
    dispatch({
      type: GET_BY_ID_GIFT_CART,
      payload: [],
    });
    dispatch(reloadCart());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateCallHistory = (id) => async (dispatch) => {
  try {
    dispatch(reloadCart());
    await CartDataService.updateCallHistory(id);
    dispatch(getCartById(id));
    dispatch(reloadCart());
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const saveUsersCartPageNum = (data) => (dispatch) => {
  dispatch({
    type: GIFT_CART_PAGE_NUMBER,
    payload: data,
  });
};

export const saveUsersCartSearchBody = (data) => (dispatch) => {
  dispatch({
    type: GIFT_CART_SEARCH_BODY,
    payload: data,
  });
};

export const clearGiftCart = (cartId) => async (dispatch) => {
  try {
    dispatch(reloadCart());
    const res = await CartDataService.clearGiftCart(cartId);
    dispatch(reloadCart());
    dispatch(showSuccessSnackbar("Clear Cart Completed"));
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadCart());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteGiftCartItem =
  (cartId, itemId, options) => async (dispatch) => {
    try {
      dispatch(reloadCart());
      const res = await CartDataService.deleteGiftCartItem(
        cartId,
        itemId,
        options
      );
      dispatch(reloadCart());
      dispatch(showSuccessSnackbar("Clear Cart Completed"));
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadCart());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };
