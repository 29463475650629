export const OPEN_GIFTS_CATEGORY_DIALOG = "OPEN_GIFTS_CATEGORY_DIALOG";
export const GET_BY_ID_GIFTS_CATEGORY = "GET_BY_ID_GIFTS_CATEGORY";
export const RELOAD_GIFTS_CATEGORY = "RELOAD_GIFTS_CATEGORY";
export const GET_GIFTS_CATEGORY_LIST = "GET_GIFTS_CATEGORY_LIST";
export const GET_ALL_GIFTS_CATEGORY = "GET_ALL_GIFTS_CATEGORY";
export const SAVE_GIFTS_CATEGORY_PAGE_NO = "SAVE_GIFTS_CATEGORY_PAGE_NO";
export const SAVE_GIFTS_CATEGORY_SEARCH_BODY =
  "SAVE_GIFTS_CATEGORY_SEARCH_BODY";
export const RESET_GIFTS_CATEGORY_FORM = "RESET_GIFTS_CATEGORY_FORM";

export const ITEM_OPTIONS_GET_ALL = "ITEM_OPTIONS_GET_ALL";
export const ITEM_OPTIONS_GET_ALL_LIST = "ITEM_OPTIONS_GET_ALL_LIST";
export const ITEM_OPTIONS_RELOAD = "ITEM_OPTIONS_RELOAD";
export const ITEM_OPTIONS_OPEN_DIALOG = "ITEM_OPTIONS_OPEN_DIALOG";
export const ITEM_OPTIONS_PAGE_NUMBER = "ITEM_OPTIONS_PAGE_NUMBER";
export const ITEM_OPTIONS_GET_BY_ID = "ITEM_OPTIONS_GET_BY_ID";
export const ITEM_OPTIONS_RESET_FORM = "ITEM_OPTIONS_RESET_FORM";
export const ITEM_OPTIONS_SEARCH_BODY = "ITEM_OPTIONS_SEARCH_BODY";

export const OPEN_GIFTS_ITEM_DIALOG = "OPEN_GIFTS_ITEM_DIALOG";
export const OPEN_PRODUCT_INFO_DIALOG = "OPEN_PRODUCT_INFO_DIALOG";
export const GET_BY_ID_GIFTS_ITEM = "GET_BY_ID_GIFTS_ITEM";
export const GET_PRODUCT_INFO = "GET_PRODUCT_INFO";
export const GET_BY_ID_GIFTS_ITEM_UPDATE_AFTER_ORDER =
  "GET_BY_ID_GIFTS_ITEM_UPDATE_AFTER_ORDER";
export const RELOAD_GIFTS_ITEM = "RELOAD_GIFTS_ITEM";
export const RELOAD_UPDATE_GIFTS_ITEM = "RELOAD_UPDATE_GIFTS_ITEM";
export const GET_GIFTS_ITEM_LIST = "GET_GIFTS_ITEM_LIST";
export const GET_GIFTS_ITEM_VARIANTS = "GET_GIFTS_ITEM_VARIANTS";
export const GET_ALL_GIFTS_ITEM = "GET_ALL_GIFTS_ITEM";
export const SAVE_GIFTS_ITEM_PAGE_NO = "SAVE_GIFTS_ITEM_PAGE_NO";
export const SAVE_GIFTS_ITEM_SEARCH_BODY = "SAVE_GIFTS_ITEM_SEARCH_BODY";
export const RESET_GIFTS_ITEM_FORM = "RESET_GIFTS_ITEM_FORM";
export const RESET_GIFTS_ITEM_AFTER_ORDER_FORM =
  "RESET_GIFTS_ITEM_AFTER_ORDER_FORM";
export const RESET_BULK_UPLOAD_FORM = "RESET_BULK_UPLOAD_FORM";
export const OPEN_BULK_UPLOAD_DIALOG = "OPEN_BULK_UPLOAD_DIALOG";
export const OPEN_UPDATE_MULTI_FILTER_COLLECTION_DIALOG =
  "OPEN_UPDATE_MULTI_FILTER_COLLECTION_DIALOG";
export const SELECTED_FILTER_COLLECTION_DATA =
  "SELECTED_FILTER_COLLECTION_DATA";
export const SAVE_SCROLL_POSITION = "SAVE_SCROLL_POSITION";
export const GIFT_ITEM_FILTER_DIALOG = "GIFT_ITEM_FILTER";
export const GIFT_ITEM_SORT_DIALOG = "GIFT_ITEM_SORT_DIALOG";
export const GIFT_ITEM_PRINT_SKU = "GIFT_ITEM_PRINT_SKU";
export const CHANGE_VIEW_TYPE = "CHANGE_VIEW_TYPE";

export const OPEN_VARIANT_DIALOG = "OPEN_VARIANT_DIALOG";
export const GET_BY_ID_VARIANT = "GET_BY_ID_VARIANT";
export const RELOAD_VARIANT = "RELOAD_VARIANT";
export const FIND_VARIANT_BY_SKU = "FIND_VARIANT_BY_SKU";
export const SAVE_VARIANT_PAGE_NO = "SAVE_VARIANT_PAGE_NO";
export const SAVE_VARIANT_SEARCH_BODY = "SAVE_VARIANT_SEARCH_BODY";
export const RESET_VARIANT_FORM = "RESET_VARIANT_FORM";

export const GET_ALL_WISH_CARD = "GET_ALL_WISH_CARD";
export const OPEN_WISH_CARD_DIALOG = "OPEN_WISH_CARD_DIALOG";
export const GET_BY_ID_WISH_CARD = "GET_BY_ID_WISH_CARD";
export const RELOAD_WISH_CARD = "RELOAD_WISH_CARD";
export const FIND_WISH_CARD_BY_SKU = "FIND_WISH_CARD_BY_SKU";
export const SAVE_WISH_CARD_PAGE_NO = "SAVE_WISH_CARD_PAGE_NO";
export const SAVE_WISH_CARD_SEARCH_BODY = "SAVE_WISH_CARD_SEARCH_BODY";
export const RESET_WISH_CARD_FORM = "RESET_WISH_CARD_FORM";

export const GET_ALL_WRAPPING = "GET_ALL_WRAPPING";
export const OPEN_WRAPPING_DIALOG = "OPEN_WRAPPING_DIALOG";
export const GET_BY_ID_WRAPPING = "GET_BY_ID_WRAPPING";
export const RELOAD_WRAPPING = "RELOAD_WRAPPING";
export const FIND_WRAPPING_BY_SKU = "FIND_WRAPPING_BY_SKU";
export const SAVE_WRAPPING_PAGE_NO = "SAVE_WRAPPING_PAGE_NO";
export const SAVE_WRAPPING_SEARCH_BODY = "SAVE_WRAPPING_SEARCH_BODY";
export const RESET_WRAPPING_FORM = "RESET_WRAPPING_FORM";

export const GET_BY_ID_ORDERS = "GET_BY_ID_ORDERS";
export const OPEN_COD_DIALOG = "OPEN_COD_DIALOG";
export const RELOAD_ORDERS = "RELOAD_ORDERS";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const SAVE_ORDERS_PAGE_NO = "SAVE_ORDERS_PAGE_NO";
export const SAVE_ORDERS_SEARCH_BODY = "SAVE_ORDERS_SEARCH_BODY";

export const ITEM_SUPPLIER_GET_ALL = "ITEM_SUPPLIER_GET_ALL";
export const ITEM_SUPPLIER_GET_ALL_LIST = "ITEM_SUPPLIER_GET_ALL_LIST";
export const ITEM_SUPPLIER_RELOAD = "ITEM_SUPPLIER_RELOAD";
export const ITEM_SUPPLIER_OPEN_DIALOG = "ITEM_SUPPLIER_OPEN_DIALOG";
export const ITEM_SUPPLIER_PAGE_NUMBER = "ITEM_SUPPLIER_PAGE_NUMBER";
export const ITEM_SUPPLIER_GET_BY_ID = "ITEM_SUPPLIER_GET_BY_ID";
export const ITEM_SUPPLIER_RESET_FORM = "ITEM_SUPPLIER_RESET_FORM";
export const ITEM_SUPPLIER_SEARCH_BODY = "ITEM_SUPPLIER_SEARCH_BODY";

export const OPEN_GIFTS_BRAND_DIALOG = "OPEN_GIFTS_BRAND_DIALOG";
export const GET_BY_ID_GIFTS_BRAND = "GET_BY_ID_GIFTS_BRAND";
export const RELOAD_GIFTS_BRAND = "RELOAD_GIFTS_BRAND";
export const GET_GIFTS_BRAND_LIST = "GET_GIFTS_BRAND_LIST";
export const GET_ALL_GIFTS_BRAND = "GET_ALL_GIFTS_BRAND";
export const SAVE_GIFTS_BRAND_PAGE_NO = "SAVE_GIFTS_BRAND_PAGE_NO";
export const SAVE_GIFTS_BRAND_SEARCH_BODY = "SAVE_GIFTS_BRAND_SEARCH_BODY";
export const RESET_GIFTS_BRAND_FORM = "RESET_GIFTS_BRAND_FORM";

export const EVENTS_GET_ALL = "EVENTS_GET_ALL";
export const EVENTS_RELOAD = "EVENTS_RELOAD";
export const EVENTS_PAGE_NUMBER = "EVENTS_PAGE_NUMBER";
export const EVENTS_SEARCH_BODY = "EVENTS_SEARCH_BODY";
export const EVENTS_OPEN_DIALOG = "EVENTS_OPEN_DIALOG";
export const EVENTS_RESET_FORM = "EVENTS_RESET_FORM";
export const EVENTS_GET_BY_ID = "EVENTS_GET_BY_ID";

export const USER_ADDRESSES_GET_ALL = "USER_ADDRESSES_GET_ALL";
export const USER_ADDRESSES_RELOAD = "USER_ADDRESSES_RELOAD";
export const USER_ADDRESSES_PAGE_NUMBER = "USER_ADDRESSES_PAGE_NUMBER";
export const USER_ADDRESSES_SEARCH_BODY = "USER_ADDRESSES_SEARCH_BODY";

export const FAVORITE_GET_ALL = "FAVORITE_GET_ALL";
export const FAVORITE_RELOAD = "FAVORITE_RELOAD";
export const FAVORITE_PAGE_NUMBER = "FAVORITE_PAGE_NUMBER";
export const FAVORITE_SEARCH_BODY = "FAVORITE_SEARCH_BODY";

export const RELOAD_GIFT_CART = "RELOAD_GIFT_CART";
export const OPEN_GIFT_CART_DIALOG = "OPEN_GIFT_CART_DIALOG";
export const GET_ALL_GIFT_CART = "GET_ALL_GIFT_CART";
export const GET_BY_ID_GIFT_CART = "GET_BY_ID_GIFT_CART";
export const RESET_FORM_GIFT_CART = "RESET_FORM_GIFT_CART";
export const GIFT_CART_PAGE_NUMBER = "GIFT_CART_PAGE_NUMBER";
export const GIFT_CART_SEARCH_BODY = "GIFT_CART_SEARCH_BODY";

export const GET_GIFT_DASHBOARD_ALL_COUNTS = "GET_GIFT_DASHBOARD_ALL_COUNTS";
export const GET_GIFT_DASHBOARD_ORDER_PER_STATUS =
  "GET_GIFT_DASHBOARD_ORDER_PER_STATUS";
export const GET_GIFT_DASHBOARD_ORDER_PER_PROVINCE =
  "GET_GIFT_DASHBOARD_ORDER_PER_PROVINCE";

export const OPEN_GIFT_COLLECTIONS_DIALOG = "OPEN_GIFT_COLLECTIONS_DIALOG";
export const GET_BY_ID_GIFT_COLLECTIONS = "GET_BY_ID_GIFT_COLLECTIONS";
export const RELOAD_GIFT_COLLECTIONS = "RELOAD_GIFT_COLLECTIONS";
export const GET_GIFT_COLLECTIONS_LIST = "GET_GIFT_COLLECTIONS_LIST";
export const GET_ALL_GIFT_COLLECTIONS = "GET_ALL_GIFT_COLLECTIONS";
export const SAVE_GIFT_COLLECTIONS_PAGE_NO = "SAVE_GIFT_COLLECTIONS_PAGE_NO";
export const SAVE_GIFT_COLLECTIONS_SEARCH_BODY =
  "SAVE_GIFT_COLLECTIONS_SEARCH_BODY";
export const RESET_GIFT_COLLECTIONS_FORM = "RESET_GIFT_COLLECTIONS_FORM";

export const OPEN_CATEGORY_BRANDS_DIALOG = "OPEN_CATEGORY_BRANDS_DIALOG";
export const RELOAD_CATEGORY_BRANDS = "RELOAD_CATEGORY_BRANDS";
export const GET_ALL_CATEGORY_BRANDS = "GET_ALL_CATEGORY_BRANDS";
export const SAVE_CATEGORY_BRANDS_SEARCH_BODY =
  "SAVE_CATEGORY_BRANDS_SEARCH_BODY";
export const RESET_CATEGORY_BRANDS_FORM = "RESET_CATEGORY_BRANDS_FORM";
