import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, LinearProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { CustomDialog } from "src/components/CustomDialog";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import {
  deleteProhibitedItems,
  getAllProhibitedItems,
  getByIdForUpdate,
  saveProhibitedItemsPageNum,
  saveProhibitedItemsSearchBody,
} from "src/actions/prohibitedItems";
import useCanShow from "src/components/CanShow";

export default function ProhibitedItemsTable() {
  const dispatch = useDispatch();

  const editRole = useCanShow("32-02");
  const deleteRole = useCanShow("32-03");

  const _ProhibitedItems = useSelector(
    (state) => state.prohibitedItems.prohibitedItemsData
  );
  const _ProhibitedItem = useSelector((state) => state.prohibitedItems);
  const loading = useSelector((state) => state.prohibitedItems.reload);
  const pageNo = useSelector((state) => state.prohibitedItems.pageNo);
  const _SearchBody = useSelector((state) => state.prohibitedItems.searchBody);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteDialogData, setOpenDeleteDialogData] = useState("");

  useEffect(() => {
    dispatch(getAllProhibitedItems(1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  const handlePageChange = (newPage) => {
    dispatch(saveProhibitedItemsPageNum(newPage + 1));
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(
      saveProhibitedItemsSearchBody({
        ..._SearchBody,
        pageSize: newPageSize,
      })
    );
  };

  function deleteOrderReasonCode(id) {
    dispatch(deleteProhibitedItems(id, pageNo)).then((res) => {
      setOpenDeleteDialog(false);
    });
  }
  const orderReasonToolbar = () => {
    return (
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteOrderReasonCode(openDeleteDialogData.id)}
            selectedObject={`${openDeleteDialogData.nameAR} - ${openDeleteDialogData.nameEN}`}
          />
        )}
      </Box>
    );
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "nameEn",
      headerName: "Name English",
      flex: 1,
    },
    {
      field: "nameAr",
      headerName: "Name Arabic",
      flex: 1,
    },
    {
      field: "nameKu",
      headerName: "Name Kurdish",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          showInMenu
          hidden={!editRole}
          onClick={(e) => {
            dispatch(getByIdForUpdate(params.row.id));
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          showInMenu
          hidden={!deleteRole}
          onClick={(e) => {
            setOpenDeleteDialog(true);
            setOpenDeleteDialogData(params.row);
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      {orderReasonToolbar()}
      <PerfectScrollbar>
        <Box sx={{ height: { md: "calc(100vh - 310px)", xs: 1000 } }}>
          <DataGrid
            sx={{
              "& .css-204u17-MuiDataGrid-main": {
                px: 3,
              },
              "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                px: 3,
              },
            }}
            columns={columns}
            rows={
              _ProhibitedItems.data?.map((data, index) => {
                return { ...data, index: index + 1 };
              }) || []
            }
            loading={loading}
            disableSelectionOnClick
            rowHeight={50}
            pagination
            paginationMode="server"
            rowCount={_ProhibitedItems?.rowCount}
            pageSize={_SearchBody.pageSize}
            page={_ProhibitedItem.pageNo - 1}
            onPageChange={(newPage) => handlePageChange(newPage)}
            onPageSizeChange={(newPageSize) => handleLimitChange(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
