import { LOGIN_AUTH } from "./type";

import AuthDataService from "../api/services/auth.service";
import { showErrorSnackbar } from "./snackbarMessages";

export const login = (data) => async (dispatch) => {
  try {
    const res = await AuthDataService.login(data);
    dispatch({
      type: LOGIN_AUTH,
      payload: res.data.data,
    });
    return Promise.resolve(res.data.data);
  } catch (err) {
    // if (err.message.includes(401)) helper.logout()
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else dispatch(showErrorSnackbar("Network error"));
    return Promise.reject(err);
  }
};

export const getIp = () => async () => {
  try {
    const res = await AuthDataService.getIp();
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const loginStaffAuth = (data) => async (dispatch) => {
  try {
    const res = await AuthDataService.loginStaffAuth(data);
    // dispatch({
    //   type: LOGIN_AUTH,
    //   payload: res.data.data,
    // });
    return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(showErrorSnackbar(err.response.data.message));
    if (err.message.includes(401))
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else dispatch(showErrorSnackbar("Network error"));
    return Promise.reject(err);
  }
};

export const validateStaffAuth = (data) => async (dispatch) => {
  try {
    const res = await AuthDataService.validateStaffAuth(data);
    dispatch({
      type: LOGIN_AUTH,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(showErrorSnackbar(err.response.data.message));
    if (err.message.includes(401))
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else dispatch(showErrorSnackbar("Network error"));
    return Promise.reject(err);
  }
};
