import axios from "../axios";

class AgentCityConfigService {
  getAllList(agentId) {
    const agent = agentId ? `&agentId=${agentId}` : "";
    return axios.get(`/agentcityconfig/list?${agent}`);
  }
  getAll(pageNo, pagesize, agentId) {
    const agent = agentId ? `&agentId=${agentId}` : "";
    return axios.get(
      `/agentcityconfig/all/${pageNo}?pagesize=${pagesize}${agent}`
    );
  }
  getById(id) {
    return axios.get(`/agentcityconfig/getbyid${id}`);
  }

  create(data) {
    return axios.post("/agentcityconfig/create", data);
  }

  delete(id) {
    return axios.delete(`/agentcityconfig/delete/${id}`);
  }

  getAllAgentRequestProvinces(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/requestagentprovinces/list?${params}`);
  }

  updateAgentRequestProvincesActivation(id) {
    return axios.put(`/requestagentprovinces/${id}`);
  }
}
export default new AgentCityConfigService();
