import departmentEmployeeService from "src/api/services/department/departmentEmployee.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../snackbarMessages";
import {
  CREATE_DEPARTMENTEMPLOYEE,
  GET_ALL_DEPARTMENTEMPLOYEE,
  DELETE_DEPARTMENTEMPLOYEE,
  ERROR_DEPARTMENTEMPLOYEE,
} from "../type";

export const createDepartmentEmployee =
  (data, departmentId) => async (dispatch) => {
    try {
      const res = await departmentEmployeeService.createDepartmentEmployee(
        data
      );
      dispatch({
        type: CREATE_DEPARTMENTEMPLOYEE,
        payload: res.data.data,
      });
      dispatch(getDepartmentEmployee(data.departmentId));
      dispatch(showSuccessSnackbar("Department Employee Created successfully"));
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getDepartmentEmployee = (departmentId) => async (dispatch) => {
  try {
    const res = await departmentEmployeeService.getDepartmentEmployee(
      departmentId
    );
    dispatch({
      type: GET_ALL_DEPARTMENTEMPLOYEE,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: ERROR_DEPARTMENTEMPLOYEE,
      payload: err,
    });
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const handelError = (error) => async (dispatch) => {
  dispatch({
    type: ERROR_DEPARTMENTEMPLOYEE,
    payload: error,
  });
};

export const deleteDepartmentEmployee =
  (departmentId, employeeId) => async (dispatch) => {
    try {
      const res = await departmentEmployeeService.deleteDepartmentEmployee(
        departmentId,
        employeeId
      );
      dispatch({
        type: DELETE_DEPARTMENTEMPLOYEE,
        payload: res.data.data,
      });
      dispatch(showSuccessSnackbar("Department Employee deleted successfully"));
      dispatch(getDepartmentEmployee(departmentId));
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };
