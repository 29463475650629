import React, { useState, useEffect } from "react";
import { Box, Card, CardHeader, Divider } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import { totalRevenuPromoVendor } from "src/actions/reports";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";

export default function TotalRevenuePromoVendor(props) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [totalRevenue, setTotalRevenue] = useState([]);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    setLoading(true);
    dispatch(totalRevenuPromoVendor("")).then((res) => {
      setTotalRevenue(res.data ?? []);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setSearch]);

  const data = totalRevenue?.map((x) =>
    x.vendors.map((i) => {
      return { ...i, promoCodeName: x?.promoCodeName, promoId: x?.promoId };
    })
  );

  function CustomToolbar() {
    return (
      <div>
        <Box>
          <Box>
            <Card>
              <CardHeader title="Total Revenue Promo Vendor Report" />
              <Divider />
            </Card>
          </Box>
          <Box>
            <GridToolbarContainer>
              <GridToolbarExport
                csvOptions={{
                  fileName: "Total Revenue Promo Vendor Report || Orderii",
                  utf8WithBom: true,
                }}
              />
            </GridToolbarContainer>
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <Card style={{ marginTop: 10 }} ref={props.print}>
      <PerfectScrollbar>
        <Box sx={{ height: "calc(100vh - 320px)" }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-row ": {
                textAlign: "center",
              },
              "& .css-204u17-MuiDataGrid-main": {
                paddingLeft: "15px",
              },
              "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                paddingLeft: "15px",
              },
            }}
            disableSelectionOnClick={true}
            columns={[
              { field: "#", headerName: "#" },
              {
                field: "promoCodeName",
                headerName: "Promo Code Name",
                width: 150,
              },
              {
                field: "vendorName",
                headerName: "Vendor Name",
                width: 300,
              },
              {
                field: "total",
                headerName: "Total ($)",
                width: 300,
              },
            ]}
            rows={data
              .flat()
              .filter((x) => x.promoCodeName != null)
              .map((x, index) => {
                return {
                  id: index,
                  "#": index + 1,
                  promoCodeName: x.promoCodeName,
                  vendorName: x.vendorName,
                  total: x.total,
                };
              })}
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            pageSize={pageSize}
            loading={loading}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[25, 75, 100]}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
