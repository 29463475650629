
import axios from "../axios";

class ExpenseTypeService {
  getAllList(pageNo) {
    return axios.get(`/expensetype/list`);
  }

  getAll(pageNo) {
    return axios.get(`/expensetype/all/${pageNo}`);
  }

  getById(id) {
    return axios.get(`/expensetype/${id}`);
  }

  create(data) {
    return axios.post("/expensetype/", data);
  }

  update(data) {
    return axios.put(`/expensetype/`, data);
  }

  delete(id) {
    return axios.delete(`/expensetype/${id}`);
  }

}
export default new ExpenseTypeService()