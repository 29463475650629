import {
  GET_ALL_GO_IP,
  GET_CREDIT_GO_IP,
  OPEN_GO_IP_DIALOG,
  RELOAD_GO_IP,
  SAVE_GO_IP_SAVE_NO,
  SAVE_GO_IP_SEARCH_BODY,
} from "./type";

import GoIpService from "../api/services/goip.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const createGoIp = (data, pagNo, searchBody) => async (dispatch) => {
  try {
    const res = await GoIpService.sendMessage(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(openDialog());
    dispatch(getAllGoIp(pagNo, searchBody));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllGoIp = (pageNo, pageSize) => async (dispatch) => {
  try {
    dispatch(reloadGoIp());
    const res = await GoIpService.getAll(pageNo, pageSize);
    dispatch({
      type: GET_ALL_GO_IP,
      payload: res.data.data,
    });
    dispatch(reloadGoIp());
  } catch (err) {
    dispatch(reloadGoIp());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const openDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_GO_IP_DIALOG,
  });
};

export const reloadGoIp = () => (dispatch) => {
  dispatch({
    type: RELOAD_GO_IP,
  });
};

export const saveGoIpPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_GO_IP_SAVE_NO,
    payload: data,
  });
};

export const saveGoIpSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_GO_IP_SEARCH_BODY,
    payload: data,
  });
};

export const getGoIpCredit = (id) => async (dispatch) => {
  try {
    dispatch(reloadGoIp());
    const res = await GoIpService.getCredit(id);
    dispatch({
      type: GET_CREDIT_GO_IP,
      payload: res.data.data,
    });
    dispatch(reloadGoIp());
  } catch (err) {
    dispatch(reloadGoIp());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
