import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Autocomplete, Box, Chip, Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { External_Item_Status } from "src/utils/common";
import Icon from "@mui/material/Icon";
import { useState } from "react";
import { UpdateStatus } from "src/actions/externalItem";
import { CustomDialog } from "src/components/CustomDialog";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { LoadingButton } from "@mui/lab";
import { showErrorSnackbar } from "src/actions/snackbarMessages";

export default function ExternalItemStatusMenu({
  size = "small",
  onCancel = function () {},
  status = "",
  onSuccess = function () {},
  onError = function () {},
  externalItemId = null,
  rejectReasonId = null,
  cartId = null,
}) {
  const itemReasons = useSelector(
    (state) => state.itemCancelReason.itemCancelReasonDataList
  );
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [_status, setStatus] = useState(null);
  const [selectCancelReason, setSelectCancelReason] = useState(rejectReasonId);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [selectStatus, setSelectStatus] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => setSelectCancelReason(rejectReasonId), [rejectReasonId]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();

  function updateOrderItemStatus(newStatus) {
    setLoading(true);
    dispatch(
      UpdateStatus({
        id: externalItemId,
        status: newStatus,
        rejectReasonId: newStatus == 3 ? selectCancelReason : null,
      })
    ).then((res) => {
      onSuccess(newStatus);
      setOpenCancelDialog(false);
      setLoading(false);
    });
  }

  const getStatusIcon = (status) => {
    var color;
    status == "Pending"
      ? (color = "warning")
      : status == "Rejected" || status == "Canceled by customer"
      ? (color = "error")
      : status == "Accepted"
      ? (color = "success")
      : status == "In Progress"
      ? (color = "primary")
      : (color = "secondary");

    return (
      <Chip
        size="small"
        label={status}
        color={color}
        clickable
        onClick={handleClick}
      />
    );
  };

  return (
    <Box>
      {getStatusIcon(_status ? _status : status)}
      <Menu
        anchorEl={anchorEl}
        open={
          _UserInfo.roleOEndpoint.map((data) => data.Code).includes("13-06")
            ? open
            : false
        }
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {External_Item_Status.map((status) => (
          <MenuItem
            key={status.id}
            id={status.id}
            onClick={(e) => {
              if (status.name == "Rejected") {
                setOpenCancelDialog(true);
                setSelectStatus(e.target.id);
              } else {
                updateOrderItemStatus(e.target.id);
              }
            }}
          >
            <ListItemIcon>
              <Icon>{status.icon}</Icon>
            </ListItemIcon>
            {status.name}
          </MenuItem>
        ))}
      </Menu>

      <CustomDialog
        isOpen={openCancelDialog}
        handleClose={() => {
          setOpenCancelDialog(false);
          setSelectCancelReason(rejectReasonId);
        }}
        title={"UPDATE STATUS"}
        maxWidth="md"
      >
        <Grid container spacing={2}>
          {!itemReasons ? null : (
            <Grid item xs={12} md={12}>
              <Autocomplete
                options={itemReasons}
                getOptionLabel={(option) =>
                  `${option.nameEN} - ${option.nameAR} `
                }
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Cancel Reason" />
                )}
                onChange={(event, value) => {
                  setSelectCancelReason(value ? value.id : null);
                }}
                value={
                  itemReasons.filter((x) => x.id == selectCancelReason)[0]
                    ? itemReasons.filter((x) => x.id == selectCancelReason)[0]
                    : null
                }
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={12}
            sx={{ justifyContent: "end", display: "flex" }}
          >
            <LoadingButton
              sx={{ my: 2 }}
              onClick={() => {
                if (selectCancelReason) {
                  updateOrderItemStatus(selectStatus);
                } else {
                  dispatch(showErrorSnackbar("Please select cancel reason"));
                }
              }}
              variant="contained"
              color="primary"
              loading={loading}
            >
              Edit
            </LoadingButton>
          </Grid>
        </Grid>
      </CustomDialog>
    </Box>
  );
}
