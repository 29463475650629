import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  TextField,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  InputAdornment,
  Stack,
  Typography,
  Divider,
  CardHeader,
  Autocomplete,
  Pagination,
  Select,
  MenuItem,
  Checkbox,
  SpeedDial,
  Drawer,
  Button,
  Menu,
  ListItemIcon,
  ListItemText,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import RolePermission from "src/components/RolePermission";
import { DeleteForever } from "@mui/icons-material";
import { AiOutlineSearch } from "react-icons/ai";
import IconButton from "@mui/material/IconButton";
import { CustomDialog } from "src/components/CustomDialog";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import {
  changeGiftItemStatus,
  deleteGiftItem,
  getAllGiftItem,
  getGiftItemById,
  getGiftItemByIdForEditAfterOrder,
  getGiftItemVariants,
  getProductInfo,
  openGiftItemDialog,
  openUpdateMultiFilterCollectionDialog,
  printSku,
  resetGiftItemForm,
  saveGiftItemPageNum,
  saveGiftItemSearchBody,
  saveScrollPosition,
  updateSelectedFilterCollectionData,
} from "src/actions/gifts/giftItem";
import moment from "moment";
import { useNavigate } from "react-router";
import { openVariantDialog } from "src/actions/gifts/variant";
import { FaEdit, FaFilter, FaStackOverflow } from "react-icons/fa";
import VariantForm from "src/pages/gifts/item/from/variantForm";
import {
  MdCollections,
  MdMenu,
  MdOutlineCategory,
  MdPrint,
} from "react-icons/md";
import EditItemAfterOrderForm from "src/pages/gifts/item/from/editAfterOrder";
import { getGiftsBrandList } from "src/actions/gifts/brand";
import { getItemSupplierList } from "src/actions/gifts/supplier";
import { getGiftsCategoryList } from "src/actions/gifts/giftCategory";
import ProductInfo from "src/pages/gifts/item/from/productInfo";
import { IoIosCloseCircle, IoMdCheckmarkCircle, IoMdEye } from "react-icons/io";
import UpdateMultiFilterCollectionForm from "src/pages/gifts/item/from/upldateMultiFilterCollection";
import { RiFileExcel2Line, RiInformationLine } from "react-icons/ri";
import PrintSKUForm from "src/pages/gifts/item/from/printDialog";
import { showSuccessSnackbar } from "src/actions/snackbarMessages";
import { FaCopy } from "react-icons/fa";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import * as XLSX from "xlsx/xlsx.mjs";
import theme from "src/theme";
import { openGiftCollectionsDialog } from "src/actions/gifts/collections";
import CollectionsForm from "src/pages/gifts/collections/from/Form";
import useCanShow from "src/components/CanShow";

const GiftItemsTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updatePromoRole = useCanShow("25-02");
  const deletePromoRole = useCanShow("25-03");

  const _UserInfo = useSelector((state) => state.users.userInfo);
  const giftItemsData = useSelector((state) => state.giftItems.data);
  const searchBody = useSelector((state) => state.giftItems.searchBody);
  const giftItems = useSelector((state) => state.giftItems);
  const loading = useSelector((state) => state.giftItems.reload);
  const _PageNo = useSelector((state) => state.giftItems.saveGiftItemsPageNum);
  const _GiftCategory = useSelector(
    (state) => state.giftItemCategory.giftItemCategoryList
  );
  const _GiftBrand = useSelector(
    (state) => state.giftItemBrand.giftItemBrandList
  );
  const _Variant = useSelector((state) => state.variant.openDialog);
  const _OpenDialogGiftItems = useSelector(
    (state) => state.giftItems.openDialog
  );
  const _GiftSupplier = useSelector(
    (state) => state.itemSupplier.itemSupplierDataList
  );
  const _OpenProductInfoDialog = useSelector(
    (state) => state.giftItems.openProductInfoDialog
  );
  const updateMultiFilterCollectionDialog = useSelector(
    (state) => state.giftItems.updateMultiFilterCollection.dialog
  );
  const collectionDialog = useSelector(
    (state) => state.giftCollections.openDialog
  );
  const selectedFilterCollectionData = useSelector(
    (state) =>
      state.giftItems.updateMultiFilterCollection.selectedFilterCollection
  );
  const giftItem = useSelector((state) => state.giftItems.getProductInfo);
  const _PrintSku = useSelector((state) => state.giftItems.printSkuForm);
  const _ViewType = useSelector((state) => state.giftItems.viewType);

  // useEffect(() => {
  //   if (!localStorage.getItem("priceRange")) {
  //     let priceRange = {
  //       fromCostPrice: giftItemsData.costPrice.min,
  //       toCostPrice: giftItemsData.costPrice.max,
  //       fromSellPrice: giftItemsData.sellPrice.min,
  //       toSellPrice: giftItemsData.sellPrice.min,
  //       fromDiscountPrice: giftItemsData.discountPrice.min,
  //       toDiscountPrice: giftItemsData.discountPrice.min,
  //     };
  //     localStorage.setItem("priceRange", JSON.stringify(priceRange));
  //   }
  // }, [
  //   giftItemsData.costPrice.max,
  //   giftItemsData.costPrice.min,
  //   giftItemsData.discountPrice.min,
  //   giftItemsData.sellPrice.min,
  // ]);

  const [openSearch, setOpenSearch] = useState(false);
  const [loadingSku, setLoadingSku] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenSearch(newOpen);
  };

  const isActive = [
    { name: "Active", key: true },
    { name: "Deactivated", key: false },
  ];

  const HasVariants = [
    { name: "Yes", key: true },
    { name: "No", key: false },
  ];

  const isAvailable = [
    { name: "Yes", key: true },
    { name: "No", key: false },
  ];

  const isOutOfStock = [
    { name: "Yes", key: true },
    { name: "No", key: false },
  ];

  const sortByPrice = [
    { name: "Hight To Low", key: 1 },
    { name: "Low To High", key: 0 },
  ];

  const [isSearch, setIsSearch] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(_PageNo ?? 1);
  const [searchName, setSearchName] = useState(searchBody.name);
  const [sku, setSearchSKU] = useState(searchBody.sku);
  const [lockerNumber, setSearchLockerNumber] = useState(
    searchBody.lockerNumber
  );
  const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const [open, setOpen] = useState(null);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showPanZoom, setShowPanZoom] = useState("");
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openSupplierDialog, setOpenSupplierDialog] = useState(false);
  const [supplierData, setSupplierData] = useState({});

  const [costPrice, setCostPrice] = useState([
    searchBody.fromCostPrice ?? 0,
    searchBody.toCostPrice ?? 8000000,
  ]);
  const [sellPrice, setSellPrice] = useState([
    searchBody.fromSellPrice ?? 0,
    searchBody.toSellPrice ?? 8000000,
  ]);
  const [discountPrice, setDiscountPrice] = useState([
    searchBody.fromDiscountPrice ?? 0,
    searchBody.toDiscountPrice ?? 8000000,
  ]);

  useEffect(() => {
    dispatch(getAllGiftItem(page, searchBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, dispatch, isSearch]);

  useEffect(() => {
    dispatch(getGiftsBrandList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getItemSupplierList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGiftsCategoryList());
  }, [dispatch]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    dispatch(saveGiftItemPageNum(newPage));
  };

  function deleteSelectedGifts(id) {
    dispatch(deleteGiftItem(id, page, searchBody)).then((x) => {
      dispatch(resetGiftItemForm());
      setOpenDeleteDialog(false);
    });
  }

  function changeItemStatus(id, status) {
    dispatch(changeGiftItemStatus(id, status, page, searchBody)).then((x) => {
      setOpenChangeStatusDialog(false);
    });
  }

  // useEffect(() => {
  //   if (searchBody.categoryIds) {
  //     setSelectedFilterCollection(
  //       searchBody.categoryIds ? searchBody.categoryIds.filterCollections : []
  //     );
  //   }
  // }, [searchBody.categoryIds]);
  // useEffect(() => {
  //   window.scrollTo({ top: 1000, behavior: "smooth" });
  // }, []);

  const handleMenuClick = (event, item) => {
    setOpenMenu(event.currentTarget);
    setActiveItem(item);
    setOpen(true);
  };

  const handleClose = () => {
    setActiveItem(null);
    setOpenMenu(null);
    setOpen(false);
  };

  // function CustomToolbar() {
  //   return (
  //     _UserInfo.roles.includes("Export") && (
  //       <GridToolbarContainer>
  //         <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
  //       </GridToolbarContainer>
  //     )
  //   );
  // }

  function CustomToolbar() {
    // const exportToExcel = (json) => {
    //   const ws = utils.json_to_sheet(json);
    //   const wb = utils.book_new();
    //   utils.book_append_sheet(wb, ws, "Sheet1");
    //   writeFile(wb, { bookType: "xlsx", type: "array" });
    //   // const data = new Blob([excelBuffer], {
    //   //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    //   // });
    //   // const url = window.URL.createObjectURL(data);
    //   // const link = document.createElement("a");
    //   // link.href = url;
    //   // link.setAttribute("download", "export.xlsx");
    //   // document.body.appendChild(link);
    //   // link.click();
    //   // document.body.removeChild(link);
    // };

    return (
      _UserInfo.roles.includes("Export") && (
        <GridToolbarContainer>
          <Button
            variant="contained"
            color="primary"
            startIcon={<RiFileExcel2Line />}
            onClick={() => {
              const agentTopupHeaders = {
                nameEn: "Name",
                costPrice: "Cost Price",
                sellPrice: "Sell Price",
                discountPrice: "Discount Price",
                totalQuantity: "Total Quantity",
                createdAt: "Created At",
                giftBrand: "Brand",
                giftItemCategories: "Categories",
                giftSupplier: "Supplier",
                isActive: "Is Active",
                isInGiftOrder: "Is In Gift Order",
                lockerNumber: "Locker Number",
                sku: "SKU",
              };

              const agentTopupHeadersHandler = Object.values(agentTopupHeaders);

              const data = [
                agentTopupHeadersHandler,
                ...giftItemsData.data.map((item) =>
                  Object.keys(agentTopupHeaders).map((key) =>
                    key === "giftBrand" || key === "giftSupplier"
                      ? item[key]?.nameEn
                      : key === "giftItemCategories"
                      ? item[key]?.map((category) => category.nameEn).join(", ")
                      : item[key]
                  )
                ),
              ];
              const agentTopupBook = XLSX.utils.book_new();
              const agentTopupSheet = XLSX.utils.aoa_to_sheet(data);
              XLSX.utils.book_append_sheet(
                agentTopupBook,
                agentTopupSheet,
                "Sheet1"
              );
              XLSX.writeFile(
                agentTopupBook,
                `Gift Products ${moment().format("YYYY-MM-DD / hh:mm")}.xlsx`
              );
            }}
          >
            Export to Excel
          </Button>
        </GridToolbarContainer>
      )
    );
  }
  const handleDialogClose = () => {
    setOpenImageDialog(false);
  };

  const handleOpenPanZoom = (url) => {
    setOpenImageDialog(true);
    setShowPanZoom(url);
  };

  const Item = ({ params }) => {
    return (
      <Stack spacing={0} sx={{ width: "450px" }} alignItems="start">
        <Stack direction="row">
          <Box
            onClick={() =>
              handleOpenPanZoom(
                params?.giftItemOptions?.find(
                  (option) => option.attachments?.url
                )?.attachments?.url || params?.giftItemAttachments[0]?.url
              )
            }
            component="img"
            sx={{
              height: "150px",
              width: "150px",
              objectFit: "contain",
            }}
            alt="itemImage"
            src={params?.giftItemAttachments[0]?.url}
          />
          <Box
            sx={{
              marginLeft: "10px",
              width: "300px",
              overflowWrap: "break-word",
              wordWrap: "break-word",
            }}
          >
            <Typography
              variant="h5"
              style={{ whiteSpace: "normal", my: 1, fontWeight: "bold" }}
            >
              {params?.nameEn}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                whiteSpace: "normal",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Description: </span>
              {`${params?.descriptionEn}`}
            </Typography>
            <Typography variant="h5" color="secondary">
              {params.giftItemCategories?.map(
                (x, i) =>
                  `${x?.nameEn?.toUpperCase()}${
                    i < params.giftItemCategories.length - 1 ? " - " : ""
                  }`
              )}
            </Typography>
          </Box>
        </Stack>
        {params.sku ? (
          <Button
            variant="text"
            color="success"
            startIcon={<FaCopy />}
            onClick={() => {
              window.navigator.clipboard.writeText(params.sku);
              dispatch(showSuccessSnackbar("SKU Copied Successfully"));
            }}
          >
            SKU: {params.sku.toUpperCase()}
          </Button>
        ) : null}
      </Stack>
    );
  };

  const columns = [
    {
      field: "sequence",
      headerName: "#",
      headerAlign: "center",
      width: 5,
    },
    {
      field: "item",
      headerName: "Item Details",
      headerAlign: "center",
      flex: 2,
      renderCell: (params) => <Item params={params.row} />,
    },
    {
      field: "sellPrice",
      headerName: "Price (IQD)",
      headerAlign: "center",
      flex: 0.8,
      renderCell: (params) => (
        <Stack spacing={1}>
          <Typography
            variant="h5"
            style={{
              whiteSpace: "normal",
              my: 1,
              fontWeight: "bold",
              color: "blue",
            }}
          >
            Cost Price: {params.row.costPrice?.toLocaleString()}
          </Typography>
          <Typography
            variant="h5"
            style={{
              whiteSpace: "normal",
              my: 1,
              fontWeight: "bold",
              color: "green",
            }}
          >
            Sell Price: {params.row.sellPrice?.toLocaleString()}
          </Typography>
          {params.row.discountPrice ? (
            <Typography
              variant="h5"
              style={{
                whiteSpace: "normal",
                my: 1,
                fontWeight: "bold",
                color: "red",
              }}
            >
              Discount Price: {params.row.discountPrice?.toLocaleString()}
            </Typography>
          ) : null}
        </Stack>
      ),
    },
    {
      field: "giftBrand",
      headerName: "Brand",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack alignItems="center" spacing={2}>
          <Box
            component="img"
            src={params.row.giftBrand?.attachment?.url}
            width={50}
            sx={{ objectFit: "contain" }}
          />
          <Typography variant="body">
            {params.row?.giftBrand?.nameEn}
          </Typography>
          {params.row?.giftBrand?.isActive ? null : (
            <span style={{ color: "red" }}> (Inactive)</span>
          )}
        </Stack>
      ),
    },
    {
      field: "giftSupplier",
      headerName: "Supplier",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Stack alignItems="center" spacing={2}>
          <Button
            onClick={() => {
              setOpenSupplierDialog(true);
              setSupplierData(params.row?.giftSupplier);
            }}
          >
            <Typography variant="body">
              {params.row?.giftSupplier?.nameEn}
            </Typography>
          </Button>
          {params.row?.giftSupplier?.isActive ? null : (
            <span style={{ color: "red" }}> (Inactive)</span>
          )}
        </Stack>
      ),
    },
    {
      field: "totalQuantity",
      headerName: "Quantity",
      headerAlign: "center",
      flex: 0.3,
      renderCell: (params) => (
        <Typography>{params.row.totalQuantity?.toLocaleString()}</Typography>
      ),
    },
    {
      field: "lockerNumber",
      headerName: "Locker",
      headerAlign: "center",
      flex: 0.3,
      renderCell: (params) => (
        <Typography>
          {params.row.lockerNumber ? params.row.lockerNumber : "----"}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      headerAlign: "center",
      flex: 0.5,
      renderCell: (params) => (
        <Stack direction="column" spacing={1}>
          <Typography>
            {moment(params.row?.createdAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="caption">
            {moment(params.row?.createdAt).format("hh:mm:ss a")}
          </Typography>
        </Stack>
      ),
    },
    // {
    //   field: "operations",
    //   headerName: "Operations",
    //   headerAlign: "center",
    //   flex: 0.3,
    //   renderCell: (params) => (
    //     <Stack direction="row" spacing={1}>
    //       <Tooltip title="Options">
    //         <IconButton
    //           size="small"
    //           sx={{ ml: 2 }}
    //           onClick={(e) => {
    //             handleMenuClick(e, params);
    //           }}
    //           aria-controls={openMenu ? params.row.id : undefined}
    //           aria-haspopup="true"
    //           aria-expanded={openMenu ? "true" : undefined}
    //         >
    //           <MdMenu size={30} />
    //         </IconButton>
    //       </Tooltip>
    //     </Stack>
    //   ),
    // },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<FaCopy size={25} />}
          label="Copy Product Id"
          showInMenu
          onClick={() => {
            navigator.clipboard.writeText(params.row.id);
            dispatch(showSuccessSnackbar("Product Id Copied"));
          }}
        />,
        <GridActionsCellItem
          icon={<FaStackOverflow size={25} color="#ac49bc" />}
          label="Variation"
          showInMenu
          onClick={() => {
            dispatch(getGiftItemById(params.row.id)).then(() =>
              dispatch(openVariantDialog(params.row.isInGiftOrder))
            );
          }}
        />,
        !params.row.isInGiftOrder ? (
          <GridActionsCellItem
            icon={<EditIcon color="primary" />}
            label="Edit Product"
            showInMenu
            onClick={() => {
              navigate("/app/gifts/items/update_item/" + params.row.id);
              dispatch(saveScrollPosition(params.row.index));
            }}
          />
        ) : (
          <GridActionsCellItem
            icon={<FaEdit size={25} color="primary" />}
            label="Edit Product"
            showInMenu
            onClick={() => {
              dispatch(
                getGiftItemByIdForEditAfterOrder(params.row.id, false)
              ).then(() => dispatch(openGiftItemDialog()));
            }}
          />
        ),
        <GridActionsCellItem
          icon={<DeleteForever color="error" />}
          label="Delete Product"
          showInMenu
          onClick={() => {
            dispatch(getGiftItemById(params.row.id, false)).then(() =>
              setOpenDeleteDialog(true)
            );
          }}
        />,
        <GridActionsCellItem
          icon={
            params.row.isActive ? (
              <IoMdCheckmarkCircle size={25} color="green" />
            ) : (
              <IoIosCloseCircle size={25} color="#f44336" />
            )
          }
          label={params.row.isActive ? "Hide Product" : "Show Product"}
          showInMenu
          onClick={() => {
            dispatch(getGiftItemById(params.row.id, false)).then(() =>
              setOpenChangeStatusDialog(true)
            );
          }}
        />,

        <GridActionsCellItem
          icon={<IoMdEye size={25} />}
          label="Product Details"
          showInMenu
          onClick={() => {
            dispatch(getProductInfo(params.row.id));
          }}
        />,
        <GridActionsCellItem
          icon={<MdPrint size={25} />}
          label="Print SKU"
          showInMenu
          onClick={() => {
            dispatch(getGiftItemById(params.row.id)).then((x) => {
              if (x.sku) {
                dispatch(printSku([x]));
              } else {
                setLoadingSku(true);
                dispatch(getGiftItemVariants(x.id)).then((res) => {
                  setLoadingSku(false);
                  dispatch(printSku(res));
                });
              }
            });
          }}
        />,
      ],
    },
  ];

  const operationMenu = (params) => (
    <Box sx={{ display: "flex" }}>
      <Menu
        anchorEl={openMenu}
        id={params.id}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 1,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 1px 2px rgba(0,0,0,1))",
            mt: 1,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 8,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          disabled={selectedRows.length > 0}
          onClick={(e) => {
            navigator.clipboard.writeText(params.id);
            dispatch(showSuccessSnackbar("Product Id Copied"));
          }}
        >
          <ListItemIcon>
            <FaCopy size={25} />
          </ListItemIcon>
          <ListItemText>Copy Product Id</ListItemText>
        </MenuItem>
        <RolePermission permission="41-05">
          <MenuItem
            disabled={selectedRows.length > 0}
            onClick={(e) => {
              dispatch(getGiftItemById(params.id)).then(() =>
                dispatch(openVariantDialog(params.isInGiftOrder))
              );
            }}
          >
            <ListItemIcon>
              <FaStackOverflow size={25} color="#ac49bc" />
            </ListItemIcon>
            <ListItemText>Variation</ListItemText>
          </MenuItem>
        </RolePermission>
        {params.isInGiftOrder ? null : (
          <RolePermission permission="41-05">
            <MenuItem
              disabled={selectedRows.length > 0}
              onClick={(e) => {
                navigate("/app/gifts/items/update_item/" + params.id);
                dispatch(saveScrollPosition(params.index));
              }}
            >
              <ListItemIcon>
                <EditIcon color="primary" />
              </ListItemIcon>
              <ListItemText>Edit Product</ListItemText>
            </MenuItem>
          </RolePermission>
        )}
        <RolePermission permission="41-06">
          <MenuItem
            disabled={selectedRows.length > 0}
            onClick={(e) => {
              dispatch(getGiftItemById(params.id, false)).then(() =>
                setOpenDeleteDialog(true)
              );
            }}
          >
            <ListItemIcon>
              <DeleteForever color="error" />
            </ListItemIcon>
            <ListItemText>Delete Product</ListItemText>
          </MenuItem>
        </RolePermission>
        <RolePermission permission="41-16">
          <MenuItem
            disabled={selectedRows.length > 0}
            onClick={(e) => {
              dispatch(getGiftItemById(params.id, false)).then(() =>
                setOpenChangeStatusDialog(true)
              );
            }}
          >
            <ListItemIcon>
              {params.isActive ? (
                <Typography>
                  <IoMdCheckmarkCircle size={25} color="green" />
                </Typography>
              ) : (
                <Typography>
                  <IoIosCloseCircle size={25} color="#f44336" />
                </Typography>
              )}
            </ListItemIcon>
            <ListItemText>
              {params.isActive ? "Hide Product" : "Show Product"}
            </ListItemText>
          </MenuItem>
        </RolePermission>
        {params.isInGiftOrder ? (
          <RolePermission permission="41-12">
            <MenuItem
              color="primary"
              disabled={selectedRows.length > 0}
              onClick={(e) => {
                dispatch(
                  getGiftItemByIdForEditAfterOrder(params.id, false)
                ).then(() => dispatch(openGiftItemDialog()));
              }}
            >
              <ListItemIcon>
                <FaEdit size={25} color="primary" />
              </ListItemIcon>
              <ListItemText>Edit Product</ListItemText>
            </MenuItem>
          </RolePermission>
        ) : null}
        <MenuItem
          disabled={selectedRows.length > 0}
          onClick={(e) => {
            dispatch(getProductInfo(params.id));
          }}
        >
          <ListItemIcon>
            <IoMdEye size={25} />
          </ListItemIcon>
          <ListItemText>Product Details</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={selectedRows.length > 0}
          onClick={(e) => {
            dispatch(getGiftItemById(params.id)).then((x) => {
              if (x.sku) {
                dispatch(printSku([x]));
              } else {
                setLoadingSku(true);
                dispatch(getGiftItemVariants(x.id)).then((res) => {
                  setLoadingSku(false);
                  dispatch(printSku(res));
                });
              }
            });
          }}
        >
          <ListItemIcon>
            <MdPrint size={25} />
          </ListItemIcon>
          <ListItemText>Print SKU</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );

  return (
    <Card style={{ overflow: "auto" }}>
      <CardContent>
        <CustomDialog
          isOpen={openImageDialog}
          handleClose={() => handleDialogClose()}
          title="Images"
          maxWidth="lg"
        >
          <div
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                width: "50%",
                height: "auto",
              }}
            >
              <ReactPanZoom image={showPanZoom} alt="Image alt text" />
            </div>
          </div>
        </CustomDialog>
        <CustomDialog
          isOpen={openSupplierDialog}
          handleClose={() => setOpenSupplierDialog(false)}
          title="Supplier Information"
          maxWidth="sm"
        >
          {!supplierData?.isActive ? (
            <Typography color="error" variant="h5" sx={{ my: 1 }}>
              Inactive
            </Typography>
          ) : null}
          <Typography variant="h5" sx={{ my: 1 }}>
            Name:{" "}
            <span style={{ fontWeight: "bolder" }}>{supplierData?.nameEn}</span>
          </Typography>
          <Typography variant="h5" sx={{ my: 1 }}>
            Phone number:{" "}
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              {supplierData?.phoneNumber}
            </span>
          </Typography>
          <Typography variant="h5" sx={{ my: 1 }}>
            Address:{" "}
            <span style={{ fontWeight: "bolder" }}>
              {" "}
              {supplierData?.province?.nameEn}-{supplierData?.city?.nameEn}
            </span>
          </Typography>
          <Typography variant="h5" sx={{ my: 1 }}>
            Note:{" "}
            <span style={{ fontWeight: "bolder" }}>{supplierData?.note}</span>
          </Typography>
        </CustomDialog>
        {_Variant && <VariantForm />}
        {_OpenDialogGiftItems && <EditItemAfterOrderForm />}
        {_OpenProductInfoDialog && <ProductInfo />}
        {loadingSku ? <LinearProgress /> : null}
        {_PrintSku.dialog ? <PrintSKUForm /> : null}
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
              dispatch(resetGiftItemForm());
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            maxWidth="sm"
            onDelete={() => deleteSelectedGifts(giftItems.updateForm.id)}
            selectedObject={`${giftItems.updateForm.nameEn}`}
          ></CustomDialog>
        )}
        {openChangeStatusDialog && (
          <CustomDialog
            isOpen={openChangeStatusDialog}
            handleClose={() => {
              setOpenChangeStatusDialog(false);
            }}
            isActiveDelete
            isConfirm
            isRequierdActions
            title="Product Status"
            confirmTitle={
              giftItems.updateForm.isActive
                ? "HIDE this product: "
                : "SHOW this product: "
            }
            maxWidth="sm"
            onDelete={() =>
              changeItemStatus(
                giftItems.updateForm.id,
                giftItems.updateForm.isActive ? false : true
              )
            }
            selectedObject={`${giftItems.updateForm.nameEn}`}
          ></CustomDialog>
        )}
        {updateMultiFilterCollectionDialog ? (
          <UpdateMultiFilterCollectionForm
            selectedItems={selectedItem}
            selectedFilterCollection={selectedFilterCollectionData}
          />
        ) : null}
        {collectionDialog ? (
          <CollectionsForm selectedItems={selectedItem} />
        ) : null}
        {selectedItem.length > 0 ? (
          <Box
            sx={{
              zIndex: 1000,
            }}
          >
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              sx={{ position: "absolute", bottom: 120, right: 16 }}
              icon={<SpeedDialIcon />}
              open={selectedRows.length > 0}
            >
              {searchBody.categoryIds && (
                <SpeedDialAction
                  icon={
                    <FaFilter size={30} color={theme.palette.primary.main} />
                  }
                  tooltipTitle={
                    <Stack
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      width={200}
                    >
                      <Typography variant="h6">
                        Add Category Filter Collection
                      </Typography>
                    </Stack>
                  }
                  tooltipOpen
                  onClick={() =>
                    dispatch(openUpdateMultiFilterCollectionDialog())
                  }
                  sx={{
                    "& .MuiSpeedDialAction-fab": {
                      width: "50px",
                      height: "50px",
                    },
                  }}
                />
              )}
              <SpeedDialAction
                icon={
                  <MdCollections size={30} color={theme.palette.success.dark} />
                }
                tooltipTitle={
                  <Stack
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    width={200}
                  >
                    <Typography variant="h6">Add New Collection</Typography>
                  </Stack>
                }
                tooltipOpen
                onClick={() => dispatch(openGiftCollectionsDialog())}
                sx={{
                  "& .MuiSpeedDialAction-fab": {
                    width: "50px",
                    height: "50px",
                  },
                }}
              />
            </SpeedDial>
          </Box>
        ) : null}

        <Drawer open={openSearch} onClose={toggleDrawer(false)}>
          <Divider />
          <Grid container spacing={2} padding={2} sx={{ width: 400 }}>
            <Grid item xs={12}>
              <Autocomplete
                disabled={loading}
                options={_GiftSupplier || []}
                getOptionLabel={(option) => option.nameEn}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Supplier" />
                )}
                onChange={(event, value) => {
                  dispatch(
                    saveGiftItemSearchBody({
                      ...searchBody,
                      giftSupplierId: value ? value.id : null,
                      name: searchName,
                      sku: sku,
                      lockerNumber: lockerNumber,
                    })
                  );
                  setPage(1);
                  dispatch(saveGiftItemPageNum(1));
                }}
                value={
                  _GiftSupplier?.filter(
                    (x) => x.id === searchBody.giftSupplierId
                  )[0] ?? null
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disabled={loading}
                options={_GiftBrand || []}
                getOptionLabel={(option) => option.nameEn}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Brand" />
                )}
                onChange={(event, value) => {
                  dispatch(
                    saveGiftItemSearchBody({
                      ...searchBody,
                      giftBrandId: value ? value.id : null,
                      name: searchName,
                      sku: sku,
                      lockerNumber: lockerNumber,
                    })
                  );
                  setPage(1);
                  dispatch(saveGiftItemPageNum(1));
                }}
                value={
                  _GiftBrand?.filter(
                    (x) => x.id === searchBody.giftBrandId
                  )[0] ?? null
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={isActive}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Is Active" />
                )}
                onChange={(event, value) => {
                  dispatch(
                    saveGiftItemSearchBody({
                      ...searchBody,
                      isActive: value ? value.key : null,
                      name: searchName,
                      sku: sku,
                      lockerNumber: lockerNumber,
                    })
                  );
                  setPage(1);
                  dispatch(saveGiftItemPageNum(1));
                }}
                value={
                  isActive.filter((x) => x.key === searchBody.isActive)[0] ??
                  null
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={HasVariants}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Has Variants !" />
                )}
                onChange={(event, value) => {
                  dispatch(
                    saveGiftItemSearchBody({
                      ...searchBody,
                      hasVarients: value ? value.key : null,
                      name: searchName,
                      sku: sku,
                      lockerNumber: lockerNumber,
                    })
                  );
                  setPage(1);
                  dispatch(saveGiftItemPageNum(1));
                }}
                value={
                  HasVariants.filter(
                    (x) => x.key === searchBody.hasVarients
                  )[0] ?? null
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={isOutOfStock}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Is Out Of Stock!" />
                )}
                onChange={(event, value) => {
                  dispatch(
                    saveGiftItemSearchBody({
                      ...searchBody,
                      isOutOfStock: value ? value.key : null,
                      name: searchName,
                      sku: sku,
                      lockerNumber: lockerNumber,
                    })
                  );
                  setPage(1);
                  dispatch(saveGiftItemPageNum(1));
                }}
                value={
                  isOutOfStock.filter(
                    (x) => x.key === searchBody.isOutOfStock
                  )[0] ?? null
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={isAvailable}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Is Available !" />
                )}
                onChange={(event, value) => {
                  dispatch(
                    saveGiftItemSearchBody({
                      ...searchBody,
                      isAvailable: value ? value.key : null,
                      name: searchName,
                      sku: sku,
                      lockerNumber: lockerNumber,
                    })
                  );
                  setPage(1);
                  dispatch(saveGiftItemPageNum(1));
                }}
                value={
                  isAvailable.filter(
                    (x) => x.key === searchBody.isAvailable
                  )[0] ?? null
                }
              />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2} padding={2} sx={{ width: 400 }}>
            {/* <Grid item xs={12}>
              <Typography variant="body1">Cost Price</Typography>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Typography variant="body1">
                  {JSON.parse(
                    localStorage.getItem("priceRange")
                  )?.fromCostPrice?.toLocaleString()}
                </Typography>
                <Typography variant="body1">
                  {JSON.parse(
                    localStorage.getItem("priceRange")
                  )?.toCostPrice?.toLocaleString()}
                </Typography>
              </Stack>
              <Slider
                sx={{ ml: 3, maxWidth: 320 }}
                getAriaLabel={() => "Cost Price"}
                value={costPrice}
                onChange={(event, newValue) => {
                  setCostPrice(newValue);
                  dispatch(
                    saveGiftItemSearchBody({
                      ...searchBody,
                      fromCostPrice: newValue[0],
                      toCostPrice: newValue[1],
                      name: searchName,
                      sku: sku,
                      lockerNumber: lockerNumber,
                    })
                  );
                }}
                valueLabelDisplay="auto"
                min={
                  JSON.parse(localStorage?.getItem("priceRange"))?.fromCostPrice
                }
                max={
                  JSON.parse(localStorage?.getItem("priceRange"))?.toCostPrice
                }
                scale={(x) => x ** 1}
                valueLabelFormat={(value, index) => {
                  return `${value.toLocaleString()} IQD`;
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Sell Price</Typography>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Typography variant="body1">
                  {sellPrice[0]?.toLocaleString()}
                </Typography>
                <Typography variant="body1">
                  {sellPrice[1]?.toLocaleString()}
                </Typography>
              </Stack>
              <Slider
                sx={{ ml: 3, maxWidth: 320 }}
                getAriaLabel={() => "Sell Price"}
                value={sellPrice}
                onChange={(event, newValue) => {
                  setSellPrice(newValue);
                  dispatch(
                    saveGiftItemSearchBody({
                      ...searchBody,
                      fromSellPrice: newValue[0],
                      toSellPrice: newValue[1],
                      name: searchName,
                      sku: sku,
                      lockerNumber: lockerNumber,
                    })
                  );
                }}
                valueLabelDisplay="auto"
                min={0}
                max={8000000}
                scale={(x) => x ** 1}
                valueLabelFormat={(value, index) => {
                  return `${value.toLocaleString()} IQD`;
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Discount Price</Typography>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Typography variant="body1">
                  {discountPrice[0]?.toLocaleString()}
                </Typography>
                <Typography variant="body1">
                  {discountPrice[1]?.toLocaleString()}
                </Typography>
              </Stack>
              <Slider
                sx={{ ml: 3, maxWidth: 320 }}
                getAriaLabel={() => "Discount Price"}
                value={discountPrice}
                onChange={(event, newValue) => {
                  setDiscountPrice(newValue);
                  dispatch(
                    saveGiftItemSearchBody({
                      ...searchBody,
                      fromDiscountPrice: newValue[0] === 0 ? null : newValue[0],
                      toDiscountPrice: newValue[1],
                      name: searchName,
                      sku: sku,
                      lockerNumber: lockerNumber,
                    })
                  );
                }}
                valueLabelDisplay="auto"
                min={0}
                max={8000000}
                scale={(x) => x ** 1}
                valueLabelFormat={(value, index) => {
                  return `${value.toLocaleString()} IQD`;
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={sortByPrice}
                getOptionLabel={(option) => option.name}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Sort By Price" />
                )}
                onChange={(event, value) => {
                  dispatch(
                    saveGiftItemSearchBody({
                      ...searchBody,
                      sortByPrice: value ? value.key : null,
                      name: searchName,
                      sku: sku,
                      lockerNumber: lockerNumber,
                    })
                  );
                  setPage(1);
                  dispatch(saveGiftItemPageNum(1));
                }}
                value={
                  sortByPrice.filter(
                    (x) => x.key === searchBody.sortByPrice
                  )[0] ?? null
                }
              />
            </Grid> */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={(e) => {
                  setIsSearch(!isSearch);
                  setPage(1);
                  dispatch(saveGiftItemPageNum(1));
                }}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Drawer>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveGiftItemSearchBody({
                      ...searchBody,
                      name: searchName,
                      sku: sku,
                      lockerNumber: lockerNumber,
                    })
                  );
                  setPage(1);
                  dispatch(saveGiftItemPageNum(1));
                  setIsSearch(!isSearch);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search SKU"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={sku}
              onChange={(e) => {
                setSearchSKU(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveGiftItemSearchBody({
                      ...searchBody,
                      name: searchName,
                      sku: sku,
                      lockerNumber: lockerNumber,
                    })
                  );
                  setPage(1);
                  dispatch(saveGiftItemPageNum(1));
                  setIsSearch(!isSearch);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2.5}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Locker Number"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={lockerNumber}
              onChange={(e) => {
                setSearchLockerNumber(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveGiftItemSearchBody({
                      ...searchBody,
                      name: searchName,
                      sku: sku,
                      lockerNumber: lockerNumber,
                    })
                  );
                  setPage(1);
                  dispatch(saveGiftItemPageNum(1));
                  setIsSearch(!isSearch);
                }
              }}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Autocomplete
              disabled={loading}
              options={_GiftCategory || []}
              getOptionLabel={(option) => option.nameEn}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveGiftItemSearchBody({
                    ...searchBody,
                    categoryIds: value ? value.id : "",
                    name: searchName,
                    sku: sku,
                    lockerNumber: lockerNumber,
                  })
                );
                setPage(1);
                dispatch(saveGiftItemPageNum(1));
                dispatch(
                  updateSelectedFilterCollectionData(
                    value ? value.filterCollections : []
                  )
                );
                setSelectedItem([]);
                setIsSearch(!isSearch);
              }}
              value={
                _GiftCategory?.filter(
                  (x) => x.id === searchBody.categoryIds
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={1}>
            <Stack
              spacing={1}
              direction="row"
              justifyContent="start"
              alignItems="center"
            >
              <Tooltip title="Advanced Search">
                <IconButton
                  onClick={() => {
                    setOpenSearch(true);
                  }}
                >
                  <FaFilter />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Advanced Filter">
                <IconButton
                  onClick={() => {
                    dispatch(openGiftItemFilterDialog);
                  }}
                >
                  <MdFilterListAlt />
                </IconButton>
              </Tooltip> */}
              <Tooltip title="Clear Filter">
                <IconButton
                  onClick={() => {
                    setSearchName("");
                    setSearchSKU("");
                    setSearchLockerNumber("");
                    setCostPrice([0, 8000000]);
                    setSellPrice([0, 8000000]);
                    setDiscountPrice([0, 8000000]);
                    dispatch(
                      saveGiftItemSearchBody({
                        name: "",
                        giftBrandId: null,
                        giftSupplierId: null,
                        hasVarients: null,
                        pageSize: 16,
                      })
                    );
                    setPage(1);
                    dispatch(saveGiftItemPageNum(1));
                    setIsSearch(!isSearch);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
            {loading && <LinearProgress />}
            {_ViewType === "grid" ? (
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2} id="scroll">
                  {giftItemsData?.data?.map((item, index) => (
                    <Grid item xs={12} md={3} key={item.id}>
                      <Card
                        id={index}
                        sx={{
                          padding: "0 20px 20px 20px",
                          ml: 0.25,
                          height: "100%",
                          position: "relative",
                        }}
                      >
                        <CardHeader
                          title={
                            <Box>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                <RolePermission permission="41-14">
                                  <Checkbox
                                    sx={{ width: 30, height: 30 }}
                                    disabled={
                                      item.isActive === false ||
                                      item.giftBrand?.isActive === false ||
                                      item.giftSupplier?.isActive === false ||
                                      item.totalQuantity <= 0
                                    }
                                    checked={selectedItem.some(
                                      (selectedItem) =>
                                        selectedItem.id === item.id
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setSelectedItem([
                                          ...selectedItem,
                                          item,
                                        ]);
                                      } else {
                                        setSelectedItem(
                                          selectedItem.filter(
                                            (selectedItem) =>
                                              selectedItem.id !== item.id
                                          )
                                        );
                                      }
                                    }}
                                  />
                                </RolePermission>
                                <Stack direction="row" spacing={1}>
                                  <Tooltip title="Options">
                                    <IconButton
                                      size="small"
                                      sx={{ ml: 2 }}
                                      onClick={(e) => {
                                        handleMenuClick(e, item);
                                      }}
                                      aria-controls={
                                        openMenu ? item.id : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        openMenu ? "true" : undefined
                                      }
                                    >
                                      <MdMenu size={30} />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </Stack>

                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="start"
                                  spacing={1}
                                >
                                  <MdOutlineCategory size={20} />
                                  <Typography
                                    variant="body"
                                    color="secondary"
                                    component="div"
                                  >
                                    {item.giftItemCategories?.map(
                                      (x, i) =>
                                        `${x?.nameEn?.toUpperCase()}${
                                          i < item.giftItemCategories.length - 1
                                            ? " - "
                                            : ""
                                        }`
                                    )}
                                  </Typography>
                                </Stack>
                              </Stack>
                              <Stack direction="row" justifyContent="end">
                                <Typography variant="body">
                                  {moment(item.createdAt).format(
                                    "YYYY-MM-DD / hh:mm a"
                                  )}
                                </Typography>
                              </Stack>
                            </Box>
                          }
                        />
                        <Stack spacing={2}>
                          <Stack
                            direction="column"
                            justifyContent="center"
                            spacing={1}
                            height="100%"
                          >
                            <Box
                              position="relative"
                              style={{
                                height: "300px",
                                width: "auto",
                              }}
                            >
                              {item.isActive ? null : (
                                <Box
                                  size="small"
                                  color="error"
                                  style={{
                                    position: "absolute",
                                    fontSize: 16,
                                    width: "100%",
                                    borderRadius: 100,
                                    backgroundColor: "#f44336",
                                    fontWeight: "bold",
                                    color: "white",
                                    padding: "2px 50px",
                                  }}
                                >
                                  <Typography align="center">
                                    Inactive
                                  </Typography>
                                </Box>
                              )}
                              <Box
                                component="img"
                                src={item.giftItemAttachments[0]?.url}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                              />
                              {item.totalQuantity <= 0 && (
                                <Box
                                  position="absolute"
                                  top={0}
                                  left={0}
                                  width="100%"
                                  height="100%"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  bgcolor="rgba(0, 0, 0, 0.5)"
                                >
                                  <Typography variant="h1" color="white">
                                    Out of Stock
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                            <Tooltip title={<h5>{item.nameEn}</h5>}>
                              <Typography
                                variant="h4"
                                color={"primary"}
                                sx={{
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 3,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {item.nameEn}
                              </Typography>
                            </Tooltip>
                            <Tooltip title={<h5>{item.descriptionEn}</h5>}>
                              <Typography
                                variant="body1"
                                sx={{
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {item.descriptionEn}
                              </Typography>
                            </Tooltip>
                            <Card sx={{ p: 2 }} elevation={2}>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold" }}
                              >
                                Brand: {item.giftBrand.nameEn.toUpperCase()}
                                {item.giftBrand.isActive ? null : (
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    (Inactive)
                                  </span>
                                )}
                              </Typography>
                              <Stack direction="row" spacing={1}>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontWeight: "bold",
                                    // color: item.availableTime?.isAvailable
                                    //   ? null
                                    //   : "#d32f2f",
                                  }}
                                >
                                  Supplier:{" "}
                                  {item.giftSupplier.nameEn.toUpperCase()}{" "}
                                  {item.availableTime?.isAvailable &&
                                  item.availableTime?.startTime &&
                                  item.availableTime?.endTime
                                    ? null
                                    : !item.availableTime?.isAvailable &&
                                      !item.availableTime?.startTime &&
                                      !item.availableTime?.endTime
                                    ? " (Closed All Day)"
                                    : " (Closed Time)"}{" "}
                                  {item.giftSupplier.isActive ? null : (
                                    <span style={{ color: "red" }}>
                                      {" "}
                                      (Inactive)
                                    </span>
                                  )}
                                </Typography>
                                {!item.availableTime?.isAvailable &&
                                item.availableTime?.startTime &&
                                item.availableTime?.endTime ? (
                                  <Tooltip
                                    title={`${item.availableTime?.startTime} - ${item.availableTime?.endTime}`}
                                  >
                                    <span>
                                      <RiInformationLine size={25} />
                                    </span>
                                  </Tooltip>
                                ) : null}
                              </Stack>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold" }}
                              >
                                Total Quantity: {item.totalQuantity}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: "bold", color: "#00A5FF" }}
                              >
                                Cost Price: {item.costPrice?.toLocaleString()}{" "}
                                IQD
                              </Typography>
                              <Typography
                                variant="body1"
                                color={"success"}
                                sx={{ fontWeight: "bold", color: "green" }}
                              >
                                Sell Price: {item.sellPrice?.toLocaleString()}{" "}
                                IQD
                              </Typography>
                              {item.discountPrice ? (
                                <Typography
                                  variant="body1"
                                  color={"error"}
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Price after discount:
                                  {item.discountPrice?.toLocaleString()} IQD
                                </Typography>
                              ) : null}
                              <Grid item xs={12}>
                                <Typography
                                  variant="body1"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  Locker Number: {item?.lockerNumber}
                                </Typography>
                              </Grid>
                            </Card>
                          </Stack>
                        </Stack>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <DataGrid
                sx={{
                  ".MuiDataGrid-cell": {
                    justifyContent: "center",
                  },
                  ".highlight": {
                    bgcolor: "#e0e0e0",
                    "&:hover": {
                      bgcolor: "#d3d3d3",
                    },
                  },
                }}
                headerHeight={80}
                getRowClassName={(params) => {
                  return params.row.isActive ? "" : "highlight";
                }}
                rows={
                  giftItemsData?.data?.map((row, index) => {
                    return { ...row, sequence: index + 1 };
                  }) || []
                }
                columns={columns}
                rowHeight={200}
                disableSelectionOnClick
                checkboxSelection
                onSelectionModelChange={(selection) => {
                  setSelectedRows(selection);
                  const selectedItemsArray = selection.map((selectedId) =>
                    giftItemsData?.data?.find(
                      (row) =>
                        row.id === selectedId &&
                        row.isActive &&
                        row.giftBrand?.isActive &&
                        row.giftSupplier?.isActive &&
                        row.totalQuantity >= 1
                    )
                  );
                  setSelectedItem(selectedItemsArray);
                }}
                isRowSelectable={(params) => {
                  return (
                    params.row.isActive &&
                    params.row.giftBrand?.isActive &&
                    params.row.giftSupplier?.isActive &&
                    params.row.totalQuantity >= 1
                  );
                }}
                selectionModel={selectedRows}
                pagination={false}
                pageSize={searchBody.pageSize}
                autoHeight
                // paginationMode="client"
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomNoRowsOverlay,
                  Toolbar: CustomToolbar,
                }}
                // onPageChange={(newPage) => handlePageChangeDataGrid(newPage)}
                // pageSize={searchBody.pageSize}
                // onPageSizeChange={(newPageSize) =>
                //   handleLimitChange(newPageSize)
                // }
                // page={page}
                // rowCount={giftItemsData.rowCount ?? 1}
                // rowsPerPageOptions={[16, 28, 52, 100]}
              />
            )}
            {open && operationMenu(activeItem)}
          </Grid>
          <Grid item xs={12}>
            <Divider />
            {loading && <LinearProgress />}
            <Stack
              sx={{ mt: 2 }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Pagination
                count={Math.ceil(
                  (giftItemsData.rowCount ?? 1) / searchBody.pageSize
                )}
                page={page}
                onChange={handlePageChange}
              />
              <Select
                size="small"
                value={searchBody.pageSize}
                onChange={(event) => {
                  dispatch(
                    saveGiftItemSearchBody({
                      ...searchBody,
                      pageSize: event.target.value,
                    })
                  );
                  setIsSearch(!isSearch);
                }}
              >
                <MenuItem value={16}>16</MenuItem>
                <MenuItem value={28}>28</MenuItem>
                <MenuItem value={52}>52</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
              <Typography>Total: {giftItemsData.rowCount}</Typography>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GiftItemsTable;
