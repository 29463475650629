import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
} from "@mui/material";
import moment from "moment";

const LoginHistory = (props) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Login date</TableCell>
            <TableCell>IP address</TableCell>
            <TableCell>Host</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data
            ? props.data.map((row) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    {moment(row.loginDate).format("DD MMM YYYY, h:mm:ss a")}{" "}
                  </TableCell>
                  <TableCell>{row.ip}</TableCell>
                  <TableCell>{row.host}</TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LoginHistory;
