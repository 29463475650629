import axios from "../axios";

class WrappingService {
  // Wish Card

  getAllWishCard(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`/giftwishcard/${pageNo}?${params}`);
  }

  createWishCard(data) {
    return axios.post("/giftwishcard", data);
  }

  getWishCardById(id) {
    return axios.get(`/giftwishcard/getById/${id}`);
  }

  findWishCardBySku(sku) {
    return axios.get(`/giftwishcard/finbysku/${sku}`);
  }

  deleteWishCard(id) {
    return axios.delete(`/giftwishcard/${id}`);
  }

  updateWishCard(id, data) {
    return axios.put(`/giftwishcard/${id}`, data);
  }

  // wrapping

  getAllWrapping(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`/giftwrappingtype/${pageNo}?${params}`);
  }

  createWrapping(data) {
    return axios.post("/giftwrappingtype", data);
  }

  getWrappingById(id) {
    return axios.get(`/giftwrappingtype/getById/${id}`);
  }

  findWrappingBySku(sku) {
    return axios.get(`/giftwrappingtype/finbysku/${sku}`);
  }

  deleteWrapping(id) {
    return axios.delete(`/giftwrappingtype/${id}`);
  }

  updateWrapping(id, data) {
    return axios.put(`/giftwrappingtype/${id}`, data);
  }
}

export default new WrappingService();
