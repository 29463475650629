import moment from "moment";
import {
  OPEN_EXTERNAL_ITEM_DEALOG,
  CREATE_EXTERNAL_ITEM,
  GET_ALL_EXTERNAL_ITEM,
  SAVE_SEARCH_BODY_EXTERNAL_ITEM,
  GET_BY_ID_EXTERNAL_ITEM,
  UPDATE_STATUS_EXTERNAL_ITEM,
  CANCEL_EXTERNAL_ITEM,
  RELOAD_EXTERNAL_ITEM,
  SAVE_SEARCH_PAGE_NO_EI,
  SAVE_CANCEL_REASON_ID,
  OPEN_EXTERNAL_ITEM_NOTE_DIALOG
} from "../actions/type";

const initialState = {
  reload: false,
  cancelReasonId: null,
  openDialog: false,
  pageCount: 0,
  rowCount: 0,
  pageSize: 15,
  pageNo: 0,
  createExternalItem: {
    url: '',
    description: '',
    quantity: 0,
  },
  data: [],
  searchBody: {
    pageSize: 15,
    status: "",
    from: moment().format("2022-01-01 00:00:00"),
    to: moment().format("YYYY-MM-DD 23:59:59"),
    phoneNumber: ''
  },
  getById: {},
  noteDialog: false,
  note: {}
};

function ExternalItemReducer(externalItem = initialState, action) {
  const { type, payload } = action;
  switch (type) {

    case GET_ALL_EXTERNAL_ITEM:
      externalItem.data = payload.data
      externalItem.pageCount = payload.pageCount
      externalItem.rowCount = payload.rowCount
      externalItem.pageSize = payload.pageSize
      return externalItem;

    case GET_BY_ID_EXTERNAL_ITEM:
      externalItem.getById = payload
      return externalItem

    case SAVE_SEARCH_BODY_EXTERNAL_ITEM:
      return { ...externalItem, searchBody: payload };

    case SAVE_SEARCH_PAGE_NO_EI:
      externalItem.pageNo = payload;
      return externalItem

    case SAVE_CANCEL_REASON_ID:
      externalItem.cancelReasonId = payload;
      return externalItem

    case OPEN_EXTERNAL_ITEM_DEALOG:
      externalItem.openDialog = !externalItem.openDialog
      return {...externalItem}

    case OPEN_EXTERNAL_ITEM_NOTE_DIALOG:
      externalItem.noteDialog = !externalItem.noteDialog
      externalItem.note = payload
      return {...externalItem}

    case RELOAD_EXTERNAL_ITEM:
      return { ...externalItem, reload: !externalItem.reload };


    default:
      return externalItem;
  }
}



export default ExternalItemReducer;
