import {
  GET_ALL_HOME_ITEMS,
  OPEN_DIALOG_HOME_ITEMS,
  RELOAD_HOME_ITEMS,
  SAVE_SEARCH_BODY_HOME_ITEMS,
} from "./type";

import helper from "src/utils/helper";
import { openAlertDialog, showErrorSnackbar } from "./snackbarMessages";
import HomeItemsService from "src/api/services/homeItems.service";

export const reloadHomeItems = () => (dispatch) => {
  dispatch({
    type: RELOAD_HOME_ITEMS,
  });
};

export const openDialogHomeItems = () => (dispatch) => {
  dispatch({
    type: OPEN_DIALOG_HOME_ITEMS,
  });
};

export const saveHomeItemsSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_BODY_HOME_ITEMS,
    payload: data,
  });
};

export const getAllHomeItems = (vendorId) => async (dispatch) => {
  try {
    dispatch(reloadHomeItems());
    const res = await HomeItemsService.getAll(vendorId);
    dispatch({
      type: GET_ALL_HOME_ITEMS,
      payload: res.data,
    });
    dispatch(reloadHomeItems());
  } catch (err) {
    dispatch(reloadHomeItems());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteHomeItems = (id, vendorId) => async (dispatch) => {
  try {
    dispatch(reloadHomeItems());
    const res = await HomeItemsService.delete(id);
    dispatch(getAllHomeItems(vendorId));
    dispatch(reloadHomeItems());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadHomeItems());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateHomeItems = (id, vendorId) => async (dispatch) => {
  try {
    dispatch(reloadHomeItems());
    const res = await HomeItemsService.update(id);
    dispatch(getAllHomeItems(vendorId));
    dispatch(reloadHomeItems());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadHomeItems());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
