import "react-perfect-scrollbar/dist/css/styles.css";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material";
import GlobalStyles from "./components/GlobalStyles";
import Routes from "./routes";
import theme from "src/theme";
import GiftsRoutes from "src/verticalRoutes";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userInfo } from "src/actions/users";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cookies = new Cookies();

  const [newtTheme, setNewTheme] = useState(theme);

  useEffect(() => {
    if (cookies.get("token")) {
      const fetchData = async () => {
        try {
          await dispatch(userInfo()).then((res) => {
            localStorage.setItem(
              "viewType",
              res.data?.data?.serviceType === "Vertical" ? "gifts" : "cross"
            );
            setNewTheme(
              createTheme({
                palette: {
                  background: {
                    default: "#f4f6f8",
                    paper: "#ffffff",
                  },
                  primary: {
                    contrastText: "#ffffff",
                    main:
                      res.data?.data?.serviceType === "Vertical"
                        ? "#00B393"
                        : "#8444FF",
                  },
                  grey: {
                    contrastText: "#ffffff",
                    main: "#808080",
                  },
                  info: {
                    contrastText: "#ffffff",
                    main: "#62cbe8",
                  },
                  text: {
                    primary: "#172b4d",
                    secondary: "#6b778c",
                    active: "#1675e0",
                  },
                },
                shadows: [
                  "none",
                  "0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 5px 8px -2px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 6px 12px -4px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 7px 12px -4px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 6px 16px -4px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 7px 16px -4px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 9px 18px -8px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -8px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 11px 20px -8px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 12px 22px -8px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 13px 22px -8px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 14px 24px -8px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 16px 28px -8px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 18px 30px -8px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 20px 32px -8px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 22px 34px -8px rgba(0,0,0,0.25)",
                  "0 0 1px 0 rgba(0,0,0,0.31), 0 24px 36px -8px rgba(0,0,0,0.25)",
                ],
                typography: {
                  h1: {
                    fontWeight: 500,
                    fontSize: 35,
                    letterSpacing: "-0.24px",
                  },
                  h2: {
                    fontWeight: 500,
                    fontSize: 29,
                    letterSpacing: "-0.24px",
                  },
                  h3: {
                    fontWeight: 500,
                    fontSize: 24,
                    letterSpacing: "-0.06px",
                  },
                  h4: {
                    fontWeight: 500,
                    fontSize: 20,
                    letterSpacing: "-0.06px",
                  },
                  h5: {
                    fontWeight: 500,
                    fontSize: 16,
                    letterSpacing: "-0.05px",
                  },
                  h6: {
                    fontWeight: 500,
                    fontSize: 14,
                    letterSpacing: "-0.05px",
                  },
                  overline: {
                    fontWeight: 500,
                  },
                },
              })
            );
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.documentElement.style.setProperty(
    "--icon-bg-color",
    newtTheme.palette.primary.main
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={newtTheme}>
        <GlobalStyles />
        {localStorage.getItem("viewType") === "cross" ? (
          <Routes />
        ) : (
          <GiftsRoutes />
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
