import { useEffect } from "react";
import {
  Button,
  Grid,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Divider,
  List,
  Card,
  CardHeader,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { useForm } from "react-hook-form";
import { useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  openTransferListDialog,
  resetForm,
  deleteAgentCityConfig,
  createAgentCityConfigFromTransferList,
  setAgentId,
  getAllAgentCityConfig,
} from "src/actions/agentCityConfig";
import { getAllAddresses } from "src/actions/addresses";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

const CityTransferList = () => {
  const dispatch = useDispatch();

  const _AgentCityConfig = useSelector(
    (state) => state.agentCityConfig.transferList
  );
  const _AgentId = useSelector((state) => state.agentCityConfig.agentId);
  const _Addresses = useSelector((state) => state.addresses);

  const [selectedProvince, setSelectedProvince] = useState({
    nameAR: "",
    nameUS: "",
    cities: [],
  });

  function handleDelete() {
    dispatch(deleteAgentCityConfig(agentCitiesChecked, _AgentId)).then(() => {
      setAgentCitiesChecked(null);
      dispatch(getAllAddresses());
    });
  }

  function handleAdd() {
    dispatch(
      createAgentCityConfigFromTransferList(
        {
          agentId: _AgentId,
          cityIds: leftChecked,
        },
        _AgentId
      )
    ).then(() => dispatch(getAllAddresses()));
  }

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  const [agentCitiesIds, setAgentCitiesIds] = useState([]);
  const [provinceId, setProvinceId] = useState(null);

  useEffect(() => {
    setAgentCitiesIds(_AgentCityConfig.data.map((city) => city.cityId));
    setRight(_AgentCityConfig.data.map((city) => city.cityId));
  }, [_AgentCityConfig, selectedProvince, _Addresses]);

  useEffect(() => {
    setProvinceId(
      _Addresses.flatMap((province) =>
        province.cities.filter((x) => x.id == agentCitiesIds[0])
      )[0]?.provinceId
    );
  }, [agentCitiesIds, _Addresses]);

  useEffect(() => {
    setSelectedProvince(
      _Addresses.filter((x) => x.id === provinceId)[0] ?? {
        nameAR: "",
        nameUS: "",
        cities: [],
      }
    );
  }, [provinceId, _Addresses]);

  useEffect(() => {
    setLeft(
      provinceId
        ? selectedProvince.cities
            ?.filter((city) => !agentCitiesIds.includes(city.id))
            ?.map((city) => city.id)
        : []
    );
  }, [agentCitiesIds, provinceId, selectedProvince.cities]);

  const [checked, setChecked] = useState([]);
  const [agentCitiesChecked, setAgentCitiesChecked] = useState(null);
  const [right, setRight] = useState([]);
  const [left, setLeft] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAgentCitiesToggle = (value) => () => {
    setAgentCitiesChecked(agentCitiesChecked === value ? null : value);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    setAgentCitiesChecked(null);
    handleAdd();
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    handleDelete();
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1, width: "100%" }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: "65vh",
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {selectedProvince.cities
          ?.filter((city) => !agentCitiesIds.includes(city.id))
          .map((city) => {
            const labelId = `transfer-list-all-item-${city.id}-label`;

            return (
              <ListItem
                key={city.id}
                role="listitem"
                button
                onClick={handleToggle(city.id)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(city.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={`${city.nameUS} - ${city.nameAR}`}
                />
              </ListItem>
            );
          })}
      </List>
    </Card>
  );
  const selectedCustomList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1, width: "100%" }}
        avatar={
          <Checkbox
            // onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${items.length} Cities`}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: "65vh",
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {_AgentCityConfig.data?.map((city, index) => {
          const labelId = `transfer-list-all-item-${city.cityId}-label`;

          return (
            <ListItem
              key={city.id}
              role="listitem"
              button
              onClick={handleAgentCitiesToggle(city.id)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={agentCitiesChecked === city.id ? true : false}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${city.cityNameUS} - ${city.cityNameAR}`}
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: _AgentCityConfig.data,
  });

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const onSubmit = (data) => {
    // dispatch(createAgentCityConfig(data, PageNo + 1, searchFilter?.pageSize));
  };

  const handleDialog = () => {
    dispatch(openTransferListDialog());
  };

  return (
    <CustomDialog
      title={"Cities"}
      isOpen={_AgentCityConfig.dialog}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handleDialog();
        setAgentCitiesChecked(null);
        dispatch(setAgentId(null));
        dispatch(getAllAgentCityConfig());
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {/* <Grid item xs={12}>
            <Controller
              name="provinceId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={_Addresses}
                  getOptionLabel={(option) =>
                    option.nameAR
                      ? `${option.nameAR} , ${option.nameUS}`
                      : "Not selected"
                  }
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField required {...params} label="Provinces" />
                  )}
                  onChange={(event, value) => {
                    setSelectedProvince(
                      value ? value : { nameAR: "", nameUS: "", cities: [] }
                    );
                    setValue("provinceId", value ? value.id : 0);
                    setLeft(
                      value
                        ? value.cities
                            ?.filter(
                              (city) => !agentCitiesIds.includes(city.id)
                            )
                            ?.map((city) => city.id)
                        : []
                    );
                  }}
                  value={
                    _Addresses.filter((x) => x.id == field.value)[0] ?? null
                  }
                />
              )}
            />
          </Grid> */}
          <Grid item xs={5}>
            {customList("All cities", left)}
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                <AiOutlineArrowRight />
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={agentCitiesChecked ? false : true}
                aria-label="move selected left"
              >
                {left.length <= 0 ? (
                  <DeleteForeverIcon />
                ) : (
                  <AiOutlineArrowLeft />
                )}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            {selectedCustomList("Cities of the agent", right)}
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default CityTransferList;
