import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, Grid, LinearProgress, Chip } from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import { CustomDialog } from "src/components/CustomDialog";
import EditIcon from "@mui/icons-material/Edit";
import {
  deleteWishCard,
  deleteWrappingType,
  getAllWishCard,
  getAllIWrappingType,
  getWishCardById,
  getWrappingTypeById,
  resetWishCardForm,
  resetWrappingTypeForm,
  saveWishCardPageNum,
  saveWishCardSearchBody,
  saveWrappingTypePageNum,
  saveWrappingTypeSearchBody,
} from "src/actions/gifts/wrapping";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import useCanShow from "src/components/CanShow";

const WrappingTable = () => {
  const dispatch = useDispatch();

  const editWishCardRole = useCanShow("44-05");
  const deleteWishCardRole = useCanShow("44-06");
  const editWrappingTypeRole = useCanShow("45-05");
  const deleteWrappingTypeRole = useCanShow("45-06");

  const wishCardData = useSelector((state) => state.wrapping.wishCardData);
  const wrappingData = useSelector((state) => state.wrapping.wrappingData);
  const wrapping = useSelector((state) => state.wrapping);
  const loadingWishCard = useSelector((state) => state.wrapping.wishCardReload);
  const loadingWrappingType = useSelector(
    (state) => state.wrapping.wrappingReload
  );
  const wishCardSearchBody = useSelector(
    (state) => state.wrapping.wishCardSearchBody
  );
  const wrappingSearchBody = useSelector(
    (state) => state.wrapping.wrappingSearchBody
  );

  const [openWishCardDeleteDialog, setOpenWishCardDeleteDialog] =
    useState(false);
  const [openWrappingTypeDeleteDialog, setOpenWrappingTypeDeleteDialog] =
    useState(false);
  const [page, setPage] = useState(0);
  const [showPanZoom, setShowPanZoom] = useState("");
  const [openImageDialog, setOpenImageDialog] = useState(false);

  useEffect(() => {
    dispatch(getAllWishCard(page + 1, wishCardSearchBody));
  }, [page, dispatch, wishCardSearchBody]);

  useEffect(() => {
    dispatch(getAllIWrappingType(page + 1, wrappingSearchBody));
  }, [page, dispatch, wrappingSearchBody]);

  const handleWrappingTypePageChange = (newPage) => {
    setPage(newPage);
    dispatch(saveWrappingTypePageNum(newPage));
  };

  const handleWishCardPageChange = (newPage) => {
    setPage(newPage);
    dispatch(saveWishCardPageNum(newPage));
  };

  function deleteSelectedWrappingType(id) {
    dispatch(deleteWrappingType(id, page + 1, wrappingSearchBody)).then((x) => {
      dispatch(resetWrappingTypeForm());
      setOpenWrappingTypeDeleteDialog(false);
    });
  }

  function deleteSelectedWishCard(id) {
    dispatch(deleteWishCard(id, page + 1, wishCardSearchBody)).then((x) => {
      dispatch(resetWishCardForm());
      setOpenWishCardDeleteDialog(false);
    });
  }

  const handleOpenPanZoom = (url) => {
    setOpenImageDialog(true);
    setShowPanZoom(url);
  };

  const handleDialogClose = () => {
    setOpenImageDialog(false);
  };

  const wishCardColumns = [
    {
      field: "#",
      headerName: "#",
      headerAlign: "center",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "attachment",
      headerName: "Image",
      headerAlign: "center",
      flex: 2,
      renderCell: (params) =>
        params.row.attachment ? (
          <Box
            sx={{ p: 1 }}
            onClick={() => handleOpenPanZoom(params.row.attachment?.url)}
            component="img"
            src={params.row.attachment?.url}
            width={150}
          />
        ) : null,
    },
    {
      field: "isActive",
      headerName: "Is Active",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) =>
        params.row.isActive ? (
          <Chip color="success" label={"Active"} size="small" />
        ) : (
          <Chip color="error" label={"Deactivated"} size="small" />
        ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          hidden={!editWishCardRole}
          showInMenu
          onClick={(e) => {
            dispatch(getWishCardById(params.row.id, true));
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForever color="error" />}
          label="Delete"
          hidden={!deleteWishCardRole}
          showInMenu
          onClick={(e) => {
            dispatch(getWishCardById(params.row.id, false)).then((x) =>
              setOpenWishCardDeleteDialog(true)
            );
          }}
        />,
      ],
    },
  ];

  const wrappingColumns = [
    {
      field: "#",
      headerName: "#",
      headerAlign: "center",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "attachment",
      headerName: "Image",
      headerAlign: "center",
      flex: 2,
      renderCell: (params) =>
        params.row.attachment ? (
          <Box
            sx={{ p: 1 }}
            onClick={() => handleOpenPanZoom(params.row.attachment?.url)}
            component="img"
            src={params.row.attachment?.url}
            width={150}
          />
        ) : null,
    },
    {
      field: "isActive",
      headerName: "Is Active",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) =>
        params.row.isActive ? (
          <Chip color="success" label={"Active"} size="small" />
        ) : (
          <Chip color="error" label={"Deactivated"} size="small" />
        ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          hidden={!editWrappingTypeRole}
          showInMenu
          onClick={(e) => {
            dispatch(getWrappingTypeById(params.row.id, true));
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForever color="error" />}
          label="Delete"
          hidden={!deleteWrappingTypeRole}
          showInMenu
          onClick={(e) => {
            dispatch(getWrappingTypeById(params.row.id, false)).then((x) =>
              setOpenWrappingTypeDeleteDialog(true)
            );
          }}
        />,
      ],
    },
  ];

  return (
    <Box>
      <CustomDialog
        isOpen={openImageDialog}
        handleClose={() => handleDialogClose()}
        title="Images"
        maxWidth="lg"
      >
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              width: "50%",
              height: "auto",
            }}
          >
            <ReactPanZoom image={showPanZoom} alt="Image alt text" />
          </div>
        </div>
      </CustomDialog>
      {openWishCardDeleteDialog && (
        <CustomDialog
          isOpen={openWishCardDeleteDialog}
          handleClose={() => {
            setOpenWishCardDeleteDialog(false);
            dispatch(resetWishCardForm());
          }}
          isActiveDelete
          isRequierdActions
          title="Delete confirmation"
          maxWidth="sm"
          onDelete={() => deleteSelectedWishCard(wrapping.wishCardForm.id)}
        ></CustomDialog>
      )}
      {openWrappingTypeDeleteDialog && (
        <CustomDialog
          isOpen={openWrappingTypeDeleteDialog}
          handleClose={() => {
            setOpenWrappingTypeDeleteDialog(false);
            dispatch(resetWrappingTypeForm());
          }}
          isActiveDelete
          isRequierdActions
          title="Delete confirmation"
          maxWidth="sm"
          onDelete={() => deleteSelectedWrappingType(wrapping.wrappingForm.id)}
        ></CustomDialog>
      )}
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Card>
            <PerfectScrollbar>
              <DataGrid
                sx={{
                  ".MuiDataGrid-cell": {
                    justifyContent: "center",
                  },
                  "& .css-204u17-MuiDataGrid-main": {
                    padding: "15px",
                  },
                  "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                    padding: "15px",
                  },
                }}
                autoHeight
                columns={wishCardColumns}
                rows={wishCardData?.data || []}
                loading={loadingWishCard}
                disableSelectionOnClick
                rowHeight={150}
                pagination
                paginationMode="server"
                rowCount={wishCardData.rowCount}
                pageSize={wishCardSearchBody.pageSize}
                page={page}
                onPageChange={(newPage) => handleWishCardPageChange(newPage)}
                onPageSizeChange={(newPageSize) =>
                  dispatch(
                    saveWishCardSearchBody({
                      ...wishCardSearchBody,
                      pageSize: newPageSize,
                    })
                  )
                }
                rowsPerPageOptions={[5, 15, 25, 75, 100]}
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            </PerfectScrollbar>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card>
            <PerfectScrollbar>
              <DataGrid
                sx={{
                  ".MuiDataGrid-cell": {
                    justifyContent: "center",
                  },
                  "& .css-204u17-MuiDataGrid-main": {
                    padding: "15px",
                  },
                  "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                    padding: "15px",
                  },
                }}
                autoHeight
                columns={wrappingColumns}
                rows={wrappingData?.data || []}
                loading={loadingWrappingType}
                disableSelectionOnClick
                rowHeight={150}
                pagination
                paginationMode="server"
                rowCount={wrappingData.rowCount}
                pageSize={wrappingSearchBody.pageSize}
                page={page}
                onPageChange={(newPage) =>
                  handleWrappingTypePageChange(newPage)
                }
                onPageSizeChange={(newPageSize) =>
                  dispatch(
                    saveWrappingTypeSearchBody({
                      ...wrappingSearchBody,
                      pageSize: newPageSize,
                    })
                  )
                }
                rowsPerPageOptions={[5, 15, 25, 75, 100]}
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            </PerfectScrollbar>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WrappingTable;
