import {
  CREATE_ORDER_REASONS,
  DELETE_ORDER_REASONS,
  GET_ALL_ORDER_REASONS,
  UPDATE_ORDER_REASONS,

} from "../actions/type";

const initialState = [];

function orderReasonReducer(orderReasons = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ORDER_REASONS:
      orderReasons.data.push(payload)
      orderReasons.rowCount= orderReasons.rowCount + 1
      orderReasons.pageCount = parseInt((orderReasons.rowCount / 10),10) + 1
      return orderReasons;

    case GET_ALL_ORDER_REASONS:
      return payload;

    case UPDATE_ORDER_REASONS:
      var indexToChange = orderReasons.data.indexOf(
        orderReasons.data.filter((x) => x.id === payload.id)[0]
      );
      orderReasons.data.splice(indexToChange, 1, payload);
      return orderReasons;

    case DELETE_ORDER_REASONS:
      orderReasons.data =orderReasons.data.filter((orderReason) => orderReason.id !== payload)
      orderReasons.rowCount= orderReasons.rowCount -1
      return orderReasons


    default:
      return orderReasons;
  }
}

export default orderReasonReducer;
