import {
  Grid,
  Stack,
  Typography,
  Chip,
  Divider,
  Button,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { useEffect, useRef } from "react";
import { printSku } from "src/actions/gifts/giftItem";
import { getItemSupplierList } from "src/actions/gifts/supplier";
import CustomSpinner from "src/components/CustomSpinner";
import { useReactToPrint } from "react-to-print";
import Barcode from "react-barcode/lib/react-barcode";

const PrintSKUForm = (props) => {
  const dispatch = useDispatch();
  const printRef = useRef(null);

  const giftItem = useSelector((state) => state.giftItems.printSkuForm);
  const loading = useSelector((state) => state.giftItems.reload);

  useEffect(() => {
    dispatch(getItemSupplierList());
  }, [dispatch]);

  const handleDialog = () => {
    dispatch(printSku([]));
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: "@page { margin: 10px }",
  });

  return (
    <CustomDialog
      title={"Print SKU"}
      isOpen={giftItem.dialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      {loading ? (
        <CustomSpinner />
      ) : (
        <Box ref={printRef}>
          {giftItem?.data?.map((variant, index) => (
            <Grid container spacing={0} key={index} paddingTop={2} paddingLeft={4} paddingRight={4}>
              <Grid item xs={5}>
                <Stack direction="row" spacing={0.3} alignItems="center">
                  <Typography>SKU:</Typography>
                  <Chip label={variant.sku} key={index} size="small" />
                </Stack>
                {variant.giftItemOptions.map((option) => (
                  <Stack direction="row" spacing={0.3} paddingTop={1}>
                    <Typography>Options Name:</Typography>
                    <Chip label={option.nameEn} key={index} size="small" />
                  </Stack>
                ))}
                <Typography paddingTop={1}>
                  Quantity: {variant.quantity}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Barcode width={0.6} height={35} displayValue={true} value={variant.sku} />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          ))}
        </Box>
      )}
      <Button
        variant="contained"
        fullWidth
        color="primary"
        onClick={() => {
          handlePrint();
        }}
      >
        Print
      </Button>
    </CustomDialog>
  );
};
export default PrintSKUForm;
