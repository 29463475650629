import { Bar } from "react-chartjs-2";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lastSevenDayssummary } from "src/actions/ticket/statistics";
import moment from "moment";
import CustomSpinner from "src/components/CustomSpinner";

const LastSevenDaysSummary = () => {
  const dispatch = useDispatch();

  const lastDaysSummary = useSelector(
    (state) => state.ticketStatistic.lastDaysSummary
  );

  useEffect(() => {
    dispatch(lastSevenDayssummary());
  }, []);

  const [_data, setData] = useState({
    total: [],
    solved: [],
    new: [],
    inProgress: [],
    canceled: [],
    date: [],
  });

  const theme = useTheme();

  const newLastDaysSummary = () => {
    var date = lastDaysSummary.map((row) => moment(row.date).format("MMM-D"));
    var _new = lastDaysSummary.map((row) => row.new);
    var inProgress = lastDaysSummary.map((row) => row.inProgress);
    var canceled = lastDaysSummary.map((row) => row.canceled);
    var solved = lastDaysSummary.map((row) => row.count);
    var total = lastDaysSummary.map((row) => row.total);
    lastDaysSummary.map((x) => {
      setData({
        total: total,
        solved: solved,
        canceled: canceled,
        inProgress: inProgress,
        new: _new,
        date: date,
      });
    });
  };

  useEffect(() => {
    newLastDaysSummary();
  }, [lastDaysSummary]);

  const data = {
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: _data.new,
        label: "New",
        maxBarThickness: 10,
      },
      {
        backgroundColor: colors.orange[300],
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: _data.inProgress,
        label: "In Progress",
        maxBarThickness: 10,
      },
      {
        backgroundColor: colors.green[300],
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: _data.solved,
        label: "Solved",
        maxBarThickness: 10,
      },
      {
        backgroundColor: colors.red[300],
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: _data.canceled,
        label: "Canceled",
        maxBarThickness: 10,
      },
      {
        backgroundColor: colors.blueGrey[300],
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: _data.total,
        label: "Total",
        maxBarThickness: 10,
      },
    ],
    labels: _data.date,
  };

  const options = {
    animation: true,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  return !lastDaysSummary ? (
    <CustomSpinner />
  ) : (
    <Card>
      <CardHeader title="Last Seven Days Summary" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: "relative",
          }}
        >
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default LastSevenDaysSummary;
