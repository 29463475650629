import moment from "moment";
import {
  PURCHASING_BILL_RESET_FORM,
  PURCHASING_BILL_GET_BY_ID,
  PURCHASING_BILL_PAGE_NUMBER,
  PURCHASING_BILL_SEARCH_BODY,
  PURCHASING_BILL_OPEN_DIALOG,
  PURCHASING_BILL_RELOAD,
  PURCHASING_BILL_GET_ALL,
  PURCHASING_BILL_EXPORT_TO_CSV,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  savePurchasingBillPageNum: 1,
  searchBody: {
    pageSize: 15,
    billNo: "",
    fromDate: null,
    toDate: null,
    vendorId: null,
    region: null,
    status: null,
    currency: null,
    bank: null,
  },
  attachmentUrl: null,
  form: {
    id: null,
    billNo: "",
    date: moment().format("YYYY-MM-DD"),
    attachment: "",
    vendorId: null,
    region: null,
    discount: "",
    amount: "",
    currency: null,
    bank: null,
  },
  data: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  exportToCsv: [],
};

function purchasingBillReducer(purchasingBill = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PURCHASING_BILL_GET_ALL:
      purchasingBill.data.data = payload.data;
      purchasingBill.data.total = payload.pageCount;
      purchasingBill.data.rowCount = payload.rowCount;
      return purchasingBill;

    case PURCHASING_BILL_GET_BY_ID:
      purchasingBill.form.id = payload.id;
      purchasingBill.form.billNo = payload.billNo;
      purchasingBill.form.date = payload.date;
      purchasingBill.form.attachment = payload.attachment?.id ?? "";
      purchasingBill.form.vendorId = payload.vendor.id;
      purchasingBill.form.region = payload.region;
      purchasingBill.form.discount = payload.discount;
      purchasingBill.form.amount = payload.amount;
      purchasingBill.form.currency = payload.currency;
      purchasingBill.form.bank = payload.bank;
      return purchasingBill;

    case PURCHASING_BILL_RESET_FORM:
      purchasingBill.form.id = null;
      purchasingBill.form.billNo = "";
      purchasingBill.form.date = moment().format("YYYY-MM-DD");
      purchasingBill.form.attachment = "";
      purchasingBill.form.vendorId = null;
      purchasingBill.form.region = null;
      purchasingBill.form.discount = "";
      purchasingBill.form.amount = "";
      purchasingBill.form.currency = "";
      purchasingBill.form.bank = "";
      return purchasingBill;

    case PURCHASING_BILL_PAGE_NUMBER:
      purchasingBill.savePurchasingBillPageNum = payload;
      return purchasingBill;

    case PURCHASING_BILL_SEARCH_BODY:
      return { ...purchasingBill, searchBody: payload };

    case PURCHASING_BILL_OPEN_DIALOG:
      return {
        ...purchasingBill,
        openDialog: !purchasingBill.openDialog,
        attachmentUrl: payload ?? "",
      };

    case PURCHASING_BILL_RELOAD:
      return { ...purchasingBill, reload: !purchasingBill.reload };

    case PURCHASING_BILL_EXPORT_TO_CSV:
      purchasingBill.exportToCsv = payload;
      return purchasingBill;

    default:
      return purchasingBill;
  }
}

export default purchasingBillReducer;
