import { useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Stack
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getTicketsummary } from "src/actions/ticket/statistics";
import { green, orange } from '@mui/material/colors';
import CustomSpinner from "src/components/CustomSpinner";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import FiberNewIcon from '@mui/icons-material/FiberNew';

const TicketSummary = () => {
  const dispatch = useDispatch()

  const summaryData = useSelector((state) => state.ticketStatistic.summary)

  useEffect(() => {
    dispatch(getTicketsummary())
  }, [])

  return (
    (!summaryData ? (
      <CustomSpinner />
    ) : (
      <Stack direction='row' spacing={2}>
        <Card sx={{ height: '100%', width: '100%' }}>
          <CardContent>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                >
                  All Ticket
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  {summaryData.ticketCount}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar
                  sx={{
                    backgroundColor: orange[600],
                    height: 56,
                    width: 56
                  }}
                >
                  <FiberNewIcon />
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ height: '100%', width: '100%' }} >
          <CardContent>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="h6"
                >
                  Solved Ticket
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="h3"
                >
                  {summaryData.solvedTicketCount}
                </Typography>
              </Grid>
              <Grid item>
                <Avatar
                  sx={{
                    backgroundColor: green[600],
                    height: 56,
                    width: 56
                  }}
                >
                  <TaskAltIcon />
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Stack>

    ))
  );
};

export default TicketSummary;
