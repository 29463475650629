import axios from "../axios";

class GiftService {
  createGiftsCategory(data) {
    return axios.post("/giftitemcategory", data);
  }

  getGiftsCategoryById(id) {
    return axios.get(`/giftitemcategory/getById/${id}`);
  }

  getGiftsCategoryList() {
    return axios.get(`/giftitemcategory/list`);
  }

  getGiftsCategoryAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`giftitemcategory/${data.pageNo}?${params}`);
  }

  deleteGiftsCategory(id) {
    return axios.delete(`/giftitemcategory/${id}`);
  }

  updateGiftsCategory(id, data) {
    return axios.put(`/giftitemcategory/${id}`, data);
  }
}

export default new GiftService();
