import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Chip, Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Ticket_Priority } from "src/utils/common";
import { changePriority, reloadTicketDetails } from "src/actions/ticket/ticket";

export default function TicketPriorityMenu({ priority = "", ticketId = null }) {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const priorityNew = priority.filter((x) => x !== null)[0];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [_priority, setPriority] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  const ticketDetailsReload = useSelector((state) => state.tickets.reload);

  const updateOrderPriority = async (e) => {
    dispatch(reloadTicketDetails(ticketDetailsReload));
    dispatch(
      changePriority(
        {
          ticketId: ticketId,
          priority: e.target.id,
        },
        ticketId
      )
    );
  };

  const getPriorityIcon = (priority) => {
    var color;
    priorityNew == "High"
      ? (color = "warning")
      : priorityNew == "Urgent"
      ? (color = "error")
      : priorityNew == "Normal"
      ? (color = "info")
      : (color = "primary");

    return (
      <Chip
        size="small"
        label={priority}
        color={color}
        clickable
        onClick={handleClick}
      />
    );
  };

  return (
    <React.Fragment>
      {getPriorityIcon(_priority ? _priority : priority)}
      <Menu
        anchorEl={anchorEl}
        open={
          _UserInfo.roleOEndpoint.map((data) => data.Code).includes("T3-02")
            ? open
            : false
        }
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {Ticket_Priority.map((priority) => (
          <MenuItem
            key={priority.id}
            id={priority.id}
            onClick={updateOrderPriority}
          >
            <ListItemIcon>
              <Icon>{priority.icon}</Icon>
            </ListItemIcon>
            {priority.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
