import {
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Divider,
  Box,
  Card,
  CardContent,
  LinearProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import {
  assignToLocker,
  getAvailableLockersList,
  openAvailableLockersDialog,
} from "src/actions/locker";

const AvailableLockersDialog = (props) => {
  const dispatch = useDispatch();

  const _Locker = useSelector((state) => state.locker);
  const lockerData = useSelector((state) => state.locker.lockersList);
  const loading = useSelector((state) => state.locker.reload);

  useEffect(() => {
    dispatch(getAvailableLockersList(props.orderId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleManualLockerSelection = (selectedLocker) => {
    dispatch(
      assignToLocker(
        {
          orderId: props.selectedRows.length > 0 ? null : props.orderId,
          lockerId: selectedLocker,
          orderItemsIds:
            props.selectedRows.length > 0 ? props.selectedRows : null,
        },
        props.orderId
      )
    ).then((x) => {
      if (x?.message === "Success") {
        dispatch(openAvailableLockersDialog());
        props.onSuccess();
      }
    });
  };

  const handleDialog = () => {
    dispatch(openAvailableLockersDialog());
  };

  return (
    <Dialog
      open={_Locker.availableLockersDialog}
      keepMounted
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Typography color="primary">Lockers</Typography>
      </DialogTitle>
      <DialogContent>
        {loading && <LinearProgress />}{" "}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid items xs={12}>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                sx={{
                  height: { md: "calc(100vh - 300px)", xs: 1000 },
                  overflow: "auto",
                }}
              >
                {lockerData?.map((item, index) => (
                  <Box
                    key={index}
                    textAlign="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      handleManualLockerSelection(item.id);
                    }}
                  >
                    <Card
                      sx={{
                        m: 1,
                        width: 100,
                        height: 100,
                        backgroundColor:
                          item.isActive === false
                            ? "#F29C9C"
                            : item.isEmpty === false
                            ? "#D1B3FF"
                            : "#81c784",
                      }}
                    >
                      <CardContent>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item xs={12} md={12}>
                            <Typography variant="h5">{item.number}</Typography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Typography variant="h5">
                              {item.isActive === false
                                ? "Inactive"
                                : item.isEmpty === false
                                ? "Order"
                                : "Available"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default AvailableLockersDialog;
