const Logo = ({ vertical = false, style = {}, altSrc }) => (
  <img
    alt="Logo"
    src={
      altSrc
        ? altSrc
        : vertical
        ? "/static/images/logo/orderii_gifts_white.png"
        : "/static/images/logo/orderii_logo_white.png"
    }
    style={style}
  />
);

export default Logo;
