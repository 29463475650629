import axios from "../axios";

class UncompletedCartService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`cart/uncompleted/${pageNo}?${params}`);
  }
}

export default new UncompletedCartService();
