import {
  Card,
  CardHeader,
  Divider,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { income } from "src/actions/reports";
import CustomSpinner from "src/components/CustomSpinner";
import PerfectScrollbar from "react-perfect-scrollbar";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { red } from "@mui/material/colors";
import moment from "moment";

export default function Income(props) {
  const dispatch = new useDispatch();
  const [loading, setLoading] = useState(false);
  const [_Income, setIncome] = useState([]);

  useEffect(() => {
    incomeReport();
  }, [props.date, props.setSearch]);

  function incomeReport() {
    setLoading(true)
    dispatch(
      income({
        from: props.date.startDate,
        to: props.date.endDate,
        forCancelled: props.forCancelled,
      })
    ).then((res) => {
      setIncome(res.data);
      setLoading(false)
    }).catch((err) => { })
  }

  const colums = [
    {
      field: 'sequence',
      headerName: "#",
      flex: 0.3,
    },
    {
      field: 'vendorName',
      headerName: "Vendor Name",
      flex: 1.2,
    },
    {
      field: 'region',
      headerName: "Region",
      flex: 1,
    },
    {
      field: 'subTotal',
      headerName: "SubTotal ($)",
      flex: 1,
    },
    {
      field: 'dangerousGoods',
      headerName: "Dangerous Goods ($)",
      flex: 1,
    },
    {
      field: 'localShipping',
      headerName: "Local Shipping ($)",
      flex: 1,
    },
    {
      field: 'tax',
      headerName: "Tax ($)",
      flex: 1,
    },
    {
      field: 'internationalShipping',
      headerName: "International Shipping ($)",
      flex: 1,
    },
    {
      field: 'customs',
      headerName: "Customs ($)",
      flex: 1,
    },
    {
      field: 'orderiiFee',
      headerName: "Orderii Fee ($)",
      flex: 1,
    },
    {
      field: 'deliveryCostBg',
      headerName: "Delivery Cost Baghdad ($)",
      flex: 1,
    },
    {
      field: 'deliveryCostOther',
      headerName: "Delivery Cost Other ($)",
      flex: 1,
    },
    {
      field: 'deliveryCost',
      headerName: "Total Delivery Cost ($)",
      flex: 1,
    },
    {
      field: 'insurance',
      headerName: "Insurance ($)",
      flex: 1,
    },
    {
      field: 'totalAmountBeforeDiscount',
      headerName: "Total Amount Before Discount ($)",
      flex: 1,
    },
    {
      field: 'totalDiscount',
      headerName: "Total Discount ($)",
      flex: 1,
    },
    {
      field: 'totalAmountAfterDiscount',
      headerName: "Total Amount After Discount ($)",
      flex: 1,
    },
  ]


  function CustomToolbar() {
    return (
      <div>
        <Box>
          <Box>
            <Card>
              <CardHeader
                title="Income Report"
                subheader={
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption">
                      {props.date.startDate + " - " + props.date.endDate}
                    </Typography>
                  </Stack>
                }
              />
              <Divider />
            </Card>
          </Box>
          <Box>
            <GridToolbarContainer>
              <GridToolbarExport
                csvOptions={{
                  fileName: `Income Reports || Orderii || ${moment(props.date.startDate).format("YYYY-MM-DD") + " - " + moment(props.date.endDate).format("YYYY-MM-DD")}`,
                  utf8WithBom: true,
                }}
              />
            </GridToolbarContainer>
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <Card style={{ marginTop: 10 }} ref={props.print}>
      <PerfectScrollbar>
        <Box>
          {loading ? (
            <CustomSpinner />
          ) : (
            <Box>
              {_Income.length == 0 ? (
                <Box sx={{ textAlign: "center", marginY: 2 }}>
                  <Typography sx={{ color: red[600] }}>
                    <DangerousOutlinedIcon fontSize="large" />
                  </Typography>
                  <Typography>No Data</Typography>
                </Box>
              ) : (
                <Box sx={{ height: 'calc(100vh - 320px)' }}>
                  <DataGrid
                    sx={{
                      "& .MuiDataGrid-row ": {
                        textAlign: "center",
                      },
                      "& .css-204u17-MuiDataGrid-main": {
                        paddingLeft: "15px",
                      },
                      "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                        paddingLeft: "15px",
                      },
                    }}
                    columns={colums}
                    rows={_Income.map((row, index) => {
                      return { ...row, sequence: index + 1 };
                    })}
                    disableSelectionOnClick
                    getRowId={(params) => params.sequence}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
