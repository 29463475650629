import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Box,
  Typography,
  TextField,
  Button,
  Autocomplete,
  Grid,
  Stack,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/styles";
import { getAllAddresses } from "src/actions/addresses";
import { AccordionDetails } from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordion from "@mui/material/Accordion";
import { user_role } from "src/utils/common";
import { CustomDialog } from "src/components/CustomDialog";
import CustomText from "src/components/CustomText";
import { showErrorSnackbar } from "src/actions/snackbarMessages";
import { Controller, useForm } from "react-hook-form";
import {
  createUser,
  openUsersDialog,
  resetForm,
  resetUserPassword,
  saveUsersPageNum,
  saveUsersSearchBody,
  updateUser,
} from "src/actions/users";

var generator = require("generate-password");

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export default function UsersForm() {
  const saveUsersPageNO = useSelector((state) => state.users.saveUsersPageNum);
  const users = useSelector((state) => state.users);
  const addresses = useSelector((state) => state.addresses);

  const [page, setPage] = useState(saveUsersPageNO ? saveUsersPageNO : 0);
  const [selectedProvince, setSelectedProvince] = useState({
    nameAR: "",
    nameUS: "",
    cities: [],
  });
  const [expanded, setExpanded] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setloading] = useState(false);

  const isAdd = users.form.id ? false : true;
  const { control, reset, watch, register, handleSubmit, setValue } = useForm({
    defaultValues: users.form,
  });

  useEffect(() => {
    reset({ ...users.form });
  }, [users.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const _searchBody = useSelector(
    (state) =>
      state.users.searchUsersBody ?? {
        phoneNumber: "",
        fullName: "",
        role: 0,
        walletType: null,
      }
  );
  const [searchBody, setSearchBody] = useState(_searchBody);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(saveUsersSearchBody(_searchBody));
  }, [_searchBody]);

  useEffect(() => {
    dispatch(saveUsersPageNum(page));
  }, [page]);

  useEffect(() => {
    dispatch(getAllAddresses());
  }, []);

  const gender = [
    { id: 0, name: `Male` },
    { id: 1, name: `Female` },
  ];

  function resetPassword(id) {
    if (newPassword === confirmNewPassword)
      dispatch(
        resetUserPassword({
          id: id,
          password: newPassword,
        })
      );
    else dispatch(showErrorSnackbar("Password didn't match!"));
  }

  const handlDialog = () => {
    dispatch(openUsersDialog(users.openDialog));
  };

  const onSubmit = (data) => {
    if (isAdd) {
      if (data.phoneNumber[0] == "0") {
        data.phoneNumber = data.phoneNumber.substring(1);
      }
      if (!data.password)
        dispatch(showErrorSnackbar("Password should not be empty"));
      else {
        setloading(true);
        dispatch(createUser(data))
          .then((res) => {
            dispatch(saveUsersSearchBody({ ..._searchBody, ...searchBody }));
            setloading(false);
          })
          .catch((err) => {
            // setloading(false);
          });
      }
    } else {
      setloading(true);
      dispatch(updateUser(data))
        .then((res) => {
          dispatch(saveUsersSearchBody({ ..._searchBody, ...searchBody }));
          setloading(false);
        })
        .catch((err) => {
          setloading(false);
        });
    }
  };

  return (
    <Box>
      <CustomDialog
        isOpen={users.openDialog}
        handleClose={handlDialog}
        title={isAdd ? "CREATE USER" : "UPDATE USER"}
        maxWidth="md"
        loading={loading}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="First Name"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="secondName"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="Second Name"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Last Name"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={!isAdd}
                    required
                    size="small"
                    label="Phone Number"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField size="small" label="Email" fullWidth {...field} />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="birthDate"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      disableFuture
                      openTo="year"
                      views={["year", "month", "day"]}
                      label="Birth Date"
                      name="birthdate"
                      value={field.value}
                      onChange={(value) => {
                        setValue(
                          "birthDate",
                          moment(value).format("YYYY-MM-DD")
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          {...params}
                          {...field}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            {isAdd ? (
              <Grid item xs={12} md={4}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      size="small"
                      label="password"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
            ) : null}
            {isAdd ? (
              <Grid item xs={12} md={8}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(e) => {
                        var _password = generator.generate({
                          length: 10,
                          numbers: true,
                          uppercase: false,
                        });
                        setValue("password", _password);
                      }}
                    >
                      Generate
                    </Button>
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={4}>
              <Controller
                name="provinceId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={addresses}
                    getOptionLabel={(option) =>
                      option.nameAR
                        ? `${option.nameAR} , ${option.nameUS}`
                        : "Not selected"
                    }
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField required {...params} label="Provinces" />
                    )}
                    onChange={(event, value) => {
                      setSelectedProvince(
                        value ? value : { nameAR: "", nameUS: "", cities: [] }
                      );
                      setValue("provinceId", value ? value.id : 0);
                    }}
                    value={
                      addresses.filter((x) => x.id == field.value)[0]
                        ? addresses.filter((x) => x.id == field.value)[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="addressId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={selectedProvince.cities}
                    getOptionLabel={(option) =>
                      option
                        ? `${option.nameAR} , ${option.nameUS}`
                        : "Not selected"
                    }
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField required {...params} label="Cities" />
                    )}
                    onChange={(event, value) => {
                      setValue("addressId", value ? value.id : 0);
                    }}
                    value={
                      addresses
                        .map((city) => city.cities)
                        .flat()
                        .filter((x) => x.id == field.value)[0]
                        ? addresses
                            .map((city) => city.cities)
                            .flat()
                            .filter((x) => x.id == field.value)[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {""}
            </Grid>
            {!isAdd ? (
              <Grid item xs={12} md={4}>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      options={user_role}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      size="small"
                      renderInput={(params) => (
                        <TextField required {...params} label="Roles" />
                      )}
                      onChange={(event, value) => {
                        setValue("role", value ? value.id : 0);
                      }}
                      value={
                        user_role.filter((x) => x.id == field.value)[0]
                          ? user_role.filter((x) => x.id == field.value)[0]
                          : null
                      }
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={4}>
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={gender}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField required {...params} label="Gender" />
                    )}
                    onChange={(event, value) => {
                      setValue("gender", value ? value.id : 0);
                    }}
                    value={
                      gender.filter((x) => x.id == field.value)[0]
                        ? gender.filter((x) => x.id == field.value)[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {isAdd ? null : (
                <Accordion
                  expanded={expanded}
                  onChange={() => setExpanded(!expanded)}
                >
                  <AccordionSummary>
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      Reset password
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomText
                      style={{ margin: 10 }}
                      label="New password"
                      value={newPassword ?? ""}
                      onChange={(e) => setNewPassword(e.target.value)}
                      type="password"
                    />
                    <CustomText
                      style={{ margin: 10 }}
                      label="Confrim password"
                      value={confirmNewPassword ?? ""}
                      type="password"
                      onChange={(e) => setConfirmNewPassword(e.target.value)}
                      error={newPassword !== confirmNewPassword}
                      helperText={
                        newPassword !== confirmNewPassword
                          ? "Password and confirmation does not match!"
                          : ""
                      }
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        resetPassword(users.form.id ? users.form.id : null)
                      }
                      sx={{ margin: 1.5 }}
                    >
                      Reset
                    </Button>
                  </AccordionDetails>
                </Accordion>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Button type="submit" variant="contained" color="primary">
                  {isAdd ? "Create" : "Update"}
                </Button>
                {isAdd ? (
                  <Button
                    onClick={() => {
                      reset({ ...users.form });
                    }}
                    variant="contained"
                    color="warning"
                  >
                    Clear Text
                  </Button>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        </form>
      </CustomDialog>
    </Box>
  );
}
