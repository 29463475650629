import axios from "../axios";

class PromotedItemsService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`/promoteditem/${pageNo}?${params}`);
  }

  getList() {
    return axios.get(`/promoteditem/list`);
  }

  getById(id) {
    return axios.get(`/promoteditem/getbyid/${id}`);
  }

  create(data) {
    return axios.post("/promoteditem", data);
  }

  update(id, data) {
    return axios.put(`/promoteditem/${id}`, data);
  }

  delete(id) {
    return axios.delete(`/promoteditem/${id}`);
  }
}

export default new PromotedItemsService();
