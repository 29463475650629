import { useEffect } from "react";
import { Button, TextField, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { Controller, useForm } from "react-hook-form";
import {
  updateContactInfo,
  openDialog,
  resetForm,
} from "src/actions/contactInfo";

const ContactInfoForm = () => {
  const _ContactInfo = useSelector((state) => state.contactInfo);
  const searchFilter = useSelector((state) => state.contactInfo.searchBody);
  const PageNo = useSelector((state) => state.contactInfo.pageNo);

  const {
    control,
    reset,
    handleSubmit,
    // setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: _ContactInfo.form,
  });

  useEffect(() => {
    reset({ ..._ContactInfo.form });
  }, [_ContactInfo.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(
      updateContactInfo(
        data.id,
        {
          name: data.name,
          value: data.value,
        },
        PageNo + 1,
        searchFilter
      )
    );
  };

  const handleDialog = () => {
    dispatch(openDialog());
  };

  return (
    <CustomDialog
      title={"Update Contact Information"}
      isOpen={_ContactInfo.openDialog}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  disabled
                  size="small"
                  label="Name"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="value"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Value"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isDirty}
            >
              {"Update"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default ContactInfoForm;
