import { Grid, Box } from "@mui/material";
import React from "react";
import AgentRequestProvincesTable from "src/pages/cross/Configuration/agentCityConfig/agentRequestProvinces/home/table/List";
import AgentRequestProvincesHeader from "src/pages/cross/Configuration/agentCityConfig/agentRequestProvinces/home/header/header";

const AgentRequestProvinces = () => {
  return (
    <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <AgentRequestProvincesHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <AgentRequestProvincesTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgentRequestProvinces;
