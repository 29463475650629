import {
  GET_ALL_LOCKERS,
  RESET_FORM_LOCKER,
  GET_BY_ID_LOCKER,
  OPEN_LOCKER_DIALOG,
  RELOAD_LOCKER,
  SAVE_LOCKER_SAVE_NO,
  SAVE_LOCKER_SEARCH_BODY,
  GET_LOCKERS_LIST,
  OPEN_AVAILABLE_LOCKER_DIALOG,
  OPEN_LOCKER_HISTORY_DIALOG,
  GET_LOCKER_HISTORY,
  SAVE_LOCKER_HISTORY_SAVE_NO,
  SAVE_LOCKER_HISTORY_SEARCH_BODY,
} from "./type";

import LockerDataService from "../api/services/lockers.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import { getDetails, reloadOrderDetails } from "src/actions/orders";

export const openDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_LOCKER_DIALOG,
    payload: data,
  });
};

export const openAvailableLockersDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_AVAILABLE_LOCKER_DIALOG,
  });
};

export const openLockerHistoryDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_LOCKER_HISTORY_DIALOG,
    payload: data,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: RESET_FORM_LOCKER,
  });
};

export const reloadLockers = () => (dispatch) => {
  dispatch({
    type: RELOAD_LOCKER,
  });
};

export const saveLockerPageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_LOCKER_SAVE_NO,
    payload: data,
  });
};

export const saveLockerHistoryPageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_LOCKER_HISTORY_SAVE_NO,
    payload: data,
  });
};

export const saveLockerSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_LOCKER_SEARCH_BODY,
    payload: data,
  });
};

export const saveLockerHistorySearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_LOCKER_HISTORY_SEARCH_BODY,
    payload: data,
  });
};

export const createLocker = (data, pagNo, pageSize) => async (dispatch) => {
  try {
    const res = await LockerDataService.create(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(getAllLockers(pagNo, pageSize));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllLockers = (pageNo, pageSize) => async (dispatch) => {
  try {
    dispatch(reloadLockers());
    const res = await LockerDataService.getAll(pageNo, pageSize);
    dispatch({
      type: GET_ALL_LOCKERS,
      payload: res.data.data,
    });
    dispatch(reloadLockers());
  } catch (err) {
    dispatch(reloadLockers());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getLockerHistory =
  (pageNo, lockerId, pageSize) => async (dispatch) => {
    try {
      dispatch(reloadLockers());
      const res = await LockerDataService.getLockerHistory(
        pageNo,
        lockerId,
        pageSize
      );
      dispatch({
        type: GET_LOCKER_HISTORY,
        payload: res.data.data,
      });
      dispatch(reloadLockers());
    } catch (err) {
      dispatch(reloadLockers());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const updateLocker = (data, pagNo, pageSize) => async (dispatch) => {
  try {
    const res = await LockerDataService.update(data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    dispatch(getAllLockers(pagNo, pageSize));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteLocker = (data, pagNo, pageSize) => async (dispatch) => {
  try {
    const res = await LockerDataService.delete(data);
    dispatch(showSuccessSnackbar("Deleted successfully!"));
    dispatch(getAllLockers(pagNo, pageSize));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadLockers());
    const res = await LockerDataService.getById(id);
    dispatch({
      type: GET_BY_ID_LOCKER,
      payload: res.data.data,
    });
    dispatch(reloadLockers());
  } catch (err) {
    dispatch(reloadLockers());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(reloadLockers());
    const res = await LockerDataService.getById(id);
    dispatch({
      type: GET_BY_ID_LOCKER,
      payload: res.data.data,
    });
    dispatch(reloadLockers());
    dispatch(openDialog());
  } catch (err) {
    dispatch(reloadLockers());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const assignToLocker = (data, orderId) => async (dispatch) => {
  try {
    dispatch(reloadOrderDetails());
    const res = await LockerDataService.assignToLocker(data);
    dispatch(getDetails(orderId));
    dispatch(reloadOrderDetails());
    dispatch(showSuccessSnackbar("Locker Assigned successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadOrderDetails());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const clearLocker = (orderId) => async (dispatch) => {
  try {
    dispatch(reloadOrderDetails());
    await LockerDataService.clearLocker(orderId);
    dispatch(getDetails(orderId));
    dispatch(reloadOrderDetails());
    dispatch(showSuccessSnackbar("Locker Cleared successfully"));
  } catch (err) {
    dispatch(reloadOrderDetails());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const toggleActivation =
  (orderId, pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadLockers());
      await LockerDataService.toggleActivation(orderId);
      dispatch(getAllLockers(pageNo, searchBody));
      dispatch(showSuccessSnackbar("Updated successfully"));
      dispatch(reloadLockers());
    } catch (err) {
      dispatch(reloadLockers());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getAvailableLockersList = (orderId) => async (dispatch) => {
  try {
    dispatch(reloadLockers());
    const res = await LockerDataService.lockersList(orderId);
    dispatch({
      type: GET_LOCKERS_LIST,
      payload: res.data.data,
    });
    // dispatch(getDetails(orderId));
    dispatch(reloadLockers());
  } catch (err) {
    dispatch(reloadLockers());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
