import axios from "../axios";

class OrderService {
  getOrderAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`giftorder/${pageNo}?${params}`);
  }

  // createOrder(data) {
  //   return axios.post("/giftorder", data);
  // }

  getOrderById(id) {
    return axios.get(`/giftorder/getbyid/${id}`);
  }

  getOrderStatusHistories(id) {
    return axios.get(`/giftorder/statushistory/${id}`);
  }

  // deleteOrder(id) {
  //   return axios.delete(`/giftorder/${id}`);
  // }

  updateOrder(id, data) {
    return axios.put(`/giftorder/${id}`, data);
  }

  updateOrderStatus(id, status) {
    return axios.put(`/giftorder/${id}/${status}`);
  }

  cancelOrder(id) {
    return axios.put(`/giftorder/cancelgiftorder/${id}`);
  }

  cancelOrderItem(id) {
    return axios.put(`/giftorder/cancelitem/${id}`);
  }

  refundOrderItem(id) {
    return axios.put(`/giftorder/refundmoneytogiftorderitem/${id}`);
  }

  refundOrder(id) {
    return axios.put(`/giftorder/refundmoneytogiftorder/${id}`);
  }

  updateRemainingCODAmount(id, amount) {
    return axios.put(`/giftorder/updateremainingcodamount/${id}/${amount}`);
  }

  updateLockerNumber(id, amount) {
    return axios.put(`/giftorder/updatelockernumber/${id}/${amount}`);
  }

  updateEmployeeNotes(id, employeeNote) {
    const encodedEmployeeNote = encodeURIComponent(employeeNote);
    return axios.put(
      `/giftorder/updateemployeenotes/${id}?EmployeeNotes=${encodedEmployeeNote}`
    );
  }
}

export default new OrderService();
