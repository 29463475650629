import { useEffect } from "react";
import { Button, TextField, Grid, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import {
  createAgentCityConfig,
  openDialog,
  resetForm,
} from "src/actions/agentCityConfig";

const AgentCityConfigForm = () => {
  const _AgentCityConfig = useSelector((state) => state.agentCityConfig);
  const _Agents = useSelector((state) => state.agentRequest.agentData);
  const _Addresses = useSelector((state) => state.addresses);

  const [selectedProvince, setSelectedProvince] = useState({
    nameAR: "",
    nameUS: "",
    cities: [],
  });

  const isAdd = _AgentCityConfig.form.id ? false : true;
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _AgentCityConfig.form,
  });

  useEffect(() => {
    reset({ ..._AgentCityConfig.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_AgentCityConfig.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(createAgentCityConfig(data));
    }
  };

  const handleDialog = () => {
    dispatch(openDialog());
  };

  return (
    <CustomDialog
      title={isAdd ? "ADD" : "UPDATE"}
      isOpen={_AgentCityConfig.openDialog}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="agentId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={_Agents}
                  getOptionLabel={(option) => (option ? option.fullName : "")}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Agent" />
                  )}
                  onChange={(event, value) => {
                    setValue("agentId", value.id ?? null);
                  }}
                  value={_Agents.filter((x) => x.id === field.value)[0] ?? null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="provinceId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={_Addresses}
                  getOptionLabel={(option) =>
                    option.nameAR
                      ? `${option.nameAR} , ${option.nameUS}`
                      : "Not selected"
                  }
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField required {...params} label="Provinces" />
                  )}
                  onChange={(event, value) => {
                    setSelectedProvince(
                      value ? value : { nameAR: "", nameUS: "", cities: [] }
                    );
                    setValue("provinceId", value ? value.id : 0);
                  }}
                  value={
                    _Addresses.filter((x) => x.id === field.value)[0] ?? null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="cityIds"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  options={selectedProvince.cities}
                  getOptionLabel={(option) =>
                    option
                      ? `${option.nameAR} , ${option.nameUS}`
                      : "Not selected"
                  }
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Cities" />
                  )}
                  onChange={(event, value) => {
                    var ids = value.map((x) => x.id);
                    setValue("cityIds", value ? ids : null);
                  }}
                  value={
                    _Addresses
                      .map((city) => city.cities)
                      .flat()
                      .filter((x) => field.value?.includes(x.id)) ?? null
                  }
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button type="submit" variant="contained" color="primary">
              {isAdd ? "Create" : "Update"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default AgentCityConfigForm;
