import {
  GET_ALL_CATEGORY_BRANDS,
  OPEN_CATEGORY_BRANDS_DIALOG,
  RELOAD_CATEGORY_BRANDS,
  RESET_CATEGORY_BRANDS_FORM,
} from "src/actions/gifts/giftTypes";

const initialState = {
  reload: false,
  openDialog: false,
  selectedCategory: null,
  form: {
    id: null,
    giftItemCategoryId: null,
    brandsIds: [],
    selectedCategory: null,
  },
  data: {
    data: [],
    pageCount: 0,
    rowCount: 0,
  },
  collectionList: [],
  getById: {},
};

function categoryBrandReducer(categoryBrand = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_CATEGORY_BRANDS:
      categoryBrand.form.brandsIds = payload.data?.map((item) => item.brand);
      categoryBrand.data = payload.data;
      return categoryBrand;

    case OPEN_CATEGORY_BRANDS_DIALOG:
      return {
        ...categoryBrand,
        openDialog: !categoryBrand.openDialog,
        selectedCategory: payload ?? null,
      };

    case RELOAD_CATEGORY_BRANDS:
      return { ...categoryBrand, reload: !categoryBrand.reload };

    case RESET_CATEGORY_BRANDS_FORM:
      categoryBrand.form.id = null;
      categoryBrand.form.selectedCategory = null;
      categoryBrand.form.brandsIds = [];
      categoryBrand.form.giftItemCategoryId = null;
      return categoryBrand;

    default:
      return categoryBrand;
  }
}

export default categoryBrandReducer;
