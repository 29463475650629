import { useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Autocomplete,
  Tooltip,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { Controller, useForm } from "react-hook-form";
import {
  createRatingQuestions,
  openDialog,
  resetForm,
  updateRatingQuestions,
} from "src/actions/ratingQuestions";
import { RATING_QUESTIONS_TYPES } from "src/utils/common";

const RatingQuestionsForm = () => {
  const _RatingQuestions = useSelector((state) => state.ratingQuestions);
  const searchFilter = useSelector((state) => state.ratingQuestions.searchBody);
  const PageNo = useSelector((state) => state.ratingQuestions.pageNo);

  const isAdd = _RatingQuestions.form.id ? false : true;
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: _RatingQuestions.form,
  });

  useEffect(() => {
    reset({ ..._RatingQuestions.form });
  }, [_RatingQuestions.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        createRatingQuestions(
          {
            questionAr: data.questionAr,
            questionEn: data.questionEn,
            questionKu: data.questionKu,
            isActive: data.isActive,
            type: data.type === "Orderii" ? 0 : data.type === "Gift" ? 1 : 2,
          },
          PageNo + 1,
          searchFilter
        )
      );
    } else {
      dispatch(
        updateRatingQuestions(
          {
            id: data.id,
            questionAr: data.questionAr,
            questionEn: data.questionEn,
            questionKu: data.questionKu,
            isActive: data.isActive,
            type: data.type === "Orderii" ? 0 : data.type === "Gift" ? 1 : 2,
          },
          PageNo + 1,
          searchFilter
        )
      );
    }
  };

  const handleDialog = () => {
    dispatch(openDialog());
  };

  return (
    <CustomDialog
      title={isAdd ? "Add Rating Question" : "Update Rating Question"}
      isOpen={_RatingQuestions.openDialog}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={RATING_QUESTIONS_TYPES || []}
                  getOptionLabel={(option) => option.value}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Type" />
                  )}
                  onChange={(event, value) => {
                    setValue("type", value?.value ?? null);
                  }}
                  value={
                    RATING_QUESTIONS_TYPES?.filter(
                      (x) => x.value === field.value
                    )[0] ?? null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <Tooltip title="FALSE | TRUE" aria-label="active_deactivate">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("isActive", e.target.checked);
                        }}
                      />
                    }
                    label="Is Active !"
                  />
                </Tooltip>
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="questionEn"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Question English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="questionAr"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Question Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="questionKu"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Question Kurdish"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isDirty}
            >
              {isAdd ? "Create" : "Update"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default RatingQuestionsForm;
