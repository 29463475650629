import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationData from "../utils/animationData";
import { Box, Card, Grid, Grow, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";

export default function WelcomeMessage() {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [currentTime, setCurrentTime] = useState(
    moment().format("MMMM Do YYYY, h:mm:ss a")
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format("MMMM Do YYYY, h:mm:ss a"));
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Card
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box
        height="90vh"
        // width="75%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
          >
            <Grow
              in={true}
              style={{ transformOrigin: "0 0 0" }}
              {...{ timeout: 1000 }}
            >
              <Typography variant="h2" color="text.secondary" width="100%">
                Welcome Back{" "}
                <span
                  style={{
                    background:
                      _UserInfo.gender === 0
                        ? "radial-gradient(at 0% 30%, #1e90ff  10px, #0E6FA9 30%, #8444FF 50%)"
                        : "radial-gradient(at 0% 30%, #D82C53 10px, #FF1493 30%, #FF69B4 50%)",
                    color: "transparent",
                    WebkitBackgroundClip: "text",
                  }}
                >
                  {_UserInfo?.firstName ?? ""} {_UserInfo?.secondName ?? ""}{" "}
                  {_UserInfo?.lastName ?? ""}
                </span>
              </Typography>
            </Grow>
            <Grow
              in={true}
              style={{ transformOrigin: "0 0 0" }}
              {...{ timeout: 1500 }}
            >
              <Typography variant="h4" color="text.secondary">
                Let's get some work done.
              </Typography>
            </Grow>
            <Grow
              in={true}
              style={{ transformOrigin: "0 0 0" }}
              {...{ timeout: 1500 }}
            >
              <Typography
                variant="h5"
                color="text.secondary"
                marginTop={10}
                sx={{ fontFamily: "monospace" }}
              >
                {currentTime}
              </Typography>
            </Grow>
          </Grid>

          <Grow
            in={true}
            style={{ transformOrigin: "0 0 0" }}
            {...{ timeout: 750 }}
          >
            <Grid item xs={6}>
              <Lottie options={defaultOptions} height={400} width={600} />
            </Grid>
          </Grow>
        </Grid>
      </Box>
    </Card>
  );
}
