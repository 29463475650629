import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateStatus,
  getOrderItemByQr,
  generateShippingNumber,
} from "src/actions/orderItems";
import { openDialog, resetForm } from "src/actions/shipments";
import { CustomDialog } from "src/components/CustomDialog";
import RolePermission from "src/components/RolePermission";

export default function ChangeStatusDialog(props) {
  const dispatch = useDispatch();

  const [shippingNumber, setShippingNumber] = useState("");
  const [orderiiNumber, setOrderiiNumber] = useState("");
  const [items, setItems] = useState([]);
  const [openChangeItemStatusDialog, setOpenChangeItemStatusDialog] =
    useState(false);
  const [changeStatusType, setChangeStatusType] = useState("");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setItems([]);
    setOrderiiNumber("");
    setShippingNumber("");
  }, [props.type]);

  function searchItemByQr(e) {
    e.preventDefault();
    if (orderiiNumber == "") props.onFail("Please enter Orderii Number ");
    else {
      let ids = items.map((number) => String(number.orderiiNumber));
      if (ids.includes(orderiiNumber))
        props.onFail("This items is already added ");
      else {
        dispatch(
          getOrderItemByQr(1, {
            orderiiNumber: orderiiNumber,
            isVip: null,
            sortByCreatedAtAsc: false,
          })
        ).then((res) => {
          var result = res.data.data;
          if (result.length > 0) {
            changeItemStatus(result[0]);
          } else
            props.onFail(
              "There is no item with this Orderii number: " + orderiiNumber
            );
        });
      }
    }
  }

  function addShippingNumber() {
    if (items.length == 0) props.onFail(`There are no items!`);
    else {
      setReload(true);
      var ids = items.map((x) => x.id);
      dispatch(generateShippingNumber({ orderItemIds: ids })).then((data) => {
        props.onSuccess("Item Status Changed Success");
        setShippingNumber(data.data);
        setReload(false);
      });
    }
  }
  function changeItemStatus(item) {
    setReload(true);
    dispatch(
      updateStatus({
        ids: [item.id],
        itemStatus: changeStatusType == "toIraq" ? 4 : 5,
      })
    )
      .then(() => {
        props.onSuccess("Item Status Changed Success");
        setItems([item, ...items]);
        document.getElementById("textQr").select();
        setReload(false);
      })
      .catch((err) => {
        props.onFail("Network Error");
      });
  }

  function undo(item) {
    setReload(true);
    dispatch(
      updateStatus({
        ids: [item.id],
        itemStatus: 3,
      })
    ).then(() => {
      props.onSuccess("Item Removed Successfully");
      var list = items.filter((product) => product !== item);
      setItems(list);
      setReload(false);
    });
  }

  return (
    <Box sx={{ marginX: 2 }}>
      <RolePermission permission="23-11">
        <Stack direction="row" spacing={1}>
          <Box>
            <Button
              sx={{ marginRight: 1, minWidth: "210px" }}
              variant="contained"
              onClick={() => {
                dispatch(resetForm());
                dispatch(openDialog("orderItems"));
              }}
            >
              Send To Iraq
            </Button>
          </Box>
          <Box>
            <Button
              sx={{ marginRight: 1, minWidth: "210px" }}
              variant="contained"
              color="warning"
              onClick={() => {
                setChangeStatusType("toBaghdad");
                setOpenChangeItemStatusDialog(true);
              }}
            >
              In Baghdad warehouse
            </Button>
          </Box>
        </Stack>
      </RolePermission>
      <CustomDialog
        isOpen={openChangeItemStatusDialog}
        handleClose={() => {
          setOpenChangeItemStatusDialog(false);
          setItems([]);
        }}
        title={
          changeStatusType == "toIraq"
            ? "Send items to Iraq"
            : "Receive items in Baghdad warehouse"
        }
        maxWidth="md"
        Draggable={true}
        isRequierdActions
        isRequierdAdd
        loading={reload}
        onAdd={searchItemByQr}
        onClear={() => {
          setItems([]);
          setOrderiiNumber("");
          setShippingNumber("");
        }}
      >
        {reload ? (
          <LinearProgress sx={{ marginTop: -2, marginBottom: 2 }} />
        ) : null}
        <Box>
          <TextField
            id="textQr"
            size="small"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon color="action">search</Icon>
                </InputAdornment>
              ),
            }}
            placeholder="Scan QR code (Orderii number)"
            sx={{ marginRight: 1 }}
            variant="outlined"
            value={orderiiNumber}
            onChange={(e) => {
              setOrderiiNumber(e.target.value);
            }}
            onKeyDown={(e) => (e.key === "Enter" ? searchItemByQr(e) : null)}
          />

          {items.length > 0 ? (
            <Box sx={{ marginY: 2 }}>
              {shippingNumber && (
                <Typography variant="h4">
                  {`Shipping number: ${shippingNumber}`}{" "}
                </Typography>
              )}
              <Table sx={{ marginTop: 2 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Orderii number</TableCell>
                    {changeStatusType != "toIraq" && (
                      <TableCell>Shipping number</TableCell>
                    )}
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item) => (
                    <TableRow hover key={item.id}>
                      <TableCell>
                        <Box
                          component="img"
                          sx={{
                            height: 130,
                            maxWidth: 150,
                          }}
                          alt="itemImage"
                          src={item.imageUrl}
                        />
                      </TableCell>
                      <TableCell>{item.itemName}</TableCell>
                      <TableCell>{item.orderiiNumber}</TableCell>
                      {changeStatusType != "toIraq" && (
                        <TableCell>{item.shippingNumber}</TableCell>
                      )}
                      <TableCell>{item.quantity}</TableCell>
                      {changeStatusType === "toIraq" ? (
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={() => {
                              undo(item);
                            }}
                          >
                            Undo
                          </Button>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          ) : null}
          {changeStatusType == "toIraq" && (
            <Button
              variant="contained"
              color="grey"
              fullWidth
              sx={{ marginTop: 1 }}
              onClick={() => {
                addShippingNumber();
              }}
            >
              Generate Shipping Number
            </Button>
          )}
        </Box>
      </CustomDialog>
    </Box>
  );
}
