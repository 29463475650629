import axios from "../axios";

class PopUpPostService {
  getAll(pageNo) {
    return axios.get(`/popuppost/all/${pageNo}`);
  }

  getList() {
    return axios.get(`/popuppost/list`);
  }

  getById(id) {
    return axios.get(`/popuppost/getById/${id}`);
  }

  create(data) {
    return axios.post("/popuppost/create", data);
  }

  update(data) {
    return axios.put(`/popuppost/update`, data);
  }

  delete(id) {
    return axios.delete(`/popuppost/delete/${id}`);
  }
}

export default new PopUpPostService();
