import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  Icon,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import { HiCurrencyDollar } from "react-icons/hi";
import {
  LocalPrintshop as LocalPrintshopIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";
import { FaShoppingBag } from "react-icons/fa";
import { MdOutlineStarRate } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import RolePermission from "src/components/RolePermission";
import { openDialog } from "src/actions/orderAddtionalFees";

const OrderActionsMenu = ({
  order,
  _UserInfo,
  setOpenInvoiceDialog,
  setOpenLabelDialog,
  setOpenTimelineDialog,
  setOpenRatingDialog,
  setRatingData,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <Icon>menu</Icon>
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <RolePermission permission="58-01">
          <MenuItem
            onClick={() => {
              dispatch(openDialog("global"));
              handleClose();
            }}
          >
            <ListItemIcon>
              <HiCurrencyDollar size={25} />
            </ListItemIcon>
            <ListItemText>Order Additional Fees</ListItemText>
          </MenuItem>
        </RolePermission>
        {_UserInfo.roles.includes("PrintLabelAndInvoice") && [
          <MenuItem
            key="print-invoice"
            onClick={() => {
              setOpenInvoiceDialog(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <LocalPrintshopIcon />
            </ListItemIcon>
            <ListItemText>Print Invoice</ListItemText>
          </MenuItem>,
          <MenuItem
            key="print-label"
            onClick={() => {
              setOpenLabelDialog(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText>Print Label</ListItemText>
          </MenuItem>,
        ]}
        <RolePermission permission="22-03">
          <MenuItem
            onClick={() => {
              setOpenTimelineDialog(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <Icon>show_chart</Icon>
            </ListItemIcon>
            <ListItemText>Timeline</ListItemText>
          </MenuItem>
        </RolePermission>
        <RolePermission permission="34-12">
          <MenuItem
            onClick={() => {
              navigate(
                `/app/wallet/${order.customer ? order.customer.id : null}`
              );
              handleClose();
            }}
          >
            <ListItemIcon>
              <Icon>wallet</Icon>
            </ListItemIcon>
            <ListItemText>Go to wallet</ListItemText>
          </MenuItem>
        </RolePermission>
        <RolePermission permission="22-04">
          <MenuItem
            onClick={() => {
              navigate(`/app/user_orders/${order.customer.id}`);
              handleClose();
            }}
          >
            <ListItemIcon>
              <FaShoppingBag size={20} />
            </ListItemIcon>
            <ListItemText>User Orders</ListItemText>
          </MenuItem>
        </RolePermission>
        {order?.orderRating?.length > 0 && (
          <MenuItem
            onClick={() => {
              setOpenRatingDialog(true);
              setRatingData(order?.orderRating[0]);
              handleClose();
            }}
          >
            <ListItemIcon>
              <MdOutlineStarRate />
            </ListItemIcon>
            <ListItemText>Order Rating</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

export default OrderActionsMenu;
