import { Helmet } from "react-helmet";
import { Box, Card, CardHeader, Container } from "@mui/material";
import { Typography } from "@mui/material";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import TicketDetailsList from "src/pages/cross/Ticket/ticketDetails/List";

const TicketDetails = () => (
  <>
    <Helmet>
      <title>Ticket Details | Orderii</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Ticket Details
              </Typography>
            }
            avatar={<BookOnlineIcon size={20} />}
          />
        </Card>

        <Box sx={{ pt: 2 }}>
          <TicketDetailsList />
        </Box>
      </Container>
    </Box>
  </>
);

export default TicketDetails;
