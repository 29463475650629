import {
  ADDITIONAL_FEES_RESET_FORM,
  ADDITIONAL_FEES_OPEN_DIALOG,
  ADDITIONAL_FEES_OPEN_FORM_DIALOG,
} from "./type";

import OrderAdditionalFeesService from "../api/services/orderAdditionalFee.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import { getOrderById } from "src/actions/gifts/orders";
import { getDetails } from "src/actions/orders";

export const openDialog = (data) => (dispatch) => {
  dispatch({
    type: ADDITIONAL_FEES_OPEN_DIALOG,
    payload: data,
  });
};

export const openFormDialog = () => (dispatch) => {
  dispatch({
    type: ADDITIONAL_FEES_OPEN_FORM_DIALOG,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: ADDITIONAL_FEES_RESET_FORM,
  });
};

export const createAdditionalFees = (data, isGiftOrder) => async (dispatch) => {
  try {
    const res = await OrderAdditionalFeesService.create(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    if (isGiftOrder) {
      dispatch(getOrderById(data.orderId));
    } else {
      dispatch(getDetails(data.orderId));
    }
    dispatch(openFormDialog());
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateAdditionalFees =
  (id, data, orderId, isGiftOrder) => async (dispatch) => {
    try {
      const res = await OrderAdditionalFeesService.update(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      if (isGiftOrder) {
        dispatch(getOrderById(orderId));
      } else {
        dispatch(getDetails(orderId));
      }
      dispatch(openFormDialog());
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteAdditionalFees =
  (id, orderId, isGiftOrder) => async (dispatch) => {
    try {
      const res = await OrderAdditionalFeesService.delete(id);
      dispatch(showSuccessSnackbar("Deleted successfully!"));
      if (isGiftOrder) {
        dispatch(getOrderById(orderId));
      } else {
        dispatch(getDetails(orderId));
      }
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };
