import { Grid, Box } from "@mui/material";
import React from "react";
import PromotedItemsHeader from "./home/header/header";
import PromotedItemsTable from "./home/table/List";

const PromotedItems = () => {
  return (
    <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <PromotedItemsHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <PromotedItemsTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PromotedItems;
