import NotificationService from "../api/services/notification.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import {
  PERSONAL_NOTIFICATION_GET_ALL,
  NOTIFICATION_RELOAD,
  NOTIFICATION_GET_ALL,
  NOTIFICATION_GET_BY_ID,
  NOTIFY_ALL_NOTIFICATION_RESET_FORM,
  SEGMENT_NOTIFICATION_RESET_FORM,
  NOTIFICATION_SEARCH_BODY,
  NOTIFY_ALL_DIALOG,
  NOTIFY_SEGMENT_DIALOG,
  NOTIFY_BY_USER_ID_DIALOG,
  NOTIFY_BY_USER_ID_NOTIFICATION_RESET_FORM,
} from "src/actions/type";

export const setNotifyAllDialog = () => (dispatch) => {
  dispatch({
    type: NOTIFY_ALL_DIALOG,
  });
};

export const setNotifySegmentDialog = () => (dispatch) => {
  dispatch({
    type: NOTIFY_SEGMENT_DIALOG,
  });
};

export const setNotifyByUserIdDialog = () => (dispatch) => {
  dispatch({
    type: NOTIFY_BY_USER_ID_DIALOG,
  });
};

export const reloadNotifications = () => (dispatch) => {
  dispatch({
    type: NOTIFICATION_RELOAD,
  });
};

export const resetNotifyAll = () => (dispatch) => {
  dispatch({
    type: NOTIFY_ALL_NOTIFICATION_RESET_FORM,
  });
};

export const resetNotifyByUserId = () => (dispatch) => {
  dispatch({
    type: NOTIFY_BY_USER_ID_NOTIFICATION_RESET_FORM,
  });
};

export const resetNotifySegment = () => (dispatch) => {
  dispatch({
    type: SEGMENT_NOTIFICATION_RESET_FORM,
  });
};

export const saveNotificationSearchBody = (data) => (dispatch) => {
  dispatch({
    type: NOTIFICATION_SEARCH_BODY,
    payload: data,
  });
};

export const createNotification = (data) => async (dispatch) => {
  try {
    dispatch(reloadNotifications());
    const res = await NotificationService.create(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(reloadNotifications());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadNotifications());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const notificationUserById = (data) => async (dispatch) => {
  try {
    dispatch(reloadNotifications());
    const res = await NotificationService.notificationUerById(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(reloadNotifications());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadNotifications());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getPersonalNotifications = () => async (dispatch) => {
  try {
    dispatch(reloadNotifications());
    const res = await NotificationService.getPersonalNotifications();
    dispatch({
      type: PERSONAL_NOTIFICATION_GET_ALL,
      payload: res.data.data,
    });
    dispatch(reloadNotifications());
  } catch (err) {
    dispatch(reloadNotifications());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const markAllNotificationsAsRead = () => async (dispatch) => {
  try {
    dispatch(reloadNotifications());
    const res = await NotificationService.markAllNotificationsAsRead();
    dispatch(showSuccessSnackbar("Updated successfully"));
    dispatch(getPersonalNotifications());
    dispatch(reloadNotifications());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadNotifications());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllNotifications = () => async (dispatch) => {
  try {
    dispatch(reloadNotifications());
    const res = await NotificationService.getAll();
    dispatch({
      type: NOTIFICATION_GET_ALL,
      payload: res.data.data,
    });
    dispatch(reloadNotifications());
  } catch (err) {
    dispatch(reloadNotifications());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getNotificationById = (id) => async (dispatch) => {
  try {
    dispatch(reloadNotifications());
    const res = await NotificationService.getById(id);
    dispatch({
      type: NOTIFICATION_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadNotifications());
  } catch (err) {
    dispatch(reloadNotifications());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteNotification =
  (id, pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadNotifications());
      await NotificationService.delete(id);
      dispatch(getAllNotifications(pageNo, searchBody));
      dispatch(reloadNotifications());
    } catch (err) {
      dispatch(reloadNotifications());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const updateNotification =
  (data, pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadNotifications());
      await NotificationService.update(data);
      dispatch(getAllNotifications(pageNo, searchBody));
      dispatch(reloadNotifications());
    } catch (err) {
      dispatch(reloadNotifications());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };
