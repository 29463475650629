import {
  TextField,
  Grid,
  Stack,
  Button,
  Box,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { uploadAttachments } from "src/actions/uploadAttachments";
import { showErrorSnackbar } from "src/actions/snackbarMessages";
import { openDialog, resetForm, create, update } from "src/actions/deals";
import { getAllVendors, getVendorsCategoryList } from "src/actions/vendors";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const DealsForm = () => {
  const dispatch = useDispatch();

  const deals = useSelector((state) => state.deals);
  const loading = useSelector((state) => state.deals.reload);
  const searchBody = useSelector((state) => state.deals.searchBody);
  const pageNo = useSelector((state) => state.deals.pageNo);
  const _Vendors = useSelector((state) => state.vendors);
  const _VendorCategory = useSelector((state) => state.vendorsCategory);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
    setValue,
  } = useForm({
    defaultValues: deals.form,
  });

  const [attachmentsFiles, setAttachmentsFiles] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(attachmentsFiles.map((files) => files.file));
    setValue("attachmentId", attachmentsFiles.map((files) => files.file)[0], {
      shouldDirty: true,
    });
  }, [attachmentsFiles, setValue]);

  useEffect(() => {
    dispatch(getVendorsCategoryList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getAllVendors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAdd = deals.form.id ? false : true;

  const handleDialog = () => {
    dispatch(openDialog());
  };

  useEffect(() => {
    reset({ ...deals.form });
  }, [reset, deals.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("type", 2);
    if (isAdd) {
      if (files[0]) {
        dispatch(uploadAttachments(formData))
          .then((res) => {
            dispatch(
              create(
                {
                  nameAr: data.nameAr,
                  nameEn: data.nameEn,
                  nameKu: data.nameKu,
                  attachmentId: res.data.id,
                  url: data.url,
                  vendorId: data.vendorId,
                  vendorCategoryId: data.vendorCategoryId,
                  color: data.color,
                },
                pageNo + 1,
                searchBody
              )
            ).then((x) => {
              reset({ ...deals.form });
              dispatch(resetForm());
              handleDialog();
            });
          })
          .catch(() => dispatch(showErrorSnackbar("Network error")));
      } else {
        dispatch(
          create(
            {
              nameAr: data.nameAr,
              nameEn: data.nameEn,
              nameKu: data.nameKu,
              attachmentId: null,
              url: data.url,
              vendorId: data.vendorId,
              vendorCategoryId: data.vendorCategoryId,
              color: data.color,
            },
            pageNo + 1,
            searchBody
          )
        ).then((x) => {
          reset({ ...deals.form });
          dispatch(resetForm());
          handleDialog();
        });
      }
    } else {
      if (files[0]) {
        dispatch(uploadAttachments(formData))
          .then((res) => {
            dispatch(
              update(
                data.id,
                {
                  nameAr: data.nameAr,
                  nameEn: data.nameEn,
                  nameKu: data.nameKu,
                  attachmentId: res.data.id,
                  url: data.url,
                  vendorId: data.vendorId,
                  vendorCategoryId: data.vendorCategoryId,
                  color: data.color,
                },
                pageNo + 1,
                searchBody
              )
            ).then((x) => {
              reset({ ...deals.form });
              dispatch(resetForm());
              handleDialog();
            });
          })
          .catch(() => dispatch(showErrorSnackbar("Network error")));
      } else {
        dispatch(
          update(
            data.id,
            {
              nameAr: data.nameAr,
              nameEn: data.nameEn,
              nameKu: data.nameKu,
              attachmentId: data.attachmentId,
              url: data.url,
              vendorId: data.vendorId,
              vendorCategoryId: data.vendorCategoryId,
              color: data.color,
            },
            pageNo + 1,
            searchBody
          )
        ).then((x) => {
          reset({ ...deals.form });
          dispatch(resetForm());
          handleDialog();
        });
      }
    }
  };

  function isValidHexColor(color) {
    const hexColorRegex = /^#([0-9A-F]{3}){1,2}$/i;
    return hexColorRegex.test(color);
  }

  return (
    <CustomDialog
      title={isAdd ? "Create Deals" : "Update Deals"}
      isOpen={deals.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Controller
              name="nameEn"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Name English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="nameAr"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Name Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="nameKu"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Name Kurdish"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="url"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Item URL"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="vendorCategoryId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={_VendorCategory?.vendorsCategoryList || []}
                  getOptionLabel={(option) =>
                    `${option.nameEn} - ${option.nameAr}`
                  }
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Vendor Category"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  onChange={(event, value) => {
                    setValue("vendorCategoryId", value ? value.id : null, {
                      shouldDirty: true,
                    });
                  }}
                  value={
                    _VendorCategory?.vendorsCategoryList.filter(
                      (x) => x.id === field.value
                    )[0] ?? null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="vendorId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={_Vendors?.dataList || []}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Vendors"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  onChange={(event, value) => {
                    setValue("vendorId", value ? value.id : null, {
                      shouldDirty: true,
                    });
                  }}
                  value={
                    _Vendors?.dataList.filter((x) => x.id === field.value)[0] ??
                    null
                  }
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} md={12}>
            <Controller
              name="color"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Color"
                  fullWidth
                  {...field}
                  helperText="Please enter color in hex format"
                  error={!isValidHexColor(field.value)}
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12} md={12}>
            <Controller
              name="attachmentId"
              control={control}
              render={({ field }) => (
                <FilePond
                  files={attachmentsFiles}
                  onupdatefiles={setAttachmentsFiles}
                  required={isAdd}
                  maxFiles={10}
                  name="file"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  credits={false}
                />
              )}
            />
          </Grid>
          {!isAdd ? (
            <Grid
              item
              xs={12}
              md={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box component="img" src={deals.form.imageUrl} width={300} />
            </Grid>
          ) : null}
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
              {
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => reset({ ...deals.form })}
                >
                  Clear Text
                </Button>
              }
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};
export default DealsForm;
