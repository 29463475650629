import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TextField,
  Button,
  LinearProgress,
  Grid,
  Typography,
  Autocomplete,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import {
  deleteShareCenter,
  getAllImages,
  getShareCenterById,
  saveSearchBody,
  UpdateShareCenter,
} from "src/actions/imageUploadCenter";
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import ShareCenterSearchFilter from "./searchFilter";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { showSuccessSnackbar } from "src/actions/snackbarMessages";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { CustomDialog } from "src/components/CustomDialog";
import CustomSpinner from "src/components/CustomSpinner";
import { Share_Center_Types } from "src/utils/common";
import useCanShow from "src/components/CanShow";

registerPlugin(FilePondPluginImagePreview);

export default function UploadImageTable() {
  const editRole = useCanShow("28-02");
  const deleteRole = useCanShow("28-03");

  const [reload, setReload] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openEditDialog, setOpendEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openZoomdialog, setOpenZoomdialog] = useState(false);
  const [saveImagePath, setSaveImagePath] = useState("");
  const [refreshOnPageChange, setRefreshOnPageChange] = useState(false);

  const _ShareCenter = useSelector((state) => state.shareCenter);
  const searchFilter = useSelector((state) => state.shareCenter.searchBody);
  const PageNo = useSelector((state) => state.shareCenter.pageNo);
  const [page, setPage] = useState(PageNo ? PageNo : 0);

  const [getByIdReload, setGetByIdReload] = useState(false);
  const [getByIdData, setGetByIdData] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setReload(true);
    dispatch(getAllImages(page + 1, searchFilter)).then((x) => {
      setReload(false);
      setRefreshOnPageChange(false);
    });
  }, [searchFilter, page, refresh]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setRefreshOnPageChange(true);
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(saveSearchBody({ ...searchFilter, pageSize: newPageSize }));
    setPage(0);
    setRefreshOnPageChange(true);
  };

  const handleUploadDialogOpen = () => {
    setOpendEditDialog(true);
  };

  function updateSelectedImage() {
    setOpendEditDialog(false);
    dispatch(
      UpdateShareCenter({
        id: getByIdData.id,
        name: getByIdData.name,
        fileType: getByIdData.type,
      })
    ).then((res) => {
      setRefresh(!refresh);
    });
  }

  function deleteSelectedImage() {
    setOpenDeleteDialog(false);
    dispatch(deleteShareCenter(getByIdData.id)).then((res) => {
      setRefresh(!refresh);
    });
  }

  const shareCenterToolbar = () => {
    return (
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedImage()}
            selectedObject={getByIdData.name}
          >
            You will delete the Image code with name{getByIdData.name}
            <b>{getByIdData.name}</b>
          </CustomDialog>
        )}
        <CustomDialog
          isOpen={openEditDialog}
          handleClose={() => {
            setOpendEditDialog(false);
          }}
          title={"UPDATE Attachment"}
          maxWidth="md"
          // onClear={clearTexts}
        >
          {getByIdReload ? (
            <LinearProgress />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  required
                  label="Name"
                  size="small"
                  fullWidth
                  value={getByIdData ? getByIdData.name : ""}
                  onChange={(e) => {
                    setGetByIdData({ ...getByIdData, name: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  options={Share_Center_Types}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Type" />
                  )}
                  onChange={(event, value) => {
                    setGetByIdData({
                      ...getByIdData,
                      type: value ? value.id : null,
                    });
                  }}
                  value={
                    Share_Center_Types.filter(
                      (x) => x.id == getByIdData.type
                    )[0]
                      ? Share_Center_Types.filter(
                          (x) => x.id == getByIdData.type
                        )[0]
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  onClick={() => updateSelectedImage()}
                  variant="contained"
                  color="primary"
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          )}
        </CustomDialog>
        {<ShareCenterSearchFilter pageNo={(newPage) => setPage(newPage)} />}
        <CustomDialog
          isOpen={openZoomdialog}
          handleClose={() => {
            setOpenZoomdialog(false);
            setSaveImagePath("");
          }}
          title="Images"
          maxWidth="lg"
        >
          <div
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                // minWidth: '500px',
                width: "50%",
                height: "auto",
              }}
            >
              <ReactPanZoom
                image={`${
                  process.env.REACT_APP_ENV == "QA"
                    ? `https://api-qa.orderii.co${saveImagePath}`
                    : `https://api.orderii.co${saveImagePath}`
                }`}
                alt="Image not available"
              />
            </div>
          </div>
        </CustomDialog>
      </Box>
    );
  };

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) =>
        Share_Center_Types.map((x, index) =>
          x.id == params.row.type ? (
            <Typography key={index}>{x.name}</Typography>
          ) : null
        ),
    },
    {
      field: "attachment",
      headerName: "Attachment",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Box
          onClick={() => {
            setOpenZoomdialog(true);
            setSaveImagePath(params.row.url);
          }}
          sx={{ height: 110, cursor: "pointer" }}
          component="img"
          src={`${
            process.env.REACT_APP_ENV == "QA"
              ? ` https://api-qa.orderii.co${params.row.url}`
              : `https://api.orderii.co${params.row.url}`
          }`}
        />
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography>
          {moment(params.value).format("yyyy-MM-DD / hh:mm")}
        </Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          showInMenu
          hidden={!editRole}
          onClick={(e) => {
            setGetByIdReload(true);
            dispatch(getShareCenterById(params.row.id)).then((res) => {
              setGetByIdData(res.data);
              setGetByIdReload(false);
            });
            handleUploadDialogOpen();
          }}
        />,
        <GridActionsCellItem
          icon={<ContentCopyIcon color="secondary" />}
          label="Copy URL"
          showInMenu
          onClick={(e) => {
            navigator.clipboard.writeText(
              `${
                process.env.REACT_APP_ENV === "QA"
                  ? `https://api-qa.orderii.co${params.row.url}`
                  : `https://api.orderii.co${params.row.url}`
              }`
            );
            dispatch(showSuccessSnackbar("URL copied to clipboard"));
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          hidden={!deleteRole}
          showInMenu
          onClick={(e) => {
            setGetByIdReload(true);
            dispatch(getShareCenterById(params.row.id)).then((res) => {
              setGetByIdData(res.data);
              setGetByIdReload(false);
            });
            setOpenDeleteDialog(true);
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      {shareCenterToolbar()}
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {!_ShareCenter.data || refreshOnPageChange ? (
            <CustomSpinner />
          ) : (
            <Box sx={{ height: { md: "calc(100vh - 250px)", xs: 1000 } }}>
              <DataGrid
                sx={{
                  "& .css-204u17-MuiDataGrid-main": {
                    paddingLeft: "15px",
                  },
                  "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                    paddingLeft: "15px",
                  },
                  ".MuiDataGrid-cell": {
                    justifyContent: "center",
                  },
                }}
                loading={reload}
                rows={_ShareCenter.data}
                columns={columns}
                rowHeight={150}
                disableSelectionOnClick
                pagination
                pageSize={searchFilter.pageSize}
                page={page}
                onPageChange={(newPage) => handlePageChange(newPage)}
                onPageSizeChange={(newPageSize) =>
                  handleLimitChange(newPageSize)
                }
                rowCount={_ShareCenter.rowCount}
                paginationMode="server"
                rowsPerPageOptions={[10, 25, 100]}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
