import axiosService from "src/api/axiosService";

class Employee {
  createEmployee(data) {
    return axiosService.post(`/department-service/api/employee`, data);
  }

  getEmployee() {
    return axiosService.get(`/department-service/api/employee`);
  }

  updateEmployee(id, data) {
    return axiosService.put(`/department-service/api/employee/${id}`, data);
  }

  deleteEmployee(id) {
    return axiosService.delete(`/department-service/api/employee/${id}`);
  }

  getByIdEmployee(id) {
    return axiosService.get(`/department-service/api/employee/byid/${id}`);
  }


}

export default new Employee();
