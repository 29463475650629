import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Chip, Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Ticket_Status } from "src/utils/common";
import { changeStatus, reloadTicketDetails } from "src/actions/ticket/ticket";

export default function TicketStatusMenu({
  status = "",
  onSuccess = function () {},
  ticketId = null,
}) {
  const ticketDetailsReload = useSelector((state) => state.tickets.reload);
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const StatusNew = status.filter((x) => x !== null)[0];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [_status, setStatus] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  const updateOrderStatus = async (e) => {
    dispatch(reloadTicketDetails(ticketDetailsReload));
    dispatch(
      changeStatus(
        {
          id: ticketId,
          status: e.target.id,
        },
        ticketId
      )
    );
  };

  const getStatusIcon = (status) => {
    var color;
    StatusNew == "Pending"
      ? (color = "warning")
      : StatusNew == "Canceled"
      ? (color = "error")
      : StatusNew == "In Progress"
      ? (color = "info")
      : StatusNew == "Solved"
      ? (color = "success")
      : (color = "secondary");

    return (
      <Chip
        size="small"
        label={status}
        color={color}
        clickable
        onClick={handleClick}
      />
    );
  };

  return (
    <React.Fragment>
      {getStatusIcon(_status ? _status : status)}
      <Menu
        anchorEl={anchorEl}
        open={
          _UserInfo.roleOEndpoint.map((data) => data.Code).includes("T3-01")
            ? open
            : false
        }
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {Ticket_Status.map((status) => (
          <MenuItem
            key={status.id}
            id={status.id}
            disabled={status.id == 0}
            onClick={updateOrderStatus}
          >
            <ListItemIcon>
              <Icon>{status.icon}</Icon>
            </ListItemIcon>
            {status.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
