import {
  OPEN_DEALS_DIALOG,
  GET_BY_ID_DEALS,
  GET_DEALS_LIST,
  GET_ALL_DEALS,
  SAVE_DEALS_PAGE_NO,
  RESET_DEALS_FORM,
  RELOAD_DEALS,
  SAVE_DEALS_SEARCH_BODY,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  form: {
    id: null,
    nameAr: "",
    nameEn: "",
    nameKu: "",
    attachmentId: "",
    url: "",
    color: "",
    vendorId: null,
    vendorCategoryId: null,
  },
  data: {
    data: [],
    rowCount: 0,
    pageCount: 0,
  },
  dealsList: [],
  searchBody: {
    pageSize: 15,
    name: "",
  },
  getById: {},
};

function dealsReducer(deals = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_DEALS:
      deals.data.data = payload.data;
      deals.data.pageCount = payload.pageCount;
      deals.data.rowCount = payload.rowCount;
      return deals;

    case GET_DEALS_LIST:
      deals.dealsList = payload;
      return deals;

    case GET_BY_ID_DEALS:
      deals.form.id = payload.id;
      deals.form.nameAr = payload.nameAr;
      deals.form.nameEn = payload.nameEn;
      deals.form.nameKu = payload.nameKu;
      deals.form.imageUrl = payload.imageUrl;
      deals.form.attachmentId = payload.attachmentId;
      deals.form.url = payload.url;
      deals.form.color = payload.color;
      deals.form.vendorId = payload.vendor?.id;
      deals.form.vendorCategoryId = payload.vendorCategory?.id;
      return deals;

    case OPEN_DEALS_DIALOG:
      return { ...deals, openDialog: !deals.openDialog };

    case SAVE_DEALS_PAGE_NO:
      return { ...deals, pageNo: payload };

    case RELOAD_DEALS:
      return { ...deals, reload: !deals.reload };

    case SAVE_DEALS_SEARCH_BODY:
      return { ...deals, searchBody: payload };

    case RESET_DEALS_FORM:
      deals.form.id = null;
      deals.form.nameEn = "";
      deals.form.nameAr = "";
      deals.form.nameKu = "";
      deals.form.attachmentId = "";
      deals.form.url = "";
      deals.form.color = "";
      deals.form.vendorId = null;
      deals.form.vendorCategoryId = null;
      return deals;

    default:
      return deals;
  }
}

export default dealsReducer;
