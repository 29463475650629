import { Box, Icon, Typography } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { statusHistories } from "src/actions/ticket/ticket";
import { Ticket_Status } from "src/utils/common";
import CustomSpinner from "src/components/CustomSpinner";

export default function TicketTimelineDialog(props) {
  const dispatch = useDispatch();

  const ticketStatusHistory = useSelector(
    (state) => state.tickets.statusHistory
  );

  useEffect(
    () => (props.ticketId ? dispatch(statusHistories(props.ticketId)) : null),
    [props.ticketId, props.status]
  );

  const newData =
    ticketStatusHistory &&
    ticketStatusHistory.map((x) => {
      const ids = Ticket_Status.filter((d) => {
        return d.id == x.new;
      });
      return { ...x, new: ids[0].name };
    });

  return (
    <Box>
      {!newData ? (
        <CustomSpinner />
      ) : (
        <Timeline position="alternate" sx={{ padding: 0 }}>
          {newData.reverse().map((status, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {moment(status.createdAt).format("yyyy-MM-DD HH:mm:ss")}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  color={status.new == "Canceled" ? "error" : "success"}
                >
                  <Icon>{status.new == "Canceled" ? "close" : "check"}</Icon>
                </TimelineDot>
                {status.id != 2 ? <TimelineConnector /> : <></>}
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h5" component="span">
                  {status.new}
                </Typography>
                <Typography variant="body2">{status.username}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      )}
    </Box>
  );
}
