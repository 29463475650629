import {
  TextField,
  Grid,
  Stack,
  FormControlLabel,
  Switch,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
  createItemSupplier,
  openItemSupplierDialog,
  resetItemSupplierForm,
  updateItemSupplier,
} from "src/actions/gifts/supplier";
import { getAllAddresses } from "src/actions/addresses";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import { week_days } from "src/utils/common";

const ItemSupplierForm = () => {
  const dispatch = useDispatch();

  const _Supplier = useSelector((state) => state.itemSupplier);
  const reload = useSelector((state) => state.itemSupplier.loading);
  const searchBody = useSelector((state) => state.itemSupplier.searchBody);
  const pageNo = useSelector((state) => state.itemSupplier.pageNo);
  const addresses = useSelector((state) => state.addresses);

  const [selectedProvince, setSelectedProvince] = useState({
    nameAR: "",
    nameUS: "",
    cities: [],
  });

  useEffect(() => {
    dispatch(getAllAddresses());
  }, [dispatch]);

  const isAdd = _Supplier.form.id ? false : true;
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: _Supplier.form,
  });

  useEffect(() => {
    reset({ ..._Supplier.form });
  }, [_Supplier.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetItemSupplierForm());
    };
    // eslint-disable-next-line no-use-before-define
  }, [dispatch]);

  const onSubmit = (data) => {
    const workTimeSchedule = data.workTimeSchedule
      .filter((x) => x.checked)
      .map((x) => {
        return {
          weekDay: x.weekDay,
          checked: x.checked,
          startTime: x.startTime?.format("HH:mm"),
          endTime: x.endTime?.format("HH:mm"),
        };
      });
    if (isAdd) {
      dispatch(
        createItemSupplier(
          {
            nameEn: data.nameEn,
            nameAr: data.nameAr,
            nameKu: data.nameKu,
            phoneNumber: data.phoneNumber,
            note: data.note ?? "",
            provinceId: data.provinceId,
            cityId: data.cityId,
            workTimeSchedule: workTimeSchedule,
          },
          pageNo,
          searchBody
        )
      );
    } else {
      dispatch(
        updateItemSupplier(
          data.id,
          {
            nameEn: data.nameEn,
            nameAr: data.nameAr,
            nameKu: data.nameKu,
            phoneNumber: data.phoneNumber,
            note: data.note ?? "",
            provinceId: data.provinceId,
            cityId: data.cityId,
            isActive: data.isActive,
            workTimeSchedule: workTimeSchedule,
          },
          pageNo,
          searchBody
        )
      );
    }
  };

  const handleDialog = () => {
    dispatch(openItemSupplierDialog(null));
  };

  useEffect(() => {
    setSelectedProvince({
      ...selectedProvince,
      cities: addresses.filter(
        (city) => city.id === watch("provinceId") && city.cities
      )[0]?.cities,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses, watch("provinceId")]);

  const [selectedDays, setSelectedDays] = useState([]);
  const [lastSetTime, setLastSetTime] = useState({
    startTime: null,
    endTime: null,
  });

  const handleDaySelect = (day, checked) => {
    setSelectedDays((prevDays) =>
      checked ? [...prevDays, day] : prevDays.filter((d) => d !== day)
    );

    if (checked && lastSetTime.startTime && lastSetTime.endTime) {
      setValue(`workTimeSchedule[${day}].startTime`, lastSetTime.startTime);
      setValue(`workTimeSchedule[${day}].endTime`, lastSetTime.endTime);
    }
  };

  const handleTimeChange = (timeType, index, newValue) => {
    if (selectedDays.includes(index)) {
      selectedDays.forEach((day) => {
        setValue(`workTimeSchedule[${day}].${timeType}`, newValue);
      });
      setLastSetTime((prevTime) => ({ ...prevTime, [timeType]: newValue }));
    } else {
      setValue(`workTimeSchedule[${index}].${timeType}`, newValue);
    }
  };

  return (
    <CustomDialog
      title={isAdd ? "Create product supplier" : "Update product supplier"}
      isOpen={_Supplier.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Controller
              name="nameEn"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  inputProps={{
                    minLength: 4,
                  }}
                  size="small"
                  label="Name English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="nameAr"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="nameKu"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name Kurdish"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  type="number"
                  inputProps={{
                    minLength: 11,
                  }}
                  size="small"
                  label="Phone Number"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="provinceId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={addresses || []}
                  getOptionLabel={(option) =>
                    option.nameAR
                      ? `${option.nameAR} , ${option.nameUS}`
                      : "Not selected"
                  }
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Provinces" />
                  )}
                  onChange={(event, value) => {
                    setSelectedProvince(
                      value ? value : { nameAR: "", nameUS: "", cities: [] }
                    );
                    setValue("provinceId", value ? value.id : 0, {
                      shouldDirty: true,
                    });
                  }}
                  value={
                    addresses.filter((x) => x.id === field.value)[0]
                      ? addresses.filter((x) => x.id === field.value)[0]
                      : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="cityId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={selectedProvince.cities || []}
                  getOptionLabel={(option) =>
                    option
                      ? `${option.nameAR} , ${option.nameUS}`
                      : "Not selected"
                  }
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Cities" />
                  )}
                  onChange={(event, value) => {
                    setValue("cityId", value ? value.id : 0, {
                      shouldDirty: true,
                    });
                  }}
                  value={
                    addresses
                      .map((city) => city.cities)
                      .flat()
                      .filter((x) => x.id === field.value)[0]
                      ? addresses
                          .map((city) => city.cities)
                          .flat()
                          .filter((x) => x.id === field.value)[0]
                      : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            {week_days.map((day, index) => (
              <Grid container spacing={2} my={1} key={index}>
                <Grid item xs={12} md={3}>
                  <Controller
                    name={`workTimeSchedule[${index}].checked`}
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            {...field}
                            checked={watch(
                              `workTimeSchedule[${index}].checked`
                            )}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setValue(
                                  `workTimeSchedule[${index}].checked`,
                                  true,
                                  {
                                    shouldDirty: true,
                                  }
                                );
                              } else {
                                setValue(
                                  `workTimeSchedule[${index}].checked`,
                                  false,
                                  {
                                    shouldDirty: true,
                                  }
                                );
                              }
                            }}
                          />
                        }
                        label={day.name}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name={`workTimeSchedule[${index}].startTime`}
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopTimePicker
                          inputFormat="HH:mm"
                          label="Opens Time"
                          value={field.value}
                          onChange={(newValue) => {
                            handleTimeChange("startTime", index, newValue);
                          }}
                          disabled={
                            watch(`workTimeSchedule[${index}].checked`)
                              ? false
                              : true
                          }
                          renderInput={(params) => (
                            <TextField
                              required={
                                watch(`workTimeSchedule[${index}].checked`)
                                  ? true
                                  : false
                              }
                              size="small"
                              {...params}
                              fullWidth
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "hours (HH): minutes(MM)",
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name={`workTimeSchedule[${index}].endTime`}
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopTimePicker
                          inputFormat="HH:mm"
                          label="Close Time"
                          value={field.value}
                          onChange={(newValue) => {
                            handleTimeChange("endTime", index, newValue);
                          }}
                          disabled={
                            watch(`workTimeSchedule[${index}].checked`)
                              ? false
                              : true
                          }
                          renderInput={(params) => (
                            <TextField
                              required={
                                watch(`workTimeSchedule[${index}].checked`)
                                  ? true
                                  : false
                              }
                              size="small"
                              {...params}
                              fullWidth
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "hours (HH): minutes(MM)",
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  {" "}
                  <Controller
                    name={`selectedDays[${index}]`}
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        disabled={
                          watch(`workTimeSchedule[${index}].checked`)
                            ? false
                            : true
                        }
                        checked={selectedDays.includes(index)}
                        onChange={(e) =>
                          handleDaySelect(index, e.target.checked)
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextField
                  multiline
                  rows={4}
                  size="small"
                  label="Note"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          {!isAdd ? (
            <Grid item xs={12}>
              <Controller
                name="isActive"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("isActive", e.target.checked, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    }
                    label="Is Active!"
                  />
                )}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={reload}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default ItemSupplierForm;
