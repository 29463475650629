import axios from "../axios";

class ExchangeRate {
    getExchangeRate(){
        return axios.get(`/exchangerate/getprice`);
    }

    updateExchangeRate(data){
        return axios.put(`/exchangerate/updateprice?price=${data}`);
    }
}
export default new ExchangeRate();