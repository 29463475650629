import {
  TextField,
  Grid,
  Stack,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { getAllGiftItem } from "src/actions/gifts/giftItem";
import {
  openCODdialog,
  updateRemainingCODAmount,
} from "src/actions/gifts/orders";
import moment from "moment";
import { CgProfile } from "react-icons/cg";
import { showErrorSnackbar } from "src/actions/snackbarMessages";

const CODForm = (props) => {
  const dispatch = useDispatch();

  const order = useSelector((state) => state.giftOrder);
  const loading = useSelector((state) => state.giftOrder.reload);
  const pageNo = useSelector((state) => state.giftItems.saveGiftItemsPageNum);
  const searchBody = useSelector((state) => state.giftItems.searchBody);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
    // setValue,
    // watch,
  } = useForm({
    defaultValues: {
      amount: 0,
    },
  });

  const handleDialog = () => {
    dispatch(openCODdialog());
  };

  useEffect(() => {
    reset({ amount: 0 });
  }, [reset]);

  const onSubmit = (data) => {
    if (data.amount <= 0 || data.amount > order?.form?.remainingCODAmount)
      return dispatch(
        showErrorSnackbar(
          `Amount should be greater than 0 and less than or equal to ${order?.form?.remainingCODAmount} IQD.`
        )
      );
    else {
      dispatch(updateRemainingCODAmount(order?.form?.id, data.amount)).then(
        (x) => {
          reset({ amount: 0 });
          handleDialog();
          dispatch(getAllGiftItem(pageNo + 1, searchBody));
        }
      );
    }
  };

  return (
    <CustomDialog
      title={"Cash on delivery amount"}
      isOpen={order.openCODDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            {order.form.remainingCODAmount > 0 && (
              <Typography
                sx={{ py: 2 }}
                color="green"
                variant="h4"
                component="div"
              >
                Remaining cash on delivery amount:{" "}
                {order?.form?.remainingCODAmount?.toLocaleString()} IQD
              </Typography>
            )}
            {order.form.remainingCodAmountHistory?.length > 0 && (
              <Typography variant="h6" component="div">
                Payment History:
              </Typography>
            )}
            {order.form.remainingCodAmountHistory?.map((item, index) => (
              <Stack
                key={index}
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={8}
              >
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <CgProfile size={30} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={item?.user?.name}
                      secondary={moment(item?.createdAt).format(
                        "YYYY-MM-DD / hh:mm a"
                      )}
                    />
                  </ListItem>
                </List>
                <Typography variant="h4" component="div">
                  {item?.amount?.toLocaleString()} IQD
                </Typography>
              </Stack>
            ))}
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  type="number"
                  size="small"
                  label="Amount (IQD)"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
              {
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => reset({ ...order.form })}
                >
                  Clear Text
                </Button>
              }
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};
export default CODForm;
