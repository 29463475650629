import { Grid, Box } from "@mui/material";
import React from "react";
import OrdersHeaders from "./home/header";
import OrderDetails from "./orderDetails";
import OrdersTable from "./home/table";
import { Helmet } from "react-helmet";

const GiftOrders = (props) => {
  return (
    <>
      <Helmet>
        <title>{props.orderDetails ? 'Order Details' : 'Orders'} | Orderii</title>
      </Helmet>
      <Box sx={{ minWidth: { lg: '100%', xs: 1800 } }}>
        <Grid container>
          <Grid item xs={12} sx={{ p: 2 }}>
            <OrdersHeaders orderDetails={props.orderDetails ? true : false} />
          </Grid>
          <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
            {props.orderDetails ? <OrderDetails /> : < OrdersTable />}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default GiftOrders;
