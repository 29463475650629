import {
  FAQ_RESET_FORM,
  FAQ_GET_BY_ID,
  FAQ_PAGE_NUMBER,
  FAQ_OPEN_DIALOG,
  FAQ_RELOAD,
  FAQ_GET_ALL,
  FAQ_SEARCH_BODY,
} from "./type";

import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import FAQService from "src/api/services/faq.service";

export const openDialog = () => (dispatch) => {
  dispatch({
    type: FAQ_OPEN_DIALOG,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: FAQ_RESET_FORM,
  });
};

export const reloadFAQ = () => (dispatch) => {
  dispatch({
    type: FAQ_RELOAD,
  });
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: FAQ_SEARCH_BODY,
    payload: data,
  });
};

export const savePageNo = (data) => (dispatch) => {
  dispatch({
    type: FAQ_PAGE_NUMBER,
    payload: data,
  });
};

export const createFAQ = (data, pagNo, searchBody) => async (dispatch) => {
  try {
    const res = await FAQService.create(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(getAllFAQ(pagNo, searchBody));
    dispatch(openDialog());
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllFAQ = (pageNo, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadFAQ());
    const res = await FAQService.getAll(pageNo, searchBody);
    dispatch({
      type: FAQ_GET_ALL,
      payload: res.data.data,
    });
    dispatch(reloadFAQ());
  } catch (err) {
    dispatch(reloadFAQ());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateFAQ = (id, data, pageNo, searchBody) => async (dispatch) => {
  try {
    const res = await FAQService.update(id, data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    dispatch(getAllFAQ(pageNo, searchBody));
    dispatch(openDialog());
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteFAQ = (id, pageNo, searchBody) => async (dispatch) => {
  try {
    const res = await FAQService.delete(id);
    dispatch(showSuccessSnackbar("Deleted successfully!"));
    dispatch(getAllFAQ(pageNo, searchBody));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadFAQ());
    const res = await FAQService.getById(id);
    dispatch({
      type: FAQ_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadFAQ());
  } catch (err) {
    dispatch(reloadFAQ());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(reloadFAQ());
    const res = await FAQService.getById(id);
    dispatch({
      type: FAQ_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadFAQ());
    dispatch(openDialog());
  } catch (err) {
    dispatch(reloadFAQ());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
