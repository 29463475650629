import { Grid, Box } from "@mui/material";
import React from "react";
import VendorsCategoryTable from "./home/table";
import VendorsCategoryHeader from "./home/header/header";
import { CustomDialog } from "src/components/CustomDialog";
import { useDispatch, useSelector } from "react-redux";
import { openCategoryVendorDialog } from "src/actions/vendors";

const VendorsCategory = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.vendors.openCategoryDialog);
  return (
    <CustomDialog
      title={"VENDORS CATEGORY"}
      isOpen={dialog}
      keepMounted
      handleClose={() => {
        dispatch(openCategoryVendorDialog());
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
    >
      <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
        <Grid container>
          <Grid item xs={12} sx={{ p: 2 }}>
            <VendorsCategoryHeader />
          </Grid>
          <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
            <VendorsCategoryTable />
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
};

export default VendorsCategory;
