import { Helmet } from "react-helmet";
import { Box, Stack } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import RolePermission from "src/components/RolePermission";
import {
  setNotifyAllDialog,
  setNotifyByUserIdDialog,
  setNotifySegmentDialog,
} from "src/actions/notification";
import NotifyAllForm from "../../form/NotifyAllForm";
import { IoIosNotifications } from "react-icons/io";
import { MdOutlineNotificationAdd } from "react-icons/md";
import NotifySegmentForm from "src/pages/shared/notification/form/NotifySegmentForm";
import NotifyByUserIdForm from "src/pages/shared/notification/form/NotifyByUserIds";

const NotificationHeader = () => {
  const dispatch = useDispatch();

  const _NotifyAll = useSelector((state) => state.notification.notifyAllDialog);
  const _NotifySegment = useSelector(
    (state) => state.notification.notifySegmentDialog
  );
  const _NotifyByUserId = useSelector(
    (state) => state.notification.notifyByUserIdDialog
  );

  return (
    <>
      <Helmet>
        <title>Notifications | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Notifications
              </Typography>
            }
            avatar={<IoIosNotifications size={20} />}
            action={
              <Stack spacing={2} direction="row">
                <RolePermission permission="19-01">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<MdOutlineNotificationAdd />}
                    onClick={() => dispatch(setNotifyAllDialog())}
                  >
                    Notify All
                  </Button>
                </RolePermission>
                <RolePermission permission="20-01">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<MdOutlineNotificationAdd />}
                    onClick={() => dispatch(setNotifySegmentDialog())}
                  >
                    Notify Segment
                  </Button>
                </RolePermission>
                <RolePermission permission="19-02">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<MdOutlineNotificationAdd />}
                    onClick={() => dispatch(setNotifyByUserIdDialog())}
                  >
                    Notify By User Ids
                  </Button>
                </RolePermission>
              </Stack>
            }
          />
          {_NotifyAll ? <NotifyAllForm /> : null}
          {_NotifySegment ? <NotifySegmentForm /> : null}
          {_NotifyByUserId ? <NotifyByUserIdForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default NotificationHeader;
