import {
  TextField,
  Grid,
  Stack,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import {
  changeTranslationStatus,
  createVendor,
  openDialog,
  resetForm,
  updateVendor,
} from "src/actions/vendors";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";

const VendorsForm = () => {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const _Vendors = useSelector((state) => state.vendors);
  const loading = useSelector((state) => state.vendors.reload);

  const [showDisclaimer, setShowDisclaimer] = useState(
    _Vendors.form.showDisclaimer || false
  );

  const isAdd = _Vendors.form.id ? false : true;
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _Vendors.form,
  });

  useEffect(() => {
    reset({ ..._Vendors.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_Vendors.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(createVendor(data));
    } else {
      dispatch(updateVendor(data));
    }
  };

  const handleDialog = () => {
    dispatch(openDialog());
  };

  useEffect(() => {
    if (showDisclaimer === false) {
      setValue("disclaimerEn", null);
      setValue("disclaimerAr", null);
      setValue("disclaimerKu", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDisclaimer]);

  function handleTranslation(id) {
    dispatch(changeTranslationStatus(id));
  }

  return (
    <CustomDialog
      title={isAdd ? "CREATE VENDOR" : "UPDATE VENDOR"}
      isOpen={_Vendors.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="nameAR"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="nameKu"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name Kurdish"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="websiteUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Website Url"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="logoUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Logo Url"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="region"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Region"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="sequence"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Sequence"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="vendorShippingCost"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Vendor Shipping Cost"
                  fullWidth
                  type="number"
                  value={field.value}
                  onChange={(e) => {
                    setValue("vendorShippingCost", e.target.value);
                    if (e.target.value <= 0) {
                      setValue("maximumShippingCost", 0);
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="maximumShippingCost"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  // disabled={watch("vendorShippingCost") <= 0}
                  size="small"
                  type="number"
                  label="Maximum Shipping Cost"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="vendorDeliveryDays"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Vendor Delivery Days"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="showDisclaimer"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      checked={field.value}
                      onChange={(e) => {
                        setValue("showDisclaimer", e.target.checked);
                        setShowDisclaimer(!showDisclaimer);
                      }}
                    />
                  }
                  label="Show Disclaimer !"
                />
              )}
            />
          </Grid>
          {showDisclaimer && (
            <Grid item xs={12} md={6}>
              <Controller
                name="disclaimerEn"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    multiline
                    rows={6}
                    size="small"
                    label="Disclaimer English"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
          )}
          {showDisclaimer && (
            <Grid item xs={12} md={6}>
              <Controller
                name="disclaimerAr"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    multiline
                    rows={6}
                    size="small"
                    label="Disclaimer Arabic"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
          )}
          {showDisclaimer && (
            <Grid item xs={12} md={6}>
              <Controller
                name="disclaimerKu"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    multiline
                    rows={6}
                    size="small"
                    label="Disclaimer Kurdish"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
          )}
          {isAdd ? (
            <Grid item xs={12} md={12}>
              <Controller
                name="activeTranslation"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue("activeTranslation", e.target.checked);
                        }}
                      />
                    }
                    label="Active Translation !"
                  />
                )}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={
                      _UserInfo.roleOEndpoint
                        .map((data) => data.Code)
                        .includes("33-04")
                        ? false
                        : true
                    }
                    checked={_Vendors.form?.activeTranslation}
                    onChange={(e) => handleTranslation(_Vendors.form?.id)}
                  />
                }
                label="Active Translation !"
              />
            </Grid>
          )}

          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default VendorsForm;
