import {
  POP_UP_POST_RESET_FORM,
  POP_UP_POST_GET_BY_ID,
  POP_UP_POST_PAGE_NUMBER,
  POP_UP_POST_OPEN_DIALOG,
  POP_UP_POST_RELOAD,
  POP_UP_POST_GET_ALL_LIST,
  POP_UP_POST_GET_ALL,
} from "./type";

import PopUpPostService from "../api/services/popUpPost.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const openDialog = () => (dispatch) => {
  dispatch({
    type: POP_UP_POST_OPEN_DIALOG,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: POP_UP_POST_RESET_FORM,
  });
};

export const reloadPopUpPost = () => (dispatch) => {
  dispatch({
    type: POP_UP_POST_RELOAD,
  });
};

export const savePopUpPostPageNo = (data) => (dispatch) => {
  dispatch({
    type: POP_UP_POST_PAGE_NUMBER,
    payload: data,
  });
};

export const createPopUpPost = (data, pagNo, dialog) => async (dispatch) => {
  try {
    const res = await PopUpPostService.create(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(getAllPopUpPost(pagNo));
    if (dialog) {
      dispatch(openDialog());
    }
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllPopUpPost = (pageNo) => async (dispatch) => {
  try {
    dispatch(reloadPopUpPost());
    const res = await PopUpPostService.getAll(pageNo);
    dispatch({
      type: POP_UP_POST_GET_ALL,
      payload: res.data.data,
    });
    dispatch(reloadPopUpPost());
  } catch (err) {
    dispatch(reloadPopUpPost());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getListPopUpPost = () => async (dispatch) => {
  try {
    dispatch(reloadPopUpPost());
    const res = await PopUpPostService.getList();
    dispatch({
      type: POP_UP_POST_GET_ALL_LIST,
      payload: res.data.data,
    });
    dispatch(reloadPopUpPost());
  } catch (err) {
    dispatch(reloadPopUpPost());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updatePopUpPost = (data, pagNo, pageSize) => async (dispatch) => {
  try {
    const res = await PopUpPostService.update(data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    dispatch(getAllPopUpPost(pagNo, pageSize));
    dispatch(openDialog());
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deletePopUpPost = (id, pagNo) => async (dispatch) => {
  try {
    const res = await PopUpPostService.delete(id);
    dispatch(showSuccessSnackbar("Deleted successfully!"));
    dispatch(getAllPopUpPost(pagNo));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadPopUpPost());
    const res = await PopUpPostService.getById(id);
    dispatch({
      type: POP_UP_POST_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadPopUpPost());
  } catch (err) {
    dispatch(reloadPopUpPost());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(reloadPopUpPost());
    const res = await PopUpPostService.getById(id);
    dispatch({
      type: POP_UP_POST_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadPopUpPost());
    dispatch(openDialog());
  } catch (err) {
    dispatch(reloadPopUpPost());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
