import axios from "../axios";

class ScheduledActionService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/scheduledActions/${pageNo}?${params}`);
  }

  getById(id) {
    return axios.get(`/scheduledActions/getById/${id}`);
  }

  create(data) {
    return axios.post("/scheduledActions", data);
  }

  update(id, data) {
    return axios.put(`/scheduledActions/${id}`, data);
  }

  delete(id) {
    return axios.delete(`/scheduledActions/${id}`);
  }

  deleteStatus(id) {
    return axios.delete(`/scheduledActions/deletestatus/${id}`);
  }
}
export default new ScheduledActionService();
