import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme,
} from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Icon, IconButton, Stack } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateRangepicker from "src/components/DateRangePicker";
import { getOrderCountByStatus } from "src/actions/gifts/dashboard";
import CustomSpinner from "src/components/CustomSpinner";

ChartJS.register(ArcElement, Tooltip, Legend);

const OrdersByStatus = () => {
  const dispatch = useDispatch();

  const ordersByStatusData = useSelector(
    (state) => state.giftDashboard.ordersPerStatus
  );

  const theme = useTheme();
  const [viewCalendar, setViewCalendar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: moment().format("YYYY-MM-01"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    setLoading(true);
    dispatch(
      getOrderCountByStatus({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dateRange, dispatch]);

  const color = ordersByStatusData?.ordersPerStatus?.labels?.map((status) =>
    status === "Pending"
      ? "#ffa726"
      : status === "Out for delivery"
      ? "#d32f2f"
      : status === "Delivered"
      ? "#66bb6a"
      : status === "Ready for delivery"
      ? "#0288d1"
      : status === "Preparing for delivery"
      ? "#0288d1"
      : status === "Accepted"
      ? "#01579b"
      : status === "Canceled"
      ? "#d32f2f"
      : status === "Preparing"
      ? "#9C27B0"
      : status === "On its way to you"
      ? "#9C27B0"
      : null
  );

  const data = {
    datasets: [
      {
        data: ordersByStatusData?.ordersPerStatus?.data,
        backgroundColor: color,
        borderWidth: 2,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: ordersByStatusData?.ordersPerStatus?.labels,
  };

  const options = {
    animation: true,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card>
      <CardHeader
        title="Orders by status"
        subheader={
          <Stack direction="column" spacing={1}>
            <Typography variant="caption">
              {dateRange.startDate + " - " + dateRange.endDate}
            </Typography>
            {viewCalendar && (
              <Box sx={{ width: { md: "70%", xs: "100%" } }}>
                <DateRangepicker
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  handleOnChange={(searchDate) => {
                    const updatedFilter = {
                      from: moment(searchDate[0].startDate).format(
                        "YYYY-MM-DD"
                      ),
                      to: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
                    };
                    if (
                      updatedFilter.from !== dateRange.startDate ||
                      updatedFilter.to !== dateRange.endDate
                    ) {
                      setDateRange({
                        startDate: moment(searchDate[0].startDate).format(
                          "YYYY-MM-DD"
                        ),
                        endDate: moment(searchDate[0].endDate).format(
                          "YYYY-MM-DD"
                        ),
                      });
                    }
                  }}
                />
              </Box>
            )}
          </Stack>
        }
        action={
          <IconButton onClick={() => setViewCalendar(!viewCalendar)}>
            <Icon aria-label="settings">calendar_month</Icon>
          </IconButton>
        }
      />
      <Divider />
      {loading ? (
        <Box
          height={415}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CustomSpinner />
        </Box>
      ) : (
        <CardContent>
          <Box
            sx={{
              height: 300,
              position: "relative",
            }}
          >
            <Doughnut data={data} options={options} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 2,
            }}
          >
            <Box
              sx={{
                pt: 1,
                textAlign: "center",
              }}
            >
              <Typography variant="h4">
                {`Total revenue : ${ordersByStatusData?.revenue.toLocaleString()} IQD`}
              </Typography>
              <Typography variant="h4">
                {`Total Orders : ${ordersByStatusData?.ordersCount.toLocaleString()}`}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      )}
    </Card>
  );
};

export default OrdersByStatus;
