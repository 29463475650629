import axiosService from "src/api/axiosService";


class ExternalItemService {
  create(data) {
    return axiosService.post(`/api/externalitem/create`, data);
  }

  getById(id) {
    return axiosService.get(`/api/externalitem/getbyid/${id}`);
  }


  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach(key => {
        params += `${key}=${data[key]}&`;
      });
    }
    return axiosService.get(`/api/externalitem/all/${pageNo}?${params}`);
  }

  UpdateStatus(data) {
    return axiosService.put(`/api/externalitem/updatestatus`, data);
  }

  UpdateNote(data) {
    return axiosService.put(`/api/externalitem/updatenote`, data);
  }
}

export default new ExternalItemService();
