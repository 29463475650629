import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { openUploadImgDialog } from "src/actions/imageUploadCenter";
import { IoMdImages } from "react-icons/io";
import RolePermission from "src/components/RolePermission";
import UploadImageForm from "src/pages/cross/Configuration/ImageUploadCenter/form/Form";

const ImageUploadHeader = () => {
  const dispatch = useDispatch();

  const openUploadDialog = useSelector((state) => state.shareCenter.openDialog);

  return (
    <>
      <Helmet>
        <title>Image Upload Center | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Image Upload Center
              </Typography>
            }
            avatar={<IoMdImages size={20} />}
            action={
              <RolePermission permission="28-01">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() =>
                    dispatch(openUploadImgDialog(openUploadDialog))
                  }
                >
                  Upload Attachment
                </Button>
              </RolePermission>
            }
          />
          {openUploadDialog ? <UploadImageForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default ImageUploadHeader;
