import { useEffect } from "react";
import { TextField, Grid, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { Controller, useForm } from "react-hook-form";
import { resetStaffUsersForm, updateUserRoles } from "src/actions/dynamicAuth";
import { openStaffUsersDialog } from "src/actions/dynamicAuth";
import { LoadingButton } from "@mui/lab";

const StaffUsersForm = () => {
  const _DynamicAuth = useSelector((state) => state.dynamicAuth);
  const loading = useSelector((state) => state.dynamicAuth.reload);
  const _SearchBody = useSelector(
    (state) => state.dynamicAuth.staffUsersSearchBody
  );

  const isAdd = _DynamicAuth.staffUsersForm.id ? false : true;
  const {
    control,
    reset,
    formState: { isDirty },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: _DynamicAuth.staffUsersForm,
  });

  useEffect(() => {
    reset({ ..._DynamicAuth.staffUsersForm });
  }, [_DynamicAuth.staffUsersForm]);

  useEffect(() => {
    return () => {
      dispatch(resetStaffUsersForm());
    };
  }, []);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(
      updateUserRoles(
        {
          roleIds: data.roles,
          userId: _DynamicAuth.staffUsersForm.userId,
        },
        _SearchBody
      )
    );
  };

  const handleDialog = () => {
    dispatch(openStaffUsersDialog());
  };

  return (
    <CustomDialog
      title={isAdd ? "Update Roles" : "UPDATE"}
      isOpen={_DynamicAuth.openStaffUsersDialog}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="roles"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  id="roles"
                  options={_DynamicAuth.dynamicAuthRoleData.data || []}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Roles" />
                  )}
                  onChange={(event, value) => {
                    var ids = value.map((x) => x.id);
                    setValue("roles", value ? ids : null, {
                      shouldDirty: true,
                    });
                  }}
                  value={_DynamicAuth.dynamicAuthRoleData.data?.filter((x) =>
                    field.value ? field.value?.includes(x.id) : null
                  )}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <LoadingButton
              disabled={!isDirty}
              type="submit"
              variant="contained"
              color="primary"
              loading={loading}
            >
              Update Role
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default StaffUsersForm;
