import axios from "../axios";

class PromoService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/promocode/all/${pageNo}?${params}`);
  }

  getById(id) {
    return axios.get(`/promocode/getById/${id}`);
  }

  create(data) {
    return axios.post("/promocode/create", data);
  }

  update(data) {
    return axios.put(`/promocode/update`, data);
  }

  delete(id) {
    return axios.delete(`/promocode/delete?id=${id}`);
  }
  changeStatus(id, status) {
    return axios.put(`/promocode/changestatus/?id=${id}&status=${status}`);
  }
}
export default new PromoService();
