import axios from "../axios";

class DynamicAuthService {
  createRole(data) {
    return axios.post("/dynamicauth/role", data);
  }

  getAllRoles() {
    return axios.get(`/dynamicauth/roles/`);
  }

  getByIdRole(id) {
    return axios.get(`/dynamicauth/role/${id}`);
  }

  deleteRole(id) {
    return axios.delete(`/dynamicauth/role/${id}`);
  }

  createRoleEndPoint(data) {
    return axios.put("/dynamicauth/roleoendpoints", data);
  }

  deleteRoleEndPoint(data) {
    return axios.delete(`/dynamicauth/roleoendpoint`, data);
  }

  updateRoleEndPoint(data) {
    return axios.put(`/dynamicauth/roleoendpoints`, data);
  }

  getAllRoleEndPoint(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`/dynamicauth/roleoendpoints/${pageNo}?${params}`);
  }

  getAllOrderiiEndPont() {
    return axios.get(`/dynamicauth/oendpoints/`);
  }

  getAllStaffUsers(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`/user/userswithroles?${params}`);
  }

  updateUserRoles(data) {
    return axios.put("/user/modifyroles", data);
  }
}

export default new DynamicAuthService();
