import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  TextField,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  InputAdornment,
  Autocomplete,
  Typography,
  Stack,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import { AiOutlineSearch } from "react-icons/ai";
import IconButton from "@mui/material/IconButton";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import { CustomDialog } from "src/components/CustomDialog";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import {
  deleteUnknownItems,
  getAll,
  getById,
  resetForm,
  savePageNum,
  saveSearchBody,
} from "src/actions/unknownItems";
import moment from "moment";
import useCanShow from "src/components/CanShow";
import UnknownItemsStatusMenu from "src/pages/cross/unknownItems/Status/Manu";
import { UNKNOWN_STATUS } from "src/utils/common";
import DateRangepicker from "src/components/DateRangePicker";

const UnknownItemsTable = () => {
  const dispatch = useDispatch();

  const updateRole = useCanShow("67-04");
  const deleteRole = useCanShow("67-06");

  const unknownItemsData = useSelector((state) => state.unknownItems.data);
  const unknownItems = useSelector((state) => state.unknownItems);
  const loading = useSelector((state) => state.unknownItems.reload);
  const searchBody = useSelector((state) => state.unknownItems.searchBody);
  const _PageNo = useSelector((state) => state.unknownItems.pageNo);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(_PageNo ?? 0);
  const [search, setSearch] = useState({
    name: searchBody.name,
    sku: searchBody.sku,
    status: searchBody.status,
    fromCreatedAt: searchBody.fromCreatedAt,
    toCreatedAt: searchBody.toCreatedAt,
  });

  useEffect(() => {
    dispatch(getAll(page + 1, searchBody));
  }, [page, dispatch, searchBody]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    dispatch(savePageNum(newPage));
  };

  function deleteSelectedVendorCategory(id) {
    dispatch(deleteUnknownItems(id, page + 1, searchBody)).then((x) => {
      dispatch(resetForm());
      setOpenDeleteDialog(false);
    });
  }

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={<Typography variant="h5">{params.row.name}</Typography>}
        >
          <div
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {params.row.name}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "price",
      headerName: "Price ($)",
      flex: 1,
      renderCell: (params) => params.row.price.toLocaleString(),
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1.2,
    },
    {
      field: "note",
      headerName: "Note",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={<Typography variant="h5">{params.row.note}</Typography>}
        >
          <div
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {params.row.note}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <UnknownItemsStatusMenu
          id={params.row.id}
          status={params.row.statusName}
          statusNumber={params.row.status}
          onSuccess={() => {}}
        />
      ),
    },
    {
      field: "attachment",
      headerName: "Image",
      flex: 1,
      renderCell: (params) =>
        params.row.attachment.url ? (
          <Box
            component="img"
            src={params.row.attachment.url}
            sx={{ objectFit: "contain" }}
            width={90}
          />
        ) : null,
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 0.5,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body">
            {moment(params.row.createdAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="caption">
            {moment(params.row.createdAt).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          hidden={updateRole ? false : true}
          showInMenu
          onClick={(e) => {
            dispatch(getById(params.row.id, true));
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForever color="error" />}
          label="Delete"
          hidden={deleteRole ? false : true}
          showInMenu
          onClick={(e) => {
            dispatch(getById(params.row.id, false)).then((x) =>
              setOpenDeleteDialog(true)
            );
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
              dispatch(resetForm());
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            maxWidth="sm"
            onDelete={() => deleteSelectedVendorCategory(unknownItems.form.id)}
            selectedObject={`${unknownItems.form.name}`}
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={search.name}
              onChange={(e) => {
                setSearch({
                  ...search,
                  name: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBody({
                      ...searchBody,
                      name: search.name,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search SKU"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={search.sku}
              onChange={(e) => {
                setSearch({
                  ...search,
                  sku: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBody({
                      ...searchBody,
                      sku: search.sku,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              disabled={loading}
              options={UNKNOWN_STATUS}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Status" />}
              onChange={(event, value) => {
                dispatch(
                  saveSearchBody({
                    ...searchBody,
                    status: value ? value.id : null,
                  })
                );
              }}
              value={
                UNKNOWN_STATUS.filter((x) => x.id === searchBody.status)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DateRangepicker
              disabled={loading}
              startDate={searchBody.fromCreatedAt}
              endDate={searchBody.toCreatedAt}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: moment(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchBody.fromCreatedAt ||
                  updatedFilter.to !== searchBody.toCreatedAt
                ) {
                  dispatch(
                    saveSearchBody({
                      ...searchBody,
                      fromCreatedAt: moment(searchDate[0].startDate).format(
                        "YYYY-MM-DD"
                      ),
                      toCreatedAt: moment(searchDate[0].endDate).format(
                        "YYYY-MM-DD"
                      ),
                    })
                  );
                }
                setPage(0);
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  setPage(0);
                  dispatch(savePageNum(0));
                  setSearch({
                    name: "",
                    sku: "",
                    status: null,
                    fromCreatedAt: "",
                    toCreatedAt: "",
                    pageSize: 15,
                  });
                  dispatch(
                    saveSearchBody({
                      ...searchBody,
                      name: "",
                      sku: "",
                      status: null,
                      fromCreatedAt: "",
                      toCreatedAt: "",
                      pageSize: 15,
                    })
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box sx={{ height: { md: "calc(100vh - 270px)", xs: 1000 } }}>
                <DataGrid
                  sx={{
                    "& .css-204u17-MuiDataGrid-main": {
                      padding: "15px",
                    },
                    "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                      padding: "15px",
                    },
                  }}
                  columns={columns}
                  rows={unknownItemsData?.data}
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={100}
                  pagination
                  paginationMode="server"
                  rowCount={unknownItemsData.rowCount}
                  pageSize={searchBody.pageSize}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    dispatch(
                      saveSearchBody({
                        ...searchBody,
                        pageSize: newPageSize,
                      })
                    )
                  }
                  rowsPerPageOptions={[5, 15, 25, 75, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UnknownItemsTable;
