import { useSelector } from "react-redux";

const RolePermission = ({ permission, children }) => {
  const userRoles = useSelector((state) => state.users.userInfo.roleOEndpoint);

  return userRoles.map((data) => data.Code).includes(permission)
    ? children
    : null;
};

export default RolePermission;
