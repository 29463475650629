import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import EPaymentsForm from "../../form/Form";
import { openEPaymentsDialog } from "src/actions/EPayments";
import { MdOutlinePayments } from "react-icons/md";
import RolePermission from "src/components/RolePermission";

const EPaymentsHeader = () => {
  const dispatch = useDispatch();

  const _EPayments = useSelector((state) => state.epayments);

  return (
    <>
      <Helmet>
        <title>E-Payments | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                E-Payments
              </Typography>
            }
            avatar={<MdOutlinePayments size={20} />}
            action={
              <RolePermission permission="09-02">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => dispatch(openEPaymentsDialog(null))}
                >
                  ADD
                </Button>
              </RolePermission>
            }
          />
          {_EPayments ? <EPaymentsForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default EPaymentsHeader;
