
import axios from "../axios";

class ReferralCodeService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach(key => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`/referralcode/gethistory?pageNo=${pageNo}&${params}`);
  }
}
export default new ReferralCodeService()