import {
  GET_ALL_ORDERS,
  GET_BY_ID_ORDERS,
  OPEN_COD_DIALOG,
  RELOAD_ORDERS,
  SAVE_ORDERS_PAGE_NO,
  SAVE_ORDERS_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";

const initialState = {
  reload: false,
  openDialog: false,
  openCODDialog: false,
  pageNo: 0,
  pageSize: 10,
  form: {},
  giftOrdersData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  searchBody: {
    pageSize: 15,
    giftOrderNumber: "",
    sku: "",
    giftItemName: "",
    promoCodeName: "",
    sender: "",
    receiver: "",
    hasAdditionalFees: null,
    isRated: null,
    status: null,
    paymentType: null,
    hasRemainingAmount: null,
    fromDeliveryDate: null,
    toDeliveryDate: null,
    fromCreatedAt: null,
    toCreatedAt: null,
    // cityId: null,
    // provinceId: null,
  },
};

function giftOrderReducer(giftOrder = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_ORDERS:
      giftOrder.giftOrdersData.data = payload.data;
      giftOrder.giftOrdersData.total = payload.pageCount;
      giftOrder.giftOrdersData.rowCount = payload.rowCount;
      return giftOrder;

    case GET_BY_ID_ORDERS:
      giftOrder.form = payload;
      return giftOrder;

    case SAVE_ORDERS_PAGE_NO:
      return { ...giftOrder, pageNo: payload };

    case SAVE_ORDERS_SEARCH_BODY:
      return { ...giftOrder, searchBody: payload };

    case RELOAD_ORDERS:
      return { ...giftOrder, reload: !giftOrder.reload };

    case OPEN_COD_DIALOG:
      return { ...giftOrder, openCODDialog: !giftOrder.openCODDialog };

    default:
      return giftOrder;
  }
}

export default giftOrderReducer;
