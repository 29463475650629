import { Helmet } from "react-helmet";
import { Box, Card, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import AgentCiyConfig from "src/pages/cross/Configuration/agentCityConfig/agents/Index";
import AgentRequestProvinces from "src/pages/cross/Configuration/agentCityConfig/agentRequestProvinces/Index";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Agents = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Agents | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
          <Card sx={{ mx: 3 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs"
                variant="fullWidth"
              >
                <Tab label="Agent Ciy Config." {...a11yProps(0)} />
                <Tab label="Agent Request Provinces" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Card>
          <TabPanel value={value} index={0}>
            <AgentCiyConfig />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AgentRequestProvinces />
          </TabPanel>
        </Box>
      </Box>
    </>
  );
};

export default Agents;
