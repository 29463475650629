import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, LinearProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { CustomDialog } from "src/components/CustomDialog";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import {
  deleteCancelOrderReasons,
  getAllCancelOrderReasons,
  getByIdForUpdate,
  saveCancelOrderReasonsPageNum,
} from "src/actions/orderCancelReason";
import useCanShow from "src/components/CanShow";

export default function OrderCancelReasonsTable() {
  const dispatch = useDispatch();

  const editRole = useCanShow("21-02");
  const deleteRole = useCanShow("21-03");

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteDialogData, setOpenDeleteDialogData] = useState("");

  const _OrderCancelReasons = useSelector(
    (state) => state.orderCancelReason.orderCancelReasonData
  );
  const _OrderCancelReason = useSelector((state) => state.orderCancelReason);
  const loading = useSelector((state) => state.orderCancelReason.reload);
  const pageNo = useSelector(
    (state) => state.orderCancelReason.saveOrderCancelPageNum
  );

  useEffect(() => {
    dispatch(getAllCancelOrderReasons(pageNo));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  const handlePageChange = (newPage) => {
    dispatch(saveCancelOrderReasonsPageNum(newPage + 1));
  };

  function deleteOrderReasonCode(id) {
    dispatch(deleteCancelOrderReasons(id, pageNo)).then((res) => {
      setOpenDeleteDialog(false);
    });
  }
  const orderReasonToolbar = () => {
    return (
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteOrderReasonCode(openDeleteDialogData.id)}
            selectedObject={`${openDeleteDialogData.nameAR} - ${openDeleteDialogData.nameEN}`}
          />
        )}
      </Box>
    );
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "nameEN",
      headerName: "English Reason",
      flex: 1,
    },
    {
      field: "nameAR",
      headerName: "Arabic Reason",
      flex: 1,
    },
    {
      field: "nameKU",
      headerName: "Kurdish Reason",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          showInMenu
          hidden={!editRole}
          onClick={(e) => {
            dispatch(getByIdForUpdate(params.row.id));
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          showInMenu
          hidden={!deleteRole}
          onClick={(e) => {
            setOpenDeleteDialog(true);
            setOpenDeleteDialogData(params.row);
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      {orderReasonToolbar()}
      <PerfectScrollbar>
        <Box sx={{ height: { md: "calc(100vh - 310px)", xs: 1000 } }}>
          <DataGrid
            sx={{
              "& .css-204u17-MuiDataGrid-main": {
                px: 3,
              },
              "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                px: 3,
              },
            }}
            columns={columns}
            rows={
              _OrderCancelReasons.data?.map((data, index) => {
                return { ...data, index: index + 1 };
              }) || []
            }
            loading={loading}
            disableSelectionOnClick
            rowHeight={50}
            pagination
            paginationMode="server"
            rowCount={_OrderCancelReasons?.rowCount}
            pageSize={10}
            page={_OrderCancelReason.saveOrderCancelPageNum - 1}
            onPageChange={(newPage) => handlePageChange(newPage)}
            // onPageSizeChange={(newPageSize) => handleLimitChange(newPageSize)}
            rowsPerPageOptions={[10]}
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
