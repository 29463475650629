import moment from "moment";
import {
  GET_ALL_GIFT_CART,
  OPEN_GIFT_CART_DIALOG,
  RELOAD_GIFT_CART,
  GET_BY_ID_GIFT_CART,
  RESET_FORM_GIFT_CART,
  GIFT_CART_SEARCH_BODY,
  GIFT_CART_PAGE_NUMBER,
} from "src/actions/gifts/giftTypes";

const initialState = {
  reload: false,
  openDialog: false,
  form: {
    name: "",
  },
  data: {},
  pageNo: 0,
  searchBody: {
    pageSize: 15,
    fromLastModifiedAt: moment().format("2024-01-01"),
  },
  usersData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
};

function giftCartReducer(giftCart = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_GIFT_CART:
      giftCart.usersData = payload;
      return giftCart;

    case OPEN_GIFT_CART_DIALOG:
      return {
        ...giftCart,
        openDialog: !giftCart.openDialog,
        attachmentUrl: payload,
      };

    case RELOAD_GIFT_CART:
      return { ...giftCart, reload: !giftCart.reload };

    case GET_BY_ID_GIFT_CART:
      giftCart.data = payload;
      return giftCart;

    case RESET_FORM_GIFT_CART:
      giftCart.form.name = "";
      return giftCart;

    case GIFT_CART_PAGE_NUMBER:
      return { ...giftCart, pageNo: payload };

    case GIFT_CART_SEARCH_BODY:
      return { ...giftCart, searchBody: payload };

    default:
      return giftCart;
  }
}

export default giftCartReducer;
