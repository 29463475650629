import {
  GET_ALL_GIFT_COLLECTIONS,
  GET_BY_ID_GIFT_COLLECTIONS,
  GET_GIFT_COLLECTIONS_LIST,
  OPEN_GIFT_COLLECTIONS_DIALOG,
  RELOAD_GIFT_COLLECTIONS,
  RESET_GIFT_COLLECTIONS_FORM,
  SAVE_GIFT_COLLECTIONS_PAGE_NO,
  SAVE_GIFT_COLLECTIONS_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";

const initialState = {
  reload: false,
  openDialog: false,
  collectionPageNo: 0,
  searchBody: {
    pageSize: 15,
    name: "",
    isForOrderiiNow: null,
  },
  form: {
    id: null,
    nameAr: "",
    nameEn: "",
    nameKu: "",
    isForOrderiiNow: true,
    giftItemsIds: [],
    newGiftItemsIds: null,
    sequence: "",
  },
  data: {
    data: [],
    pageCount: 0,
    rowCount: 0,
  },
  collectionList: [],
  getById: {},
};

function giftCollectionReducer(collection = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_GIFT_COLLECTIONS:
      collection.data = payload.data;
      collection.pageCount = payload.pageCount;
      collection.rowCount = payload.rowCount;
      return collection;

    case GET_GIFT_COLLECTIONS_LIST:
      collection.collectionList = payload;
      return collection;

    case GET_BY_ID_GIFT_COLLECTIONS:
      collection.form.id = payload.id;
      collection.form.nameAr = payload.nameAr;
      collection.form.nameEn = payload.nameEn;
      collection.form.nameKu = payload.nameKu;
      collection.form.giftItemsIds = payload.giftItems;
      collection.form.isForOrderiiNow = payload.isForOrderiiNow;
      collection.form.sequence = payload.sequence;
      return collection;

    case OPEN_GIFT_COLLECTIONS_DIALOG:
      return { ...collection, openDialog: !collection.openDialog };

    case SAVE_GIFT_COLLECTIONS_PAGE_NO:
      return { ...collection, collectionPageNo: payload };

    case RELOAD_GIFT_COLLECTIONS:
      return { ...collection, reload: !collection.reload };

    case SAVE_GIFT_COLLECTIONS_SEARCH_BODY:
      return { ...collection, searchBody: payload };

    case RESET_GIFT_COLLECTIONS_FORM:
      collection.form.id = null;
      collection.form.nameEn = "";
      collection.form.nameAr = "";
      collection.form.nameKu = "";
      collection.form.sequence = "";
      collection.form.isForOrderiiNow = true;
      collection.form.giftItemsIds = [];
      return collection;

    default:
      return collection;
  }
}

export default giftCollectionReducer;
