import { TextField, Grid, Stack, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  createCancelItemReasons,
  openDialog,
  resetForm,
  updateCancelItemReasons,
} from "src/actions/itemCancelReason";

const ItemCancelReasonsForm = () => {
  const dispatch = useDispatch();

  const _ItemCancelReason = useSelector((state) => state.itemCancelReason);
  const loading = useSelector((state) => state.itemCancelReason.reload);
  const orderCancelPageNO = useSelector(
    (state) => state.itemCancelReason.saveItemCancelReasonPageNum
  );
  const isAdd = _ItemCancelReason.form.id ? false : true;
  const { control, reset, handleSubmit } = useForm({
    defaultValues: _ItemCancelReason.form,
  });

  useEffect(() => {
    reset({ ..._ItemCancelReason.form });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_ItemCancelReason.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(createCancelItemReasons(data, orderCancelPageNO));
    } else {
      dispatch(updateCancelItemReasons(data, orderCancelPageNO));
    }
  };

  const handleDialog = () => {
    dispatch(openDialog());
  };

  return (
    <CustomDialog
      title={isAdd ? "Create Item Cancel Reason" : "Update Item Cancel Reason"}
      isOpen={_ItemCancelReason.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="nameEN"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="English Reason"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="nameAR"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Arabic Reason"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="nameKU"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Kurdish Reason"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
              >
                Save
              </LoadingButton>
              {isAdd && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => reset({ ..._ItemCancelReason.form })}
                >
                  Clear Text
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default ItemCancelReasonsForm;
