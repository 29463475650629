import ticketService from "src/api/services/ticket/ticket.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../snackbarMessages";
import {
  GET_ALL_TICKETS,
  SAVE_SEARCH_BODY_TICKETS,
  GET_TICKET_DETAILS,
  RELOAD_TICKET_DETAILS,
  OPEN_TICKET_DEALOG,
  REST_TICKET_DEALOG,
  RELOAD_TICKET_TABLE,
  UPDATE_TICKET_PRIORITY,
  UPDATE_TICKET_STATUS,
  UNASSIGN_TICKET,
  ASSIGN_TICKET,
  DELETE_TICKET_ATTACHMENT,
  SAVE_SEARCH_PAGE_NO,
  GET_TICKET_TIMLINE,
} from "../type";

export const getAllTickets = (pageNo, data) => async (dispatch) => {
  try {
    const res = await ticketService.getAllTickets(pageNo, data);
    dispatch({
      type: GET_ALL_TICKETS,
      payload: res.data.data,
    });
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else dispatch(showErrorSnackbar("Network Error"));
  }
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_BODY_TICKETS,
    payload: data,
  });
};

export const savePageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_PAGE_NO,
    payload: data,
  });
};

export const openDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_TICKET_DEALOG,
    payload: data,
  });
};

export const restCreatTicketDialog = (data) => (dispatch) => {
  dispatch({
    type: REST_TICKET_DEALOG,
    payload: data,
  });
};

export const getTicketById = (id) => async (dispatch) => {
  try {
    const res = await ticketService.getTicketById(id);
    dispatch({
      type: GET_TICKET_DETAILS,
      payload: res.data.data,
    });
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const reloadTicketDetails = (data) => (dispatch) => {
  dispatch({
    type: RELOAD_TICKET_DETAILS,
    payload: data,
  });
};

export const statusHistories = (id) => async (dispatch) => {
  try {
    const res = await ticketService.statusHistories(id);
    dispatch({
      type: GET_TICKET_TIMLINE,
      payload: res.data.data,
    });
    dispatch(reloadTicketDetails(true));
    // dispatch(getTicketById(id))
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const changeStatus = (data, ticketId) => async (dispatch) => {
  try {
    const res = await ticketService.changeStatus(data);
    dispatch(reloadTicketDetails(true));
    dispatch(getTicketById(ticketId));
    dispatch(showSuccessSnackbar("Ticket status changed successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const changePriority = (data, ticketId) => async (dispatch) => {
  try {
    const res = await ticketService.changePriority(data);
    // dispatch({
    //   type: UPDATE_TICKET_PRIORITY,
    //   payload: res.data.data,
    // });
    dispatch(reloadTicketDetails(true));
    dispatch(getTicketById(ticketId));
    dispatch(showSuccessSnackbar("Ticket Priority changed successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else dispatch(showErrorSnackbar("Network Error"));
  }
};

export const assignTicket = (data, ticketId) => async (dispatch) => {
  try {
    const res = await ticketService.assignTicket(data);
    dispatch({
      type: ASSIGN_TICKET,
      payload: res.data.data,
    });
    dispatch(getTicketById(ticketId));
    dispatch(showSuccessSnackbar("Ticket assingeed successfully"));
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else dispatch(showErrorSnackbar("Network Error"));
  }
};

export const unAssignTicket = (data, ticketId) => async (dispatch) => {
  try {
    const res = await ticketService.unAssignTicket(data);
    dispatch({
      type: UNASSIGN_TICKET,
      payload: res.data.data,
    });
    // dispatch(getTicketById(ticketId))
    dispatch(showSuccessSnackbar("Ticket unassingeed successfully"));
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else dispatch(showErrorSnackbar("Network Error"));
  }
};

// export const department = (data) => async (dispatch) => {
//   try {
//     const res = await ticketService.department(data);
//     return Promise.resolve(res.data);
//   } catch (err) {
//     if (err.message.includes(401)) helper.logout();
//     else if (err.response.status === 400)
//       dispatch(showErrorSnackbar(err.response.data.message));
//     else dispatch(showErrorSnackbar("Network Error"));
//   }
// };

export const deleteAttachment = (id, ticketId) => async (dispatch) => {
  try {
    const res = await ticketService.deleteAttachment(id);
    dispatch({
      type: DELETE_TICKET_ATTACHMENT,
      payload: res.data.data,
    });
    // dispatch(getTicketById(ticketId))
    dispatch(showSuccessSnackbar("Attachments deleted successfully"));
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else dispatch(showErrorSnackbar("Network Error"));
  }
};

// export const getPersonal = (data) => async (dispatch) => {
//   try {
//     const res = await ticketService.getPersonal(data);
//     return Promise.resolve(res.data);
//   } catch (err) {
//     if (err.message.includes(401)) helper.logout();
//     else if (err.response.status === 400)
//       dispatch(showErrorSnackbar(err.response.data.message));
//     else dispatch(showErrorSnackbar("Network Error"));
//   }
// };

export const createTickets = (data, filterData) => async (dispatch) => {
  try {
    const res = await ticketService.createTicket(data);
    dispatch(showSuccessSnackbar("Create successfully!"));
    dispatch(getAllTickets(1, filterData));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else dispatch(showErrorSnackbar("Network Error"));
  }
};

export const addAttachment = (data) => async (dispatch) => {
  try {
    const res = await ticketService.addAttachment(data);
    dispatch(showSuccessSnackbar("Upload successfully!"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else dispatch(showErrorSnackbar("Network Error"));
  }
};

export const updateDepartment = (data, ticketId) => async (dispatch) => {
  try {
    const res = await ticketService.department(data);
    dispatch(getTicketById(ticketId));
    dispatch(showSuccessSnackbar("Updated successfully!"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else dispatch(showErrorSnackbar("Network Error"));
  }
};
