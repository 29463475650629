import { TextField, Grid, Stack, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { openNoteDialog, updateNote } from "src/actions/externalItem";
import { useState } from "react";

const ExternalItemNote = () => {
  const _ExternalItem = useSelector((state) => state.externalItem);
  const _PageNO = useSelector((state) => state.externalItem.pageNo);
  const _SearchBody = useSelector((state) => state.externalItem.searchBody);

  const [loading, setLoading] = useState(false);

  const isAdd = _ExternalItem.note.id ? false : true;
  const { control, reset, handleSubmit } = useForm({
    defaultValues: _ExternalItem.note,
  });

  useEffect(() => {
    reset({ ..._ExternalItem.note });
  }, [_ExternalItem.note]);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      updateNote(
        {
          id: data.id,
          note: data.note,
        },
        _PageNO,
        _SearchBody
      )
    ).then((x) => setLoading(false));
  };

  const handleDialog = () => {
    dispatch(openNoteDialog({}));
  };

  return (
    <CustomDialog
      title={"NOTE"}
      isOpen={_ExternalItem.noteDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextField
                  multiline
                  rows={3}
                  size="small"
                  label="note"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
              >
                Save
              </LoadingButton>
              {isAdd && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => reset({ ..._ExternalItem.form })}
                >
                  Clear Text
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default ExternalItemNote;
