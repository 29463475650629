import {
  Grid,
  DialogActions,
  Box,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import {
  createCategoryBrands,
  openCategoryBrandsDialog,
  resetCategoryBrandsForm,
} from "src/actions/gifts/categoryBrands";
import SelectGiftBrand from "src/components/Selectors/SelectBrands";
import CustomEmpty from "src/components/CustomEmpty";

const BrandsCollectionForm = ({ selectedItems }) => {
  const dispatch = useDispatch();

  const _CategoryBrand = useSelector((state) => state.categoryBrands);
  const reload = useSelector((state) => state.categoryBrands.reload);

  const isAdd = _CategoryBrand.form.id ? false : true;
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: _CategoryBrand.form,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "brandsIds",
  });

  useEffect(() => {
    reset({ ..._CategoryBrand.form });
  }, [_CategoryBrand.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetCategoryBrandsForm());
    };
    // eslint-disable-next-line no-use-before-define
  }, [dispatch]);

  useEffect(() => {
    if (selectedItems && selectedItems.length > 0) {
      selectedItems.forEach((item) => {
        append({
          ...item,
          attachment: item.attachment[0],
        });
      });
    }
  }, [selectedItems, append]);

  const onSubmit = (data) => {
    dispatch(
      createCategoryBrands(
        {
          giftItemCategoryId: _CategoryBrand.selectedCategory,
          brandsIds: data.brandsIds.map((item) => item.id),
        },
        true
      )
    );
  };

  const handleDialog = () => {
    dispatch(openCategoryBrandsDialog(null));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog
        open={_CategoryBrand.openDialog}
        keepMounted
        onClose={() => {
          handleDialog();
        }}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          <Typography color={isAdd ? "primary" : "orange.main"}>
            {isAdd ? "Create Category Brands" : "Update Category Brands"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} py={2}>
            <Grid item xs={12}>
              <Controller
                name="selectedCategory"
                control={control}
                render={({ field }) => (
                  <SelectGiftBrand
                    fullWidth
                    onChange={(e, newVal) => {
                      append({
                        ...newVal,
                        id: newVal.id,
                        attachment: newVal.attachment,
                      });
                      setValue("selectedCategory", null);
                    }}
                    clearOnEscape
                    filterSelectedOptions={true}
                    value={null}
                    blurOnSelect={true}
                    getOptionDisabled={(option) => {
                      return watch("brandsIds")?.some(
                        (item) => item.id === option.id
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Operations</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields.length > 0 ? (
                      fields?.map((item, index) => (
                        <TableRow key={item.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.nameEn}</TableCell>
                          <TableCell>
                            <Box
                              component="img"
                              src={item.attachment?.url}
                              width={120}
                              height={120}
                              sx={{ objectFit: "contain" }}
                            />
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => {
                                remove(index);
                              }}
                            >
                              Remove
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Stack
                            display="flex"
                            width="100%"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CustomEmpty />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* {!isAdd ? (
            <Grid item xs={12}>
              <Controller
                name="isActive"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("isActive", e.target.checked, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    }
                    label="Is Active!"
                  />
                )}
              />
            </Grid>
          ) : null} */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            color="primary"
            loading={reload}
            disabled={!isDirty}
            sx={{ width: "25%", my: 2 }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default BrandsCollectionForm;
