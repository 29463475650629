import { Helmet } from 'react-helmet';
import { Box, Card, CardHeader, Grid } from '@mui/material';
import { Typography } from '@mui/material';
import AgentRequestsContent from '../components/pagesContent/agentRequests/AgentRequestsContent'
import { RiBookReadFill } from 'react-icons/ri';

const AgentRequests = () => (
  <>
    <Helmet>
      <title>Agent Requests | Orderii</title>
    </Helmet>
    <Box sx={{ minWidth: { md: '100%', xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <Card>
            <CardHeader
              title={
                <Typography variant="h4" component="div">
                  Agent Requests
                </Typography>
              }
              avatar={<RiBookReadFill size={20} />}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <Box>
            <AgentRequestsContent />
          </Box>
        </Grid>
      </Grid>
    </Box>
  </>
);

export default AgentRequests;