import {
  FAVORITE_PAGE_NUMBER,
  FAVORITE_RELOAD,
  FAVORITE_GET_ALL,
  FAVORITE_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  pageSize: 10,
  favoriteData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  searchBody: {
    pageSize: 15,
    name: "",
    phoneNumber: "",
  },
  getById: {},
};

function favoriteReducer(favorite = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FAVORITE_GET_ALL:
      favorite.favoriteData.data = payload.data;
      favorite.favoriteData.total = payload.pageCount;
      favorite.favoriteData.rowCount = payload.rowCount;
      return favorite;

    case FAVORITE_PAGE_NUMBER:
      return { ...favorite, pageNo: payload };

    case FAVORITE_SEARCH_BODY:
      return { ...favorite, searchBody: payload };

    case FAVORITE_RELOAD:
      return { ...favorite, reload: !favorite.reload };

    default:
      return favorite;
  }
}

export default favoriteReducer;
