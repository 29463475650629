import GiftService from "src/api/giftServices/category.service";
import helper from "src/utils/helper";
import uploadAttachmentsService from "src/api/services/uploadAttachments.service";
import {
  GET_ALL_GIFTS_CATEGORY,
  GET_BY_ID_GIFTS_CATEGORY,
  GET_GIFTS_CATEGORY_LIST,
  OPEN_GIFTS_CATEGORY_DIALOG,
  RELOAD_GIFTS_CATEGORY,
  RESET_GIFTS_CATEGORY_FORM,
  SAVE_GIFTS_CATEGORY_PAGE_NO,
  SAVE_GIFTS_CATEGORY_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";
import { UPLOAD_ATTACHMENTS } from "src/actions/type";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";

export const openGiftsCategoryDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_GIFTS_CATEGORY_DIALOG,
  });
};

export const createGiftCategory = (data, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadGiftsCategory());
    const res = await GiftService.createGiftsCategory(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(getAllGiftsCategory(searchBody));
    dispatch(openGiftsCategoryDialog());
    dispatch(reloadGiftsCategory());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadGiftsCategory());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteGiftsCategory = (id, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadGiftsCategory());
    const res = await GiftService.deleteGiftsCategory(id);
    dispatch(showSuccessSnackbar("Gift category deleted successfully!"));
    dispatch(getAllGiftsCategory(searchBody));
    dispatch(reloadGiftsCategory());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadGiftsCategory());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const reloadGiftsCategory = () => (dispatch) => {
  dispatch({
    type: RELOAD_GIFTS_CATEGORY,
  });
};

export const getGiftsCategoryById = (id, openDialog) => async (dispatch) => {
  try {
    dispatch(reloadGiftsCategory());
    const res = await GiftService.getGiftsCategoryById(id);
    dispatch({
      type: GET_BY_ID_GIFTS_CATEGORY,
      payload: res.data.data,
    });
    if (openDialog) dispatch(openGiftsCategoryDialog());
    dispatch(reloadGiftsCategory());
    // return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(reloadGiftsCategory());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllGiftsCategory = (searchBody) => async (dispatch) => {
  try {
    dispatch(reloadGiftsCategory());
    const res = await GiftService.getGiftsCategoryAll(searchBody);
    dispatch({
      type: GET_ALL_GIFTS_CATEGORY,
      payload: res.data,
    });
    dispatch(reloadGiftsCategory());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadGiftsCategory());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getGiftsCategoryList = () => async (dispatch) => {
  try {
    dispatch(reloadGiftsCategory());
    const res = await GiftService.getGiftsCategoryList();
    dispatch({
      type: GET_GIFTS_CATEGORY_LIST,
      payload: res.data.data,
    });
    dispatch(reloadGiftsCategory());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadGiftsCategory());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const saveGiftsCategoryPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_GIFTS_CATEGORY_PAGE_NO,
    payload: data,
  });
};

export const saveGiftsCategorySearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_GIFTS_CATEGORY_SEARCH_BODY,
    payload: data,
  });
};

export const resetGiftsCategoryForm = () => (dispatch) => {
  dispatch({
    type: RESET_GIFTS_CATEGORY_FORM,
  });
};

export const updateGiftsCategory =
  (id, data, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadGiftsCategory());
      const res = await GiftService.updateGiftsCategory(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllGiftsCategory(searchBody));
      dispatch(openGiftsCategoryDialog());
      dispatch(reloadGiftsCategory());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadGiftsCategory());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const uploadAttachments = (data) => async (dispatch) => {
  try {
    dispatch(reloadGiftsCategory());
    const res = await uploadAttachmentsService.uploadAttachments(data);
    dispatch(showSuccessSnackbar("Uploaded successfully"));
    dispatch({
      type: UPLOAD_ATTACHMENTS,
      data: res.data,
    });
    dispatch(reloadGiftsCategory());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadGiftsCategory());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
