/* eslint-disable radix */
import React, { useEffect, useRef } from "react";
import {
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Logo from "src/components/Logo";

const useStyles = makeStyles((theme) => ({
  mb: {
    marginBottom: "60px",
  },
  margin: {
    margin: "30px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "0px",
  },
  "@global": {
    "td, th": {
      padding: "3px",
      fontSize: "13px",
      color: "#3d3da4",
    },
  },
  tableHeader: {
    textAlign: "center",
    backgroundColor: "#03a9f4",
    color: "white",
  },
}));

export default function GenerateInvoice({ data = {} }) {
  const classes = useStyles();
  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: `@media print {
          @page {
            size: A4;
            margin: 10
          }
        }`,
  });

  useEffect(() => {
    handlePrint();
  }, []);

  return (
    <div>
      <Button
        onClick={() => {
          handlePrint();
        }}
        color="primary"
        variant="contained"
        fullWidth
      >
        print
      </Button>
      <div ref={printRef} style={{ padding: "15px" }}>
        <div>
          <Logo
            altSrc={
              "/static/images/logo/logo_orderii_dual_black.png"
            }
            style={{ width: "300px", float: "right" }}
          />
          <div style={{ float: "left" }}>
            <Typography variant="h5">
              Customer name: {data.customer ? data.customer.fullName : ""}
            </Typography>
            <Typography variant="h5">
              Phone number: {data.customer ? data.customer.phoneNumber : ""}
            </Typography>
            <Typography variant="h5">
              Address: {data.customer ? data.customer.address : ""}
            </Typography>
            <Typography variant="h5">
              Date: {moment(new Date()).format("yyyy-MM-DD, dddd")}
            </Typography>
            <Typography variant="h5">Invoice no: {data.number}</Typography>
          </div>
        </div>

        {data ? (
          <Table style={{ marginTop: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell>Item details</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Quantity</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Item photo</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell
                    sx={
                      item.cancelReasonId
                        ? { textDecoration: "line-through" }
                        : {}
                    }
                  >{`${item.name} - ${item.totalAmount}$`}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {item.quantity}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <img
                      src={item.imageUrl}
                      style={{ maxWidth: "200px", maxHeight: "150px" }}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Typography sx={{ color: "red" }}>
                      {item.cancelReasonId ? "CACNCELED" : ""}
                    </Typography>
                    <Typography>
                      {item.cancelReasonId ? item.cancelReasonEN : ""}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
        <Divider />
        <Box sx={{ margin: 1.5 }}>
          <Typography variant="h4">
            Total amount: {data.totalAmount ?? 0} $
          </Typography>
          <Typography variant="h5">
            USA shipping: {data.totalUSShipping ?? 0}$
          </Typography>
          <Typography variant="h5">
            TR shipping: {data.totalTRShipping ?? 0}$
          </Typography>
          <Typography variant="h5">
            IQ customs: {data.totalIQCustoms ?? 0}$
          </Typography>
          <Divider sx={{ marginTop: 3, marginBottom: 1 }} />
          <Typography variant="h5" color="primary.main">
            Collect delivery cost: {data.toBeDelivered ? data.deliveryCost : 0}$
          </Typography>
        </Box>
      </div>
    </div>
  );
}
