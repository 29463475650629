import {
  GET_ALL_SHIPPING_CONFIGS,
  SAVE_USERS_PAGE_SC,
  SAVE_SEARCH_BODY_SC,
  OPEN_SHIPPING_CONFIGS_DIALOG,
  GET_BY_ID_SHIPPING_CONFIGS,
  RESET_FORM_SHIPPING_CONFIGS,
  SHIPPINGS_CONFIGS_RELOAD,
} from "./type";

import ShippingConfigService from "../api/services/shippingConfig.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";

export const openShippingConfigsDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_SHIPPING_CONFIGS_DIALOG,
    payload: data,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: RESET_FORM_SHIPPING_CONFIGS,
  });
};

export const saveShippingConfigsSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_BODY_SC,
    payload: data,
  });
};

export const saveShippingConfigsPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_USERS_PAGE_SC,
    payload: data,
  });
};

export const reloadShippingConfigs = () => (dispatch) => {
  dispatch({
    type: SHIPPINGS_CONFIGS_RELOAD,
  });
};

export const createShippingConfig = (data, pagNo) => async (dispatch) => {
  try {
    const res = await ShippingConfigService.create(data);
    dispatch(showSuccessSnackbar("Created successfully!"));
    dispatch(getAllShippingConfigs());
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllShippingConfigs = () => async (dispatch) => {
  try {
    dispatch(reloadShippingConfigs());
    const res = await ShippingConfigService.getAll();
    dispatch({
      type: GET_ALL_SHIPPING_CONFIGS,
      payload: res.data.data,
    });
    dispatch(reloadShippingConfigs());
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateShippingConfig = (data, pagNo) => async (dispatch) => {
  try {
    const res = await ShippingConfigService.update(data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    dispatch(getAllShippingConfigs());
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteShippingConfig = (id, pagNo) => async (dispatch) => {
  try {
    await ShippingConfigService.delete(id);
    dispatch(showSuccessSnackbar("ShippingConfig Code deleted successfully"));
    dispatch(getAllShippingConfigs());
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadShippingConfigs());
    const res = await ShippingConfigService.getById(id);
    dispatch({
      type: GET_BY_ID_SHIPPING_CONFIGS,
      payload: res.data.data,
    });
    dispatch(reloadShippingConfigs());
    dispatch(openShippingConfigsDialog());
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
export const getByIdDelete = (id) => async (dispatch) => {
  try {
    dispatch(reloadShippingConfigs());
    const res = await ShippingConfigService.getById(id);
    dispatch({
      type: GET_BY_ID_SHIPPING_CONFIGS,
      payload: res.data.data,
    });
    dispatch(reloadShippingConfigs());
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
