import {
    CREATE_MOBILE_APP_VERSION,
    GET_ALL_MOBILE_APP_VERSIONS,
    UPDATE_MOBILE_APP_VERSION,
    DELETE_MOBILE_APP_VERSION,
  } from "../actions/type";
  
  const initialState = [];
  
  function mobileAppVersionReducer(mobileAppVersions = initialState, action) {
    const { type, payload } = action;

    switch (type) {
      case CREATE_MOBILE_APP_VERSION:
        mobileAppVersions.data.push(payload)
        mobileAppVersions.rowCount= mobileAppVersions.rowCount + 1
        mobileAppVersions.pageCount = parseInt((mobileAppVersions.rowCount / 10),10) + 1
        return mobileAppVersions;
  
      case GET_ALL_MOBILE_APP_VERSIONS:
        return payload;
  
      case UPDATE_MOBILE_APP_VERSION:
        var indexToChange = mobileAppVersions.data.indexOf(
          mobileAppVersions.data.filter((x) => x.id === payload.id)[0]
        );
        mobileAppVersions.data.splice(indexToChange, 1, payload);
        return mobileAppVersions;
  
      case DELETE_MOBILE_APP_VERSION:
        mobileAppVersions.data =mobileAppVersions.data.filter((mobileAppVersion) => mobileAppVersion.id !== payload.id)
        return mobileAppVersions
  
      default:
        return mobileAppVersions;
    }
  }

  export default mobileAppVersionReducer;