import {
  TextField,
  Grid,
  Stack,
  FormControlLabel,
  Switch,
  Tooltip,
  Button,
  Autocomplete,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  openDialog,
  resetForm,
  uploadAttachment,
  uploadAttachmentForUpdate,
} from "src/actions/banners";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useState } from "react";
import moment from "moment";
import { BANNERS_TYPES } from "src/utils/common";
import { getGiftsCategoryList } from "src/actions/gifts/giftCategory";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const BannersForm = () => {
  const dispatch = useDispatch();

  const _Banner = useSelector((state) => state.banners);
  const savePromoPageNO = useSelector(
    (state) => state.banners.savePromoPageNum
  );
  const _PageSize = useSelector((state) => state.banners.pageSize);
  const _SearchBody = useSelector((state) => state.banners.searchBody);
  const _GiftCategory = useSelector(
    (state) => state.giftItemCategory.giftItemCategoryList
  );
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const isAdd = _Banner.form.id ? false : true;
  const {
    control,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: _Banner.form,
  });

  useEffect(() => {
    dispatch(getGiftsCategoryList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    if (isAdd) {
      const formData = new FormData();
      formData.append("file", attachmentFiles[0]?.file);
      dispatch(
        uploadAttachment(formData, data, _SearchBody.type, savePromoPageNO, {
          ..._SearchBody,
          pageSize: _PageSize,
        })
      ).then(() => setLoading(false));
    } else {
      const formData = new FormData();
      formData.append("file", attachmentFiles[0]?.file);
      dispatch(
        uploadAttachmentForUpdate(
          attachmentFiles[0]?.file ? formData : false,
          data,
          _SearchBody.type,
          savePromoPageNO,
          { ..._SearchBody, pageSize: _PageSize },
          true
        )
      ).then(() => setLoading(false));
    }
  };

  const handleDialog = () => {
    dispatch(openDialog(null));
  };

  useEffect(() => {
    reset({ ..._Banner.form });
  }, [_Banner.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomDialog
      title={isAdd ? "CREATE BANNER" : "UPDATE BANNER"}
      isOpen={_Banner.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      Draggable={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disabled={loading}
                  options={BANNERS_TYPES || []}
                  getOptionLabel={(option) => option.value}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField required {...params} label="Type" />
                  )}
                  onChange={(event, value) => {
                    setValue("type", value ? value.key : 0, {
                      shouldDirty: true,
                    });
                    setValue("redirectUrl", "", {
                      shouldDirty: true,
                    });
                    setValue("giftItemCategoryId", null, {
                      shouldDirty: true,
                    });
                    setValue("giftItemId", "", {
                      shouldDirty: true,
                    });
                  }}
                  value={
                    BANNERS_TYPES?.filter((x) => x.key === field.value)[0] ??
                    null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="owner"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Owner"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="sequence"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Sequence"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="validUpTo"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    label="Expiration date"
                    value={field.value}
                    onChange={(newValue) => {
                      setValue(
                        "validUpTo",
                        moment(newValue).format("YYYY-MM-DD")
                      );
                    }}
                    {...field}
                    renderInput={(params) => (
                      <TextField
                        required
                        size="small"
                        {...params}
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "YYYY-MM-DD",
                        }}
                        {...field}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="titleEN"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Title English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="titleAR"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Title Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="titleKu"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  label="Title Kurdish"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="descriptionEN"
              control={control}
              render={({ field }) => (
                <TextField
                  multiline
                  rows={4}
                  size="small"
                  label="Description English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="descriptionAR"
              control={control}
              render={({ field }) => (
                <TextField
                  multiline
                  rows={4}
                  size="small"
                  label="Description Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="descriptionKu"
              control={control}
              render={({ field }) => (
                <TextField
                  multiline
                  rows={4}
                  size="small"
                  label="Description Kurdish"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          {watch("type") === 1 || watch("type") === 0 ? (
            <Grid item xs={12} md={12}>
              <Controller
                name="redirectUrl"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="Redirect URL"
                    disabled={
                      watch("giftItemCategoryId") || watch("giftItemId")
                        ? true
                        : false
                    }
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
          ) : null}
          {watch("type") === 2 || watch("type") === 0 ? (
            <Grid item xs={12}>
              <Controller
                name="giftItemCategoryId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={
                      watch("giftItemId") || watch("redirectUrl") ? true : false
                    }
                    options={_GiftCategory || []}
                    getOptionLabel={(option) => option.nameEn}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} label="Category" />
                    )}
                    onChange={(event, value) => {
                      setValue("giftItemCategoryId", value ? value.id : null, {
                        shouldDirty: true,
                      });
                    }}
                    value={
                      _GiftCategory?.filter((x) => x.id === field.value)[0] ??
                      null
                    }
                  />
                )}
              />
            </Grid>
          ) : null}
          {watch("type") === 2 || watch("type") === 0 ? (
            <Grid item xs={12}>
              <Controller
                name="giftItemId"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    disabled={
                      watch("giftItemCategoryId") || watch("redirectUrl")
                        ? true
                        : false
                    }
                    label="Gift Product Id"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
          ) : null}
          {/* <Grid item xs={12} md={6}>
            <Controller
              name="isTop"
              control={control}
              render={({ field }) => (
                <Tooltip title="FALSE | TRUE" aria-label="active_deactivate">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("isTop", e.target.checked);
                        }}
                        {...field}
                      />
                    }
                    label="Is Top !"
                  />
                </Tooltip>
              )}
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <Controller
              name="isForNewUser"
              control={control}
              render={({ field }) => (
                <Tooltip title="FALSE | TRUE" aria-label="active_deactivate">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("isForNewUser", e.target.checked);
                        }}
                        {...field}
                      />
                    }
                    label="Is For New User !"
                  />
                </Tooltip>
              )}
            />
          </Grid>
          {isAdd ? null : (
            <Grid item xs={12} md={12} display="flex" justifyContent="center">
              <img
                alt=""
                src={_Banner.attachmentUrl}
                style={{ maxHeight: "200px", maxWidth: "250px" }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Controller
              name="attachmentId"
              control={control}
              render={({ field }) => (
                <FilePond
                  file={attachmentFiles}
                  // onupdatefiles={setAttachmentFiles}
                  name="file"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  credits={false}
                  {...field}
                  onupdatefiles={(fileItems) => {
                    setAttachmentFiles(fileItems);
                    setValue("attachmentId", fileItems, {
                      shouldDirty: true,
                    });
                  }}
                  onremovefile={(fileItems) => {
                    setValue("attachmentId", null, {
                      shouldDirty: false,
                    });
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
              {isAdd && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => reset({ ..._Banner.form })}
                >
                  Clear Text
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default BannersForm;
