import { useEffect } from "react";
import { Button, Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { Controller, useForm } from "react-hook-form";
import {
  updateOnboarding,
  openDialog,
  resetForm,
} from "src/actions/onboarding";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useState } from "react";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize
);

const OnboardingForm = () => {
  const _Onboarding = useSelector((state) => state.onboarding);
  const searchFilter = useSelector((state) => state.onboarding.searchBody);
  const PageNo = useSelector((state) => state.onboarding.pageNo);

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: _Onboarding.form,
  });

  const [attachmentsFiles, setAttachmentsFiles] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (attachmentsFiles.length > 0) {
      setFiles(attachmentsFiles.map((files) => files.file));
      setValue("file", attachmentsFiles.map((files) => files.file)[0], {
        shouldDirty: true,
      });
    } else {
      setValue("file", watch("file"), {
        shouldDirty: true,
      });
    }
  }, [attachmentsFiles, setValue, watch]);

  useEffect(() => {
    reset({ ..._Onboarding.form });
  }, [_Onboarding.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("type", data.type === "Orderii" ? 9 : 10);
    dispatch(
      updateOnboarding(data.id, formData, PageNo + 1, searchFilter)
    ).then(() => {
      setFiles([]);
      setAttachmentsFiles([]);
    });
  };

  const handleDialog = () => {
    dispatch(openDialog());
  };

  return (
    <CustomDialog
      title={"Update Onboarding Images"}
      isOpen={_Onboarding.openDialog}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Controller
              name="file"
              control={control}
              render={({ field }) => (
                <FilePond
                  required={watch("file") === "" ? true : false}
                  files={attachmentsFiles}
                  onupdatefiles={setAttachmentsFiles}
                  maxFiles={1}
                  name="file"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  credits={false}
                  // imageValidateSizeMinWidth={100}
                  // imageValidateSizeMaxWidth={500}
                  // imageValidateSizeMinHeight={100}
                  // imageValidateSizeMaxHeight={500}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              component="img"
              src={_Onboarding.form?.url}
              width={300}
              style={{ objectFit: "contain" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isDirty}
            >
              {"Update"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default OnboardingForm;
