import { Helmet } from "react-helmet";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { MdCardTravel } from "react-icons/md";
import ExternalItemForm from "../../form/Form";

const ExternalItemHeader = () => {
  const _ExternalItem = useSelector((state) => state.externalItem.openDialog);

  return (
    <>
      <Helmet>
        <title>External Item | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                External Item
              </Typography>
            }
            avatar={<MdCardTravel size={20} />}
          />
          {_ExternalItem ? <ExternalItemForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default ExternalItemHeader;
