import axios from "../axios";

class ReportsService {
  newCustomerPerDay(data) {
    return axios.post(`/report/newcustomerperday`, data);
  }

  totalRevenuPromoVendor(id) {
    return axios.post(`report/totalrevenuepromovendor?promoCodeId=${id}`);
  }

  getUnsedPromo(id) {
    return axios.post(`report/getunusedpromo?promoCodeId=${id}`);
  }

  totalDiscountPerDay(data) {
    return axios.post(`report/totaldiscountperday`, data);
  }

  allInfo(data) {
    return axios.post(`report/allinfo`, data);
  }

  regionPromoVendorRevenue(data) {
    return axios.post(`report/regionpromovendorrevenue`, data);
  }

  cac(id) {
    return axios.post(`report/cac`, id);
  }

  getTopCustomersByOrderCount(filter) {
    let url = `/report/topcustomersbyordercount`;
    var filterLink = "";
    filterLink += filter.count ? `?count=${filter.count}` : "?";

    return axios.get(url + filterLink);
  }

  getTopCustomersByOrderValue(filter) {
    let url = `/report/topcustomersbyordervalue`;
    var filterLink = "";
    filterLink += filter.count ? `?count=${filter.count}` : "?";

    return axios.get(url + filterLink);
  }

  getTopTenVendorsByClickCount(filter) {
    let url = `/report/topvendorsbyclickcount`;
    var filterLink = "";
    filterLink += filter.region ? `?region=${filter.region}` : "?";
    filterLink += filter.count ? `&count=${filter.count}` : "";

    return axios.get(url + filterLink);
  }

  getTopTenVendorsByOrderCount(filter) {
    let url = `/report/topvendorsbyordercount`;
    var filterLink = "";
    filterLink += filter.region ? `?region=${filter.region}` : "?";
    filterLink += filter.count ? `&count=${filter.count}` : "";

    return axios.get(url + filterLink);
  }

  transactions(filter) {
    let url = `/report/transactions`;
    var filterLink = "";
    filterLink += filter.from ? `?from=${filter.from}` : "?";
    filterLink += filter.to ? `&to=${filter.to}` : "";

    return axios.get(url + filterLink);
  }

  userBalace() {
    return axios.get(`report/userbalances`);
  }

  topup(filter) {
    let url = `/report/topupreport`;
    var filterLink = "";
    filterLink += filter.from ? `?from=${filter.from}` : "?";
    filterLink += filter.to ? `&to=${filter.to}` : "";

    return axios.get(url + filterLink);
  }

  walletLiability() {
    return axios.get(`report/walletliabilityreport`);
  }

  income(filter) {
    let url = `/report/incomereport`;
    var filterLink = "";
    filterLink += filter.from ? `?from=${filter.from}` : "?";
    filterLink += filter.to ? `&to=${filter.to}` : "";
    filterLink += `&forCancelled=${filter.forCancelled}`;

    return axios.get(url + filterLink);
  }

  walletSnapshot(filter) {
    let url = `/report/walletsnapshot`;
    var filterLink = "";
    filterLink += filter.to ? `?to=${filter.to}` : "";

    return axios.get(url + filterLink);
  }

  agentTopUp(filter) {
    let url = `/report/agenttopupreport`;
    var filterLink = "";
    filterLink += filter.from ? `?from=${filter.from}` : "";
    filterLink += filter.to ? `&to=${filter.to}` : "";

    return axios.get(url + filterLink);
  }

  giftAllInfo(data) {
    return axios.post(`report/allgiftinfo`, data);
  }

  allStockInfo() {
    return axios.get(`report/allstockinfo`);
  }
}

export default new ReportsService();
