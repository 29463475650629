import { Grid, Box } from "@mui/material";
import React from "react";
import BusinessPromosTable from "./home/table";
import BusinessPromosHeader from "./home/header/header";

const BusinessPromos = () => {
  return (
    <Box sx={{ minWidth: { md: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12}>
          <BusinessPromosHeader />
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: 2 }}>
          <BusinessPromosTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessPromos;
