import TicketCommentService from "src/api/services/ticket/TicketComment.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../snackbarMessages";
import {
  CREATE_COMMENT,
  UPLOAD_ATTACHMENT,
  DELETE_COMMENT,
  GET_COMMENT_DEATAILS,
} from "../type";

export const getTicketCommentById = (id) => async (dispatch) => {
  try {
    const res = await TicketCommentService.getTicketCommentById(id);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getTicketComment = (ticketId) => async (dispatch) => {
  try {
    const res = await TicketCommentService.getTicketComment(ticketId);
    dispatch({
      type: GET_COMMENT_DEATAILS,
      payload: res.data.data,
    });
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const createTicketComment = (data, ticketId) => async (dispatch) => {
  try {
    const res = await TicketCommentService.createTicketComment(data);
    dispatch(showSuccessSnackbar("Comment Send successfully"));
    dispatch({
      type: CREATE_COMMENT,
      payload: res.data.data,
    });
    dispatch(getTicketComment(ticketId));
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const uploadAttachment = (data) => async (dispatch) => {
  try {
    const res = await TicketCommentService.uploadAttachment(data);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getTicketCommentAttachmentId = (id) => async (dispatch) => {
  try {
    const res = await TicketCommentService.getTicketCommentAttachmentId(id);
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteAttachment = (id, ticketId) => async (dispatch) => {
  try {
    const res = await TicketCommentService.deleteAttachment(id);
    dispatch(showSuccessSnackbar("Attachment Deleted successfully"));
    dispatch(getTicketComment(ticketId));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteTicketComment = (id, ticketId) => async (dispatch) => {
  try {
    const res = await TicketCommentService.deleteTicketComment(id);
    dispatch(showSuccessSnackbar("Comment Deleted successfully"));
    dispatch({
      type: DELETE_COMMENT,
      payload: id,
    });
    dispatch(getTicketComment(ticketId));
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
