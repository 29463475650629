import {
  CREATE_ITEM_REASONS,
  DELETE_ITEM_REASONS,
  GET_ALL_ITEM_REASONS,
  UPDATE_ITEM_REASONS,
  GET_ALL_ITEM_REASONS_LIST
} from "../actions/type";

const initialState = {
  data: [],
  dataList: []
};

function itemReasonReducer(itemReasons = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ITEM_REASONS:
      itemReasons.data.push(payload)
      itemReasons.rowCount = itemReasons.rowCount + 1
      itemReasons.pageCount = parseInt((itemReasons.rowCount / 10), 10) + 1
      return itemReasons;

    case GET_ALL_ITEM_REASONS:
      itemReasons.data = payload;
      return itemReasons

    case GET_ALL_ITEM_REASONS_LIST:
      itemReasons.dataList = payload;
      return itemReasons

    case UPDATE_ITEM_REASONS:
      var indexToChange = itemReasons.data.indexOf(
        itemReasons.data.filter((x) => x.id === payload.id)[0]
      );
      itemReasons.data.splice(indexToChange, 1, payload);
      return itemReasons;

    case DELETE_ITEM_REASONS:
      itemReasons.data = itemReasons.data.filter((itemReason) => itemReason.id !== payload)
      itemReasons.rowCount = itemReasons.rowCount - 1
      return itemReasons


    default:
      return itemReasons;
  }
}

export default itemReasonReducer;
