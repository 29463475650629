import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  TextField,
  InputAdornment,
  Chip,
  Typography,
  Autocomplete,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import {
  deleteSuggestedEvent,
  getAllEvents,
  getByIdEvents,
  resetEventForm,
  saveEventsPageNum,
  saveEventsSearchBody,
} from "src/actions/gifts/events";
import { AiOutlineSearch } from "react-icons/ai";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import DateRangepicker from "src/components/DateRangePicker";
import RolePermission from "src/components/RolePermission";
import { DeleteForever } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { CustomDialog } from "src/components/CustomDialog";

export default function EventsTable() {
  const dispatch = useDispatch();

  const _EventsForm = useSelector((state) => state.events);
  const _Events = useSelector((state) => state.events.eventsData);
  const loading = useSelector((state) => state.events.reload);
  const pageNo = useSelector((state) => state.events.pageNo);

  const [page, setPage] = useState(pageNo);
  const searchBody = useSelector((state) => state.events.searchBody);
  const [searchName, setSearchName] = useState(searchBody.name);
  const [phoneNumber, setPhoneNumber] = useState(searchBody.phoneNumber);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const status = [
    { name: "Upcoming", key: 0 },
    { name: "Completed", key: 1 },
  ];

  const isAnnually = [
    { name: "Annually", key: true },
    { name: "One Time", key: false },
  ];

  useEffect(() => {
    dispatch(getAllEvents(page + 1, { ...searchBody, isPublicEvent: true }));
  }, [dispatch, page, searchBody]);

  const handlePageChange = (newPage) => {
    dispatch(saveEventsPageNum(newPage));
    setPage(newPage);
  };

  function deleteSelectedGiftsBrand(id) {
    dispatch(
      deleteSuggestedEvent(id, page + 1, { ...searchBody, isPublicEvent: true })
    ).then((x) => {
      dispatch(resetEventForm());
      setOpenDeleteDialog(false);
    });
  }

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Name Arabic",
      flex: 1,
    },
    {
      field: "nameEn",
      headerName: "Name English",
      flex: 1,
    },
    {
      field: "note",
      headerName: "Note Arabic",
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={<h5>{params.row.note}</h5>}>
            <Typography variant="body" noWrap>
              {params.row.note}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "noteEn",
      headerName: "Note English",
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={<h5>{params.row.noteEn}</h5>}>
            <Typography variant="body" noWrap>
              {params.row.noteEn}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "isAnnually",
      headerName: "Is Annually",
      flex: 1,
      renderCell: (params) =>
        params.row.isAnnually ? (
          <Chip color="success" label={"Annually "} size="small" />
        ) : (
          <Chip color="secondary" label={"One Time"} size="small" />
        ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) =>
        params.row.status === "UpComing" ? (
          <Chip
            color="success"
            label={params.row.status?.split(/(?=[A-Z])/).join(" ")}
            size="small"
          />
        ) : (
          <Chip
            color="secondary"
            label={params.row.status?.split(/(?=[A-Z])/).join(" ")}
            size="small"
          />
        ),
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => moment(params.row.date).format("YYYY-MM-DD"),
    },
    {
      field: "Operations",
      headerName: "Operations",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <RolePermission permission="51-11">
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={(e) => {
                dispatch(getByIdEvents(params.row.id, true));
              }}
            >
              <Tooltip title="Edit" aria-label="edit">
                <EditIcon />
              </Tooltip>
            </IconButton>
          </RolePermission>
          <RolePermission permission="51-10">
            <IconButton
              color="error"
              aria-label="delete"
              onClick={(e) => {
                dispatch(getByIdEvents(params.row.id, false)).then((x) =>
                  setOpenDeleteDialog(true)
                );
              }}
            >
              <Tooltip title="Delete" aria-label="delete">
                <DeleteForever />
              </Tooltip>
            </IconButton>
          </RolePermission>
        </Box>
      ),
    },
  ];

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
              dispatch(resetEventForm());
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            maxWidth="sm"
            onDelete={() => deleteSelectedGiftsBrand(_EventsForm.form?.id)}
            selectedObject={`${_EventsForm.form?.name ?? ""}`}
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Phone Number"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value.replace(/^0+/, ""));
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveEventsSearchBody({
                      ...searchBody,
                      phoneNumber: phoneNumber,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveEventsSearchBody({
                      ...searchBody,
                      name: searchName,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              options={status}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Status" />}
              onChange={(event, value) => {
                dispatch(
                  saveEventsSearchBody({
                    ...searchBody,
                    status: value ? value.key : null,
                  })
                );
                setPage(0);
              }}
              value={
                status.filter((x) => x.key === searchBody.status)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              options={isAnnually}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Is Annually !" />
              )}
              onChange={(event, value) => {
                dispatch(
                  saveEventsSearchBody({
                    ...searchBody,
                    isAnnually: value ? value.key : null,
                  })
                );
                setPage(0);
              }}
              value={
                isAnnually.filter((x) => x.key === searchBody.isAnnually)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={searchBody.fromDate}
              endDate={searchBody.toDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  fromDate: moment(searchDate[0].startDate).format(
                    "YYYY-MM-DD"
                  ),
                  toDate: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.fromDate !== searchBody.fromDate ||
                  updatedFilter.toDate !== searchBody.toDate
                ) {
                  dispatch(
                    saveEventsSearchBody({
                      ...searchBody,
                      fromDate: moment(searchDate[0].startDate).format(
                        "YYYY-MM-DD"
                      ),
                      toDate: moment(searchDate[0].endDate).format(
                        "YYYY-MM-DD"
                      ),
                    })
                  );
                }
                setPage(0);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Clear filter">
              <IconButton
                onClick={() => {
                  setSearchName("");
                  setPhoneNumber("");
                  dispatch(
                    saveEventsSearchBody({
                      fromDate: moment().format("2024-01-01"),
                      name: "",
                      phoneNumber: "",
                      cityId: null,
                      provinceId: null,
                      pageSize: 15,
                    })
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box
                sx={{
                  height: { md: "calc(100vh - 410px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  columns={columns}
                  rows={
                    _Events?.data.map((data, index) => {
                      return { ...data, index: index + 1 };
                    }) || []
                  }
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  rowCount={_Events?.rowCount}
                  pageSize={10}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  rowsPerPageOptions={[10]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
