import { useEffect } from "react";
import { Button, TextField, Grid, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { Controller, useForm } from "react-hook-form";
import {
  createPromotedItems,
  openDialog,
  resetForm,
  updatePromotedItems,
} from "src/actions/promotedItems";

const PromotedItemsForm = () => {
  const _PromotedItems = useSelector((state) => state.promotedItems);
  const searchFilter = useSelector((state) => state.promotedItems.searchBody);
  const vendors = useSelector((state) => state.vendors.dataList);
  const PageNo = useSelector((state) => state.promotedItems.pageNo);

  const isAdd = _PromotedItems.form.id ? false : true;
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: _PromotedItems.form,
  });

  useEffect(() => {
    reset({ ..._PromotedItems.form });
  }, [_PromotedItems.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, []);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        createPromotedItems(
          {
            url: data.url,
            price: data.price,
            name: data.name,
            vendorId: data.vendorId,
            imageUrl: data.imageUrl,
          },
          PageNo + 1,
          searchFilter
        )
      );
    } else {
      dispatch(
        updatePromotedItems(
          {
            id: data.id,
            url: data.url,
            price: data.price,
            name: data.name,
            vendorId: data.vendorId,
            imageUrl: data.imageUrl,
          },
          PageNo + 1,
          searchFilter
        )
      );
    }
  };

  const handleDialog = () => {
    dispatch(openDialog());
  };

  return (
    <CustomDialog
      title={isAdd ? "Add promoted item" : "Update promoted item"}
      isOpen={_PromotedItems.openDialog}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Controller
              name="vendorId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={vendors || []}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Vendors" />
                  )}
                  onChange={(event, value) => {
                    setValue("vendorId", value ? value.id : null, {
                      shouldDirty: true,
                    });
                  }}
                  value={
                    vendors?.filter((x) => x.id === field.value)[0] ?? null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="url"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Item URL"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Price ($)"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="imageUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Image URL"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isDirty}
            >
              {isAdd ? "Create" : "Update"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default PromotedItemsForm;
