import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import RolePermission from "src/components/RolePermission";
import FAQForm from "../../form/Form";
import { openDialog } from "src/actions/faq";
import { IoIosStarHalf } from "react-icons/io";
import { FaRegQuestionCircle } from "react-icons/fa";

const FAQHeader = () => {
  const dispatch = useDispatch();

  const openFAQ = useSelector((state) => state.faq.openDialog);

  return (
    <>
      <Helmet>
        <title>FAQ | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                FAQ
              </Typography>
            }
            avatar={<FaRegQuestionCircle size={20} />}
            action={
              <RolePermission permission="50-01">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => dispatch(openDialog())}
                >
                  Create
                </Button>
              </RolePermission>
            }
          />
          {openFAQ ? <FAQForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default FAQHeader;
