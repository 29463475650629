import Lottie from 'react-lottie'
import animationData from '../lotties/empty'

export default function CustomEmpty() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
      };
    
    return (
      <div>
        <Lottie
          options={defaultOptions}
          height={400}
          width={400}
        />
      </div>
    );
  }