import {
  Box,
  Card,
  CardHeader,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  getTopCustomersByOrderCount,
  getTopCustomersByOrderValue,
} from "src/actions/reports";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import CustomSpinner from "src/components/CustomSpinner";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";


const TopCustomers = (props) => {
  const dispatch = new useDispatch();
  let navigate = useNavigate();

  const [customerData, setCustomerData] = useState([]);
  const [value, setValue] = useState(0);
  const [customerDataValue, setCustomerDataValue] = useState([]);
  const [customerDataCount, setCustomerDataCount] = useState([]);
  const [reloadValue, setReloadValue] = useState(0);
  const [reloadCount, setReloadCount] = useState(0);

  useEffect(() => {
    gettoporderValue();
    gettopordercount();
  }, [props.setSearch]);

  function gettopordercount() {
    setReloadCount(true);
    dispatch(getTopCustomersByOrderCount({ count: 10 })).then((res) => {
      if (res.data) setCustomerDataCount(res.data);
      setReloadCount(false);
    }).catch((err) => { })
  }

  function gettoporderValue() {
    setReloadValue(true);
    dispatch(getTopCustomersByOrderValue({ count: 10 })).then((res) => {
      if (res.data) setCustomerDataValue(res.data);
      setReloadValue(false);
    }).catch((err) => { })
  }

  function CustomExport() {
    return (
      <Box>
        <Box>
          <Box>
          </Box>
        </Box>
        <Box>
          <GridToolbarContainer>
            <GridToolbarExport
              csvOptions={{
                fileName: "Top Customers || Orderii",
                utf8WithBom: true,
              }}
            />
          </GridToolbarContainer>
        </Box>
      </Box>
    );
  }
  const columns = [
    { field: "#", headerName: "#", flex: 0.3, },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          placement="top"
          arrow
          title={params.value}
        >
          <a
            onClick={(e) => {
              window.open(`/app/user_orders/${params.row.ids}`, '_blank')
              // navigate(`/app/user_orders/${params.row.ids}`,);
            }}
            target="_blank"
            style={{ cursor: "pointer", color: "black" }}
          >
            <Typography noWrap>
              {params.value}
            </Typography>
          </a>
        </Tooltip>
      ),
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
    },
    {
      field: "totalOrders",
      headerName: "Total Orders",
      flex: 1,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      flex: 1,
    },
  ]
  return (
    <Box>
      <PerfectScrollbar>
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12} xl={6} xs={12}>
              {reloadValue ? (
                <CustomSpinner />
              ) : (
                <Box sx={{ height: 'calc(100vh - 320px)' }}>
                  <Card sx={{ margin: "5px 0" }}>
                    <CardHeader title="Top Customers By Order Value" />
                  </Card>
                  <Box sx={{ height: 'calc(100vh - 380px)' }}>
                    <DataGrid
                      sx={{
                        "& .MuiDataGrid-row ": {
                          textAlign: "center",
                        },
                        "& .css-204u17-MuiDataGrid-main": {
                          paddingLeft: "15px",
                        },
                        "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                          paddingLeft: "15px",
                        },

                      }}
                      disableSelectionOnClick={true}
                      columns={columns}
                      rows={customerDataValue.length > 0 && customerDataValue.map((x, index) => {
                        return {
                          ids: x.customerId,
                          id: index,
                          "#": index + 1,
                          customerName: x.customerName,
                          phone: `0${x.phone}`,
                          totalOrders: x.totalOrders,
                          totalAmount: `${x.totalAmount} $`,
                        };
                      })}
                      components={{
                        Toolbar: CustomExport,
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item lg={6} md={12} xl={6} xs={12}>
              {reloadCount ? (
                <CustomSpinner />
              ) : (
                <Box sx={{ height: 'calc(100vh - 320px)' }}>
                  <Card sx={{ margin: "5px 0" }}>
                    <CardHeader title="Top Customers By Order Counts" />
                  </Card>
                  <Box sx={{ height: 'calc(100vh - 380px)' }}>
                    <DataGrid
                      sx={{
                        "& .MuiDataGrid-row ": {
                          textAlign: "center",
                        },
                        "& .css-204u17-MuiDataGrid-main": {
                          paddingLeft: "15px",
                        },
                        "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                          paddingLeft: "15px",
                        },

                      }}
                      disableSelectionOnClick={true}
                      columns={[
                        { field: "#", headerName: "#", flex: 0.3, },
                        {
                          field: "customerName",
                          headerName: "Customer Name",
                          flex: 1,
                          renderCell: (params) => (
                            <Tooltip
                              placement="top"
                              arrow
                              title={params.value}
                            >
                              <a
                                onClick={(e) => {
                                  window.open(`/app/user_orders/${params.row.ids}`, '_blank')
                                  // navigate(`/app/user_orders/${params.row.ids}`);
                                }}
                                target="_blank"
                                style={{ cursor: "pointer", color: "black" }}
                              >
                                <Typography noWrap>
                                  {params.value}
                                </Typography>
                              </a>
                            </Tooltip>
                          ),
                        },
                        {
                          field: "phone",
                          headerName: "Phone",
                          flex: 1,
                        },
                        {
                          field: "totalOrders",
                          headerName: "Total Orders",
                          flex: 1,
                        },
                        {
                          field: "totalAmount",
                          headerName: "Total Amount",
                          flex: 1,
                        },
                      ]}
                      rows={customerDataCount.length > 0 && customerDataCount.map((x, index) => {
                        return {
                          ids: x.customerId,
                          id: index,
                          "#": index + 1,
                          customerName: x.customerName,
                          phone: `0${x.phone}`,
                          totalOrders: x.totalOrders,
                          totalAmount: `${x.totalAmount} $`,
                        };
                      })}
                      components={{
                        Toolbar: CustomExport,
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

export default TopCustomers;
