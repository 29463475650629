import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
  colors,
  useTheme,
} from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Icon, IconButton, Stack } from "@mui/material";
import { Pie } from "react-chartjs-2";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateRangepicker from "src/components/DateRangePicker";
import { useEffect } from "react";
import { getAllAddresses } from "src/actions/addresses";
import { getOrdersPerProvince } from "src/actions/gifts/dashboard";
import CustomSpinner from "src/components/CustomSpinner";
// import * as XLSX from "xlsx/xlsx.mjs";

ChartJS.register(ArcElement, Tooltip, Legend);

const OrdersPerProvince = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const ordersPerProvinceData = useSelector(
    (state) => state.giftDashboard.OrdersPerProvince
  );

  const addresses = useSelector((state) => state.addresses);
  // const _UserInfo = useSelector((state) => state.users.userInfo);

  const [viewCalendar, setViewCalendar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState({
    nameAR: "",
    nameUS: "",
    cities: [],
  });
  const [dateRange, setDateRange] = useState({
    startDate: moment().format("YYYY-MM-01"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  // const downloadXLSX = async () => {
  //   const ordersPerProvinceHeaders = {
  //     count: "Count",
  //     province: "Province Name",
  //   };
  //   const ordersPerProvinceHeadersHandler = Object.values(
  //     ordersPerProvinceHeaders
  //   );
  //   const ordersPerProvinceBook = XLSX.utils.book_new();
  //   const ordersPerProvinceSheet = XLSX.utils.aoa_to_sheet([
  //     ordersPerProvinceHeadersHandler,
  //     ...ordersPerProvinceData.data?.map((item) =>
  //       Object.keys(ordersPerProvinceHeaders).map((key) => item[key])
  //     ),
  //   ]);
  //   XLSX.utils.book_append_sheet(
  //     ordersPerProvinceBook,
  //     ordersPerProvinceSheet,
  //     "Sheet1"
  //   );
  //   XLSX.writeFile(
  //     ordersPerProvinceBook,
  //     `Orders Per Province - ${dateRange.startDate} - ${dateRange.endDate}.xlsx`
  //   );
  // };

  const color = ordersPerProvinceData.province?.map((province) =>
    province === "Baghdad"
      ? "#42a5f5"
      : province === "Basrah"
      ? "#d32f2f"
      : province === "Muthanna"
      ? "#66bb6a"
      : province === "Maysan"
      ? "#d32f2f"
      : province === "Nineveh"
      ? "#01579b"
      : province === "Saladin"
      ? "#0288d1"
      : province === "Erbil"
      ? "#9C27B0"
      : province === "Qadisiyyah"
      ? "#ffa726"
      : province === "Wasit"
      ? "#7c4dff"
      : province === "Karbala"
      ? "#ce93d8"
      : province === "Dhi Qar"
      ? "#1b5e20"
      : province === "Najaf"
      ? "#2e7d32"
      : province === "Al Anbar"
      ? "#81c784"
      : province === "Dohuk"
      ? "#81c784"
      : province === "Babil"
      ? "#f57c00"
      : province === "Sulaymaniyah"
      ? "#009688"
      : province === "Diyala"
      ? "#009688"
      : null
  );

  const data = {
    datasets: [
      {
        data: ordersPerProvinceData.ordersCount,
        backgroundColor: color,
        borderWidth: 2,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: ordersPerProvinceData.province,
  };

  const options = {
    animation: true,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      getOrdersPerProvince({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        provinceId: selectedProvince.id,
      })
    ).then(() => {
      setLoading(false);
    });
  }, [dateRange.endDate, dateRange.startDate, dispatch, selectedProvince.id]);

  useEffect(() => {
    dispatch(getAllAddresses());
  }, [dispatch]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title="Orders per province"
        subheader={
          <Stack direction="column" spacing={1}>
            <Typography variant="caption">
              {dateRange.startDate + " - " + dateRange.endDate}
            </Typography>
            {viewCalendar && (
              <Box sx={{ width: { md: "70%", xs: "100%" } }}>
                <Stack direction="row" spacing={2}>
                  <DateRangepicker
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    handleOnChange={(searchDate) => {
                      const updatedFilter = {
                        from: moment(searchDate[0].startDate).format(
                          "YYYY-MM-DD"
                        ),
                        to: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
                      };
                      if (
                        updatedFilter.from !== dateRange.startDate ||
                        updatedFilter.to !== dateRange.endDate
                      ) {
                        setDateRange({
                          startDate: moment(searchDate[0].startDate).format(
                            "YYYY-MM-DD"
                          ),
                          endDate: moment(searchDate[0].endDate).format(
                            "YYYY-MM-DD"
                          ),
                        });
                      }
                    }}
                  />
                  <Autocomplete
                    options={addresses}
                    getOptionLabel={(option) =>
                      option.nameAR
                        ? `${option.nameAR} , ${option.nameUS}`
                        : "Not selected"
                    }
                    sx={{ width: "50%" }}
                    size="small"
                    renderInput={(params) => (
                      <TextField required {...params} label="Provinces" />
                    )}
                    onChange={(event, value) => {
                      setSelectedProvince(value ? value : "");
                    }}
                    value={
                      addresses.filter(
                        (x) => x.id === selectedProvince.id
                      )[0] ?? null
                    }
                  />
                </Stack>
              </Box>
            )}
          </Stack>
        }
        action={
          <Stack
            direction="row"
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <IconButton onClick={() => setViewCalendar(!viewCalendar)}>
              <Icon aria-label="settings">calendar_month</Icon>
            </IconButton>
            {/* {_UserInfo.roles.includes("Export") && (
              <IconButton
                onClick={() => {
                  downloadXLSX();
                }}
              >
                <BiExport />
              </IconButton>
            )} */}
          </Stack>
        }
      />
      <Divider />
      {loading ? (
        <Box
          height={415}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CustomSpinner />
        </Box>
      ) : (
        <CardContent>
          <Box
            sx={{
              height: 300,
              position: "relative",
            }}
          >
            <Pie data={data} options={options} />
          </Box>
        </CardContent>
      )}
    </Card>
  );
};

export default OrdersPerProvince;
