import axiosService from "../../axiosService";

class TicketCommentService {
  createTicketComment(data) {
    return axiosService.post(`/ticket-service/api/ticketcomment`, data);
  }
  
  getTicketCommentById(id) {
    return axiosService.get(`/ticket-service/api/ticketcomment/byid/${id}`);
  }

  getTicketComment(ticketId) {
    return axiosService.get(`/ticket-service/api/ticketcomment/${ticketId}`);
  }

  uploadAttachment(data) {
    return axiosService.post(`/ticket-service/api/ticketcomment/attachment`, data);
  }

  getTicketCommentAttachmentId(id) {
    return axiosService.get(`/ticket-service/api/ticketcomment/attachment/${id}`);
  }

  deleteAttachment(id) {
    return axiosService.delete(`/ticket-service/api/ticketcomment/attachment/${id}`);
  }

  deleteTicketComment(id) {
    return axiosService.delete(`/ticket-service/api/ticketcomment/${id}`);
  }
  
}
 export default new TicketCommentService()