import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Typography,
  colors,
  useTheme,
} from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Icon, IconButton, Stack } from "@mui/material";
import { Pie } from "react-chartjs-2";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateRangepicker from "src/components/DateRangePicker";
import { useEffect } from "react";
import { getAllAddresses } from "src/actions/addresses";
import * as XLSX from "xlsx/xlsx.mjs";
import { BiExport } from "react-icons/bi";

ChartJS.register(ArcElement, Tooltip, Legend);

const NewUsersPerProvince = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const addresses = useSelector((state) => state.addresses);
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [viewCalendar, setViewCalendar] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState({
    nameAR: "",
    nameUS: "",
    cities: [],
  });

  const downloadXLSX = async () => {
    const newUsersPerProvinceHeaders = {
      count: "Count",
      provinceName: "Province Name",
    };
    const newUsersPerProvinceHeadersHandler = Object.values(
      newUsersPerProvinceHeaders
    );
    const ordersPerProvinceBook = XLSX.utils.book_new();
    const ordersPerProvinceSheet = XLSX.utils.aoa_to_sheet([
      newUsersPerProvinceHeadersHandler,
      ...props.data.data?.map((item) =>
        Object.keys(newUsersPerProvinceHeaders).map((key) => item[key])
      ),
    ]);
    XLSX.utils.book_append_sheet(
      ordersPerProvinceBook,
      ordersPerProvinceSheet,
      "Sheet1"
    );
    XLSX.writeFile(
      ordersPerProvinceBook,
      `New Users Per Province - ${props.data.fromDate} - ${props.data.toDate}.xlsx`
    );
  };

  const color = props.data.provinceName?.map((provinceName) =>
    provinceName === "Baghdad"
      ? "#42a5f5"
      : provinceName === "Basrah"
      ? "#d32f2f"
      : provinceName === "Muthanna"
      ? "#66bb6a"
      : provinceName === "Maysan"
      ? "#d32f2f"
      : provinceName === "Nineveh"
      ? "#01579b"
      : provinceName === "Saladin"
      ? "#0288d1"
      : provinceName === "Erbil"
      ? "#9C27B0"
      : provinceName === "Qadisiyyah"
      ? "#ffa726"
      : provinceName === "Wasit"
      ? "#7c4dff"
      : provinceName === "Karbala"
      ? "#ce93d8"
      : provinceName === "Dhi Qar"
      ? "#1b5e20"
      : provinceName === "Najaf"
      ? "#2e7d32"
      : provinceName === "Al Anbar"
      ? "#81c784"
      : provinceName === "Dohuk"
      ? "#81c784"
      : provinceName === "Babil"
      ? "#f57c00"
      : provinceName === "Sulaymaniyah"
      ? "#009688"
      : provinceName === "Diyala"
      ? "#009688"
      : null
  );

  const data = {
    datasets: [
      {
        data: props.data.counts,
        backgroundColor: color,
        borderWidth: 2,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: props.data.provinceName,
  };

  const options = {
    animation: true,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  useEffect(() => {
    dispatch(getAllAddresses());
  }, []);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title="New users per province"
        subheader={
          <Stack direction="column" spacing={1}>
            <Typography variant="caption">
              {props.data.fromDate + " - " + props.data.toDate}
            </Typography>
            {viewCalendar && (
              <Box sx={{ width: { md: "70%", xs: "100%" } }}>
                <Stack direction="row" spacing={2}>
                  <DateRangepicker
                    startDate={props.data.fromDate}
                    endDate={props.data.toDate}
                    handleOnChange={(searchDate) => {
                      const updatedFilter = {
                        from: moment(searchDate[0].startDate).format(
                          "YYYY-MM-DD"
                        ),
                        to: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
                      };
                      if (
                        updatedFilter.from !== props.data.fromDate ||
                        updatedFilter.to !== props.data.toDate
                      ) {
                        props.onDateChanged({
                          from: moment(searchDate[0].startDate).format(
                            "YYYY-MM-DD"
                          ),
                          to: moment(searchDate[0].endDate).format(
                            "YYYY-MM-DD"
                          ),
                          provinceId: selectedProvince.id ?? "",
                        });
                      }
                    }}
                  />
                  <Autocomplete
                    options={addresses}
                    getOptionLabel={(option) =>
                      option.nameAR
                        ? `${option.nameAR} , ${option.nameUS}`
                        : "Not selected"
                    }
                    sx={{ width: "50%" }}
                    size="small"
                    renderInput={(params) => (
                      <TextField required {...params} label="Provinces" />
                    )}
                    onChange={(event, value) => {
                      setSelectedProvince(value ? value : "");
                      props.onDateChanged({
                        from: props.data.fromDate,
                        to: props.data.toDate,
                        provinceId: value ? value.id : "",
                      });
                    }}
                    value={
                      addresses.filter((x) => x.id == selectedProvince.id)[0] ??
                      null
                    }
                  />
                </Stack>
              </Box>
            )}
          </Stack>
        }
        action={
          <Stack
            direction="row"
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <IconButton onClick={() => setViewCalendar(!viewCalendar)}>
              <Icon aria-label="settings">calendar_month</Icon>
            </IconButton>
            {_UserInfo.roles.includes("Export") && (
              <IconButton
                onClick={() => {
                  downloadXLSX();
                }}
              >
                <BiExport />
              </IconButton>
            )}
          </Stack>
        }
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: "relative",
          }}
        >
          <Pie data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default NewUsersPerProvince;
