import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import DynamicAuthForm from "../../form/Form";
import { openDialog } from "src/actions/dynamicAuth";
import { FaUsersCog } from "react-icons/fa";

const DynamicAuthHeader = () => {
  const dispatch = useDispatch();

  const _DynamicAuth = useSelector((state) => state.dynamicAuth);

  return (
    <>
      <Helmet>
        <title>Permissions | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Permissions
              </Typography>
            }
            avatar={<FaUsersCog size={20} />}
            action={
              <Button
                variant="contained"
                color="primary"
                size="medium"
                startIcon={<AddIcon />}
                onClick={() => dispatch(openDialog())}
              >
                ADD
              </Button>
            }
          />
          {_DynamicAuth ? <DynamicAuthForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default DynamicAuthHeader;
