import axios from "../axios";

class OnboardingService {
  getAll() {
    return axios.get(`/onboarding/`);
  }

  getById(id) {
    return axios.get(`/onboarding/getbyid/${id}`);
  }

  update(id, data) {
    return axios.put(`/onboarding/${id}`, data);
  }
}

export default new OnboardingService();
