import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Slide,
  Stack,
  Tooltip,
  Autocomplete,
  Typography,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  createTickets,
  openDialog,
  restCreatTicketDialog,
  addAttachment,
} from "src/actions/ticket/ticket";
import { getCategory } from "src/actions/ticket/category";
import { getDepartment } from "src/actions/department/department";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TicketForm = () => {
  const [attachmentfiles, setAttachmentfiles] = useState([]);
  const dispatch = useDispatch();

  const CreateTicket = useSelector((state) => state.tickets);
  const searchBody = useSelector((state) => state.tickets.searchBody);
  const ticketCategoryData = useSelector(
    (state) => state.ticketCategory.dataList
  );

  const departments = useSelector((state) => state.department);

  const [attachmentdata, setAttachmentData] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const [data, setData] = useState({
    description: "",
    attachmentIds: [],
    departmentId: 0,
    categoryId: 0,
    internal: false,
  });

  const clearTexts = () => {
    setData({
      description: "",
      attachmentIds: [],
      departmentId: 0,
      categoryId: 0,
    });
    setFiles([]);
    setAttachmentfiles([]);
  };

  useEffect(() => {
    dispatch(getCategory());
    dispatch(getDepartment());
  }, []);

  useEffect(
    () => (attachmentdata ? (onSubmit(), setIsloading(false)) : null),
    [attachmentdata]
  );

  useEffect(() => {
    return () => {
      dispatch(restCreatTicketDialog(CreateTicket.createTickets));
    };
  }, []);

  const handlDialog = () => {
    dispatch(openDialog(CreateTicket.openDialog));
  };

  const onSubmit = () => {
    dispatch(createTickets(data, searchBody))
      .then((x) => {
        setFiles([]);
      })
      .catch((err) => {});
  };

  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(attachmentfiles.map((files) => files.file));
  }, [attachmentfiles]);

  const sendFiles = async () => {
    setIsloading(true);
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append("file", files[i]);
      try {
        const res = await dispatch(addAttachment(formData));
        setData((prev) => {
          return {
            ...prev,
            attachmentIds: [...prev.attachmentIds, res.data.id],
          };
        });
      } catch (err) {
        // handle error
      }
    }
    return setAttachmentData(!attachmentdata);
  };

  return (
    <CustomDialog
      isOpen={CreateTicket.openDialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handlDialog();
      }}
      onClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      title="Create Ticket"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
            <Typography>Office Ticket!</Typography>
            <Tooltip title="(Deactive) | (Active)" aria-label="active_deactive">
              <Switch
                checked={data.internal}
                onChange={(e) =>
                  setData({ ...data, internal: e.target.checked })
                }
              />
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          {ticketCategoryData ? (
            <Autocomplete
              options={ticketCategoryData}
              size="small"
              // key={ticketCategoryData.id}
              getOptionLabel={(option) => `${option.nameAr} , ${option.nameEn}`}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
              onChange={(event, value) => {
                setData({ ...data, categoryId: value ? value.id : null });
              }}
              value={
                ticketCategoryData.filter((x) => x.id == data.categoryId)[0]
                  ? ticketCategoryData.filter((x) => x.id == data.categoryId)[0]
                  : null
              }
            />
          ) : null}
        </Grid>
        <Grid item xs={6}>
          {departments ? (
            <Autocomplete
              options={departments.data}
              size="small"
              key={ticketCategoryData.id}
              getOptionLabel={(option) => (option ? option.name : "")}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Departments" />
              )}
              onChange={(event, value) => {
                setData({ ...data, departmentId: value ? value.id : null });
              }}
              value={
                departments.data.filter((x) => x.id == data.departmentId)[0]
                  ? departments.data.filter((x) => x.id == data.departmentId)[0]
                  : null
              }
            />
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            rows="7"
            required
            size="small"
            label="Description"
            fullWidth
            onChange={(e) => {
              setData({ ...data, description: e.target.value });
            }}
            value={data.description}
          />
        </Grid>
        <Grid item xs={12}>
          <div>
            <FilePond
              files={attachmentfiles}
              onupdatefiles={setAttachmentfiles}
              allowMultiple={true}
              maxFiles={10}
              name="file"
              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              credits={false}
            />
          </div>
        </Grid>
      </Grid>
      <Stack
        direction="row"
        spacing={2}
        sx={{ paddingY: 2, display: "felx", justifyContent: "end" }}
      >
        <LoadingButton
          variant="contained"
          onClick={sendFiles}
          color="primary"
          loading={isLoading}
        >
          Send Ticket
        </LoadingButton>
        <Button onClick={clearTexts} variant="contained" color="warning">
          Clear
        </Button>
      </Stack>
    </CustomDialog>
  );
};

export default TicketForm;
