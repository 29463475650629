import {
  GET_ALL_SHIPPING_OPTIONS,
  GET_BY_ID_SHIPPING_OPTIONS,
  GET_SHIPPING_OPTIONS_LIST,
  OPEN_SHIPPING_OPTIONS_DIALOG,
  OPEN_SHIPPING_OPTIONS_FORM_DIALOG,
  RELOAD_SHIPPING_OPTIONS,
  RESET_SHIPPING_OPTIONS_FORM,
  SAVE_SHIPPING_OPTIONS_PAGE_NO,
  SAVE_SHIPPING_OPTIONS_SEARCH_BODY,
} from "src/actions/type";
import { shipping_types } from "src/utils/common";

const initialState = {
  reload: false,
  formDialog: false,
  selectedVendor: null,
  pageDialog: false,
  pageNo: 0,
  searchBody: {
    pageSize: 15,
    name: null,
    vendorId: null,
    fromPrice: "",
    toPice: "",
    isActive: null,
    isDefault: null,
  },
  form: {
    id: null,
    vendorId: null,
    vendor: null,
    name: null,
    price: "",
    isActive: true,
    isDefault: false,
  },
  data: {
    data: [],
    pageCount: 0,
    rowCount: 0,
  },
  collectionList: [],
  getById: {},
};

function shippingOptionsReducer(shippingOption = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_SHIPPING_OPTIONS:
      shippingOption.data = payload.data;
      shippingOption.pageCount = payload.pageCount;
      shippingOption.rowCount = payload.rowCount;
      return shippingOption;

    case GET_SHIPPING_OPTIONS_LIST:
      shippingOption.collectionList = payload;
      return shippingOption;

    case GET_BY_ID_SHIPPING_OPTIONS:
      shippingOption.form.id = payload.id;
      shippingOption.form.vendorId = payload.vendor?.id;
      shippingOption.form.vendor = payload.vendor;
      shippingOption.form.name =
        shipping_types.filter(
          (item) => item.id === payload.shippingOptionNameId
        )[0]?.id ?? null;
      shippingOption.form.price = payload.price;
      shippingOption.form.isActive = payload.isActive;
      shippingOption.form.isDefault = payload.isDefault;
      return shippingOption;

    case OPEN_SHIPPING_OPTIONS_FORM_DIALOG:
      return { ...shippingOption, formDialog: !shippingOption.formDialog };

    case OPEN_SHIPPING_OPTIONS_DIALOG:
      return {
        ...shippingOption,
        pageDialog: !shippingOption.pageDialog,
        selectedVendor: payload,
      };

    case SAVE_SHIPPING_OPTIONS_PAGE_NO:
      return { ...shippingOption, collectionPageNo: payload };

    case RELOAD_SHIPPING_OPTIONS:
      return { ...shippingOption, reload: !shippingOption.reload };

    case SAVE_SHIPPING_OPTIONS_SEARCH_BODY:
      return { ...shippingOption, searchBody: payload };

    case RESET_SHIPPING_OPTIONS_FORM:
      shippingOption.form.id = null;
      shippingOption.form.vendorId = null;
      shippingOption.form.vendor = null;
      shippingOption.form.name = "";
      shippingOption.form.price = "";
      shippingOption.form.isActive = true;
      shippingOption.form.isDefault = false;
      return shippingOption;

    default:
      return shippingOption;
  }
}

export default shippingOptionsReducer;
