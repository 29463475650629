import moment from "moment";
import {
  CREATE_BANNER,
  DELETE_BANNER,
  GET_ALL_BANNERS,
  UPDATE_BANNER,
  GET_BY_ID_BANNERS,
  RESET_FORM_BANNERS,
  SAVE_BANNERS_PAGE_NO,
  OPEN_BANNER_DIALOG,
  RELOAD_BANNER,
  SAVE_BANNERS_PAGE_SIZE,
  SAVE_SEARCH_BODY_BANNERS,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  savePromoPageNum: 1,
  pageSize: 15,
  searchBody: {
    isExpired: false,
    isTop: null,
    isNewUser: null,
    owner: "",
    type: 0,
  },
  attachmentUrl: null,
  form: {
    id: null,
    type: null,
    owner: "",
    attachmentId: [],
    sequence: 0,
    descriptionEN: "",
    descriptionAR: "",
    descriptionKu: "",
    titleAR: "",
    titleEN: "",
    titleKu: "",
    redirectUrl: "",
    isTop: true,
    isForNewUser: false,
    validUpTo: moment().format("YYYY-MM-DD"),
    giftItemId: null,
    giftItemCategoryId: null,
  },
  data: [],
  getById: {},
};

function bannerReducer(banners = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_BANNER:
      banners.data.push(payload.data);
      banners.totalCount += 1;
      return [...banners, payload];

    case GET_ALL_BANNERS:
      var _Banner = {
        reload: banners.reload,
        data: payload,
        getById: banners.getById,
        form: banners.form,
        openDialog: false,
        pageCount: payload.pageCount,
        rowCount: payload.rowCount,
        savePromoPageNum: banners.savePromoPageNum,
        pageSize: banners.pageSize,
        searchBody: banners.searchBody,
      };
      return _Banner;

    case GET_BY_ID_BANNERS:
      banners.form.id = payload.id;
      banners.form.type = payload.type;
      banners.form.owner = payload.owner;
      banners.form.attachmentId = payload.attachmentId;
      banners.form.sequence = payload.sequence;
      banners.form.descriptionEN = payload.descriptionEN;
      banners.form.descriptionAR = payload.descriptionAR;
      banners.form.descriptionKu = payload.descriptionKU;
      banners.form.titleAR = payload.titleAR;
      banners.form.titleEN = payload.titleEN;
      banners.form.titleKu = payload.titleKU;
      banners.form.redirectUrl = payload.type === 2 ? "" : payload.redirectUrl;
      banners.form.isTop = payload.isTop;
      banners.form.isForNewUser = payload.isForNewUser;
      banners.form.validUpTo = payload.validUpTo;
      banners.form.giftItemCategoryId =
        payload.type === 2 || payload.type === 0
          ? payload.giftItemCategoryId
          : null;
      banners.form.giftItemId =
        payload.type === 2 || payload.type === 0 ? payload.giftItemId : null;
      return banners;

    case RESET_FORM_BANNERS:
      banners.form.id = null;
      banners.form.type = null;
      banners.form.owner = "";
      banners.form.attachmentId = [];
      banners.form.sequence = 0;
      banners.form.descriptionEN = "";
      banners.form.descriptionAR = "";
      banners.form.titleAR = "";
      banners.form.titleEN = "";
      banners.form.titleKu = "";
      banners.form.redirectUrl = "";
      banners.form.isTop = false;
      banners.form.validUpTo = moment().format("YYYY-MM-DD");
      banners.form.giftItemCategoryId = null;
      banners.form.giftItemId = null;
      return banners;

    case SAVE_BANNERS_PAGE_NO:
      banners.savePromoPageNum = payload;
      return banners;

    case SAVE_SEARCH_BODY_BANNERS:
      return { ...banners, searchBody: payload };

    case SAVE_BANNERS_PAGE_SIZE:
      banners.pageSize = payload;
      return banners;

    case OPEN_BANNER_DIALOG:
      return {
        ...banners,
        openDialog: !banners.openDialog,
        attachmentUrl: payload,
      };

    case RELOAD_BANNER:
      return { ...banners, reload: !banners.reload };

    case UPDATE_BANNER:
      var indexToChange = banners.data.indexOf(
        banners.data.filter((x) => x.id === payload.id)[0]
      );
      banners.data.splice(indexToChange, 1, payload);
      return banners;

    case DELETE_BANNER:
      banners.data = banners.data.filter((promo) => promo.id !== payload.id);
      return banners;

    default:
      return banners;
  }
}

export default bannerReducer;
