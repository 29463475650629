import { Grid, Box } from "@mui/material";
import React from "react";
import ShippingConfigsHeader from "./Home/header/header";
import ShippingConfigsTable from "./Home/table";

const ShippingsConfigs = () => {
  return (
    <Box sx={{ minWidth: { md: '100%', xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <ShippingConfigsHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <ShippingConfigsTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShippingsConfigs;
