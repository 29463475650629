import Cookies from "universal-cookie";
const cookies = new Cookies();

class Cookie {
  get(CName) {
    return cookies.get(CName);
  }

  set(CName, data) {
    return cookies.set(CName, data, {
      expires: new Date(Date.now() + 172800000),
    }); //expire in 48 hours
  }

  clear(CName) {
    return cookies.remove(CName);
  }
}

export default new Cookie();
