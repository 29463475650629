import { Grid, Box } from "@mui/material";
import React from "react";
import UsersTable from "./Home/table";
import UsersHeader from "./Home/header/header";

const Users = () => {
  return (
    <Box sx={{minWidth: { lg: '100%', xs: 1800 }}}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <UsersHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <UsersTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Users;
