import React from "react";
import { Helmet } from "react-helmet";
import { Box, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import RolePermission from "src/components/RolePermission";
import TotalOrders from "src/pages/gifts/dashboard/TotalOrders";
import OrdersThisMonth from "src/pages/gifts/dashboard/OrdersThisMonth";
import TotalCategories from "src/pages/gifts/dashboard/TotalCategories";
import AverageOrderRatings from "src/pages/gifts/dashboard/averageOrderRatings";
import TotalProducts from "src/pages/gifts/dashboard/TotalProducts";
import TotalBrands from "src/pages/gifts/dashboard/totalBrands";
import TotalSuppliers from "src/pages/gifts/dashboard/totalSuppliers";
import { getAllCounts } from "src/actions/gifts/dashboard";
import OrdersByStatus from "src/pages/gifts/dashboard/OrdersByStatus";
import OrdersPerProvince from "src/pages/gifts/dashboard/ordersPerProvince";

export default function GiftDashboard() {
  const dispatch = new useDispatch();

  function getStatistics() {
    dispatch(getAllCounts());
  }

  useEffect(() => {
    getStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Grid container spacing={1} sx={{ p: 2, paddingTop: 0 }}>
          <RolePermission permission="07-01">
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalOrders />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <OrdersThisMonth />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalProducts />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalCategories />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <AverageOrderRatings />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalSuppliers />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalBrands />
            </Grid>
          </RolePermission>
          <RolePermission permission="07-04">
            <Grid item md={6} xl={6} xs={6}>
              <OrdersByStatus sx={{ height: "100%" }} />
            </Grid>
          </RolePermission>
          <RolePermission permission="07-07">
            <Grid item md={6} xl={6} xs={6}>
              <OrdersPerProvince sx={{ height: "100%" }} />
            </Grid>
          </RolePermission>
        </Grid>
      </Box>
    </>
  );
}
