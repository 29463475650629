import { Grid, Box } from "@mui/material";
import React from "react";
import CollectionsHeader from "./home/header/header";
import CollectionsTable from "./home/table";

const Collections = () => {
  return (
    <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <CollectionsHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <CollectionsTable />
        </Grid>
      </Grid>
      <Box sx={{ paddingX: 2 }}></Box>
    </Box>
  );
};

export default Collections;
