import { Grid, Box } from "@mui/material";
import React from "react";
import CartHeader from "./home/header/header";
import CartTable from "./home/table";

const Cart = () => {
  return (
    <Box sx={{ minWidth: { lg: '100%', xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <CartHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <CartTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Cart;
