import { useEffect } from "react";
import { Button, TextField, Grid, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { Controller, useForm } from "react-hook-form";
import { createFAQ, openDialog, resetForm, updateFAQ } from "src/actions/faq";
import { RATING_QUESTIONS_TYPES } from "src/utils/common";

const FAQForm = () => {
  const _FAQ = useSelector((state) => state.faq);
  const searchFilter = useSelector((state) => state.faq.searchBody);
  const PageNo = useSelector((state) => state.faq.pageNo);

  const isAdd = _FAQ.form.id ? false : true;
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: _FAQ.form,
  });

  useEffect(() => {
    reset({ ..._FAQ.form });
  }, [_FAQ.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        createFAQ(
          {
            questionAr: data.questionAr,
            questionEn: data.questionEn,
            questionKu: data.questionKu,
            answerEn: data.answerEn,
            answerAr: data.answerAr,
            answerKu: data.answerKu,
            type: data.type === "Orderii" ? 0 : data.type === "Gift" ? 1 : 2,
          },
          PageNo,
          searchFilter
        )
      );
    } else {
      dispatch(
        updateFAQ(
          data.id,
          {
            questionAr: data.questionAr,
            questionEn: data.questionEn,
            questionKu: data.questionKu,
            answerEn: data.answerEn,
            answerAr: data.answerAr,
            answerKu: data.answerKu,
            type: data.type === "Orderii" ? 0 : data.type === "Gift" ? 1 : 2,
          },
          PageNo,
          searchFilter
        )
      );
    }
  };

  const handleDialog = () => {
    dispatch(openDialog());
  };

  return (
    <CustomDialog
      title={isAdd ? "Add Rating Question" : "Update Rating Question"}
      isOpen={_FAQ.openDialog}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={RATING_QUESTIONS_TYPES || []}
                  getOptionLabel={(option) => option.value}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Type" />
                  )}
                  onChange={(event, value) => {
                    setValue("type", value?.value ?? null, {
                      shouldDirty: true,
                    });
                  }}
                  value={
                    RATING_QUESTIONS_TYPES?.filter(
                      (x) => x.value === field.value
                    )[0] ?? null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="questionEn"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Question English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="questionAr"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Question Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="questionKu"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Question Kurdish"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="answerEn"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  multiline
                  rows={4}
                  size="small"
                  label="Answer English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="answerAr"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  multiline
                  rows={4}
                  size="small"
                  label="Answer Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="answerKu"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  multiline
                  rows={4}
                  size="small"
                  label="Answer Kurdish"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isDirty}
            >
              {isAdd ? "Create" : "Update"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default FAQForm;
