import { TextField, Grid, Stack, Button, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useState } from "react";
import {
  createPurchasingBill,
  resetForm,
  updatePurchasingBill,
  openDialog,
} from "src/actions/purchasingBill";
import {
  purchasing_bill_banks,
  purchasing_bill_currency,
} from "src/utils/common";
import moment from "moment";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const PurchasingBillForm = () => {
  const dispatch = useDispatch();

  const _PurchasingBill = useSelector((state) => state.purchasingBill);
  const savePurchasingBillPageNO = useSelector(
    (state) => state.purchasingBill.savePurchasingBillPageNum
  );
  const _SearchBody = useSelector((state) => state.purchasingBill.searchBody);
  const _Vendors = useSelector((state) => state.vendors.dataList);

  const [loading, setLoading] = useState(false);
  const [attachmentsFiles, setAttachmentsFiles] = useState([]);
  const [files, setFiles] = useState([]);

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: _PurchasingBill.form,
  });

  useEffect(() => {
    if (attachmentsFiles.length > 0) {
      setFiles(attachmentsFiles.map((files) => files.file));
      setValue("attachment", attachmentsFiles.map((files) => files.file)[0], {
        shouldDirty: true,
      });
    } else {
      setValue("attachmentId", watch("attachmentId"), {
        shouldDirty: true,
      });
    }
    // eslint-disable-next-line no-use-before-define
  }, [attachmentsFiles, setValue, watch]);

  const isAdd = _PurchasingBill.form.id ? false : true;

  useEffect(() => {
    reset({ ..._PurchasingBill.form });
  }, [_PurchasingBill.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line no-use-before-define
  }, [dispatch]);

  const onSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("billNo", data.billNo);
    formData.append("date", moment(data.date).format("YYYY-MM-DD"));
    formData.append("vendorId", data.vendorId);
    formData.append("region", data.region);
    if (data.discount) formData.append("discount", data.discount ?? null);
    formData.append("amount", data.amount);
    formData.append("currency", data.currency);
    formData.append("bank", data.bank);
    if (isAdd) {
      formData.append("attachment", files[0]);
      dispatch(
        createPurchasingBill(formData, savePurchasingBillPageNO, _SearchBody)
      ).then(() => setLoading(false));
    } else {
      formData.append("id", data.id);
      if (attachmentsFiles.length > 0) {
        formData.append("attachment", files[0]);
      } else {
        formData.append("attachmentId", data.attachment);
      }
      dispatch(
        updatePurchasingBill(
          data.id,
          formData,
          savePurchasingBillPageNO,
          _SearchBody,
          true
        )
      ).then(() => setLoading(false));
    }
  };

  const handleDialog = () => {
    dispatch(openDialog(null));
  };

  return (
    <CustomDialog
      title={isAdd ? "CREATE PURCHASING BILL" : "UPDATE PURCHASING BILL"}
      isOpen={_PurchasingBill.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      Draggable={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Controller
              name="billNo"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Bill Number"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="region"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={["TR", "US", "EU", "AG", "WSM"]}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Region" />
                  )}
                  onChange={(event, value) => {
                    setValue("region", value ?? null, { shouldDirty: true });
                  }}
                  value={field.value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="vendorId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={_Vendors || []}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Vendors" />
                  )}
                  onChange={(event, value) => {
                    setValue("vendorId", value ? value.id : null, {
                      shouldDirty: true,
                    });
                  }}
                  value={
                    _Vendors.filter((x) => x.id === field.value)[0] ?? null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="discount"
              control={control}
              render={({ field }) => (
                <TextField size="small" label="Discount" fullWidth {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Amount"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="currency"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={purchasing_bill_currency}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Currency" />
                  )}
                  onChange={(event, value) => {
                    setValue("currency", value ?? null, { shouldDirty: true });
                  }}
                  value={field.value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="bank"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  options={purchasing_bill_banks}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Bank" />
                  )}
                  onChange={(event, value) => {
                    setValue("bank", value ?? null, { shouldDirty: true });
                  }}
                  value={field.value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    label="Date"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(moment(newValue).format("YYYY-MM-DD"), {
                        shouldDirty: true,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField size="small" {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          {isAdd ? null : (
            <Grid item xs={12} md={12} display="flex" justifyContent="center">
              <img
                alt=""
                src={_PurchasingBill.attachmentUrl}
                style={{
                  maxHeight: "200px",
                  maxWidth: "250px",
                  objectFit: "contain",
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Controller
              name="attachment"
              control={control}
              render={({ field }) => (
                <FilePond
                  required={watch("attachment") === "" ? true : false}
                  files={attachmentsFiles}
                  onupdatefiles={setAttachmentsFiles}
                  name="file"
                  labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                  credits={false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={loading}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
              {isAdd && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => reset({ ..._PurchasingBill.form })}
                >
                  Clear Text
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default PurchasingBillForm;
