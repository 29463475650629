import { Grid, Box } from "@mui/material";
import React from "react";
import HomeItemsHeader from "./home/header/header";
import HomeItemsTable from "./home/table";

const HomeItems = () => {
  return (
    <Box sx={{ minWidth: { md: '100%', xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <HomeItemsHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <HomeItemsTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeItems;
