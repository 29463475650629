import helper from "src/utils/helper";
import DashboardDataService from "../../api/giftServices/dashboard.service";
import {
  openAlertDialog,
  showErrorSnackbar,
} from "src/actions/snackbarMessages";
import {
  GET_GIFT_DASHBOARD_ALL_COUNTS,
  GET_GIFT_DASHBOARD_ORDER_PER_PROVINCE,
  GET_GIFT_DASHBOARD_ORDER_PER_STATUS,
} from "src/actions/gifts/giftTypes";

export const getAllCounts = () => async (dispatch) => {
  try {
    const res = await DashboardDataService.getAll();
    dispatch({
      type: GET_GIFT_DASHBOARD_ALL_COUNTS,
      payload: res.data.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getOrderCountByStatus =
  (startDate, endDate) => async (dispatch) => {
    try {
      const res = await DashboardDataService.getOrders(startDate, endDate);
      dispatch({
        type: GET_GIFT_DASHBOARD_ORDER_PER_STATUS,
        payload: res.data.data,
      });
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getOrdersPerProvince = (filter) => async (dispatch) => {
  try {
    const res = await DashboardDataService.getOrdersPerProvince(filter);
    dispatch({
      type: GET_GIFT_DASHBOARD_ORDER_PER_PROVINCE,
      payload: res.data.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
