import {
  CLOSE_ALERT_DIALOG,
  CLOSE_SNACKBAR,
  OPEN_ALERT_DIALOG,
  SHOW_SNACKBAR,
} from "./type";

export const closeSnackbar = () => async (dispatch) => {
  dispatch({
    type: CLOSE_SNACKBAR,
  });
};

export const showSuccessSnackbar = (message) => async (dispatch) => {
  var data = {
    message: message,
    type: "success",
    show: true,
    dialog: false,
  };
  dispatch({
    type: SHOW_SNACKBAR,
    payload: data,
  });
};

export const showErrorSnackbar = (message) => async (dispatch) => {
  var data = {
    message: message,
    type: "error",
    show: true,
    dialog: false,
  };
  dispatch({
    type: SHOW_SNACKBAR,
    payload: data,
  });
};

export const showWarningSnackbar = (message) => async (dispatch) => {
  var data = {
    message: message,
    type: "warning",
    show: true,
    dialog: false,
  };
  dispatch({
    type: SHOW_SNACKBAR,
    payload: data,
  });
};

export const openAlertDialog = (message) => async (dispatch) => {
  var data = {
    message: message,
    type: "error",
    show: false,
    dialog: true,
  };
  dispatch({
    type: OPEN_ALERT_DIALOG,
    payload: data,
  });
};

export const closeAlertDialog = (message) => async (dispatch) => {
  var data = {
    message: message,
    type: "error",
    show: false,
    dialog: false,
  };
  dispatch({
    type: CLOSE_ALERT_DIALOG,
    payload: data,
  });
};
