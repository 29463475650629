import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "src/actions/ticket/ticket";
import TicketContent from "./List";
import RolePermission from "src/components/RolePermission";
import TicketForm from "src/pages/cross/Ticket/Form";

const Tickets = () => {
  const ticket = useSelector((state) => state.tickets);
  const dispatch = useDispatch();

  return (
    <>
      <Helmet>
        <title>Tickets | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false} sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
          <Card>
            <CardHeader
              title={
                <Typography variant="h4" component="div">
                  Tickets
                </Typography>
              }
              avatar={<BookOnlineIcon size={20} />}
              action={
                <RolePermission permission="T3-07">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={() => dispatch(openDialog(ticket.openDialog))}
                  >
                    Create Ticket
                  </Button>
                </RolePermission>
              }
            />
            {ticket.openDialog ? <TicketForm /> : null}
          </Card>

          <Box sx={{ pt: 2 }}>
            <TicketContent />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Tickets;
