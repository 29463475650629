import {
  PROMOTED_ITEMS_RESET_FORM,
  PROMOTED_ITEMS_GET_BY_ID,
  PROMOTED_ITEMS_PAGE_NUMBER,
  PROMOTED_ITEMS_OPEN_DIALOG,
  PROMOTED_ITEMS_RELOAD,
  PROMOTED_ITEMS_GET_ALL,
  PROMOTED_ITEMS_GET_ALL_LIST,
  PROMOTED_ITEMS_SEARCH_BODY,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  pageSize: 10,
  searchBody: {
    pageSize: 15,
    price: "",
    name: "",
    vendorId: null,
  },
  form: {
    id: null,
    url: "",
    price: "",
    name: "",
    vendorId: "",
    imageUrl: "",
  },
  promotedItemsData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  promotedItemsDataList: [],
  getById: {},
};

function promotedItemsReducer(promotedItems = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PROMOTED_ITEMS_GET_ALL:
      promotedItems.promotedItemsData.data = payload.data;
      promotedItems.promotedItemsData.total = payload.pageCount;
      promotedItems.promotedItemsData.rowCount = payload.rowCount;
      return promotedItems;

    case PROMOTED_ITEMS_GET_ALL_LIST:
      promotedItems.promotedItemsDataList = payload;
      return promotedItems;

    case PROMOTED_ITEMS_GET_BY_ID:
      promotedItems.form = {
        ...payload,
        vendorId: payload.vendor?.id
      };
      return promotedItems;

    case PROMOTED_ITEMS_RESET_FORM:
      promotedItems.form.id = null;
      promotedItems.form.name = "";
      promotedItems.form.price = "";
      promotedItems.form.vendorId = "";
      promotedItems.form.url = "";
      promotedItems.form.imageUrl = "";
      return promotedItems;

    case PROMOTED_ITEMS_PAGE_NUMBER:
      return { ...promotedItems, pageNo: payload };

    case PROMOTED_ITEMS_SEARCH_BODY:
      return { ...promotedItems, searchBody: payload };

    case PROMOTED_ITEMS_OPEN_DIALOG:
      return {
        ...promotedItems,
        openDialog: !promotedItems.openDialog,
      };

    case PROMOTED_ITEMS_RELOAD:
      return { ...promotedItems, reload: !promotedItems.reload };

    default:
      return promotedItems;
  }
}

export default promotedItemsReducer;
