import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, LinearProgress, Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  getAllOnboarding,
  getByIdForUpdate,
  saveSearchBody,
} from "src/actions/onboarding";
import OnboardingForm from "src/pages/cross/Configuration/onboarding/form/Form";
import { FaRegCopy } from "react-icons/fa";
import { showSuccessSnackbar } from "src/actions/snackbarMessages";
import useCanShow from "src/components/CanShow";

export default function OnboardingTable() {
  const dispatch = useDispatch();

  const editRole = useCanShow("56-03");

  const _Onboarding = useSelector((state) => state.onboarding.onboardingData);
  const searchFilter = useSelector((state) => state.onboarding.searchBody);
  const reload = useSelector((state) => state.onboarding.reload);

  useEffect(() => {
    dispatch(getAllOnboarding(searchFilter));
  }, [searchFilter, dispatch]);

  const handleLimitChange = (newPageSize) => {
    dispatch(saveSearchBody({ ...searchFilter, pageSize: newPageSize }));
  };

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "url",
      headerName: "Image URL",
      flex: 1,
      renderCell: (params) => (
        <Box
          component="img"
          src={params.row.url}
          width={200}
          style={{ objectFit: "contain" }}
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<FaRegCopy />}
          label="Copy Image URL"
          showInMenu
          onClick={(e) => {
            window.navigator.clipboard.writeText(params.row.url);
            dispatch(showSuccessSnackbar("Image URL Copied"));
          }}
        />,
        <GridActionsCellItem
          icon={<EditIcon color="primary" />}
          label="Edit"
          showInMenu
          hidden={!editRole}
          onClick={(e) => {
            dispatch(getByIdForUpdate(params.row.id));
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <OnboardingForm />
      <PerfectScrollbar>
        <Grid container spacing={2} sx={{ padding: 3 }}>
          <Grid item xs={12}>
            <Box sx={{ minWidth: 1050 }}>
              <Box sx={{ height: { md: "calc(100vh - 220px)", xs: 1000 } }}>
                <DataGrid
                  sx={{
                    "& .css-204u17-MuiDataGrid-main": {
                      paddingLeft: "15px",
                    },
                    "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                      paddingLeft: "15px",
                    },
                  }}
                  loading={reload}
                  rows={_Onboarding || []}
                  columns={columns}
                  rowHeight={300}
                  disableSelectionOnClick
                  pagination
                  pageSize={searchFilter.pageSize}
                  onPageSizeChange={(newPageSize) =>
                    handleLimitChange(newPageSize)
                  }
                  rowCount={_Onboarding.length || 0}
                  paginationMode="client"
                  rowsPerPageOptions={[15, 25, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Card>
  );
}
