import axiosService from "../../axiosService";

class StatisticsService {
  getTicketSubmitted() {
    return axiosService.get(`/ticket-service/api/statistics/submitted`);
  }

  getTicketSummary() {
    return axiosService.get(`/ticket-service/api/statistics/summary`);
  }

  lastSevenDaysSummary() {
    return axiosService.get(`/ticket-service/api/statistics/lastsevendayssummary`);
  }
}

export default new StatisticsService();
