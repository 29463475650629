import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { CustomDialog } from "../../CustomDialog";
import { DatePicker, LocalizationProvider  } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Agent_Priority, Agent_Type } from "src/utils/common";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOrderOptim } from "src/actions/agentrequests";
import { getAllAddresses } from "src/actions/addresses";
import { Grid } from "@mui/material";

export default function AgentDialog(props) {

  const addresses = useSelector((state) => state.addresses);

  function clearTexts() {
    setData({
      ...data,
      notes: "",
      email: "",
    });
    setAgentPriority({});
    setAgentType({});
    setSelectedProvince({
      nameAR: "",
      nameUS: "",
      cities: [],
    })
  }
  const [agentType, setAgentType] = useState({});
  const [agentPriority, setAgentPriority] = useState({});
  // const [addresses, setAddresses] = useState({});
  const [selectedProvince, setSelectedProvince] = useState({
    nameAR: "",
    nameUS: "",
    cities: [],
  });
  const [data, setData] = useState({
    orderNo: props.customer.number,
    date: moment(new Date()).format("yyyy-MM-DD"),
    duration: 1,
    priority: "M",
    type: "P",
    // address: "",
    // locationName: "",
    address: props.customer.address,
    locationName: props.customer.address,
    notes: "",
    email: "",
    phone: "0"+props.customer.phone,
    customerName: props.customer.fullName,
    amount: `${props.customer.amount}`,
    requestDate: moment(props.customer.createdAt).format("yyyy-MM-DD"),
  });



  const dispatch = useDispatch();

  const handleCreate = () => {
    dispatch(createOrderOptim(data)).then(() => {
      props.handleClose();
      clearTexts()
    });
  };

  useEffect(() => {
    dispatch(getAllAddresses()).then((res) => {
      // setAddresses(res.data);

    });
  }, []);

  useEffect(() => {
    if (props.customer)
      setData({
        ...data,
        address: props.customer.address,
        locationName: props.customer.address,
        orderNo: props.customer.number,
        phone: "0"+props.customer.phone,
        customerName: props.customer.fullName,
        amount: props.customer.amount,
        requestDate: moment(props.customer.createdAt).format("yyy-MM-DD"),
      })
  },
    [props.customer]
  )

  return (
    <CustomDialog
      isOpen={props.open}
      handleClose={props.handleClose}
      title="Create"
      maxWidth="md"
    >
      <Box
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        justifyItems="center"
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              label="Order number"
              size="small"
              fullWidth
              value={data.orderNo}
              onChange={(e) => {
                setData({ ...data, orderNo: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                views={["year", "month", "day"]}
                label="Delivery date"
                name="deliveryDate"
                value={data.date}
                onChange={(value) => {
                  setData({ ...data, date: moment(value).format("yyyy-MM-DD") });
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Duration"
              size="small"
              fullWidth
              value={data.duration}
              onChange={(e) => setData({ ...data, duration: e.target.value })}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              id="Priority"
              options={Agent_Priority}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Priority" />}
              onChange={(event, value) => {
                setAgentPriority(value ? value : Agent_Priority.filter((x) => x.id === 'M')[0]);
                setData({ ...data, priority: value ? value.id : "M" });
              }}
              value={
                Agent_Priority.filter((x) => x.id === agentPriority.id)[0]
                  ? Agent_Priority.filter((x) => x.id === agentPriority.id)[0]
                  : Agent_Priority.filter((x) => x.id === 'M')[0]
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              id="Type"
              options={Agent_Type}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Type" />}
              onChange={(event, value) => {
                setAgentType(value ? value : Agent_Type.filter((x) => x.id === 'P')[0]);
                setData({ ...data, type: value ? value.id : 'P' });
              }}
              value={
                Agent_Type.filter((x) => x.id === agentType.id)[0]
                  ? Agent_Type.filter((x) => x.id === agentType.id)[0]
                  : Agent_Type.filter((x) => x.id === 'P')[0]
              }
            />
          </Grid>
          {/* <Grid item xs={6}>
            <Autocomplete
              options={addresses}
              getOptionLabel={(option) =>
                option.nameAR
                  ? `${option.nameAR} , ${option.nameUS}`
                  : "Not selected"
              }
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Provinces" />}
              onChange={(event, value) => {
                setSelectedProvince(
                  value ? value : { nameAR: "", nameUS: "", cities: [] }
                );
                setData({ ...data, address:value? value.nameAR + ", " + value.nameUS :""});
              }}
              value={selectedProvince}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={selectedProvince.cities}
              getOptionLabel={(option) =>
                option ? `${option.nameAR} , ${option.nameUS}` : "Not selected"
              }
              fullWidth
              size="small"
              renderInput={(params) => <TextField {...params} label="Cities" />}
              onChange={(event, value) => {
                setData({
                  ...data,
                  locationName: value?value.nameAR + ", " + value.nameUS:"",
                });
              }}
            />
          </Grid> */}
          
          <Grid item xs={12}>
            <TextField
              label="Address"
              size="small"
              fullWidth
              value={data.address}
              onChange={(e) => {

                setData({ ...data, address: e.target.value ,locationName:e.target.value});

              }}
            />
          </Grid>
          <Grid item xs={3}>
          <TextField
              label="Phone Number"
              size="small"
              fullWidth
              value={data.phone}
              onChange={(e) => {
                setData({ ...data, phone: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Customer Name"
              size="small"
              fullWidth
              value={data.customerName}
              onChange={(e) => {
                setData({ ...data, customerName: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Amount"
              size="small"
              fullWidth
              value={`$ ${data.amount}`}
              onChange={(e) => {
                setData({ ...data, amount: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Request time"
              size="small"
              fullWidth
              value={
                data.requestDate
                  ? moment(data.requestDate).format("yyy-MM-DD / hh:mm a")
                  : ""
              }
              onChange={(e) => {
                setData({ ...data, requestDate: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              label="Notes"
              size="small"
              fullWidth
              value={data.notes}
              onChange={(e) => {
                setData({ ...data, notes: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Email"
              size="small"
              fullWidth
              value={data.email}
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
            />
          </Grid>
        </Grid>


        <Button
          sx={{ marginTop: 2, marginLeft: 45 }}
          variant="contained"
          onClick={() => {
            handleCreate();
          }}
        >
          Create
        </Button>
      </Box>
    </CustomDialog>
  );
}
