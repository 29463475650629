import {
  GET_ALL_GO_IP,
  GET_CREDIT_GO_IP,
  OPEN_GO_IP_DIALOG,
  RELOAD_GO_IP,
  SAVE_GO_IP_SAVE_NO,
  SAVE_GO_IP_SEARCH_BODY,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  saveGoIpPageNum: 0,
  pageSize: 15,
  data: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  creditBalance: {
    asiaCell: {
      balance: "",
      expireAt: "",
      message: "",
    },
    zain: {
      balance: "",
      expireAt: "",
      message: "",
    },
  },
  searchBody: {
    status: null,
    fromCreatedAt: null,
    toCreatedAt: null,
    pageSize: 15,
  },
  getById: {},
};

function goIpReducer(goIp = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_GO_IP:
      goIp.data.data = payload.data;
      goIp.data.total = payload.total;
      goIp.data.rowCount = payload.rowCount;
      return goIp;

    case GET_CREDIT_GO_IP:
      const extractBalanceAndDate = (text) => {
        const balanceRegex =
          /(?:رصيدك|رصيد حسابك|رصيد حسابك هو:|رصيد حسابك هو|رصيدك هو)\s*([\d,]+(?:\.\d+)?)/;
        const dateRegex =
          /(?:صالح لغاية|تنتهي صلاحية في|يمكنك استخدامه لغاية|تنتهي صلاحيته في)\s*(\d{4}-\d{2}-\d{2}|\d{2}\/\d{2}\/\d{4})/;

        const balanceMatch = text.match(balanceRegex);
        const dateMatch = text.match(dateRegex);

        const balance = balanceMatch ? balanceMatch[1] : null;
        const date = dateMatch ? dateMatch[1] : null;

        return { balance, date };
      };

      const updateCreditBalance = (provider, payload) => {
        if (!payload.message && !payload.expireAt && !payload.balance) return;
        if (!payload.balance && !payload.expireAt) {
          const extractedData = extractBalanceAndDate(payload?.message);
          goIp.creditBalance[provider].balance = extractedData.balance;
          goIp.creditBalance[provider].expireAt = extractedData.date;
          goIp.creditBalance[provider].message = payload?.message;
        } else {
          goIp.creditBalance[provider] = payload;
        }
      };

      updateCreditBalance("asiaCell", payload.asiaCell);
      updateCreditBalance("zain", payload.zain);

      return goIp;

    case SAVE_GO_IP_SAVE_NO:
      return { ...goIp, saveGoIpPageNum: payload };

    case SAVE_GO_IP_SEARCH_BODY:
      return { ...goIp, searchBody: payload };

    case OPEN_GO_IP_DIALOG:
      return { ...goIp, openDialog: !goIp.openDialog };

    case RELOAD_GO_IP:
      return { ...goIp, reload: !goIp.reload };

    default:
      return goIp;
  }
}

export default goIpReducer;
