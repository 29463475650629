import * as React from "react";
import { Box, CircularProgress } from "@mui/material";

export default function CustomSpinner() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        padding: "30px",
        position: "relative",
        alignItems: "center",
      }}
    >
      <CircularProgress size={80} thickness={2.5} />
      <img
        style={{ width: 50, position: "absolute" }}
        src="/static/images/logo/logo_orderii_english.png"
        alt="Progress bar"
      />
    </Box>
  );
}
