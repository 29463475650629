import { Helmet } from 'react-helmet';
import { Box, Card, CardHeader, Container, Grid, Typography } from '@mui/material';
import ReportsList from 'src/components/pagesContent/reports/reportsContent';
import { TbReportAnalytics } from 'react-icons/tb';

const WalletReport = () => (
  <>
    <Helmet>
      <title>Wallet Report | Orderii</title>
    </Helmet>
    <Box sx={{ minWidth: { md: '100%', xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <Card>
            <CardHeader
              title={
                <Typography variant="h4" component="div">
                  Wallet Report
                </Typography>
              }
              avatar={<TbReportAnalytics size={20} />}
            />
          </Card>
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <Box>
            <ReportsList />
          </Box>
        </Grid>
      </Grid>
    </Box>
  </>
);

export default WalletReport;
