import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, Chip, LinearProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import {
  getAllAgentRequestProvinces,
  updateAgentRequestProvincesActivation,
} from "src/actions/agentCityConfig";
import { CustomDialog } from "src/components/CustomDialog";

export default function AgentRequestProvincesTable() {
  const dispatch = useDispatch();

  const _AgentCityConfig = useSelector(
    (state) => state.agentCityConfig.agentRequestProvinces
  );
  const reload = useSelector((state) => state.agentCityConfig.reload);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState();

  useEffect(() => {
    dispatch(getAllAgentRequestProvinces());
  }, [dispatch]);

  function deleteSelectedPromo(id) {
    setOpenDeleteDialog(false);
    dispatch(updateAgentRequestProvincesActivation(id)).then();
  }

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "province",
      headerName: "Province",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) =>
        `${params.row.province?.nameUS} - ${params.row.province?.nameAR}`,
    },
    {
      field: "isActive",
      headerName: "Is Active",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.isActive ? "Active" : "Inactive"}
          color={params.row.isActive ? "success" : "error"}
          size="small"
          onClick={(e) => {
            setSelectedProvince(params.row);
            setOpenDeleteDialog(true);
          }}
        />
      ),
    },
  ];

  return (
    <Card>
      <CustomDialog
        isOpen={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        isActiveDelete
        isConfirm={true}
        confirmTitle={`${
          selectedProvince?.isActive ? "deactivate" : "activate"
        }`}
        isRequierdActions
        title="Confirmation"
        onDelete={() => deleteSelectedPromo(selectedProvince?.id)}
        selectedObject={`${selectedProvince?.province?.nameUS} - ${selectedProvince?.province?.nameAR}`}
      />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <DataGrid
            sx={{
              "& .css-204u17-MuiDataGrid-main": {
                paddingLeft: "15px",
              },
              "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                paddingLeft: "15px",
              },
              ".MuiDataGrid-cell": {
                justifyContent: "center",
              },
            }}
            autoHeight
            loading={reload}
            rows={_AgentCityConfig}
            columns={columns}
            rowHeight={65}
            disableSelectionOnClick
            pagination
            rowCount={_AgentCityConfig?.rowCount}
            paginationMode="client"
            rowsPerPageOptions={[100]}
            components={{
              LoadingOverlay: LinearProgress,
            }}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
