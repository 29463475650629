import moment from "moment";
import {
  EVENTS_PAGE_NUMBER,
  EVENTS_RELOAD,
  EVENTS_GET_ALL,
  EVENTS_SEARCH_BODY,
  EVENTS_OPEN_DIALOG,
  EVENTS_RESET_FORM,
  EVENTS_GET_BY_ID,
} from "src/actions/gifts/giftTypes";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  pageSize: 10,
  form: {
    name: "",
    nameEn: "",
    date: null,
    note: "",
    noteEn: "",
    isAnnually: false,
  },
  eventsData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  searchBody: {
    pageSize: 15,
    name: "",
    status: null,
    phoneNumber: "",
    isAnnually: null,
    fromDate: moment().format("2024-01-01"),
  },
  getById: {},
};

function eventsReducer(events = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EVENTS_GET_ALL:
      events.eventsData.data = payload.data;
      events.eventsData.total = payload.pageCount;
      events.eventsData.rowCount = payload.rowCount;
      return events;

    case EVENTS_GET_BY_ID:
      return { ...events, form: payload };

    case EVENTS_PAGE_NUMBER:
      return { ...events, pageNo: payload };

    case EVENTS_SEARCH_BODY:
      return { ...events, searchBody: payload };

    case EVENTS_RELOAD:
      return { ...events, reload: !events.reload };

    case EVENTS_OPEN_DIALOG:
      return { ...events, openDialog: !events.openDialog };

    case EVENTS_RESET_FORM:
      return { ...events, form: initialState.form };

    default:
      return events;
  }
}

export default eventsReducer;
