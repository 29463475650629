import {
  TextField,
  Grid,
  DialogActions,
  Box,
  TableContainer,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Switch,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useEffect } from "react";

import { LoadingButton } from "@mui/lab";
import {
  createGiftCollections,
  openGiftCollectionsDialog,
  resetGiftCollectionsForm,
  updateGiftCollections,
} from "src/actions/gifts/collections";
import SelectGiftItem from "src/components/Selectors/SelectGiftItem";

const CollectionsForm = ({ selectedItems }) => {
  const dispatch = useDispatch();

  const _Collections = useSelector((state) => state.giftCollections);
  const reload = useSelector((state) => state.giftCollections.reload);
  const searchBody = useSelector((state) => state.giftCollections.searchBody);
  const pageNo = useSelector((state) => state.giftCollections.collectionPageNo);

  const isAdd = _Collections.form.id ? false : true;
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: _Collections.form,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "giftItemsIds",
  });

  useEffect(() => {
    reset({ ..._Collections.form });
  }, [_Collections.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetGiftCollectionsForm());
    };
    // eslint-disable-next-line no-use-before-define
  }, [dispatch]);

  useEffect(() => {
    if (selectedItems && selectedItems.length > 0) {
      selectedItems.forEach((item) => {
        append({
          ...item,
          giftItemAttachments: item.giftItemAttachments[0],
        });
      });
    }
  }, [selectedItems, append]);

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        createGiftCollections(
          {
            nameEn: data.nameEn,
            nameAr: data.nameAr,
            nameKu: data.nameKu,
            isForOrderiiNow: data.isForOrderiiNow,
            giftItemsIds: data.giftItemsIds.map((item) => item.id),
            sequence: data.sequence,
          },
          pageNo,
          searchBody,
          true
        )
      );
    } else {
      dispatch(
        updateGiftCollections(
          data.id,
          {
            nameEn: data.nameEn,
            nameAr: data.nameAr,
            nameKu: data.nameKu,
            isForOrderiiNow: data.isForOrderiiNow,
            giftItemsIds: data.giftItemsIds.map((item) => item.id),
            sequence: data.sequence,
          },
          pageNo,
          searchBody
        )
      );
    }
  };

  const handleDialog = () => {
    dispatch(openGiftCollectionsDialog(null));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog
        open={_Collections.openDialog}
        keepMounted
        onClose={() => {
          handleDialog();
        }}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle>
          <Typography color={isAdd ? "primary" : "orange.main"}>
            {isAdd ? "Create Collection" : "Update Collection"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} py={2}>
            <Grid item xs={4}>
              <Controller
                name="nameEn"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    inputProps={{
                      minLength: 4,
                    }}
                    size="small"
                    label="Name English"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="nameAr"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Name Arabic"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="nameKu"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Name Kurdish"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="newGiftItemsIds"
                control={control}
                render={({ field }) => (
                  <SelectGiftItem
                    fullWidth
                    onChange={(e, newVal) => {
                      append({
                        ...newVal,
                        id: newVal.id,
                        giftItemAttachments: newVal.giftItemAttachments[0],
                      });
                      setValue("newGiftItemsIds", null);
                    }}
                    clearOnEscape
                    filterSelectedOptions={true}
                    value={null}
                    blurOnSelect={true}
                    getOptionDisabled={(option) => {
                      return watch("giftItemsIds").some(
                        (item) => item.id === option.id
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="sequence"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Sequence"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="isForOrderiiNow"
                control={control}
                render={({ field }) => (
                  <Tooltip title="FALSE | TRUE" aria-label="active_deactivate">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={field.value}
                          onChange={(e) => {
                            setValue("isForOrderiiNow", e.target.checked);
                          }}
                          {...field}
                        />
                      }
                      label="Is For Orderii Now"
                    />
                  </Tooltip>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Brand</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Operations</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields?.map((item, index) => (
                      <TableRow key={item.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.nameEn}</TableCell>
                        <TableCell>
                          {item.giftItemCategories?.map(
                            (data, index) =>
                              data.nameEn?.toUpperCase() +
                              (index + 1 === item.giftItemCategories?.length
                                ? ""
                                : ", ")
                          )}
                        </TableCell>
                        <TableCell>{item.giftBrand?.nameEn}</TableCell>
                        <TableCell>
                          <Box
                            component="img"
                            src={item.giftItemAttachments?.url}
                            width={120}
                            height={120}
                            sx={{ objectFit: "contain" }}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* {!isAdd ? (
            <Grid item xs={12}>
              <Controller
                name="isActive"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("isActive", e.target.checked, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    }
                    label="Is Active!"
                  />
                )}
              />
            </Grid>
          ) : null} */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            color="primary"
            loading={reload}
            disabled={!isDirty}
            sx={{ width: "25%", my: 2 }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default CollectionsForm;
