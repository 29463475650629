import axios from "../axios";
import _axios from "axios";

class AuthService {
  login(data) {
    return axios.post(`/auth/login`, data);
  }

  getIp() {
    return _axios.get(`https://api.hostip.info/get_html.php`);
  }

  loginStaffAuth(data) {
    return axios.post(`/staffauth/login`, data);
  }

  validateStaffAuth(data) {
    return axios.post(`/staffauth/validatelogin`, data);
  }
}

export default new AuthService();
