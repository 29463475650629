import { Grid, Box } from "@mui/material";
import React from "react";
import WrappingTable from "src/pages/gifts/wrapping/home/table";
import WrappingHeader from "./home/header/header";

const Wrapping = () => {
  return (
    <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <WrappingHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <WrappingTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Wrapping;
