import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TextField,
  Tooltip,
  Stack,
  Grid,
  CardContent,
  LinearProgress,
  Autocomplete,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { AiOutlineClose } from "react-icons/ai";
import {
  deleteHomeItems,
  getAllHomeItems,
  saveHomeItemsSearchBody,
} from "src/actions/homeItems";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import { getAllVendors } from "src/actions/vendors";
import RolePermission from "src/components/RolePermission";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { CustomDialog } from "src/components/CustomDialog";

export default function HomeItemsTable() {
  const dispatch = useDispatch();

  const _HomeItems = useSelector((state) => state.homeItems.data);
  const loading = useSelector((state) => state.homeItems.reload);
  const _SearchBody = useSelector((state) => state.homeItems.vendorId);
  const vendors = useSelector((state) => state.vendors.dataList);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    dispatch(getAllHomeItems(_SearchBody));
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(getAllVendors());
  }, [dispatch]);

  function deleteSelectedItem() {
    dispatch(deleteHomeItems(selectedId?.id, _SearchBody)).then((res) => {
      setOpenDeleteDialog(false);
      setSelectedId(null);
    });
  }

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.item?.name}>
          <a
            rel="noreferrer"
            href={
              params.row.item?.url.includes("shein")
                ? params.row.item?.url.replace(/(https:\/\/\s*)m/g, "$1ar")
                : params.row.item?.url
            }
            target="_blank"
          >
            <Typography variant="body" noWrap sx={{ textAlign: "start" }}>
              {params.row.item?.name}
            </Typography>
          </a>
        </Tooltip>
      ),
    },
    {
      field: "imageUrl",
      headerName: "Image Url",
      flex: 1,
      renderCell: (params) => (
        <Box component="img" src={params.row.item?.imageUrl} width={90} />
      ),
    },
    {
      field: "vendor",
      headerName: "Vendor",
      flex: 1,
      renderCell: (params) => (
        <Box component="img" src={params.row.vendor?.logoUrl} width={90} />
      ),
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <RolePermission permission="38-02">
            <IconButton
              color="error"
              aria-label="delete"
              onClick={(e) => {
                setOpenDeleteDialog(true);
                setSelectedId(params.row.item);
              }}
            >
              <Tooltip title="Delete" aria-label="delete">
                <DeleteForeverIcon />
              </Tooltip>
            </IconButton>
          </RolePermission>
        </Stack>
      ),
    },
  ];

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => {
              setOpenDeleteDialog(false);
            }}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedItem()}
            selectedObject={selectedId?.name}
          ></CustomDialog>
        )}
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Autocomplete
              options={vendors || []}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Vendors" />
              )}
              onChange={(event, value) => {
                dispatch(saveHomeItemsSearchBody(value ? value.id : null));
              }}
              value={vendors?.filter((x) => x.id === _SearchBody)[0] ?? null}
            />
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" spacing={2} sx={{ justifyContent: "start" }}>
              {/* <Tooltip title="Search with filter">
                <IconButton
                  onClick={() => {
                    dispatch(saveHomeItemsSearchBody(_SearchBody));
                  }}
                >
                  <AiOutlineSearch />
                </IconButton>
              </Tooltip> */}
              <Tooltip title="Clear filter">
                <IconButton
                  onClick={() => {
                    dispatch(saveHomeItemsSearchBody(null));
                  }}
                >
                  <AiOutlineClose />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box sx={{ height: { md: "calc(100vh - 270px)", xs: 1000 } }}>
                <DataGrid
                  columns={columns}
                  rows={_HomeItems?.map((x, index) => ({
                    ...x,
                    index: index + 1,
                  }))}
                  getRowId={(params) => params.index}
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={150}
                  pagination
                  paginationMode="client"
                  // rowCount={_HomeItems?.rowCount}
                  rowsPerPageOptions={[15, 25, 75, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
