import { Helmet } from "react-helmet";
import { Box, Button } from "@mui/material";
import { Card, CardHeader, Typography } from "@mui/material";
import { MdDateRange } from "react-icons/md";
import RolePermission from "src/components/RolePermission";
import { Add } from "@mui/icons-material";
import { openEventsDialog } from "src/actions/gifts/events";
import { useDispatch, useSelector } from "react-redux";
import SuggestedEventsForm from "src/pages/gifts/events/sugestedEvents/from/Form";

const EventsHeader = () => {
  const dispatch = useDispatch();

  const dialog = useSelector((state) => state.events.openDialog);

  return (
    <>
      <Helmet>
        <title>Suggested Events | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Suggested Events
              </Typography>
            }
            avatar={<MdDateRange size={20} />}
            action={
              <RolePermission permission="51-09">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<Add />}
                  onClick={() => dispatch(openEventsDialog())}
                >
                  Create
                </Button>
              </RolePermission>
            }
          />
          {dialog ? <SuggestedEventsForm /> : null}
        </Card>
      </Box>
    </>
  );
};

export default EventsHeader;
