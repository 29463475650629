import categoryService from "src/api/services/ticket/category.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../snackbarMessages";
import {
  GET_ALL_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  OPEN_CATEGORY_DIALOG,
  GET_CATEGORY_BYID,
  RELOAD_CATEGORY,
  TICKET_CATEGORY_PAGE_NO,
  GET_ALL_CATEGORY_LIST,
  RESET_FORM_CATEGORY,
} from "../type";

export const createCategory = (data, pageNo) => async (dispatch) => {
  try {
    const res = await categoryService.createCategory(data);
    dispatch(showSuccessSnackbar("Category Created successfully"));
    dispatch(openCategoryDialog());
    dispatch(showSuccessSnackbar("Ticket category created successfully"));
    dispatch(getCategoryPageNo(pageNo));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const openCategoryDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_CATEGORY_DIALOG,
    payload: data,
  });
};

export const reloadCategory = (data) => (dispatch) => {
  dispatch({
    type: RELOAD_CATEGORY,
    payload: data,
  });
};

export const saveTicketCategoryPageNo = (data) => (dispatch) => {
  dispatch({
    type: TICKET_CATEGORY_PAGE_NO,
    payload: data,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: RESET_FORM_CATEGORY,
  });
};

export const getCategory = () => async (dispatch) => {
  try {
    dispatch(reloadCategory());
    const res = await categoryService.getCategory();
    dispatch({
      type: GET_ALL_CATEGORY_LIST,
      payload: res.data.data,
    });
    dispatch(reloadCategory());
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getCategoryById = (id) => async (dispatch) => {
  try {
    dispatch(reloadCategory());
    const res = await categoryService.getCategoryById(id);
    dispatch({
      type: GET_CATEGORY_BYID,
      payload: res.data.data,
    });
    dispatch(reloadCategory());
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getCategoryPageNo = (pageNo) => async (dispatch) => {
  try {
    dispatch(reloadCategory());
    const res = await categoryService.getCategoryPageNo(pageNo);
    dispatch({
      type: GET_ALL_CATEGORY,
      payload: res.data.data,
    });
    dispatch(reloadCategory());
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const UpdateCategory = (id, data, pageNo) => async (dispatch) => {
  try {
    const res = await categoryService.UpdateCategory(id, data);
    dispatch({
      type: UPDATE_CATEGORY,
      payload: data,
    });
    dispatch(showSuccessSnackbar("Ticket updated created successfully"));
    dispatch(getCategoryPageNo(pageNo));
    dispatch(openCategoryDialog());
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteCategory = (id, pageNo) => async (dispatch) => {
  try {
    const res = await categoryService.deleteCategory(id);
    dispatch({
      type: DELETE_CATEGORY,
      payload: id,
    });
    dispatch(showSuccessSnackbar("Ticket deleted created successfully"));
    dispatch(getCategoryPageNo(pageNo));
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
