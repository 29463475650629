import {
  FAQ_RESET_FORM,
  FAQ_GET_BY_ID,
  FAQ_PAGE_NUMBER,
  FAQ_OPEN_DIALOG,
  FAQ_RELOAD,
  FAQ_GET_ALL,
  FAQ_GET_ALL_LIST,
  FAQ_SEARCH_BODY,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 1,
  pageSize: 10,
  searchBody: {
    pageSize: 15,
    question: "",
    answer: "",
    isActive: null,
    type: 2,
  },
  form: {
    id: null,
    questionAr: "",
    questionEn: "",
    questionKu: "",
    answerEn: "",
    answerAr: "",
    answerKu: "",
    type: null,
    isActive: true,
  },
  FAQData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  FAQDataList: [],
  getById: {},
};

function FAQReducer(FAQ = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FAQ_GET_ALL:
      FAQ.FAQData.data = payload.data;
      FAQ.FAQData.total = payload.pageCount;
      FAQ.FAQData.rowCount = payload.rowCount;
      return FAQ;

    case FAQ_GET_ALL_LIST:
      FAQ.FAQDataList = payload;
      return FAQ;

    case FAQ_GET_BY_ID:
      FAQ.form = payload;
      return FAQ;

    case FAQ_RESET_FORM:
      FAQ.form.id = null;
      FAQ.form.questionEn = "";
      FAQ.form.questionAr = "";
      FAQ.form.questionKu = "";
      FAQ.form.answerAr = "";
      FAQ.form.answerEn = "";
      FAQ.form.answerKu = "";
      return FAQ;

    case FAQ_PAGE_NUMBER:
      return { ...FAQ, pageNo: payload };

    case FAQ_SEARCH_BODY:
      return { ...FAQ, searchBody: payload };

    case FAQ_OPEN_DIALOG:
      return {
        ...FAQ,
        openDialog: !FAQ.openDialog,
      };

    case FAQ_RELOAD:
      return { ...FAQ, reload: !FAQ.reload };

    default:
      return FAQ;
  }
}

export default FAQReducer;
