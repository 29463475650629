import {
  Card,
  CardHeader,
  Divider,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { newCustomerPerDay } from "src/actions/reports";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment from "moment/moment";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";

export default function NewCustomerPerDay(props) {
  const dispatch = new useDispatch();
  const [loading, setLoading] = useState(false);
  const [newCustomer, setNewCustomer] = useState([]);

  useEffect(() => {
    GetNewCustomerPerDay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date, props.setSearch]);

  function GetNewCustomerPerDay() {
    setLoading(true);
    dispatch(
      newCustomerPerDay({
        startDate: props.date.startDate,
        endDate: props.date.endDate,
      })
    ).then((res) => {
      setNewCustomer(res.data);
      setLoading(false);
    });
  }

  function CustomToolbar() {
    return (
      <div>
        <Box>
          <Box>
            <Card>
              <CardHeader
                title="New Customer Per Day  Report "
                subheader={
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption">
                      {props.date.startDate + " - " + props.date.endDate}
                    </Typography>
                  </Stack>
                }
              />
              <Divider />
            </Card>
          </Box>
          <Box>
            <GridToolbarContainer>
              <GridToolbarExport
                csvOptions={{
                  fileName: `New Customer Per Day  Report || Orderii || ${
                    props.date.startDate + " - " + props.date.endDate
                  }`,
                  utf8WithBom: true,
                }}
              />
            </GridToolbarContainer>
          </Box>
        </Box>
      </div>
    );
  }

  const columns = [
    {
      field: "#",
      flex: 0.3,
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "count",
      headerName: "Count",
      flex: 1,
    },
  ];

  return (
    <Card style={{ marginTop: 10 }} ref={props.print}>
      <PerfectScrollbar>
        <Box sx={{ height: "calc(100vh - 320px)" }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-row ": {
                textAlign: "center",
              },
              "& .css-204u17-MuiDataGrid-main": {
                paddingLeft: "15px",
              },
              "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                paddingLeft: "15px",
              },
            }}
            columns={columns}
            loading={loading}
            rows={newCustomer.map((data, index) => {
              return {
                id: index + 1,
                "#": index + 1,
                date: moment(data.date).format("YYYY-MM-DD"),
                count: data.count,
              };
            })}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
