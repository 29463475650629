import axiosService from "src/api/axiosService";

class UnknownItemsService {
  create(data) {
    return axiosService.post(`/api/unknownitems`, data);
  }

  getById(id) {
    return axiosService.get(`/api/unknownitems/getbyid/${id}`);
  }

  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axiosService.get(`api/unknownitems/${pageNo}?${params}`);
  }

  update(id, data) {
    return axiosService.put(`/api/unknownitems/${id}`, data);
  }

  delete(id) {
    return axiosService.delete(`/api/unknownitems/${id}`);
  }

  updateStatus(id, status) {
    return axiosService.put(`api/unknownitems/${id}/${status}`);
  }
}

export default new UnknownItemsService();
