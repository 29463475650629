import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "src/actions/orderCancelReason";
import { MdCancelScheduleSend } from "react-icons/md";
import RolePermission from "src/components/RolePermission";
import OrderCancelReasonForm from "src/pages/cross/Configuration/reasons/OrderCancelReasonsContent/form";

const OrderCancelReasonsHeader = () => {
  const dispatch = useDispatch();

  const _OrderCancelReason = useSelector(
    (state) => state.orderCancelReason.openDialog
  );
  return (
    <>
      <Helmet>
        <title>Order Cancel Reasons | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Order Cancel Reasons
              </Typography>
            }
            avatar={<MdCancelScheduleSend size={20} />}
            action={
              <RolePermission permission="21-01">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => dispatch(openDialog())}
                >
                  CREATE
                </Button>
              </RolePermission>
            }
          />
          {_OrderCancelReason && <OrderCancelReasonForm />}
        </Card>
      </Box>
    </>
  );
};

export default OrderCancelReasonsHeader;
