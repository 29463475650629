import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, LinearProgress, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import {
  getAllAgentCityConfig,
  getAllTransferList,
  openTransferListDialog,
  setAgentId,
} from "src/actions/agentCityConfig";
import { getAgents } from "src/actions/agentrequests";
import { getAllAddresses } from "src/actions/addresses";
import { BsFillPinMapFill } from "react-icons/bs";
import CityTransferList from "src/pages/cross/Configuration/agentCityConfig/agents/home/transferList";
import RolePermission from "src/components/RolePermission";

export default function AgentCityConfigTable() {
  const dispatch = useDispatch();

  const _AgentCityConfig = useSelector(
    (state) => state.agentCityConfig.agentCityConfigData
  );
  const _AgentCityConfigNewData = useSelector(
    (state) => state.agentCityConfig.agentCityConfigNewData
  );
  const _AgentCityConfigTransferList = useSelector(
    (state) => state.agentCityConfig.transferList.dialog
  );
  const reload = useSelector((state) => state.agentCityConfig.reload);

  useEffect(() => {
    dispatch(getAgents());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllAddresses());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllAgentCityConfig());
  }, [dispatch]);

  const shareCenterToolbar = () => {
    return <Box>{_AgentCityConfigTransferList && <CityTransferList />}</Box>;
  };

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "agentName",
      headerName: "Agent",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "city",
      headerName: "Cities Count",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => params.row.assignedCities.length,
    },
    {
      field: "province",
      headerName: "Province",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) =>
        `${params.row.provinceNameUS} - ${params.row.provinceNameAR}`,
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <RolePermission permission="03-02">
          <Stack direction="row" spacing={1}>
            <IconButton
              color="primary"
              aria-label="delete"
              onClick={(e) => {
                dispatch(setAgentId(params.row.agentId));
                dispatch(getAllTransferList(params.row.agentId)).then(() =>
                  dispatch(openTransferListDialog())
                );
              }}
            >
              <BsFillPinMapFill />
            </IconButton>
          </Stack>
        </RolePermission>
      ),
    },
  ];

  return (
    <Card>
      {shareCenterToolbar()}
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050, minHeight: 600 }}>
          <DataGrid
            sx={{
              "& .css-204u17-MuiDataGrid-main": {
                paddingLeft: "15px",
              },
              "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                paddingLeft: "15px",
              },
              ".MuiDataGrid-cell": {
                justifyContent: "center",
              },
            }}
            loading={reload}
            autoHeight
            rows={_AgentCityConfigNewData}
            columns={columns}
            rowHeight={50}
            disableSelectionOnClick
            pagination
            rowCount={_AgentCityConfig.rowCount}
            paginationMode="client"
            rowsPerPageOptions={[100]}
            components={{
              LoadingOverlay: LinearProgress,
            }}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
