import { TextField, Grid, Stack, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { getAllListUserDeactivation } from "src/actions/userDeactivationReason";
import {
  changeStatus,
  getAllUsers,
  openUsersStatusDialog,
} from "src/actions/users";

const UserStatus = (props) => {
  const dispatch = useDispatch();

  const _UserDeactivations = useSelector(
    (state) => state.userDeactivation.userDeactivationDataList
  );
  const _UserStatus = useSelector((state) => state.users.form);
  const dialog = useSelector((state) => state.users.openStatusDialog);
  const userPageNO = useSelector((state) => state.users.saveUsersPageNum);
  const _searchBody = useSelector((state) => state.users.searchUsersBody);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: _UserStatus,
  });

  useEffect(() => {
    dispatch(getAllListUserDeactivation());
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(
      changeStatus(
        _UserStatus.id,
        !_UserStatus.isActive,
        _UserStatus.isActive ? data.userDeactivationReasonId : ""
      )
    ).then((x) => {
      if (x.message === "Success") {
        dispatch(
          getAllUsers(userPageNO + 1, _searchBody.pageSize, _searchBody)
        );
        dispatch(openUsersStatusDialog());
      }
    });
  };

  const handleDialog = () => {
    dispatch(openUsersStatusDialog());
  };

  return (
    <CustomDialog
      title="Status Dialog"
      isOpen={dialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {!props.loading && (
              <Controller
                name="userDeactivationReasonId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    disabled={!_UserStatus.isActive}
                    options={_UserDeactivations}
                    getOptionLabel={(option) =>
                      `${option.nameEN} - ${option.nameAR}`
                    }
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Deactivation Reason"
                      />
                    )}
                    onChange={(event, value) => {
                      setValue(
                        "userDeactivationReasonId",
                        value ? value.id : null
                      );
                    }}
                    value={
                      _UserDeactivations.filter(
                        (x) => x.id === field.value
                      )[0] ?? null
                    }
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={props.loading}
              >
                {_UserStatus.isActive ? "Deactivate User" : "Activate User"}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default UserStatus;
