import {
  CONTACT_INFO_RESET_FORM,
  CONTACT_INFO_GET_BY_ID,
  CONTACT_INFO_OPEN_DIALOG,
  CONTACT_INFO_RELOAD,
  CONTACT_INFO_GET_ALL,
  CONTACT_INFO_SEARCH_BODY,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  pageSize: 10,
  searchBody: {
    pageSize: 15,
    name: "",
    value: "",
  },
  form: {
    id: null,
    name: "",
    value: "",
  },
  contactInfoData: [],
  contactInfoDataList: [],
  getById: {},
};

function contactInfoReducer(contactInfo = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CONTACT_INFO_GET_ALL:
      contactInfo.contactInfoData = payload.data;
      return contactInfo;

    case CONTACT_INFO_GET_BY_ID:
      contactInfo.form = payload;
      return contactInfo;

    case CONTACT_INFO_RESET_FORM:
      contactInfo.form.id = null;
      contactInfo.form.value = "";
      contactInfo.form.name = "";
      return contactInfo;

    case CONTACT_INFO_SEARCH_BODY:
      return { ...contactInfo, searchBody: payload };

    case CONTACT_INFO_OPEN_DIALOG:
      return {
        ...contactInfo,
        openDialog: !contactInfo.openDialog,
      };

    case CONTACT_INFO_RELOAD:
      return { ...contactInfo, reload: !contactInfo.reload };

    default:
      return contactInfo;
  }
}

export default contactInfoReducer;
