import axios from "../axios";

class FavoriteService {
  getAllFavorite(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] ?? ""}&`;
      });
    }
    return axios.get(`giftfavourites/${pageNo}?${params}`);
  }
}

export default new FavoriteService();
