import {
  USER_ADDRESSES_PAGE_NUMBER,
  USER_ADDRESSES_RELOAD,
  USER_ADDRESSES_GET_ALL,
  USER_ADDRESSES_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  pageSize: 10,
  userAddressesData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  searchBody: {
    pageSize: 15,
    status: null,
    name: "",
    cityId: null,
    provinceId: null,
    isActive: null,
  },
};

function userAddressesReducer(userAddresses = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_ADDRESSES_GET_ALL:
      userAddresses.userAddressesData.data = payload.data;
      userAddresses.userAddressesData.total = payload.pageCount;
      userAddresses.userAddressesData.rowCount = payload.rowCount;
      return userAddresses;

    case USER_ADDRESSES_PAGE_NUMBER:
      return { ...userAddresses, pageNo: payload };

    case USER_ADDRESSES_SEARCH_BODY:
      return { ...userAddresses, searchBody: payload };

    case USER_ADDRESSES_RELOAD:
      return { ...userAddresses, reload: !userAddresses.reload };

    default:
      return userAddresses;
  }
}

export default userAddressesReducer;
