import { Grid, Box } from "@mui/material";
import React from "react";
import GiftItemsHeader from "./home/header/header";
import GiftItemsTable from "src/pages/gifts/item/home/table";

const GiftItems = () => {
  return (
    <Box sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
      <Grid container>
        <Grid item xs={12} sx={{ p: 2 }}>
          <GiftItemsHeader />
        </Grid>
        <Grid item xs={12} sx={{ p: 2, paddingTop: 0 }}>
          <GiftItemsTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GiftItems;
