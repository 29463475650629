import axios from "../axios";

class ContactInfoService {
  getAll() {
    return axios.get(`/contactinfo/`);
  }

  getById(id) {
    return axios.get(`/contactinfo/getbyid/${id}`);
  }

  update(id, data) {
    return axios.put(`/contactinfo/${id}`, data);
  }
}

export default new ContactInfoService();
