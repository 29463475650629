import {
  Card,
  CardHeader,
  Divider,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { walletsnapshot } from "src/actions/reports";
import CustomSpinner from "src/components/CustomSpinner";
import PerfectScrollbar from "react-perfect-scrollbar";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { red } from "@mui/material/colors";
import moment from "moment/moment";

export default function WalletSnapshot(props) {
  const dispatch = new useDispatch();
  const [loading, setLoading] = useState(false);
  const [walletSnapshot, setWalletSnapshot] = useState([]);

  useEffect(() => {
    walletSnapshotReport();
  }, [props.date, props.setSearch]);

  function walletSnapshotReport() {
    setLoading(true)
    dispatch(
      walletsnapshot({
        to: moment(props.date.endDate).format("YYYY-MM-DD"),
      })
    ).then((res) => {
      setWalletSnapshot(res.data);
      setLoading(false)
    }).catch((err) => {
      setLoading(false);
      setWalletSnapshot([]);
    })
  }

  const colums = [
    {
      field: 'sequence',
      headerName: "#",
      flex: 0.3,
    },
    {
      field: 'username',
      headerName: "User Name",
      flex: 1,
    },
    {
      field: 'phoneNumber',
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: 'walletType',
      headerName: "Wallet Type",
      flex: 1,
    },
    {
      field: 'amount',
      headerName: "Amount ($)",
      flex: 1,
    },
  ]


  function CustomToolbar() {
    return (
      <div>
        <Box>
          <Box>
            <Card>
              <CardHeader
                title="Wallet Snapshot"
                subheader={
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption">
                      {moment(props.date.endDate).format("YYYY-MM-DD")}
                    </Typography>
                  </Stack>
                }
              />
              <Divider />
            </Card>
          </Box>
          <Box>
            <GridToolbarContainer>
              <GridToolbarExport
                printOptions={{
                  disableToolbarButton: true,
                  hideFooter: true,
                  hideToolbar: true,
                }}
                csvOptions={{
                  fileName: `Wallet Snapshot Report || Orderii || ${moment(props.date.endDate).format("YYYY-MM-DD")}`,
                  utf8WithBom: true,
                }}
              />
            </GridToolbarContainer>
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <Card style={{ marginTop: 10 }} ref={props.print}>
      <PerfectScrollbar>
        <Box>
          {loading ? (
            <CustomSpinner />
          ) : (
            <Box>
              {!walletSnapshot ? (
                <Box sx={{ textAlign: "center", marginY: 2 }}>
                  <Typography sx={{ color: red[600] }}>
                    <DangerousOutlinedIcon fontSize="large" />
                  </Typography>
                  <Typography>No Data</Typography>
                </Box>
              ) : (
                <Box sx={{ height: 'calc(100vh - 320px)' }}>
                  <DataGrid
                    sx={{
                      "& .MuiDataGrid-row ": {
                        textAlign: "center",
                      },
                      "& .css-204u17-MuiDataGrid-main": {
                        paddingLeft: "15px",
                      },
                      "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                        paddingLeft: "15px",
                      },
                    }}
                    columns={colums}
                    rows={walletSnapshot.filter((row) => row.amount !== 0).map((row, index) => {
                      return { ...row, sequence: index + 1 };
                    })}
                    getRowId={(params) => params.sequence}
                    disableSelectionOnClick
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
