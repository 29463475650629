import {
  OPEN_UNKNOWN_ITEMS_DIALOG,
  GET_BY_ID_UNKNOWN_ITEMS,
  GET_ALL_UNKNOWN_ITEMS,
  SAVE_UNKNOWN_ITEMS_PAGE_NO,
  RESET_UNKNOWN_ITEMS_FORM,
  RELOAD_UNKNOWN_ITEMS,
  SAVE_UNKNOWN_ITEMS_SEARCH_BODY,
} from "../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 0,
  form: {
    id: null,
    name: "",
    note: "",
    quantity: "",
    price: "",
    attachment: null,
    attachmentUrl: null,
  },
  data: {
    data: [],
    rowCount: 0,
    pageCount: 0,
  },
  searchBody: {
    pageSize: 15,
    name: "",
    sku: "",
    status: null,
    fromCreatedAt: "",
    toCreatedAt: "",
  },
  getById: {},
};

function unknownItemsReducer(unknownItems = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_UNKNOWN_ITEMS:
      unknownItems.data.data = payload.data;
      unknownItems.data.pageCount = payload.pageCount;
      unknownItems.data.rowCount = payload.rowCount;
      return unknownItems;

    case GET_BY_ID_UNKNOWN_ITEMS:
      unknownItems.form = {
        ...payload,
        attachment: payload.attachment?.id,
        attachmentUrl: payload.attachment?.url,
      };
      return unknownItems;

    case OPEN_UNKNOWN_ITEMS_DIALOG:
      return { ...unknownItems, openDialog: !unknownItems.openDialog };

    case SAVE_UNKNOWN_ITEMS_PAGE_NO:
      return { ...unknownItems, pageNo: payload };

    case RELOAD_UNKNOWN_ITEMS:
      return { ...unknownItems, reload: !unknownItems.reload };

    case SAVE_UNKNOWN_ITEMS_SEARCH_BODY:
      return { ...unknownItems, searchBody: payload };

    case RESET_UNKNOWN_ITEMS_FORM:
      unknownItems.form.id = null;
      unknownItems.form.name = "";
      unknownItems.form.quantity = "";
      unknownItems.form.attachment = null;
      unknownItems.form.attachmentUrl = null;
      unknownItems.form.note = "";
      return unknownItems;

    default:
      return unknownItems;
  }
}

export default unknownItemsReducer;
