import helper from "src/utils/helper";
import TransactionDataService from "src/api/services/transaction.service";
import { openAlertDialog, showErrorSnackbar } from "./snackbarMessages";

export const getAllTransaction =
  (userId, pageNo, filter) => async (dispatch) => {
    try {
      const res = await TransactionDataService.getAll(userId, pageNo, filter);
      return res.data.data;
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };
