import {
  GET_ALL_EMPLOYEE, UPDATE_EMPLOYEE, DELETE_EMPLOYEE, CREATE_EMPLOYEE, OPEN_EMPLOYEE_DIALOG, GET_EMPLOYEE_BYID
} from "../../actions/type";

const initialState = {
  data: [],
  getByID: {
    id: null,
    name: ''
  },
  dialog: false,
  reload: false
}

function employee(employee = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_EMPLOYEE:
      employee.data.push(payload);
      return employee;

    case OPEN_EMPLOYEE_DIALOG:
      return {
        ...employee,
        dialog: !payload
      }

    case GET_ALL_EMPLOYEE:
      return {
        ...employee,
        data: payload
      }

    case GET_EMPLOYEE_BYID:
      return {
        ...employee,
        getByID: payload
      }

    case UPDATE_EMPLOYEE:
      employee.data.filter(x => x.role == payload.role)
      return employee;

    case DELETE_EMPLOYEE:
      employee.data = employee.data.filter((x) => x.id !== payload);
      return employee;


    default:
      return employee;
  }
}

export default employee;