import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme,
} from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { Icon, IconButton, Stack } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import DateRangepicker from "src/components/DateRangePicker";

ChartJS.register(ArcElement, Tooltip, Legend);

const OrdersByStatus = (props) => {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const theme = useTheme();
  const [viewCalendar, setViewCalendar] = useState(false);

  const color = props.data.status?.map((status) =>
    status === 0
      ? "#ffa726"
      : status === 3
      ? "#d32f2f"
      : status === 2
      ? "#66bb6a"
      : status === 16
      ? "#d32f2f"
      : status === 14
      ? "#01579b"
      : status === 10
      ? "#0288d1"
      : status === 9
      ? "#9C27B0"
      : status === 12
      ? "#42a5f5"
      : status === 11
      ? "#7c4dff"
      : status === 13
      ? "#ce93d8"
      : status === 15
      ? "#1b5e20"
      : status === 19
      ? "#2e7d32"
      : status === 17
      ? "#81c784"
      : status === 18
      ? "#81c784"
      : status === 1
      ? "#f57c00"
      : status === 8
      ? "#009688"
      : null
  );

  const data = {
    datasets: [
      {
        data: props.data.data,
        backgroundColor: color,
        borderWidth: 2,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: props.data.labels,
  };

  const options = {
    animation: true,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card>
      <CardHeader
        title="Orders by status"
        subheader={
          <Stack direction="column" spacing={1}>
            <Typography variant="caption">
              {props.data.fromDate + " - " + props.data.toDate}
            </Typography>
            {viewCalendar && (
              <Box sx={{ width: { md: "70%", xs: "100%" } }}>
                <DateRangepicker
                  startDate={props.data.fromDate}
                  endDate={props.data.toDate}
                  handleOnChange={(searchDate) => {
                    const updatedFilter = {
                      from: moment(searchDate[0].startDate).format(
                        "YYYY-MM-DD"
                      ),
                      to: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
                    };
                    if (
                      updatedFilter.from !== props.data.fromDate ||
                      updatedFilter.to !== props.data.toDate
                    ) {
                      props.onDateChanged({
                        from: moment(searchDate[0].startDate).format(
                          "YYYY-MM-DD"
                        ),
                        to: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
                      });
                    }
                  }}
                />
              </Box>
            )}
          </Stack>
        }
        action={
          <IconButton onClick={() => setViewCalendar(!viewCalendar)}>
            <Icon aria-label="settings">calendar_month</Icon>
          </IconButton>
        }
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: "relative",
          }}
        >
          <Doughnut data={data} options={options} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 2,
          }}
        >
          <Box
            sx={{
              pt: 1,
              textAlign: "center",
            }}
          >
            <Typography variant="h4">
              {`Total revenue : ${props.data.revenue.toLocaleString()} $`}
            </Typography>
            <Typography variant="h4">
              {`Total Orders : ${props.data.ordered.toLocaleString()}`}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default OrdersByStatus;
