import {
  GET_ALL_VENDORS,
  CHANGE_VENDOR_STATUS,
  UPDATE_PROPERTIES,
  ACTIVATE_TRANSLATION,
  OPEN_VENDOR_DIALOG,
  RELOAD_VENDORS,
  GET_BY_ID_VENDOR,
  RESET_FORM_VENDOR,
  GET_ALL_VENDORS_LIST,
  OPEN_VENDORS_CATEGORY_DIALOG,
  GET_BY_ID_VENDORS_CATEGORY,
  RELOAD_VENDORS_CATEGORY,
  GET_VENDORS_CATEGORY_LIST,
  GET_ALL_VENDORS_CATEGORY,
  SAVE_VENDORS_CATEGORY_PAGE_NO,
  SAVE_VENDORS_CATEGORY_SEARCH_BODY,
  RESET_VENDORS_CATEGORY_FORM,
  UPLOAD_ATTACHMENTS,
  OPEN_VENDOR_CATEGORY_DIALOG,
  OPEN_VENDORS_CATEGORIES_DIALOG,
  OPEN_CATEGORY_VENDOR_DIALOG,
} from "./type";

import VendorDataService from "../api/services/vendor.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import uploadAttachmentsService from "src/api/services/uploadAttachments.service";

export const createVendor = (data) => async (dispatch) => {
  try {
    const res = await VendorDataService.create(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(getAllList());
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllFixed = () => async (dispatch) => {
  try {
    const res = await VendorDataService.getAllFixed();
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllList = () => async (dispatch) => {
  try {
    dispatch(reloadVendors());
    const res = await VendorDataService.getAll();
    dispatch({
      type: GET_ALL_VENDORS,
      payload: res.data.data,
    });
    dispatch(reloadVendors());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadVendors());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const openDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_VENDOR_DIALOG,
  });
};

export const openCategoryVendorDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_CATEGORY_VENDOR_DIALOG,
  });
};

export const openVendorCategoryDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_VENDOR_CATEGORY_DIALOG,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: RESET_FORM_VENDOR,
  });
};

export const reloadVendors = () => (dispatch) => {
  dispatch({
    type: RELOAD_VENDORS,
  });
};

export const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(reloadVendors());
    const res = await VendorDataService.getById(id);
    dispatch({
      type: GET_BY_ID_VENDOR,
      payload: res.data.data,
    });
    dispatch(openDialog());
    dispatch(reloadVendors());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadVendors());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadVendors());
    const res = await VendorDataService.getById(id);
    dispatch({
      type: GET_BY_ID_VENDOR,
      payload: res.data.data,
    });
    dispatch(reloadVendors());
    // return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(reloadVendors());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateVendor = (data) => async (dispatch) => {
  try {
    const res = await VendorDataService.update(data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    dispatch(getAllList());
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateProperties = (data) => async (dispatch) => {
  try {
    await VendorDataService.updateProperties(data);
    dispatch({
      type: UPDATE_PROPERTIES,
      payload: data,
    });
    dispatch(showSuccessSnackbar("Updated successfully!"));
    dispatch(getAllList());
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const changeStatus = (id) => async (dispatch) => {
  try {
    await VendorDataService.changeStatus(id);
    dispatch({
      type: CHANGE_VENDOR_STATUS,
      payload: { id },
    });
    dispatch(showSuccessSnackbar("Update vendor status successfully"));
    dispatch(getAllList());
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const changeTranslationStatus = (id) => async (dispatch) => {
  try {
    await VendorDataService.changetranslationstatus(id);
    dispatch({
      type: ACTIVATE_TRANSLATION,
      payload: { id },
    });
    dispatch(showSuccessSnackbar("Translation status changed successfully"));
    dispatch(getAllList());
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const uploadFile = (data) => async (dispatch) => {
  try {
    const res = await VendorDataService.uploadFile(data);
    dispatch(showSuccessSnackbar("Upload successfully!"));
    dispatch(getAllList());
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteVendor = (id) => async (dispatch) => {
  try {
    const res = await VendorDataService.delete(id);
    dispatch(showSuccessSnackbar("Vendor deleted successfully!"));
    dispatch(getAllList());
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllVendors = () => async (dispatch) => {
  try {
    dispatch(reloadVendors());
    const res = await VendorDataService.getAllVendors();
    dispatch({
      type: GET_ALL_VENDORS_LIST,
      payload: res.data.data,
    });
    dispatch(reloadVendors());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadVendors());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateVendorCategories = (id, data) => async (dispatch) => {
  try {
    dispatch(reloadVendors());
    await VendorDataService.updateVendorCategory(id, data);
    dispatch(getAllList());
    dispatch(reloadVendors());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadVendors());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
////////VendorsCategory///////////////

export const openVendorsCategoryDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_VENDORS_CATEGORY_DIALOG,
  });
};

export const openVendorsCategoriesDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_VENDORS_CATEGORIES_DIALOG,
  });
};

export const createVendorCategory = (data, searchBody) => async (dispatch) => {
  try {
    const res = await VendorDataService.createVendorsCategory(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(
      getAllVendorsCategory(
        searchBody.pageNo,
        searchBody.pageSize,
        searchBody.name
      )
    );
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteVendorsCategory = (id, searchBody) => async (dispatch) => {
  try {
    const res = await VendorDataService.deleteVendorsCategory(id);
    dispatch(showSuccessSnackbar("Vendor category deleted successfully!"));
    dispatch(
      getAllVendorsCategory(
        searchBody.pageNo,
        searchBody.pageSize,
        searchBody.name
      )
    );
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const reloadVendorsCategory = () => (dispatch) => {
  dispatch({
    type: RELOAD_VENDORS_CATEGORY,
  });
};

export const getVendorsCategoryById = (id, openDialog) => async (dispatch) => {
  try {
    dispatch(reloadVendorsCategory());
    const res = await VendorDataService.getVendorsCategoryById(id);
    dispatch({
      type: GET_BY_ID_VENDORS_CATEGORY,
      payload: res.data.data,
    });
    if (openDialog) dispatch(openVendorsCategoryDialog());
    dispatch(reloadVendorsCategory());
    // return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(reloadVendorsCategory());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllVendorsCategory =
  (pageNO, pageSize, name) => async (dispatch) => {
    try {
      dispatch(reloadVendorsCategory());
      const res = await VendorDataService.getVendorsCategoryAll(
        pageNO,
        pageSize,
        name
      );
      dispatch({
        type: GET_ALL_VENDORS_CATEGORY,
        payload: res.data.data,
      });
      dispatch(reloadVendorsCategory());
      // return Promise.resolve(res.data.data)
    } catch (err) {
      dispatch(reloadVendorsCategory());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getVendorsCategoryList = () => async (dispatch) => {
  try {
    // dispatch(reloadVendors());
    dispatch(reloadVendorsCategory());
    const res = await VendorDataService.getVendorsCategoryList();
    dispatch({
      type: GET_VENDORS_CATEGORY_LIST,
      payload: res.data.data,
    });
    // dispatch(reloadVendors());
    dispatch(reloadVendorsCategory());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    // dispatch(reloadVendors());
    dispatch(reloadVendorsCategory());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const saveVendorsCategoryPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_VENDORS_CATEGORY_PAGE_NO,
    payload: data,
  });
};

export const saveVendorsCategorySearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_VENDORS_CATEGORY_SEARCH_BODY,
    payload: data,
  });
};

export const resetVendorsCategoryForm = () => (dispatch) => {
  dispatch({
    type: RESET_VENDORS_CATEGORY_FORM,
  });
};

export const updateVendorsCategory =
  (id, data, searchBody) => async (dispatch) => {
    try {
      const res = await VendorDataService.updateVendorsCategory(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(
        getAllVendorsCategory(
          searchBody.pageNo,
          searchBody.pageSize,
          searchBody.name
        )
      );
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const uploadAttachments = (data) => async (dispatch) => {
  try {
    dispatch(reloadVendorsCategory());
    const res = await uploadAttachmentsService.uploadAttachments(data);
    dispatch(showSuccessSnackbar("Uploaded successfully"));
    dispatch({
      type: UPLOAD_ATTACHMENTS,
      data: res.data,
    });
    dispatch(reloadVendorsCategory());
    // return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadVendorsCategory());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const assignVendorCategories = (id, data) => async (dispatch) => {
  try {
    dispatch(reloadVendors());
    await VendorDataService.assignVendorCategory(id, data);
    dispatch(showSuccessSnackbar("Updated successfully"));
    dispatch(getAllList());
    dispatch(reloadVendors());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadVendors());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
