import helper from "src/utils/helper";
import {
  ITEM_SUPPLIER_RESET_FORM,
  ITEM_SUPPLIER_GET_BY_ID,
  ITEM_SUPPLIER_PAGE_NUMBER,
  ITEM_SUPPLIER_OPEN_DIALOG,
  ITEM_SUPPLIER_RELOAD,
  ITEM_SUPPLIER_GET_ALL,
  ITEM_SUPPLIER_SEARCH_BODY,
  ITEM_SUPPLIER_GET_ALL_LIST,
} from "src/actions/gifts/giftTypes";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";
import itemSupplierService from "src/api/giftServices/supplier.service";

export const openItemSupplierDialog = () => (dispatch) => {
  dispatch({
    type: ITEM_SUPPLIER_OPEN_DIALOG,
  });
};

export const saveItemSupplierPageNum = (data) => (dispatch) => {
  dispatch({
    type: ITEM_SUPPLIER_PAGE_NUMBER,
    payload: data,
  });
};

export const saveItemSupplierSearchBody = (data) => (dispatch) => {
  dispatch({
    type: ITEM_SUPPLIER_SEARCH_BODY,
    payload: data,
  });
};

export const resetItemSupplierForm = () => (dispatch) => {
  dispatch({
    type: ITEM_SUPPLIER_RESET_FORM,
  });
};

export const createItemSupplier =
  (data, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await itemSupplierService.createSupplier(data);
      dispatch(showSuccessSnackbar("Created successfully"));
      dispatch(openItemSupplierDialog());
      dispatch(getAllItemSupplier(pageNo, searchBody));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteItemSupplier =
  (id, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await itemSupplierService.deleteSupplier(id);
      dispatch(showSuccessSnackbar("Gift category deleted successfully!"));
      dispatch(getAllItemSupplier(pageNo, searchBody));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const reloadItemSupplier = () => (dispatch) => {
  dispatch({
    type: ITEM_SUPPLIER_RELOAD,
  });
};

export const getItemSupplierById = (id, openDialog) => async (dispatch) => {
  try {
    dispatch(reloadItemSupplier());
    const res = await itemSupplierService.getSupplierById(id);
    dispatch({
      type: ITEM_SUPPLIER_GET_BY_ID,
      payload: res.data.data,
    });
    if (openDialog) dispatch(openItemSupplierDialog());
    dispatch(reloadItemSupplier());
    // return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(reloadItemSupplier());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllItemSupplier = (pageNO, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadItemSupplier());
    const res = await itemSupplierService.getSupplierAll(pageNO, searchBody);
    dispatch({
      type: ITEM_SUPPLIER_GET_ALL,
      payload: res.data.data,
    });
    dispatch(reloadItemSupplier());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadItemSupplier());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getItemSupplierList = () => async (dispatch) => {
  try {
    dispatch(reloadItemSupplier());
    const res = await itemSupplierService.getSupplierList();
    dispatch({
      type: ITEM_SUPPLIER_GET_ALL_LIST,
      payload: res.data.data,
    });
    dispatch(reloadItemSupplier());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadItemSupplier());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateItemSupplier =
  (id, data, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await itemSupplierService.updateSupplier(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(openItemSupplierDialog());
      dispatch(getAllItemSupplier(pageNo, searchBody));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };
