import axios from "../axios";

class PurchasingBillService {
  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/purchasingbill/${pageNo}?${params}`);
  }
  getById(id) {
    return axios.get(`/purchasingbill/getById/${id}`);
  }

  create(data) {
    return axios.post(`/purchasingbill`, data, {
      headers: {
        Accept: "text/plain",
      },
    });
  }

  update(id, data) {
    return axios.put(`/purchasingbill/${id}`, data, {
      headers: {
        Accept: "text/plain",
      },
    });
  }

  delete(id) {
    return axios.delete(`/purchasingbill/${id}`);
  }

  changeStatus(id, status) {
    return axios.put(`/purchasingbill/${id}/${status}`);
  }

  exportToCsv(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/purchasingbill/export?${params}`);
  }
}
export default new PurchasingBillService();
