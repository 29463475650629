import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  TextField,
  Tooltip,
  Stack,
  Grid,
  CardContent,
  LinearProgress,
  InputAdornment,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import {
  getAllReferralCode,
  saveReferralCodePageNO,
  saveReferralCodeSearchBody,
} from "src/actions/ReferralCode";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";

export default function ReferralCodeTable() {
  const _ReferralCode = useSelector((state) => state.referralCode);
  const loading = useSelector((state) => state.referralCode.reload);
  const _SearchBody = useSelector((state) => state.referralCode.searchBody);

  const [searchName, setSearchName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllReferralCode(_ReferralCode.pagNo + 1, _SearchBody));
  }, [_ReferralCode.pagNo, _SearchBody]);

  const handlePageChange = (newPage) => {
    dispatch(saveReferralCodePageNO(newPage));
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(
      saveReferralCodeSearchBody({
        ..._SearchBody,
        pageSize: newPageSize,
      })
    );
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "username",
      headerName: "User Name",
      flex: 1,
    },
    {
      field: "userPhoneNumber",
      headerName: "User Phone Number",
      flex: 1,
    },
    {
      field: "code",
      headerName: "Code",
      flex: 1,
    },
    {
      field: "useCount",
      headerName: "Use Count",
      flex: 1,
    },
  ];

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Code Name"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  dispatch(
                    saveReferralCodeSearchBody({
                      ..._SearchBody,
                      codeName: searchName,
                      phoneNumber: phoneNumber,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
              placeholder="Search Phone Number"
              sx={{ marginRight: 1 }}
              variant="outlined"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  dispatch(
                    saveReferralCodeSearchBody({
                      ..._SearchBody,
                      codeName: searchName,
                      phoneNumber: phoneNumber,
                    })
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" spacing={2} sx={{ justifyContent: "start" }}>
              <Tooltip title="Search with filter">
                <IconButton
                  onClick={() => {
                    dispatch(
                      saveReferralCodeSearchBody({
                        ..._SearchBody,
                        codeName: searchName,
                        phoneNumber: phoneNumber,
                      })
                    );
                  }}
                >
                  <AiOutlineSearch />
                </IconButton>
              </Tooltip>
              <Tooltip title="Clear filter">
                <IconButton
                  onClick={() => {
                    dispatch(
                      saveReferralCodeSearchBody({
                        pageSize: 15,
                        codeName: "",
                        phoneNumber: "",
                      })
                    );
                    setSearchName("");
                    setPhoneNumber("");
                    dispatch(saveReferralCodePageNO(0));
                  }}
                >
                  <AiOutlineClose />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box sx={{ height: { md: "calc(100vh - 270px)", xs: 1000 } }}>
                <DataGrid
                  columns={columns}
                  rows={_ReferralCode?.data.map((x, index) => ({
                    ...x,
                    index: index + 1,
                  }))}
                  loading={loading}
                  getRowId={(params) => params.codeId}
                  disableSelectionOnClick
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  rowCount={_ReferralCode?.rowCount}
                  pageSize={_SearchBody.pageSize}
                  page={_ReferralCode.pagNo}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    handleLimitChange(newPageSize)
                  }
                  rowsPerPageOptions={[15, 25, 75, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
