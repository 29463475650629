import shippingOptionsOptionsService from "src/api/services/shippingOption.service";
import helper from "src/utils/helper";
import {
  GET_ALL_SHIPPING_OPTIONS,
  GET_BY_ID_SHIPPING_OPTIONS,
  GET_SHIPPING_OPTIONS_LIST,
  OPEN_SHIPPING_OPTIONS_DIALOG,
  OPEN_SHIPPING_OPTIONS_FORM_DIALOG,
  RELOAD_SHIPPING_OPTIONS,
  RESET_SHIPPING_OPTIONS_FORM,
  SAVE_SHIPPING_OPTIONS_PAGE_NO,
  SAVE_SHIPPING_OPTIONS_SEARCH_BODY,
} from "src/actions/type";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";
import { getAllList } from "src/actions/vendors";

export const openShippingOptionsFormDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_SHIPPING_OPTIONS_FORM_DIALOG,
  });
};

export const openShippingOptionsDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_SHIPPING_OPTIONS_DIALOG,
    payload: data,
  });
};

export const saveShippingOptionsPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SHIPPING_OPTIONS_PAGE_NO,
    payload: data,
  });
};

export const saveShippingOptionsSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SHIPPING_OPTIONS_SEARCH_BODY,
    payload: data,
  });
};

export const resetShippingOptionsForm = () => (dispatch) => {
  dispatch({
    type: RESET_SHIPPING_OPTIONS_FORM,
  });
};

export const reloadShippingOptions = () => (dispatch) => {
  dispatch({
    type: RELOAD_SHIPPING_OPTIONS,
  });
};

export const createShippingOptions = (data, vendor) => async (dispatch) => {
  try {
    dispatch(reloadShippingOptions());
    const res = await shippingOptionsOptionsService.create(data);
    dispatch(showSuccessSnackbar("Created successfully"));
    dispatch(getAllShippingOptionsByVendor(vendor.id));
    dispatch(getAllList());
    dispatch(openShippingOptionsFormDialog());
    dispatch(reloadShippingOptions());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadShippingOptions());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteShippingOptions = (id, vendorId) => async (dispatch) => {
  try {
    dispatch(reloadShippingOptions());
    const res = await shippingOptionsOptionsService.delete(id);
    dispatch(showSuccessSnackbar("Deleted successfully!"));
    dispatch(getAllShippingOptionsByVendor(vendorId));
    dispatch(getAllList());
    dispatch(reloadShippingOptions());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadShippingOptions());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getShippingOptionsById =
  (vendorId, openDialog) => async (dispatch) => {
    try {
      dispatch(reloadShippingOptions());
      const res = await shippingOptionsOptionsService.getById(vendorId);
      dispatch({
        type: GET_BY_ID_SHIPPING_OPTIONS,
        payload: res.data.data,
      });
      if (openDialog) dispatch(openShippingOptionsFormDialog());
      dispatch(reloadShippingOptions());
      // return Promise.resolve(res.data.data);
    } catch (err) {
      dispatch(reloadShippingOptions());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getAllShippingOptionsByVendor = (vendorId) => async (dispatch) => {
  try {
    dispatch(reloadShippingOptions());
    const res = await shippingOptionsOptionsService.getByVendor(vendorId);
    dispatch({
      type: GET_ALL_SHIPPING_OPTIONS,
      payload: res,
    });
    dispatch(reloadShippingOptions());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadShippingOptions());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getShippingOptionsList = () => async (dispatch) => {
  try {
    dispatch(reloadShippingOptions());
    const res = await shippingOptionsOptionsService.getList();
    dispatch({
      type: GET_SHIPPING_OPTIONS_LIST,
      payload: res.data.data,
    });
    dispatch(reloadShippingOptions());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadShippingOptions());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateShippingOptions = (id, data, vendor) => async (dispatch) => {
  try {
    dispatch(reloadShippingOptions());
    const res = await shippingOptionsOptionsService.update(id, data);
    dispatch(showSuccessSnackbar("Updated successfully!"));
    dispatch(getAllShippingOptionsByVendor(vendor.id));
    dispatch(getAllList());
    dispatch(openShippingOptionsFormDialog());
    dispatch(reloadShippingOptions());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadShippingOptions());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};
