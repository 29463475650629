import React, { useRef, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Barcode from "react-barcode";
import moment from "moment";
import { CustomDialog } from "src/components/CustomDialog";

const useStyles = makeStyles((theme) => ({
  mb: {
    marginBottom: "60px",
  },
  margin: {
    margin: "30px",
  },
  footerText: {
    fontSize: "18px",
    lineHeight: "0px",
  },
  footer: {
    borderBottomWidth: 3,
    backgroundColor: "black",
  },
  div: {
    fontSize: "26px",
  },
  h: {
    fontSize: "28px",
    fontWeight: "bold",
  },
  "@global": {
    "p, h4": {
      padding: "3px",
      color: "black",
    },
  },
  tableHeader: {
    textAlign: "center",
    backgroundColor: "#03a9f4",
    color: "white",
  },
}));

export default function GenerateLabel({ data = {} }) {
  const classes = useStyles();
  const printRef = useRef(null);

  const [openNoteDialog, setOpenNoteDialog] = useState(true);
  const [noteDialogText, setNoteDialogText] = useState("");

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: `@media print {
          @page {
            size: A4;
            margin: 10;
          }
        }`,
  });

  // useEffect(() => {
  //   handlePrint();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div>
      <CustomDialog
        isOpen={openNoteDialog}
        handleClose={() => setOpenNoteDialog(false)}
        title={""}
        maxWidth="sm"
        Draggable={true}
        dir="rtl"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">
              - الرجاء كتابة الملاحظات بالعربي الفصيح
            </Typography>
            <Typography variant="h4">- عدم الكتابة بخط اليد</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              label="Notes"
              value={noteDialogText}
              inputProps={{ maxLength: 100 }}
              onChange={(e) => {
                const lineCount = (e.target.value.match(/\n/g) || []).length;
                if (lineCount < 2) {
                  setNoteDialogText(e.target.value);
                }
              }}
              helperText={`${noteDialogText.length}/100`}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} sx={{ direction: "ltr" }}>
              <Button
                onClick={() => {
                  setOpenNoteDialog(false);
                }}
                color="primary"
                variant="contained"
                fullWidth
              >
                Done
              </Button>
              <Button
                onClick={() => {
                  setNoteDialogText("");
                }}
                color="warning"
                variant="contained"
                fullWidth
              >
                clear text
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </CustomDialog>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Button
          onClick={() => {
            handlePrint();
          }}
          color="primary"
          variant="contained"
          fullWidth
        >
          print
        </Button>
        <Button
          onClick={() => {
            setOpenNoteDialog(true);
          }}
          color="warning"
          variant="contained"
          fullWidth
        >
          Add Notes
        </Button>
      </Stack>
      <div ref={printRef} style={{ padding: "15px", direction: "rtl" }}>
        <Box
          sx={{
            position: "relative",
            height: "140mm",
            width: "140mm",
          }}
        >
          <Stack direction="column">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="start"
              sx={{ p: 2 }}
            >
              <Stack direction="column">
                <Box
                  component="img"
                  paddingBottom={4}
                  src="/static/images/logo/orderii_gifts_label.png"
                  width={100}
                />

                <Typography className={classes.h} variant="h4">
                  معلومات المرسل
                </Typography>
                <Typography className={classes.div}>
                  {data?.senderName}
                </Typography>
              </Stack>
              <img alt="QR Code" src="/static/images/QR.png" height="120px" />
            </Stack>

            <Stack direction="column" padding={2}>
              <Typography className={classes.h} variant="h4">
                معلومات المستلم
              </Typography>
              <Typography className={classes.div}>
                {data?.receiverName}
              </Typography>
              <Typography className={classes.div}>
                {"0" + data?.phoneNumber}
              </Typography>
              <Typography className={classes.div}>
                المحافظة: {data?.receiverAddress?.city?.province?.nameAR}
              </Typography>
              <Typography className={classes.div}>
                المدينة: {data?.receiverAddress?.city?.nameAR}
              </Typography>
              {data?.receiverAddress?.neighborhood ? (
                <Typography className={classes.div}>
                  الحي: {data?.receiverAddress?.neighborhood}
                </Typography>
              ) : null}
              {data?.receiverAddress?.nearestLocation ? (
                <Typography className={classes.div}>
                  اقرب نقطة دالة: {data?.receiverAddress?.nearestLocation}
                </Typography>
              ) : null}
              <Stack direction="row" alignItems="center" sx={{ mt: 1 }}>
                <Typography className={classes.h} variant="h4">
                  طريقة الدفع:
                </Typography>
                <Typography className={classes.div}>
                  {data?.paymentType === "Wallet And Cash On Delivery"
                    ? "Wallet And COD"
                    : data?.paymentType}{" "}
                </Typography>
              </Stack>
              {data?.remainingCODAmount > 0 ? (
                <Stack direction="row" alignItems="center">
                  <Typography className={classes.h} variant="h4">
                    المبلغ المتبقي:
                  </Typography>
                  <Typography className={classes.div}>
                    {data?.paymentType !== "Wallet"
                      ? data?.remainingCODAmount.toLocaleString() + " IQD"
                      : ""}
                  </Typography>
                </Stack>
              ) : null}
              {data?.orderAdditionalFees?.total > 0 ? (
                <Stack direction="row" alignItems="center">
                  <Typography className={classes.h} variant="h4">
                    مبالغ اضافية:
                  </Typography>
                  <Typography className={classes.div}>
                    {data?.orderAdditionalFees?.total?.toLocaleString()} IQD
                  </Typography>
                </Stack>
              ) : null}
              {data?.orderAdditionalFees?.total > 0 ||
              data?.remainingCODAmount > 0 ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ border: "1px solid #000", borderRadius: "8px" }}
                >
                  <Typography className={classes.h} variant="h4">
                    المجموع الكلي:
                  </Typography>
                  <Typography className={classes.div}>
                    {(
                      data?.remainingCODAmount +
                      data?.orderAdditionalFees?.total
                    )?.toLocaleString()}{" "}
                    IQD
                  </Typography>
                </Stack>
              ) : null}
              <Divider />
              <Stack direction="row" alignItems="center" sx={{ mt: 1 }}>
                <Typography className={classes.h} variant="h4">
                  تاريخ التوصيل:
                </Typography>
                <Typography className={classes.div}>
                  {moment(data?.deliveryDate).format("YYYY-MM-DD")}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography className={classes.h} variant="h4">
                  تاريخ طباعة الوصل:
                </Typography>
                <Typography className={classes.div}>
                  {moment(new Date()).format("YYYY-MM-DD")}
                </Typography>
              </Stack>
              <Divider />
              {noteDialogText.length > 0 ? (
                <Typography
                  className={classes.div}
                  style={{ wordWrap: "break-word", fontWeight: "bold" }}
                >
                  الملاحظات: {noteDialogText}
                </Typography>
              ) : null}
              <Divider />
            </Stack>
          </Stack>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography className={classes.h}>{data?.number}</Typography>
            <Barcode
              // width={0.8}
              height={40}
              displayValue={false}
              value={data?.number}
            />
          </Stack>
          <Typography variant="subtitle2" textAlign="center">
            www.orderii.co
          </Typography>
        </Box>
      </div>
    </div>
  );
}
