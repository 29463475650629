import { Helmet } from "react-helmet";
import { Box, Container, Grid } from "@mui/material";
import { Card, CardHeader, Typography } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import LastSevenDaysSummary from "src/pages/cross/Ticket/Statistics/List/lastSevenDaysSummary";
import TicketSubmitted from "src/pages/cross/Ticket/Statistics/List/submitted";
import TicketSummary from "src/pages/cross/Ticket/Statistics/List/summary";

const Statistic = () => {
  return (
    <>
      <Helmet>
        <title>Statistic | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false} sx={{ minWidth: { lg: "100%", xs: 1800 } }}>
          <Card>
            <CardHeader
              title={
                <Typography variant="h4" component="div">
                  Statistic
                </Typography>
              }
              avatar={<BarChartIcon size={20} />}
            />
          </Card>

          <Box sx={{ pt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <TicketSummary />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginY: 1 }}>
              <Grid item xs={6} md={6}>
                <LastSevenDaysSummary />
              </Grid>
              <Grid item xs={6} md={6}>
                <TicketSubmitted />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Statistic;
