import {
  GET_ALL_ORDER_ITEMS,
  SAVE_SEARCH_BODY_ITEMS,
  UPDATE_ORDER_STATUS,
  SAVE_ITEMS_PAGE_NUM,
  RELOAD_ORDER_ITEM,
} from "../actions/type";

const initialState = {
  searchBody: {
    pageSize: 10,
    sortByCreatedAtAsc: false,
    // itemName: "",
    // orderiiNumber: "",
    // trackingNumber: "",
    // invoiceNumber: "",
    // shippingNumber: "",
    // phoneNumber: "",
    // itemStatus: [],
    // region: null,
    // vendorIds: [],
    // isVip: null,
    // isWrongTrackingNumber: false,
    // hasPurchaseError: null,
    // isDamaged: false,
    // isDangerousGoods: false,
  },
  reload: false,
  pageCount: 0,
  rowCount: 0,
  pageSize: 10,
  saveItemsPageNum: 0,
  data: [],
  getById: {},
};

function orderReducer(orderItems = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_ORDER_ITEMS:
      orderItems.data = payload.data;
      orderItems.pageCount = payload.pageCount;
      orderItems.rowCount = payload.rowCount;
      return orderItems;

    case SAVE_SEARCH_BODY_ITEMS:
      return { ...orderItems, searchBody: payload };

    case SAVE_ITEMS_PAGE_NUM:
      orderItems.saveItemsPageNum = payload;
      return orderItems;

    case RELOAD_ORDER_ITEM:
      orderItems.reload = !orderItems.reload;
      return { ...orderItems };

    case UPDATE_ORDER_STATUS:
      var indexToChange = orderItems.data.indexOf(
        orderItems.data.filter((x) => x.id === payload.id)[0]
      );
      orderItems.data[indexToChange].status = payload.newStatus;
      return orderItems;

    default:
      return orderItems;
  }
}

export default orderReducer;
