import React, { useState, useEffect, useMemo } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  Grid,
  CardContent,
  LinearProgress,
  Typography,
  TextField,
  Icon,
  Autocomplete,
  Drawer,
  Divider,
  Stack,
  Badge,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { CustomDialog } from "src/components/CustomDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  getById,
  resetForm,
  savePageNo,
  getAllPurchasingBill,
  deletePurchasingBill,
  saveSearchBody,
  openDialog,
} from "src/actions/purchasingBill";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import PurchasingBillStatusMenu from "src/pages/cross/purchasingBill/components/menu";
import {
  purchasing_bill_banks,
  purchasing_bill_currency,
  purchasing_bill_status,
} from "src/utils/common";
import { getAllVendors } from "src/actions/vendors";
import { FaArrowDown, FaArrowUp, FaFilter } from "react-icons/fa";
import DateRangepicker from "src/components/DateRangePicker";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { changeFiltersType } from "src/actions/users";
import { Search as SearchIcon } from "react-feather";
import useCanShow from "src/components/CanShow";

export default function PurchasingBillTable() {
  const dispatch = useDispatch();

  const editRole = useCanShow("61-04");
  const deleteRole = useCanShow("61-06");

  const _PurchasingBills = useSelector((state) => state.purchasingBill.data);
  const _PurchasingBill = useSelector((state) => state.purchasingBill.form);
  const loading = useSelector((state) => state.purchasingBill.reload);
  const savePurchasingBillPageNO = useSelector(
    (state) => state.purchasingBill.savePurchasingBillPageNum
  );
  const _PageSize = useSelector((state) => state.purchasingBill.pageSize);
  const _SearchBody = useSelector((state) => state.purchasingBill.searchBody);
  const vendors = useSelector((state) => state.vendors.dataList);
  const filterType = useSelector((state) => state.users.filterType);

  const [searchBody, setSearchBody] = useState(_SearchBody);
  const [isSearch, setIsSearch] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [openSearch, setOpenSearch] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [showPanZoom, setShowPanZoom] = useState("");

  const toggleDrawer = (newOpen) => () => {
    setOpenSearch(newOpen);
  };

  const activeFilters = useMemo(() => {
    if (
      searchBody.billNo === "" &&
      searchBody.fromDate === null &&
      searchBody.toDate === null &&
      searchBody.vendorId === null &&
      searchBody.region === null &&
      searchBody.status === null &&
      searchBody.currency === null &&
      searchBody.bank === null
    ) {
      return 0;
    } else {
      const truthyValuesCount = {
        billNo: searchBody.billNo.length > 0 ? 1 : 0,
        fromDate: searchBody.fromDate !== null ? 1 : 0,
        toDate: searchBody.toDate !== null ? 1 : 0,
        vendorId: searchBody.vendorId !== null ? 1 : 0,
        region: searchBody.region !== null ? 1 : 0,
        status: searchBody.status !== null ? 1 : 0,
        currency: searchBody.currency !== null ? 1 : 0,
        bank: searchBody.bank !== null ? 1 : 0,
      };
      const activeFilters = Object.values(truthyValuesCount).filter((item) =>
        item === 1 ? true : false
      )?.length;
      return activeFilters;
    }
  }, [searchBody]);

  useEffect(() => {
    dispatch(savePageNo(page + 1));
  }, [dispatch, page]);

  useEffect(() => {
    dispatch(
      getAllPurchasingBill(page + 1, {
        ..._SearchBody,
      })
    );
  }, [page, _SearchBody, dispatch, isSearch]);

  useEffect(() => {
    dispatch(getAllVendors());
  }, [dispatch]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleLimitChange = (newPageSize) => {
    dispatch(saveSearchBody({ ...searchBody, pageSize: newPageSize }));
    setPage(0);
    setIsSearch(!isSearch);
  };

  function deleteSelectedPurchasingBill(id) {
    setOpenDeleteDialog(false);
    dispatch(
      deletePurchasingBill(id, savePurchasingBillPageNO, _PageSize)
    ).then((x) => {
      dispatch(resetForm());
    });
  }

  const handleDialogClose = () => {
    setOpenImageDialog(false);
  };

  const handleOpenPanZoom = (url) => {
    setOpenImageDialog(true);
    setShowPanZoom(url);
  };

  var columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.5,
    },
    {
      field: "billNo",
      headerName: "Bill Number",
      flex: 2,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <PurchasingBillStatusMenu
          shipmentId={params.row.id}
          status={params.row.statusName}
          statusId={params.row.status}
          onSuccess={(newStatus) => {
            setIsSearch(!isSearch);
          }}
        />
      ),
    },
    {
      field: "discount",
      headerName: "Discount ($)",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body">
          {params.row.discount?.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: "amount",
      headerName: "Amount ($)",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body">
          {params.row.amount?.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body">
          {moment(params.row.date).format("YYYY-MM-DD")}
        </Typography>
      ),
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
    },
    {
      field: "bank",
      headerName: "Bank",
      flex: 1,
    },
    {
      field: "region",
      headerName: "Region",
      flex: 1,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body">{params.row.vendor.name}</Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body">
          {moment(params.row.createdAt).format("YYYY-MM-DD")}
        </Typography>
      ),
    },
    {
      field: "attachment",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => (
        <Box
          onClick={() => handleOpenPanZoom(params.row.attachment?.url)}
          component="img"
          src={params.row.attachment?.url}
          style={{
            maxHeight: "100px",
            maxWidth: "100px",
            objectFit: "contain",
            cursor: "pointer",
          }}
          alt="Error"
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Icon color="primary">edit</Icon>}
          label="Edit"
          hidden={!editRole}
          showInMenu
          onClick={(e) => {
            dispatch(getById(params.row.id)).then(() =>
              dispatch(openDialog(params.row.attachment?.url))
            );
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteForeverIcon color="error" />}
          label="Delete"
          hidden={!deleteRole}
          showInMenu
          onClick={(e) => {
            dispatch(getById(params.row.id)).then((x) =>
              setOpenDeleteDialog(true)
            );
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <CardContent>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteSelectedPurchasingBill(_PurchasingBill.id)}
            selectedObject={`${_PurchasingBill.billNo}`}
          ></CustomDialog>
        )}
        <CustomDialog
          isOpen={openImageDialog}
          handleClose={() => handleDialogClose()}
          title="Images"
          maxWidth="lg"
        >
          <div
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                width: "50%",
                height: "auto",
              }}
            >
              <ReactPanZoom image={showPanZoom} alt="Image alt text" />
            </div>
          </div>
        </CustomDialog>
        <Grid container spacing={2}>
          <Drawer open={openSearch} onClose={toggleDrawer(false)}>
            <Divider />
            <Grid container spacing={2} padding={2} sx={{ width: 400 }}>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={loading}
                  options={purchasing_bill_banks}
                  getOptionLabel={(option) => option}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Banks" />
                  )}
                  onChange={(event, value) => {
                    setSearchBody({
                      ...searchBody,
                      bank: value ? value : null,
                    });
                  }}
                  value={searchBody.bank}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={loading}
                  id="currency"
                  options={purchasing_bill_currency}
                  getOptionLabel={(option) => option}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Currency" />
                  )}
                  onChange={(event, value) => {
                    setSearchBody({
                      ...searchBody,
                      currency: value ? value : null,
                    });
                  }}
                  value={searchBody.currency}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={loading}
                  id="status"
                  options={purchasing_bill_status || []}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Status" />
                  )}
                  onChange={(event, value) => {
                    setSearchBody({
                      ...searchBody,
                      status: value ? value.id : null,
                    });
                  }}
                  value={
                    purchasing_bill_status?.filter(
                      (x) => x.id === searchBody.status
                    )[0] ?? null
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={loading}
                  id="vendors"
                  options={vendors || []}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Vendors" />
                  )}
                  onChange={(event, value) => {
                    setSearchBody({
                      ...searchBody,
                      vendorId: value ? value.id : null,
                    });
                  }}
                  value={
                    vendors?.filter((x) => x.id === searchBody.vendorId)[0] ??
                    null
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={loading}
                  onClick={(e) => {
                    dispatch(
                      saveSearchBody({
                        ..._SearchBody,
                        ...searchBody,
                      })
                    );
                    // setIsSearch(!isSearch);
                    setPage(0);
                  }}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={loading}
                  fullWidth
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    setSearchBody({
                      pageSize: 15,
                      billNo: "",
                      fromDate: null,
                      toDate: null,
                      vendorId: null,
                      region: null,
                      status: null,
                      currency: null,
                      bank: null,
                    });
                    dispatch(
                      saveSearchBody({
                        pageSize: 15,
                        billNo: "",
                        fromDate: null,
                        toDate: null,
                        vendorId: null,
                        region: null,
                        status: null,
                        currency: null,
                        bank: null,
                      })
                    );
                    setIsSearch(!isSearch);
                  }}
                >
                  Clear Filter
                </Button>
              </Grid>
            </Grid>
            <Divider />
          </Drawer>
          <Grid item xs={2.4}>
            <TextField
              label="Search Bill Number"
              size="small"
              value={searchBody.billNo}
              fullWidth
              onChange={(e) => {
                setSearchBody({
                  ...searchBody,
                  billNo: e.target.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(
                    saveSearchBody({
                      ..._SearchBody,
                      billNo: e.target.value,
                    })
                  );
                }
              }}
            />
          </Grid>
          {filterType === "1" ? null : (
            <>
              <Grid item xs={2.4}>
                <Autocomplete
                  disabled={loading}
                  options={purchasing_bill_banks}
                  getOptionLabel={(option) => option}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Banks" />
                  )}
                  onChange={(event, value) => {
                    setSearchBody({
                      ...searchBody,
                      bank: value ? value : null,
                    });
                  }}
                  value={searchBody.bank}
                />
              </Grid>
              <Grid item xs={2.4}>
                <Autocomplete
                  disabled={loading}
                  id="currency"
                  options={purchasing_bill_currency}
                  getOptionLabel={(option) => option}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Currency" />
                  )}
                  onChange={(event, value) => {
                    setSearchBody({
                      ...searchBody,
                      currency: value ? value : null,
                    });
                  }}
                  value={searchBody.currency}
                />
              </Grid>
              <Grid item xs={2.4}>
                <Autocomplete
                  disabled={loading}
                  id="status"
                  options={purchasing_bill_status || []}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Status" />
                  )}
                  onChange={(event, value) => {
                    setSearchBody({
                      ...searchBody,
                      status: value ? value.id : null,
                    });
                  }}
                  value={
                    purchasing_bill_status?.filter(
                      (x) => x.id === searchBody.status
                    )[0] ?? null
                  }
                />
              </Grid>
              <Grid item xs={2.4}>
                <Autocomplete
                  disabled={loading}
                  id="vendors"
                  options={vendors || []}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Vendors" />
                  )}
                  onChange={(event, value) => {
                    setSearchBody({
                      ...searchBody,
                      vendorId: value ? value.id : null,
                    });
                  }}
                  value={
                    vendors?.filter((x) => x.id === searchBody.vendorId)[0] ??
                    null
                  }
                />
              </Grid>
            </>
          )}
          <Grid item xs={3}>
            <DateRangepicker
              disabled={loading}
              startDate={searchBody.fromDate}
              endDate={searchBody.toDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  fromDate: moment(searchDate[0].startDate).format(
                    "YYYY-MM-DD"
                  ),
                  toDate: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.fromDate !== searchBody.fromDate ||
                  updatedFilter.toDate !== searchBody.toDate
                ) {
                  setSearchBody({
                    ...searchBody,
                    fromDate: moment(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    toDate: moment(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                  dispatch(
                    saveSearchBody({
                      ...searchBody,
                      fromDate: moment(searchDate[0].startDate).format(
                        "YYYY-MM-DD"
                      ),
                      toDate: moment(searchDate[0].endDate).format(
                        "YYYY-MM-DD"
                      ),
                    })
                  );
                }
                setPage(0);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Stack direction="row" spacing={2}>
              {filterType === "1" ? (
                <IconButton
                  disabled={loading}
                  onClick={() => {
                    setOpenSearch(true);
                  }}
                >
                  <Tooltip title="Advanced Search">
                    <Badge badgeContent={activeFilters} color="error">
                      <FaFilter />
                    </Badge>
                  </Tooltip>
                </IconButton>
              ) : null}
              <IconButton
                disabled={loading}
                sx={{ marginLeft: 1 }}
                onClick={() => {
                  setIsSearch(!isSearch);
                  setPage(0);
                }}
              >
                <Tooltip title="Search with filter">
                  <SearchIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                disabled={loading}
                onClick={() => {
                  setSearchBody({
                    pageSize: 15,
                    billNo: "",
                    fromDate: null,
                    toDate: null,
                    vendorId: null,
                    region: null,
                    status: null,
                    currency: null,
                    bank: null,
                  });
                  dispatch(
                    saveSearchBody({
                      pageSize: 15,
                      billNo: "",
                      fromDate: null,
                      toDate: null,
                      vendorId: null,
                      region: null,
                      status: null,
                      currency: null,
                      bank: null,
                    })
                  );
                }}
              >
                <Tooltip title="Clear filter">
                  <CloseIcon />
                </Tooltip>
              </IconButton>
            </Stack>
          </Grid>
          <Grid
            item
            xs={filterType === "1" ? 4.6 : 7}
            display="flex"
            justifyContent="end"
          >
            <IconButton
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(changeFiltersType(filterType === "1" ? "0" : "1"));
              }}
            >
              {filterType === "1" ? (
                <FaArrowDown size={30} />
              ) : (
                <FaArrowUp size={30} />
              )}
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar>
              <Box
                sx={{
                  height: { md: "calc(100vh - 280px)", xs: 1000 },
                  minWidth: 1050,
                }}
              >
                <DataGrid
                  sx={{
                    ".highlight": {
                      backgroundColor: "#bdbdbd",
                      "&:hover": {
                        backgroundColor: "darkgrey",
                      },
                    },
                  }}
                  columns={columns}
                  rows={
                    _PurchasingBills.data?.map((item, index) => {
                      return {
                        index: index + 1,
                        ...item,
                      };
                    }) || []
                  }
                  loading={loading}
                  disableSelectionOnClick
                  rowHeight={200}
                  pagination
                  paginationMode="server"
                  rowCount={_PurchasingBills?.rowCount}
                  pageSize={_SearchBody.pageSize}
                  page={page}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                  onPageSizeChange={(newPageSize) =>
                    handleLimitChange(newPageSize)
                  }
                  rowsPerPageOptions={[15, 25, 75, 100]}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
