import { TextField, Grid, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  createItemOption,
  openItemOptionsDialog,
  resetItemOptionsForm,
  updateItemOptions,
} from "src/actions/gifts/itemOptions";

const ItemOptionForm = () => {
  const dispatch = useDispatch();

  const _ItemOption = useSelector((state) => state.itemOptions);
  const reload = useSelector((state) => state.itemOptions.loading);
  const searchBody = useSelector((state) => state.itemOptions.searchBody);
  const pageNo = useSelector((state) => state.itemOptions.pageNo);
  const isAdd = _ItemOption.form.id ? false : true;
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: _ItemOption.form,
  });

  useEffect(() => {
    reset({ ..._ItemOption.form });
  }, [_ItemOption.form, reset]);

  useEffect(() => {
    return () => {
      dispatch(resetItemOptionsForm());
    };
    // eslint-disable-next-line no-use-before-define
  }, [dispatch]);

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        createItemOption(data, {
          pageNo: pageNo,
          pageSize: searchBody.pageSize,
          name: searchBody.name,
        })
      );
    } else {
      dispatch(
        updateItemOptions(data.id, data, {
          pageNo: pageNo,
          pageSize: searchBody.pageSize,
          name: searchBody.name,
        })
      );
    }
  };

  const handleDialog = () => {
    dispatch(openItemOptionsDialog(null));
  };

  return (
    <CustomDialog
      title={isAdd ? "Create product Option" : "Update product Option"}
      isOpen={_ItemOption.openDialog}
      keepMounted
      handleClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="nameEn"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name English"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="nameAr"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name Arabic"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="nameKu"
              control={control}
              render={({ field }) => (
                <TextField
                  required
                  size="small"
                  label="Name Kurdish"
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              sx={{ marginTop: 2 }}
              direction="row"
              spacing={2}
              justifyContent="end"
              alignItems="center"
            >
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={reload}
                disabled={!isDirty}
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default ItemOptionForm;
