import {
  PROHIBITED_ITEMS_RESET_FORM,
  PROHIBITED_ITEMS_GET_BY_ID,
  PROHIBITED_ITEMS_PAGE_NUMBER,
  PROHIBITED_ITEMS_OPEN_DIALOG,
  PROHIBITED_ITEMS_RELOAD,
  PROHIBITED_ITEMS_GET_ALL,
  PROHIBITED_ITEMS_GET_ALL_LIST,
  PROHIBITED_ITEMS_SEARCH_BODY,
} from "../../actions/type";

const initialState = {
  reload: false,
  openDialog: false,
  pageNo: 1,
  searchBody: {
    pageSized: 10,
    name: "",
  },
  form: {
    id: null,
    nameAr: "",
    nameEn: "",
    nameKu: "",
  },
  prohibitedItemsData: {
    data: [],
    total: 0,
    rowCount: 0,
  },
  prohibitedItemsDataList: [],
  getById: {},
};

function prohibitedItemsReducer(prohibitedItems = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PROHIBITED_ITEMS_GET_ALL:
      prohibitedItems.prohibitedItemsData.data = payload.data;
      prohibitedItems.prohibitedItemsData.total = payload.pageCount;
      prohibitedItems.prohibitedItemsData.rowCount = payload.rowCount;
      return prohibitedItems;

    case PROHIBITED_ITEMS_GET_ALL_LIST:
      prohibitedItems.prohibitedItemsDataList = payload;
      return prohibitedItems;

    case PROHIBITED_ITEMS_GET_BY_ID:
      prohibitedItems.form.id = payload.id;
      prohibitedItems.form.nameEn = payload.nameEn;
      prohibitedItems.form.nameAr = payload.nameAr;
      prohibitedItems.form.nameKu = payload.nameKu;
      return prohibitedItems;

    case PROHIBITED_ITEMS_RESET_FORM:
      prohibitedItems.form.id = null;
      prohibitedItems.form.nameEn = "";
      prohibitedItems.form.nameAr = "";
      prohibitedItems.form.nameKu = "";
      return prohibitedItems;

    case PROHIBITED_ITEMS_PAGE_NUMBER:
      return { ...prohibitedItems, pageNo: payload };

    case PROHIBITED_ITEMS_SEARCH_BODY:
      return { ...prohibitedItems, searchBody: payload };

    case PROHIBITED_ITEMS_OPEN_DIALOG:
      return { ...prohibitedItems, openDialog: !prohibitedItems.openDialog };

    case PROHIBITED_ITEMS_RELOAD:
      return { ...prohibitedItems, reload: !prohibitedItems.reload };

    default:
      return prohibitedItems;
  }
}

export default prohibitedItemsReducer;
