import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  Tooltip,
  Button,
  CardContent,
  InputAdornment,
  SvgIcon,
  Autocomplete,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { CustomDialog } from "../../CustomDialog";
import CustomSpinner from "../../CustomSpinner";
import { Search as SearchIcon } from "react-feather";
import AddIcon from "@mui/icons-material/Add";
import CustomText from "../../CustomText";
import { useDispatch, useSelector } from "react-redux";
import {
  createExpense,
  getAllExpenses,
  updateExpense,
  deleteExpense,
  getById,
} from "../../../actions/expenses";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import moment from "moment";
import { getAllExpenseTypesList } from "src/actions/expenseTypes";
import { showErrorSnackbar } from "src/actions/snackbarMessages";

export default function ExpensesList() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [isAdd, setisAdd] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openExpenseDialog, setOpenExpenseDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loading, setloading] = useState(false);
  const [reload, setReload] = useState(false);

  const initialExpenseDetials = {
    id: null,
    expenseTypeId: null,
    notes: "",
    amount: 0,
    date: new Date(),
  };
  const [expense, setExpense] = useState(initialExpenseDetials);

  const expenses = useSelector((state) => state.expense.data);
  const rowCount = useSelector((state) => state.expense.rowCount);
  const expenseTypes = useSelector((state) => state.expenseTypes);

  const dispatch = useDispatch();

  useEffect(() => {
    setReload(true);
    dispatch(getAllExpenses(page + 1)).then((x) => {
      setReload(false);
    });
  }, [page, refresh]);

  useEffect(() => {
    dispatch(getAllExpenseTypesList());
  }, []);

  // const errorAlert = (msg) =>
  //   setAlert({ open: true, message: msg, type: "error" });
  // const successAlert = (msg) =>
  //   setAlert({ open: true, message: msg, type: "success" });
  // const warningAlert = (msg) =>
  //   setAlert({ open: true, message: msg, type: "warning" });

  function clearTexts() {
    setExpense({
      ...expense,
      expenseTypeId: null,
      notes: "",
      amount: 0,
      date: new Date(),
    });
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleExpenseDialogOpen = () => {
    setOpenExpenseDialog(true);
  };

  const handleExpenseDialogClose = () => {
    setOpenExpenseDialog(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setExpense({ ...expense, [name]: value });
  };

  function addExpense() {
    setloading(true);
    dispatch(createExpense(expense))
      .then((res) => {
        setRefresh(!refresh);
        setloading(false);
        handleExpenseDialogClose();
      })
      .catch((err) => {
        setloading(false);
      });
  }

  function updateSelectedExpense() {
    setloading(true);
    dispatch(updateExpense(expense))
      .then((res) => {
        setRefresh(!refresh);
        setloading(false);
        handleExpenseDialogClose();
      })
      .catch((err) => {
        setloading(false);
      });
  }

  function getExpenseById(id) {
    clearTexts();
    dispatch(getById(id))
      .then((res) => {
        setExpense(res.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401)
            dispatch(showErrorSnackbar("Token expire"));
          // errorAlert("Token expire");
          //un-authorized ,Logout
          else if (err.response.status === 400)
            dispatch(showErrorSnackbar(err.response.data.message));
          // errorAlert(err.response.data.message);
        } else dispatch(showErrorSnackbar("Network error"));
        // errorAlert("Network error");
      });
  }

  function removeExpense(id) {
    setOpenDeleteDialog(false);
    dispatch(deleteExpense(id));
  }

  const expenseToolbar = () => {
    return (
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => removeExpense(expense.id)}
            selectedObject={`this expense`}
          >
            You will delete the expense code with name <b>{expense.name}</b>
          </CustomDialog>
        )}
        <CustomDialog
          isOpen={openExpenseDialog}
          handleClose={handleExpenseDialogClose}
          title={isAdd ? "CREATE EXPENSE" : "UPDATE PROMO"}
          maxWidth="md"
          isRequierdActions={true}
          isRequierdAdd={isAdd ? true : false}
          isRequierdEdit={isAdd ? false : true}
          onAdd={addExpense}
          onEdit={updateSelectedExpense}
          onClear={clearTexts}
          loading={loading}
        >
          <form noValidate autoComplete="off">
            <Box>
              <Autocomplete
                disableClearable
                options={expenseTypes}
                // getOptionLabel={(option) =>
                //   `${option.name}`
                // }
                getOptionLabel={(option) =>
                  option.name
                    ? option.name
                    : expenseTypes[
                        expenseTypes.findIndex((obj) => obj.id === option)
                      ].name
                }
                sx={{ width: 300 }}
                fullWidth
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Expense type" fullWidth />
                )}
                onChange={(event, value) => {
                  setExpense({ ...expense, expenseTypeId: value.id ?? null });
                }}
                value={expense.expenseTypeId}
              />
              <CustomText
                fullWidth
                label="Amount"
                name="amount"
                type="number"
                value={expense.amount}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Expense date"
                  inputFormat="MM/dd/yyyy"
                  value={expense ? expense.date : null}
                  onChange={(newValue) => {
                    setExpense({ ...expense, date: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      sx={{ marginTop: 2 }}
                    />
                  )}
                />
              </LocalizationProvider>
              <CustomText
                fullWidth
                label="Notes"
                name="notes"
                value={expense.notes}
                onChange={handleInputChange}
                rows={4}
                multiline
              />
            </Box>
          </form>
        </CustomDialog>
        <Box>
          <Card>
            <CardContent style={{ paddingBottom: 15 }}>
              <div
                style={{
                  maxWidth: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => {
                      setisAdd(true);
                      clearTexts();
                      handleExpenseDialogOpen();
                    }}
                    startIcon={<AddIcon />}
                  >
                    Create Expense
                  </Button>
                </div>
                <div>
                  <TextField
                    size="small"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search Expense"
                    variant="outlined"
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </Box>
      </Box>
    );
  };

  return (
    <Card>
      {expenseToolbar()}
      {/* <CustomizedSnackbars
        open={alert.open}
        type={alert.type}
        message={alert.message}
        onClose={() =>
          setAlert((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
      /> */}
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          {!expenses || reload ? (
            <CustomSpinner />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Note</TableCell>
                  <TableCell>Operations</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expenses.slice(0, limit).map((expense) => (
                  <TableRow hover key={expense.id}>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {expense.amount}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{expense.expenseType.name}</TableCell>
                    <TableCell>
                      {moment(expense.date).format("yyy-MM-DD")}
                    </TableCell>
                    <TableCell>{expense.notes}</TableCell>

                    <TableCell>
                      <IconButton
                        color="primary"
                        aria-label="edit"
                        onClick={(e) => {
                          setisAdd(false);
                          getExpenseById(expense.id);
                          handleExpenseDialogOpen();
                        }}
                      >
                        <Tooltip title="Edit" aria-label="edit">
                          <EditIcon />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        color="error"
                        aria-label="delete"
                        onClick={(e) => {
                          getExpenseById(expense.id);
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <Tooltip title="Delete" aria-label="delete">
                          <DeleteForeverIcon />
                        </Tooltip>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={rowCount ? rowCount : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10]}
      />
    </Card>
  );
}
