import {
  Box,
  Card,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { totalDiscountPerDay } from "src/actions/reports";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";

export default function TotalDicount(props) {
  const dispatch = new useDispatch();
  const [loading, setLoading] = useState(false);
  const [discountData, setDiscountData] = useState([]);

  useEffect(() => {
    GetTotalDiscountPerDay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date, props.setSearch]);

  function GetTotalDiscountPerDay() {
    setLoading(true);
    dispatch(
      totalDiscountPerDay({
        startDate: props.date.startDate,
        endDate: props.date.endDate,
        promoCodeId: "",
      })
    ).then((res) => {
      setDiscountData(res.data ?? []);
      setLoading(false);
    });
  }

  function CustomToolbar() {
    return (
      <div>
        <Box>
          <Box>
            <Card>
              <CardHeader
                title="Total Discount Per Promo Code Report"
                subheader={
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption">
                      {props.date.startDate + " - " + props.date.endDate}
                    </Typography>
                  </Stack>
                }
              />
              <Divider />
            </Card>
          </Box>
          <Box>
            <GridToolbarContainer>
              <GridToolbarExport
                csvOptions={{
                  fileName: `Total Discount Per Promo Code Report || Orderii || ${
                    props.date.startDate + " - " + props.date.endDate
                  }`,
                  utf8WithBom: true,
                }}
              />
            </GridToolbarContainer>
          </Box>
        </Box>
      </div>
    );
  }
  return (
    <Card style={{ marginTop: 10 }} ref={props.print}>
      <PerfectScrollbar>
        <Box sx={{ height: "calc(100vh - 320px)" }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-row ": {
                textAlign: "center",
              },
              "& .css-204u17-MuiDataGrid-main": {
                paddingLeft: "15px",
              },
              "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                paddingLeft: "15px",
              },
            }}
            columns={[
              { field: "#" },
              { field: "Promo Code", width: 200 },
              {
                field: "Total Discount",
                width: 200,
                headerName: "Total Discount ($)",
              },
            ]}
            rows={discountData
              .filter((x) => x.promoCode !== null)
              .map((data, index) => {
                return {
                  id: index + 1,
                  "#": index + 1,
                  "Promo Code": data.promoCode,
                  "Total Discount": data.totalDiscountDay,
                };
              })}
            loading={loading}
            disableSelectionOnClick
            components={{
              Toolbar: CustomToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
