import moment from "moment";
import {
  GET_ALL_TICKETS, SAVE_SEARCH_BODY_TICKETS, GET_TICKET_DETAILS, OPEN_TICKET_DEALOG, REST_TICKET_DEALOG, RELOAD_TICKET_DETAILS, UPDATE_TICKET_STATUS, UPDATE_TICKET_PRIORITY, ASSIGN_TICKET, UNASSIGN_TICKET, DELETE_TICKET_ATTACHMENT, SAVE_SEARCH_PAGE_NO, GET_TICKET_TIMLINE
} from "../actions/type";

const initialState = {
  pageNo: 0,
  reload: false,
  openDialog: false,
  createTicket: {
    description: '',
    attachmentIds: [],
    departmentId: 0,
    categoryId: 0
  },
  data: [],
  pageCount: 0,
  rowCount: 0,
  searchBody: {
    // pageNo: 1,
    number: "",
    // status: 1,
    assigneeId: "",
    from: moment().format("2023-01-01"),
    to: moment().format('YYYY-MM-DD'),
    priority: "",
    pageSize: 15,
  },
  savedPageNumber: 0,
  statusHistory: '',
  ticketDetails: {
    assignees: [],
    attachments: [],
    categoryId: '',
    comments: [],
    createdAt: '',
    departmentId: '',
    departments: [],
    departmentName: '',
    description: '',
    id: '',
    issuerInfo: {
      fullName: '',
      phoneNumber: '',
      userId: ''
    },
    number: '',
    priority: '',
    status: ''
  }
};

function ticketReducer(tickets = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_TICKETS:
      var _tickets = {
        data: payload.data,
        pageCount: payload.pageCount,
        rowCount: payload.totalCount,
        searchBody: tickets.searchBody,
        pageNo: tickets.pageNo,
        createTicket: tickets.createTicket,
        openDialog: false,
        savedPageNumber: tickets.savedPageNumber,
        statusHistory: tickets.statusHistory
      }
      return _tickets;

    case SAVE_SEARCH_BODY_TICKETS:
      return { ...tickets, searchBody: payload };

    case SAVE_SEARCH_PAGE_NO:
      tickets.pageNo = payload;
      return tickets

    case GET_TICKET_DETAILS:
      tickets.ticketDetails = payload
      return tickets;

    case OPEN_TICKET_DEALOG:
      return { ...tickets, openDialog: !payload };

    case REST_TICKET_DEALOG:
      return { ...tickets, createTicket: tickets.createTicket };

    case RELOAD_TICKET_DETAILS:
      return { ...tickets, reload: !payload };

    case GET_TICKET_TIMLINE:
      tickets.statusHistory = payload
      return tickets;

    // case UPDATE_TICKET_STATUS:
    //   tickets.ticketDetails = action.id
    //   tickets.ticketDetails = action.status
    //   return tickets;

    // case UPDATE_TICKET_PRIORITY:
    //   tickets.ticketDetails = action.id
    //   tickets.ticketDetails = action.priority
    //   return tickets;

    case DELETE_TICKET_ATTACHMENT:
      tickets.ticketDetails.attachments.filter((x) => x.id == payload.id)
      return tickets;

    case ASSIGN_TICKET:
      tickets.ticketDetails.assignees.push(payload)
      return tickets;

    case UNASSIGN_TICKET:
      tickets.ticketDetails.assignees.filter((x) => x.userId == payload.userId)
      return tickets;

    default:
      return tickets;
  }
}



export default ticketReducer;
