import { useState, useEffect } from "react";
import { Button, TextField, Grid, Autocomplete } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {
  createIamge,
  openUploadImgDialog,
} from "src/actions/imageUploadCenter";
import { Share_Center_Types } from "src/utils/common";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const UploadImageForm = () => {
  const _ShareCenter = useSelector((state) => state.shareCenter);
  const searchFilter = useSelector((state) => state.shareCenter.searchBody);

  const [attachmentfiles, setAttachmentfiles] = useState([]);

  const [uploadData, setUploadData] = useState({
    name: "",
    type: null,
    file: null,
  });

  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(attachmentfiles.map((files) => files.file));
  }, [attachmentfiles]);

  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("name", uploadData.name);
    formData.append("FileType", uploadData.type);
    dispatch(createIamge(formData, 1, searchFilter));
  };

  const handlDialog = () => {
    dispatch(openUploadImgDialog(_ShareCenter.openDialog));
  };

  return (
    <CustomDialog
      title={"Upload Attachment"}
      isOpen={_ShareCenter.openDialog}
      keepMounted
      fullWidth={true}
      handleClose={() => {
        handlDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <TextField
              required
              label="Name"
              size="small"
              fullWidth
              value={uploadData.name}
              onChange={(e) => {
                setUploadData({ ...uploadData, name: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Autocomplete
              options={Share_Center_Types}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField {...params} label="Type" required />
              )}
              onChange={(event, value) => {
                setUploadData({ ...uploadData, type: value ? value.id : null });
              }}
              value={
                Share_Center_Types.filter((x) => x.id == uploadData.type)[0]
                  ? Share_Center_Types.filter((x) => x.id == uploadData.type)[0]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FilePond
              required
              files={attachmentfiles}
              onupdatefiles={setAttachmentfiles}
              maxFiles={10}
              name="file"
              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              credits={false}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Button type="submit" variant="contained" color="primary">
              Upload
            </Button>
          </Grid>
        </Grid>
      </form>
    </CustomDialog>
  );
};

export default UploadImageForm;
