import axios from "../axios";

class GiftItemService {
  createGiftItem(data) {
    return axios.post("/giftitem", data);
  }

  bulkUpload(supplierId, data) {
    return axios.post(`/giftitem/upload-gift-items/${supplierId}`, data);
  }

  getGiftItemById(id) {
    return axios.get(`/giftitem/getById/${id}`);
  }

  getGiftItemAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${encodeURIComponent(key)}=${encodeURIComponent(
            data[key] ?? ""
          )}&`;
        }
      });
    }
    params = params.slice(0, -1);
    return axios.get(`giftitem/${pageNo}?${params}`);
  }

  deleteGiftItem(id) {
    return axios.delete(`/giftitem/${id}`);
  }

  updateGiftItem(id, data) {
    return axios.put(`/giftitem/${id}`, data);
  }

  updateGiftItemAfterOrder(id, data) {
    return axios.put(`/giftitem/updategiftitemafterorder/${id}`, data);
  }

  getGiftItemVariants(id) {
    return axios.get(`/giftitem/variants/${id}`);
  }

  updateLockerNumber(id, number) {
    return axios.put(
      `/giftitem/updatelockernumber/${id}?lockerNumber=${number}`
    );
  }

  updateMultiFilterCollection(data) {
    return axios.put(`/giftitem`, data);
  }

  changeGiftItemStatus(id, data) {
    return axios.put(`/giftitem/${id}/${data}`);
  }
}

export default new GiftItemService();
