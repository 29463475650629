import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListItemCancelReasons } from "src/actions/itemCancelReason";
import { getListOrderCancelReasons } from "src/actions/orderCancelReason";
import { rejectItem, updateStatus } from "src/actions/orders";
import { LoadingButton } from "@mui/lab";
import { CustomDialog } from "src/components/CustomDialog";
import { showErrorSnackbar } from "src/actions/snackbarMessages";

export default function CancelDialog(props) {
  const dispatch = useDispatch();

  const ordersCancelReasons = useSelector(
    (state) => state.orderCancelReason.orderCancelReasonDataList
  );
  const itemsCancelReasons = useSelector(
    (state) => state.itemCancelReason.itemCancelReasonDataList
  );

  const [selectedReasonId, setSelectedReasonId] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancelNote, setCancelNote] = useState("");

  useEffect(() => getCancelReasons(), [props.type, props.open]);

  function getCancelReasons() {
    if (props.open) {
      dispatch(
        props.type === "order"
          ? getListOrderCancelReasons()
          : getListItemCancelReasons()
      );
    }
  }

  function cancel() {
    setLoading(true);
    setConfirmationDialog(false);
    setTimeout(() => {
      dispatch(
        props.type === "order"
          ? updateStatus({
              id: props.id,
              status: 16,
              cancelReasonId: selectedReasonId,
            })
          : rejectItem(props.id, selectedReasonId, props.orderId, cancelNote)
      ).then((res) => {
        setLoading(false);
        props.onSuccess("Order cancelled successfully");
        props.handleClose();
      });
    }, 800);
  }

  const Confirmation = () => (
    <CustomDialog
      isOpen={confirmationDialog}
      handleClose={() => setConfirmationDialog(false)}
      isActiveDelete
      isRequierdActions
      title="Cancel Confirmation"
      onCancel={() => cancel()}
      selectedObject={props.type === "order" ? "This Order" : "This Item"}
      maxWidth="sm"
      isConfirmation
    ></CustomDialog>
  );

  return (
    <CustomDialog
      isOpen={props.open}
      handleClose={() => {
        props.handleClose();
        setCancelNote("");
      }}
      title={
        props.type === "order"
          ? "CANCEL REASON FOR ORDERS"
          : "CANCEL REASON FOR ITEMS"
      }
      maxWidth="md"
    >
      <Box>
        {Confirmation()}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              options={
                props.type === "order"
                  ? ordersCancelReasons
                  : itemsCancelReasons
              }
              getOptionLabel={(option) => `${option.nameAR} , ${option.nameEN}`}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Reasons" />
              )}
              onChange={(event, value) => {
                setSelectedReasonId(value ? value.id : null);
              }}
            />
          </Grid>
          {props.type === "item" ? (
            <Grid item xs={12}>
              <TextField
                multiline
                size="small"
                label="Cancel Note"
                inputProps={{ minLength: 10 }}
                fullWidth
                rows="5"
                onChange={(e) => setCancelNote(e.target.value)}
                value={cancelNote}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
            <LoadingButton
              color="error"
              variant="contained"
              loading={loading}
              sx={{ marginTop: 1 }}
              onClick={() =>
                selectedReasonId
                  ? setConfirmationDialog(true)
                  : dispatch(showErrorSnackbar("Please select cancel reason"))
              }
            >
              Cancelation
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </CustomDialog>
  );
}
