import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import CustomSpinner from "src/components/CustomSpinner";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { allStockInfoExport, getAllStockInfo } from "src/actions/gifts/reports";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";

export default function AllStockInfo(props) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [allStockInfo, setAllStockInfo] = useState([]);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getAllStockInfo({
        startDate: props.date.startDate,
        endDate: props.date.endDate,
      })
    ).then((res) => {
      setAllStockInfo(res.data);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date, props.setSearch]);

  function CustomToolbar() {
    return (
      <div>
        <Box>
          <Box>
            <Card>
              <CardHeader
                title="Gift Stock Info Report"
                subheader={
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption">
                      {props.date.startDate + " - " + props.date.endDate}
                    </Typography>
                  </Stack>
                }
              />
              <Divider />
            </Card>
          </Box>
          <GridToolbarContainer>
            <GridToolbarExportContainer>
              <MenuItem
                onClick={() => {
                  dispatch(
                    allStockInfoExport({
                      startDate: props.date.startDate,
                      endDate: props.date.endDate,
                    })
                  );
                }}
              >
                Export To CSV
              </MenuItem>
            </GridToolbarExportContainer>
          </GridToolbarContainer>
        </Box>
      </div>
    );
  }

  return (
    <Card style={{ marginTop: 10 }} ref={props.print}>
      <PerfectScrollbar>
        <Box>
          {loading ? (
            <CustomSpinner />
          ) : (
            <Box sx={{ height: "calc(100vh - 320px)" }}>
              <DataGrid
                sx={{
                  "& .MuiDataGrid-row ": {
                    textAlign: "center",
                  },
                  "& .css-204u17-MuiDataGrid-main": {
                    paddingLeft: "15px",
                  },
                  "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                    paddingLeft: "15px",
                  },
                }}
                disableSelectionOnClick={true}
                columns={[
                  { field: "#", headerName: "#" },
                  {
                    field: "nameEn",
                    headerName: "Name English",
                    width: 200,
                  },
                  {
                    field: "nameAr",
                    headerName: "Name Arabic",
                    width: 200,
                  },
                  {
                    field: "totalQuantity",
                    headerName: "Quantity",
                    width: 100,
                  },
                  {
                    field: "quantityAtCreationDate",
                    headerName: "Quantity At Creation Date",
                    width: 200,
                  },
                  {
                    field: "sku",
                    headerName: "SKU",
                    width: 230,
                  },
                  {
                    field: "giftItemCategories",
                    width: 200,
                    headerName: "Categories",
                    renderCell: (params) => {
                      const categories = params.row?.giftItemCategories?.map(
                        (data) => data.nameEn
                      );
                      return categories.join(", ");
                    },
                    valueGetter: (params) => {
                      const categories = params.row?.giftItemCategories?.map(
                        (data) => data.nameEn
                      );
                      return categories.join(", ");
                    },
                  },
                  {
                    field: "costPrice",
                    headerName: "Cost price (IQD)",
                    width: 150,
                    renderCell: (params) =>
                      params.row.costPrice?.toLocaleString() ?? "",
                  },
                  {
                    field: "sellPrice",
                    headerName: "Sell Price (IQD)",
                    width: 150,
                    renderCell: (params) =>
                      params.row.sellPrice?.toLocaleString(),
                  },
                  {
                    field: "discountPrice",
                    headerName: "Discounted Price (IQD)",
                    width: 160,
                    renderCell: (params) =>
                      params.row.discountPrice?.toLocaleString(),
                  },
                  {
                    field: "lockerNumber",
                    headerName: "Locker Number",
                    width: 150,
                  },
                ]}
                rows={
                  allStockInfo?.map((x, index) => {
                    return {
                      ...x,
                      "#": index + 1,
                    };
                  }) || []
                }
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[25, 75, 100]}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
