import {
  Card,
  CardHeader,
  Divider,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { topup } from "src/actions/reports";
import CustomSpinner from "src/components/CustomSpinner";
import PerfectScrollbar from "react-perfect-scrollbar";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { red } from "@mui/material/colors";
import moment from "moment/moment";

export default function TopUp(props) {
  const dispatch = new useDispatch();
  const [loading, setLoading] = useState(false);
  const [topUp, setTopup] = useState([]);

  useEffect(() => {
    topUpReport();
  }, [props.date, props.setSearch]);

  function topUpReport() {
    setLoading(true)
    dispatch(
      topup({
        from: props.date.startDate,
        to: props.date.endDate,
      })
    ).then((res) => {
      setTopup(res.data);
      setLoading(false)
    });
  }

  const colums = [
    {
      field: 'sequence',
      headerName: "#",
      flex: 0.3,
    },
    {
      field: 'amount',
      headerName: "Amount ($)",
      flex: 1,
    },
    {
      field: 'count',
      headerName: "Count",
      flex: 1,
    },
    {
      field: 'type',
      headerName: "Type",
      flex: 1,
    },
  ]


  function CustomToolbar() {
    return (
      <div>
        <Box>
          <Box>
            <Card>
              <CardHeader
                title="Top-Up Report"
                subheader={
                  <Stack direction="column" spacing={1}>
                    <Typography variant="caption">
                      {props.date.startDate + " - " + props.date.endDate}
                    </Typography>
                  </Stack>
                }
              />
              <Divider />
            </Card>
          </Box>
          <Box>
            <GridToolbarContainer>
              <GridToolbarExport
                csvOptions={{
                  fileName: `Top-Up Report || Orderii || ${props.date.startDate + " - " + props.date.endDate}`,
                  utf8WithBom: true,
                }}
              />
            </GridToolbarContainer>
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <Card style={{ marginTop: 10 }} ref={props.print}>
      <PerfectScrollbar>
        <Box>
          {loading ? (
            <CustomSpinner />
          ) : (
            <Box>
              {topUp.length == 0 ? (
                <Box sx={{ textAlign: "center", marginY: 2 }}>
                  <Typography sx={{ color: red[600] }}>
                    <DangerousOutlinedIcon fontSize="large" />
                  </Typography>
                  <Typography>No Data</Typography>
                </Box>
              ) : (
                <Box sx={{ height: 'calc(100vh - 320px)' }}>
                  <DataGrid
                    sx={{
                      "& .MuiDataGrid-row ": {
                        textAlign: "center",
                      },
                      "& .css-204u17-MuiDataGrid-main": {
                        paddingLeft: "15px",
                      },
                      "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                        paddingLeft: "15px",
                      },
                    }}
                    columns={colums}
                    rows={topUp.map((row, index) => {
                      return { ...row, sequence: index + 1 };
                    })}
                    getRowId={(params) => params.sequence}
                    components={{
                      Toolbar: CustomToolbar,
                    }}
                    disableSelectionOnClick
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}
