
import axios from "../axios";

class ExpenseService {
  getAll(pageNo) {
    return axios.get(`/expense/all/${pageNo}`);
  }

  getById(id) {
    return axios.get(`/expense/${id}`);
  }

  create(data) {
    return axios.post("/expense/", data);
  }

  update(data) {
    return axios.put(`/expense/`, data);
  }

  delete(id) {
    return axios.delete(`/expense/${id}`);
  }

}
export default new ExpenseService()