import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
  Button,
  CardContent,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  createMobileAppVersion,
  deleteMobileAppVersion,
  getAllMobileAppVersions,
  getById,
  updateMobileAppVersion,
} from "src/actions/mobileAppVersion";
import { CustomDialog } from "src/components/CustomDialog";
import CustomText from "src/components/CustomText";
import CustomSpinner from "src/components/CustomSpinner";

export default function MobileAppVersionsList() {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [isAdd, setisAdd] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openMobileAppVersionDialog, setOpenMobileAppVersionDialog] =
    useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loading, setloading] = useState(false);
  const [reload, setReload] = useState(false);

  const [mobileAppVersion, setMobileAppVersion] = useState({
    version: "",
  });

  const mobileAppVersions = useSelector(
    (state) => state.mobileAppVersions.data
  );
  const rowCount = useSelector((state) => state.mobileAppVersions.rowCount);

  const dispatch = useDispatch();

  useEffect(() => {
    setReload(true);
    dispatch(getAllMobileAppVersions(page + 1)).then((res) => {
      setReload(false);
    });
  }, [refresh]);

  // const errorAlert = (msg) =>
  //   setAlert({ open: true, message: msg, type: "error" });
  // const successAlert = (msg) =>
  //   setAlert({ open: true, message: msg, type: "success" });

  function clearTexts() {
    setMobileAppVersion({
      ...mobileAppVersion,
      version: "",
    });
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setRefresh(!refresh);
  };

  const handleMobileAppVersionDialogOpen = () => {
    setOpenMobileAppVersionDialog(true);
  };

  const handleMobileAppVersionDialogClose = () => {
    setOpenMobileAppVersionDialog(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setMobileAppVersion({ ...mobileAppVersion, [name]: value });
  };

  function addMobileAppVersion() {
    setloading(true);
    dispatch(createMobileAppVersion(mobileAppVersion))
      .then((res) => {
        setRefresh(!refresh);
        setloading(false);
        setOpenMobileAppVersionDialog(false);
      })
      .catch((err) => {
        setloading(false);
      });
  }

  function updateSelectedMobileAppVersion() {
    setloading(true);
    dispatch(updateMobileAppVersion(mobileAppVersion))
      .then((res) => {
        setRefresh(!refresh);
        setloading(false);
        setOpenMobileAppVersionDialog(false);
      })
      .catch((err) => {
        setloading(false);
      });
  }

  function getMobileAppVersionById(id) {
    clearTexts();
    dispatch(getById(id)).then((res) => {
      setMobileAppVersion(res.data);
    });
  }

  function deleteMobileAppVersionCode(id) {
    setOpenDeleteDialog(false);
    dispatch(deleteMobileAppVersion(id));
  }

  const expenseTypeToolbar = () => {
    return (
      <Box>
        {openDeleteDialog && (
          <CustomDialog
            isOpen={openDeleteDialog}
            handleClose={() => setOpenDeleteDialog(false)}
            isActiveDelete
            isRequierdActions
            title="Delete confirmation"
            onDelete={() => deleteMobileAppVersionCode(mobileAppVersion.id)}
            selectedObject={`this Mobile App Version ${mobileAppVersion.version}`}
          >
            You will delete the Mobile App Version with version{" "}
            <b>{mobileAppVersion.version}</b>
          </CustomDialog>
        )}
        <CustomDialog
          isOpen={openMobileAppVersionDialog}
          handleClose={handleMobileAppVersionDialogClose}
          title={
            isAdd ? "CREATE Mobile App Version" : "UPDATE Mobile App Version"
          }
          maxWidth="md"
          isRequierdActions={true}
          isRequierdAdd={isAdd ? true : false}
          isRequierdEdit={isAdd ? false : true}
          onAdd={addMobileAppVersion}
          onEdit={updateSelectedMobileAppVersion}
          onClear={clearTexts}
          loading={loading}
        >
          <form noValidate autoComplete="off">
            <Box>
              <CustomText
                fullWidth
                label="Version"
                name="version"
                value={mobileAppVersion.version}
                onChange={handleInputChange}
              />
            </Box>
          </form>
        </CustomDialog>
        <Box>
          <Card>
            <CardContent style={{ paddingBottom: 15 }}>
              <div
                style={{
                  maxWidth: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => {
                      setisAdd(true);
                      clearTexts();
                      handleMobileAppVersionDialogOpen();
                    }}
                    startIcon={<AddIcon />}
                  >
                    Create Mobile App Version
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </Box>
      </Box>
    );
  };

  return (
    <Card>
      {expenseTypeToolbar()}
      <PerfectScrollbar>
        <Box sx={{ height: { md: "calc(100vh - 297px)", xs: 1000 } }}>
          {!mobileAppVersions || reload ? (
            <CustomSpinner />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Operations</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mobileAppVersions.slice(0, limit).map((mobileAppVersion) => (
                  <TableRow hover key={mobileAppVersion.id}>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {mobileAppVersion.version}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        aria-label="edit"
                        onClick={(e) => {
                          setisAdd(false);
                          getMobileAppVersionById(mobileAppVersion.id);
                          handleMobileAppVersionDialogOpen();
                        }}
                      >
                        <Tooltip title="Edit" aria-label="edit">
                          <EditIcon />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        color="error"
                        aria-label="delete"
                        onClick={(e) => {
                          getMobileAppVersionById(mobileAppVersion.id);
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <Tooltip title="Delete" aria-label="delete">
                          <DeleteForeverIcon />
                        </Tooltip>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={rowCount ? rowCount : 0}
        onPageChange={handlePageChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10]}
      />
    </Card>
  );
}
