import axios from "../axios";

class ReportsService {
  giftAllInfo(data) {
    return axios.post(`giftreport/allgiftinfo`, data);
  }

  allStockInfo(filter) {
    let url = `giftreport/allstockinfo`;
    var filterLink = "";
    filterLink += filter.startDate ? `?StartDate=${filter.startDate}` : "";
    filterLink += filter.endDate ? `&endDate=${filter.endDate}` : "";

    return axios.get(url + filterLink);
  }

  allStockInfoExport(filter) {
    let url = `giftreport/exportstockinfo`;
    var filterLink = "";
    filterLink += filter.startDate ? `?StartDate=${filter.startDate}` : "";
    filterLink += filter.endDate ? `&endDate=${filter.endDate}` : "";

    return axios.get(url + filterLink);
  }
}

export default new ReportsService();
