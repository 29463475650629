import {
    LOGIN_AUTH
  } from "../actions/type";
  
  const initialState = null;
  
  function authReducer(auth = initialState, action) {
    const { type, payload } = action;
      switch (type) {
      case LOGIN_AUTH:
        return payload;
  
      default:
        return auth;
    }
  };
  
  export default authReducer;