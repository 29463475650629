import axios from "../axios";

class TransactionDataService {
  getAll(userId, pageNo, filter) {
    let uri = `/transaction/getalltransactions/${userId}/${pageNo}`

    var type = { Deposit: 1, Withdraw: 2 }
    let uriAddType = ''
    let uriAddendDate = ''
    let uriAddStartDate = ''
    let filters = 0

    var filterLink = ""
    filterLink += filter.transactionType ? `?transactionType=${filter.transactionType}` : '?'
    filterLink += filter.transactionStatus ? `&transactionStatus=${filter.transactionStatus}` : ''
    filterLink += filter.startDate ? `&startDate=${filter.startDate}` : ''
    filterLink += filter.endDate ? `&endDate=${filter.endDate}` : ''
    filterLink += filter.pageSize ? `&pageSize=${filter.pageSize}` : ''
    return axios.get(uri + filterLink);
  }
}

export default new TransactionDataService